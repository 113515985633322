import { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLanguage } from 'src/context/LanguageContext';
import { isValidDate } from '../../utils/utils';
import { translations as translationsSettings } from '../../utils/translationsSettings';
import AlertDialog from '../common/AlertDialog';

import { StyleDialog } from '../common/SimpleStyledDialog/StyleDialog';

export default function UpdateDialog(props) {
  const lang = useLanguage();
  const { open, onClose, onCreate, edit, onUpdate, onDelete } = props;

  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);
  const [valid, setValid] = useState(false);

  const [title, setTitle] = useState(
    (edit?.title ? decodeURIComponent(edit?.title) : edit?.title ?? '') || '',
  );

  const [description, setDescription] = useState(
    (edit?.description ? decodeURIComponent(edit?.description) : edit?.description ?? '') || '',
  );
  const [language, setLanguage] = useState(edit?.language || false);
  const date = new Date();

  const [startDate, setStartDate] = useState(
    edit?.startDate ? new Date(edit?.startDate) : new Date(),
  );

  const [endDate, setEndDate] = useState(
    edit?.endDate ? new Date(edit?.endDate) : new Date(date.setDate(date.getDate() + 7)),
  );

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete({ ...edit });
  };

  const handleCreate = () => {
    if (valid) {
      onCreate({
        title: encodeURIComponent(title),
        ...(description?.length > 0 ? { description: encodeURIComponent(description) } : {}),
        language,
        startDate,
        endDate,
      });
    }
  };

  const handleUpdate = () => {
    if (valid) {
      onUpdate({
        id: edit.id,
        title: encodeURIComponent(title),
        ...(description?.length > 0 ? { description: encodeURIComponent(description) } : {}),
        language,
        startDate,
        endDate,
      });
    }
  };

  let dialogTitle = 'Create update';

  if (edit) {
    dialogTitle = 'Edit update';
  }

  useEffect(() => {
    setValid(
      title?.length > 0 &&
        ['en', 'sv']?.includes(language) &&
        isValidDate(startDate) &&
        isValidDate(endDate) &&
        (!edit ||
          (edit &&
            (title !== edit.title ||
              (description?.length > 0 && description !== edit?.description) ||
              edit.language !== language ||
              new Date(edit.startDate) !== startDate ||
              new Date(edit.endDate) !== endDate))),
    );
  }, [title, description, language, startDate, endDate, edit]);

  return (
    <>
      <AlertDialog
        open={simpleAlertDialogOpen}
        title={translationsSettings.closeBeforeSave[lang]}
        description={translationsSettings.areYouSureClose[lang]}
        confirmBtnText={translationsSettings.confirm[lang]}
        cancelBtnText={translationsSettings.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogOpen(false);
          onClose();
        }}
        onCancel={() => {
          setSimpleAlertDialogOpen(false);
        }}
      />
      <AlertDialog
        open={simpleAlertDialogOpenDelete}
        title="Delete Item"
        description="Are you sure you want to delete this question?"
        confirmBtnText="Yes, delete!"
        cancelBtnText="No, cancel"
        onConfirm={() => {
          setSimpleAlertDialogOpenDelete(false);
          handleDelete();
        }}
        onCancel={() => {
          setSimpleAlertDialogOpenDelete(false);
        }}
      />
      <StyleDialog
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiDialogActions-root': {
            margin: 'auto',
          },
          '& .MuiDialogTitle-root': {
            color: '#2F54EB',
          },
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            borderRadius: '20px',
            '& .MuiPaper-rounded': {
              borderRadius: '8px',
            },
          },
          '& .MuiDialogContent-root': {
            minWidth: '600px',
            paddingTop: '00px',
            paddingRight: '40px',
            paddingBottom: '0px',
            paddingLeft: '30px',
            '& .section': {
              paddingTop: '10px',
              paddingRight: '0px',
              paddingBottom: '15px',
              paddingLeft: '0px',
            },
          },
          '& .MuiPaper-root .dialog-footer': {
            textAlign: 'center',
            flex: 'none',
            padding: '8px 16px',
            background: '#fbfbfb',
            borderTop: '1px solid #ededee',
            display: 'flex',
            justifyContent: 'flex-end',
            boxSizing: 'border-box',
            color: '#000',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
        }}
      >
        <DialogTitle>
          {dialogTitle}
          <IconButton
            aria-label={translationsSettings.closeAriaLabel[lang]}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <i className="icon">close</i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <fieldset className="section">
            <label htmlFor="title">
              <b className="required-field">Title </b>
              <input
                name="title"
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </label>
            <label htmlFor="description">
              <b>Description</b>
              <textarea
                name="description"
                id="description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </label>
            <label className="type-select">
              <b className="required-field">Select Language </b>
              <br />
              <select
                className="lang-select"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                required
              >
                <option disabled value={false}>
                  -- Select an Language --
                </option>
                <option value="en">English</option>
                <option value="sv">Swedish</option>
              </select>
            </label>

            <label htmlFor="start-date">
              <b className="required-field">Start Date </b>
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  format="yyyy-MM-dd"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </label>
            <label htmlFor="end-date">
              <b className="required-field">End Date </b>
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  format="yyyy-MM-dd"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </label>
          </fieldset>
        </DialogContent>
        <div className="dialog-footer">
          <DialogActions
            sx={{
              padding: '0px',
            }}
          >
            {edit ? (
              <>
                <Button
                  color="secondary"
                  onClick={() => {
                    setSimpleAlertDialogOpenDelete(true);
                  }}
                >
                  <img src="/images/icons/bin-icon.svg" alt="" />
                  {translationsSettings.question.delete[lang]}
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleUpdate}
                  disabled={!valid}
                >
                  {translationsSettings.question.save[lang]}
                </Button>
              </>
            ) : (
              <>
                <Button variant="outlined" color="secondary" onClick={handleClose}>
                  {translationsSettings.cancel[lang]}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCreate}
                  autoFocus
                  disabled={!valid}
                >
                  Create
                </Button>
              </>
            )}
          </DialogActions>
        </div>
      </StyleDialog>
    </>
  );
}
