import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../../fragments/videoCards';

export class LibraryApi {
  private backOfficeClient: ApolloClient;

  constructor(backOfficeClient: ApolloClient) {
    this.backOfficeClient = backOfficeClient;
  }

  getVideosMinimal({ orgId, encodedOrgId }: { orgId: number; encodedOrgId: string }) {
    return this.backOfficeClient.query({
      query: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        query VideosMinimal($orgId: Int!, $encodedOrgId: String!) {
          videosMinimal(orgId: $orgId, encodedOrgId: $encodedOrgId) {
            videoCards {
              ...VideoCardDetails
            }
            tagCategories {
              id
              name {
                sv
                en
              }
              tags {
                id
                name {
                  sv
                  en
                }
                isHidden
              }
            }
          }
        }
      `,
      variables: { orgId, encodedOrgId },
    });
  }

  getVideosExtended({ orgId, encodedOrgId }: { orgId: number; encodedOrgId: string }) {
    return this.backOfficeClient.query({
      query: gql`
        query VideosExtended($orgId: Int!, $encodedOrgId: String!) {
          videosExtended(orgId: $orgId, encodedOrgId: $encodedOrgId) {
            users {
              id
              firstName
              lastName
            }
            orgQuestions {
              id
              question
              categoryId
              forLeaders
              forTeamMember
              created
            }
            recipients {
              id
              orgId
              firstName
              lastName
              name
              phoneNumber
              email
              title
              receiveAutoRequests
              leader
            }
          }
        }
      `,
      variables: { orgId, encodedOrgId },
    });
  }
}

export const useLibraryApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new LibraryApi(backOfficeClient), [backOfficeClient]);
};
