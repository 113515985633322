import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useLanguage } from 'src/context/LanguageContext';
import { useStorylinesApi } from '../../api/storylines';
import { translationsStoryline } from '../../utils/translationsStoryline';
import { getDefaultState } from '../../utils/utils';
import StorylineCreateEdit from './Storyline/StorylineCreateEdit';

export default function StorylineCreate() {
  const lang = useLanguage();
  const storylinesApi = useStorylinesApi();
  const { basicOrgData } = useOutletContext();
  const [createNewIsOpen, setCreateNewIsOpen] = useState(true);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [editStoryline, setEditStoryline] = useState(null);
  const [userOptions, setUserOptions] = useState([getDefaultState('user', lang)]);

  const [selectedUser, setSelectedUser] = useState(getDefaultState('user', lang));
  const [selectedStatus, setSelectedStatus] = useState(getDefaultState('status', lang));
  const [urls, setUrls] = useState([]);
  const [urlsJsonb, setUrlsJsonb] = useState([]);
  const [storylines, setStorylines] = useState(null);

  const [info, setInfo] = useState(null);
  const [users, setUsers] = useState(null);
  const [storylineSearchField, setStoryLineSearchField] = useState('');

  const statusOptions = [
    { value: 'LIVE', label: translationsStoryline.statusOptionsLive[lang] },
    { value: 'EDIT', label: translationsStoryline.statusOptionsEdit[lang] },
    { value: 'ALL', label: translationsStoryline.statusOptionsAll[lang] },
  ];

  useEffect(() => {
    storylinesApi
      .getStorylinesMinimal(basicOrgData.encodedId)
      .then((result) => {
        setStorylines(result.data.orgStorylinesMinimal);

        storylinesApi
          .getStorylinesExtended(basicOrgData.encodedId)
          .then((result) => {
            setUsers(result.data.orgStorylinesExtended?.users ?? []);
            setUrls(result.data.orgStorylinesExtended?.urls ?? []);
            const newOptions = result?.data?.orgStorylinesExtended?.users?.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }));

            setUrlsJsonb(result.data.orgStorylinesExtended?.urlsJsonb ?? []);

            setUserOptions([getDefaultState('user', lang), ...newOptions]);
          })

          .catch((error) => {});
      })
      .catch((err) => {});
  }, []);

  const stringIncludes = (stringToCompare, stringToSearchFor) => {
    return stringToCompare
      ?.toLowerCase()
      ?.trim()
      ?.includes(stringToSearchFor?.toLowerCase()?.trim());
  };

  const storylineHasUser = (st, userId) => {
    return userId === 'ALL_USERS' || (Number(userId) && st.user.id === Number(userId));
  };

  const storylineHasStatus = (st, status) => {
    return status === 'ALL' || st.status === status;
  };

  const storylineHasUrl = (st, stringToSearchFor) => {
    return st?.urls?.some((url) => {
      return stringIncludes(url, stringToSearchFor);
    });
  };

  const handleSelected = (e) => {
    setEditStoryline(e);
    setEditIsOpen(true);
  };

  const createNewModal = createNewIsOpen ? (
    <StorylineCreateEdit
      item={null}
      close={() => setCreateNewIsOpen(false)}
      setStorylines={(e) => {
        setStorylines(e);
      }}
      basicOrgData={basicOrgData}
      setInfo={setInfo}
      setUsers={(e) => {
        setUsers(e);
      }}
      setUrls={(e) => {
        setUrls(e);
      }}
      orgUrls={urls}
      orgUrlsJsonb={urlsJsonb}
    />
  ) : null;

  const editModal = editIsOpen ? (
    <StorylineCreateEdit
      item={editStoryline}
      close={() => {
        setEditIsOpen(false);
        setEditStoryline(null);
      }}
      setStorylines={(e) => {
        setStorylines(e);
      }}
      basicOrgData={basicOrgData}
      setInfo={setInfo}
      setUsers={(e) => {
        setUsers(e);
      }}
      setUrls={(e) => {
        setUrls(e);
      }}
      orgUrls={urls}
      orgUrlsJsonb={urlsJsonb}
    />
  ) : null;

  const infoMsg = <div className="info-pop">{info}</div>;

  return (
    <div className="storyline">
      <div className="admin-library">
        {infoMsg}
        {createNewModal}
        {editModal}
      </div>
    </div>
  );
}
