import { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import BackgroundCircles from '../public/BackgroundCircles';
import { SubtitleTranslation } from '../../utils/subtitleTranslation';
import style from './OnboardingStats/styles.module.scss';
import { VIDEO_CARD_SUMMARY_FRAGMENT } from '../../fragments/videoCards';
import { HappyScribeChip } from './HappyScribeChip';

const HappyScribeResponseFragment = gql`
  fragment HappyScribeResponseFragment on HappyScribeResponse {
    id
    name
    createdAt
    updatedAt
    sharingEnabled
    state
    language
    audioLength
    soundwaveURL
    organizationId
    _links {
      self {
        url
      }
      editor {
        url
      }
    }
  }
`;

export const getSubtitle = (adminClient, videoCardId, encodedVideoCardId) => {
  if (videoCardId) {
    return adminClient.query({
      query: gql`
        ${VIDEO_CARD_SUMMARY_FRAGMENT}
        ${HappyScribeResponseFragment}
        query GetSubtitle($videoCardId: Int) {
          getSubtitle(videoCardId: $videoCardId) {
            id
            subtitleRequestId
            orgId
            videoCardId
            requestDate
            videoCard {
              ...VideoCardSummary
              happyScribeResponse {
                ...HappyScribeResponseFragment
              }
            }
            orgData {
              orgName
            }
          }
        }
      `,
      variables: { videoCardId },
    });
  }

  if (encodedVideoCardId) {
    return adminClient.query({
      query: gql`
        ${VIDEO_CARD_SUMMARY_FRAGMENT}
        ${HappyScribeResponseFragment}
        query GetSubtitle($encodedVideoCardId: String) {
          getSubtitle(encodedVideoCardId: $encodedVideoCardId) {
            id
            orgId
            subtitleRequestId
            videoCardId
            requestDate
            videoCard {
              ...VideoCardSummary
              happyScribeResponse {
                ...HappyScribeResponseFragment
              }
            }
            orgData {
              orgName
            }
          }
        }
      `,
      variables: { encodedVideoCardId },
    });
  }

  return adminClient.query({
    query: gql`
      ${VIDEO_CARD_SUMMARY_FRAGMENT}
      ${HappyScribeResponseFragment}
      query GetSubtitle {
        getSubtitle {
          id
          orgId
          videoCardId
          requestDate
          subtitleRequestId
          videoCard {
            ...VideoCardSummary
            happyScribeResponse {
              ...HappyScribeResponseFragment
            }
          }
          orgData {
            orgName
          }
        }
      }
    `,
  });
};

const deleteSubtitle = (adminClient, id) => {
  return adminClient.mutate({
    mutation: gql`
          mutation {
            deleteSubtitle( id: ${id} ) {
              statusMessage
           }
          }
      `,
  });
};

function SubtitleRequest() {
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const [translationData, setTranslationData] = useState([]);
  useEffect(() => {
    try {
      getSubtitle(adminClient)
        .then((res) => {
          const sortedData = [...(res.data.getSubtitle ?? [])]?.sort((a, b) => {
            return new Date(a.requestDate) - new Date(b.requestDate);
          });
          setTranslationData(sortedData);
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(err);
          console.error('error fetching subtitle requests', err);
        });
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Something went wrong. Contact support. ', e);
      console.error('error fetching subtitle requests', e);
    }
  }, [adminClient]);

  const deleteTransconfirm = (id) => {
    if (!id) {
      // eslint-disable-next-line no-alert
      alert('Could not remove Record. Contact support.');
    } else {
      // eslint-disable-next-line no-alert
      const confirmation = window.confirm('Do you want to delete this subtitle request?');
      if (confirmation) {
        deleteSubtitle(adminClient, id)
          .then(() => {
            navigate('/admin/subtitleRequest');
          })
          .catch((e) => {
            // eslint-disable-next-line no-alert
            alert('Something went wrong. Contact support. ', e);
            console.error('error deleting subtitle request', e);
          });
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={style.container}>
      <h1>{SubtitleTranslation.TranslationordersList[lang]}</h1>

      <table
        id="dtBasicExample"
        className="table table-striped table-bordered table-sm"
        cellSpacing="0"
        width="100%"
      >
        <thead className={style.header}>
          <tr>
            <th className="th-sm">{SubtitleTranslation.orgName[lang]}</th>
            <th className="th-sm">{SubtitleTranslation.VideoCardID[lang]}</th>
            <th className="th-sm"> {SubtitleTranslation.RequestedDate[lang]}</th>
            <th>{SubtitleTranslation.UploadSubtitle[lang]}</th>
            <th>{SubtitleTranslation.DeleteRequest[lang]}</th>
            <th>{SubtitleTranslation.happyScribeStatus[lang]}</th>
          </tr>
        </thead>
        <tbody>
          {translationData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((subtitleRequestObj) => {
              const happyScribeResponse = subtitleRequestObj?.videoCard?.happyScribeResponse;

              const happyScribeResponseState = happyScribeResponse?.state;

              return (
                <tr key={subtitleRequestObj.id}>
                  <td>{subtitleRequestObj.orgData.orgName}</td>
                  <td>{subtitleRequestObj.videoCard.encodedId}</td>
                  <td>{subtitleRequestObj.requestDate}</td>
                  <td>
                    <Button
                      onClick={() => {
                        navigate(
                          `/admin/${subtitleRequestObj.videoCard.encodedId}/subtitleUpload`,
                          {
                            state: {
                              ...subtitleRequestObj.videoCard,
                              subtitleRequestId: subtitleRequestObj.id,
                            },
                          },
                        );
                      }}
                    >
                      {SubtitleTranslation.Upload[lang]}
                      <i className="icon">launch</i>
                    </Button>
                  </td>
                  <td>
                    <Button color="error" onClick={() => deleteTransconfirm(subtitleRequestObj.id)}>
                      {SubtitleTranslation.Delete[lang]}
                    </Button>
                  </td>
                  <td>
                    {!happyScribeResponse?.state ? (
                      'No HappyScribe ref'
                    ) : (
                      <HappyScribeChip happyScribeResponseState={happyScribeResponseState} />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={translationData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <BackgroundCircles />
    </div>
  );
}

export default SubtitleRequest;
