import React, { useContext } from 'react';

import Typography from '@mui/material/Typography';

import Link from '@mui/material/Link';
import ThemeContext from 'src/utils/ThemeContext';
import { useLanguage } from 'src/context/LanguageContext';
import translations from './translations';

function ReCAPTCHAText() {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);
  return (
    <Typography
      variant="bodySmall"
      color={theme.palette.text.light}
      align="center"
      sx={{
        display: 'block',
      }}
      component="p"
      role="paragraph"
    >
      {translations.text[lang]}{' '}
      <Link
        target="_blank"
        href={translations.privacyPolicyLink[lang]}
        rel="noreferrer"
        color={theme.palette.text.light}
      >
        {translations.privacyPolicy[lang]}
      </Link>{' '}
      {translations.and[lang]}{' '}
      <Link
        target="_blank"
        href={translations.termsOfServiceLink[lang]}
        rel="noreferrer"
        color={theme.palette.text.light}
      >
        {translations.termsOfService[lang]}
      </Link>{' '}
      {translations.apply[lang]}
    </Typography>
  );
}

export default ReCAPTCHAText;
