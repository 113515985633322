import { MenuItem } from '@mui/material';
import { fontFamily } from './SharedStylingSettings';

const styling = {
  shared: {
    fontFamily: fontFamily,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.5',
  },
};

export default function SimpleMenuItem(props) {
  const { empty = false } = props;

  var newPros = { ...props };
  if (newPros.hasOwnProperty('empty')) {
    delete newPros.empty;
  }

  if (empty) {
    return (
      <MenuItem
        value={props.key}
        key={_.uniqueId(props.key)}
        {...newPros}
        style={{ ...styling.shared, ...newPros?.style }}
        sx={{ ...newPros?.sx }}
      >
        <em>none</em>
      </MenuItem>
    );
  }
  return (
    <MenuItem
      value={props.key}
      key={_.uniqueId(props.key)}
      {...newPros}
      style={{ ...styling.shared, ...newPros?.style }}
      sx={{ ...newPros?.sx }}
    >
      {props.title}
    </MenuItem>
  );
}
