import React, { useEffect, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import ToolTip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { uniqueId } from 'lodash';

import { useLanguage } from 'src/context/LanguageContext';
import SimpleMenuItem from '../../common/SimpleMenuItem';
import { outlinedInput } from '../../common/SplitButton/SplitButtonThemes';
import { isValidHttpUrl, packageVariant } from '../../../utils/utils';
import { translationsStoryline } from '../../../utils/translationsStoryline';

export const getTranslationKey = (value) => {
  return Object.keys(translationsStoryline.availableWhenConditions)?.find((key) => {
    return translationsStoryline.availableWhenConditions[key] === value;
  });
};

const getPlaceholderText = ({ selectedConditionValue, lang }) => {
  switch (selectedConditionValue) {
    case getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch):
      return 'https://lifeinside.io/article';
    case getTranslationKey(translationsStoryline.availableWhenConditions.beginsWith):
      return `https://lifeinside.io/lifeinside/article ${translationsStoryline.or[
        lang
      ].toLowerCase()} /lifeinside/article`;

    case getTranslationKey(translationsStoryline.availableWhenConditions.endsWith):
      return '/lifeinside/article';

    case getTranslationKey(translationsStoryline.availableWhenConditions.contains):
      return '/lifeinside/';

    case getTranslationKey(translationsStoryline.availableWhenConditions.wildCard):
      return `https://lifeinside.io/*/lifeinside/*/product/ ${translationsStoryline.or[
        lang
      ].toLowerCase()} */lifeinside/*/product/`;

    default:
      return 'https://lifeinside.io/article';
  }
};

const getTooltipHelp = (selectedConditionValue, lang) => {
  return translationsStoryline.availableWhenConditions?.[selectedConditionValue]?.tooltipHelpText[
    lang
  ];
};

export default function StorylineLinkWhenCondition({
  basicOrgData = {},
  urlsJsonb = [],
  setUrlsJsonb,
  urlOjb = [],
  indexInList,
  otherStorylinesUrls = [],
}) {
  const lang = useLanguage();
  const [hasStartedEdit, setStartedEdit] = useState(false);
  const { premiumFeatures } = basicOrgData;
  const [selectedCondition, setSelectedCondition] = useState(
    urlOjb?.conditionWhen ??
      urlOjb?.excludedWhen ??
      getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch),
  );
  const [conditionValue, setConditionValue] = useState(urlOjb?.url ?? '');
  const [conditionValueValid, setConditionValueValid] = useState(true);

  const isFreemium = premiumFeatures?.packageVariant === packageVariant.freemium;
  const freemiumStoriesLimitCondition =
    isFreemium && premiumFeatures?.freemiumFeatures?.stories?.conditions;

  const checkIfUrlWhenConditionIsUsed = useCallback(
    ({ value = conditionValue, condition = selectedCondition }) => {
      return (
        value?.length > 0 &&
        otherStorylinesUrls?.some(
          (otherStorylinesUrlJsonb) =>
            condition === otherStorylinesUrlJsonb.conditionWhen &&
            value === otherStorylinesUrlJsonb.url,
        )
      );
    },
    [conditionValue, selectedCondition, otherStorylinesUrls],
  );

  const checkIfUrlWhenConditionIsUsedByCurrent = useCallback(
    ({ value = conditionValue, condition = selectedCondition }) => {
      return (
        value?.length > 0 &&
        urlsJsonb?.filter(
          (otherStorylinesUrlJsonb) =>
            (condition === otherStorylinesUrlJsonb.conditionWhen ||
              condition === otherStorylinesUrlJsonb.excludedWhen) &&
            value === otherStorylinesUrlJsonb.url,
        )?.length > 1
      );
    },
    [conditionValue, selectedCondition, urlsJsonb],
  );

  const checkIfValidExactMatchValue = useCallback(
    ({ value = conditionValue, condition = selectedCondition }) => {
      if (
        condition === getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch)
      ) {
        return !isValidHttpUrl(value);
      }
      return false;
    },
    [conditionValue, selectedCondition],
  );

  const validateInput = useCallback(
    (text, callbackName, onBlur = false) => {
      const isValid = !(
        checkIfUrlWhenConditionIsUsed({ value: text }) ||
        checkIfUrlWhenConditionIsUsedByCurrent({ value: text }) ||
        (onBlur && checkIfValidExactMatchValue({ value: text }))
      );
      setConditionValueValid(isValid);
    },
    [
      checkIfUrlWhenConditionIsUsed,
      checkIfUrlWhenConditionIsUsedByCurrent,
      checkIfValidExactMatchValue,
    ],
  );
  useEffect(() => {
    validateInput(
      urlOjb?.url ?? '',
      'setConditionValue',
      !hasStartedEdit && urlOjb?.url?.length > 0,
    );
  }, [urlOjb, urlsJsonb, validateInput, hasStartedEdit]);

  const [override, setOverride] = useState(
    getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) ===
      selectedCondition && urlOjb?.override,
  );

  const getConditionInputRow = () => {
    return (
      <input
        type="url"
        aria-label="Url exact match"
        name={uniqueId('urlWhen')}
        id={uniqueId('urlWhen')}
        value={conditionValue}
        onChange={(e) => {
          setStartedEdit(true);
          setConditionValue(e.target.value);
        }}
        placeholder={getPlaceholderText({ selectedConditionValue: selectedCondition, lang })}
        required
        onBlur={(e) => {
          const items = [...urlsJsonb];
          const item = { ...items[urlOjb.index] };
          item.url = e.target.value;
          items[urlOjb.index] = item;
          validateInput(e.target.value, 'setConditionValue', true);
          setUrlsJsonb(items);
        }}
        style={{
          ...(conditionValueValid
            ? {}
            : {
                borderColor: 'red',
                boxShadow: '0 0 0 0.2rem rgba(244, 67, 54, 0.25)',
                outline: 'none !important',
                border: '1px solid rgb(244, 67, 54)',
              }),
        }}
      />
    );
  };

  const errors = {
    WHEN_CONDITION_USED_BY_OTHER_SHORT: 'WHEN_CONDITION_USED_BY_OTHER_SHORT',
    WHEN_CONDITION_USED: 'WHEN_CONDITION_USED',
    INVALID_URL: 'INVALID_URL',
  };

  const validationError = () => {
    if (checkIfUrlWhenConditionIsUsed({})) {
      return errors.WHEN_CONDITION_USED_BY_OTHER_SHORT;
    }
    if (checkIfUrlWhenConditionIsUsedByCurrent({})) {
      return errors.WHEN_CONDITION_USED;
    }
    if (checkIfValidExactMatchValue({})) {
      return errors.INVALID_URL;
    }

    return false;
  };

  return (
    <Box>
      <Grid
        id="top-row"
        container
        direction="row"
        justify="center"
        alignItems="left"
        justifyContent="left"
      >
        <Grid
          item
          alignItems="center"
          justifyContent="right"
          align="right"
          display="flex"
          style={{ width: '50px' }}
        >
          <Typography variant="titleSmall">
            {indexInList === 0 ? translationsStoryline.when[lang] : translationsStoryline.or[lang]}:
          </Typography>
        </Grid>
        <Grid item alignItems="center" justifyContent="center" align="center" display="flex">
          <div style={{ width: '10px' }} />
          <Typography
            variant="titleSmall"
            style={{
              fontWeight: '300',
              color: '#292929',
            }}
          >
            {translationsStoryline.websiteLink[lang]}
          </Typography>
        </Grid>
        <Grid
          item
          alignItems="left"
          justifyContent="left"
          align="left"
          display="flex"
          style={{ minWidth: '182px', paddingLeft: '12px' }}
        >
          <Select
            variant="outlined"
            size="small"
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty
            value={selectedCondition}
            onChange={(e) => {
              setSelectedCondition(e.target.value);
              const items = [...urlsJsonb];
              const item = { ...items[urlOjb.index] };
              if (urlOjb?.conditionWhen) {
                item.conditionWhen = e.target.value;
              } else if (urlOjb?.excludedWhen) {
                item.excludedWhen = e.target.value;
              }

              if (
                e.target.value !==
                  getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) &&
                !!item?.override
              ) {
                delete item.override;
              }
              items[urlOjb.index] = item;
              setUrlsJsonb(items);
            }}
            style={{ width: '100%' }}
            input={outlinedInput(true)}
            renderValue={(selected) => {
              return translationsStoryline.availableWhenConditions?.[selected]?.[lang];
            }}
          >
            {Object.keys(translationsStoryline.availableWhenConditions)?.map((key) => (
              <SimpleMenuItem
                value={key}
                key={uniqueId(key)}
                title={translationsStoryline.availableWhenConditions?.[key]?.[lang]}
                disabled={isFreemium && !freemiumStoriesLimitCondition?.[key]}
              />
            ))}
          </Select>
        </Grid>
        <Grid
          item
          alignItems="center"
          justifyContent="center"
          align="center"
          display="flex"
          style={{ minWidth: '560px', paddingLeft: '12px', position: 'relative' }}
        >
          {!conditionValueValid && (
            <div
              style={{
                minWidth: '20px',
                minHeight: '20px',
                position: 'absolute',
                top: '-20px',
                left: '25px',
              }}
            >
              <Typography
                variant="bodyMedium"
                style={{
                  lineHeight: '18px',
                  color: 'red',
                }}
              >
                {translationsStoryline.errors?.[validationError()]?.[lang]}
              </Typography>
            </div>
          )}
          {getConditionInputRow()}
        </Grid>
        {!!urlOjb?.conditionWhen &&
          selectedCondition ===
            getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) && (
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              align="center"
              display="flex"
              style={{ paddingLeft: '12px' }}
            >
              <label
                className="checkbox-label exact-match"
                style={{ marginBottom: 'unset' }}
                htmlFor="urlOverride"
              >
                <input
                  aria-label="Url exact match override"
                  name={uniqueId('urlOverride')}
                  id={uniqueId('urlOverride')}
                  type="checkbox"
                  checked={override}
                  onChange={() => {
                    if (
                      selectedCondition ===
                        getTranslationKey(
                          translationsStoryline.availableWhenConditions.exactMatch,
                        ) &&
                      !!urlOjb?.conditionWhen
                    ) {
                      setOverride(!override);
                      const items = [...urlsJsonb];
                      const item = { ...items[urlOjb.index] };
                      if (!!urlOjb?.override && override) {
                        delete item.override;
                      } else {
                        item.override = !override;
                      }
                      items[urlOjb.index] = item;
                      setUrlsJsonb(items);
                    } else {
                      setOverride(false);
                      const items = [...urlsJsonb];
                      const item = { ...items[urlOjb.index] };
                      if (!!urlOjb?.override && override) {
                        delete item.override;
                      }
                      items[urlOjb.index] = item;
                      setUrlsJsonb(items);
                    }
                  }}
                />

                <Typography variant="bodyMedium">{translationsStoryline.override[lang]}</Typography>
              </label>
            </Grid>
          )}
        <Grid
          item
          alignItems="center"
          justifyContent="center"
          align="center"
          display="flex"
          style={{ paddingLeft: '12px' }}
        >
          <ToolTip title={getTooltipHelp(selectedCondition, lang)} key={uniqueId()}>
            <HelpIcon fontSize="small" style={{ fill: 'rgb(193 197 213)' }} />
          </ToolTip>
        </Grid>
        <Grid
          item
          alignItems="center"
          justifyContent="center"
          align="center"
          display="flex"
          style={{ paddingLeft: '12px' }}
        >
          {urlsJsonb?.length > 1 && (
            <IconButton
              onClick={() => {
                const items = [...urlsJsonb]
                  ?.filter((o) => o.index !== urlOjb.index)
                  ?.map((o, index) => ({ ...o, index }));

                setUrlsJsonb(items);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

StorylineLinkWhenCondition.propTypes = {
  basicOrgData: PropTypes.shape({
    premiumFeatures: PropTypes.shape({
      packageVariant: PropTypes.string,
      freemiumFeatures: PropTypes.shape({
        stories: PropTypes.shape({
          conditions: PropTypes.shape({}),
        }),
      }),
    }),
  }),
  urlsJsonb: PropTypes.arrayOf(
    PropTypes.shape({
      conditionWhen: PropTypes.string,
      excludedWhen: PropTypes.string,
      index: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
  setUrlsJsonb: PropTypes.func.isRequired,
  urlOjb: PropTypes.shape({
    conditionWhen: PropTypes.string,
    excludedWhen: PropTypes.string,
    index: PropTypes.number,
    url: PropTypes.string,
    override: PropTypes.bool,
  }),
  indexInList: PropTypes.number.isRequired,
  otherStorylinesUrls: PropTypes.arrayOf(
    PropTypes.shape({
      conditionWhen: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};
