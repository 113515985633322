import PropTypes from 'prop-types';

function DraftOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.9999C10.6167 21.9999 9.31667 21.7372 8.1 21.2119C6.88333 20.6865 5.825 19.9742 4.925 19.0749C4.025 18.1749 3.31267 17.1165 2.788 15.8999C2.26333 14.6832 2.00067 13.3832 2 11.9999C2 10.6165 2.26267 9.31655 2.788 8.09988C3.31333 6.88321 4.02567 5.82488 4.925 4.92488C5.825 4.02488 6.88333 3.31254 8.1 2.78788C9.31667 2.26321 10.6167 2.00054 12 1.99988C13.3833 1.99988 14.6833 2.26254 15.9 2.78788C17.1167 3.31321 18.175 4.02554 19.075 4.92488C19.975 5.82488 20.6877 6.88321 21.213 8.09988C21.7383 9.31655 22.0007 10.6165 22 11.9999C22 13.3832 21.7373 14.6832 21.212 15.8999C20.6867 17.1165 19.9743 18.1749 19.075 19.0749C18.175 19.9749 17.1167 20.6875 15.9 21.2129C14.6833 21.7382 13.3833 22.0005 12 21.9999ZM12 19.9999C14.2333 19.9999 16.125 19.2249 17.675 17.6749C19.225 16.1249 20 14.2332 20 11.9999C20 9.76654 19.225 7.87488 17.675 6.32488C16.125 4.77488 14.2333 3.99988 12 3.99988C9.76667 3.99988 7.875 4.77488 6.325 6.32488C4.775 7.87488 4 9.76654 4 11.9999C4 14.2332 4.775 16.1249 6.325 17.6749C7.875 19.2249 9.76667 19.9999 12 19.9999ZM8 15.9999V12.9249L13.525 7.42488C13.675 7.27488 13.8417 7.16654 14.025 7.09988C14.2083 7.03321 14.3917 6.99988 14.575 6.99988C14.775 6.99988 14.9667 7.03754 15.15 7.11288C15.3333 7.18821 15.5 7.30054 15.65 7.44988L16.575 8.37488C16.7083 8.52488 16.8127 8.69154 16.888 8.87488C16.9633 9.05821 17.0007 9.24154 17 9.42488C17 9.60821 16.9667 9.79588 16.9 9.98788C16.8333 10.1799 16.725 10.3505 16.575 10.4999L11.075 15.9999H8ZM9.5 14.4999H10.45L13.475 11.4499L13.025 10.9749L12.55 10.5249L9.5 13.5499V14.4999ZM13.025 10.9749L12.55 10.5249L13.475 11.4499L13.025 10.9749Z"
        fill={fill}
      />
    </svg>
  );
}

export default DraftOutlinedIcon;

DraftOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
