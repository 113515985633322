import React, { useState, useEffect, useCallback } from 'react';
import { useVideoStatsApi } from './VideoStatsApi';
import { VideoStatsProps } from './types';
import { StatsByTypeTotalResult } from '../../Analytics/Overview/analyticsOverviewApi';
import StatsDisplay from '../../StatsDisplay';

const isLocalhost = window.location.href.includes('localhost');
const isStaging = window.location.href.includes('app.staging');

function VideoStats({ video, encodedOrgId }: VideoStatsProps) {
  const videoStatsApi = useVideoStatsApi();
  const [videoTotalStats, setVideoTotalStats] = useState<StatsByTypeTotalResult | null>(null);
  const [error, setError] = useState<string | null | unknown>(null);

  const fetchData = useCallback(async () => {
    try {
      setError(null);
      const data = await videoStatsApi.fetchVideoCardAnalyticsData({
        videoCardId: video.id,
        encodedOrgId,
      });

      if (data?.totalStats) {
        setVideoTotalStats(data.totalStats);
      }
    } catch (err) {
      setError(err);
      if (isLocalhost || isStaging) {
        console.warn(err);
      }
    }
  }, [video.id, encodedOrgId, videoStatsApi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const showVideoStats = useCallback(() => {
    return video.status === 'LIVE' || video.status === 'APPROVED' || video.status === 'ARCHIVED';
  }, [video.status]);

  if (!video || !showVideoStats() || !videoTotalStats || error) {
    return null;
  }

  return <StatsDisplay stats={videoTotalStats} title="videoStats" />;
}

export default VideoStats;
