/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, memo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { getStaticThumbnail, getAnimatedThumbnail, VideoCard } from '../../utils/video-card/utils';
import LifeInsidePlayLogoFilledIcon from '../common/icons/lifeInsidePlayLogo/LifeInsidePlayLogo';

interface HoverVideoProps {
  videoCard: VideoCard;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
}

const translations = {
  videoThumbnail: {
    en: 'Video thumbnail',
    sv: 'Videothumbnail',
  },
  videoThumbnailHoverArea: {
    en: 'Video thumbnail hover area',
    sv: 'Videothumbnail hover-område',
  },
};

function HoverVideo({ videoCard, width = 56, height = '100%', borderRadius = 8 }: HoverVideoProps) {
  const theme = useTheme();
  const lang = useLanguage();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mouseHover, setMouseHover] = useState(false);

  const style = {
    width: typeof width === 'number' ? `${width}px` : width,
    height: typeof height === 'number' ? `${height}px` : height,
    borderRadius: typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
  };

  const handleVideoEnd = () => {
    if (videoRef.current && mouseHover) {
      videoRef.current.play().catch(() => {});
    }
  };

  const handleMouseEnter = () => {
    setMouseHover(true);
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch(() => {});
    }
  };

  const handleMouseLeave = () => {
    setMouseHover(false);
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'transparent',
        height: style.height,
        width: style.width,
        borderRadius: style.borderRadius,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label={translations.videoThumbnailHoverArea[lang]}
      role="region"
      data-testid="hover-video-box"
      data-hovering={mouseHover}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.divider,
          height: '100%',
          width: '100%',
          borderRadius: style.borderRadius,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '25%',
            height: '25%',
          }}
          aria-hidden="true"
        >
          <LifeInsidePlayLogoFilledIcon fill={theme.palette.text.secondary} />
        </Box>

        <video
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: style.borderRadius,
            backgroundColor: 'transparent',
            position: 'relative',
          }}
          poster={getStaticThumbnail(videoCard, 'sqcif')}
          src={getAnimatedThumbnail(videoCard, 'sqcif')}
          ref={videoRef}
          onEnded={handleVideoEnd}
          disableRemotePlayback
          aria-label={translations.videoThumbnail[lang]}
          data-testid="hover-video-element"
          muted
        />
      </Box>
    </Box>
  );
}

const MemoHoverVideo = memo(HoverVideo);

export default MemoHoverVideo;
