import React, { useState, useEffect } from 'react';

import { sub, add, startOfYear, startOfMonth } from 'date-fns';

import '../common/Datepicker/Datepicker.scss';
import { useOutletContext } from 'react-router-dom';
import { useLanguage } from 'src/context/LanguageContext';

import Typography from '@mui/material/Typography';
import Select from '../common/atoms/Select';
import { translations } from '../../utils/translations';

import StatsBoxByType from './StatsBoxByType';
import formatDailyData, { DailyDataByType } from './formatDailyData';
import formatDateString from './formatDateString';
import { useActions } from './actions';

const validTimePeriods = {
  PAST_7_DAYS: 'PAST_7_DAYS',
  PAST_30_DAYS: 'PAST_30_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  CUSTOM: 'CUSTOM',
};

interface Organization {
  encodedId: string;
}

interface OrganizationOutletContext {
  basicOrgData: Organization;
}

export default function Stats() {
  const lang = useLanguage();
  const { basicOrgData }: OrganizationOutletContext = useOutletContext();
  const actions = useActions();

  const [statsData, setStatsData] = useState(null);
  const [dailyData, setDailyData] = useState<DailyDataByType | undefined>(undefined);
  const [timePeriod, setTimePeriod] = useState(validTimePeriods.PAST_7_DAYS);
  const [startEndDate, setStartEndDate] = useState<{ start: string; end: string }>({
    start: '',
    end: '',
  });
  useEffect(() => {
    if (startEndDate.start && startEndDate.end) {
      actions
        .getOrgStats(basicOrgData.encodedId, startEndDate.start, startEndDate.end)
        .then((res) => {
          const data = res.data.orgStats;
          if (data && 'analytics' in data) {
            setStatsData(data.analytics);
            setDailyData(formatDailyData(startEndDate, data.analytics) || undefined);
          }
        })
        .catch(() => {});
    }
  }, [actions, basicOrgData.encodedId, startEndDate]);

  useEffect(() => {
    const currentDate = new Date();
    switch (timePeriod) {
      case validTimePeriods.PAST_7_DAYS:
        setStartEndDate({
          start: formatDateString(sub(currentDate, { days: 6 })),
          end: formatDateString(add(currentDate, { days: 1 })),
        });
        break;
      case validTimePeriods.PAST_30_DAYS:
        setStartEndDate({
          start: formatDateString(sub(currentDate, { days: 29 })),
          end: formatDateString(add(currentDate, { days: 1 })),
        });
        break;
      case validTimePeriods.THIS_MONTH:
        setStartEndDate({
          start: formatDateString(startOfMonth(currentDate)),
          end: formatDateString(currentDate),
        });
        break;
      case validTimePeriods.LAST_MONTH:
        setStartEndDate({
          start: formatDateString(startOfMonth(sub(currentDate, { months: 1 }))),
          end: formatDateString(startOfMonth(currentDate)),
        });
        break;
      case validTimePeriods.THIS_YEAR:
        setStartEndDate({
          start: formatDateString(startOfYear(currentDate)),
          end: formatDateString(currentDate),
        });
        break;
      case validTimePeriods.LAST_YEAR:
        setStartEndDate({
          start: formatDateString(startOfYear(sub(currentDate, { years: 1 }))),
          end: formatDateString(startOfYear(currentDate)),
        });
        break;
      default:
        setStartEndDate({
          start: '',
          end: '',
        });
        break;
    }
  }, [timePeriod]);

  if (!statsData || !('playedVideos' in statsData) || !dailyData) return null;

  return (
    <div className="stats-page">
      <div className="title-row">
        <Typography variant="headlineLargeBoldRecoleta">{translations.analytics[lang]}</Typography>
        <div>
          <Select
            label={translations.timeline.selectTimePeriod[lang]}
            labelHidden={false}
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            options={[
              { value: 'PAST_7_DAYS', label: translations.timeline.past7Days[lang] },
              { value: 'PAST_30_DAYS', label: translations.timeline.past30Days[lang] },
              { value: 'THIS_MONTH', label: translations.timeline.thisMonth[lang] },
              { value: 'LAST_MONTH', label: translations.timeline.lastMonth[lang] },
              { value: 'THIS_YEAR', label: translations.timeline.thisYear[lang] },
              { value: 'LAST_YEAR', label: translations.timeline.lastYear[lang] },
              { value: 'CUSTOM', label: translations.timeline.custom[lang] },
            ]}
          />
        </div>
      </div>
      <div className="stats-box-wrapper">
        <StatsBoxByType
          field="presentedVideos"
          statsData={statsData}
          dailyData={dailyData}
          fullWidth
        />
        <StatsBoxByType field="playedVideos" statsData={statsData} dailyData={dailyData} />
        <StatsBoxByType field="pausedVideos" statsData={statsData} dailyData={dailyData} />
        <StatsBoxByType field="showNext" statsData={statsData} dailyData={dailyData} />
        <StatsBoxByType field="completedVideos" statsData={statsData} dailyData={dailyData} />
        <StatsBoxByType field="minimizedWidget" statsData={statsData} dailyData={dailyData} />
        <StatsBoxByType field="openLibrary" statsData={statsData} dailyData={dailyData} />
      </div>
    </div>
  );
}

export const fieldToEventType = {
  presentedVideos: 'LOADED',
  playedVideos: 'PLAY',
  pausedVideos: 'PAUSE',
  completedVideos: 'END',
  minimizedWidget: 'CLICK_MINIMIZE',
  watchAgain: 'REWIND',
  showNext: 'CLICK_NEXT',
  openLibrary: 'CLICK_MODAL',
};
