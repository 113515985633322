import React from 'react';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { styled } from '@mui/material/styles';
import { useLanguage } from 'src/context/LanguageContext';

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  '&:focus-visible': {
    outlineColor: theme.palette.primary.main,
  },
}));

interface StatusMessagesProps {
  status: string[];
}

const translations = {
  ariaLabel: {
    statusMessages: {
      en: 'Status messages',
      sv: 'Statusmeddelanden',
    },
  },
};

function StatusMessages({ status }: StatusMessagesProps) {
  const statusText = status.join('\n');
  const lang = useLanguage();

  return (
    <Box sx={{ width: '100%', marginTop: 2 }} data-testid="status-messages-container">
      <StyledTextareaAutosize
        minRows={3}
        sx={{
          backgroundColor: 'background.paper',
          resize: 'none',
          width: '100%',
          overflow: 'scroll',
          overflowX: 'auto',
          minHeight: '354px',
          maxHeight: '354px',
        }}
        value={statusText}
        readOnly
        aria-label={translations.ariaLabel.statusMessages[lang]}
        data-testid="status-textarea"
      />
    </Box>
  );
}

export default StatusMessages;
