import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { translations } from '../../../utils/translations';

import SimpleDialogTitle from '../../common/SimpleDialogTitle';
import SimpleDialogActions from '../../common/SimpleDialogAction';
import { EndScreenTable } from '../EndScreen/EndScreenTable';
import '../back-office.scss';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} style={{ transformOrigin: '15% 25% 0' }} />;
});

export default function VideoFunnelAddEndScreenDialog({
  open,
  handleClose,
  onConfirm,
  containerRef,
  basicOrgData,
  maxItems,
  dialogTitle,
}) {
  const lang = useLanguage();
  const { users, endScreens: endScreenContext } = useOutletContext();
  const [selectedItems, setSelectedItems] = useState([]);

  const [searchField, setSearchField] = useState('');
  const [allEndScreen, setAllEndScreens] = useState([
    ...(endScreenContext?.length > 0 ? endScreenContext : []),
  ]);

  useEffect(() => {
    setSelectedItems([]);
  }, []);

  const handleOnClose = (props) => {
    setSelectedItems([]);
    handleClose(props);
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      TransitionComponent={Transition}
      ref={containerRef}
      maxWidth="xl"
      PaperProps={{
        style: { borderRadius: '20px', minHeight: 'calc(100vh - 64px)' },
      }}
      fullWidth
    >
      <SimpleDialogTitle
        title={dialogTitle}
        searchField={searchField}
        setSearchField={setSearchField}
        handleOnClose={handleOnClose}
      />
      <DialogContent>
        <EndScreenTable
          searchField={searchField}
          allEndScreen={allEndScreen}
          setAllEndScreens={setAllEndScreens}
          checkboxSelection
          setSelectedEndScreens={(e) => {
            setSelectedItems(e);
          }}
        />
      </DialogContent>
      <SimpleDialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          {translations.cancel[lang]}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOnClose({ newEndScreens: selectedItems })}
          disabled={!selectedItems || selectedItems?.length === 0}
        >
          {(maxItems > 1 || !maxItems) && selectedItems?.length > 1
            ? translationsStoryline.videoFunnel.selectEndScreens[lang]
            : translationsStoryline.videoFunnel.selectEndScreen[lang]}
        </Button>
      </SimpleDialogActions>
    </Dialog>
  );
}

VideoFunnelAddEndScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  containerRef: PropTypes.object.isRequired,
  basicOrgData: PropTypes.object.isRequired,
  maxItems: PropTypes.number,
  dialogTitle: PropTypes.string.isRequired,
};
