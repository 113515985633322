import React, { useMemo, useState, forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import { gql, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';

import { SplitButtonOption } from 'src/components/common/SplitButton';
import { translations } from 'src/utils/translations';
import { useLanguage } from 'src/context/LanguageContext';
import { VideoCollectorDetailed } from '../../types';
import ReviewCard from './ReviewCard';
import filterValidVideoCards from '../Videos/filterVideoCards';
import ReviewFooter from './ReviewFooter';
import { VIDEO_COLLECTOR_FIELDS } from '../fragments';
import reviewTranslations from './ReviewTranslations';

export interface UpdateVideoCardsStatusByVideoCollectorResponse {
  updateVideoCardsStatusByVideoCollector: VideoCollectorDetailed;
}

type VideoCardStatus = 'APPROVED' | 'LIVE';

export const UPDATE_VIDEO_CARDS_STATUS_VIDEO_COLLECTOR_MUTATION = gql`
  ${VIDEO_COLLECTOR_FIELDS}
  mutation UpdateVideoCardsStatusByVideoCollector(
    $encodedVideoCollectorId: String!
    $encodedVideoCardIds: [String!]!
    $status: VideoCollectorVideoCardStatus!
  ) {
    updateVideoCardsStatusByVideoCollector(
      encodedVideoCollectorId: $encodedVideoCollectorId
      encodedVideoCardIds: $encodedVideoCardIds
      status: $status
    ) {
      ...VideoCollectorFields
    }
  }
`;

export interface ReviewLibraryProps {
  videoCollector: VideoCollectorDetailed;
  disabled: boolean;
  onChange: (videoCollector: Partial<VideoCollectorDetailed>) => void;
}
const ReviewLibrary = forwardRef(
  ({ videoCollector, disabled, onChange }: ReviewLibraryProps, ref) => {
    const theme = useTheme();
    const lang = useLanguage();
    const [alertMessage, setAlertMessage] = useState<string | null>(null);

    const [validVideoCards, processingVideoCards] = useMemo(() => {
      const validCards = filterValidVideoCards(videoCollector.videoCards, ['REVIEW']);
      const validCardIds = validCards.map((card) => card.id);

      // Assume all cards in REVIEW but not valid are still begin processed.
      const processingCards = videoCollector.videoCards.filter(
        (card) => card.status === 'REVIEW' && !validCardIds.includes(card.id),
      );

      return [validCards, processingCards];
    }, [videoCollector.videoCards]);

    const [selectedEncodedVideoIds, setSelectedVideoIds] = useState<string[]>([]);

    const [updatedVideoCollector, { loading }] =
      useMutation<UpdateVideoCardsStatusByVideoCollectorResponse>(
        UPDATE_VIDEO_CARDS_STATUS_VIDEO_COLLECTOR_MUTATION,
      );

    const anyDisabled = disabled || loading;

    const showAlertMessage = (message: string) => {
      setAlertMessage(message);
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
    };

    const handleSelectVideo = (encodedVideoId: string) => {
      setSelectedVideoIds((prev) => {
        if (prev.includes(encodedVideoId)) {
          return prev.filter((id) => id !== encodedVideoId);
        }
        return [...prev, encodedVideoId];
      });
    };

    const isSelected = (encodedVideoId: string) => {
      return selectedEncodedVideoIds.includes(encodedVideoId);
    };

    const updateVideoCardsStatus = (status: VideoCardStatus) => {
      updatedVideoCollector({
        variables: {
          encodedVideoCollectorId: videoCollector.encodedId,
          encodedVideoCardIds: selectedEncodedVideoIds,
          status,
        },
      })
        .then((response) => {
          if (response.errors) {
            showAlertMessage(
              `${reviewTranslations.errorUpdating[lang]}: ${response.errors[0].message}`,
            );
            return;
          }
          if (!response.data) {
            showAlertMessage(reviewTranslations.errorUpdating[lang]);
            return;
          }

          onChange(response.data.updateVideoCardsStatusByVideoCollector);
          setSelectedVideoIds([]);
        })
        .catch((error) => {
          showAlertMessage(`${reviewTranslations.errorUpdating[lang]}: ${error.message}`);
        });
    };

    const splitButtonOptions: SplitButtonOption[] = [
      {
        label: translations.videoCard.goLiveVideo[lang],
        onClick: () => {
          updateVideoCardsStatus('LIVE');
        },
        disabled,
      },
      {
        label: translations.videoCard.approve[lang],
        onClick: () => {
          updateVideoCardsStatus('APPROVED');
        },

        disabled,
      },
    ];

    const handleSelectAll = () => {
      if (selectedEncodedVideoIds.length === validVideoCards.length) {
        setSelectedVideoIds([]);
        return;
      }
      setSelectedVideoIds(validVideoCards.map((video) => video.encodedId));
    };

    return (
      <Grid
        item
        container
        spacing={3}
        role="grid"
        data-testid="review-library-grid-container"
        sx={{
          position: 'relative',
        }}
      >
        {alertMessage && !disabled && (
          <Alert
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 1000,
              left: '50%',
              mt: 4,
              transform: 'translateX(-50%)',
              backgroundColor: theme.palette.background.paper,
            }}
            elevation={1}
            severity="error"
          >
            {alertMessage}
          </Alert>
        )}
        {validVideoCards.map((video) => (
          <ReviewCard
            video={video}
            data-testid={`review-video-${video.id}`}
            key={video.id}
            isSelected={isSelected(video.encodedId)}
            onSelectVideo={handleSelectVideo}
            disabled={anyDisabled}
          />
        ))}
        <ReviewFooter
          ref={ref}
          disabled={anyDisabled}
          onSelectAllClicked={handleSelectAll}
          selectedEncodedVideoIds={selectedEncodedVideoIds}
          totalVideoCards={validVideoCards.length}
          processingVideoCards={processingVideoCards.length}
          splitButtonOptions={splitButtonOptions}
        />
      </Grid>
    );
  },
);

export default ReviewLibrary;
