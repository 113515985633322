import { useEffect, useState } from 'react';
import { useReactFlow, getConnectedEdges, useStore } from 'reactflow';

export default function connectableFn({ id, direction, sourceHandle }) {
  const idString = `${id}`;
  const edgesCount = useStore((store) => store.edges.length);

  const { getNode, getEdges } = useReactFlow();

  const [hasConnections, setHasConnections] = useState(false);

  useEffect(() => {
    setHasConnections(() => {
      const edges = getConnectedEdges([getNode(idString)], getEdges()).filter((e) => {
        return (
          e[direction] === idString && (sourceHandle ? e['sourceHandle'] === sourceHandle : true)
        );
      });
      return edges.length > 0;
    });
  }, [getNode, getEdges, idString, edgesCount]);

  return hasConnections;
}
