import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import translationsEndScreen from '../../../utils/translationsEndScreen';
import { storylineStatuses } from '../../../utils/utils';

function EndScreenColumns({
  hoveredRow,
  setEditEndScreenDialog,
  setDeleteEndScreenDialog,
  checkboxSelection = false,
}) {
  return [
    {
      field: 'meta.title',
      headerName: 'Title',
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: false,
      valueGetter: (value, row) => {
        return row.meta.title;
      },
    },
    {
      field: 'meta.endScreenType',
      headerName: 'Type',
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: false,
      valueGetter: (value, row) => {
        return translationsEndScreen[row.meta.endScreenType].en;
      },
    },
    ...(!checkboxSelection
      ? [
          {
            field: 'status',
            headerName: 'Active',
            minWidth: 200,
            disableColumnMenu: true,
            renderCell: (params) => {
              return params.row.status === storylineStatuses.LIVE ? (
                <CheckIcon sx={{ color: '#74bf66' }} />
              ) : (
                <CloseIcon sx={{ color: '#d76767' }} />
              );
            },
          },
        ]
      : []),
    ...(hoveredRow !== undefined && setEditEndScreenDialog && setDeleteEndScreenDialog
      ? [
          {
            isRowSelectable: false,
            field: 'actions',
            headerName: '',
            minWidth: 100,
            flex: 0,
            renderCell: (params) => {
              if (hoveredRow && hoveredRow === params.id && params.row.id >= 0) {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {setEditEndScreenDialog && (
                      <IconButton
                        onClick={() => {
                          setEditEndScreenDialog(params.row);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {/* {setDeleteEndScreenDialog && (
                      <IconButton
                        onClick={() => {
                          setDeleteEndScreenDialog(params.row);
                        }}
                      >
                        <img src="/images/icons/bin-icon.svg" alt="" />
                      </IconButton>
                    )} */}
                  </Box>
                );
              }
              return null;
            },
            sortable: false,
            disableColumnMenu: true,
          },
        ]
      : []),
  ];
}

export default EndScreenColumns;

EndScreenColumns.propTypes = {
  hoveredRow: PropTypes.number,
  setEditEndScreenDialog: PropTypes.func,
  setDeleteEndScreenDialog: PropTypes.func,
};
