import React, { ElementType, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { NavLink, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ThemeContext from '../../../utils/ThemeContext';

interface AvailableTab {
  value: string;
  label: string;
  disabled: boolean;
  path?: string;
  number?: number;
}

type SetActiveTabFunc = (value: string) => void;

interface TabSelectorProps {
  availableTabs: AvailableTab[];
  setActiveTab: SetActiveTabFunc | undefined;
  activeTab: string | undefined;
}

function TabSelector({
  availableTabs,
  setActiveTab = undefined,
  activeTab = undefined,
}: TabSelectorProps) {
  const location = useLocation();

  const theme = useContext(ThemeContext);

  const [activeTabValue, setActiveTabValue] = useState(!setActiveTab && !activeTab ? 0 : null);

  useEffect(() => {
    if (!setActiveTab) {
      const value = availableTabs.findIndex(
        (tab) => tab.path && location.pathname.endsWith(tab.path),
      );

      setActiveTabValue(value);
    }
  }, [location, availableTabs, setActiveTab]);

  return (
    <Tabs
      value={
        setActiveTab && activeTab
          ? availableTabs.findIndex((tab) => tab.value === activeTab)
          : activeTabValue
      }
      variant="scrollable"
      TabIndicatorProps={{
        style: {
          height: '100%',
          borderRadius: '30px',
          backgroundColor: theme.palette.tertiary.main,
        },
      }}
      sx={{
        overflow: 'visible',
        background: theme.palette.background.paper,
        maxWidth: '70vw',
        borderRadius: '30px',
        '& .MuiTab-root': {
          textTransform: 'none',
          fontSize: '15px',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
          padding: '10px 24px',
          overflow: 'visible',
          '&.Mui-selected': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.tertiary.main,
          },
          '&:hover': {
            backgroundColor: theme.palette.divider,
            borderRadius: '30px',
          },
          '&:first-of-type': {
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px',
          },
          '&:last-of-type': {
            borderTopRightRadius: '30px',
            borderBottomRightRadius: '30px',
          },
        },
        '& .MuiButtonBase-root': {
          overflow: 'visible',
          '&.Mui-selected': {
            color: theme.palette.text.primary,
          },
        },
      }}
    >
      {availableTabs?.map((tab) => (
        <Tab
          key={tab.value}
          component={!setActiveTab ? NavLink : (null as unknown as ElementType)}
          to={!setActiveTab ? tab?.path : null}
          onClick={setActiveTab ? () => setActiveTab(tab.value) : undefined}
          label={
            <>
              <Typography
                variant="labelLarge"
                sx={{
                  textDecoration: tab.disabled ? 'line-through' : 'none',
                  color: tab.disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                }}
              >
                {tab.label}
              </Typography>
              {tab?.number && tab.number > 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    backgroundColor: 'error.main',
                    borderRadius: '50%',
                    padding: '0px',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                  }}
                >
                  <Typography variant="bodyMediumBold" color="text.light">
                    {tab.number}
                  </Typography>
                </Box>
              )}
            </>
          }
          disableRipple
          disabled={tab.disabled}
          sx={{
            textDecoration: tab.disabled ? 'line-through' : 'none',
          }}
        />
      ))}
    </Tabs>
  );
}

export default TabSelector;
