import React, { useState, useEffect, useContext, useMemo } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@mui/system';
import { CardMedia, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useLanguage } from 'src/context/LanguageContext';
import ThemeContext from '../../../../utils/ThemeContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';

import { getStaticThumbnail } from '../../../../utils/video-card/utils';
import { sumAndFormatTotalTime, sumTotalImpressions } from './dataUtils';

function BestPerformance({ topViewTimeVideoCardData = null, topImpressionsVideoCardData = null }) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const displayData = useMemo(() => {
    const data = [];

    // Use helper functions to process data
    if (topViewTimeVideoCardData?.stats?.playback?.timePlayed) {
      data.push({
        ...topViewTimeVideoCardData,
        metric: {
          metricName: translationsAnalytics.bestPerformance.metricName.totalViewTime[lang],
          value: sumAndFormatTotalTime(topViewTimeVideoCardData.stats.playback.timePlayed, lang),
        },
      });

      setLoading(false);
      setError('');
    }

    if (topImpressionsVideoCardData?.stats?.playback?.impression) {
      data.push({
        ...topImpressionsVideoCardData,
        metric: {
          metricName: translationsAnalytics.bestPerformance.metricName.totalImpressions[lang],
          value: sumTotalImpressions(topImpressionsVideoCardData.stats.playback.impression),
          unit: '',
        },
      });

      setLoading(false);
      setError('');
    }

    return data;
  }, [topViewTimeVideoCardData, lang, topImpressionsVideoCardData]);

  useEffect(() => {
    setLoading(true);
    setError('');

    const timer = setTimeout(() => {
      if (!topViewTimeVideoCardData) {
        setError(translationsAnalytics.totalImpressionsCard.error?.[lang]);
      }
      setLoading(false);
    }, 10000);

    if (topViewTimeVideoCardData) {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [topViewTimeVideoCardData, lang]);

  return (
    <Card
      sx={{
        display: 'flex',
        boxShadow: 'none',
        borderRadius: '8px',
        maxHeight: '284px',
        position: 'relative',
      }}
    >
      {(loading || error) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            flexDirection: 'column',
          }}
        >
          {loading && <CircularProgress size={50} />}
          <Typography variant="titleLarge" gutterBottom sx={{ mt: 2 }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Carousel
          indicators={false}
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
              width: '100%',
            },
          }}
          autoPlay
        >
          {displayData?.map((videoData) => (
            <Box key={`${videoData.encodedId}-box`}>
              <Stack direction="row" spacing={2}>
                <Box
                  key={`${videoData.encodedId}-box-video`}
                  sx={{
                    position: 'relative',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    flex: '0 0 auto',
                    height: '284px', // Set a fixed height for the box
                  }}
                >
                  <Typography
                    key={`${videoData.encodedId}-video-title`}
                    variant="titleSmall"
                    color="primary.contrastText"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
                      p: 1,
                      borderTopRightRadius: '8px',
                    }}
                  >
                    {videoData?.question && videoData?.question[lang]
                      ? videoData?.question[lang]
                      : ' '}
                  </Typography>
                  <CardMedia
                    key={`${videoData.encodedId}-video-thumbnail`}
                    image={getStaticThumbnail(videoData, 'ntsc')}
                    component="img"
                    sx={{
                      borderRadius: '8px',
                      objectFit: 'cover', // Cover the area of the box without losing the aspect ratio
                      width: '100%', // Make the image take the full width of the box
                      height: '100%', // Make the image take the full height of the box
                    }}
                  />
                  <Typography
                    variant="labelSmall"
                    key={`${videoData.encodedId}-video-actor`}
                    color="primary.contrastText"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
                      p: 1,
                      borderBottomRightRadius: '8px',
                    }}
                  >
                    {videoData?.recipient?.name ?? videoData?.actorName ?? ''}
                  </Typography>
                </Box>

                <Box
                  key={`${videoData.encodedId}-box-metrics`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Box
                    key={videoData.metric.metricName}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Typography
                      variant="titleLarge"
                      sx={{
                        mb: 8,
                        mt: 3,
                      }}
                    >
                      {translationsAnalytics.bestPerformance?.title?.[lang]}
                    </Typography>

                    <Typography variant="titleLarge" display="block">
                      {videoData.metric.metricName}
                    </Typography>

                    <Typography variant="titleLargeBold">
                      {videoData.metric.value}
                      {videoData.metric.unit}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Card>
  );
}

BestPerformance.propTypes = {
  topViewTimeVideoCardData: PropTypes.shape({
    stats: PropTypes.shape({
      playback: PropTypes.shape({
        timePlayed: PropTypes.arrayOf(
          PropTypes.shape({
            count: PropTypes.number,
          }),
        ),
      }),
    }),
  }),
  topImpressionsVideoCardData: PropTypes.shape({
    stats: PropTypes.shape({
      playback: PropTypes.shape({
        impression: PropTypes.arrayOf(
          PropTypes.shape({
            count: PropTypes.number,
          }),
        ),
      }),
    }),
  }),
};

export default BestPerformance;
