import React from 'react';
import LazyLoad from 'react-lazy-load';
import _ from 'lodash';
import Vimeo from '@u-wave/react-vimeo';
import { gql } from '@apollo/client/index';

import { useLanguage } from 'src/context/LanguageContext';
import { gqClient } from '../../utils/graphQL';
import { getVimeoVideoIdFromLink, shadeHexColorToRgb, hexToRgb } from '../../utils/utils';

// createVideoStatsEvent
const sendVideoStats = ({
  encodedOrgId,
  videoCardId,
  sessionId,
  statsType,
  viewContext,
  matches = [],
}) => {
  const matchesString = matches
    ?.map((match) =>
      [
        `{`,
        [
          `expressionType:"${match.expressionType}"`,
          `expressionValue:"${match.expressionValue}"`,
          match.storylineId ? `storylineId:${match.storylineId}` : '',
          match.videoFunnelId ? `videoFunnelId:${match.videoFunnelId}` : '',
          match.override ? `override:true` : '',
        ]
          ?.filter((o) => o !== '')
          ?.join(','),
        `}`,
      ]
        ?.filter((o) => o !== '')
        ?.join(''),
    )
    ?.filter((o) => o !== '')
    ?.join(',');

  const url = window.location.href;

  return gqClient.mutate({
    mutation: gql`
        mutation {
          createVideoStatsEvent(input: {
                                        encodedOrgId: "${encodedOrgId}",
                                        sessionId: "${sessionId}",
                                        videoCardId: ${videoCardId},
                                        context: "${viewContext}",
                                        statsType: "${statsType}",
                                        ${matches?.length > 0 ? `matches: [${matchesString}],` : ''}
                                        url: "${url}"
                                        }){
            statusMessage
          }
        }
    `,
  });
};

function VideoCard(props) {
  const lang = useLanguage();

  const videoData = props.data;
  const { basicOrgData } = props;
  const doNotTrack = props.basicOrgData.doNotTrack ? props.basicOrgData.doNotTrack : false;
  const { sessionId } = props;
  const tags = videoData.tags.map((t) => {
    return (
      <span
        className="tag"
        key={t.id}
        style={{
          background: basicOrgData.style.textColor || '#111111',
          color: basicOrgData.style.backgroundColor || '#ffffff',
        }}
      >
        {t.name[lang]}
      </span>
    );
  });
  const onLoaded = () => {
    if (videoData.id && sessionId && basicOrgData.encodedId) {
      sendVideoStats({
        encodedOrgId: basicOrgData.encodedId,
        videoCardId: videoData.id,
        sessionId,
        statsType: 'IMPRESSION',
        viewContext: props.viewContext,
      });
    }
  };

  const onPlay = () => {
    if (videoData.id && sessionId && basicOrgData.encodedId) {
      sendVideoStats({
        encodedOrgId: basicOrgData.encodedId,
        videoCardId: videoData.id,
        sessionId,
        statsType: 'CLICK_PLAY',
        viewContext: props.viewContext,
      });

      sendVideoStats({
        encodedOrgId: basicOrgData.encodedId,
        videoCardId: videoData.id,
        sessionId,
        statsType: 'PLAY',
        viewContext: props.viewContext,
      });
    }
  };
  const onPause = (status) => {
    if (status.percent > 0 && status.percent < 1) {
      if (videoData.id && sessionId && basicOrgData.encodedId) {
        sendVideoStats({
          encodedOrgId: basicOrgData.encodedId,
          videoCardId: videoData.id,
          sessionId,
          statsType: 'CLICK_PAUSE',
          viewContext: props.viewContext,
        });
        sendVideoStats({
          encodedOrgId: basicOrgData.encodedId,
          videoCardId: videoData.id,
          sessionId,
          statsType: 'PAUSE',
          viewContext: props.viewContext,
        });
      }
    }
  };
  const onEnd = () => {
    if (videoData.id && sessionId && basicOrgData.encodedId) {
      sendVideoStats({
        encodedOrgId: basicOrgData.encodedId,
        videoCardId: videoData.id,
        sessionId,
        statsType: 'END',
        viewContext: props.viewContext,
      });
    }
  };

  if (videoData.videoLink && lang in videoData.videoLink && videoData.videoLink[lang]) {
    const vimeoVideoId = getVimeoVideoIdFromLink(videoData.videoLink[lang]);

    const bgGradient =
      basicOrgData.style && basicOrgData.style.backgroundColor
        ? `linear-gradient(310deg, ${hexToRgb(
            basicOrgData.style.backgroundColor,
          )} 0%, ${shadeHexColorToRgb(basicOrgData.style.backgroundColor, 30)} 100%)`
        : '';

    return (
      <div className="video-card">
        <div className="title">{videoData.question[lang]}</div>
        <div>
          <LazyLoad offset={300} width={224} height={400}>
            <Vimeo
              video={vimeoVideoId}
              width="224"
              height="400"
              color={_.trimStart(basicOrgData.style.btnColor, '#')}
              onLoaded={onLoaded}
              onPlay={onPlay}
              onPause={onPause}
              onEnd={onEnd}
              dnt={doNotTrack}
            />
          </LazyLoad>
        </div>
        <div className="actor">
          <span className="actor-name">
            {videoData?.recipient?.name ?? videoData?.actorName ?? ''}
          </span>
          <span className="sep">|</span>
          <span className="actor-title">
            {videoData?.recipient?.title ?? videoData?.actorTitle[lang] ?? ''}
          </span>
        </div>
      </div>
    );
  }
  return null;
}

export default VideoCard;
