import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useBackOfficeStatsApolloClient } from 'src/context/ApolloClientContext';
import { useMemo } from 'react';
import {
  StatsByTypeTotalResult,
  StatsConfig,
  generateStatsQueryPart,
} from '../../Analytics/Overview/analyticsOverviewApi';

interface FetchVideoCardAnalyticsDataParams {
  videoCardId: number;
  encodedOrgId: string;
}

interface VideoCardAnalyticsDataTotal {
  totalStats: StatsByTypeTotalResult;
}

export class VideoStatsApi {
  #backOfficeStatsClient: ApolloClient<NormalizedCacheObject>;

  constructor(backOfficeStatsClient: ApolloClient<NormalizedCacheObject>) {
    this.#backOfficeStatsClient = backOfficeStatsClient;
  }

  async fetchVideoCardAnalyticsData({
    videoCardId,
    encodedOrgId,
  }: FetchVideoCardAnalyticsDataParams): Promise<VideoCardAnalyticsDataTotal | null> {
    const statsConfig: StatsConfig = {
      playback: ['impression', 'timePlayed'],
      interactions: ['total'],
    };
    const statsPart = generateStatsQueryPart(statsConfig, false);
    const query = gql`
      query getVideoCardAnalyticsDataTotal($encodedOrgId: String!, $videoCardId: Int!) {
        videoCardAnalyticsDataTotal(encodedOrgId: $encodedOrgId, videoCardId: $videoCardId) {
          totalStats {
            ${statsPart}
          }
        }
      }
    `;

    const result = await this.#backOfficeStatsClient.query({
      query,
      variables: { encodedOrgId, videoCardId },
    });

    return result.data.videoCardAnalyticsDataTotal[0];
  }
}

export const useVideoStatsApi = (): VideoStatsApi => {
  const backOfficeStatsClient = useBackOfficeStatsApolloClient();
  return useMemo(() => new VideoStatsApi(backOfficeStatsClient), [backOfficeStatsClient]);
};
