import React, { useState, useEffect, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Outlet, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import { ProductFruits } from 'react-product-fruits';

import { LanguageProvider, useLanguage } from 'src/context/LanguageContext';
import { CurrentUserProvider } from 'src/context/CurrentUserContext';

import { frontendPath } from '../../utils/environment';
import { useActions } from './actions';

import Navigator from './Navigator';
import ThemeContext from '../../utils/ThemeContext';
import { useDashboardApi } from './Dashboard/DashboardApi';

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

function Template() {
  // TODO: Make backoffice share language with rest of app. For now this is not
  // possible since backOffice assumes orgLang determines language.
  const appLang = useLanguage();
  const dashboardApi = useDashboardApi();
  const [lang, setLang] = useState(appLang);
  const setSelectedLanguage = setLang;
  const setDetectedLanguage = setLang;

  const actions = useActions();

  const navigate = useNavigate();
  const params = useParams();
  const { orgnameslug: orgNameSlug } = params;
  const [basicOrgData, setBasicOrgData] = useState(null);
  const [videoCards, setVideoCards] = useState([]);
  const [videoRequests, setVideoRequests] = useState([]);

  const suggestedQuestions = useRef([]);
  const orgRecipients = useRef([]);
  const storylineObjects = useRef([]);
  const videoFunnelObjects = useRef([]);

  const users = useRef([]);
  const tagCategories = useRef([]);
  const signedInUser = useRef([]);
  const [user, setUser] = useState(null);

  const dashboardStats = useRef({});
  const endScreens = useRef([]);
  const [error, setError] = useState(null);
  const theme = useContext(ThemeContext);
  const [showNavBar, setShowNavBar] = useState(true);

  useEffect(() => {
    if (orgNameSlug) {
      actions
        .getOrganizationData(orgNameSlug)
        .then((result) => {
          setBasicOrgData(result.data.organization);
          setDetectedLanguage(result.data.organization.lang);
        })
        .catch((err) => {
          navigate(`/${orgNameSlug}/login`);
        });
    } else {
      navigate(`/${orgNameSlug}/login`);
    }
  }, [actions, orgNameSlug]);

  useEffect(() => {
    if (basicOrgData?.encodedId == null) {
      return;
    }
    dashboardApi
      .getUserData(basicOrgData.encodedId)
      .then((res) => {
        signedInUser.current = res.data.me;
        setUser(res.data.me);
      })
      .catch((err) => {});
  }, [dashboardApi, basicOrgData?.encodedId]);

  const isLocalhost = window.location.href.includes('localhost');
  const isStaging = window.location.href.includes('app.staging');

  const environmentPrefix = `${isLocalhost ? 'localhost-' : ''}${isStaging ? 'staging-' : ''}`;

  if (error) {
    return <ErrorComponent errorMessage={error} />;
  }
  if (!basicOrgData || !lang || !user) {
    return <div />;
  }

  const userProductTour = `user:${environmentPrefix}${user.encodedId}`;
  const organizationGroupID = `org:${environmentPrefix}${basicOrgData.encodedId}`;

  const setVideoRequestState = (updatedVideoRequest) => {
    setVideoRequests((prevVideoRequests) => {
      const updatedVideoRequests = prevVideoRequests.map((videoRequest) => {
        if (Number(videoRequest.id) === Number(updatedVideoRequest.id)) {
          return {
            ...removeEmpty(videoRequest),
            ...removeEmpty(updatedVideoRequest),
            lastPullFromDatabase: updatedVideoRequest?.lastPullFromDatabase,
          };
        }
        return videoRequest;
      });
      return updatedVideoRequests?.sort((a, b) => Number(b.id) - Number(a.id));
    });
  };

  const setVideoRequestsState = (updatedVideoRequests) => {
    setVideoRequests((prevVideoRequests) => {
      const updatedVideoRequestsState = updatedVideoRequests.map((updatedVideoRequest) => {
        const prevVideoRequest = prevVideoRequests.find(
          (prevRequest) => Number(prevRequest.id) === Number(updatedVideoRequest.id),
        );

        if (prevVideoRequest) {
          return { ...removeEmpty(updatedVideoRequest), ...removeEmpty(prevVideoRequest) };
        }
        return updatedVideoRequest;
      });
      return updatedVideoRequestsState?.sort((a, b) => Number(b.id) - Number(a.id));
    });
  };

  const setVideoCardsState = (updatedVideoCards) => {
    setVideoCards((prevVideoCards) => {
      const updatedVideoCardsState = updatedVideoCards.map((updatedVideoCard) => {
        const prevVideoCard = prevVideoCards.find(
          (prevCard) => Number(prevCard.id) === Number(updatedVideoCard.id),
        );

        if (prevVideoCard) {
          return { ...removeEmpty(updatedVideoCard), ...removeEmpty(prevVideoCard) };
        }
        return updatedVideoCard;
      });
      return updatedVideoCardsState?.sort((a, b) => Number(b.id) - Number(a.id));
    });
  };

  const setVideoCardState = (updatedVideoCard) => {
    setVideoCards((prevVideoCards) => {
      const updatedVideoCards = prevVideoCards.map((videoCard) => {
        if (Number(videoCard.id) === Number(updatedVideoCard.id)) {
          return {
            ...removeEmpty(videoCard),
            ...removeEmpty(updatedVideoCard),
            lastPullFromDatabase: updatedVideoCard?.lastPullFromDatabase,
          };
        }
        return videoCard;
      });
      return updatedVideoCards?.sort((a, b) => Number(b.id) - Number(a.id));
    });
  };

  const template = (
    <div
      className="template back-office"
      style={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <ProductFruits
        workspaceCode="NAJM1TZWjCR1znu2"
        language={lang}
        user={{
          username: userProductTour,
          props: {
            orgNameSlug: basicOrgData.orgNameSlug,
          },
          group: {
            groupId: organizationGroupID,
          },
        }}
      />
      {!frontendPath.includes('localhost') && (
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/25100100.js"
          />
        </Helmet>
      )}

      <header
        className="header"
        id="navigation-header-bar-left-side"
        style={{ ...(!showNavBar && { display: 'none' }) }}
      >
        <div className="header-inner">
          <div className="company-name" target="_blank">
            <NavLink to={`/${basicOrgData.orgNameSlug}/backoffice`} className="logo-link">
              <img src="/images/lifeinside-logo.svg" alt="" className="logo" />
            </NavLink>
          </div>
          <div className="menu primary-menu">
            <Navigator basicOrgData={basicOrgData} setShowNavBar={setShowNavBar} />
          </div>
          <div className="menu secondary-menu" />
        </div>
      </header>

      <div
        className="main"
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Outlet
          context={{
            basicOrgData,
            videoCards,
            setVideoCardsState,
            setVideoCardState,
            suggestedQuestions,
            orgRecipients,
            users,
            storylineObjects,
            videoFunnelObjects,
            videoRequests,
            setVideoRequestsState,
            setVideoRequestState,
            tagCategories,
            signedInUser,
            dashboardStats,
            endScreens,
            theme,
          }}
        />
      </div>
    </div>
  );

  return (
    <CurrentUserProvider currentUser={user}>
      <LanguageProvider
        setDetectedLanguage={setDetectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        language={lang}
      >
        {template}
      </LanguageProvider>
    </CurrentUserProvider>
  );
}

export default Template;

function ErrorComponent(props) {
  return (
    <div className="error-wrapper">
      <Typography variant="headlineSmall">Something went wrong</Typography>
      {props.errorMessage}
    </div>
  );
}
