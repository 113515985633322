import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ThemeContext from 'src/utils/ThemeContext';
import { Stack, TextField } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useLanguage } from 'src/context/LanguageContext';

import { ChatBoxProps } from './types';

export const translations = {
  typeAMessage: {
    en: 'Type a message',
    sv: 'Skriv ett meddelande',
  },
  assistantIsThinking: {
    en: 'Assistant is thinking...',
    sv: 'Assistenten tänker...',
  },
  sendMessage: {
    en: 'Send message',
    sv: 'Skicka meddelande',
  },
};

export function ChatBox({
  chat,
  textToVideoLoading,
  isLoading,
  disabled,
  userTextPrompt,
  setUserTextPrompt,
  handleTalk,
  videoGridItemRef = null,
}: ChatBoxProps) {
  const lang = useLanguage();
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useContext(ThemeContext);

  const [maxHeight, setChatBoxHeight] = useState<string | null>(
    chat.length > 0 && videoGridItemRef?.current?.offsetHeight
      ? `${videoGridItemRef.current.offsetHeight}px`
      : null,
  );

  const scrollToBottom = useCallback(() => {
    if (chatEndRef?.current?.scrollIntoView) {
      chatEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [chatEndRef]);

  useEffect(() => {
    scrollToBottom();
  }, [chat, scrollToBottom]);

  useEffect(() => {
    const updateChatBoxHeight = () => {
      if (videoGridItemRef?.current) {
        setChatBoxHeight(`${videoGridItemRef.current.offsetHeight}px`);
      }
    };

    updateChatBoxHeight();

    window.addEventListener('resize', updateChatBoxHeight);
    window.addEventListener('orientationchange', updateChatBoxHeight);
    window.addEventListener('load', updateChatBoxHeight);
    window.addEventListener('scroll', updateChatBoxHeight);

    return () => {
      window.removeEventListener('resize', updateChatBoxHeight);
      window.removeEventListener('orientationchange', updateChatBoxHeight);
      window.removeEventListener('load', updateChatBoxHeight);
      window.removeEventListener('scroll', updateChatBoxHeight);
    };
  }, [videoGridItemRef]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      data-testid="chat-box"
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        overflow: 'hidden',
        ...(chat.length > 0 && { maxHeight }),
        minHeight: maxHeight,
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          width: '100%',
          height: '100%',
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
        role="log"
        aria-live="polite"
        aria-relevant="additions"
        data-testid="chat-messages"
      >
        {chat.map((msgObj) => (
          <Box
            key={`${msgObj.role}-${msgObj.index}-${msgObj.timestamp}`}
            data-testid={`chat-message-${msgObj.index}`}
            sx={{
              alignSelf: msgObj.role === 'user' ? 'flex-end' : 'flex-start',
              background:
                msgObj.role === 'user' ? theme.palette.tertiary.light : theme.palette.softGray80,
              color: theme.palette.text.primary,
              p: 1,
              ml: msgObj.role === 'user' ? 2 : 0,
              mr: msgObj.role === 'user' ? 0 : 2,
              borderRadius: 2,
            }}
            role="article"
            aria-labelledby={`message-${msgObj.index}`}
          >
            <Typography
              id={`message-${msgObj.index}`}
              variant="bodyMedium"
              sx={{ color: theme.palette.text.primary }}
            >
              {msgObj.message}
            </Typography>
          </Box>
        ))}
        {textToVideoLoading && (
          <Box
            sx={{
              alignSelf: 'flex-start',
              display: 'flex',
              alignItems: 'center',
              p: 1,
            }}
            data-testid="loading-indicator"
            aria-live="assertive"
          >
            <CircularProgress size={14} />
            <Typography variant="bodyMedium" sx={{ ml: 2, color: theme.palette.text.secondary }}>
              {translations.assistantIsThinking[lang]}
            </Typography>
          </Box>
        )}

        <div ref={chatEndRef} />
      </Box>
      <Box
        sx={{
          backgroundColor: 'divider',
          px: 1.5,
          py: 0.5,
        }}
        data-testid="chat-input-container"
      >
        <TextField
          id="outlined-basic"
          placeholder={translations.typeAMessage[lang]}
          variant="outlined"
          fullWidth
          value={userTextPrompt}
          onChange={(e) => setUserTextPrompt(e.target.value)}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 100,
            '& .MuiOutlinedInput-root': {
              borderRadius: 100,
              pr: 1,
            },
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleTalk('user');
            }
          }}
          disabled={isLoading || disabled}
          InputProps={{
            'aria-label': translations.typeAMessage[lang],
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={translations.sendMessage[lang]}
                  onClick={() => handleTalk('user')}
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.contrastText,
                    p: 0.5,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.dark,
                    },
                    '&:disabled': {
                      backgroundColor: theme.palette.secondary.light,
                      color: theme.palette.text.light,
                      opacity: 0.3,
                    },
                  }}
                  disabled={isLoading || disabled || !userTextPrompt.trim()}
                  data-testid="send-button"
                >
                  <SendRoundedIcon
                    fontSize="small"
                    sx={{
                      rotate: '-90deg',
                      pl: 0.2,
                      color: theme.palette.text.light,
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          data-testid="chat-input"
        />
      </Box>
    </Stack>
  );
}

export default ChatBox;
