import React, { useState, useMemo, useCallback } from 'react';
import { Backdrop, Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import TablePagination from 'src/components/common/TablePagination';
import StoryRow from './StoryRow';
import StoriesTableHead from './StoriesTableHead';
import { EnhancedStoryType, Order, StoriesProps } from './types';
import useFetchStories from './useFetchStories';

function Stories({
  lang,
  encodedOrgId,
  selectedDuration,
  dates = { startDate: null, endDate: null },
  storiesData,
  setStoriesData,
}: StoriesProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof EnhancedStoryType>('totalImpressions');

  const { loading, error } = useFetchStories(encodedOrgId, selectedDuration, dates, setStoriesData);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof EnhancedStoryType) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const sortedData = useMemo(() => {
    if (!storiesData) return [];

    return [...storiesData].sort((a, b) => {
      const aValue = orderBy === 'title' ? a?.title ?? '' : a[orderBy] ?? 0;
      const bValue = orderBy === 'title' ? b?.title ?? '' : b[orderBy] ?? 0;

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [order, orderBy, storiesData]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedData, page, rowsPerPage]);

  if (error || !storiesData || storiesData.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={{ p: 0, pt: 4, position: 'relative' }} data-testid="stories-box">
      <Backdrop
        open={loading}
        style={{ opacity: 0.2 }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      />
      {loading && (
        <Box
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <LoadingIndicator backgroundColor="transparent" />
        </Box>
      )}
      <Paper elevation={0} data-testid="stories-paper" key="stories-paper">
        <TableContainer data-testid="stories-table-container">
          <Table aria-label="stories table" data-testid="stories-table">
            <StoriesTableHead
              order={order}
              orderBy={orderBy as keyof EnhancedStoryType}
              onRequestSort={handleRequestSort}
              lang={lang}
            />
            <TableBody>
              {paginatedData.map((story) => (
                <StoryRow key={`${story.id}`} story={story} lang={lang} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          count={storiesData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          lang={lang}
        />
      </Paper>
    </Box>
  );
}

export default Stories;
