import React, { useState, useRef, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useMutation } from '@apollo/client';

import {
  CreateSessionResponse,
  StartSessionResponse,
  SubmitIceCandidateResponse,
  RepeatTextResponse,
  TextToVideoResponse,
  CloseSessionResponse,
  SessionData,
  ChatMessage,
  SDP,
  Role,
  Organization,
  SaveSettingsResponse,
} from './types';
import {
  CREATE_SESSION_MUTATION,
  CREATE_SESSION_ORG_MUTATION,
  START_SESSION_MUTATION,
  START_SESSION_ORG_MUTATION,
  SUBMIT_ICE_CANDIDATE_MUTATION,
  SUBMIT_ICE_CANDIDATE_ORG_MUTATION,
  REPEAT_TEXT_MUTATION,
  REPEAT_TEXT_ORG_MUTATION,
  TEXT_TO_VIDEO_MUTATION,
  TEXT_TO_VIDEO_ORG_MUTATION,
  CLOSE_SESSION_MUTATION,
  CLOSE_SESSION_ORG_MUTATION,
  SAVE_SETTINGS_MUTATION,
  SAVE_SETTINGS_ORG_MUTATION,
} from './mutations';
import ChatBox from './ChatBox';
import VideoContainer from './VideoContainer';
import SettingsPanel from './SettingsPanel';

interface ConversationalAvatarProps {
  organization?: Organization;
  assistantId?: string;
  avatarName?: string;
  voiceId?: string;
  avatarPosterUrl: string;
  superAdmin: boolean;
}

function ConversationalAvatar({
  organization = undefined,
  assistantId = undefined,
  avatarName = undefined,
  voiceId = undefined,
  superAdmin,
  avatarPosterUrl,
}: ConversationalAvatarProps) {
  const [avatarNameState, setAvatarNameState] = useState<string>(avatarName || '');
  const [voiceIdState, setVoiceIdState] = useState<string>(voiceId || '');
  const [assistantIdState, setAssistantIdState] = useState<string>(assistantId || '');
  const [avatarPosterUrlState, setAvatarPosterUrlState] = useState<string>(avatarPosterUrl || '');

  const [status, setStatus] = useState<string[]>([]);
  const [userTextPrompt, setUserTextPrompt] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [userSessionId, setUserSessionId] = useState<string>('');
  const [chat, setChat] = useState<ChatMessage[]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoGridItemRef = useRef<HTMLDivElement>(null);
  const peerConnection = useRef<RTCPeerConnection | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [iceConnectionState, setIceConnectionState] = useState<string>('');
  const [isStreamStarted, setIsStreamStarted] = useState<boolean>(false);
  const [isIceCandidatesSubmitted, setIsIceCandidatesSubmitted] = useState<boolean>(false);
  const [pendingIceCandidates, setPendingIceCandidates] = useState<number>(0);
  const [isFirstRepeatSubmitted, setIsFirstRepeatSubmitted] = useState<boolean>(false); // New state
  const submittedCandidates = useRef<Set<string>>(new Set());

  const [createSession, { loading: createSLoading }] = useMutation<CreateSessionResponse>(
    !organization ? CREATE_SESSION_MUTATION : CREATE_SESSION_ORG_MUTATION,
  );
  const [startSession, { loading: startLoading }] = useMutation<StartSessionResponse>(
    !organization ? START_SESSION_MUTATION : START_SESSION_ORG_MUTATION,
  );
  const [submitIceCandidate, { loading: submitLoading }] = useMutation<SubmitIceCandidateResponse>(
    !organization ? SUBMIT_ICE_CANDIDATE_MUTATION : SUBMIT_ICE_CANDIDATE_ORG_MUTATION,
  );
  const [repeatText, { loading: repeatLoading }] = useMutation<RepeatTextResponse>(
    !organization ? REPEAT_TEXT_MUTATION : REPEAT_TEXT_ORG_MUTATION,
  );
  const [textToVideo, { loading: textToVideoLoading }] = useMutation<TextToVideoResponse>(
    !organization ? TEXT_TO_VIDEO_MUTATION : TEXT_TO_VIDEO_ORG_MUTATION,
  );
  const [closeSession, { loading: closeLoading }] = useMutation<CloseSessionResponse>(
    !organization ? CLOSE_SESSION_MUTATION : CLOSE_SESSION_ORG_MUTATION,
  );

  const [saveSettings, { loading: saveSettingsLoading }] = useMutation<SaveSettingsResponse>(
    !organization ? SAVE_SETTINGS_MUTATION : SAVE_SETTINGS_ORG_MUTATION,
  );

  const [sessionInfo, setSessionInfo] = useState<SessionData | null>(null);
  const [openAiThreadId, setOpenAiThreadId] = useState<string | null>(null);

  const loading =
    createSLoading ||
    startLoading ||
    submitLoading ||
    repeatLoading ||
    textToVideoLoading ||
    closeLoading ||
    saveSettingsLoading;

  useEffect(() => {
    return () => {
      if (peerConnection.current) {
        peerConnection.current.close();
        peerConnection.current = null;
      }
    };
  }, []);

  const updateStatus = (msg: string) => setStatus((prev) => [msg, ...prev]);

  const sendMessage = (newMsg: string, role: Role) => {
    setChat((prevChat) => [
      ...prevChat,
      { message: newMsg, role, index: prevChat.length, timestamp: Date.now() },
    ]);
    setUserTextPrompt('');
  };

  const handleTalk = useCallback(
    async (role: Role) => {
      try {
        if (role === 'user' && userTextPrompt.trim() === '') {
          updateStatus('Prompt is empty');
          alert('Please enter a prompt for the LLM');
          return;
        }

        if (role === 'user') sendMessage(userTextPrompt, 'user');

        updateStatus('Sending prompt to LLM... please wait');
        const response = await textToVideo({
          variables: {
            ...(!organization
              ? {
                  sessionId: sessionInfo?.session_id,
                  text: role === 'user' ? userTextPrompt : text,
                  openAiThreadId,
                  role,
                  userSessionId,
                  assistantId: assistantIdState,
                }
              : {
                  encodedOrgId: organization.encodedId,
                  sessionId: sessionInfo?.session_id,
                  text: role === 'user' ? userTextPrompt : text,
                  openAiThreadId,
                  role,
                  userSessionId,
                  assistantId: assistantIdState,
                }),
          },
        });

        if (response.errors) {
          updateStatus(`Error sending prompt: ${response.errors}`);
          throw new Error(`Error sending prompt: ${response.errors}`);
        }

        if (!response.data) {
          updateStatus('No data returned');
          throw new Error('No data returned');
        }

        const {
          textResponse,
          userSessionId: newUserSessionId,
          openAiThreadId: newOpenAiThreadId,
        } = response.data.textToVideoHeyGen;

        if (!textResponse) {
          updateStatus('No text response returned');
          throw new Error('No text response returned');
        }

        updateStatus(
          `Prompt sent with status: ${response.data.textToVideoHeyGen.status || 'unknown'}`,
        );
        setText(textResponse);
        sendMessage(textResponse, 'assistant');
        setUserSessionId(newUserSessionId);

        if (newOpenAiThreadId) {
          setOpenAiThreadId(newOpenAiThreadId);
        }
      } catch (err) {
        updateStatus(`Error sending prompt: ${err}`);
      }
    },
    [
      openAiThreadId,
      sessionInfo,
      textToVideo,
      userTextPrompt,
      text,
      userSessionId,
      organization,
      assistantIdState,
    ],
  );

  const handleICECandidate = async (
    sessionId: string,
    candidate: RTCIceCandidate,
    usId: string,
  ) => {
    try {
      const candidateStr = JSON.stringify(candidate);
      if (submittedCandidates.current.has(candidateStr)) {
        updateStatus('ICE candidate already submitted');
        return;
      }

      submittedCandidates.current.add(candidateStr);

      setPendingIceCandidates((prev) => prev + 1);
      updateStatus('Sending ICE candidate to server... please wait');
      const response = await submitIceCandidate({
        variables: {
          ...(!organization
            ? { sessionId, candidate, userSessionId: usId }
            : {
                encodedOrgId: organization.encodedId,
                sessionId,
                candidate,
                userSessionId: usId,
              }),
        },
      });

      if (response.errors) {
        updateStatus(`Error submitting ICE candidate: ${response.errors}`);
        throw new Error(`Error submitting ICE candidate: ${response.errors}`);
      }

      if (!response.data) {
        updateStatus('No data returned');
        throw new Error('No data returned');
      }

      updateStatus(`ICE candidate submitted`);
    } catch (err) {
      updateStatus(`Error submitting ICE candidate: ${err}`);
    } finally {
      setPendingIceCandidates((prev) => prev - 1);
    }
  };

  const initializePeerConnection = async (
    iceServers: RTCIceServer[],
    serverSdp: SDP,
    sessionId: string,
    usId: string,
  ) => {
    const pc = new RTCPeerConnection({ iceServers });

    pc.onicecandidate = async ({ candidate }) => {
      if (candidate) await handleICECandidate(sessionId, candidate, usId);
    };

    pc.onicegatheringstatechange = () => {
      if (pc.iceGatheringState === 'complete') {
        updateStatus('ICE gathering state complete');
        setIsIceCandidatesSubmitted(true);
      }
    };

    pc.oniceconnectionstatechange = () => {
      updateStatus(`ICE connection state changed to: ${pc.iceConnectionState}`);
      setIceConnectionState(pc.iceConnectionState);
    };

    pc.ontrack = ({ streams: [stream] }) => {
      if (videoRef.current) {
        updateStatus('Received video stream');
        videoRef.current.srcObject = stream;
        setIsStreamStarted(true);
      }
    };

    const remoteDescription = new RTCSessionDescription(serverSdp);
    await pc.setRemoteDescription(remoteDescription);

    peerConnection.current = pc;
    setIsConnected(true);
  };

  const handleNewSession = async () => {
    updateStatus('Creating new session... please wait');
    const response = await createSession({
      variables: {
        ...(!organization
          ? {
              quality: 'high',
              avatarName: avatarNameState,
              voiceId: voiceIdState,
              assistantId: assistantIdState,
            }
          : {
              encodedOrgId: organization.encodedId,
            }),
      },
    });

    if (response.errors) {
      updateStatus(`Error creating session: ${response.errors}`);
      throw new Error(`Error creating session: ${response.errors}`);
    }

    if (!response.data) {
      updateStatus('No data returned');
      throw new Error('No data returned');
    }

    const {
      sdp,
      ice_servers2: iceServers,
      session_id: sessionId,
    } = response.data.createHeyGenSession.data;
    setSessionInfo(response.data.createHeyGenSession.data);
    setOpenAiThreadId(response.data.createHeyGenSession.openAiThreadId);
    setUserSessionId(response.data.createHeyGenSession.userSessionId);
    await initializePeerConnection(
      iceServers,
      sdp,
      sessionId,
      response.data.createHeyGenSession.userSessionId,
    );
  };

  const handleStartSession = useCallback(async () => {
    if (!isConnected || !sessionInfo || !peerConnection.current) return;

    updateStatus('Starting session... please wait');

    const localDescription = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(localDescription);

    const response = await startSession({
      variables: {
        ...(!organization
          ? { sessionId: sessionInfo.session_id, sdp: localDescription, userSessionId }
          : {
              sessionId: sessionInfo.session_id,
              sdp: localDescription,
              userSessionId,
              encodedOrgId: organization.encodedId,
            }),
      },
    });

    if (response.errors) {
      updateStatus(`Error starting session: ${response.errors}`);
      throw new Error(`Error starting session: ${response.errors}`);
    }

    if (!response.data) {
      updateStatus('No data returned');
      throw new Error('No data returned');
    }

    const { textResponse, status: sessionStatus = 'unknown' } = response.data.startHeyGenSession;

    updateStatus(`Session started with status: ${sessionStatus}`);
    setText(textResponse || '');
    sendMessage(textResponse || 'No response text', 'assistant');
  }, [isConnected, sessionInfo, startSession, userSessionId, organization]);

  useEffect(() => {
    if (sessionInfo && isConnected && !peerConnection.current?.localDescription) {
      handleStartSession();
    }
  }, [isConnected, sessionInfo, handleStartSession]);

  const handleRepeat = useCallback(
    async (retryCount = 0) => {
      if (!sessionInfo || text.trim() === '') return;

      const retryDelay = 250;
      const maxRetries = 3;

      updateStatus('Repeating session... please wait');
      try {
        const response = await repeatText({
          variables: {
            ...(!organization
              ? { sessionId: sessionInfo.session_id, text, userSessionId }
              : {
                  sessionId: sessionInfo.session_id,
                  text,
                  userSessionId,
                  encodedOrgId: organization.encodedId,
                }),
          },
        });

        if (response.errors) {
          updateStatus(`Error repeating text: ${response.errors}`);
          throw new Error(`Error repeating text: ${response.errors}`);
        }

        if (!response.data) {
          updateStatus('No data returned');
          throw new Error('No data returned');
        }

        updateStatus('Session repeated successfully');
        if (!isFirstRepeatSubmitted) setIsFirstRepeatSubmitted(true);
      } catch (err) {
        updateStatus(`Error repeating text: ${err}`);
        if (retryCount < maxRetries) {
          updateStatus(`Retrying repeat in ${retryDelay / 1000} seconds...`);
          setTimeout(() => handleRepeat(retryCount + 1), retryDelay);
        }
      }
    },
    [sessionInfo, text, repeatText, userSessionId, organization, isFirstRepeatSubmitted],
  );

  const handleCloseSession = async () => {
    if (!sessionInfo) return;

    updateStatus('Closing session... please wait');
    const response = await closeSession({
      variables: {
        ...(!organization
          ? { sessionId: sessionInfo.session_id, userSessionId }
          : {
              encodedOrgId: organization.encodedId,
              sessionId: sessionInfo.session_id,
              userSessionId,
            }),
      },
    });

    if (response.errors) {
      updateStatus(`Error closing session: ${response.errors}`);
      throw new Error(`Error closing session: ${response.errors}`);
    }

    if (!response.data) {
      updateStatus('No data returned');
      throw new Error('No data returned');
    }

    const { status: closeStatus = 'unknown' } = response.data.closeHeyGenSession;

    updateStatus(`Session closed with status: ${closeStatus}`);
    setSessionInfo(null);
    setIsConnected(false);
    setOpenAiThreadId(null);
    setUserSessionId('');
    setChat([]);
    setText('');
    setIsStreamStarted(false);
    setIsIceCandidatesSubmitted(false);
    submittedCandidates.current.clear();
    setPendingIceCandidates(0);
    if (videoRef.current) videoRef.current.srcObject = null;
  };

  const handleSaveSettings = async () => {
    if (superAdmin) {
      updateStatus('Saving settings... please wait');
      const response = await saveSettings({
        variables: {
          ...(!organization
            ? {
                avatarName: avatarNameState,
                avatarVoiceId: voiceIdState,
                assistantId: assistantIdState,
                avatarPosterUrl: avatarPosterUrlState,
              }
            : {
                encodedOrgId: organization.encodedId,
                avatarName: avatarNameState,
                avatarVoiceId: voiceIdState,
                assistantId: assistantIdState,
                avatarPosterUrl: avatarPosterUrlState,
              }),
        },
      });

      if (response.errors) {
        updateStatus(`Error saving settings: ${response.errors}`);
        throw new Error(`Error saving settings: ${response.errors}`);
      }

      if (!response.data) {
        updateStatus('No data returned');
        throw new Error('No data returned');
      }

      updateStatus('Settings saved');
      if (isConnected) {
        await handleCloseSession();
        await handleNewSession();
      }
    }
  };

  useEffect(() => {
    if (
      chat.length === 1 &&
      chat[0].role === 'assistant' &&
      isIceCandidatesSubmitted &&
      isStreamStarted &&
      isConnected &&
      iceConnectionState === 'connected' &&
      pendingIceCandidates === 0 &&
      !isFirstRepeatSubmitted
    ) {
      setTimeout(() => handleRepeat(), 1000);
    }
  }, [
    chat,
    handleRepeat,
    isIceCandidatesSubmitted,
    isStreamStarted,
    isConnected,
    iceConnectionState,
    pendingIceCandidates,
    isFirstRepeatSubmitted,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '16px',
        p: 1,
        m: 0,
        maxWidth: '1200px',
        position: 'relative',
      }}
      aria-live="polite"
    >
      <Grid container spacing={1} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} md={8} sx={{ width: '100%', height: '100%', aspectRatio: '16/9' }}>
          <VideoContainer
            sessionInfo={sessionInfo}
            isConnected={isConnected}
            loading={loading}
            hasSessionInfo={Boolean(sessionInfo)}
            handleCloseSession={handleCloseSession}
            handleRepeat={handleRepeat}
            handleNewSession={handleNewSession}
            text={text}
            videoRef={videoRef}
            videoGridItemRef={videoGridItemRef}
            avatarPosterUrl={avatarPosterUrlState}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ChatBox
            chat={chat.filter((msg) => msg.role !== 'assistant' || isFirstRepeatSubmitted)}
            textToVideoLoading={textToVideoLoading}
            isLoading={loading}
            disabled={false}
            userTextPrompt={userTextPrompt}
            setUserTextPrompt={setUserTextPrompt}
            handleTalk={handleTalk}
            videoGridItemRef={videoGridItemRef}
          />
        </Grid>
      </Grid>
      <SettingsPanel
        showSettings={showSettings}
        setShowSettings={setShowSettings}
        avatarName={avatarNameState}
        setAvatarName={setAvatarNameState}
        voiceId={voiceIdState}
        setVoiceID={setVoiceIdState}
        assistantId={assistantIdState}
        setAssistantID={setAssistantIdState}
        avatarPosterUrl={avatarPosterUrlState}
        setAvatarPosterUrl={setAvatarPosterUrlState}
        status={status}
        loading={loading}
        allowSettings={superAdmin || Boolean(!organization)}
        saveSettings={handleSaveSettings}
      />
    </Box>
  );
}

export default ConversationalAvatar;
