import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {
  fontFamily,
  textColorBlack,
  textInputFieldBorderColor,
  textInputFieldBorderColorSelected,
} from '../common/SharedStylingSettings';

const styling = {
  shared: {
    fontFamily: fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    color: textColorBlack,
    width: '100%',
    height: '40px',
    lineHeight: '40px',
    '& .MuiInputBase-root': {
      fontFamily: fontFamily,
      fontSize: '14px',
      fontWeight: 400,
      color: textColorBlack,
      height: '40px',
      lineHeight: '40px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px',
      fontFamily: fontFamily,
      fontSize: '14px',
      fontWeight: 400,
      color: textColorBlack,
      height: '40px',
      lineHeight: '40px',
      paddingLeft: '10px',
    },
    '& .MuiFormLabel-root': {
      fontFamily: fontFamily,

      marginTop: '-7px',
      '.[data-shrink="true"]': {},
    },
    '& label.Mui-focused': {},
    '& .MuiInput-underline:after': {},
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontFamily: fontFamily,
        fontSize: '14px',
        fontWeight: 400,
        color: textColorBlack,
        border: '1px solid #dbdbdd',
        borderRadius: '5px',
        width: '100%',
        padding: '0 10px',
        borderColor: textInputFieldBorderColor,
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        border: '1px solid #dbdbdd',
        borderWidth: '1px',
        borderColor: textInputFieldBorderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: 'none',
        boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 20%)',
        borderWidth: '1px',
        borderColor: textInputFieldBorderColorSelected,
        '&.error': {
          border: '1px solid #f44336',
          borderWidth: '1px',
        },
        outline: 0,
      },
    },
  },
};

const errorStyles = {
  borderColor: 'red',
  '&:hover': { borderColor: 'red' },
  '&:focus': { borderColor: 'red' },
};

const getStyles = (error) => ({
  ...styling.shared,
  ...(error && errorStyles),
});

export default function SimpleTextField({ error, ...props }) {
  // We want to pass down all props to TextField, except for the `error` prop
  // so we use the rest operator to get all the props except for `error`
  // and then pass them down to TextField
  return <TextField sx={getStyles(error)} {...props} />;
}
