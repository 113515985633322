import _ from 'lodash';
import axios from 'axios';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';
import { translations } from './translations';
import { translationsStoryline } from './translationsStoryline';

export const statuses = {
  SUGGESTION: 'SUGGESTION',
  PENDING: 'PENDING',
  REVIEW: 'REVIEW',
  APPROVED: 'APPROVED',
  LIVE: 'LIVE',
  LIVE_SELECTED: 'LIVE_SELECTED',
  ARCHIVED: 'ARCHIVED',
};

export const validVideoQualities = {
  FHD: { value: 'FHD', label: '1080p', qualityId: 4 },
  HD: { value: 'HD', label: '720p', qualityId: 3 },
  qHD: { value: 'qHD', label: '540p', qualityId: 2 },
  nHD: { value: 'nHD', label: '360p', qualityId: 1 },
  ntsc: { value: 'ntsc', label: '240p', qualityId: 0 },
};

export const getDefaultState = (type, lang) => {
  switch (type) {
    case 'user':
      return {
        value: 'ALL_USERS',
        label: translations.allUsers[lang],
      };
    case 'status':
      return {
        value: 'ALL',
        label: translationsStoryline.statusOptionsAll[lang],
      };
    case 'statusLive':
      return {
        value: statuses.LIVE,
        label: translations.status[statuses.LIVE][lang],
      };

    default:
      return {};
  }
};

export const storylineStatuses = {
  EDIT: 'EDIT',
  LIVE: 'LIVE',
};

export const actions = {
  SEND_REQUEST: 'SEND_REQUEST',
  SEND_SMS_REQUEST: 'SEND_SMS_REQUEST',
  SEND_SMS_EMAIL_REQUEST: 'SEND_SMS_EMAIL_REQUEST',
  APPROVE: 'APPROVE',
  PUBLISH: 'PUBLISH',
  ARCHIVE: 'ARCHIVE',
  UNPUBLISH: 'UNPUBLISH',
  UNARCHIVE: 'UNARCHIVE',
};

export const signupStatuses = {
  initial: 'initial',
  pendingReCaptcha: 'pendingReCaptcha',
  pending: 'pending',
  creatingUser: 'creatingUser',
  userCreated: 'userCreated',
  waitingPhoneNumberVerification: 'waitingPhoneNumberVerification',
  verifyingPhoneNumber: 'verifyingPhoneNumber',
  updatingInput: 'updatingInput',
  success: 'success',
  error: 'error',
};

export const packageVariant = {
  freemium: 'freemium',
};

export const freemiumDefaultPackageSettings = {
  interactionOption: true,
  textMessage: true,
  subtitleAllowed: false,
  includeSubtitle: false,
  editableConsentTexts: false,
  autoReminders: true,
  autoRequests: false,
  excludeFromVimeo: true,
  hideWatermark: false,
  advancedWidgetStyling: false,
  advancedWidgetBehavior: false,
  requestsLibraryBeta: false,
  orgSubtitleUpload: true,
  advancedWidgetStylingMobile: false,
  widgetThree: true,
  videoFunnel: true,
  videoFunnelEndScreen: true,
  widgetVersion: 'default',
  packageVariant: packageVariant.freemium,
  freemiumFeatures: {
    videoFunnel: {
      // live: 1,
      // total: 3,
      // videoCards: 3,
      connectionsPerCard: 2,
    },
    // storyline: {
    // live: 1,
    // total: 3,
    // videoCards: 3,
    // },
    stories: {
      live: 2,
      // total: 6,
      whenCondition: 1,
      conditions: ['exactMatch'],
      excludeWhenCondition: 1,
    },
    videoCard: {
      live: 3,
    },
    // videoRequest: {
    //   // total: 24,
    // },
  },
  advStyleVidTitleDialog: false,
  videoCollector: false,
  organizationPermissionsSettings: false,
  videoRequestEmailCustomization: false,
  conversationalAvatar: false,
};

export const parseQueryParams = (queryString) => {
  const trimmed = _.trimStart(queryString, '?');
  const params = trimmed.split('&');
  const paramObj = {};
  params.forEach((param) => {
    const pair = param.split('=');
    paramObj[pair[0]] = decodeURIComponent(pair[1]);
  });
  return paramObj;
};

export const getVimeoVideoIdFromLink = (videoLink) => {
  if (videoLink && videoLink.indexOf('https://player.vimeo.com/video/') !== -1) {
    return _.trimStart(videoLink, 'https://player.vimeo.com/video/');
  }
  if (videoLink && videoLink.indexOf('https://player.vimeo.com/videos/') !== -1) {
    return _.trimStart(videoLink, 'https://player.vimeo.com/videos/');
  }
};

export const getVideoThumbnailUrl = ({ videoId }) => {
  const url = `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}&width=720`;
  return axios({
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return response.data.thumbnail_url_with_play_button;
  });
};

export const shadeHexColorToRgb = (color, amount) => {
  const newHex = `#${color
    .replace(/^#/, '')
    .replace(/../g, (color) =>
      `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2),
    )}`;
  return hexToRgb(newHex);
};

export const hexToRgb = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isValidHttpUrl = (string, addHttp = false) => {
  let url;
  let urlString = string;
  try {
    if (addHttp) {
      if (!urlString.startsWith('http://') && !urlString.startsWith('https://')) {
        urlString = `http://${string}`;
      }
    }

    url = new URL(urlString);
  } catch (error) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getVimeoVideoIdByItem = (item, lang) => {
  if (item?.videoLink?.[lang]) {
    return Number(_.trimStart(item.videoLink[lang], 'https://player.vimeo.com/videos/'));
  }
};

export const isValidPhoneNumberInput = ({ phoneNumber, lang, orgRecipients, editRecipient }) => {
  const errors = {
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
    NOT_POSSIBLE_PHONE_NUMBER: 'NOT_POSSIBLE_PHONE_NUMBER',
    PHONE_NUMBER_ALREADY_EXISTS: 'PHONE_NUMBER_ALREADY_EXISTS',
  };

  const validatePhoneNumberLengthVal = validatePhoneNumberLength(`+${phoneNumber}`);

  const isValidPhoneNumberVal = isValidPhoneNumber(`+${phoneNumber}`);

  const isPossiblePhoneNumberVal = isPossiblePhoneNumber(`+${phoneNumber}`);

  const doesNotExistInRecipients = !orgRecipients?.some((recipient) => {
    if (!recipient.phoneNumber) {
      return false;
    }

    const editRecp = editRecipient && editRecipient.id && recipient.id === editRecipient?.id;

    const recipient1 = (
      recipient?.phoneNumber?.startsWith('+')
        ? recipient?.phoneNumber
        : `+${recipient?.phoneNumber}`
    ).trim();

    const recipient2 = (phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`)?.trim();

    const hasSameNumber = recipient1 === recipient2;

    if (editRecp) {
      return false;
    }
    return hasSameNumber;
  });

  const isValid =
    phoneNumber?.length < 4 ||
    (validatePhoneNumberLengthVal === undefined &&
      isValidPhoneNumberVal &&
      isPossiblePhoneNumberVal &&
      doesNotExistInRecipients);

  if (phoneNumber?.length > 3 && !doesNotExistInRecipients) {
    return translations.errors.phoneNumber[errors.PHONE_NUMBER_ALREADY_EXISTS][lang];
  }

  if (phoneNumber?.length > 3 && validatePhoneNumberLengthVal !== undefined) {
    return validatePhoneNumberLengthVal in translations.errors.phoneNumber
      ? translations.errors.phoneNumber[validatePhoneNumberLengthVal][lang]
      : validatePhoneNumberLengthVal;
  }
  if (phoneNumber?.length > 3 && !isValidPhoneNumberVal) {
    return translations.errors.phoneNumber[errors.INVALID_PHONE_NUMBER][lang];
  }

  if (phoneNumber?.length > 3 && !isPossiblePhoneNumberVal) {
    return translations.errors.phoneNumber[errors.NOT_POSSIBLE_PHONE_NUMBER][lang];
  }

  return isValid;
};

export const hasVideoLink = ({ excludeFromVimeo = false, videoLink, orgLang }) => {
  if (!excludeFromVimeo) {
    return !!videoLink?.[orgLang];
  }
  return videoLink?.FHD || videoLink?.HD || videoLink?.qHD || videoLink?.nHD || videoLink?.ntsc;
};

export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const timeTranslations = {
  hours: {
    en: 'hours',
    de: 'Stunden',
    fr: 'heures',
    it: 'ore',
    es: 'horas',
    sv: 'timmar',
  },
  minutes: {
    en: 'minutes',
    de: 'Minuten',
    fr: 'minutes',
    it: 'minuti',
    es: 'minutos',
    sv: 'minuter',
  },
  seconds: {
    en: 'seconds',
    de: 'Sekunden',
    fr: 'secondes',
    it: 'secondi',
    es: 'segundos',
    sv: 'sekunder',
  },
};

const timeAbbreviationsTranslations = {
  h: {
    en: 'h',
    sv: 'h',
  },
  min: {
    en: 'min',
    sv: 'min',
  },
  sec: {
    en: 'sec',
    sv: 'sek',
  },
};

export const formatTime = (totalSeconds, lang) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (hours > 10) {
    return `${hours} ${timeTranslations.hours[lang]}`;
  }
  if (hours > 0) {
    return `${hours} ${timeAbbreviationsTranslations.h[lang]} ${minutes} ${timeAbbreviationsTranslations.min[lang]}`;
  }
  if (minutes > 0) {
    return `${minutes} ${timeAbbreviationsTranslations.min[lang]} ${seconds} ${timeAbbreviationsTranslations.sec[lang]}`;
  }
  return `${seconds} ${timeTranslations.seconds[lang]}`;
};
