const translationsPlanner = {
  plannerTitle: {
    en: 'Planner',
    sv: 'Planerare',
  },
  videoRequest: {
    en: 'Video request',
    sv: 'Videoförfrågan',
  },
  uploadVideo: {
    en: 'Upload video',
    sv: 'Ladda upp video',
  },
  search: {
    en: 'Search ...',
    sv: 'Sök ...',
  },
};

export default translationsPlanner;
