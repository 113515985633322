export const translationsRequest = {
  allRequestsTitle: {
    en: 'Requests',
    sv: 'Förfrågningar',
  },
  deleteItemTitle: {
    en: 'Delete request',
    sv: 'Ta bort request',
  },
  deleteItemDescription: {
    en: 'Are you sure you want to delete this request? It will be deleted permanently.',
    sv: 'Är du säker på att du vill ta bort denna request? Detta kommer att tas bort permanent.',
  },
  noCancel: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  deleteConfirm: {
    en: 'Delete',
    sv: 'Ta bort',
  },
  duplicateMenuItemTitle: {
    en: 'Duplicate',
    sv: 'Duplicera',
  },
  deleteMenuItemTitle: {
    en: 'Delete',
    sv: 'Ta bort',
  },
  allStatuses: {
    en: 'All statuses',
    sv: 'Alla statusar',
  },
  statuses: {
    en: 'Status',
    sv: 'Status',
  },
};

export default translationsRequest;
