import { gql } from '@apollo/client';
import { useBackOfficeStatsApolloClient } from 'src/context/ApolloClientContext';
import { useMemo } from 'react';
import formatDateString from '../../formatDateString';

// Define the fragments
export const STATS_BY_TIMESTAMP_FRAGMENT = gql`
  fragment StatByTimestamp on StatByTimestamp {
    timestamp
    count
  }
`;

export const VIDEO_CARD_DETAILS_FRAGMENT_FOR_STATS = gql`
  fragment VideoCardDetails on VideoCardDetails {
    id
    encodedId
    actorName
    actorEmail
    orgId
    actorTitle {
      sv
      en
    }
    question {
      sv
      en
    }
    recipient {
      id
      firstName
      lastName
      title
      name
    }
    staticThumbnails {
      FHD
      HD
      qHD
      nHD
      ntsc
      qcif
      sqcif
    }
    animatedThumbnails {
      FHD
      HD
      qHD
      nHD
      ntsc
      qcif
      sqcif
    }
  }
`;

// Define the queries
const GET_TOTAL_STATS_DATA = gql`
  ${STATS_BY_TIMESTAMP_FRAGMENT}
  query GetTotalStatsData($encodedOrgId: String!, $startDate: String, $endDate: String) {
    getTotalStatsData(encodedOrgId: $encodedOrgId, startDate: $startDate, endDate: $endDate) {
      playback {
        impression {
          ...StatByTimestamp
        }
        timePlayed {
          ...StatByTimestamp
        }
        play {
          ...StatByTimestamp
        }
      }
      interactions {
        total {
          ...StatByTimestamp
        }
      }
    }
  }
`;

const GET_BEST_PERFORMANCE_VIDEO_CARD_QUERY = (statsPart: string, queryName: string) => gql`
  ${STATS_BY_TIMESTAMP_FRAGMENT}
  ${VIDEO_CARD_DETAILS_FRAGMENT_FOR_STATS}
  query ${queryName}(
    $encodedOrgId: String!
    $startDate: String
    $endDate: String
  ) {
    ${queryName}(
      encodedOrgId: $encodedOrgId
      startDate: $startDate
      endDate: $endDate
    ) {
        ...VideoCardDetails
        stats {
          ${statsPart}
        }
    }
  }
`;

const GET_BEST_PERFORMANCE_IMPRESSION_STORY_QUERY = (statsPart: string, queryName: string) => gql`
  ${STATS_BY_TIMESTAMP_FRAGMENT}
  query GetBestPerformanceImpressionStory(
    $encodedOrgId: String!
    $startDate: String
    $endDate: String
  ) {
    ${queryName}(
      encodedOrgId: $encodedOrgId
      startDate: $startDate
      endDate: $endDate
    ) {
      identifier {
        videoFunnelId
        storylineId
      }
      title
      stats {
        ${statsPart}
      }
    }
  }
`;

export interface PlaybackTotal {
  impression: number;
  timePlayed: number;
}

export interface InteractionsTotal {
  total: number;
}

export type StatsByTypeTotalResult = {
  playback: PlaybackTotal;
  interactions: InteractionsTotal;
};

export interface StatByTimestamp {
  timestamp: string;
  count: number;
}

export type PlaybackTimestamp = {
  pause: StatByTimestamp[];
  play: StatByTimestamp[];
  end: StatByTimestamp[];
  impression: StatByTimestamp[];
  timePlayed: StatByTimestamp[];
};

export type InteractionTimestamp = {
  total: StatByTimestamp[];
};

export interface StatsConfig {
  playback?: Array<keyof PlaybackTimestamp>;
  interactions?: Array<keyof InteractionTimestamp>;
}

// Function to generate the stats query part
export const generateStatsQueryPart = (
  statsConfig: StatsConfig,
  includeStatByTimestamp: boolean = true,
) => {
  return Object.entries(statsConfig)
    .map(([statType, subStats]) => {
      const subStatsFields = (subStats as string[])
        .map((subStat) => `${subStat} ${includeStatByTimestamp ? `{ ...StatByTimestamp }` : ``}`)
        .join('\n        ');
      return `
      ${statType} {
        ${subStatsFields}
      }
    `;
    })
    .join('\n');
};

// Define the interface for the API parameters
interface StatsParams {
  encodedOrgId: string;
  startDate?: Date | null;
  endDate?: Date | null;
}

// Define the AnalyticsOverviewApi class
export class AnalyticsOverviewApi {
  private backOfficeStatsClient: ReturnType<typeof useBackOfficeStatsApolloClient>;

  constructor(backOfficeStatsClient: ReturnType<typeof useBackOfficeStatsApolloClient>) {
    this.backOfficeStatsClient = backOfficeStatsClient;
  }

  async getTotalStatsData({ encodedOrgId, startDate, endDate }: StatsParams) {
    const variables: Record<string, string | number> = { encodedOrgId };

    const isValidDates =
      startDate && endDate && startDate instanceof Date && endDate instanceof Date;

    if (isValidDates) {
      variables.startDate = formatDateString(startDate);
      variables.endDate = formatDateString(endDate);
    }

    const result = await this.backOfficeStatsClient.query({
      query: GET_TOTAL_STATS_DATA,
      variables,
    });

    return result.data.getTotalStatsData;
  }

  private async getBestPerformanceVideoCard({
    query,
    queryName,
    encodedOrgId,
    startDate,
    endDate,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;
    queryName: string;
    encodedOrgId: string;
    startDate?: Date | null;
    endDate?: Date | null;
  }) {
    const variables: Record<string, string | number> = { encodedOrgId };
    const isValidDates =
      startDate && endDate && startDate instanceof Date && endDate instanceof Date;

    if (isValidDates) {
      variables.startDate = formatDateString(startDate);
      variables.endDate = formatDateString(endDate);
    }

    const result = await this.backOfficeStatsClient.query({
      query,
      variables,
    });

    return result.data[queryName];
  }

  private async getBestPerformanceStory({
    query,
    queryName,
    encodedOrgId,
    startDate,
    endDate,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;
    queryName: string;
    encodedOrgId: string;
    startDate?: Date | null;
    endDate?: Date | null;
  }) {
    const variables: Record<string, string | number> = { encodedOrgId };
    const isValidDates =
      startDate && endDate && startDate instanceof Date && endDate instanceof Date;

    if (isValidDates) {
      variables.startDate = formatDateString(startDate);
      variables.endDate = formatDateString(endDate);
    }

    const result = await this.backOfficeStatsClient.query({
      query,
      variables,
    });

    return result.data[queryName];
  }

  async getBestPerformanceImpressionVideoCard(params: StatsParams) {
    const statsConfigImpression: StatsConfig = {
      playback: ['impression'],
    };
    const statsPart = generateStatsQueryPart(statsConfigImpression);
    const query = GET_BEST_PERFORMANCE_VIDEO_CARD_QUERY(
      statsPart,
      'getBestPerformanceImpressionVideoCard',
    );
    return this.getBestPerformanceVideoCard({
      query,
      queryName: 'getBestPerformanceImpressionVideoCard',
      ...params,
    });
  }

  async getBestPerformanceViewTimeVideoCard(params: StatsParams) {
    const statsConfigTimePlayed: StatsConfig = {
      playback: ['timePlayed'],
    };
    const statsPart = generateStatsQueryPart(statsConfigTimePlayed);
    const query = GET_BEST_PERFORMANCE_VIDEO_CARD_QUERY(
      statsPart,
      'getBestPerformanceViewTimeVideoCard',
    );
    return this.getBestPerformanceVideoCard({
      query,
      queryName: 'getBestPerformanceViewTimeVideoCard',
      ...params,
    });
  }

  async getBestPerformanceImpressionStory(params: StatsParams) {
    const statsConfigImpression: StatsConfig = {
      playback: ['impression'],
    };
    const statsPart = generateStatsQueryPart(statsConfigImpression);

    const query = GET_BEST_PERFORMANCE_IMPRESSION_STORY_QUERY(
      statsPart,
      'getBestPerformanceImpressionStory',
    );
    return this.getBestPerformanceStory({
      query,
      queryName: 'getBestPerformanceImpressionStory',
      ...params,
    });
  }
}

// Hook to use the AnalyticsOverviewApi
export const useAnalyticsOverviewApi = () => {
  const backOfficeStatsClient = useBackOfficeStatsApolloClient();
  return useMemo(() => new AnalyticsOverviewApi(backOfficeStatsClient), [backOfficeStatsClient]);
};
