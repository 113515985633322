import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from 'react-google-recaptcha';

import { Checkbox, CircularProgress, Collapse } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import ThemeContext from 'src/utils/ThemeContext';
import validator from 'validator';
import parse from 'html-react-parser';

import { useLanguage } from 'src/context/LanguageContext';
import { validatePhoneNumber } from './validation';
import VideoUploadInput from './VideoUploadInput';
import translations from './translations';
import GDPRCompliant from '../gdpr-compliant';
import Recaptcha from '../recaptcha';
import { VideoCollector } from './types';
import UploadProgress from './UploadProgress';

type UploadStatus = 'NOT_STARTED' | 'LOADING';

export interface VideoUploadData {
  videoFile: File;
  question: string;
  actorName: string | null;
  actorTitle: string | null;
  actorEmail: string | null;
  actorPhone: string | null;
  uploadConsent: string;
  soMeConsent: string | null;
}

export type DisplayMode = 'dialog' | 'drawer' | 'inline';

interface InputProps {
  value: string;
  hasOnBlur: boolean;
}

const isEmpty = (value: string) => validator.isEmpty(value, { ignore_whitespace: true });

const isTextInputValid = (value: string, allowEmpty: boolean = false) => {
  if (isEmpty(value)) {
    return allowEmpty;
  }
  return validator.isLength(value, { min: 1, max: 60 });
};

const isActorNameValid = (actorName: string, required: boolean) =>
  isTextInputValid(actorName, !required);

const isActorTitleValid = (actorRole: string, required: boolean) =>
  isTextInputValid(actorRole, !required);

const isActorEmailValid = (actorEmail: string, required: boolean) => {
  if (isEmpty(actorEmail)) {
    return !required;
  }
  return validator.isEmail(actorEmail);
};

const isActorPhoneValid = (actorPhone: string, required: boolean) => {
  if (isEmpty(actorPhone)) {
    return !required;
  }
  return validatePhoneNumber(actorPhone) === 'VALID';
};

const actorPhoneErrorMessage = (actorPhone: string, lang: Language) => {
  const res = validatePhoneNumber(actorPhone);
  if (res === 'VALID') {
    return '';
  }
  return translations.validation.phoneNumberError[res][lang];
};

function QuestionSelectInput({
  disabled,
  questions,
  allowCustomQuestion,
  onChange,
}: {
  disabled: boolean;
  questions: string[];
  allowCustomQuestion: boolean;
  onChange: (question: string | null) => void;
}) {
  const lang = useLanguage();
  type QuestionOption = { id: number; label: string };
  const dummyUseCustomOption: QuestionOption = { id: -1, label: translations.CustomTitle[lang] };
  const options: QuestionOption[] = questions.map((label, idx) => ({ id: idx, label }));
  if (allowCustomQuestion) {
    options.push(dummyUseCustomOption);
  }

  const defaultSelectedQuestion = options[0];
  const [selectedQuestion, setSelectedQuestion] = useState(defaultSelectedQuestion);
  const [customQuestion, setCustomQuestion] = useState<InputProps>({ value: '', hasOnBlur: false });

  const useCustom = selectedQuestion.id === dummyUseCustomOption.id;
  const selectedQuestionValid = selectedQuestion != null;
  const customQuestionValid = isTextInputValid(customQuestion.value);

  useEffect(() => {
    if (useCustom && customQuestionValid) {
      onChange(customQuestion.value);
    } else if (!useCustom && selectedQuestionValid) {
      onChange(selectedQuestion.label);
    } else {
      onChange(null);
    }
  }, [
    customQuestion.value,
    customQuestionValid,
    onChange,
    selectedQuestion.label,
    selectedQuestionValid,
    useCustom,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Autocomplete
        disabled={disabled}
        onChange={(_event, newValue) => setSelectedQuestion(newValue)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        defaultValue={defaultSelectedQuestion}
        disableClearable
        size="small"
        fullWidth
        renderInput={(params) => (
          <Typography variant="titleMedium">
            {translations.VideoTitle[lang]}
            <Typography variant="titleMedium" color="error">
              {' '}
              *
            </Typography>
            <TextField
              {...params}
              size="small"
              placeholder={`${translations.VideoTitle[lang]} (${translations.Required[lang]})`}
              variant="outlined"
              required
              disabled={disabled}
              sx={{
                backgroundColor: 'background.default',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                },
                borderRadius: 1,
              }}
            />
          </Typography>
        )}
      />
      <Collapse in={useCustom} sx={{ marginTop: useCustom ? '4px ' : 0, width: '100%' }}>
        <Typography variant="titleMedium">
          {translations.CustomTitle[lang]}
          <Typography variant="titleMedium" color="error">
            {' '}
            *
          </Typography>
          <TextField
            placeholder={`${translations.CustomTitle[lang]} (${translations.Required[lang]})`}
            required={useCustom}
            disabled={!useCustom}
            value={customQuestion.value}
            onChange={(event) =>
              setCustomQuestion({ ...customQuestion, value: event.target.value })
            }
            onBlur={() => setCustomQuestion({ ...customQuestion, hasOnBlur: true })}
            error={customQuestion.hasOnBlur && !customQuestionValid}
            sx={{
              backgroundColor: 'background.default',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
              },
              borderRadius: 1,
            }}
            fullWidth
          />
        </Typography>
      </Collapse>
    </Box>
  );
}

function QuestionLabel({ question }: { question: string | undefined }) {
  const lang = useLanguage();

  if (question == null) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Typography variant="titleLargeBold">
        {translations.QuestionAbbreviation[lang]}: {question}
      </Typography>
    </Box>
  );
}

interface UploadStepsProps {
  promoteRecordVideoPortrait?: boolean;
}

function UploadSteps({ promoteRecordVideoPortrait = true }: UploadStepsProps) {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();

  const steps = [
    {
      index: 1,
      title: translations.step1Title[lang],
      description: promoteRecordVideoPortrait
        ? translations.step1[lang]
        : translations.step1NoPortrait[lang],
    },
    {
      index: 2,
      title: translations.step2Title[lang],
      description: translations.step2[lang],
    },
    {
      index: 3,
      title: translations.step3Title[lang],
      description: translations.step3[lang],
    },
  ];

  return (
    <>
      {steps.map((step) => (
        <Grid item sx={{ width: '100%' }} key={`${step.index}-${step.title?.trim()}`}>
          <Box
            key={step.index}
            sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%' }}
          >
            <Box
              sx={{
                height: 34,
                width: 34,
                padding: '0 7px',
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.text.light,
                ...theme.typography.bodyMediumBold,
              }}
            >
              {step.index}
            </Box>
            <Box sx={{ flex: '1 1 0' }}>
              <Typography variant="bodyMediumBold">{step.title}</Typography>
              <br />
              <Typography variant="bodyMedium">{step.description}</Typography>
            </Box>
          </Box>
        </Grid>
      ))}
      <Typography variant="bodyMedium" align="center" sx={{ width: '100%' }}>
        {translations.needSomeTips[lang]}
      </Typography>
    </>
  );
}

interface VideoUploadFormProps {
  onSubmit: (data: VideoUploadData) => Promise<void>;
  videoCollector: VideoCollector;
  reCaptchaRef: React.RefObject<ReCAPTCHA>;
  displayMode: DisplayMode;
  uploadProgress: number;
}

function VideoUploadForm({
  onSubmit,
  videoCollector,
  reCaptchaRef,
  displayMode,
  uploadProgress,
}: VideoUploadFormProps) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);
  // Consent texts.
  const {
    customTexts: { uploadConsent, soMeConsent },
  } = videoCollector.organization;
  const uploadConsentText = decodeURIComponent(uploadConsent);
  const soMeConsentText = soMeConsent.enable ? decodeURIComponent(soMeConsent.text) : null;
  const soMeConsentRequired = soMeConsent.mandatory && soMeConsentText != null;

  // Form input.
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoTitle, setVideoTitle] = useState<string | null>(null);
  const [actorName, setActorName] = useState<InputProps>({ value: '', hasOnBlur: false });
  const [actorTitle, setActorTitle] = useState<InputProps>({ value: '', hasOnBlur: false });
  const [actorEmail, setActorEmail] = useState<InputProps>({ value: '', hasOnBlur: false });
  const [actorPhone, setActorPhone] = useState<InputProps>({ value: '', hasOnBlur: false });
  const [soMeUploadConsent, setSoMeUploadConsent] = useState<boolean>(soMeConsentRequired);

  // Form submit status.
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('NOT_STARTED');
  const isLoading = uploadStatus === 'LOADING';

  // Fields editable?
  const videoTitleEditable =
    videoCollector.allowUserEnteredQuestions || videoCollector.questions.length > 1;
  const actorNameEditable = videoCollector.saveName;
  const actorTitleEditable = videoCollector.saveTitle;
  const actorEmailEditable = videoCollector.saveEmail;
  const actorPhoneEditable = videoCollector.savePhone;

  // Fields required?
  const actorNameRequired = videoCollector.saveNameRequired;
  const actorTitleRequired = videoCollector.saveTitleRequired;
  const actorEmailRequired = videoCollector.saveEmailRequired;
  const actorPhoneRequired = videoCollector.savePhoneRequired;

  // Fields valid?
  const videoFileValid = videoFile != null;
  const videoTitleValid = videoTitleEditable ? videoTitle != null : true;
  const actorNameValid = actorNameEditable
    ? isActorNameValid(actorName.value, actorNameRequired)
    : true;
  const actorTitleValid = actorTitleEditable
    ? isActorTitleValid(actorTitle.value, actorTitleRequired)
    : true;
  const actorEmailValid = actorEmailEditable
    ? isActorEmailValid(actorEmail.value, actorEmailRequired)
    : true;
  const actorPhoneValid = actorPhoneEditable
    ? isActorPhoneValid(actorPhone.value, actorPhoneRequired)
    : true;

  // Form valid?
  const formValid =
    videoFileValid &&
    videoTitleValid &&
    actorNameValid &&
    actorTitleValid &&
    actorEmailValid &&
    actorPhoneValid;
  const formMaySubmit = formValid && !isLoading;

  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setVideoFile(file);
    } else {
      setVideoFile(null);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const question = videoTitleEditable
      ? videoTitle!
      : videoCollector.questions[0]?.question ?? 'No Question';

    const userSettingsValue = (field: InputProps, editable: boolean) => {
      if (!editable || isEmpty(field.value)) {
        return null;
      }
      return field.value;
    };

    const data: VideoUploadData = {
      videoFile: videoFile!,
      question,
      actorName: userSettingsValue(actorName, actorNameEditable),
      actorTitle: userSettingsValue(actorTitle, actorTitleEditable),
      actorEmail: userSettingsValue(actorEmail, actorEmailEditable),
      actorPhone: userSettingsValue(actorPhone, actorPhoneEditable),
      uploadConsent: uploadConsentText,
      soMeConsent: soMeUploadConsent ? soMeConsentText : null,
    };

    setUploadStatus('LOADING');
    try {
      await onSubmit(data);
    } finally {
      setUploadStatus('NOT_STARTED');
    }
  };

  const fieldRequiredSymbol = (
    <Typography variant="titleMedium" color="error">
      {' '}
      *
    </Typography>
  );

  // Field placeholders.
  const fieldPlacholder = (fieldName: string, required: boolean) =>
    required ? `${fieldName} (${translations.Required[lang]})` : fieldName;
  const actorNamePlaceholder = fieldPlacholder(translations.Name[lang], actorNameRequired);
  const actorTitlePlaceholder = fieldPlacholder(translations.RoleTitle[lang], actorTitleRequired);
  const actorEmailPlaceholder = fieldPlacholder(translations.Email[lang], actorEmailRequired);
  const actorPhonePlaceholder = fieldPlacholder(translations.PhoneNumber[lang], actorPhoneRequired);

  return (
    <>
      <Stack
        direction="column"
        spacing={3}
        sx={{ maxWidth: '460px', mt: displayMode === 'inline' ? 4 : 0 }}
      >
        <Grid item sx={{ width: '100%' }}>
          <Stack direction="column" spacing={2} alignItems="center">
            <Typography
              variant={displayMode === 'inline' ? 'headlineLarge' : 'headlineLargeBoldRecoleta'}
              align="center"
              sx={{ width: '100%' }}
            >
              {videoTitleEditable
                ? translations.uploadVideoForMultiQuestions[lang]
                : translations.uploadVideoForQuestion[lang]}
            </Typography>

            {videoTitleEditable ? (
              <QuestionSelectInput
                disabled={isLoading}
                questions={videoCollector.questions.map((q) => q.question)}
                allowCustomQuestion={videoCollector.allowUserEnteredQuestions}
                onChange={setVideoTitle}
              />
            ) : (
              <QuestionLabel question={videoCollector.questions[0]?.question} />
            )}

            <UploadSteps
              promoteRecordVideoPortrait={videoCollector.organization.promoteRecordVideoPortrait}
            />
          </Stack>
        </Grid>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Stack direction="column" spacing={2}>
            {/* VideoFile */}
            <VideoUploadInput onChange={handleVideoChange} />

            {/* ActorName */}
            {actorNameEditable && (
              <Typography variant="titleMedium">
                {translations.Name[lang]}
                {actorNameRequired && fieldRequiredSymbol}
                <TextField
                  placeholder={actorNamePlaceholder}
                  required={actorNameRequired}
                  fullWidth
                  disabled={isLoading}
                  value={actorName.value}
                  onChange={(event) => setActorName({ ...actorName, value: event.target.value })}
                  onBlur={() => setActorName({ ...actorName, hasOnBlur: true })}
                  error={actorName.hasOnBlur && !actorNameValid}
                  sx={{
                    backgroundColor: 'background.default',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                    borderRadius: 1,
                  }}
                />
              </Typography>
            )}

            {/* ActorTitle */}
            {actorTitleEditable && (
              <Typography variant="titleMedium">
                {translations.RoleTitle[lang]}
                {actorTitleRequired && fieldRequiredSymbol}
                <TextField
                  placeholder={actorTitlePlaceholder}
                  required={actorTitleRequired}
                  fullWidth
                  disabled={isLoading}
                  value={actorTitle.value}
                  onChange={(event) => setActorTitle({ ...actorTitle, value: event.target.value })}
                  onBlur={() => setActorTitle({ ...actorTitle, hasOnBlur: true })}
                  error={actorTitle.hasOnBlur && !actorTitleValid}
                  sx={{
                    backgroundColor: 'background.default',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                    borderRadius: 1,
                  }}
                />
              </Typography>
            )}

            {/* ActorEmail */}
            {actorEmailEditable && (
              <Typography variant="titleMedium">
                {translations.Email[lang]}
                {actorEmailRequired && fieldRequiredSymbol}
                <TextField
                  placeholder={actorEmailPlaceholder}
                  required={actorEmailRequired}
                  fullWidth
                  disabled={isLoading}
                  value={actorEmail.value}
                  onChange={(event) => setActorEmail({ ...actorEmail, value: event.target.value })}
                  onBlur={() => setActorEmail({ ...actorEmail, hasOnBlur: true })}
                  error={actorEmail.hasOnBlur && !actorEmailValid}
                  sx={{
                    backgroundColor: 'background.default',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                    borderRadius: 1,
                  }}
                />
              </Typography>
            )}

            {/* ActorPhone */}
            {actorPhoneEditable && (
              <Typography variant="titleMedium">
                {translations.PhoneNumber[lang]}
                {actorPhoneRequired && fieldRequiredSymbol}
                <PhoneInput
                  enableSearch
                  placeholder={actorPhonePlaceholder}
                  value={actorPhone.value}
                  priority={{ se: 1 }}
                  preferredCountries={['se']}
                  country="se"
                  inputProps={{
                    name: 'phoneNumber',
                  }}
                  autoFormat
                  disabled={isLoading}
                  onChange={(numberInput) => setActorPhone({ ...actorPhone, value: numberInput })}
                  onBlur={() => setActorPhone({ ...actorPhone, hasOnBlur: true })}
                  isValid={() => !actorPhone.hasOnBlur || actorPhoneValid}
                  defaultErrorMessage={actorPhoneErrorMessage(actorPhone.value, lang)}
                  inputClass="custom-phone-input"
                  inputStyle={{
                    backgroundColor: theme.palette.background.default,
                    borderColor: 'transparent',
                  }}
                />
              </Typography>
            )}

            {/* Upload Consent */}
            <Typography variant="bodySmall" align="center">
              {translations.UploadConsent[lang]}
              {', '} {parse(uploadConsentText?.trim()?.replace(/\n/g, '<br />'))}
            </Typography>

            {/* SoMe Consent */}
            {soMeConsentText != null && (
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
                {!soMeConsentRequired && (
                  <Checkbox
                    checked={soMeUploadConsent}
                    disabled={isLoading}
                    onChange={() => {
                      setSoMeUploadConsent(!soMeUploadConsent);
                    }}
                  />
                )}
                <Typography variant="bodySmall" align="center" style={{ flexGrow: 1 }}>
                  {soMeConsentRequired && (
                    <>
                      {translations.UploadConsent[lang]}
                      {', '}
                    </>
                  )}
                  {parse(soMeConsentText?.trim()?.replace(/\n/g, '<br />'))}
                </Typography>
              </Stack>
            )}

            <Box>
              <Button type="submit" variant="contained" fullWidth disabled={!formMaySubmit}>
                {translations.SubmitVideo[lang]}
                {isLoading && <CircularProgress size={20} />}
              </Button>
              <UploadProgress
                isUploadingLoading={isLoading}
                uploadProgress={uploadProgress}
                videoFile={videoFile}
              />
            </Box>
            <GDPRCompliant />
          </Stack>
        </Box>
      </Stack>

      <Recaptcha reCaptchaRef={reCaptchaRef} hidden />
    </>
  );
}

export default VideoUploadForm;
