export const generateRandomData = (duration) => {
  const currentDate = new Date();
  const generatedData = [];

  const selectedDuration = Number(duration) || 7;

  for (let i = 0; i < selectedDuration; i += 1) {
    if (selectedDuration <= 7) {
      for (let hour = 0; hour < 24; hour += 1) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);
        date.setHours(hour, 0, 0, 0); // Set minutes, seconds, and milliseconds to 0

        const baseCount = Math.floor(10 * Math.sin(hour * (Math.PI / 12)) + 24) + 1; // Higher amplitude, range 1 to 1600
        let count = baseCount;

        if (hour >= 22 || hour < 8) {
          count = Math.max(1, baseCount - 1); // Ensure count doesn't decrease too much during night hours
        } else if (hour >= 8 && hour < 18) {
          count = baseCount + 1; // Moderate increase during day hours
        }

        if (date.getDay() === 0 || date.getDay() === 6) {
          count = Math.max(1, baseCount - 1); // Ensure count doesn't decrease too much during weekends
        } else {
          count = baseCount + 1; // Moderate increase during weekdays
        }

        // Add more random noise to the count
        count += Math.floor(Math.random() * 30) - 15; // Random value between -15 and 15

        // Ensure the count is never less than 0
        count = Math.max(0, count);

        const formattedDate = date.toISOString().slice(0, 16).replace('T', ' '); // Format as 'YYYY-MM-DD HH:MM'
        generatedData.push({
          date: formattedDate,
          count,
        });
      }
    } else {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - i);
      date.setHours(12, 0, 0, 0); // Set to midday for daily entry

      const baseCount = Math.floor(10 * Math.sin(i * (Math.PI / 7)) + 24) + 1; // Higher amplitude, range 1 to 1600
      let count = baseCount;

      if (date.getDay() === 0 || date.getDay() === 6) {
        count = Math.max(1, baseCount - 1); // Ensure count doesn't decrease too much during weekends
      } else {
        count = baseCount + 1; // Moderate increase during weekdays
      }

      // Add more random noise to the count
      count += Math.floor(Math.random() * 30) - 15; // Random value between -15 and 15

      // Ensure the count is never less than 0
      count = Math.max(0, count);

      generatedData.push({
        date: date.toISOString().split('T')[0],
        count,
      });
    }
  }

  return generatedData;
};

export const randomArray = () => {
  const array = [];
  for (let i = 0; i < 5; i += 1) {
    array.push(Math.floor(Math.random() * 1000));
  }
  return array;
};
