/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { Alert, Backdrop, Button } from '@mui/material';
import { isValidPhoneNumberInput } from 'src/utils/utils';
import { useLanguage } from 'src/context/LanguageContext';
import validator from 'validator';
import PhoneNumberInput from '../back-office/Recipient/PhoneNumberInput';
import LoadingIndicator from '../common/LoadingIndicator';

interface User {
  id: number;
  superAdmin: boolean;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  unsubscribeEmails: boolean;
  permissions: [
    {
      organization: {
        id: number;
        orgName: string;
      };
    },
  ];
}

interface EditUserInnerProps {
  user: User;
}

const EDIT_USER_MUTATION = gql`
  mutation EditUser($input: UserInput!) {
    editUser(input: $input) {
      id
      superAdmin
      email
      phoneNumber
      firstName
      lastName
      unsubscribeEmails
    }
  }
`;

function EditUserInner({ user }: EditUserInnerProps) {
  const lang = useLanguage();
  const [email, setEmail] = useState(user.email || '');
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || '');
  const [unsubscribeEmails, setUnsubscribeEmails] = useState(user.unsubscribeEmails || false);
  const [success, setSuccess] = useState<boolean | string>(false);
  const [error, setError] = useState<boolean | string>(false);

  const [editUser, { loading }] = useMutation(EDIT_USER_MUTATION, {
    onCompleted: () => setSuccess("User's information has been updated"),
    onError: (err) => setError(`Error updating user: ${err.message}`),
  });

  const hasChanged = (field: keyof User, value: string | boolean): boolean => user[field] !== value;

  const getUpdatedFields = () => {
    const fields: Partial<User> = {};
    if (hasChanged('email', email)) fields.email = email;
    if (hasChanged('firstName', firstName)) fields.firstName = firstName;
    if (hasChanged('lastName', lastName)) fields.lastName = lastName;
    if (hasChanged('phoneNumber', phoneNumber)) fields.phoneNumber = phoneNumber;
    if (hasChanged('unsubscribeEmails', unsubscribeEmails))
      fields.unsubscribeEmails = unsubscribeEmails;
    return fields;
  };

  const hasChangedFields = () => Object.keys(getUpdatedFields()).length > 0;

  const doEditUser = (e: React.FormEvent) => {
    setSuccess(false);
    setError(false);
    e.preventDefault();
    const updatedFields = getUpdatedFields();

    if (!Object.keys(updatedFields).length) {
      setError('No changes detected');
      return;
    }

    if (!validator.isEmail(email)) {
      setError('Email must be valid');
      return;
    }

    if (!isValidPhoneNumberInput({ phoneNumber, lang, orgRecipients: null, editRecipient: null })) {
      setError('Invalid phone number');
      return;
    }

    editUser({
      variables: {
        input: {
          id: user.id,
          email,
          firstName,
          lastName,
          phoneNumber,
          unsubscribeEmails,
        },
      },
    });
  };

  return (
    <>
      <Backdrop open={loading}>
        <LoadingIndicator />
      </Backdrop>
      <div className="create-new-page">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <h1>Edit user #{user.id}</h1>
        <p>
          <Link to={`/admin/edit-user-permissions/${user.id}`}>Edit permissions</Link>
          &nbsp;
          <Link to={`/admin/reset-user-mfas/${user.id}`}>Reset MFAs</Link>
        </p>
        <p>Organization(s):</p>
        <ul>
          {user.permissions.map((up) => (
            <li key={`${up.organization.orgName}-${up.organization.id}`}>
              {up.organization.orgName} ({up.organization.id})
            </li>
          ))}
        </ul>
        <div>
          <form onSubmit={doEditUser}>
            <label>
              <span>
                Email *
                {email && !validator.isEmail(email) && (
                  <span style={{ color: 'red' }}> Must be valid email</span>
                )}
              </span>
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              <span>Telefonnummer *</span>
              <PhoneNumberInput
                actorPhoneNumber={phoneNumber}
                setActorPhoneNumber={setPhoneNumber}
                disabled={false}
                orgRecipients={null}
                editRecipient={null}
              />
            </label>
            <label>
              <span>Förnamn *</span>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </label>
            <label>
              <span>Efternamn *</span>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </label>
            <label htmlFor="unsubscribeEmails">
              <input
                type="checkbox"
                id="unsubscribeEmails"
                checked={unsubscribeEmails}
                onChange={() => setUnsubscribeEmails(!unsubscribeEmails)}
              />
              <span>Unsubscribe emails</span>
            </label>

            <Button
              variant="contained"
              type="submit"
              disabled={
                !hasChangedFields() ||
                !isValidPhoneNumberInput({
                  phoneNumber,
                  lang,
                  orgRecipients: null,
                  editRecipient: null,
                }) ||
                phoneNumber?.length < 5 ||
                !validator.isEmail(email) ||
                firstName?.trim() === '' ||
                lastName?.trim() === '' ||
                loading
              }
            >
              Uppdatera användare
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

const USER_QUERY = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      superAdmin
      email
      phoneNumber
      firstName
      lastName
      jobTitle {
        en
        sv
      }
      unsubscribeEmails
      permissions {
        organization {
          id
          orgName
        }
      }
    }
  }
`;

function EditUser() {
  const params = useParams<{ userid: string }>();
  const { data, loading, error } = useQuery(USER_QUERY, {
    variables: { id: Number(params.userid) },
  });

  if (loading) return <LoadingIndicator />;
  if (error) return <Alert severity="error">{`Error loading user: ${error.message}`}</Alert>;
  if (!data) return <Alert severity="info">User not found</Alert>;

  const { user } = data;
  return <EditUserInner user={user} />;
}

export default EditUser;
