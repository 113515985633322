import { Chip, Tooltip } from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import LocalPlayRoundedIcon from '@mui/icons-material/LocalPlayRounded';
import propTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';

export const happyScribeStatuses = {
  initial: {
    title: {
      en: 'Waiting',
      sv: 'Väntar',
    },
    description: {
      en: 'Transcription is waiting to be processed',
      sv: 'Transkribering väntar på att bearbetas',
    },
    color: 'info',
    icon: <HourglassEmptyRoundedIcon />,
    doneState: false,
  },
  ingesting: {
    title: {
      en: 'Ingesting',
      sv: 'Läser in',
    },
    description: {
      en: 'Media file is being ingested',
      sv: 'Mediefilen läses in',
    },
    color: 'info',
    icon: <HourglassEmptyRoundedIcon />,
    doneState: false,
  },
  automatic_transcribing: {
    title: {
      en: 'Transcribing',
      sv: 'Transkriberar',
    },
    description: {
      en: 'Audio is being transcribed to text',
      sv: 'Ljudet transkriberas till text',
    },
    color: 'primary',
    icon: <RecordVoiceOverRoundedIcon />,
    doneState: false,
  },
  automatic_done: {
    title: {
      en: 'Done',
      sv: 'Klart',
    },
    description: {
      en: 'Transcription is finished and ready to export!',
      sv: 'Transkriberingen är klar och redo för export!',
    },
    color: 'success',
    icon: <CheckCircleRoundedIcon />,
    doneState: true,
  },
  aligning: {
    title: {
      en: 'Aligning',
      sv: 'Justering',
    },
    description: {
      en: 'Text is being realigned with the audio',
      sv: 'Texten justeras med ljudet',
    },
    color: 'warning',
    icon: <CompareArrowsRoundedIcon />,
    doneState: false,
  },
  locked: {
    title: {
      en: 'Locked',
      sv: 'Låst',
    },
    description: {
      en: 'Transcription is locked due to insufficient credits',
      sv: 'Transkriberingen är låst på grund av otillräckliga krediter',
    },
    color: 'secondary',
    icon: <LockRoundedIcon />,
    doneState: true,
  },
  failed: {
    title: {
      en: 'Failed',
      sv: 'Misslyckades',
    },
    description: {
      en: 'File failed to process',
      sv: 'Misslyckades att bearbeta filen',
    },
    color: 'error',
    icon: <ReportProblemRoundedIcon />,
    doneState: true,
  },
  demo: {
    title: {
      en: 'Demo',
      sv: 'Demo',
    },
    description: {
      en: 'The initial demo file',
      sv: 'Den ursprungliga demo-filen',
    },
    color: 'info',
    icon: <LocalPlayRoundedIcon />,
    doneState: true,
  },
};

export function HappyScribeChip({ happyScribeResponseState }) {
  const lang = useLanguage();
  return (
    <Tooltip title={happyScribeStatuses?.[happyScribeResponseState]?.description?.[lang]}>
      <Chip
        icon={happyScribeStatuses?.[happyScribeResponseState]?.icon}
        label={happyScribeStatuses?.[happyScribeResponseState]?.title?.[lang]}
        color={happyScribeStatuses?.[happyScribeResponseState]?.color}
      />
    </Tooltip>
  );
}

export default HappyScribeChip;

HappyScribeChip.propTypes = {
  happyScribeResponseState: propTypes.string.isRequired,
};
