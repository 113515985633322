import { useQuery } from '@apollo/client';
import { ConversationalAvatarSettingsResult } from 'src/components/back-office/ConversationalAvatar/types';

import { AdminUserResult } from './types';
import { ADMIN_USER_QUERY, CONVERSATIONAL_AVATAR_SETTINGS_QUERY } from './queries';

export const useAdminUserData = () => {
  const { data, loading, error } = useQuery<AdminUserResult>(ADMIN_USER_QUERY);

  return { data, loading, error };
};

export const useAvatarSettings = () => {
  const { data, loading, error } = useQuery<ConversationalAvatarSettingsResult>(
    CONVERSATIONAL_AVATAR_SETTINGS_QUERY,
  );

  return { data, loading, error };
};
