import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLanguage } from 'src/context/LanguageContext';
import { formatTime } from 'src/utils/utils';
import { StatsByTypeTotalResult } from '../Analytics/Overview/analyticsOverviewApi';
import translations from './translations';

type Layout = 'vertical' | 'horizontal';
type Title = 'videoStats' | 'storyStats' | null;

interface StatsDisplayProps {
  stats: StatsByTypeTotalResult;
  title?: Title;
  layout?: Layout;
}

function StatsDisplay({ stats, layout = 'vertical', title = null }: StatsDisplayProps) {
  const lang = useLanguage();

  const renderStatBox = (statTitle: string, value: string | number, ariaLabel: string) => (
    <Box
      className="stats-box"
      sx={{
        mb: 2,
        display: layout === 'horizontal' ? 'flex' : 'block',
        alignItems: layout === 'horizontal' ? 'baseline' : 'center',
        px: layout === 'horizontal' ? 1.5 : 0,
      }}
      data-testid={`stats-box-${statTitle.replace(/\s+/g, '-').toLowerCase()}`}
      aria-labelledby={`title-${statTitle.replace(/\s+/g, '-').toLowerCase()}`}
    >
      <Typography
        variant={title ? 'titleSmall' : 'titleMedium'}
        component="h3"
        data-testid={`title-${statTitle.replace(/\s+/g, '-').toLowerCase()}`}
        id={`title-${statTitle.replace(/\s+/g, '-').toLowerCase()}`}
        aria-label={ariaLabel}
        sx={{ pr: layout === 'horizontal' ? 0.5 : 0 }}
        color="text"
        noWrap
      >
        {statTitle}
      </Typography>
      <Typography
        noWrap
        variant="labelMedium"
        data-testid={`data-${statTitle.replace(/\s+/g, '-').toLowerCase()}`}
        aria-label={`${statTitle}: ${value}`}
        color="text.secondary"
        sx={{ display: 'inline-flex', alignItems: 'baseline' }}
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box
      className="stats-box-wrapper"
      sx={{
        p: 0,
        display: layout === 'horizontal' ? 'flex' : 'block',
        alignItems: layout === 'horizontal' ? 'baseline' : 'center',
        whiteSpace: 'nowrap',
        justifyContent: layout === 'horizontal' ? 'space-between' : 'flex-start',
      }}
      data-testid="stats-wrapper"
      role="region"
      aria-label={translations.videoStats[lang]}
    >
      {title && (
        <Typography
          variant="titleLarge"
          component="h2"
          data-testid="header"
          tabIndex={0}
          aria-label={translations.videoStats[lang]}
          sx={{ marginRight: layout === 'horizontal' ? 2 : 0, whiteSpace: 'nowrap' }}
        >
          {translations[title][lang]}
        </Typography>
      )}
      {renderStatBox(
        translations.videoStatsTitles.playback.impression[lang],
        stats.playback.impression,
        translations.ariaLabels.playback.impression[lang],
      )}
      {renderStatBox(
        translations.videoStatsTitles.playback.timePlayed[lang],
        formatTime(stats.playback.timePlayed, lang),
        translations.ariaLabels.playback.timePlayed[lang],
      )}
      {renderStatBox(
        translations.videoStatsTitles.interactions.total[lang],
        stats.interactions.total,
        translations.ariaLabels.interactions.total[lang],
      )}
    </Box>
  );
}

export default StatsDisplay;
