/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

// GraphQL query
export const GET_ORG_USERS = gql`
  query GetOrgUsers($encodedOrgId: String!) {
    getOrgUsers(encodedOrgId: $encodedOrgId) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
      }
    }
  }
`;

export const UPDATE_USER_PERMISSION = gql`
  mutation UpdateUserPermission(
    $encodedOrgId: String!
    $updateUserId: Int!
    $permission: PermissionType!
  ) {
    updateUserPermission(
      encodedOrgId: $encodedOrgId
      updateUserId: $updateUserId
      permission: $permission
    ) {
      id
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
        orgId
      }
    }
  }
`;
