import './BackgroundCircles.scss';

import PropTypes from 'prop-types';

function BackgroundCircles({ blueStyle = {}, pinkStyle = {} }) {
  return (
    <div>
      <img className="pink-circle" src="/images/circle-pink.svg" alt="" style={pinkStyle} />
      <img className="blue-circle" src="/images/circle-blue.svg" alt="" style={blueStyle} />
    </div>
  );
}

const styleShape = PropTypes.shape({
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  transform: PropTypes.string,
});

BackgroundCircles.propTypes = {
  blueStyle: styleShape,
  pinkStyle: styleShape,
};

export default BackgroundCircles;
