import PropTypes from 'prop-types';

function PublicLibraryOutlinedIcon({ fill = '#FFFFFF' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V6.50001C3 6.25001 3.04167 6.02501 3.125 5.82501C3.20834 5.62501 3.31667 5.43334 3.45 5.25001L4.85 3.55001C4.98333 3.36667 5.15 3.22901 5.35 3.13701C5.55 3.04501 5.76667 2.99934 6 3.00001H18C18.2333 3.00001 18.45 3.04567 18.65 3.13701C18.85 3.22834 19.0167 3.36601 19.15 3.55001L20.55 5.25001C20.6833 5.43334 20.7917 5.62501 20.875 5.82501C20.9583 6.02501 21 6.25001 21 6.50001V19C21 19.55 20.804 20.021 20.412 20.413C20.02 20.805 19.5493 21.0007 19 21H5ZM5.4 6.00001H18.6L17.75 5.00001H6.25L5.4 6.00001ZM5 8.00001V19H19V8.00001H5ZM12 18L16 14L14.6 12.6L13 14.2V10H11V14.2L9.4 12.6L8 14L12 18Z"
        fill={fill}
      />
    </svg>
  );
}

export default PublicLibraryOutlinedIcon;

PublicLibraryOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
