import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { matchSorter } from 'match-sorter';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';

import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import SimpleTextField from '../../common/SimpleTextField';
import DataGridTable from '../../common/DataGridTable';
import CsvImportDialog from '../Recipient/CsvImportDialog';
import CreateRecipientDialog from '../Recipient/CreateRecipientDialog';
import { useRecipientActions } from '../Recipient/actions';
import { translations } from '../../../utils/translationsSettings';

import useCheckPermissionsAndNavigate from './Permissions/CheckPermissionsAndNavigate';

export default function Recipients() {
  const lang = useLanguage();
  const recipientActions = useRecipientActions();
  const { basicOrgData } = useOutletContext();
  const [openBasicAddDialog, setOpenBasicAddDialog] = useState(false);
  const [openCsvImportDialog, setOpenCsvImportDialog] = useState(false);
  const [editRecipient, setEditRecipient] = useState(null);
  const [searchField, setSearchField] = useState('');

  const [recipients, setRecipients] = useState([]);
  const [unfilteredRecipients, setUnfilteredRecipients] = useState([]);

  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }, [error]);

  useEffect(() => {
    recipientActions
      .recipientsByOrgId({ orgId: basicOrgData.id, encodedOrgId: basicOrgData.encodedId })
      .then((res) => {
        setRecipients(res?.data?.recipientsByOrgId || []);
        setUnfilteredRecipients(res?.data?.recipientsByOrgId || []);
        handleChangeSearchField('', res?.data?.recipientsByOrgId || []);
      })
      .catch((err) => {
        setError(translations.failedToFetchData[lang]);
      });
  }, [recipientActions]);

  const hasAutoRequest = basicOrgData?.premiumFeatures?.autoRequests;

  const columns = [
    {
      field: 'firstName',
      headerName: translations.recipient.dataGrid.header.firstName[lang],
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: translations.recipient.dataGrid.header.lastName[lang],
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'title',
      headerName: translations.recipient.dataGrid.header.title[lang],
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'leader',
      headerName: translations.recipient.dataGrid.header.leader[lang],
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.leader ? (
          <IconButton
            onClick={() => {
              toggleLeader(params.id);
            }}
          >
            <CheckIcon sx={{ color: '#74bf66' }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              toggleLeader(params.id);
            }}
          >
            <CloseIcon sx={{ color: '#d76767' }} />
          </IconButton>
        );
      },
    },
    {
      field: 'email',
      headerName: translations.recipient.dataGrid.header.email[lang],
      minWidth: 250,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    ...(basicOrgData?.premiumFeatures?.textMessage
      ? [
          {
            field: 'phoneNumber',
            headerName: translations.recipient.dataGrid.header.phoneNumber[lang],
            minWidth: 250,
            disableColumnMenu: true,

            renderCell: (params) => {
              const phoneNumber = params.row?.phoneNumber;
              if (!phoneNumber) return '';

              return (phoneNumber?.startsWith('+') ? phoneNumber : `+${phoneNumber}`)?.trim();
            },
          },
        ]
      : []),

    ...(hasAutoRequest
      ? [
          {
            field: 'receiveAutoRequests',
            headerName: translations.recipient.dataGrid.header.receiveAutoRequests[lang],
            minWidth: 200,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) => {
              return (
                <Switch
                  color="secondary"
                  checked={params.row.receiveAutoRequests}
                  onChange={() => {
                    toggleActiveForAutomation(params.id);
                  }}
                />
              );
            },
          },
        ]
      : []),

    {
      isRowSelectable: false,
      field: 'actions',
      headerName: translations.recipient.dataGrid.header.edit[lang],
      minWidth: 100,
      flex: 0,
      renderCell: (params) => {
        if (hoveredRow === params.id && params.row.id >= 0) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => {
                  setEditRecipient(params.row);
                  setOpenBasicAddDialog(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Box>
          );
        }
        return null;
      },
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const handleRowChange = (params) => {
    const { id, field, value } = params;
    const updatedRecipient = recipients?.find((r) => r.id === id);
    if (updatedRecipient && updatedRecipient[field] !== value) {
      updatedRecipient[field] = value;
      handleChangeSearchField(searchField, [
        ...recipients?.filter((r) => r.id !== id),
        updatedRecipient,
      ]);
      recipientActions
        .updateRecipient({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          id,
          [field]: value,
        })
        .then((res) => {
          setUnfilteredRecipients(res.data.updateRecipient.recipients);
          handleChangeSearchField(searchField, res.data.updateRecipient.recipients);
          setInfo(translations.recipient.updateSuccess[lang]);
        })
        .catch((err) => {
          setError(translations.recipient.updateError[lang]);
        });
    }
  };

  const handleUpdate = ({ recipient, receiveAutoRequests, leader }) => {
    recipientActions
      .updateRecipient({
        encodedOrgId: basicOrgData.encodedId,
        orgId: basicOrgData.id,
        id: recipient.id,
        receiveAutoRequests,
        leader,
      })
      .then((res) => {
        setUnfilteredRecipients(res.data.updateRecipient.recipients);
        handleChangeSearchField(searchField, res.data.updateRecipient.recipients);
        setInfo(translations.recipient.updateSuccess[lang]);
      })
      .catch((err) => {
        setError(translations.recipient.updateError[lang]);
      });
  };

  const toggleLeader = (id) => {
    const recipient = recipients?.find((r) => r.id === id);
    const newRecipients = [
      ...recipients.map((row) => (row.id === id ? { ...row, leader: !row.leader } : row)),
    ];
    setUnfilteredRecipients(newRecipients);
    handleChangeSearchField(searchField, newRecipients);
    handleUpdate({ recipient, leader: !recipient?.leader });
  };

  const toggleActiveForAutomation = (id) => {
    const recipient = recipients?.find((r) => r.id === id);
    const newRecipients = [
      ...recipients.map((row) =>
        row.id === id ? { ...row, receiveAutoRequests: !row.receiveAutoRequests } : row,
      ),
    ];
    setUnfilteredRecipients(newRecipients);
    handleChangeSearchField(searchField, newRecipients);
    handleUpdate({ recipient, receiveAutoRequests: !recipient.receiveAutoRequests });
  };
  const updateRecipientData = (newRecipients) => {
    setRecipients(newRecipients);
    setOpenCsvImportDialog(false);
  };
  const handleCloseCrewNew = (value) => {
    setOpenBasicAddDialog(false);
    setEditRecipient(null);
    if (value) {
      const selectedRecipients = [...recipients, value];
      setUnfilteredRecipients(selectedRecipients);
      handleChangeSearchField(searchField, selectedRecipients);
    }
  };

  const handleChangeSearchField = (value = searchField, newRecipients = unfilteredRecipients) => {
    setSearchField(value);
    const filtered = matchSorter(
      newRecipients,
      value,
      { keys: ['firstName', 'lastName', 'title', 'email', 'phoneNumber'] },
      { threshold: matchSorter.rankings.EQUAL },
    );
    setRecipients(filtered);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const onMouseEnterRow = (event) => {
    const rowId = Number(event.currentTarget.dataset.id);
    const row = recipients.find((el) => el.id === rowId);
    setHoveredRow(row.id);
  };

  const onMouseLeaveRow = () => {
    setHoveredRow(null);
  };

  if (userLoading) return <LoadingIndicator />;

  const infoMsg = <div className="info-pop">{info}</div>;
  const errorMsg = <div className="error-pop">{error}</div>;

  return (
    <div className="inner">
      {infoMsg}
      {errorMsg}
      <div>
        <div className="section-row">
          <FormControl sx={{ m: 1, minWidth: 400 }}>
            <SimpleTextField
              type="search"
              value={searchField}
              onChange={(e) => handleChangeSearchField(e.target.value, unfilteredRecipients)}
              placeholder={translations.recipient.search[lang]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <div>
            <FormControl sx={{ m: 1 }}>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setEditRecipient(null);
                  setOpenBasicAddDialog(true);
                }}
              >
                {translations.recipient.addRecipient[lang]}
              </Button>
            </FormControl>
            <FormControl sx={{ m: 1 }}>
              <Button
                onClick={() => {
                  setOpenCsvImportDialog(true);
                }}
              >
                <AddIcon />
                {translations.recipient.importFromCsv[lang]}
              </Button>
            </FormControl>
          </div>
        </div>
        {(openBasicAddDialog || editRecipient) && (
          <CreateRecipientDialog
            open={openBasicAddDialog}
            onClose={handleCloseCrewNew}
            basicOrgData={basicOrgData}
            textMessageAllowed={basicOrgData?.premiumFeatures?.textMessage ?? false}
            orgRecipients={recipients}
            setRecipientOptions={(e) => {
              setUnfilteredRecipients(e);
              handleChangeSearchField(searchField, e);
            }}
            handleUpdateBoard={(e) => {}}
            settingsView
            editRecipient={editRecipient}
            hasAutoRequest={basicOrgData?.premiumFeatures?.autoRequests ?? false}
            setError={setError}
            setInfo={setInfo}
          />
        )}

        {openCsvImportDialog && (
          <CsvImportDialog
            open={openCsvImportDialog}
            onClose={() => setOpenCsvImportDialog(false)}
            onCloseUpdate={updateRecipientData}
            basicOrgData={basicOrgData}
            textMessageAllowed={basicOrgData?.premiumFeatures?.textMessage ?? false}
            orgRecipients={recipients}
            setRecipientOptions={(e) => {
              setUnfilteredRecipients(e);
              handleChangeSearchField(searchField, e);
            }}
            handleUpdateBoard={(e) => {}}
            settingsView
          />
        )}

        <div className="section">
          <DataGridTable
            rows={recipients}
            columns={columns}
            pageSize={12}
            onCellEditCommit={(params) => {
              handleRowChange(params);
            }}
            initialState={{ pinnedColumns: { right: ['actions'] } }}
            slotProps={{
              row: {
                onMouseEnter: onMouseEnterRow,
                onMouseLeave: onMouseLeaveRow,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
