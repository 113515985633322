import React, { forwardRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import SplitButton, { SplitButtonOption } from 'src/components/common/SplitButton';
import videoCollectorTranslations from '../../VideoCollectorTranslations';

interface ReviewFooterProps {
  disabled: boolean;
  onSelectAllClicked: () => void;
  selectedEncodedVideoIds: string[];
  splitButtonOptions: SplitButtonOption[];
  processingVideoCards: number;
  totalVideoCards: number;
}

const ReviewFooter = forwardRef(
  (
    {
      disabled,
      onSelectAllClicked,
      selectedEncodedVideoIds,
      splitButtonOptions,
      processingVideoCards,
      totalVideoCards,
    }: ReviewFooterProps,
    ref,
  ) => {
    const lang = useLanguage();
    const [navBarWidth, setNavBarWidth] = useState(120);

    useEffect(() => {
      const updateWidth = () => {
        const navBar = document.getElementById('navigation-header-bar-left-side');
        const currentOffsetWidth = navBar?.offsetWidth ?? 0;

        if (currentOffsetWidth > 0) {
          setNavBarWidth(currentOffsetWidth);
        }
      };

      updateWidth();
      window.addEventListener('resize', updateWidth);

      return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return (
      <Box
        ref={ref}
        role="contentinfo"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: `${navBarWidth}px`,
          right: 0,
          zIndex: 1,
          height: 'auto',
          backgroundColor: 'background.paper',
          py: 0.5,
          px: 3,
        }}
      >
        <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
          <Box>
            <Typography align="center" variant="labelLarge">
              ({processingVideoCards} {videoCollectorTranslations.processing[lang]})
            </Typography>
          </Box>
          <Stack direction="row" spacing={0}>
            <Stack
              direction="row"
              spacing={0}
              alignItems="center"
              sx={{
                m: 0.5,
              }}
            >
              <Typography align="center" variant="labelLarge">
                {selectedEncodedVideoIds.length}{' '}
                {videoCollectorTranslations.selected[lang](selectedEncodedVideoIds.length)}{' '}
              </Typography>
            </Stack>
            <Button
              disabled={disabled}
              onClick={onSelectAllClicked}
              data-testid="select-all-button"
              variant="outlined"
              sx={{
                minWidth: `${
                  Math.max(
                    videoCollectorTranslations.deselectAll[lang].length,
                    videoCollectorTranslations.selectAll[lang].length,
                  ) * 11
                }px`,
              }}
            >
              {totalVideoCards === selectedEncodedVideoIds.length
                ? videoCollectorTranslations.deselectAll[lang]
                : videoCollectorTranslations.selectAll[lang]}
            </Button>
            <SplitButton
              options={splitButtonOptions}
              disabled={disabled || selectedEncodedVideoIds.length === 0}
              disableElevation
            />
          </Stack>
        </Stack>
      </Box>
    );
  },
);
export default ReviewFooter;
