import React, { useState, useEffect } from 'react';
import CardHeader from '@mui/material/CardHeader';

const styling = {
  shared: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'none',
    textTransform: 'none',
    cursor: 'pointer',
    '.MuiTypography-root': {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: '20px',
      fontWeight: 350,
      color: '#34363a',
    },
  },
};

export default function SimpleCardHeader(props) {
  return <CardHeader {...props} sx={{ ...styling.shared }} />;
}
