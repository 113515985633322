import React, { memo, useState, useEffect, useRef } from 'react';
import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LazyLoad from 'react-lazy-load';

import { useLanguage } from 'src/context/LanguageContext';
import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';

import { VideoCard as VideoCardType } from 'src/utils/video-card/utils';
import { translations } from '../../utils/translations';
import VideoCardSettings from './VideoCardSettings';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../fragments/videoCards';
import VideoStorySettings from './VideoStorySettings';
import ConfirmPop from '../common/ConfirmPop';
import { useActions } from './actions';
import AlertDialog from '../common/AlertDialog';
import HoverVideo from './HoverVideo';

export interface OrgDataType {
  encodedId: string;
  id: number;
}

export interface ConfirmPopData {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
}
export const getVideoCard = (
  backOfficeClient: ApolloClient,
  encodedOrgId: string,
  videoCardId: number,
) => {
  return backOfficeClient.query({
    query: gql`
      ${VIDEO_CARD_DETAILS_FRAGMENT}
      query VideoCard($encodedOrgId: String!, $videoCardId: Int!) {
        videoCard(encodedOrgId: $encodedOrgId, id: $videoCardId) {
          ...VideoCardDetails
        }
      }
    `,
    variables: { encodedOrgId, videoCardId },
  });
};

interface VideoCardStoryProps {
  item: VideoCardType;
  orgData: OrgDataType;
  setAffectedItem: (item: VideoCardType | null) => void;
  affectedId?: number;
  setInfo: (info: string) => void;
  suggestedQuestions?: unknown[];
  handleDuplicateItemClick: (item: VideoCardType) => void;
  handleUpdateBoard: () => void;
  orgRecipients?: unknown[];
  updateVideoCard: (item: VideoCardType) => void;
  setError: (error: string) => void;
}

function VideoCardStoryComponent({
  item,
  orgData,
  setAffectedItem,
  affectedId = undefined,
  setInfo,
  suggestedQuestions = [],
  handleDuplicateItemClick,
  handleUpdateBoard,
  orgRecipients = [],
  updateVideoCard,
  setError,
}: VideoCardStoryProps) {
  const lang = useLanguage();
  const actions = useActions();
  const backOfficeClient = useBackOfficeApolloClient();
  const [videoCard, setVideoCard] = useState(item);
  const [affected, setAffected] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [confirmPopData, setConfirmPopData] = useState<ConfirmPopData | null>(null);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<boolean | string>(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const [cardIsOpen, setCardIsOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (affected) {
      setTimeout(() => {
        if (isMounted) {
          setAffectedItem(null);
        }
      }, 2000);
    }
    return () => {
      isMounted = false;
    };
  }, [affected, setAffectedItem]);

  useEffect(() => {
    let isMounted = true;
    if (
      !videoCard?.lastPullFromDatabase ||
      (videoCard?.lastPullFromDatabase &&
        Math.floor(
          Math.abs(new Date().getTime() - new Date(videoCard.lastPullFromDatabase).getTime()) /
            1000,
        ) > 120)
    ) {
      getVideoCard(backOfficeClient, orgData.encodedId, videoCard?.id)
        .then((res) => {
          if (isMounted) {
            const newVideoCard = {
              ...res.data.videoCard,
              lastPullFromDatabase: new Date().toISOString(),
            };
            setVideoCard(newVideoCard);
            updateVideoCard(newVideoCard);
          }
        })
        .catch(() => {});
    }

    return () => {
      isMounted = false;
    };
  }, [
    orgData.encodedId,
    videoCard?.id,
    videoCard?.lastPullFromDatabase,
    updateVideoCard,
    backOfficeClient,
  ]);

  useEffect(() => {
    if (affectedId === videoCard?.id) {
      setAffected(true);
    } else {
      setAffected(false);
    }
  }, [affectedId, videoCard?.id]);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpenMenu(false);
  };

  const prevOpen = useRef(openMenu);
  useEffect(() => {
    if (
      prevOpen.current === true &&
      openMenu === false &&
      anchorRef.current &&
      anchorRef.current.focus
    ) {
      anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  const handleDeleteItemClick = () => {
    actions
      .deleteVideoCard(orgData.encodedId, videoCard?.id, orgData.id)
      .then(() => {
        handleUpdateBoard();
        setInfo(translations.videoCard?.deleteSuccessMessage[lang]);
      })
      .catch(() => {
        setError(translations.videoCard.deleteErrorMessage[lang]);
      });

    setConfirmPopData(null);
  };

  const deleteItem = () => {
    if (videoCard?.id) {
      actions
        .getVideoCardVideoFunnelStatus({
          id: videoCard?.id,
          orgId: orgData.id,
          encodedOrgId: orgData.encodedId,
        })
        .then((res) => {
          const videoFunnelCount = res?.length ?? 0;
          const videoFunnelEdgeCount = res
            ?.map((vf: { videoFunnelEdges: [] }) => vf?.videoFunnelEdges?.length ?? 0)
            .reduce((a: number, b: number) => a + b, 0);

          const videoFunnelNodeCount = res
            ?.map((vf: { videoFunnelNodes: [] }) => vf?.videoFunnelNodes?.length ?? 0)
            .reduce((a: number, b: number) => a + b, 0);

          if (videoFunnelCount === 0) {
            handleDeleteItemClick();
          } else {
            setDeleteConfirmDialog(
              translations.deleteInVideoFunnel[lang]({
                videoFunnelCount,
                videoFunnelEdgeCount,
                videoFunnelNodeCount,
                videoFunnelTitles: res?.map((vf: { title: string }) => vf?.title),
              }).join(' '),
            );
          }
        })
        .catch(() => {});
    }
  };

  const deleteItemConfirm = () => {
    setConfirmPopData({
      title: translations.videoCard?.deleteItem[lang],
      description: translations.videoCard?.areYouSureDelete[lang],
      onCancel: () => {
        setConfirmPopData(null);
      },
      onConfirm: () => deleteItem(),
    });
  };

  const updateCardIsOpen = (value: boolean) => {
    setCardIsOpen(value);
  };

  if (videoCard) {
    return (
      <>
        <Card
          role="button"
          tabIndex={0}
          elevation={0}
          onClick={() => updateCardIsOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              updateCardIsOpen(true);
            }
          }}
          sx={{
            m: 1,
            width: '100%',
            height: '100%',
            background: 'white',
            borderRadius: '8px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            transition: 'all 0.2s ease-in-out',
            ':hover': {
              transform: 'scale(1.03)',
              cursor: 'pointer',
            },
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 0, width: '100%', height: '100%' }}
          >
            <LazyLoad width={56} height={100} offset={600}>
              <HoverVideo videoCard={videoCard} />
            </LazyLoad>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{
                m: 0.5,
                p: 0.5,
                width: '100%',
                height: '100%',
              }}
            >
              <Grid item container xs justifyContent="space-between">
                <Grid item xs={10} xl={10} sm={10} md={10} lg={10}>
                  <Typography
                    variant="titleSmall"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      maxHeight: `${2 * 1.43}em`,
                    }}
                  >
                    {videoCard?.question && videoCard?.question[lang]
                      ? videoCard?.question[lang]
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={2} xl={2} sm={2} md={2} lg={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                      height: '100%',
                      mt: 0.5,
                    }}
                  >
                    <VideoStorySettings
                      anchorRef={anchorRef}
                      openMenu={openMenu}
                      handleToggle={handleToggle}
                      handleClose={handleClose}
                      handleDuplicateItemClick={handleDuplicateItemClick}
                      videoCard={videoCard}
                      deleteItemConfirm={deleteItemConfirm}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs>
                <Typography variant="bodySmall">
                  {videoCard?.recipient?.name ?? videoCard?.actorName ?? ''}
                </Typography>
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    height: '100%',
                  }}
                >
                  <Typography variant="bodySmall">{videoCard?.user?.firstName ?? ''}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
        {cardIsOpen && (
          <VideoCardSettings
            item={videoCard}
            close={() => updateCardIsOpen(false)}
            orgData={orgData}
            setAffected={setAffectedItem}
            setInfo={setInfo}
            suggestedQuestions={suggestedQuestions}
            handleDuplicateItemClick={(itemToDuplicate: VideoCardType) =>
              handleDuplicateItemClick(itemToDuplicate)
            }
            handleUpdateBoard={handleUpdateBoard}
            orgRecipients={orgRecipients}
            duplicateItem={null}
            setVideoCardData={updateVideoCard}
          />
        )}
        {confirmPopData && (
          <ConfirmPop
            title={confirmPopData.title}
            description={confirmPopData.description}
            onCancel={confirmPopData.onCancel}
            onConfirm={confirmPopData.onConfirm}
          />
        )}
        {deleteConfirmDialog && (
          <AlertDialog
            open={!!deleteConfirmDialog}
            title={translations.confirmDeleteTitle[lang]}
            description={typeof deleteConfirmDialog === 'string' ? deleteConfirmDialog : ''}
            confirmBtnText={translations.deleteConfirmBtnText[lang]}
            cancelBtnText={translations.deleteCancelBtnText[lang]}
            onConfirm={() => {
              setDeleteConfirmDialog(false);
            }}
            onCancel={() => {
              setDeleteConfirmDialog(false);
            }}
          />
        )}
      </>
    );
  }
  return null;
}

VideoCardStoryComponent.propTypes = {
  item: PropTypes.shape({}).isRequired,
  orgData: PropTypes.shape({
    encodedId: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  setAffectedItem: PropTypes.func.isRequired,
  affectedId: PropTypes.number,
  setInfo: PropTypes.func.isRequired,
  suggestedQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  handleDuplicateItemClick: PropTypes.func.isRequired,
  handleUpdateBoard: PropTypes.func.isRequired,
  orgRecipients: PropTypes.arrayOf(PropTypes.shape({})),
  updateVideoCard: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

const VideoCardStory = memo(VideoCardStoryComponent, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});

export default VideoCardStory;
