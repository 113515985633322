import { Navigate, Route } from 'react-router';

import EmailPreferences from '../components/public/EmailPreferences';
import FreemiumSignup from '../components/public/Freemium/FreemiumSignup';
import Library from '../components/public/Library';
import Login from '../components/public/Login';
import Upload from '../components/public/Upload';
import PublicVideoCollectorDispatcher from '../components/public/video-collector';

const publicRoutes = (
  <>
    <Route path="/signup" element={<FreemiumSignup />} />

    <Route path="/login" element={<Login />} />
    <Route index element={<Navigate to="/login" replace />} />
    <Route path="/admin-login" element={<Navigate to="/login" replace />} />

    <Route path="/:orgnameslug" element={<Library />} />

    <Route path="/:orgnameslug/login" element={<Login />} />

    <Route path="/:encodedorgid/video/upload/:videocardid" element={<Upload />} />
    <Route path="/:encodedorgid/upload/:videocardid" element={<Upload />} />
    <Route path="/upload/:videocardid" element={<Upload />} />
    <Route path="/video/upload/:videocardid" element={<Upload />} />
    <Route
      path="/:encodedorgid/collect/video/:encodedvideocollectorid"
      element={<PublicVideoCollectorDispatcher />}
    />
    <Route path="/:encodedorgid/email-preferences/:usertoken" element={<EmailPreferences />} />
  </>
);

export default publicRoutes;
