import { ApolloClient as ApolloClientT, NormalizedCacheObject } from '@apollo/client';
import React, { createContext, useContext } from 'react';

export type ApolloClient = ApolloClientT<NormalizedCacheObject>;

const PublicApolloClientContext = createContext<ApolloClient | null>(null);
const BackOfficeApolloClientContext = createContext<ApolloClient | null>(null);
const BackOfficeStatsApolloClientContext = createContext<ApolloClient | null>(null);
const AdminApolloClientContext = createContext<ApolloClient | null>(null);

export function PublicApolloClientProvider({
  children,
  client,
}: {
  children: React.ReactNode;
  client: ApolloClient;
}) {
  return (
    <PublicApolloClientContext.Provider value={client}>
      {children}
    </PublicApolloClientContext.Provider>
  );
}

export function BackOfficeApolloClientProvider({
  children,
  client,
}: {
  children: React.ReactNode;
  client: ApolloClient | null;
}) {
  return (
    <BackOfficeApolloClientContext.Provider value={client}>
      {children}
    </BackOfficeApolloClientContext.Provider>
  );
}

export function BackOfficeStatsApolloClientProvider({
  children,
  client,
}: {
  children: React.ReactNode;
  client: ApolloClient | null;
}) {
  return (
    <BackOfficeStatsApolloClientContext.Provider value={client}>
      {children}
    </BackOfficeStatsApolloClientContext.Provider>
  );
}

export function AdminApolloClientProvider({
  children,
  client,
}: {
  children: React.ReactNode;
  client: ApolloClient | null;
}) {
  return (
    <AdminApolloClientContext.Provider value={client}>{children}</AdminApolloClientContext.Provider>
  );
}

export const usePublicApolloClient = () => {
  const client = useContext(PublicApolloClientContext);
  if (client == null) {
    throw new Error('must be within PublicApolloClientProvider');
  }
  return client;
};

export const useBackOfficeApolloClientUnsafe = () => {
  return useContext(BackOfficeApolloClientContext);
};

export const useBackOfficeApolloClient = () => {
  const client = useBackOfficeApolloClientUnsafe();
  if (client == null) {
    throw new Error('must be within BackOfficeApolloClientProvider');
  }
  return client;
};

export const useBackOfficeStatsApolloClientUnsafe = () => {
  return useContext(BackOfficeStatsApolloClientContext);
};

export const useBackOfficeStatsApolloClient = () => {
  const client = useBackOfficeStatsApolloClientUnsafe();
  if (client == null) {
    throw new Error('must be within BackOfficeStatsApolloClientProvider');
  }
  return client;
};

export const useAdminApolloClientUnsafe = () => {
  return useContext(AdminApolloClientContext);
};

export const useAdminApolloClient = () => {
  const client = useAdminApolloClientUnsafe();
  if (client == null) {
    throw new Error('must be within AdminApolloClientProvider');
  }
  return client;
};
