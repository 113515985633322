import { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import './SubtitleUpload.scss';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useLanguage } from 'src/context/LanguageContext';
import { useActions } from '../../actions';
import { outlinedInput } from '../../../common/SplitButton/SplitButtonThemes';
import AlertDialog from '../../../common/AlertDialog';
import DataGridTable from '../../../common/DataGridTable';
import { useSubtitleApi } from './SubtitleAPI';
import { SubtitleTranslation } from '../../../../utils/subtitleTranslation';
import { isValidHttpUrl } from '../../../../utils/utils';

const style = {
  bgcolor: 'background.paper',
  borderRight: '1px solid #e9edfd',
  p: 4,
  zIndex: 1,
  padding: '0px',
  paddingBottom: '0px',
  marginBottom: '0px',
  bottom: '58px',
  right: '280px',
  left: '0px',
  top: '0px',
};

const extractVideoIdFromVideoLink = (videoLink) => {
  const path = new URL(videoLink);
  const pathElements = path.pathname.split('/');
  return pathElements[pathElements.length - 1];
};

export default function ManageSubtitleTransitionsModal({
  open,
  setOpen,
  videoCard,
  basicOrgData,
  subtitles,
  setSubtitles,
}) {
  const lang = useLanguage();
  const actions = useActions();
  const subtitleApi = useSubtitleApi();
  const handleClose = () => setOpen(false);
  const [rows, setRows] = useState(
    videoCard?.subtitles?.map((sub) => ({ id: _.uniqueId(sub?.srcLang), ...sub })) ?? [],
  );

  const [hoveredRow, setHoveredRow] = useState(null);

  const [success, setSuccess] = useState(false);
  const [selectedLang, setSelectedLang] = useState('');
  const [isDefault, setIsDefault] = useState(
    !(videoCard?.subtitles?.length && videoCard?.subtitles?.length > 0),
  );
  const [fileName, setFileName] = useState('');
  const videoId =
    (videoCard?.videoLink?.en && extractVideoIdFromVideoLink(videoCard?.videoLink?.en)) ??
    (videoCard?.videoLink?.sv && extractVideoIdFromVideoLink(videoCard?.videoLink?.sv)) ??
    null;

  const [subFile, setSubFile] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [rowToDelete, setRowToDelete] = useState(null);
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);

  const [LanguageSelectionOptions, setLanguageSelectionOptions] = useState(
    Object.keys(SubtitleTranslation?.languageAcronym)
      ?.sort((a, b) => a.localeCompare(b))
      ?.map((key) => {
        return (
          <MenuItem value={key} key={_.uniqueId(key)}>
            {
              SubtitleTranslation?.[
                SubtitleTranslation?.languageAcronyms?.[key]?.en?.toLowerCase()
              ]?.[lang]
            }
            {videoCard?.subtitles?.find((sub) => sub.srcLang === key)
              ? ` (${SubtitleTranslation.languageReUpload[lang]})`
              : ''}
          </MenuItem>
        );
      }) ?? [],
  );

  useEffect(() => {
    setLanguageSelectionOptions(
      Object.keys(SubtitleTranslation?.languageAcronym)
        ?.sort((a, b) => a.localeCompare(b))
        ?.map((key) => {
          return (
            <MenuItem value={key} key={_.uniqueId(key)}>
              {
                SubtitleTranslation?.[
                  SubtitleTranslation?.languageAcronyms?.[key]?.en?.toLowerCase()
                ]?.[lang]
              }
              {videoCard?.subtitles?.find((sub) => sub.srcLang === key)
                ? ` (${SubtitleTranslation.languageReUpload[lang]})`
                : ''}
            </MenuItem>
          );
        }) ?? [],
    );
  }, [rows]);

  const handleDelete = (object) => {
    subtitleApi
      .deleteSubtitle({
        encodedOrgId: basicOrgData.encodedId,
        encodedVideoCardId: videoCard.encodedId,
        srcLang: object.srcLang,
      })
      .then((response) => {
        const updatedVideoCard = response.data.deleteSubtitle;
        setRows(
          updatedVideoCard?.subtitles?.map((sub) => ({ id: _.uniqueId(sub?.srcLang), ...sub })) ??
            [],
        );
        setSubtitles(updatedVideoCard?.subtitles ?? []);
        setRowToDelete(null);
        setSimpleAlertDialogOpenDelete(false);
      })
      .catch((error) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (subFile && selectedLang) {
      setUploading(true);

      subtitleApi
        .uploadSubtitleFile({
          encodedOrgId: basicOrgData.encodedId,
          videoId: Number(videoId),
          videoCardId: Number(videoCard.id),
          srcLang: selectedLang,
          label: SubtitleTranslation.languageAcronym[selectedLang],
          isDefault,
          file: subFile,
        })
        .then((response) => {
          const { link, uri, excludeFromVimeo } = response.data.uploadSubtitleFile;
          const updatedVideoCard = response.data.uploadSubtitleFile.videoCard;
          setRows(
            updatedVideoCard?.subtitles?.map((sub) => ({ id: _.uniqueId(sub?.srcLang), ...sub })) ??
              [],
          );
          setSubtitles(updatedVideoCard?.subtitles ?? []);

          const headers = {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          };
          if (!excludeFromVimeo) {
            axios
              .put(`${link}`, subFile, {
                headers,
              })
              .then((response) => {
                applySubtitle(uri);
              })
              .catch((err) => {
                setError(err);
                setUploading(false);
              });
          } else {
            setFileName('');
            setSubFile(null);
            setSelectedLang('');
            setError(null);
            setSuccess(true);
            setUploading(false);
            setIsDefault(false);
            setTimeout(() => {
              setSuccess(false);
            }, 6000);
            const frm = document.getElementById('subtitle-upload-form');

            const inputData = document.getElementById('subtitle_data');
            frm.reset(); // Reset all form data

            return false; // Prevent page refresh
          }
        })
        .catch((err) => {
          setError(err);
          setUploading(false);
        });
    }
  };

  function handleSelectChange(e) {
    setSelectedLang(e.target.value);
  }

  const handleChangeFile = (data) => {
    const { validity } = data.target;
    const file = data.target.files[0];

    if (file.size > 10000000) {
      alert('The file is too large, please select another one');
      data.target.value = null;
      setSubFile(null);
    } else if (validity.valid) {
      setSubFile(file);
    } else {
      data.target.value = null;
      setSubFile(null);
    }
    setFileName(file.name);

    if (file.name.includes(videoCard.encodedId)) {
      // empty
    } else {
      setError(SubtitleTranslation.wrongFile[lang]);
    }
  };

  const onMouseEnterRow = (event) => {
    const rowId = event.currentTarget.dataset.id;
    const row = rows?.find((el) => el.id === rowId);
    setHoveredRow(row.id);
  };

  const onMouseLeaveRow = () => {
    setHoveredRow(null);
  };

  const handleDownload = (option) => {
    if (isValidHttpUrl(option.src)) {
      actions
        .getVideoCardPreSignedUrlAWS({
          encodedOrgId: basicOrgData.encodedId,
          encodedVideoCardId: videoCard.encodedId,
          path: option.src,
          key: option.srcLang,
        })
        .then((res) => {
          const signedUrl = res.data.getVideoCardPreSignedUrlAWS;
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = signedUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {});
    }
  };

  const updateDefaultSub = (sub) => {
    subtitleApi
      .updateDefaultSubtitle({
        encodedOrgId: basicOrgData.encodedId,
        encodedVideoCardId: videoCard.encodedId,
        defaultLang: sub.srcLang,
      })
      .then((res) => {
        const updatedVideoCard = res.data.updateDefaultSubtitle;
        setRows(
          updatedVideoCard?.subtitles?.map((sub) => ({ id: _.uniqueId(sub?.srcLang), ...sub })) ??
            [],
        );
        setSubtitles(updatedVideoCard?.subtitles ?? []);
      })
      .catch((err) => {});
  };

  const columns = useMemo(() => [
    {
      field: 'srcLang',
      headerName: SubtitleTranslation.languageTitle[lang],
      minWidth: 30,
      disableColumnMenu: true,
      flex: 1,
      sortable: true,
      type: 'string',
      renderCell: (params) => {
        return SubtitleTranslation.languageAcronyms?.[params.row.srcLang]?.[lang];
      },
    },
    {
      field: 'default',
      headerName: SubtitleTranslation.defaultLanguage[lang],
      minWidth: 30,
      disableColumnMenu: true,
      flex: 0,
      sortable: true,
      type: 'boolean',
      renderCell: (params) => {
        if (hoveredRow === params.id && params.row.id === hoveredRow) {
          return (
            <input
              aria-label={SubtitleTranslation.defaultLanguage[lang]}
              type="checkbox"
              checked={params.row.default}
              onChange={(e) => {}}
              value={params.row.default}
              onClick={(e) => {
                if (!params.row.default) {
                  updateDefaultSub(params.row);
                } else {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            />
          );
        }
        if (params.row.default) {
          return (
            <input
              aria-label={SubtitleTranslation.defaultLanguage[lang]}
              checked={params.row.default}
              onChange={(e) => {}}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              type="checkbox"
              value={params.row.default}
            />
          );
        }
        return '';
      },
    },

    {
      field: 'actions',
      headerName: '',
      minWidth: 20,
      sortable: false,
      flex: 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (hoveredRow === params.id && params.row.id === hoveredRow) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => {
                  handleDownload(params.row);
                }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setRowToDelete(params.row);
                  setSimpleAlertDialogOpenDelete(true);
                }}
              >
                <img src="/images/icons/bin-icon.svg" alt="" />
              </IconButton>
            </Box>
          );
        }
        return null;
      },
    },
  ]);

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <Fade in={open}>
        <Box sx={{ ...style, position: 'absolute', overflow: 'hidden' }}>
          <Box>
            <div className="modal-header" style={{ display: 'flex' }}>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              <Typography variant="titleLarge" sx={{ marginTop: '12px' }}>
                {SubtitleTranslation.subtitleTitle[lang]}
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
              overflowY: 'scroll',
              mb: 2,
              position: 'relative',
              height: '100%',
              paddingBottom: '96px',
            }}
          >
            <AlertDialog
              open={simpleAlertDialogOpenDelete}
              title={SubtitleTranslation.deleteTitle[lang]}
              description={SubtitleTranslation.deleteDescription[lang]}
              subDescription={SubtitleTranslation?.languageAcronyms?.[rowToDelete?.srcLang]?.[lang]}
              confirmBtnText={SubtitleTranslation.deleteConfirmBtnText[lang]}
              cancelBtnText={SubtitleTranslation.deleteCancelBtnText[lang]}
              onConfirm={() => {
                handleDelete(rowToDelete);
                setSimpleAlertDialogOpenDelete(false);
              }}
              onCancel={() => {
                setSimpleAlertDialogOpenDelete(false);
              }}
            />

            <div className="subtitle-upload">
              <div className="subtitle-upload-inner">
                <form
                  method="PUT"
                  id="subtitle-upload-form"
                  encType="multipart/form-data"
                  onSubmit={handleSubmit}
                >
                  <ol>
                    <li style={{ paddingTop: '0px' }}>
                      <Typography variant="titleMedium" className="num">
                        1
                      </Typography>
                      <div className="">
                        <Typography variant="titleMedium">
                          {SubtitleTranslation.SelectLang[lang]}
                        </Typography>
                        <label>
                          <Select
                            variant="outlined"
                            size="small"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            displayEmpty
                            value={selectedLang}
                            onChange={handleSelectChange}
                            sx={{ minWidth: '260px', width: '100%', maxWidth: '360px' }}
                            input={outlinedInput(selectedLang?.length > 0)}
                            renderValue={(selected) => {
                              if (selected?.length > 0) {
                                return selected;
                              }
                              return <em>{SubtitleTranslation.Select[lang]}</em>;
                            }}
                          >
                            <MenuItem disabled value="">
                              <em>{SubtitleTranslation.Select[lang]}</em>
                            </MenuItem>
                            {LanguageSelectionOptions?.map((s) => s)}
                          </Select>
                        </label>
                      </div>
                    </li>
                    <li>
                      <Typography variant="titleMedium" className="num">
                        2
                      </Typography>

                      <Typography variant="bodyLarge">
                        {SubtitleTranslation.subtitleShouldBeDefault[lang]}
                      </Typography>
                      <label style={{ display: 'flex', marginTop: '5px' }}>
                        <input
                          type="checkbox"
                          checked={isDefault}
                          disabled={!videoCard?.subtitles?.length}
                          onChange={() => setIsDefault(!isDefault)}
                        />
                      </label>
                    </li>
                    <li>
                      <Typography variant="titleMedium" className="num">
                        3
                      </Typography>
                      <span>
                        <Typography variant="bodyLarge">
                          {SubtitleTranslation.SelectSubtitle[lang]}
                        </Typography>
                        <Typography variant="bodyLarge">
                          {SubtitleTranslation.FileFormat[lang]}{' '}
                          <Typography variant="titleMedium"> Webvtt</Typography> (.vtt)
                        </Typography>
                      </span>
                    </li>
                  </ol>

                  <div>
                    <label
                      htmlFor="subtitle_data"
                      className={`upload-area ${subFile && selectedLang && 'file-selected'}`}
                    >
                      {subFile ? (
                        <>
                          <img src="/images/icons/subtitles-icon.svg" alt="subtitle" />
                          <Typography variant="titleSmall">{fileName}</Typography>
                        </>
                      ) : (
                        <>
                          <img src="/images/icons/subtitles-icon.svg" alt="subtitle" />
                          <Typography variant="bodyLarge">
                            {SubtitleTranslation.clickToSelect[lang]}
                          </Typography>
                        </>
                      )}
                      <input
                        onChange={(e) => {
                          handleChangeFile(e);
                        }}
                        onClick={(e) => {}}
                        type="file"
                        accept="text/vtt"
                        name="subtitle_data"
                        id="subtitle_data"
                        required
                      />
                    </label>

                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      fullWidth
                      disabled={!subFile || !selectedLang || uploading}
                      onClick={handleSubmit}
                    >
                      {SubtitleTranslation.Upload[lang]}
                    </Button>
                  </div>
                </form>
              </div>
              <Divider
                variant="fullWidth"
                style={{
                  borderBottomWidth: '1px',
                  width: '45%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
              <DataGridTable
                rows={rows}
                columns={columns}
                pageSize={Object.keys(SubtitleTranslation.languageAcronyms)?.length ?? 12}
                slotProps={{
                  row: {
                    onMouseEnter: onMouseEnterRow,
                    onMouseLeave: onMouseLeaveRow,
                  },
                }}
                style={{
                  width: '45%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '25px',
                  marginBottom: '25px',
                }}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
              />
            </div>
          </Box>
        </Box>
      </Fade>
    </Slide>
  );
}
