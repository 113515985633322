const translationsGDPR = {
  isCompliant: {
    en: 'Life Inside is GDPR compliant',
    sv: 'Life Inside uppfyller GDPR',
  },
  learnMore: {
    en: 'learn more',
    sv: 'läs mer',
  },
  isCompliantContent: {
    en: 'about how you can use Life Inside in a GDPR compliant way',
    sv: 'om hur du kan använda Life Inside på ett GDPR-kompatibelt sätt',
  },
};

export default translationsGDPR;
