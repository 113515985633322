import { useState, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';

import { useLanguage } from 'src/context/LanguageContext';
import { getDefaultState, statuses } from '../../utils/utils';
import LibraryGrid from './VideoLibrary/LibraryGrid';
import LibraryHead from './VideoLibrary/LibraryHead';
import { useLibraryApi } from './VideoLibrary/LibraryApi';

export default function VideoLibrary() {
  const lang = useLanguage();
  const libraryApi = useLibraryApi();
  const { basicOrgData, videoCards, suggestedQuestions, orgRecipients, users, tagCategories } =
    useOutletContext();

  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [theUltimateStateData, setTheUltimateStateData] = useState({
    videoCards: videoCards?.sort((item1, item2) => {
      const q1 =
        item1?.baseData?.question?.[lang]?.toLowerCase() ??
        item1?.question?.[lang]?.toLowerCase() ??
        '';
      const q2 =
        item2?.baseData?.question?.[lang]?.toLowerCase() ??
        item2?.question?.[lang]?.toLowerCase() ??
        '';

      return q1?.localeCompare(q2);
    }),
  });

  const [tagsToFilter, setTagsToFilter] = useState([]);

  const [affectedItem, setAffectedItem] = useState(null);
  const [selectLocation, setSelectLocation] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState([]);
  const [selectTheme, setSelectTheme] = useState([]);
  const [selectedUser, setSelectedUser] = useState(getDefaultState('user', lang));
  const [selectedStatus, setSelectedStatus] = useState(getDefaultState('statusLive', lang));

  const [createNewVideoRequestIsOpen, setCreateNewVideoRequestIsOpen] = useState(false);
  const [duplicateRequestItem, setDuplicateRequestItem] = useState(null);

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 8000);
    }
  }, [error]);

  const updateTheUltimateStateData = (data) => {
    setTheUltimateStateData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }
  const VideoCardHasUser = (vc, userId) => {
    return userId === 'ALL_USERS' || (Number(userId) && vc?.user?.id === Number(userId));
  };

  const VideoCardHasStatus = (vc, status) => {
    return status === 'ALL' || vc?.status === status || vc?.baseData?.status === status;
  };

  const shouldIncludeInFilter = (item) => {
    if (tagsToFilter?.length === 0) {
      return (
        VideoCardHasUser(item, selectedUser?.value) &&
        VideoCardHasStatus(item, selectedStatus?.value)
      );
    }

    return (
      VideoCardHasUser(item, selectedUser?.value) &&
      VideoCardHasStatus(item, selectedStatus?.value) &&
      tagsToFilter?.every((tag) => item?.tags?.some((t) => tag == t.id))
    );
  };

  const updateVideoCard = (data) => {
    const items = [...videoCards.current];
    const index = items?.findIndex((item) => item.id === data.id);
    if (index > -1) {
      items[index] = data;
      videoCards.current = items;
    } else {
      videoCards.current = [...items, data];
    }
  };

  useEffect(() => {
    const time = new Date().getTime();

    libraryApi
      .getVideosMinimal({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
      .then((result) => {
        const newStateData = {
          videoCards: (
            result.data.videosMinimal?.videoCards?.map((vc) => ({
              id: vc.id,
              baseData: vc,
            })) ?? []
          )?.sort((item1, item2) => {
            const q1 =
              item1?.baseData?.question?.[lang]?.toLowerCase() ??
              item1?.question?.[lang]?.toLowerCase() ??
              '';
            const q2 =
              item2?.baseData?.question?.[lang]?.toLowerCase() ??
              item2?.question?.[lang]?.toLowerCase() ??
              '';

            return q1?.localeCompare(q2);
          }),
          orgData: {
            ...basicOrgData,
            tagCategories: result?.data?.videosMinimal?.tagCategories ?? [],
          },
        };
        tagCategories.current = result?.data?.videosMinimal?.tagCategories ?? [];

        updateTheUltimateStateData(newStateData);
        libraryApi
          .getVideosExtended({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
          .then((result) => {
            suggestedQuestions.current = result?.data?.videosExtended?.orgQuestions ?? [];
            users.current = result?.data?.videosExtended?.users ?? [];
            orgRecipients.current = result?.data?.videosExtended?.recipients ?? [];
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, [libraryApi]);

  const handleUpdateBoard = () => {
    libraryApi
      .getVideosMinimal({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
      .then((result) => {
        const videoCardItems =
          result.data.videosMinimal?.videoCards?.map((item) => {
            const prevState = videoCards.current?.find((i) => {
              return i.id === item.id;
            });
            return {
              ...(prevState || []),
              id: item.id,
              baseData: {
                ...item,
                ...(prevState?.user?.id === item?.user?.id ? { user: prevState?.user } : {}),
                ...(prevState?.recipient?.id === item?.recipient?.id
                  ? { recipient: prevState?.recipient }
                  : {}),
              },
            };
          }) ?? [];

        const newStateData = {
          videoCards: (videoCardItems ?? [])?.sort((item1, item2) =>
            item1?.question?.[lang]
              ?.lowercase()
              ?.localeCompare(item2?.question?.[lang]?.lowercase()),
          ),
          orgData: {
            ...basicOrgData,
            tagCategories: result?.data?.videosMinimal?.tagCategories ?? [],
          },
        };
        tagCategories.current = result?.data?.videosMinimal?.tagCategories ?? [];
        updateTheUltimateStateData(newStateData);
        libraryApi
          .getVideosExtended({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
          .then((result) => {
            suggestedQuestions.current = result?.data?.videosExtended?.orgQuestions ?? [];
            users.current = result?.data?.videosExtended?.users ?? [];
            orgRecipients.current = result?.data?.videosExtended?.recipients ?? [];
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const handleDuplicateItemClick = (item) => {
    const RequestItemDuplicate = {
      hideFromLibrary: item?.hideFromLibrary ?? false,
      internalNote: item?.internalNote,
      questions: item?.question
        ? [{ sv: item?.question?.sv, en: item?.question?.en }]
        : item?.questions,
      tags: item?.tags ?? [],
      status: statuses.SUGGESTION,
      interactionOption: item?.interactionOption ?? null,
    };
    setDuplicateRequestItem(RequestItemDuplicate);
    setCreateNewVideoRequestIsOpen(true);
  };

  const filteredVideos = useMemo(
    () =>
      theUltimateStateData?.videoCards?.filter((vc) => {
        return shouldIncludeInFilter(vc);
      }),
    [
      theUltimateStateData,
      selectedStatus,
      selectLocation,
      selectDepartment,
      selectTheme,
      selectedUser,
    ],
  );

  const infoMsg = <div className="info-pop">{info}</div>;
  return (
    <div className="requests">
      <div className="admin-library">
        {infoMsg}
        <LibraryHead
          setTagsToFilter={setTagsToFilter}
          tagsToFilter={tagsToFilter}
          setSelectLocation={setSelectLocation}
          selectLocation={selectLocation}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectTheme={setSelectTheme}
          selectTheme={selectTheme}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          orgData={theUltimateStateData?.orgData}
          users={users.current}
          handleUpdateBoard={(e) => {
            handleUpdateBoard();
          }}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setInfo={(e) => {
            setInfo(e);
          }}
          setError={(e) => {
            setError(e);
          }}
          suggestedQuestions={suggestedQuestions.current ?? []}
          orgRecipients={orgRecipients.current ?? []}
          setVideoCardData={(e) => updateVideoCard(e)}
        />
        <LibraryGrid
          videoCards={filteredVideos}
          encodedOrgId={basicOrgData.encodedId}
          orgData={theUltimateStateData.orgData ?? basicOrgData}
          suggestedQuestions={suggestedQuestions.current ?? []}
          orgRecipients={orgRecipients.current ?? []}
          setInfo={(e) => {
            setInfo(e);
          }}
          setError={(e) => {
            setError(e);
          }}
          handleDuplicateItemClick={(e) => {
            handleDuplicateItemClick(e);
          }}
          updateVideoCard={(e) => {
            updateVideoCard(e);
          }}
          setAffectedItem={(e) => {}}
          handleUpdateBoard={(e) => {
            handleUpdateBoard();
          }}
        />
      </div>
    </div>
  );
}
