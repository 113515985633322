import { useRef, useState, useEffect } from 'react';
import '../../common/videoPlayerWrapper.scss';
import LazyLoad from 'react-lazy-load';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';

import { useLanguage } from 'src/context/LanguageContext';
import { statuses, hasVideoLink } from '../../../utils/utils';
import { translations } from '../../../utils/translations';

import { useRequestsApi } from '../Requests/RequestsApi';
import VideoControls from '../../public/VideoControlsHtml5';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import VideoCardSettings from '../VideoCardSettings';

export default function VideoCard({
  theme,
  videoCard,
  index = videoCard.id,
  width = 220,
  height = 500,
  padding = 10,
  fontSize = '14px',
  orgData,
  updateVideoCard,
  setAffectedItem,
  setInfo,
  handleUpdateBoard,
  suggestedQuestions,
  orgRecipients,
  setError,
  handleDuplicateItemClick,
  reviewMode = false,
  playMode = false,
  storylineEditMode = false,
  onClick,
  storylinePickVideoCardMode = false,
  storylineIndexOfItem,
  storylineSelected,
  dynamicDimensions,
  setDynamicDimensions,
  allowFullscreen = true,
  videoIsLandscape,
  setVideoIsLandscape,
  setVideoDuration,
  reachedMaxVideos = false,
  customVideoTitle,
  disabled = false,
}) {
  const lang = useLanguage();
  const requestsApi = useRequestsApi();

  if (!videoCard || videoCard.id === undefined) return null;

  const videoWidth = width - padding * 2;
  const videoHeight = videoWidth * (16 / 9);

  const [videoDimensions, setVideoDimensions] = useState({
    width: videoWidth,
    height: videoHeight,
  });

  const [videoCardData, setVideoCardData] = useState(videoCard);
  const isVideoRequest = videoCardData?.type === 'video_request';
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  useEffect(() => {
    if (storylineEditMode) {
      if (isVideoRequest) {
        setDynamicDimensions({
          videoRequestId: videoCard.id,
          ...videoDimensions,
          indexOfItem: storylineIndexOfItem,
        });
      } else {
        setDynamicDimensions({
          videoCardId: videoCard.id,
          ...videoDimensions,
          indexOfItem: storylineIndexOfItem,
        });
      }
    }
  }, [videoDimensions]);

  const [cardIsOpen, setCardIsOpen] = useState(false);
  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (
      !videoCardData?.lastPullFromDatabase ||
      (videoCardData?.lastPullFromDatabase &&
        Math.floor(Math.abs(new Date() - videoCardData.lastPullFromDatabase) / 1000) > 120)
    ) {
      if (!isVideoRequest) {
        requestsApi
          .getVideoCard(orgData.encodedId, videoCardData?.id)
          .then((res) => {
            if (isMounted) {
              setVideoCardData({ ...res.data.videoCard, lastPullFromDatabase: new Date() });
              updateVideoCard({ ...res.data.videoCard, lastPullFromDatabase: new Date() });
            }
          })
          .catch((err) => {});
      }
    }

    return () => {
      isMounted = false;
    };
  }, [requestsApi]);

  const videoPlayerRef = useRef();

  const defaultQuality = 'nHD';
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const [videoSubtitles, setVideoSubtitles] = useState(null);
  const [activeSubtitle, setActiveSubtitle] = useState(null);
  const [currentCue, setCurrentCue] = useState(null);
  const [selectedSubtitleLang, setSelectedSubtitleLang] = useState(lang);
  const [selectedQuality, setSelectedQuality] = useState(defaultQuality);
  const [availableQualities, setAvailableQualites] = useState(null);
  const [videoProgress, setVideoProgress] = useState({ playedSeconds: 0, playedPerc: 0 });
  const [selected, setSelected] = useState(storylineSelected ?? false);

  useEffect(() => {
    setSelected(storylineSelected);
  }, [storylineSelected]);

  const toggleVolume = () => {
    const newVolume = volume === 1 ? 0 : 1;
    setVolume(newVolume);
  };
  const replayVideo = () => {
    videoPlayerRef.current.seekTo(0);
  };

  const [url, setUrl] = useState(null);

  function imageFound(e) {
    handleVideoDimension({
      videoWidth: e?.path?.[0]?.naturalWidth,
      videoHeight: e?.path?.[0]?.naturalHeight,
    });
  }

  function imageNotFound(e) {}

  useEffect(() => {
    if (!reviewMode && !playMode && !storylineEditMode) {
      let imageUrl;
      if (videoCardData?.staticThumbnails?.ntsc) {
        imageUrl = videoCardData?.staticThumbnails.ntsc;
      } else if (videoCardData?.staticThumbnails?.nHD) {
        imageUrl = videoCardData?.staticThumbnails.nHD;
      } else if (videoCardData?.staticThumbnails?.qHD) {
        imageUrl = videoCardData?.staticThumbnails.qHD;
        setUrl(videoCardData?.staticThumbnails.qHD);
      }

      if (imageUrl) {
        var tester = new Image();
        tester.onload = imageFound;
        tester.onerror = imageNotFound;
        tester.src = imageUrl;
        setImageSource(tester);
      } else if (videoCardData?.videoLink?.ntsc) {
        setUrl(videoCardData?.videoLink.ntsc);
      } else if (videoCardData?.videoLink?.nHD) {
        setUrl(videoCardData?.videoLink.nHD);
      } else if (videoCardData?.videoLink?.qHD) {
        setUrl(videoCardData?.videoLink.qHD);
      } else if (videoCardData?.videoLink?.HD) {
        setUrl(videoCardData?.videoLink.HD);
      } else if (videoCardData?.videoLink?.FHD) {
        setUrl(videoCardData?.videoLink.FHD);
      }
    } else if (storylineEditMode) {
      if (videoCardData?.videoLink?.ntsc) {
        setUrl(videoCardData?.videoLink.ntsc);
      } else if (videoCardData?.videoLink?.nHD) {
        setUrl(videoCardData?.videoLink.nHD);
      } else if (videoCardData?.videoLink?.qHD) {
        setUrl(videoCardData?.videoLink.qHD);
      } else if (videoCardData?.videoLink?.HD) {
        setUrl(videoCardData?.videoLink.HD);
      } else if (videoCardData?.videoLink?.FHD) {
        setUrl(videoCardData?.videoLink.FHD);
      }

      let imageUrl;
      if (videoCardData?.staticThumbnails?.ntsc) {
        imageUrl = videoCardData?.staticThumbnails.ntsc;
      } else if (videoCardData?.staticThumbnails?.nHD) {
        imageUrl = videoCardData?.staticThumbnails.nHD;
      } else if (videoCardData?.staticThumbnails?.qHD) {
        imageUrl = videoCardData?.staticThumbnails.qHD;
      } else if (videoCardData?.staticThumbnails?.HD) {
        imageUrl = videoCardData?.staticThumbnails.HD;
      } else if (videoCardData?.staticThumbnails?.FHD) {
        imageUrl = videoCardData?.staticThumbnails.FHD;
      }

      if (imageUrl) {
        var tester = new Image();
        tester.onload = function () {
          const { width } = this;
          const { height } = this;
          handleVideoDimension({
            videoWidth: width,
            videoHeight: height,
          });
        };

        tester.onerror = imageNotFound;
        tester.src = imageUrl;
        setImageSource(tester);
      }
    } else if (videoCardData?.videoLink?.nHD) {
      setUrl(videoCardData?.videoLink.nHD);
    } else if (videoCardData?.videoLink?.ntsc) {
      setUrl(videoCardData?.videoLink.ntsc);
    }
  }, [videoCardData]);

  useEffect(() => {
    setCurrentCue(null);
  }, [activeSubtitle]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const video = document.querySelector(`#video-player-${index}`);
    if (isFullScreen) {
      document.addEventListener('keydown', escFunction);
      handleVideoDimension(video, true);
    } else {
      document.removeEventListener('keydown', escFunction);
      handleVideoDimension(video);
    }
  }, [isFullScreen]);

  const seek = (secs) => {
    if (videoPlayerRef.current?.fastSeek) {
      videoPlayerRef.current.fastSeek(secs);
    } else {
      videoPlayerRef.current.currentTime = secs;
    }
  };

  const onVideoProgress = (progressData) => {
    const { playedSeconds, played, loadedSeconds, loaded } = progressData;
    setVideoProgress({ playedSeconds, playedPerc: played });
    if (activeSubtitle) {
      const cue = _.find(activeSubtitle?.cues, (c) => {
        return c.startTime <= playedSeconds && c.endTime > playedSeconds;
      });
      if (cue && !_.isEqual(cue?.text, currentCue?.text)) {
        setCurrentCue(cue);
      }
    } else {
      setCurrentCue(null);
    }
  };

  const onReady = (e) => {
    const video = document.querySelector(`#video-player-${index}`);
    handleVideoDimension(video, isFullScreen);
  };

  const onVideoEnded = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (isPlaying && !isLoadingVideo) {
      setIsLoadingVideo(true);
    }
  }, [isPlaying]);

  const handleVideoDimension = (video, fullscreen = false) => {
    if (!video) return;
    const isLandscape = video.videoWidth > video.videoHeight;
    if (setVideoIsLandscape && videoIsLandscape !== null && videoPlayerRef?.current?.duration) {
      setVideoIsLandscape(isLandscape || video.videoHeight / video.videoWidth <= 4 / 3);
      if (setVideoDuration) {
        setVideoDuration(videoPlayerRef.current.duration);
      }
    }
    if (fullscreen) {
      const preferredHeight = window.innerHeight - 100;

      setVideoDimensions({
        width: video.videoWidth * (preferredHeight / video.videoHeight),
        height: preferredHeight,
      });
      return;
    }

    if (isLandscape) {
      const preferredWidth = videoWidth * 1.6;
      setVideoDimensions({
        width: preferredWidth,
        height: video.videoHeight * (preferredWidth / video.videoWidth),
      });
    } else {
      const preferredWidth = videoWidth;
      setVideoDimensions({
        width: preferredWidth,
        height: video.videoHeight * (preferredWidth / video.videoWidth),
      });
    }
    setTimeout(() => {
      setIsVideoVisible(true);
    }, 500);
  };

  const changeVideoProgress = (newProgressPerc) => {
    seek(newProgressPerc * videoPlayerRef.current.duration);
  };

  const onVideoPlay = () => {};
  const onVideoPause = () => {};
  const onVideoError = (e) => {
    if (videoCardData?.videoLink?.ntsc) {
      setUrl(videoCardData?.videoLink.ntsc);
    } else if (videoCardData?.videoLink?.nHD) {
      setUrl(videoCardData?.videoLink.nHD);
    } else if (videoCardData?.videoLink?.qHD) {
      setUrl(videoCardData?.videoLink.qHD);
    }
  };

  const getQuestion = () => {
    if (customVideoTitle?.length > 0) {
      return customVideoTitle;
    }
    if (isVideoRequest) {
      return videoCardData?.questions?.[0]?.[lang] ?? '';
    }
    if (videoCardData?.baseData?.question?.[lang]) {
      return videoCardData?.baseData?.question?.[lang];
    }
    if (videoCardData?.question?.[lang]) {
      return videoCardData?.question?.[lang];
    }
    return '';
  };
  const getRecipientName = () => {
    if (videoCardData?.recipient?.name) {
      return videoCardData.recipient?.name;
    }
    if (videoCardData?.actorName) {
      return videoCardData?.actorName;
    }
    return '';
  };

  const getRecipientTitle = () => {
    if (videoCardData?.recipient?.title) {
      return videoCardData.recipient?.title;
    }
    if (videoCardData?.actorTitle?.[lang]) {
      return videoCardData?.actorTitle?.[lang];
    }
    return '';
  };

  const approveVideo = () => {
    const prevStatus = videoCardData?.status;

    setVideoCardData({ ...videoCardData, status: statuses.LIVE });
    updateVideoCard({ ...videoCardData, status: statuses.LIVE });
    requestsApi
      .updateVideoCardStatus(orgData.encodedId, orgData.id, videoCardData.id, statuses.LIVE)
      .then((response) => {
        setVideoCardData({ ...response.datavideoCard, lastPullFromDatabase: new Date() });
        updateVideoCard({ ...response.datavideoCard, lastPullFromDatabase: new Date() });
        handleUpdateBoard();
        setInfo(translations.successFeedback.LIVE[lang]);
      })
      .catch((error) => {
        setVideoCardData({ ...videoCardData, status: prevStatus });
        updateVideoCard({ ...videoCardData, status: prevStatus });
        setError(translations.savedError[lang]);
      });
  };

  const getModuleVideoRepresentation = () => {
    return (
      <VideoCardSettings
        item={videoCardData}
        close={() => setCardIsOpen(false)}
        orgData={orgData}
        setAffected={(e) => setAffectedItem(e)}
        setInfo={setInfo}
        suggestedQuestions={suggestedQuestions ?? []}
        handleDuplicateItemClick={(itemToDuplicate) => {
          handleDuplicateItemClick(itemToDuplicate);
        }}
        handleUpdateBoard={(e) => handleUpdateBoard(e)}
        orgRecipients={orgRecipients ?? []}
        setVideoCardData={(item) => {
          setVideoCardData({ ...item, lastPullFromDatabase: new Date() });
          updateVideoCard({ ...item, lastPullFromDatabase: new Date() });
        }}
      />
    );
  };

  const handleOnTimeUpdate = (e) => {
    const video = e.target;
    if (!video) return;
    const { currentTime } = video;
    const { duration } = video;
    const progress = currentTime / duration;
    const progressData = {
      playedSeconds: currentTime,
      played: progress,
      loadedSeconds: duration,
      loaded: duration,
    };
    onVideoProgress(progressData);
  };

  const isValid = () => {
    if (
      !hasVideoLink({
        excludeFromVimeo: orgData?.premiumFeatures?.excludeFromVimeo,
        videoLink: videoCardData?.videoLink,
        orgLang: lang,
      })
    ) {
      return false;
    }
    if (getQuestion() === '') {
      return false;
    }
    if (getRecipientName() === '') {
      return false;
    }
    return true;
  };

  useEffect(() => {}, [url, setUrl, imageSource, setImageSource]);

  return (
    <>
      {cardIsOpen && getModuleVideoRepresentation()}
      <Card
        key={videoCard?.id}
        sx={{
          width: `${videoDimensions.width}px`,
          height: '100%',
          borderRadius: 2,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          shadowOpacity: 0,
          boxShadow: 'none',
        }}
        disabled={disabled}
      >
        <CardActionArea
          disableRipple
          disableTouchRipple
          disabled={disabled}
          onClick={(e) => {
            if (storylineEditMode) {
              e.stopPropagation();
              e.preventDefault();
              if (videoCardData?.videoLink?.ntsc) {
                setUrl(videoCardData?.videoLink.ntsc);
              } else if (videoCardData?.videoLink?.nHD) {
                setUrl(videoCardData?.videoLink.nHD);
              } else if (videoCardData?.videoLink?.qHD) {
                setUrl(videoCardData?.videoLink.qHD);
              } else if (videoCardData?.videoLink?.HD) {
                setUrl(videoCardData?.videoLink.HD);
              } else if (videoCardData?.videoLink?.FHD) {
                setUrl(videoCardData?.videoLink.FHD);
              }
              if (!isPlaying && !isVideoRequest) {
                setIsPlaying(true);
              }
            } else if (reviewMode || playMode) {
              e.stopPropagation();
              e.preventDefault();
            } else {
              setCardIsOpen(true);
            }
          }}
          component="div"
        >
          <CardHeader
            title={TypographyTitle({
              title: getQuestion(),
              fontSize,
            })}
            onClick={(e) => {
              if (!reachedMaxVideos && storylineEditMode && storylinePickVideoCardMode) {
                onClick();
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            disabled={disabled}
            action={
              !reachedMaxVideos && storylinePickVideoCardMode && !disabled ? (
                <input
                  key={_.uniqueId('input-video-card')}
                  type="checkbox"
                  id="select-video-card"
                  name="select-video-card"
                  checked={selected}
                  onChange={(e) => {
                    e.stopPropagation();
                  }}
                />
              ) : (
                storylineIndexOfItem >= 0 && (
                  <div className="video-card-index">
                    <Typography
                      variant="bodySmall"
                      color="text.light"
                      sx={{ backgroundColor: 'tertiary.main', p: 0.5, borderRadius: 0.2 }}
                    >
                      {storylineIndexOfItem + 1}
                    </Typography>
                  </div>
                )
              )
            }
            sx={{
              boxShadow: '0 0 10px rgb(0 0 0 / 10%)',
              border: '1px solid #eee',
              display: 'flex',
              borderRadius: '8px',
              fontSize: '14px',
              lineHeight: '1.4',
              fontWeight: '400',
              height: 'auto',
              minHeight: '80px',
              maxHeight: '100px',
              color: '#5a636f',
              marginBottom: '-15px',
              padding: '10px',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              background: 'white',
            }}
          />
          <CardContent
            style={{
              width: `${videoDimensions.width}px`,
              position: 'relative',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 0 10px rgb(0 0 0 / 10%)',
              border: '1px solid #eee',
              padding: '0px',
              ...(isVideoRequest ? { backgroundColor: '#F2F4F9' } : {}),
            }}
            disabled={disabled}
          >
            <div
              className={`video-player-wrapper ${isFullScreen ? 'fullscreen' : ''}`}
              style={{ position: 'relative' }}
            >
              <div>
                {!isVideoRequest && (reviewMode || playMode || storylineEditMode) && !disabled && (
                  <>
                    <VideoControls
                      videoProgress={videoProgress}
                      activeVideo={videoCardData}
                      viewCount={viewCount}
                      isPlaying={isPlaying}
                      setIsPlaying={(e) => {
                        setIsPlaying(e);
                        if (e) {
                          videoPlayerRef.current.play();
                        } else {
                          videoPlayerRef.current.pause();
                        }
                      }}
                      showControls
                      availableSubtitles={null}
                      toggleVolume={toggleVolume}
                      volume={volume}
                      orgData={orgData}
                      isFullScreen={isFullScreen}
                      setIsFullScreen={setIsFullScreen}
                      subtitles={videoSubtitles}
                      selectedSubtitleLang={selectedSubtitleLang}
                      setSelectedSubtitleLang={setSelectedSubtitleLang}
                      replayVideo={replayVideo}
                      availableQualities={availableQualities}
                      selectedQuality={selectedQuality}
                      setSelectedQuality={setSelectedQuality}
                      changeVideoProgress={changeVideoProgress}
                      allowFullscreen={allowFullscreen}
                      disabled={disabled}
                    />
                    <div className="subtitle-wrapper">
                      <span className="subtitle-cue">{currentCue?.text}</span>
                    </div>
                  </>
                )}
                <LazyLoad
                  offset={height}
                  width={!isFullScreen ? `${videoDimensions.width}px` : '100%'}
                  height={!isFullScreen ? `${videoDimensions.height}px` : '100%'}
                >
                  <>
                    {storylineEditMode &&
                      [
                        statuses.REVIEW,
                        statuses.APPROVED,
                        statuses.ARCHIVED,
                        statuses.SUGGESTION,
                        statuses.PENDING,
                      ]?.includes(videoCardData?.status) && (
                        <Slide in={!isPlaying} direction="down" mountOnEnter unmountOnExit>
                          <div
                            style={{
                              position: 'absolute',
                              top: isVideoRequest ? `${height * 0.25}px` : '10px',
                              left: '10px',
                              right: '10px',
                              borderRadius: '10px',
                              height: 'auto',
                              backgroundColor: isVideoRequest
                                ? 'rgb(0 0 0 / 24%)'
                                : 'rgb(0 0 0 / 44%)',
                              transition: 'all 1s ease',
                            }}
                          >
                            <Box style={{ margin: '10px', display: 'flex' }}>
                              <Grid container spacing={0} sx={{ justifyContent: 'space-between' }}>
                                {isVideoRequest && (
                                  <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    md={12}
                                    xl={12}
                                    xs={12}
                                    style={{ display: 'grid', placeItems: 'center' }}
                                  >
                                    <VideoCameraFrontOutlinedIcon
                                      fontSize="large"
                                      sx={{ margin: '5px', color: '#ecebeb' }}
                                    />
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  lg={12}
                                  sm={12}
                                  md={12}
                                  xl={12}
                                  xs={12}
                                  style={{ display: 'grid', placeItems: 'center' }}
                                >
                                  <Typography
                                    style={{
                                      color: 'white',
                                      fontFamily: ['Roboto', 'sans-serif'],
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      letterSpacing: '-0.5px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {
                                      translationsStoryline.noVideoNewVideoStatus[
                                        videoCardData.status
                                      ][lang]
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </Slide>
                      )}
                    {!isVideoRequest && (
                      <>
                        {reviewMode || playMode || storylineEditMode ? (
                          <video
                            ref={videoPlayerRef}
                            id={`video-player-${index}`}
                            src={url}
                            width="100%"
                            height="100%"
                            controls={false}
                            onLoad={(e) => {
                              if (storylineEditMode && isPlaying) {
                                setIsLoadingVideo(false);
                              }
                              onReady();
                            }}
                            onProgress={onVideoProgress}
                            onEnded={onVideoEnded}
                            onTimeUpdate={(e) => {
                              handleOnTimeUpdate(e);
                            }}
                            volume={reviewMode || playMode ? volume : 0}
                            onPlay={onVideoPlay}
                            onPause={onVideoPause}
                            onError={onVideoError}
                            playsInline
                            poster={
                              videoCardData?.staticThumbnails?.ntsc
                                ? videoCardData?.staticThumbnails?.ntsc
                                : ''
                            }
                            onCanPlay={(e) => {
                              onReady(e);
                              if (isPlaying) {
                                videoPlayerRef.current.play();
                                setIsLoadingVideo(false);
                              } else {
                                setIsLoadingVideo(true);
                              }
                            }}
                          />
                        ) : imageSource?.src ? (
                          <img
                            src={imageSource?.src}
                            alt=""
                            id={`video-player-${index}`}
                            width="100%"
                            height="100%"
                          />
                        ) : (
                          <video
                            ref={videoPlayerRef}
                            id={`video-player-${index}`}
                            src={url}
                            width="100%"
                            height="100%"
                            controls={false}
                            onLoad={onReady}
                            onProgress={onVideoProgress}
                            onEnded={onVideoEnded}
                            onTimeUpdate={(e) => {
                              handleOnTimeUpdate(e);
                            }}
                            volume={0}
                            onCanPlay={(e) => {
                              onReady(e);
                              if (isPlaying) {
                                videoPlayerRef.current.play();
                                setIsLoadingVideo(false);
                              } else {
                                setIsLoadingVideo(true);
                              }
                            }}
                            onPlay={onVideoPlay}
                            onPause={onVideoPause}
                            onError={onVideoError}
                            playsInline
                            muted
                          />
                        )}
                      </>
                    )}
                  </>
                </LazyLoad>
                <div
                  className="actor"
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    background: isVideoRequest
                      ? 'none'
                      : 'linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  <Typography
                    variant="bodySmall"
                    className="actor-name"
                    style={{ fontWeight: '600', ...(isVideoRequest ? { color: 'black' } : {}) }}
                  >
                    {getRecipientName()}
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    className="sep"
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                      ...(isVideoRequest ? { color: 'black' } : {}),
                    }}
                  >
                    {getRecipientName() !== '' && getRecipientTitle() !== '' && '|'}
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    className="actor-title"
                    style={{ fontWeight: '300', ...(isVideoRequest ? { color: 'black' } : {}) }}
                  >
                    {getRecipientTitle()}
                  </Typography>
                </div>
              </div>
            </div>
          </CardContent>

          {reviewMode && (
            <CardActions
              style={{ display: 'flex', justifyContent: 'space-around' }}
              disabled={disabled}
            >
              <Button
                onClick={(e) => {
                  setCardIsOpen(true);
                }}
              >
                {translations.videoCard.openVideoCard[lang]}
              </Button>
              {isValid() && (
                <Button
                  onClick={(e) => {
                    approveVideo();
                  }}
                  disabled={!isValid()}
                >
                  {translations.videoCard.approve[lang]}
                </Button>
              )}
            </CardActions>
          )}
        </CardActionArea>
      </Card>
    </>
  );
}

function TypographyTitle({ title, fontSize = '14px' }) {
  return (
    <Typography
      gutterBottom
      xs={{
        lineHeight: '1.4',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '-0.5px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {title && title}
    </Typography>
  );
}
