import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';

import { useLanguage } from 'src/context/LanguageContext';
import { getDefaultState, statuses } from '../../utils/utils';
import RequestsGrid from './Requests/RequestsGrid';
import RequestsHead from './Requests/RequestsHead';
import { useRequestsApi } from './Requests/RequestsApi';
import VideoRequest from './VideoRequest';

export default function Requests() {
  const lang = useLanguage();
  const requestsApi = useRequestsApi();
  const {
    basicOrgData,
    videoRequests,
    videoCards,
    suggestedQuestions,
    orgRecipients,
    users,
    tagCategories,
  } = useOutletContext();

  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [theUltimateStateData, setTheUltimateStateData] = useState({
    videoCards:
      videoCards?.current?.map((vc) => ({
        id: vc.id,
        baseData: vc,
      })) ?? [],
    videoRequests:
      videoRequests?.current?.map((vc) => ({
        id: vc.id,
        baseData: vc,
      })) ?? [],
    orgData: {
      ...basicOrgData,
      tagCategories: tagCategories?.current ?? [],
    },
  });

  const [tagsToFilter, setTagsToFilter] = useState([]);

  const [affectedItem, setAffectedItem] = useState(null);
  const [selectLocation, setSelectLocation] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState([]);
  const [selectTheme, setSelectTheme] = useState([]);
  const [selectedUser, setSelectedUser] = useState(getDefaultState('user', lang));
  const [selectedStatus, setSelectedStatus] = useState(getDefaultState('status', lang));
  const [createNewVideoRequestIsOpen, setCreateNewVideoRequestIsOpen] = useState(false);
  const [duplicateRequestItem, setDuplicateRequestItem] = useState(null);

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 8000);
    }
  }, [error]);

  const updateTheUltimateStateData = (data) => {
    setTheUltimateStateData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }
  const VideoCardHasUser = (vc, userId) => {
    return userId === 'ALL_USERS' || (Number(userId) && vc?.user?.id === Number(userId));
  };

  const VideoCardHasStatus = (vc, status) => {
    return status === 'ALL' || vc?.status === status;
  };

  const shouldIncludeInFilter = (item) => {
    if (
      !(
        item?.status === statuses.SUGGESTION ||
        item?.baseData?.status === statuses.SUGGESTION ||
        item?.status === statuses.PENDING ||
        item?.baseData?.status === statuses.PENDING ||
        item?.status === statuses.REVIEW ||
        item?.baseData?.status === statuses.REVIEW
      )
    ) {
      return false;
    }

    if (tagsToFilter?.length === 0) {
      return (
        VideoCardHasUser(item, selectedUser?.value) &&
        VideoCardHasStatus(item, selectedStatus?.value)
      );
    }

    return (
      VideoCardHasUser(item, selectedUser?.value) &&
      VideoCardHasStatus(item, selectedStatus?.value) &&
      tagsToFilter?.every((tag) => item?.tags?.some((t) => tag == t.id))
    );
  };

  const updateVideoCard = (data) => {
    let items = [...videoCards.current];
    const index = items?.findIndex((item) => item.id === data.id);
    if (index > -1) {
      items[index] = data;
      videoCards.current = items;
    } else {
      videoCards.current = [...items, data];
    }
  };

  const updateVideoRequest = (data) => {
    let items = [...videoRequests.current];
    const index = items?.findIndex((item) => item.id === data.id);
    if (index > -1) {
      items[index] = data;
      videoRequests.current = items;
    } else {
      videoRequests.current = [...items, data];
    }
  };

  useEffect(() => {
    requestsApi
      .getRequestsMinimal({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
      .then((result) => {
        let newStateData = {
          videoCards:
            result.data.requestsMinimal?.videoCards?.map((vc) => ({
              id: vc.id,
              baseData: vc,
            })) ?? [],
          videoRequests:
            result.data.requestsMinimal?.videoRequests?.map((vc) => ({
              id: vc.id,
              baseData: vc,
            })) ?? [],
          orgData: {
            ...basicOrgData,
            tagCategories: result.data.requestsMinimal?.tagCategories,
          },
        };
        tagCategories.current = result.data.requestsMinimal?.tagCategories;

        updateTheUltimateStateData(newStateData);
        requestsApi
          .getRequestsExtended({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
          .then((result) => {
            suggestedQuestions.current = result?.data?.requestsExtended?.orgQuestions ?? [];
            users.current = result?.data?.requestsExtended?.users ?? [];
            orgRecipients.current = result?.data?.requestsExtended?.recipients ?? [];
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, []);

  const handleUpdateBoard = () => {
    requestsApi
      .getRequestsMinimal({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
      .then((result) => {
        let videoCardItems =
          result.data.requestsMinimal?.videoCards?.map((item) => {
            const prevState = videoCards.current?.find((i) => {
              return i.id === item.id;
            });
            return {
              ...(prevState ? prevState : []),
              id: item.id,
              baseData: {
                ...item,
                ...(prevState?.user?.id === item?.user?.id ? { user: prevState?.user } : {}),
                ...(prevState?.recipient?.id === item?.recipient?.id
                  ? { recipient: prevState?.recipient }
                  : {}),
              },
            };
          }) ?? [];

        let videoRequestItems =
          result.data.requestsMinimal?.videoRequests?.map((item) => {
            const prevState = videoRequests.current?.find((i) => {
              return i.id === item.id;
            });
            return {
              ...(prevState ? prevState : []),
              id: item.id,
              baseData: {
                ...item,
                ...(prevState?.user?.id === item?.user?.id ? { user: prevState?.user } : {}),
                ...(prevState?.recipient?.id === item?.recipient?.id
                  ? { recipient: prevState?.recipient }
                  : {}),
              },
            };
          }) ?? [];

        let newStateData = {
          videoCards: videoCardItems ?? [],
          videoRequests: videoRequestItems ?? [],
          orgData: {
            ...basicOrgData,
            tagCategories: result.data.requestsMinimal?.tagCategories,
          },
        };
        tagCategories.current = result.data.requestsMinimal?.tagCategories;

        updateTheUltimateStateData(newStateData);
        requestsApi
          .getRequestsExtended({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
          .then((result) => {
            suggestedQuestions.current = result?.data?.requestsExtended?.orgQuestions ?? [];

            users.current = result?.data?.requestsExtended?.users ?? [];

            orgRecipients.current = result?.data?.requestsExtended?.recipients ?? [];
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const handleDuplicateItemClick = (item) => {
    const RequestItemDuplicate = {
      hideFromLibrary: item?.hideFromLibrary ?? false,
      internalNote: item?.internalNote,
      questions: item?.question
        ? [{ sv: item?.question?.sv, en: item?.question?.en }]
        : item?.questions,
      tags: item?.tags ?? [],
      status: statuses.SUGGESTION,
      interactionOption: item?.interactionOption ?? null,
    };
    setDuplicateRequestItem(RequestItemDuplicate);
    setCreateNewVideoRequestIsOpen(true);
  };

  const createRequestDuplicateModal =
    createNewVideoRequestIsOpen && duplicateRequestItem ? (
      <VideoRequest
        item={duplicateRequestItem}
        close={() => {
          setCreateNewVideoRequestIsOpen(false);
          setDuplicateRequestItem(null);
        }}
        orgData={theUltimateStateData?.orgData}
        setInfo={(e) => {
          setInfo(e);
        }}
        setAffected={(id) => setAffectedItem(id)}
        suggestedQuestions={suggestedQuestions.current}
        duplicateItem={duplicateRequestItem}
        handleUpdateBoard={(e) => handleUpdateBoard(e)}
        setVideoRequest={(item) => {
          updateVideoRequest(item);
        }}
        orgRecipients={orgRecipients.current}
      />
    ) : null;

  const infoMsg = <div className="info-pop">{info}</div>;
  return (
    <div className="requests">
      <div className="admin-library">
        {infoMsg}
        <RequestsHead
          setTagsToFilter={setTagsToFilter}
          tagsToFilter={tagsToFilter}
          setSelectLocation={setSelectLocation}
          selectLocation={selectLocation}
          setSelectDepartment={setSelectDepartment}
          selectDepartment={selectDepartment}
          setSelectTheme={setSelectTheme}
          selectTheme={selectTheme}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          orgData={theUltimateStateData?.orgData}
          users={users.current}
          handleUpdateBoard={(e) => {
            handleUpdateBoard();
          }}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setInfo={(e) => {
            setInfo(e);
          }}
          setError={(e) => {
            setError(e);
          }}
          suggestedQuestions={suggestedQuestions.current ?? []}
          orgRecipients={orgRecipients.current ?? []}
          setAffectedItem={(id) => setAffectedItem(id)}
          updateVideoRequest={(item) => {
            updateVideoRequest(item);
          }}
        />
        <RequestsGrid
          requestObjects={_.orderBy(
            [
              ...(theUltimateStateData?.videoCards ?? []),
              ...(theUltimateStateData?.videoRequests ?? []),
            ]?.filter((item) => shouldIncludeInFilter(item)),
            ['created', 'id'],
            ['asc', 'asc'],
          )}
          encodedOrgId={basicOrgData.encodedId}
          orgData={theUltimateStateData.orgData ?? basicOrgData}
          suggestedQuestions={suggestedQuestions.current ?? []}
          orgRecipients={orgRecipients.current ?? []}
          setInfo={(e) => {
            setInfo(e);
          }}
          setError={(e) => {
            setError(e);
          }}
          handleDuplicateItemClick={(e) => {
            handleDuplicateItemClick(e);
          }}
          updateVideoRequest={(e) => {
            updateVideoRequest(e);
          }}
          updateVideoCard={(e) => {
            updateVideoCard(e);
          }}
          setAffectedItem={(e) => {}}
          handleUpdateBoard={(e) => {
            handleUpdateBoard();
          }}
        />
        {createRequestDuplicateModal}
      </div>
    </div>
  );
}
