/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const VIDEO_COLLECTOR_FIELDS = gql`
  fragment VideoCollectorFields on VideoCollector {
    id
    status
    encodedId
    title
    description
    password
    usePassword
    thankYouEmailContent
    approvedEmailContent
    allowVideoLibrary
    allowVideoLibraryNotReviewed
    allowUserEnteredQuestions
    saveName
    saveNameRequired
    saveTitle
    saveTitleRequired
    saveEmail
    saveEmailRequired
    savePhone
    savePhoneRequired
    startDate
    endDate
    videoCards {
      id
      encodedId
      staticThumbnails {
        FHD
        HD
        qHD
        nHD
        ntsc
        qcif
        sqcif
      }
      videoLink {
        FHD
        HD
        qHD
        nHD
        ntsc
      }
      animatedThumbnails {
        FHD
        HD
        qHD
        nHD
        ntsc
        qcif
        sqcif
      }
      question {
        en
        sv
      }
      actorName
      actorTitle {
        en
        sv
      }
      recipient {
        id
        firstName
        lastName
        title
      }
      status
    }
    questions {
      __typename
      ... on OrgQuestion {
        id
        question
      }
      ... on VideoCollectorCustomQuestion {
        id
        question
      }
    }
    responsible {
      id
      firstName
      lastName
      email
    }
  }
`;
