import PropTypes from 'prop-types';

function DashboardFilledIcon({ fill = '#000000' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 13H11V3H5C3.89543 3 3 3.89543 3 5V13ZM3 19C3 20.1046 3.89543 21 5 21H11V15H3V19ZM13 21H19C20.1046 21 21 20.1046 21 19V11H13V21ZM13 3V9H21V5C21 3.89543 20.1046 3 19 3H13Z"
        fill={fill}
      />
    </svg>
  );
}

export default DashboardFilledIcon;

DashboardFilledIcon.propTypes = {
  fill: PropTypes.string,
};
