import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useSetDetectedLanguage } from 'src/context/LanguageContext';
import Logo from '../common/li-logo';
import { gqClient } from '../../utils/graphQL';

const getOrganizationData = (orgNameSlug, encodedOrgId) => {
  if (orgNameSlug) {
    return gqClient.query({
      query: gql`
        query {
          organization(orgNameSlug: "${orgNameSlug}"){
            id
            orgName
            orgNameSlug
            encodedId
            lang
            logoPath
            doNotTrack
            promoteRecordVideoPortrait
            customTexts {
              uploadConsent
              soMeConsent {
                text
                enable
                mandatory
              }
            }
            style {
              backgroundColor
              textColor
              btnColor
              btnTextColor
            }
          }
        }
      `,
    });
  }
  return gqClient.query({
    query: gql`
      query {
        organization(encodedOrgId: "${encodedOrgId}"){
          id
          orgName
          orgNameSlug
          encodedId
          lang
          promoteRecordVideoPortrait
          customTexts {
            uploadConsent
            soMeConsent {
              text
              enable
              mandatory
            }
          }
          style {
            backgroundColor
            textColor
            btnColor
            btnTextColor
          }
        }
      }
    `,
  });
};

function Template({ children, className = '' }) {
  const params = useParams();
  const setDetectedLang = useSetDetectedLanguage();

  const [orgData, setOrgData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { orgnameslug: orgNameSlug, encodedorgid: encodedOrgId } = params;

    getOrganizationData(orgNameSlug, encodedOrgId)
      .then((result) => {
        setOrgData(result.data.organization);
        setDetectedLang(result.data.organization.lang);
      })
      .catch((e) => setError(e.message));
  }, [params, setDetectedLang]); // Include 'params' in the dependency array

  if (error) {
    return <ErrorComponent errorMessage={error} />;
  }
  if (!orgData) {
    return null;
  }

  const childrenArray = React.Children.toArray(children).map((e) =>
    React.cloneElement(e, { basicOrgData: orgData }),
  );
  const logo = orgData?.logoPath;

  return (
    <div
      className={`template public library ${className}`}
      style={{ backgroundColor: orgData.style?.backgroundColor }}
    >
      <header className="header" style={{ color: orgData.style?.textColor }}>
        <div className="company-name">
          {logo ? (
            <img
              src={logo}
              alt=""
              title={orgData.orgName}
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
            />
          ) : (
            orgData.orgName
          )}
        </div>
        <div>
          <a
            href="https://www.lifeinside.io/"
            style={{ color: orgData.style?.textColor }}
            target="_blank"
            className="powered-by"
            rel="noreferrer"
            alt="Powered by Lifeinside"
          >
            <Logo fill={orgData.style?.textColor} />
          </a>
        </div>
      </header>
      <div className="main">{childrenArray}</div>
    </div>
  );
}

export default Template;

function ErrorComponent(props) {
  const { errorMessage } = props;
  return (
    <div className="error-wrapper">
      <Typography variant="headlineSmall">Something went wrong</Typography>
      {errorMessage}
    </div>
  );
}

// Add prop type validation for 'children' and 'className'
Template.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ErrorComponent.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
