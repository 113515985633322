import LazyLoad from 'react-lazy-load';
import Vimeo from '@u-wave/react-vimeo';
import { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { useLanguage } from 'src/context/LanguageContext';
import {
  getVimeoVideoIdFromLink,
  getVideoThumbnailUrl,
  shadeHexColorToRgb,
  hexToRgb,
  statuses,
  hasVideoLink,
} from '../../../utils/utils';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import VideoCard from '../VideoLibrary/VideoCard';

import '../back-office.scss';

function StorylineVideoCard(props) {
  const lang = useLanguage();

  const videoData = props.data;

  const { basicOrgData } = props;
  const doNotTrack = props?.basicOrgData?.doNotTrack ?? false;
  const { editableStoryline } = props;
  const { selected } = props;
  const { handleSelected } = props;
  const { indexOfItem } = props;
  const { moveCard } = props;
  const { theme } = props;
  const { cardHeight, cardWidth, dynamicDimensions, setDynamicDimensions } = props;

  const videoLink = videoData?.videoLink?.[lang] ?? null;
  const vimeoVideoId = videoLink ? getVimeoVideoIdFromLink(videoLink) : null;

  const videoNotLiveVideo =
    hasVideoLink({
      excludeFromVimeo: basicOrgData?.premiumFeatures?.excludeFromVimeo,
      videoLink: videoData?.videoLink,
      orgLang: lang,
    }) &&
    (videoData.status === statuses.REVIEW ||
      videoData.status === statuses.APPROVED ||
      videoData.status === statuses.ARCHIVED);
  const backgroundColor = '#ffffff';
  const bgGradient = `linear-gradient(310deg, ${hexToRgb(backgroundColor)} 0%, ${shadeHexColorToRgb(
    backgroundColor,
    30,
  )} 100%)`;
  const textColor = '#111111';
  const [active, setActive] = useState(false);
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState(null);

  useEffect(() => {}, [active, videoThumbnailUrl]);

  const tags = videoData?.tags?.map((t) => {
    let className = 'tag';
    if (selected && videoLink && !videoNotLiveVideo) {
      className += ' tag-active';
    }
    return (
      <span className={className} key={t.id}>
        {t.name[lang]}
      </span>
    );
  });

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'videoCard',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(hoveredOverItem) {
      if (hoveredOverItem.videoData.id !== videoData.id) {
        moveCard(hoveredOverItem.videoData.id, videoData.id);
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'videoCard',
    item: () => {
      return { videoData, indexOfItem };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const widthVideo = `${cardWidth}`;
  const heightVideo = `${cardHeight}`;

  useEffect(() => {
    if (basicOrgData?.premiumFeatures?.excludeFromVimeo) {
      if (videoData.staticThumbnails?.nHD) {
        setVideoThumbnailUrl(videoData.staticThumbnails.nHD);
      } else if (videoData.staticThumbnails?.ntsc) {
        setVideoThumbnailUrl(videoData.staticThumbnails.ntsc);
      }
    } else if (vimeoVideoId) {
      getVideoThumbnailUrl({
        videoId: vimeoVideoId,
      })
        .then((response) => {
          setVideoThumbnailUrl(response);
        })
        .catch((error) => {
          setVideoThumbnailUrl(null);
        });
    }
  }, []);

  const handleActivateVimeoPlayer = (e) => {
    e.stopPropagation();
    setActive(!active);
  };

  if (basicOrgData?.premiumFeatures?.excludeFromVimeo) {
    return (
      <div
        ref={ref}
        data-handler-id={handlerId}
        className="storyline-video-card-aws"
        style={{
          cursor: 'move',
          backgroundColor: 'rgba(255, 255, 255, 0)',
        }}
        onClick={() => {
          if (!isDragging) {
            handleSelected();
          }
        }}
      >
        <div
          className={`video-wrapper ${
            !hasVideoLink({
              excludeFromVimeo: basicOrgData?.premiumFeatures?.excludeFromVimeo,
              videoLink: videoData?.videoLink,
              orgLang: lang,
            }) && 'no-video'
          }`}
        >
          <LazyLoad offset={600} width={dynamicDimensions.cardWidth}>
            <VideoCard
              key={videoData.id}
              playMode={false}
              theme={theme}
              videoCard={videoData}
              orgData={basicOrgData}
              updateVideoCard={() => {}}
              height={cardHeight}
              width={cardWidth}
              padding={cardHeight * 0.025}
              setAffectedItem={() => {}}
              setInfo={() => {}}
              handleUpdateBoard={() => {}}
              suggestedQuestions={[]}
              orgRecipients={[]}
              setError={() => {}}
              handleDuplicateItemClick={() => {}}
              storylineEditMode
              onClick={(e) => {
                if (!isDragging) {
                  handleSelected();
                }
              }}
              storylinePickVideoCardMode={editableStoryline}
              storylineIndexOfItem={indexOfItem}
              storylineSelected={selected}
              dynamicDimensions={dynamicDimensions}
              setDynamicDimensions={(e) => {
                setDynamicDimensions(e);
              }}
            />
          </LazyLoad>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className="storyline-video-card"
      style={{
        cursor: 'move',
        opacity,
        backgroundColor,
        background: bgGradient,
        color: textColor,
      }}
      onClick={() => {
        if (!isDragging) {
          handleSelected();
        }
      }}
    >
      <div className="header-wrapper">
        <Typography variant="bodyMedium" style={{ color: textColor }}>
          {videoData.question[lang]}
        </Typography>
      </div>
      <div className="board-header-right">
        {editableStoryline ? (
          <div>
            <Tooltip
              title={translationsStoryline.selectVideoCardAriaLabel[lang]}
              className="select-video-card-label"
            >
              <label
                htmlFor="select-video-card"
                aria-label={translationsStoryline.selectVideoCardAriaLabel[lang]}
              >
                <input
                  type="checkbox"
                  id="select-video-card"
                  name="select-video-card"
                  checked={selected}
                  onChange={() => {}}
                />
              </label>
            </Tooltip>
          </div>
        ) : (
          indexOfItem >= 0 && (
            <div className="video-card-index">
              <Typography variant="bodySmall">{indexOfItem + 1}</Typography>
            </div>
          )
        )}
      </div>
      <div
        className={`video-wrapper ${
          !hasVideoLink({
            excludeFromVimeo: basicOrgData?.premiumFeatures?.excludeFromVimeo,
            videoLink: videoData?.videoLink,
            orgLang: lang,
          }) && 'no-video'
        }`}
        style={{ width: `${widthVideo}px`, height: `${heightVideo}px` }}
      >
        {active || !videoThumbnailUrl ? (
          <LazyLoad key={_.uniqueId(videoData.id)} offset={600} width={135}>
            {vimeoVideoId && !basicOrgData?.premiumFeatures?.excludeFromVimeo ? (
              <Vimeo
                video={vimeoVideoId}
                width={widthVideo}
                height={heightVideo - (videoNotLiveVideo ? 50 : 0)}
                color={_.trimStart(basicOrgData.style.btnColor, '#')}
                dnt={doNotTrack}
                autoplay={!!videoThumbnailUrl}
                onEnd={() => {
                  setActive(false);
                }}
              />
            ) : (
              <VideoCard
                key={videoData.id}
                playMode
                theme={theme}
                videoCard={videoData}
                orgData={basicOrgData}
                updateVideoCard={() => {}}
                height={175}
                width={135}
                padding={175 * 0.025}
                setAffectedItem={() => {}}
                setInfo={() => {}}
                handleUpdateBoard={() => {}}
                suggestedQuestions={[]}
                orgRecipients={[]}
                setError={() => {}}
                handleDuplicateItemClick={() => {}}
              />
            )}
          </LazyLoad>
        ) : (
          <LazyLoad offset={600} width={135}>
            {vimeoVideoId && !basicOrgData?.premiumFeatures?.excludeFromVimeo ? (
              <Button
                style={{
                  width: `${widthVideo}px`,
                  height: `${heightVideo - (videoNotLiveVideo ? 50 : 0)}px`,
                }}
                onClick={(e) => {
                  if (!isDragging) {
                    handleActivateVimeoPlayer(e);
                  }
                }}
              >
                <img src={videoThumbnailUrl} className="thumbnail" alt="" />
              </Button>
            ) : (
              <Button
                style={{
                  width: `${widthVideo}px`,
                  height: `${heightVideo - (videoNotLiveVideo ? 50 : 0)}px`,
                }}
                onClick={(e) => {
                  if (!isDragging) {
                    handleActivateVimeoPlayer(e);
                  }
                }}
              >
                <img src={videoThumbnailUrl} className="thumbnail" alt="" />
              </Button>
            )}
          </LazyLoad>
        )}
        {(!hasVideoLink({
          excludeFromVimeo: basicOrgData?.premiumFeatures?.excludeFromVimeo,
          videoLink: videoData?.videoLink,
          orgLang: lang,
        }) ||
          videoData.status === statuses.REVIEW ||
          videoData.status === statuses.APPROVED ||
          videoData.status === statuses.ARCHIVED) && (
          <div>
            {!hasVideoLink({
              excludeFromVimeo: basicOrgData?.premiumFeatures?.excludeFromVimeo,
              videoLink: videoData?.videoLink,
              orgLang: lang,
            }) && <img src="/images/icons/film-camera-icon.svg" alt="" />}
            <Typography variant="bodySmall">
              {translationsStoryline.noVideoNewVideoStatus[videoData.status][lang]}
            </Typography>
          </div>
        )}
      </div>
      <div className="actor">
        <Typography variant="bodySmall" className="actor-name">
          {videoData?.recipient?.name ?? videoData?.actorName ?? ''} {' | '}{' '}
          {videoData?.recipient?.title ?? videoData.actorTitle[lang] ?? ''}
        </Typography>
      </div>
    </div>
  );
}

export default StorylineVideoCard;
