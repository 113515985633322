interface LeftIconProps {
  fill?: string;
}

function LeftIcon({ fill = '#121212' }: LeftIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M15 6.70501C14.61 6.31501 13.98 6.31501 13.59 6.70501L9.00002 11.295C8.61002 11.685 8.61002 12.315 9.00002 12.705L13.59 17.295C13.98 17.685 14.61 17.685 15 17.295C15.39 16.905 15.39 16.275 15 15.885L11.12 11.995L15 8.11501C15.39 7.72501 15.38 7.08501 15 6.70501Z"
        fill={fill}
      />
    </svg>
  );
}

export default LeftIcon;
