import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../fragments/videoCards';
import { STORYLINE_DETAILS_FRAGMENT } from '../fragments/storylines';
import { VIDEO_REQUEST_DETAILS_FRAGMENT } from '../fragments/videoRequests';

export class ApiUtilsStoryline {
  #backOfficeClient;

  constructor(backOfficeClient) {
    this.#backOfficeClient = backOfficeClient;
  }

  getAllVideoCardsByOrgId(encodedOrgId, statuses = []) {
    const statusesString = statuses.join(',');
    return this.#backOfficeClient.query({
      query: gql`
            ${VIDEO_CARD_DETAILS_FRAGMENT}
            ${VIDEO_REQUEST_DETAILS_FRAGMENT}
            query {
              allLiveVideoCardsByOrgIdByStatus(encodedOrgId: "${encodedOrgId}", statuses: "${statusesString}") {
                videoCards {
                    ...VideoCardDetails
                  } 
                  videoRequests {
                    ...VideoRequestDetails
                  } 
              }
            }
          `,
    });
  }

  getVideoCardsByStorylineId({ encodedOrgId, storylineId, statuses = [] }) {
    const statusesString = statuses.join(',');
    return this.#backOfficeClient.query({
      query: gql`
              ${VIDEO_CARD_DETAILS_FRAGMENT}
              ${VIDEO_REQUEST_DETAILS_FRAGMENT}
              query {
                videoCardsByStorylineIdAndTags(encodedOrgId: "${encodedOrgId}", storylineId: ${storylineId}, statuses: "${statusesString}") {
                  tagCategories {
                    id
                    name {
                      sv
                      en
                    }
                    tags {
                      id,
                      name {
                        sv
                        en
                      }
                      isHidden
                    }
                  }
                  videoCards {
                    ...VideoCardDetails
                  } 
                  videoRequests {
                    ...VideoRequestDetails
                  } 
                  randomizedVideoCards
                }
              }
            `,
    });
  }

  getVideosByTags(encodedOrgId, tags, statuses = []) {
    const tagIds = tags.join(',');
    const statusesString = statuses.join(',');
    return this.#backOfficeClient.query({
      query: gql`
      ${VIDEO_CARD_DETAILS_FRAGMENT}
      ${VIDEO_REQUEST_DETAILS_FRAGMENT}
              query {
                videoCardsByTagsWithStatus(encodedOrgId: "${encodedOrgId}", tagIds: [${tagIds}], statuses: "${statusesString}") {
                  videoCards {
                    ...VideoCardDetails
                  } 
                  videoRequests {
                    ...VideoRequestDetails
                  } 
                }
              }
            `,
    });
  }

  createStoryline({ encodedOrgId, orgId, status }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
              ${VIDEO_CARD_DETAILS_FRAGMENT}
              ${STORYLINE_DETAILS_FRAGMENT}
              mutation {
                createStoryline(encodedOrgId: "${encodedOrgId}", input: {
                  orgId: ${orgId},
                  status: "${status}",
                }){
                  users {
                    id
                    firstName
                    lastName
                  }
                  urls
                  storylines {
                      ...StorylineDetails
                  }
                  storyline {
                      ...StorylineDetails
                  }
                  tagCategories {
                    id
                    name {
                      sv
                      en
                    }
                    tags {
                      id,
                      name {
                        sv
                        en
                      }
                      isHidden
                    }
                  }
                  videoCards {
                    ...VideoCardDetails
                  }
                }
              }
            `,
    });
  }

  updateStoryline({
    encodedOrgId,
    orgId,
    id,
    title,
    urls,
    videoCards,
    status,
    excludeWidgetLibraryBtn,
  }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
              ${STORYLINE_DETAILS_FRAGMENT}
              mutation {
                updateStoryline(encodedOrgId: "${encodedOrgId}", input: {
                  orgId: ${orgId},
                  status: "${status}",
                  id: ${id},
                  title: "${title}",
                  urls: [${urls}],
                  videoCards: [${videoCards}],
                  excludeWidgetLibraryBtn: ${excludeWidgetLibraryBtn},
                  }
                ){
                  users {
                    id
                    firstName
                    lastName
                  }
                  urls
                  storylines {
                    ...StorylineDetails
                  }
                }
              }
            `,
    });
  }

  updateStorylineBeta({
    encodedOrgId,
    orgId,
    id,
    title,
    videoCards,
    status,
    excludeWidgetLibraryBtn,
    urlsJsonb,
  }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${STORYLINE_DETAILS_FRAGMENT}
        mutation UpdateStorylineBeta($encodedOrgId: String!, $input: StorylineInput!) {
          updateStorylineBeta(encodedOrgId: $encodedOrgId, input: $input) {
            users {
              id
              firstName
              lastName
            }
            urls
            storylines {
              ...StorylineDetails
            }
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: {
          id,
          orgId,
          status,
          title,
          videoCards: videoCards ?? [],
          excludeWidgetLibraryBtn,
          urlsJsonb: urlsJsonb?.map(({ __typename, storylineId, ...keepAttrs }) => keepAttrs) ?? [],
        },
      },
    });
  }

  deleteStoryline({ encodedOrgId, storylineId, orgId }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
              ${STORYLINE_DETAILS_FRAGMENT}
              mutation {
                deleteStoryline(encodedOrgId: "${encodedOrgId}", input: {
                  id: ${storylineId},
                  orgId: ${orgId},
                }){
                  users {
                    id
                    firstName
                    lastName
                  }
                  urls
                  storylines {
                    ...StorylineDetails
                  }
                  storyline {
                    ...StorylineDetails
                  }
                }
              }
            `,
    });
  }
}

export const useApiUtilsStoryline = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new ApiUtilsStoryline(backOfficeClient), [backOfficeClient]);
};
