import { useContext, useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';

import ThemeContext from 'src/utils/ThemeContext';
import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';

import translations from './SelectBackOfficeTranslations';

export type MinimalOrganization = {
  id: number;
  encodedId: string;
  orgName: string;
  orgNameSlug: string;
  logoPath: string | null;
  backgroundColor: string | null;
};

type MyOrganizationsResult = {
  myOrganizations: MinimalOrganization[];
};

export const MY_ORGANIZATIONS_QUERY = gql`
  query MyOrganizations {
    myOrganizations {
      id
      encodedId
      orgName
      orgNameSlug
      logoPath
      backgroundColor
    }
  }
`;

const backOfficePath = (org: MinimalOrganization) => `/${org.orgNameSlug}/backoffice`;

interface BackOfficeCardProps {
  orgName: string;
  orgLogo: string | null;
  orgBgColor: string | null;
  onClick: () => void;
}

function BackOfficeCard({ orgName, orgLogo, orgBgColor, onClick }: BackOfficeCardProps) {
  const logoHeight = 96;
  const logoBgColor = orgBgColor ?? 'white';
  return (
    <Card variant="outlined">
      <CardActionArea onClick={onClick}>
        {orgLogo ? (
          <CardMedia
            component="img"
            sx={{ height: logoHeight, background: logoBgColor }}
            image={orgLogo}
            alt={`${orgName} logo`}
          />
        ) : (
          <Box sx={{ height: logoHeight, background: logoBgColor }} />
        )}
        <CardContent>
          <Typography variant="h5" component="p" sx={{ overflowWrap: 'break-word' }}>
            {orgName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function SelectBackoffice() {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const setLanguage = useSetLanguage();
  const navigate = useNavigate();

  const { data, error } = useQuery<MyOrganizationsResult>(MY_ORGANIZATIONS_QUERY);
  const myOrgs = useMemo(() => {
    if (data?.myOrganizations) {
      // data.myOrganizations is immutable, need to clone before sorting.
      return [...data.myOrganizations].sort((orgA, orgB) => {
        const nameA = orgA.orgName.toLocaleLowerCase();
        const nameB = orgB.orgName.toLocaleLowerCase();
        return nameA.localeCompare(nameB);
      });
    }
    return null;
  }, [data]);

  useEffect(() => {
    if (error != null || myOrgs?.length === 0) {
      navigate('/login', { replace: true });
    }

    // If only part of a single organization, redirect there.
    if (myOrgs?.length === 1) {
      navigate(backOfficePath(myOrgs[0]), { replace: true });
    }
  }, [error, myOrgs, navigate]);

  const renderContent = () => {
    if (myOrgs == null || myOrgs.length === 1) {
      return <LoadingIndicator />;
    }

    return (
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {myOrgs.map((org) => (
          <Grid item xs={12} md={6} xl={4} key={org.id}>
            <BackOfficeCard
              onClick={() => navigate(backOfficePath(org))}
              orgName={org.orgName}
              orgLogo={org.logoPath}
              orgBgColor={org.backgroundColor}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <div style={{ background: theme.palette.primary.main, minHeight: '100vh' }}>
      <Grid container sx={{ p: 3, pb: 2 }}>
        <Grid item xs={6}>
          <img src="/lifeinside_logo_horizontal-326x52.svg" width="184" alt="Life Inside" />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                color: 'white',
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <img src="/globe-icon.svg" alt="Globe" style={{ width: 18, height: 18 }} />
              <select
                value={lang}
                onChange={(e) => setLanguage(e.target.value as Language)}
                style={{
                  flex: 1,
                  height: '100%',
                  paddingLeft: 8,
                  paddingRight: 32,
                  paddingTop: 6,
                  paddingBottom: 6,
                  backgroundColor: 'transparent',
                  color: 'white',
                  border: 'none',
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                  appearance: 'none',
                  backgroundImage: 'url(arrow_drop_down_24px.svg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right center',
                  backgroundSize: '24px',
                  MozAppearance: 'none',
                  WebkitAppearance: 'none',
                }}
              >
                <option value="en">English</option>
                <option value="sv">Svenska</option>
              </select>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Card
            sx={{
              border: '0px',
              borderRadius: '16px',
              ml: 1,
              mr: 1,
              p: 2,
              pt: 3,
            }}
            elevation={0}
          >
            <CardHeader
              title={translations.selectOrganization[lang]}
              component="h1"
              sx={{
                pt: 0,
                pb: 1,
                mt: 0,
                mb: 0,
              }}
              titleTypographyProps={{
                variant: 'headlineLargeBoldRecoleta',
                align: 'center',
              }}
            />
            <CardContent sx={{ mb: 0, pb: 0 }}>{renderContent()}</CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ p: 2 }}>
        <Typography
          variant="bodySmall"
          color="text.light"
          component="p"
          sx={{
            textAlign: 'center',
          }}
        >
          © {new Date().getFullYear()} Life Inside
        </Typography>
      </Box>
    </div>
  );
}

export default SelectBackoffice;
