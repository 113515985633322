import { useState } from 'react';
import parse from 'html-react-parser';
import { gql, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';
import { StyleDialog } from '../common/SimpleStyledDialog/StyleDialog';

const ME_QUERY = gql`
  query Me($encodedOrgId: String) {
    me(encodedOrgId: $encodedOrgId) {
      id
      firstName
      lastName
    }
  }
`;

export default function UploadVideoDialog({ onClose, open, orgData }) {
  const lang = useLanguage();
  const encodedOrgId = orgData.encodedId;

  const { data: meData } = useQuery(ME_QUERY, {
    variables: { encodedOrgId },
  });
  const user = meData?.me;

  const acceptedUploadConsentText =
    decodeURIComponent(orgData.customTexts?.uploadConsent) ??
    orgData.customTexts?.uploadConsent ??
    '';

  const soMeConsent =
    orgData.customTexts?.soMeConsent?.text?.length > 0
      ? orgData.customTexts?.soMeConsent?.text
      : orgData.customTexts?.soMeConsent;
  const acceptedSoMeConsentText = decodeURIComponent(soMeConsent) ?? soMeConsent ?? '';

  const [acceptedUploadConsent, setAcceptedUploadConsent] = useState(false);
  const [acceptedSoMeConsent, setAcceptedSoMeConsent] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <StyleDialog
      onClose={() => {
        onClose({
          acceptedUploadConsent: null,
          acceptedSoMeConsent: null,
        });
      }}
      open={open}
    >
      <DialogTitle>
        {translations.videoCard.directUploadConsent[lang]}
        <IconButton
          aria-label={translations.videoCard.closeAriaLabel[lang]}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={() => {
            onClose({
              acceptedUploadConsent: null,
              acceptedSoMeConsent: null,
            });
          }}
        >
          <i className="icon">close</i>
        </IconButton>
      </DialogTitle>

      <label className="terms" style={{ margin: '20px', display: 'flex' }}>
        <input
          type="checkbox"
          required
          value={acceptedUploadConsent}
          onChange={() => setAcceptedUploadConsent(!acceptedUploadConsent)}
          style={{
            width: '25px',
            height: '25px',
            marginTop: '0px',
            marginRight: '10px',
            marginBottom: 'auto',
          }}
        />

        <div>
          {translations.upload.userAcceptedUploadConsentText[lang]({
            userName: `${user.firstName} ${user.lastName}`,
          })}
          {`"${parse(acceptedUploadConsentText?.trim()?.replace(/\n/g, '<br />'))}"`}
          <b style={{ color: 'red' }}> *</b>
        </div>
      </label>
      {orgData.customTexts?.soMeConsent?.enable && (
        <label className="terms" style={{ margin: '20px', display: 'flex' }}>
          <input
            type="checkbox"
            value={acceptedSoMeConsent}
            onChange={() => setAcceptedSoMeConsent(!acceptedSoMeConsent)}
            style={{
              width: '25px',
              height: '25px',
              marginTop: '0px',
              marginRight: '10px',
              marginBottom: 'auto',
            }}
          />
          <div>
            {translations.upload.userAcceptedUploadConsentText[lang]({
              userName: `${user.firstName} ${user.lastName}`,
            })}
            {`"${parse(acceptedSoMeConsentText?.trim()?.replace(/\n/g, '<br />'))}"`}
            {orgData.customTexts?.soMeConsent?.mandatory && <b style={{ color: 'red' }}> *</b>}
          </div>
        </label>
      )}

      <div className="dialog-footer">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            onClose({
              acceptedUploadConsent: null,
              acceptedSoMeConsent: null,
            });
          }}
        >
          {translations.videoCard.cancel[lang]}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          disabled={
            !acceptedUploadConsent ||
            (!acceptedSoMeConsent &&
              orgData.customTexts?.soMeConsent?.enable &&
              orgData.customTexts?.soMeConsent?.mandatory)
          }
          onClick={() => {
            onClose({
              acceptedUploadConsent: acceptedUploadConsent
                ? [
                    ...translations.upload.userAcceptedUploadConsentText[lang]({
                      userName: `${user.firstName} ${user.lastName}`,
                    }),
                    '"',
                    acceptedUploadConsentText,
                    '"',
                  ].join('')
                : null,
              acceptedSoMeConsent:
                acceptedSoMeConsent && orgData.customTexts?.soMeConsent?.enable
                  ? [
                      ...translations.upload.userAcceptedUploadConsentText[lang]({
                        userName: `${user.firstName} ${user.lastName}`,
                      }),
                      '"',
                      acceptedSoMeConsentText,
                      '"',
                    ].join('')
                  : null,
            });
          }}
        >
          {translations.upload.continueUpload[lang]}{' '}
        </Button>
      </div>
    </StyleDialog>
  );
}
