import { useOutletContext } from 'react-router-dom';
import { useState } from 'react';
import _ from 'lodash';
import './questions.scss';

import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Switch from '@mui/material/Switch';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import DataGridTable from '../../../common/DataGridTable';
import SimpleCard from '../../../common/SimpleCard';
import AlertDialog from '../../../common/AlertDialog';
import { useQuestionsActions } from './Actions';
import { translations } from '../../../../utils/translationsSettings';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function QuestionCategoryCard({
  expanded,
  setExpanded,
  props,
  title,
  category,
  handleEditQuestion,
  questions,
  setQuestions,
  handleEditCategory,
}) {
  const lang = useLanguage();
  const questionsActions = useQuestionsActions();
  const { basicOrgData } = useOutletContext();
  const hasAutoRequest = basicOrgData?.premiumFeatures?.autoRequests;
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDelete = (question) => {
    if (question) {
      questionsActions
        .deleteQuestion({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          id: question.id,
          questionCategoryId: category.id,
        })
        .then((response) => {
          setSimpleAlertDialogOpenDelete(false);
          setQuestions(response?.data?.deleteOrgQuestion);
          if (response?.data?.deleteOrgQuestion.status !== 'SUCCESS') {
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
    setQuestionToDelete(null);
  };

  const columns = [
    {
      field: 'question',
      headerName: translations.question.dataGrid.header.question[lang],
      minWidth: 600,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    ...(hasAutoRequest
      ? [
          {
            field: 'enableAutoRequest',
            headerName: translations.question.dataGrid.header.automationActivated[lang],
            minWidth: 200,
            disableColumnMenu: true,
            flex: 0,
            renderCell: (params) => {
              if (params.row.id >= 0) {
                return (
                  <Switch
                    color="secondary"
                    checked={params.row.enableAutoRequest}
                    onChange={() => {
                      toggleActiveForAutomation(params.id);
                    }}
                  />
                );
              }
            },
          },
        ]
      : []),
    {
      field: 'actions',
      headerName: '',
      minWidth: 20,
      sortable: false,
      flex: 0,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (hoveredRow === params.id && params.row.id >= 0) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => handleEditQuestion(params.row)}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setQuestionToDelete(params.row);
                  setSimpleAlertDialogOpenDelete(true);
                }}
              >
                <img src="/images/icons/bin-icon.svg" alt="" />
              </IconButton>
            </Box>
          );
        }
        return null;
      },
    },
  ];

  const toggleActiveForAutomation = (id) => {
    const question = questions?.find((q) => {
      return q.id === id;
    });
    const newQuestions = [
      ...questions.map((row) =>
        row.id === id ? { ...row, enableAutoRequest: !row?.enableAutoRequest } : row,
      ),
    ];

    setQuestions(newQuestions);
    handelRowChange({ id, enableAutoRequestValue: !question?.enableAutoRequest });
  };

  const handelRowChange = ({ id, questionValue, enableAutoRequestValue }) => {
    questionsActions
      .updateQuestion({
        encodedOrgId: basicOrgData.encodedId,
        orgId: basicOrgData.id,
        id,
        question: questionValue,
        enableAutoRequest: enableAutoRequestValue,
        questionCategoryId: category.id,
      })
      .then((response) => {
        setQuestions(response?.data?.updateOrgQuestion);
        if (response?.data?.updateOrgQuestion.status !== 'SUCCESS') {
        }
      })
      .catch((error) => {});
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const onMouseEnterRow = (event) => {
    const rowId = Number(event.currentTarget.dataset.id);
    const row = questions.find((el) => el.id === rowId);
    setHoveredRow(row.id);
  };

  const onMouseLeaveRow = () => {
    setHoveredRow(null);
  };

  return (
    <SimpleCard {...props} sx={{ maxWidth: '100%' }}>
      {simpleAlertDialogOpenDelete && questionToDelete && (
        <AlertDialog
          key={_.uniqueId()}
          open={simpleAlertDialogOpenDelete}
          title={translations.question.deleteTitle[lang]}
          description={translations.question.deleteDescription[lang]}
          subDescription={questionToDelete?.question}
          confirmBtnText={translations.question.deleteConfirmBtnText[lang]}
          cancelBtnText={translations.question.deleteCancelBtnText[lang]}
          onConfirm={() => {
            handleDelete(questionToDelete);
            setSimpleAlertDialogOpenDelete(false);
          }}
          onCancel={() => {
            setSimpleAlertDialogOpenDelete(false);
          }}
        />
      )}
      <CardHeader
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          handleExpandClick();
        }}
        action={
          <>
            <Typography variant="bodyMedium">{questions?.length}</Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={translations.question.showMore[lang]}
            >
              <ExpandMoreIcon />
            </ExpandMore>
            {category.id && (
              <IconButton aria-label="settings" onClick={handleEditCategory}>
                <MoreVertIcon />
              </IconButton>
            )}
          </>
        }
        title={<Typography variant="bodyLarge">{title}</Typography>}
      />
      <CardActions disableSpacing />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {questions?.length > 0 && (
            <DataGridTable
              rows={questions}
              columns={columns}
              pageSize={questions?.length}
              onCellEditCommit={(params, event) => {
                handelRowChange({ id: params.id, questionValue: params.value });
              }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slotProps={{
                row: {
                  onMouseEnter: onMouseEnterRow,
                  onMouseLeave: onMouseLeaveRow,
                },
              }}
            />
          )}
        </CardContent>
      </Collapse>
    </SimpleCard>
  );
}
