import { TypographyOptions } from '@mui/material/styles/createTypography';

import fontFamily from './fontFamily';

const typographyDefinition: TypographyOptions = {
  displayLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '57px',
    lineHeight: 64 / 57,
    letterSpacing: '-0.25px',
    fontFamily,
  },
  displayMedium: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '45px',
    lineHeight: 52 / 45,
    fontFamily,
  },
  displaySmall: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: 44 / 36,
    fontFamily,
  },
  headlineLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: 40 / 32,
    fontFamily,
  },
  headlineLargeRecoleta: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'Recoleta-Bold',
    fontSize: '32px',
    lineHeight: 40 / 32,
  },
  headlineLargeBoldRecoleta: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontFamily: 'Recoleta-Bold',
    fontSize: '32px',
    lineHeight: 40 / 32,
  },
  headlineMedium: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: 36 / 28,
    fontFamily,
  },
  headlineSmall: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: 32 / 24,
    fontFamily,
  },
  headlineSmallBold: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: 32 / 24,
    fontFamily,
  },
  headlineSmallBoldRecoleta: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: 32 / 24,
    fontFamily: 'Recoleta-Bold',
  },
  titleLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: 28 / 22,
    fontFamily,
  },
  titleLargeRecoletaBold: {
    fontFamily: 'Recoleta-Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: 28 / 22,
  },
  titleLargeRecoleta: {
    fontFamily: 'Recoleta-Bold',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: 32 / 40,
  },
  titleLargeBold: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: 28 / 22,
    fontFamily,
  },
  titleMedium: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 24 / 16,
    letterSpacing: '0.15px',
    fontFamily,
  },
  titleMediumRecoleta: {
    fontStyle: 'normal',
    fontFamily: 'Recoleta-Bold',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 24 / 16,
    letterSpacing: '0.15px',
  },
  titleSmall: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.1px',
    fontFamily,
  },
  labelLarge: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.1px',
    fontFamily,
  },
  labelMedium: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 16 / 12,
    letterSpacing: '0.5px',
    fontFamily,
  },
  labelMediumSemiBold: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 16 / 12,
    letterSpacing: '0.5px',
    fontFamily,
  },
  labelMediumBold: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 12 / 16,
    letterSpacing: '0.5px',
    fontFamily,
  },
  labelSmall: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: 16 / 11,
    letterSpacing: '0.5px',
    fontFamily,
  },
  bodyLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 24 / 16,
    letterSpacing: '0.5px',
    fontFamily,
  },
  bodyMedium: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.25px',
    fontFamily,
  },
  bodyMediumBold: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.25px',
    fontFamily,
  },
  bodySmall: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 16 / 12,
    letterSpacing: '0.4px',
    fontFamily,
  },
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: '0',
    fontFamily,
    lineHeight: 0.1,
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '57px',
    lineHeight: 64 / 57,
    letterSpacing: '-0.25px',
    fontFamily,
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '45px',
    lineHeight: 52 / 45,
    fontFamily,
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: 44 / 36,
    fontFamily,
  },
  h4: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: 40 / 32,
    fontFamily,
  },
  h5: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: 32 / 24,
    fontFamily,
  },
  h6: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: 28 / 22,
    fontFamily,
  },
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 24 / 16,
    letterSpacing: '0.15px',
    fontFamily,
  },
  subtitle2: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.1px',
    fontFamily,
  },
  body1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 24 / 16,
    letterSpacing: '0.5px',
    fontFamily,
  },
  body2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.25px',
    fontFamily,
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.1px',
    fontFamily,
  },
  caption: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 16 / 12,
    letterSpacing: '0.4px',
    fontFamily,
  },
  overline: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: 16 / 11,
    letterSpacing: '0.5px',
    fontFamily,
  },
};

export default typographyDefinition;
