import PropTypes from 'prop-types';

function EndScreensOutlinedIcon({ fill = '#FFFFFF' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 14C7.28334 14 7.521 13.904 7.713 13.712C7.905 13.52 8.00067 13.2827 8 13C8 12.7167 7.904 12.479 7.712 12.287C7.52 12.095 7.28267 11.9993 7 12C6.71667 12 6.479 12.096 6.287 12.288C6.095 12.48 5.99934 12.7173 6 13C6 13.2833 6.096 13.521 6.288 13.713C6.48 13.905 6.71734 14.0007 7 14ZM7 10C7.28334 10 7.521 9.904 7.713 9.712C7.905 9.52 8.00067 9.28267 8 9C8 8.71667 7.904 8.479 7.712 8.287C7.52 8.095 7.28267 7.99934 7 8C6.71667 8 6.479 8.096 6.287 8.288C6.095 8.48 5.99934 8.71734 6 9C6 9.28334 6.096 9.521 6.288 9.713C6.48 9.905 6.71734 10.0007 7 10ZM9 14H18V12H9V14ZM9 10H18V8H9V10ZM8 21V19H4C3.45 19 2.979 18.804 2.587 18.412C2.195 18.02 1.99934 17.5493 2 17V5C2 4.45 2.196 3.979 2.588 3.587C2.98 3.195 3.45067 2.99934 4 3H20C20.55 3 21.021 3.196 21.413 3.588C21.805 3.98 22.0007 4.45067 22 5V17C22 17.55 21.804 18.021 21.412 18.413C21.02 18.805 20.5493 19.0007 20 19H16V21H8ZM4 17H20V5H4V17Z"
        fill={fill}
      />
    </svg>
  );
}

export default EndScreensOutlinedIcon;

EndScreensOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
