import React, { useState, useEffect, useRef } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import AppBar from '@mui/material/AppBar';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsRequest } from '../../../utils/translationsRequest';
import { translations } from '../../../utils/translations';
import { useRequestsApi } from './RequestsApi';
import VideoRequest from '../VideoRequest';
import { getDefaultState, statuses } from '../../../utils/utils';
import SimpleListItemText from '../../common/SimpleListItemText';
import getTheme from '../../common/SplitButton/SplitButtonThemes';

export default function RequestsHead({
  setTagsToFilter,
  tagsToFilter,
  setSelectLocation,
  selectLocation,
  setSelectDepartment,
  selectDepartment,
  setSelectTheme,
  selectTheme,
  setSelectedUser,
  selectedUser,
  orgData,
  users,
  handleUpdateBoard,
  selectedStatus,
  setSelectedStatus,
  setInfo,
  suggestedQuestions,
  orgRecipients,
  setAffectedItem,
  updateVideoRequest,
}) {
  const lang = useLanguage();
  const requestsApi = useRequestsApi();
  const [createNewVideoRequestIsOpen, setCreateNewVideoRequestIsOpen] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [userOptions, setUserOptions] = useState([
    {
      value: 'ALL_USERS',
      label: `${translations.allUsers[lang]}`,
    },
    ...(users?.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` })) ??
      []),
  ]);

  const [showAddSection, setShowAddSection] = useState(false);
  const [newTagName, setNewTagName] = useState('');

  const statusesOptions = [
    getDefaultState('status', lang),
    {
      value: statuses.SUGGESTION,
      label: `${translations.status[statuses.SUGGESTION][lang]}`,
    },
    {
      value: statuses.PENDING,
      label: `${translations.status[statuses.PENDING][lang]}`,
    },
    {
      value: statuses.REVIEW,
      label: `${translations.status[statuses.REVIEW][lang]}`,
    },
  ]?.sort((a, b) => a?.label.localeCompare(b?.label));

  useEffect(() => {
    const newOptions =
      users?.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` })) ?? [];
    setUserOptions([
      {
        value: 'ALL_USERS',
        label: `${translations.allUsers[lang]}`,
      },
      ...newOptions,
    ]);
  }, [users]);

  const theme = getTheme('active');

  const toggleDrawer = (anchor, open) => (event) => {
    const dropdownWrapper = !!event.target.closest('#menu-');
    if (
      !(
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift' || dropdownWrapper)
      )
    ) {
      setDrawer({ ...drawer, [anchor]: open });
    }
  };

  const handleFilterChange = (event, type) => {
    const {
      target: { value },
    } = event;
    if (value.includes('create_tag')) {
      setShowAddSection(true);
    }
    switch (type) {
      case 'Location':
      case 'Plats':
        setSelectLocation(value);
        break;
      case 'Avdelning':
      case 'Department':
        setSelectDepartment(value);
        break;
      case 'Tema':
      case 'Theme':
        setSelectTheme(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      selectLocation?.length > 0 ||
      selectDepartment?.length > 0 ||
      selectTheme?.length > 0 ||
      selectedUser?.value !== 'ALL_USERS' ||
      tagsToFilter?.length > 0 ||
      selectedStatus?.value !== 'ALL'
    ) {
      setIsApply(true);
    } else {
      setIsApply(false);
    }
    updateIds(selectLocation, selectDepartment, selectTheme);
  }, [selectLocation, selectDepartment, selectTheme, selectedUser, selectedStatus]);

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 220,
      },
    },
  };

  useEffect(() => {
    if (drawer.right) {
      setSelectLocation(selectLocation.filter((item) => tagsToFilter.includes(item.id)));
      setSelectDepartment(selectDepartment.filter((item) => tagsToFilter.includes(item.id)));
      setSelectTheme(selectTheme.filter((item) => tagsToFilter.includes(item.id)));
    }
  }, [drawer]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const deleteTagConfirm = (event, id) => {
    event.stopPropagation();
    setConfirmPopData({
      title: translations.tag.deleteItem[lang],
      description: translations.tag.areYouSureDelete[lang],
      onCancel: () => setConfirmPopData(null),
      onConfirm: () => deleteTagConfirmed(id),
    });
    setDrawer({ ...drawer, right: false });
  };

  const updateIds = (locationData = [], departmentData = [], themeData = []) => {
    const temp = [...locationData, ...departmentData, ...themeData];
    const ids = temp.map((item) => item.id);
    setTagsToFilter(ids);
  };

  const handleApply = () => {
    setDrawer({ ...drawer, right: false });
    updateIds(selectLocation, selectDepartment, selectTheme);
    handleUpdateBoard();
  };

  const handleUserSelected = (e) => {
    setSelectedUser(e);
    handleUpdateBoard();
  };

  const handleSelectedStatus = (e) => {
    setSelectedStatus(e);
    handleUpdateBoard();
  };

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const deleteTagConfirmed = (id) => {
    if (!id) {
      setError(translations.savedError[lang]);
    }

    requestsApi
      .deleteTag(orgData.encodedId, id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        setError(translations.savedError[lang]);
      });
  };

  const checkCounts = (arg) => {
    if (arg.length > 2) {
      const len = arg.length - 2;
      const temp = arg.slice(0, 2).join(', ');
      return `${temp} +${len}`;
    }
    if (arg.length) {
      return arg.join(', ');
    }
    return null;
  };

  const handleDeleteChip = (type) => {
    switch (type) {
      case 'location': {
        const ids = selectLocation.flatMap((item) => {
          return item.id;
        });
        const filterId = tagsToFilter.filter((id) => !ids.includes(id));
        setTagsToFilter(filterId);
        setSelectLocation([]);
        break;
      }
      case 'department': {
        const ids = selectDepartment.flatMap((item) => {
          return item.id;
        });
        const filterId = tagsToFilter.filter((id) => !ids.includes(id));
        setTagsToFilter(filterId);
        setSelectDepartment([]);
        break;
      }
      case 'theme': {
        const ids = selectTheme.flatMap((item) => {
          return item.id;
        });
        const filterId = tagsToFilter.filter((id) => !ids.includes(id));
        setTagsToFilter(filterId);
        setSelectTheme([]);
        break;
      }
      case 'user':
        setSelectedUser(getDefaultState('user', lang));
        break;
      case 'status':
        setSelectedStatus(getDefaultState('status', lang));
        break;

      default:
        break;
    }
  };

  const clearState = () => {
    setDefaultFilters();
    updateIds();
    handleUpdateBoard();
  };

  const setDefaultFilters = () => {
    setSelectLocation([]);
    setSelectDepartment([]);
    setSelectTheme([]);
    setSelectedUser(getDefaultState('user', lang));
    setSelectedStatus(getDefaultState('status', lang));
  };

  const handleOnClose = (type) => {
    switch (type) {
      case 'Location':
      case 'Plats':
      case 'Avdelning':
      case 'Department':
      case 'Tema':
      case 'Theme':
        setShowAddSection(false);
        setNewTagName('');
        break;
      default:
        break;
    }
  };

  const createTag = (catId) => {
    const categoryId = catId;
    if (newTagName) {
      requestsApi
        .createNewTag(orgData.encodedId, lang, orgData.id, categoryId, newTagName)
        .then((r) => {
          handleUpdateBoard();
          setNewTagName('');
        })
        .catch((e) => {
          setError(translations.savedError[lang]);
        });
    }
  };

  const handleDynamicState = (type) => {
    switch (type) {
      case 'Location':
      case 'Plats':
        return selectLocation;
      case 'Avdelning':
      case 'Department':
        return selectDepartment;
      case 'Tema':
      case 'Theme':
        return selectTheme;
      default:
        return [];
    }
  };

  const validateNewTagName = (value) => {
    setNewTagName(value);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 340,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="Video-filter-App-bar-and-checkbox">
        <AppBar position="static">
          <Typography
            variant="titleLarge"
            component="div"
            sx={{
              flexGrow: 1,
              p: 2.5,
              backgroundColor: '#2F54EB',
              color: 'white',
            }}
          >
            {translations.chooseFilters[lang]}

            <IconButton
              aria-label="clear"
              sx={{ color: 'white', pl: '118px', '&:hover': { background: 'none' } }}
              onClick={toggleDrawer('right', false)}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </AppBar>
      </div>

      <div className="dropdown-wrapper">
        <div style={{ padding: '24px 24px', paddingBottom: '35px' }}>
          {orgData?.tagCategories?.map((item, index) => {
            return (
              <div key={index}>
                <InputLabel id="demo-multiple-checkbox-label">{item.name[lang]}</InputLabel>
                <Select
                  className="select-wrap"
                  labelId="storyline-section-label"
                  multiple
                  onClose={() => {
                    handleOnClose(item.name[lang]);
                  }}
                  value={handleDynamicState(item.name[lang])}
                  displayEmpty
                  onChange={(event) => {
                    handleFilterChange(event, item.name[lang]);
                  }}
                  input={theme.outlinedInput(false)}
                  renderValue={(selected) => {
                    const renderString = selected
                      ?.filter((item) => item !== 'create_tag')
                      ?.map((st) => st.name[lang]);
                    return selected?.length > 0 ? renderString?.join(', ') : '';
                  }}
                >
                  {item.tags.map((tagD, index) => {
                    return (
                      <MenuItem key={tagD.id} value={tagD}>
                        <Checkbox
                          checked={
                            !!handleDynamicState(item.name[lang]).find((t1) => t1.id === tagD.id)
                          }
                        />

                        <SimpleListItemText primary={tagD.name[lang]} />
                        <IconButton
                          aria-label="clear"
                          size="small"
                          sx={{
                            '&:hover': { color: '#ef9a9a', backgroundColor: 'transparent' },
                          }}
                          onClick={(event) => deleteTagConfirm(event, tagD.id)}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </MenuItem>
                    );
                  })}

                  <MenuItem value="create_tag">
                    {!showAddSection && (
                      <div>
                        <Typography
                          variant="labelLarge"
                          sx={{
                            flexDirection: 'row',
                            lineHeight: '15px',
                            letterSpacing: '-0.5px',
                            marginTop: '10px',
                            marginBottom: '8px',
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="flex-start" padding="3px">
                            <i className="icon">add</i>
                            <SimpleListItemText primary={translations.addTag[lang]} />
                          </Stack>
                        </Typography>
                        <Stack spacing={1} direction="row" alignItems="flex-start" padding="3px">
                          <i className="icon">add</i>
                          <SimpleListItemText primary={translations.addTag[lang]} />
                        </Stack>
                      </div>
                    )}
                  </MenuItem>
                  <div className="add-tag-section">
                    {showAddSection && (
                      <div>
                        <Stack spacing={1} alignItems="flex-start" padding="24px" boxShadow="none">
                          <input
                            className="small"
                            type="text"
                            value={newTagName}
                            onChange={(e) => {
                              validateNewTagName(e.target.value);
                            }}
                            onClickCapture={stopImmediatePropagation}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            disableElevation
                            startIcon={<AddIcon />}
                            onClick={() => createTag(item.id)}
                          >
                            {translations.createTag[lang]}
                          </Button>
                        </Stack>
                      </div>
                    )}
                  </div>
                </Select>
              </div>
            );
          })}
        </div>
      </div>

      <Divider variant="middle" />
      <div className="status-wrapper">
        <div style={{ padding: '24px' }}>
          <InputLabel id="demo-multiple-checkbox-label">
            {translationsRequest.statuses[lang]}
          </InputLabel>
          <Select
            className="select-wrap"
            labelId="storyline-section-label"
            onClose={() => {
              // handleOnClose(item.name[lang]);
            }}
            value={selectedStatus}
            displayEmpty
            onChange={(event) => {
              handleSelectedStatus(event.target.value);
            }}
            input={theme.outlinedInput(false)}
            renderValue={(selected) => {
              return selected ? selected?.label : '';
            }}
          >
            {statusesOptions?.map((sta, index) => {
              return (
                <MenuItem key={_.uniqueId(sta.value)} value={sta}>
                  <SimpleListItemText primary={sta.label} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>

      <div className="user-wrapper">
        <div style={{ padding: '24px' }}>
          <InputLabel id="demo-multiple-checkbox-label">{translations.selectUser[lang]}</InputLabel>
          <Select
            input={theme.outlinedInput(false)}
            style={{ width: '100%', height: '40px' }}
            labelId="storyline-section-label"
            value={selectedUser}
            onChange={(e) => handleUserSelected(e.target.value)}
            renderValue={(selected) => {
              return selected ? selected?.label : '';
            }}
          >
            {userOptions?.map((item) => {
              return (
                <MenuItem value={item} key={_.uniqueId(item?.id)}>
                  <SimpleListItemText primary={item.label} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" padding="15px">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleApply()}
          disabled={!isApply}
        >
          {translations.applyButton[lang]}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => clearState()}
          disabled={!isApply}
          disableElevation
        >
          {translations.resetButton[lang]}
        </Button>
      </Stack>
    </Box>
  );

  const createNewModalVideoRequest = createNewVideoRequestIsOpen ? (
    <VideoRequest
      item={null}
      close={() => {
        setCreateNewVideoRequestIsOpen(false);
        setDuplicateRequestItem(null);
      }}
      orgData={orgData}
      setInfo={(e) => {
        setInfo(e);
      }}
      setAffected={(id) => setAffectedItem(id)}
      suggestedQuestions={suggestedQuestions}
      duplicateItem={null}
      handleUpdateBoard={(e) => handleUpdateBoard(e)}
      orgRecipients={orgRecipients}
      setVideoRequest={(item) => {
        updateVideoRequest(item);
      }}
    />
  ) : null;

  return (
    <div className="board-header">
      <div>
        <Stack direction="row" spacing={2}>
          <Typography variant="headlineMedium">
            {translationsRequest.allRequestsTitle[lang]}
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            startIcon={<AddIcon />}
            onClick={setCreateNewVideoRequestIsOpen}
          >
            {translations.newRequest[lang]}
          </Button>
        </Stack>
      </div>

      <div className="board-header-filters">
        <div
          onClick={toggleDrawer('right', true)}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            startIcon={<FilterAltOutlinedIcon />}
            onClick={toggleDrawer('right', true)}
          >
            {translations.filter[lang]}
          </Button>

          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {selectLocation?.filter(
              (item) => item !== 'create_tag' && tagsToFilter?.includes(item.id),
            )?.length > 0 && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={checkCounts(
                    selectLocation
                      ?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
                      ?.map((item) => item.name[lang]),
                  )}
                  onDelete={() => handleDeleteChip('location')}
                />
              </ListItem>
            )}

            {selectDepartment?.filter(
              (item) => item !== 'create_tag' && tagsToFilter.includes(item.id),
            ).length > 0 && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={checkCounts(
                    selectDepartment
                      ?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
                      ?.map((item) => item.name[lang]),
                  )}
                  onDelete={() => handleDeleteChip('department')}
                />
              </ListItem>
            )}

            {selectTheme?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
              .length > 0 && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={checkCounts(
                    selectTheme
                      ?.filter((item) => item !== 'create_tag' && tagsToFilter?.includes(item.id))
                      ?.map((item) => item.name[lang]),
                  )}
                  onDelete={() => handleDeleteChip('theme')}
                />
              </ListItem>
            )}

            {selectedStatus?.value !== 'ALL' && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  label={selectedStatus.label}
                  onDelete={() => handleDeleteChip('status')}
                />
              </ListItem>
            )}

            {selectedUser?.value !== 'ALL_USERS' && (
              <ListItem>
                <Chip
                  sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                  onDelete={() => handleDeleteChip('user')}
                />
              </ListItem>
            )}
          </Paper>
        </div>

        <React.Fragment key="right">
          <Drawer anchor="right" open={drawer.right} onClose={toggleDrawer('right', false)}>
            {list('right')}
          </Drawer>
        </React.Fragment>
      </div>
      {createNewModalVideoRequest}
    </div>
  );
}
