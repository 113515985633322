import DialogActions from '@mui/material/DialogActions';

export default function SimpleDialogActions(props) {
  const { sx, style } = props;
  return (
    <DialogActions
      {...props}
      sx={{
        background: '#fbfbfb',
        borderTop: '1px solid #ededee',
        padding: '8px 16px',
        ...(sx ? sx : {}),
      }}
      style={{ ...(style ? style : {}) }}
    ></DialogActions>
  );
}
