export type Quality = 'sqcif' | 'qcif' | 'ntsc' | 'nHD' | 'qHD' | 'HD' | 'FHD';

export const qualityMap: Record<Quality, Quality> = {
  sqcif: 'sqcif', // 96p
  qcif: 'qcif', // 144p
  ntsc: 'ntsc', // 240p
  nHD: 'nHD', // 360p
  qHD: 'qHD', // 540p
  HD: 'HD', // 720p
  FHD: 'FHD', // 1080p
};

// Define the quality levels in ascending order
const qualityLevels: Quality[] = ['sqcif', 'qcif', 'ntsc', 'nHD', 'qHD', 'HD', 'FHD'];

export type Question = {
  [K in Language]?: string;
};

export type ActorTitle = {
  [K in Language]?: string;
};

export type Recipient = {
  firstName: string;
  lastName: string;
  title: string;
  name: string;
};

export type Subtitle = {
  label: string;
  src: string;
  srcLang: Language;
  default: boolean;
};

export type VideoCardStatus = 'REVIEW' | 'APPROVED' | 'LIVE' | 'ARCHIVED';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface StatByTimestamp {
  timestamp: string;
  count: number;
}

export type PlaybackTimestamp = {
  impression: StatByTimestamp[];
  timePlayed: StatByTimestamp[];
  play: StatByTimestamp[];
};

export type StatsByTimestamp = {
  playback: PlaybackTimestamp;
};

export interface VideoCard {
  id: number;
  encodedId: string;
  staticThumbnails?: Partial<Record<Quality, string | null>> | null;
  animatedThumbnails?: Partial<Record<Quality, string | null>> | null;
  videoLink?: Partial<Record<Quality, string | null>>;
  question: Question;
  actorName: string;
  actorTitle: ActorTitle;
  recipient?: Recipient | null;
  subtitles?: Subtitle[] | null;
  status: VideoCardStatus;
  lastPullFromDatabase?: string;
  user?: User | null;
  stats?: StatsByTimestamp | null;
}

/**
 * Retrieves the URL of the media object based on the requested quality size.
 *
 * @param mediaObject - The partial record of quality sizes mapped to their respective URLs.
 * @param size - The requested quality size to retrieve the URL for. Defaults to 'ntsc'.
 * @returns The URL of the media object for the requested quality size, or undefined if not found.
 */
export function getMediaUrl(
  mediaObject: Partial<Record<Quality, string | null>> | undefined | null,
  size: Quality = qualityMap.ntsc,
): string | undefined {
  if (!mediaObject) {
    return undefined;
  }

  // Find the index of the requested quality
  const requestedQualityIndex = qualityLevels.indexOf(size);

  // Check from the requested quality to the best quality
  for (let i = requestedQualityIndex; i < qualityLevels.length; i += 1) {
    const quality = qualityLevels[i];
    const url = mediaObject[quality];
    if (url) {
      return url as string;
    }
  }

  // If none found, check from the requested quality to the lowest quality
  for (let i = requestedQualityIndex - 1; i >= 0; i -= 1) {
    const quality = qualityLevels[i];
    const url = mediaObject[quality];
    if (url) {
      return url as string;
    }
  }

  return undefined;
}

/**
 * Retrieves the best available static thumbnail URL for the specified quality.
 * @param videoCard - The video card containing the static thumbnails.
 * @param size - The requested quality of the thumbnail.
 * @returns The URL of the static thumbnail or undefined if not found.
 */
export function getStaticThumbnail(
  videoCard: Pick<VideoCard, 'staticThumbnails'> | null,
  size: Quality = qualityMap.ntsc,
): string | undefined {
  return getMediaUrl(videoCard?.staticThumbnails, size);
}

/**
 * Retrieves the best available animated thumbnail URL for the specified quality.
 * @param videoCard - The video card containing the animated thumbnails.
 * @param size - The requested quality of the thumbnail.
 * @returns The URL of the animated thumbnail or undefined if not found.
 */
export function getAnimatedThumbnail(
  videoCard: VideoCard,
  size: Quality = qualityMap.ntsc,
): string | undefined {
  return getMediaUrl(videoCard?.animatedThumbnails, size);
}

/**
 * Retrieves the best available video link for the specified quality.
 * @param videoCard - The video card containing the video links.
 * @param size - The requested quality of the video link.
 * @returns The URL of the video link or undefined if not found.
 */
export function getVideoLink(
  videoCard: VideoCard,
  size: Quality = qualityMap.ntsc,
): string | undefined {
  return getMediaUrl(videoCard?.videoLink, size);
}

/**
 * Retrieves the question text in the specified language from the video card.
 * Falls back to English or Swedish if the preferred language is not available.
 * @param videoCard - The video card containing the question information.
 * @param language - The preferred language for the question text.
 * @returns The question text in the specified language or a fallback language, or null if not available.
 */
export const getQuestion = (videoCard: VideoCard, language: Language): string | null => {
  return (
    videoCard?.question?.[language] || videoCard?.question?.en || videoCard?.question?.sv || null
  );
};

/**
 * Retrieves the actor's name from the video card.
 * @param videoCard - The video card containing actor information.
 * @returns The actor's name or null if not available.
 */
export const getName = (videoCard: VideoCard): string | null => {
  return videoCard?.actorName || null;
};

/**
 * Retrieves the actor's title in the specified language.
 * @param videoCard - The video card containing the actor's title.
 * @param language - The preferred language for the title.
 * @param hasName - Indicates if the actor's name is present.
 * @returns The formatted title or null if not available.
 */
export const getTitle = (
  videoCard: VideoCard,
  language: Language,
  hasName: boolean,
): string | null => {
  const actorTitle =
    videoCard?.actorTitle?.[language] ??
    videoCard?.actorTitle?.en ??
    videoCard?.actorTitle?.sv ??
    null;

  if (!actorTitle || actorTitle === '-') return null;

  return `${hasName ? ' | ' : ''}${actorTitle}`;
};
