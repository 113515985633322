import { useState } from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListSubheader from '@mui/material/ListSubheader';
import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import ConfirmPop from '../ConfirmPop';
import { translations } from '../../../utils/translations';

const deleteTag = (backOfficeClient, encodedOrgId, tagId) => {
  return backOfficeClient.mutate({
    mutation: gql`
      mutation DeleteTag($encodedOrgId: String!, $tagId: Int!) {
        deleteTag(encodedOrgId: $encodedOrgId, tagId: $tagId) {
          statusMessage
        }
      }
    `,
    variables: { encodedOrgId, tagId },
  });
};

function Tags(props) {
  const lang = useLanguage();
  const backOfficeClient = useBackOfficeApolloClient();
  const [visibilityClass, setVisibilityClass] = useState('hidden');
  const { categories, onChangeChecked, previouslySelected, orgId, encodedOrgId, adminView } = props;
  const [confirmPopData, setConfirmPopData] = useState(null);

  const deleteTagConfirm = (id) => {
    setConfirmPopData({
      title: translations.tag.deleteItem[lang],
      description: translations.tag.areYouSureDelete[lang],
      onCancel: () => setConfirmPopData(null),
      onConfirm: () => deleteTagConfirmed(id),
    });
  };
  const deleteTagConfirmed = (id) => {
    if (!id) {
      return alert('Could not remove tag. Contact support.');
    }

    deleteTag(backOfficeClient, encodedOrgId, id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        alert('Something went wrong. Contact support. ', e);
      });
  };

  const toggleVisibilityClass = () => {
    if (visibilityClass === 'hidden') {
      setVisibilityClass('visible');
    } else {
      setVisibilityClass('hidden');
    }
  };

  const cats = categories
    .filter((category) => category.tags.length > 0 || adminView)
    .map((category) => category.tags)
    .flat();

  // const tagCategories = _.map(
  //   categories.filter((category) => category.tags.length > 0 || adminView),
  //   (tagCat, key) => {
  //     return (
  //       <TagCategory
  //         adminView={adminView}
  //         category={tagCat}
  //         key={key}
  //         previouslySelected={previouslySelected}
  //         onChangeChecked={onChangeChecked}
  //         orgId={orgId}
  //         encodedOrgId={encodedOrgId}
  //         deleteTagConfirm={deleteTagConfirm}
  //         lang={lang}
  //       />
  //     );
  //   },
  // );

  const tagCategories = [];

  categories
    ?.filter((category) => category?.tags?.length > 0 || adminView)
    .forEach((tagCat) => {
      tagCategories.push(
        <ListSubheader key={_.uniqueId(tagCat?.name[lang])} value={-1}>
          {tagCat?.name[lang]}
        </ListSubheader>,
      );
      const categoryTags =
        [...tagCat.tags].sort((a, b) => a.name[lang].localeCompare(b.name[lang])) ?? tagCat?.tags;
      categoryTags.forEach((tag) =>
        tagCategories.push(
          <MenuItem key={_.uniqueId(tag?.name[lang])} value={tag?.id}>
            <Checkbox checked={_.findIndex(previouslySelected, ['id', tag?.id]) >= 0} />
            {tag?.name[lang] ?? ''}
          </MenuItem>,
        ),
      );
    });

  return (
    <div className="tag-list inner-scroll">
      <div className={`tag-list-inner ${visibilityClass}`}>
        <Select
          labelId="tags-selector"
          id="tags-selector"
          multiple
          value={[...previouslySelected.map((tag) => tag.id)]}
          displayEmpty
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            onChangeChecked(value);
          }}
          input={
            <OutlinedInput
              sx={{
                width: '100%',
                height: '40px',
                border: '0px solid #dbdbdd',
                // color: previouslySelected?.length > 0 ? '#2c2c3a' : 'rgba(128, 128, 128, 0.9)',

                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#80bdff',
                  border: '1px solid #80bdff',
                  outline: '0',
                  boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                },
              }}
            />
          }
          renderValue={(selected) => {
            const renderString = selected.map(
              (tag) => cats.find((obj) => obj.id === tag)?.name[lang],
            );
            return selected?.length > 0
              ? renderString.join(', ')
              : translations.videoCard.selectTags[lang];
          }}
          style={{ width: '100%' }}
        >
          {tagCategories}
        </Select>

        {confirmPopData ? (
          <ConfirmPop
            title={confirmPopData.title}
            description={confirmPopData.description}
            onCancel={confirmPopData.onCancel}
            onConfirm={confirmPopData.onConfirm}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Tags;
