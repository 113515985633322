import { Button } from '@mui/material';
import React from 'react';

function WhitelistedDomainsTable({ whitelistedDomains, onDelete, editable }) {
  return (
    <table className="table">
      <tbody>
        {whitelistedDomains.map((domain) => (
          <tr key={domain.id}>
            <td>{domain.domainUrl}</td>
            {editable && (
              <td>
                <Button key={domain.id} onClick={() => onDelete(domain)} color="warning">
                  <img src="/images/icons/bin-icon.svg" alt="Delete Domain for a organization" />
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default WhitelistedDomainsTable;
