const translations = {
  videoStatsTitles: {
    playback: {
      impression: {
        en: 'Impressions',
        sv: 'Visningar',
      },
      timePlayed: {
        en: 'Time played',
        sv: 'Speltid',
      },
    },
    interactions: {
      total: {
        en: 'Interactions',
        sv: 'Interaktioner',
      },
    },
  },
  videoStats: {
    en: 'Video Stats',
    sv: 'Videostatistik',
  },
  storyStats: {
    en: 'Story Stats',
    sv: 'Storystatistik',
  },
  ariaLabels: {
    playback: {
      impression: {
        en: 'Number of times the video was shown',
        sv: 'Antal gånger videon visades',
      },
      timePlayed: {
        en: 'Total time the video has been played',
        sv: 'Total tid som videon har spelats',
      },
    },
    interactions: {
      total: {
        en: 'Total number of interactions with the video',
        sv: 'Totalt antal interaktioner med videon',
      },
    },
  },
};

export default translations;
