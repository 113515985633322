import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import _, { uniqueId } from 'lodash';
import {
  Collapse,
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { freemiumDefaultPackageSettings } from '../../utils/utils';
import { translationsStoryline } from '../../utils/translationsStoryline';

function removeTypenameRecursively(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypenameRecursively(item));
  }
  if (obj !== null && typeof obj === 'object') {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      if (key !== '__typename') {
        newObj[key] = removeTypenameRecursively(obj[key]);
      }
    });
    return newObj;
  }
  return obj;
}

export default function EditPremiumFeaturesView({
  premiumFeatures,
  setPremiumFeatures,
  createNewOrg = false,
  orgData,
}) {
  const [interactionOptionBtn, setInteractionOptionBtn] = useState(
    (premiumFeatures && premiumFeatures.interactionOption) || false,
  );
  const [textMessage, setTextMessage] = useState(premiumFeatures?.textMessage || false);
  const [subtitleStatuBtn, setSubtitleStatuBtn] = useState(
    premiumFeatures?.subtitleAllowed || false,
  );
  const [subtitleIncluded, setSubtitleIncluded] = useState(
    premiumFeatures?.includeSubtitle || false,
  );

  const [editableConsentTexts, setEditableConsentTexts] = useState(
    premiumFeatures?.editableConsentTexts || false,
  );

  const [autoReminders, setAutoReminders] = useState(premiumFeatures?.autoReminders ?? false);

  const [autoRequests, setAutoRequests] = useState(premiumFeatures?.autoRequests ?? false);
  const [excludeFromVimeo, setExcludeFromVimeo] = useState(
    createNewOrg ? true : premiumFeatures?.excludeFromVimeo ?? true,
  );

  const [hideWatermark, setHideWatermark] = useState(() => {
    if (createNewOrg) {
      return false;
    }
    return typeof premiumFeatures?.hideWatermark === 'boolean'
      ? premiumFeatures?.hideWatermark
      : true;
  });

  const [advancedWidgetStyling, setAdvancedWidgetStyling] = useState(
    premiumFeatures?.advancedWidgetStyling ?? false,
  );
  const [advancedWidgetBehavior, setAdvancedWidgetBehavior] = useState(
    premiumFeatures?.advancedWidgetBehavior ?? false,
  );
  const [advStyleVidTitleDialog, setAdvStyleVidTitleDialog] = useState(
    premiumFeatures?.advStyleVidTitleDialog ?? false,
  );

  const [advStyleStories, setAdvStyleStories] = useState(premiumFeatures?.advStyleStories ?? false);

  const [intervalURLCheckbox, setIntervalURLCheckbox] = useState(
    premiumFeatures?.intervalURLUpdate > 0,
  );
  const [intervalURLUpdate, setIntervalURLUpdate] = useState(
    premiumFeatures?.intervalURLUpdate > 0 ? premiumFeatures?.intervalURLUpdate : 0,
  );

  const [requestsLibraryBeta, setRequestsLibraryBeta] = useState(
    premiumFeatures?.requestsLibraryBeta ?? false,
  );

  const [orgSubtitleUpload, setOrgSubtitleUpload] = useState(
    premiumFeatures?.orgSubtitleUpload ?? false,
  );

  const [advancedWidgetStylingMobile, setAdvancedWidgetStylingMobile] = useState(
    premiumFeatures?.advancedWidgetStylingMobile ?? false,
  );

  const [widgetThree, setWidgetThree] = useState(
    createNewOrg ? true : premiumFeatures?.widgetThree ?? false,
  );

  const [widgetVersion, setWidgetVersion] = useState(premiumFeatures?.widgetVersion);

  const [videoFunnel, setVideoFunnel] = useState(premiumFeatures?.videoFunnel ?? false);

  const [videoFunnelEndScreen, setVideoFunnelEndScreen] = useState(
    premiumFeatures?.videoFunnelEndScreen ?? false,
  );

  const [packageVariant, setPackageVariant] = useState(premiumFeatures?.packageVariant ?? 'none');

  const [freemiumFeatures, setFreemiumFeatures] = useState(
    premiumFeatures?.freemiumFeatures ?? null,
  );
  const [expandFreemiumFeatures, setExpandFreemiumFeatures] = useState(true);

  const [videoCollector, setVideoCollector] = useState(premiumFeatures?.videoCollector ?? false);

  const [organizationPermissionsSettings, setOrganizationPermissionsSettings] = useState(
    premiumFeatures?.organizationPermissionsSettings ?? false,
  );

  const [conversationalAvatar, setConversationalAvatar] = useState(
    premiumFeatures?.conversationalAvatar ?? false,
  );

  const [videoRequestEmailCustomization, setVideoRequestEmailCustomization] = useState(
    premiumFeatures?.videoRequestEmailCustomization ?? false,
  );

  const handleSetFreemium = () => {
    setInteractionOptionBtn(
      freemiumDefaultPackageSettings.interactionOption ?? premiumFeatures.interactionOption,
    );
    setTextMessage(freemiumDefaultPackageSettings.textMessage ?? premiumFeatures?.textMessage);
    setSubtitleStatuBtn(
      freemiumDefaultPackageSettings.subtitleAllowed ?? premiumFeatures?.subtitleAllowed,
    );
    setSubtitleIncluded(
      freemiumDefaultPackageSettings.includeSubtitle ?? premiumFeatures?.includeSubtitle,
    );

    setEditableConsentTexts(
      freemiumDefaultPackageSettings.editableConsentTexts ?? premiumFeatures?.editableConsentTexts,
    );

    setAutoReminders(
      freemiumDefaultPackageSettings.autoReminders ?? premiumFeatures?.autoReminders,
    );

    setAutoRequests(freemiumDefaultPackageSettings.autoRequests ?? premiumFeatures?.autoRequests);

    setExcludeFromVimeo(
      freemiumDefaultPackageSettings.excludeFromVimeo ?? premiumFeatures?.excludeFromVimeo,
    );

    setHideWatermark(
      freemiumDefaultPackageSettings.hideWatermark ?? premiumFeatures?.hideWatermark,
    );

    setAdvancedWidgetStyling(
      freemiumDefaultPackageSettings.advancedWidgetStyling ??
        premiumFeatures?.advancedWidgetStyling,
    );
    setAdvancedWidgetBehavior(
      freemiumDefaultPackageSettings.advancedWidgetBehavior ??
        premiumFeatures?.advancedWidgetBehavior,
    );

    setRequestsLibraryBeta(
      freemiumDefaultPackageSettings.requestsLibraryBeta ?? premiumFeatures?.requestsLibraryBeta,
    );

    setOrgSubtitleUpload(
      freemiumDefaultPackageSettings.orgSubtitleUpload ?? premiumFeatures?.orgSubtitleUpload,
    );

    setAdvancedWidgetStylingMobile(
      freemiumDefaultPackageSettings.advancedWidgetStylingMobile ??
        premiumFeatures?.advancedWidgetStylingMobile,
    );

    setWidgetThree(
      createNewOrg
        ? true
        : freemiumDefaultPackageSettings.widgetThree ?? premiumFeatures?.widgetThree,
    );

    setWidgetVersion(
      freemiumDefaultPackageSettings.widgetVersion ?? premiumFeatures?.widgetVersion,
    );

    setVideoFunnel(freemiumDefaultPackageSettings.videoFunnel ?? premiumFeatures?.videoFunnel);

    setVideoFunnelEndScreen(
      freemiumDefaultPackageSettings.videoFunnelEndScreen ?? premiumFeatures?.videoFunnelEndScreen,
    );

    setAdvStyleVidTitleDialog(
      freemiumDefaultPackageSettings.advStyleVidTitleDialog ??
        premiumFeatures?.advStyleVidTitleDialog,
    );

    setAdvStyleStories(
      freemiumDefaultPackageSettings.advStyleStories ?? premiumFeatures?.advStyleStories,
    );

    setVideoCollector(
      freemiumDefaultPackageSettings?.videoCollector ?? premiumFeatures?.videoCollector ?? false,
    );

    setOrganizationPermissionsSettings(
      freemiumDefaultPackageSettings?.organizationPermissionsSettings ??
        premiumFeatures?.organizationPermissionsSettings ??
        false,
    );

    setVideoRequestEmailCustomization(
      freemiumDefaultPackageSettings?.videoRequestEmailCustomization ??
        premiumFeatures?.videoRequestEmailCustomization ??
        false,
    );

    setConversationalAvatar(
      freemiumDefaultPackageSettings?.conversationalAvatar ??
        premiumFeatures?.conversationalAvatar ??
        false,
    );
  };

  useEffect(() => {
    setPremiumFeatures({
      interactionOption: interactionOptionBtn,
      textMessage,
      subtitleAllowed: subtitleStatuBtn,
      includeSubtitle: subtitleIncluded,
      editableConsentTexts,
      autoReminders,
      autoRequests,
      excludeFromVimeo: createNewOrg ? true : excludeFromVimeo,
      hideWatermark,
      advancedWidgetStyling,
      intervalURLUpdate: intervalURLCheckbox && intervalURLUpdate > 0 ? intervalURLUpdate : null,
      advancedWidgetBehavior,
      requestsLibraryBeta,
      orgSubtitleUpload,
      advancedWidgetStylingMobile,
      widgetThree,
      videoFunnel,
      videoFunnelEndScreen:
        !widgetThree || !videoFunnel || !interactionOptionBtn ? false : videoFunnelEndScreen,
      ...(widgetThree && widgetVersion && { widgetVersion }),
      ...(packageVariant && { packageVariant }),
      ...(freemiumFeatures &&
        packageVariant === 'freemium' && {
          freemiumFeatures: { ...removeTypenameRecursively(freemiumFeatures) },
        }),
      advStyleVidTitleDialog,
      advStyleStories,
      videoCollector,
      organizationPermissionsSettings,
      videoRequestEmailCustomization,
      conversationalAvatar,
    });
  }, [
    interactionOptionBtn,
    textMessage,
    subtitleStatuBtn,
    subtitleIncluded,
    editableConsentTexts,
    autoReminders,
    autoRequests,
    excludeFromVimeo,
    hideWatermark,
    advancedWidgetStyling,
    advancedWidgetBehavior,
    intervalURLCheckbox,
    intervalURLUpdate,
    requestsLibraryBeta,
    orgSubtitleUpload,
    advancedWidgetStylingMobile,
    widgetThree,
    videoFunnel,
    videoFunnelEndScreen,
    widgetVersion,
    packageVariant,
    freemiumFeatures,
    advStyleVidTitleDialog,
    advStyleStories,
    videoCollector,
    organizationPermissionsSettings,
    setPremiumFeatures,
    createNewOrg,
    videoRequestEmailCustomization,
    conversationalAvatar,
  ]);

  useEffect(() => {
    if (orgData?.premiumFeatures?.widgetVersion) {
      setWidgetVersion(orgData?.premiumFeatures?.widgetVersion);
    } else {
      setWidgetVersion('default');
    }
  }, [orgData?.premiumFeatures?.widgetVersion]);
  if (!widgetVersion) {
    return <>Loading...</>;
  }

  return (
    <>
      <h4>Premium Features:</h4>
      <div className="create-new-page-inner">
        <label>
          <input
            type="checkbox"
            checked={interactionOptionBtn}
            onChange={() => setInteractionOptionBtn(!interactionOptionBtn)}
          />
          LeadGen
        </label>
        <label>
          <input
            type="checkbox"
            checked={textMessage}
            onChange={() => setTextMessage(!textMessage)}
          />
          Send Text Message(SMS) Reminders
        </label>

        <label>
          <input
            type="checkbox"
            checked={editableConsentTexts}
            onChange={() => setEditableConsentTexts(!editableConsentTexts)}
          />
          Custom consent texts for video upload
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            By activating this mode, the organization will get access to edit boxes on the
            &quot;Settings&quot; page.
          </p>
        </label>

        <label>
          <input
            type="checkbox"
            checked={autoReminders}
            onChange={() => setAutoReminders(!autoReminders)}
          />
          Automatically send reminders
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            By activating this mode, will allow the organization to send out reminders automatically
            on given interval for manually sent requests.
          </p>
        </label>
        <label>
          <input
            type="checkbox"
            checked={autoRequests}
            onChange={() => setAutoRequests(!autoRequests)}
          />
          Automatically send request
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            By activating this mode, will allow the organization to setup to send out requests
            automatically on given interval.
          </p>
        </label>

        <label>
          <input
            type="checkbox"
            checked={excludeFromVimeo}
            onChange={() => {
              if (excludeFromVimeo) {
                setRequestsLibraryBeta(false);
              }
              setExcludeFromVimeo(!excludeFromVimeo);
            }}
            disabled={createNewOrg || premiumFeatures?.excludeFromVimeo}
          />
          Only upload videos to AWS
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            By activating this mode, will allow the organization to upload videos to AWS only.
            <br />
            Videos will <b>NOT</b> be uploaded to Vimeo.
          </p>
        </label>

        <label>
          <input
            type="checkbox"
            checked={hideWatermark}
            onChange={() => setHideWatermark(!hideWatermark)}
          />
          Hide Watermark on all Videos and images
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            This feature allows you to remove the Life Inside Logo watermark from all videos,
            animated thumbnails, and static thumbnail images. By activating this feature, the
            branding overlay will no longer appear in the top left corner of the content.
            <br />
            Please note that this will only apply to videos uploaded to AWS, and not to Vimeo
            videos.
          </p>
        </label>

        <h5>Styling:</h5>
        <label>
          <input
            type="checkbox"
            checked={advancedWidgetStyling}
            onChange={() => setAdvancedWidgetStyling(!advancedWidgetStyling)}
          />
          Advanced Widget Styling
        </label>
        <label>
          <input
            type="checkbox"
            checked={advancedWidgetStylingMobile}
            onChange={() => setAdvancedWidgetStylingMobile(!advancedWidgetStylingMobile)}
          />
          Advanced Widget Styling Mobile
        </label>

        <label>
          <input
            type="checkbox"
            checked={advancedWidgetBehavior}
            onChange={() => setAdvancedWidgetBehavior(!advancedWidgetBehavior)}
          />
          Advanced Widget Behavior
        </label>

        <label>
          <input
            type="checkbox"
            checked={intervalURLCheckbox}
            onChange={() => {
              if (!intervalURLCheckbox) {
                setIntervalURLUpdate(1000);
              } else {
                setIntervalURLUpdate(0);
              }
              setIntervalURLCheckbox(!intervalURLCheckbox);
            }}
          />
          Widget Intervall check URL changes
          <input
            type="number"
            value={intervalURLUpdate}
            onChange={(e) => {
              if (e.target.value <= 0) {
                setIntervalURLUpdate(0);
                setIntervalURLCheckbox(false);
              }
              setIntervalURLUpdate(Number(e.target.value));
            }}
            disabled={!intervalURLCheckbox}
            min={0}
          />
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            In rare cases, the widget will not update the URL when the user navigates to a new page.
            Activate this to force the widget to check for URL changes every X <b>milliseconds</b> .
          </p>
        </label>

        <label htmlFor="advStyleVidTitleDialog">
          <input
            id="advStyleVidTitleDialog"
            name="advStyleVidTitleDialog"
            type="checkbox"
            checked={advStyleVidTitleDialog}
            onChange={() => setAdvStyleVidTitleDialog(!advStyleVidTitleDialog)}
          />
          Advanced Widget Styling Video Title Dialog
        </label>
        {/* 
        <label htmlFor="advStyleStories">
          <input
            id="advStyleStories"
            name="advStyleStories"
            type="checkbox"
            checked={advStyleStories}
            onChange={() => setAdvStyleStories(!advStyleStories)}
          />
          Advanced Widget Styling Stories
        </label> */}
        <h5>Subtitles:</h5>
        <label>
          <input
            type="checkbox"
            checked={subtitleStatuBtn}
            onChange={() => {
              if (!subtitleStatuBtn) {
                setOrgSubtitleUpload(true);
              }
              setSubtitleStatuBtn(!subtitleStatuBtn);
            }}
          />
          Allowing Subtitle Request
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            By activating this mode, the organization will be able to order subtitles on individual
            VideoCards from the backOffice.
          </p>
        </label>
        <label>
          <input
            type="checkbox"
            checked={subtitleIncluded}
            onChange={() => {
              if (!subtitleIncluded) {
                setOrgSubtitleUpload(true);
              }
              setSubtitleIncluded(!subtitleIncluded);
            }}
          />
          Auto order subtitles on all new Video Uploads
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            By activating this mode, the organization will automatically order subtitles on all new
            Video Uploads.
          </p>
        </label>
        <label>
          <input
            type="checkbox"
            disabled={subtitleIncluded || subtitleStatuBtn}
            checked={orgSubtitleUpload || subtitleIncluded || subtitleStatuBtn}
            onChange={() => setOrgSubtitleUpload(!orgSubtitleUpload)}
          />
          Allow Organizations to upload and download subtitles
          <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
            With this active the organization can manage the video&apos;s subtitles. They be able to
            upload, download, and edit their own subtitles directly from the backoffice.
          </p>
        </label>
      </div>

      <label>
        <input
          type="checkbox"
          checked={videoFunnel}
          onChange={() => setVideoFunnel(!videoFunnel)}
          disabled={!widgetThree}
        />
        Video Funnel
        <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
          With Video Funnel, users can easily create compelling video funnel using an intuitive
          drag-and-drop interface. You can create flow charts of your story, complete with video
          cards that you can drag and drop into the flow and connect with nodes. This makes it easy
          to create dynamic, interactive stories that are engaging and visually appealing. Whether
          you&apos;re a content creator or just looking to tell your own story in a creative way,
          Video Funnel is the perfect tool for bringing your ideas to life!
        </p>
      </label>
      <label>
        <input
          type="checkbox"
          checked={videoFunnelEndScreen}
          onChange={() => setVideoFunnelEndScreen(!videoFunnelEndScreen)}
          disabled={!widgetThree || !videoFunnel || !interactionOptionBtn}
        />
        Video Funnel End Screen
        <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
          With Video Funnel End Screens
        </p>
      </label>

      <label>
        <input
          type="checkbox"
          checked={widgetThree}
          onChange={() => {
            if (widgetThree) {
              setVideoFunnel(false);
            }
            setWidgetThree(!widgetThree);
          }}
          disabled={createNewOrg || premiumFeatures?.widgetThree}
        />
        Widget 3.0.0
      </label>

      <label>
        Enter Custom Version
        <input
          type="text"
          value={widgetVersion}
          onChange={(e) => {
            setWidgetVersion(e.target.value);
          }}
          disabled={!premiumFeatures?.widgetThree}
        />
      </label>

      <h4>Package</h4>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="packageSelect">
        <select
          id="packageSelect"
          value={packageVariant}
          onChange={(e) => {
            setPackageVariant(e.target.value);
            if (e.target.value === 'freemium') {
              setPremiumFeatures(freemiumDefaultPackageSettings);
              setFreemiumFeatures(freemiumDefaultPackageSettings.freemiumFeatures);
              handleSetFreemium();
            } else {
              setFreemiumFeatures(freemiumDefaultPackageSettings.freemiumFeatures);
            }
          }}
        >
          <option value="none">none</option>
          <option value="freemium">Freemium</option>
        </select>
      </label>

      <Collapse in={packageVariant === 'freemium'}>
        <Box
          maxWidth="sm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            background: 'pink',
            borderRadius: '16px',
            p: 2,
          }}
        >
          <h4>
            Freemium Features (Limits):
            <Button
              sx={{ textTransform: 'none' }}
              disableRipple
              onClick={() => setExpandFreemiumFeatures(!expandFreemiumFeatures)}
            >
              {expandFreemiumFeatures ? 'Collapse' : 'Expand'}
            </Button>
          </h4>
          <Collapse in={expandFreemiumFeatures}>
            {Object.keys(freemiumDefaultPackageSettings.freemiumFeatures).map((featureKey) => (
              <Box
                key={uniqueId()}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  background: 'pink',
                  borderRadius: '16px',
                  p: 2,
                }}
              >
                <Typography variant="caption" key={uniqueId()}>
                  {featureKey}:
                </Typography>
                {Object.keys(freemiumDefaultPackageSettings.freemiumFeatures[featureKey]).map(
                  (key) =>
                    key === 'conditions' ? (
                      <Select
                        key={uniqueId()}
                        value={
                          freemiumFeatures?.[featureKey]?.[key]?.length > 0
                            ? freemiumFeatures?.[featureKey]?.[key]
                            : freemiumDefaultPackageSettings.freemiumFeatures[featureKey][key]
                        }
                        multiple
                        onChange={(e) => {
                          setFreemiumFeatures({
                            ...freemiumFeatures,
                            [featureKey]: {
                              ...freemiumFeatures[featureKey],
                              [key]: e.target.value,
                            },
                          });
                        }}
                      >
                        {Object.keys(translationsStoryline.availableWhenConditions)?.map(
                          (option) => (
                            <MenuItem
                              value={option}
                              option={_.uniqueId(option)}
                              title={option}
                              key={uniqueId()}
                            >
                              {option}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    ) : (
                      <TextField
                        key={uniqueId()}
                        label={key}
                        value={
                          !Number.isNaN(freemiumFeatures?.[featureKey]?.[key]) &&
                          Number.isInteger(freemiumFeatures?.[featureKey]?.[key]) &&
                          Number(freemiumFeatures?.[featureKey]?.[key]) >= 0
                            ? freemiumFeatures?.[featureKey]?.[key]
                            : freemiumDefaultPackageSettings.freemiumFeatures[featureKey][key]
                        }
                        type="number"
                        onChange={(e) => {
                          setFreemiumFeatures({
                            ...freemiumFeatures,
                            [featureKey]: {
                              ...freemiumFeatures[featureKey],
                              [key]: Number(e.target.value),
                            },
                          });
                        }}
                      />
                    ),
                )}
                <Divider key={uniqueId()} />
              </Box>
            ))}
          </Collapse>
        </Box>
      </Collapse>

      <Typography variant="titleMedium" sx={{ pt: 3 }}>
        Video Request e-mail customization
      </Typography>
      <label htmlFor="video-request-email-customization">
        <Checkbox
          id="video-request-email-customization"
          name="video-request-email-customization"
          type="checkbox"
          size="small"
          checked={videoRequestEmailCustomization}
          onChange={() => setVideoRequestEmailCustomization(!videoRequestEmailCustomization)}
        />
        <Typography variant="bodyMedium">Customization of the video request e-mail</Typography>
      </label>

      <Typography variant="titleMedium" sx={{ pt: 3 }}>
        Video Collector
      </Typography>
      <label htmlFor="videoCollector">
        <input
          name="videoCollector"
          type="checkbox"
          checked={videoCollector}
          onChange={() => setVideoCollector(!videoCollector)}
        />
        Video Collector
      </label>

      <h4>Beta Features:</h4>
      <div className="create-new-page-inner">
        <label htmlFor="conversationalAvatar">
          <input
            name="conversationalAvatar"
            type="checkbox"
            checked={conversationalAvatar}
            onChange={() => setConversationalAvatar(!conversationalAvatar)}
          />
          Conversational Avatar Beta
        </label>

        <label>
          <input
            type="checkbox"
            disabled={!excludeFromVimeo}
            checked={requestsLibraryBeta}
            onChange={() => setRequestsLibraryBeta(!requestsLibraryBeta && excludeFromVimeo)}
          />
          Requests & Library Beta
          {!excludeFromVimeo && (
            <p style={{ paddingLeft: '30px', marginTop: '0px', fontSize: '12px' }}>
              Needs <b>Only upload videos to AWS</b> to be activated.
            </p>
          )}
        </label>
      </div>
      <Typography variant="titleMedium" sx={{ pt: 3 }}>
        Organization permissions settings
      </Typography>
      <label htmlFor="organizationPermissionsSettings">
        <input
          name="organizationPermissionsSettings"
          type="checkbox"
          checked={organizationPermissionsSettings}
          onChange={() => setOrganizationPermissionsSettings(!organizationPermissionsSettings)}
        />
        Organization permissions
      </label>
    </>
  );
}
