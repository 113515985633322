import React from 'react';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';

interface ProgressBarProps {
  playFullVideo: boolean;
  progress: number;
  mediaRef: React.RefObject<HTMLVideoElement>;
  onProgressBarClick: (newTime: number) => void;
  isMobileDevice?: boolean;
  borderRadius?: string | number;
}

function ProgressBar({
  playFullVideo,
  progress,
  mediaRef,
  onProgressBarClick,
  isMobileDevice = false,
  borderRadius = 0,
}: ProgressBarProps) {
  const handleProgressBarClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!mediaRef.current) return;

    // Check if duration is a finite number
    const { duration } = mediaRef.current;
    if (!Number.isFinite(duration)) return;

    const boundingRect = event.currentTarget.getBoundingClientRect();
    const clickedPosition = event.clientX - boundingRect.left;
    const totalWidth = boundingRect.width;

    // Ensure totalWidth is not zero to prevent division by zero
    if (totalWidth === 0) return;

    const clickedRatio = clickedPosition / totalWidth;
    const newTime = clickedRatio * duration;
    onProgressBarClick(newTime);
  };

  const height = Math.max(5, typeof borderRadius === 'number' ? borderRadius : 5);

  const StyledLinearProgress = styled(LinearProgress)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    minHeight: `${height}px`,
    '&:hover': {
      height: isMobileDevice ? '0.5rem' : '1rem',
    },
    cursor: 'pointer',
    zIndex: 1,
    borderTopLeftRadius: typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
    borderTopRightRadius: typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
  });

  return (
    <Collapse in={playFullVideo} sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <StyledLinearProgress
        variant="determinate"
        value={progress}
        onClick={handleProgressBarClick}
        role="progressbar"
      />
    </Collapse>
  );
}

export default ProgressBar;
