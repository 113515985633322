import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';

function VideoStorySettingsComponent({
  anchorRef = null,
  openMenu,
  handleToggle,
  handleClose,
  handleDuplicateItemClick,
  videoCard = null,
  deleteItemConfirm,
}) {
  const lang = useLanguage();

  return (
    <>
      <IconButton
        aria-label={translations.videoCardSettings[lang]}
        ref={anchorRef}
        id="composition-button"
        aria-controls={openMenu ? 'composition-menu' : undefined}
        aria-expanded={openMenu ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          handleToggle();
        }}
        sx={{ p: 0 }}
        disableFocusRipple
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={openMenu}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDuplicateItemClick(videoCard);
                      handleClose(e);
                    }}
                  >
                    {translations.videoCard.duplicateItem[lang]}
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      deleteItemConfirm();
                      e.stopPropagation();
                      handleClose(e);
                    }}
                  >
                    {translations.videoCard.deleteItem[lang]}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

VideoStorySettingsComponent.propTypes = {
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  openMenu: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDuplicateItemClick: PropTypes.func.isRequired,
  videoCard: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  deleteItemConfirm: PropTypes.func.isRequired,
};

function VideoStorySettings({ shouldWrap = false, ...props }) {
  if (shouldWrap) {
    return (
      <div className="video-card-settings">
        <VideoStorySettingsComponent {...props} />
      </div>
    );
  }
  return <VideoStorySettingsComponent {...props} />;
}

VideoStorySettings.propTypes = {
  shouldWrap: PropTypes.bool,
};

export default VideoStorySettings;
