import { gql } from '@apollo/client';

export const CREATE_SESSION_MUTATION = gql`
  mutation CreateSession(
    $quality: String
    $avatarName: String
    $voiceId: String
    $assistantId: String
  ) {
    createHeyGenSession(
      quality: $quality
      avatarName: $avatarName
      voiceId: $voiceId
      assistantId: $assistantId
    ) {
      code
      data {
        ice_servers2 {
          urls
          username
          credential
        }
        sdp {
          sdp
          type
        }
        session_id
      }
      message
      openAiThreadId
      userSessionId
    }
  }
`;

export const CREATE_SESSION_ORG_MUTATION = gql`
  mutation CreateSession(
    $encodedOrgId: String!
    $quality: String
    $avatarName: String
    $voiceId: String
    $assistantId: String
  ) {
    createHeyGenSession(
      encodedOrgId: $encodedOrgId
      quality: $quality
      avatarName: $avatarName
      voiceId: $voiceId
      assistantId: $assistantId
    ) {
      code
      data {
        ice_servers2 {
          urls
          username
          credential
        }
        sdp {
          sdp
          type
        }
        session_id
      }
      message
      openAiThreadId
      userSessionId
    }
  }
`;

export const START_SESSION_MUTATION = gql`
  mutation StartSession($sessionId: String!, $sdp: SDPInput!, $userSessionId: String!) {
    startHeyGenSession(sessionId: $sessionId, sdp: $sdp, userSessionId: $userSessionId) {
      status
      textResponse
      userSessionId
    }
  }
`;

export const START_SESSION_ORG_MUTATION = gql`
  mutation StartSession(
    $encodedOrgId: String!
    $sessionId: String!
    $sdp: SDPInput!
    $userSessionId: String!
  ) {
    startHeyGenSession(
      encodedOrgId: $encodedOrgId
      sessionId: $sessionId
      sdp: $sdp
      userSessionId: $userSessionId
    ) {
      status
      textResponse
      userSessionId
    }
  }
`;

export const SUBMIT_ICE_CANDIDATE_MUTATION = gql`
  mutation SubmitIceCandidate(
    $sessionId: String!
    $candidate: RTCIceCandidateInput!
    $userSessionId: String!
  ) {
    submitHeyGenIceCandidate(
      sessionId: $sessionId
      candidate: $candidate
      userSessionId: $userSessionId
    ) {
      status
      userSessionId
    }
  }
`;

export const SUBMIT_ICE_CANDIDATE_ORG_MUTATION = gql`
  mutation SubmitIceCandidate(
    $encodedOrgId: String!
    $sessionId: String!
    $candidate: RTCIceCandidateInput!
    $userSessionId: String!
  ) {
    submitHeyGenIceCandidate(
      encodedOrgId: $encodedOrgId
      sessionId: $sessionId
      candidate: $candidate
      userSessionId: $userSessionId
    ) {
      status
      userSessionId
    }
  }
`;

export const REPEAT_TEXT_MUTATION = gql`
  mutation RepeatHeyGenText($sessionId: String!, $text: String!, $userSessionId: String!) {
    repeatHeyGenText(sessionId: $sessionId, text: $text, userSessionId: $userSessionId) {
      userSessionId
    }
  }
`;

export const REPEAT_TEXT_ORG_MUTATION = gql`
  mutation RepeatHeyGenText(
    $sessionId: String!
    $text: String!
    $userSessionId: String!
    $encodedOrgId: String!
  ) {
    repeatHeyGenText(
      sessionId: $sessionId
      text: $text
      userSessionId: $userSessionId
      encodedOrgId: $encodedOrgId
    ) {
      userSessionId
    }
  }
`;

export const TEXT_TO_VIDEO_MUTATION = gql`
  mutation TextToVideoHeyGen(
    $sessionId: String
    $text: String!
    $openAiThreadId: String
    $role: Role
    $userSessionId: String
    $assistantId: String
  ) {
    textToVideoHeyGen(
      sessionId: $sessionId
      text: $text
      openAiThreadId: $openAiThreadId
      role: $role
      userSessionId: $userSessionId
      assistantId: $assistantId
    ) {
      status
      textResponse
      openAiThreadId
      userSessionId
    }
  }
`;

export const TEXT_TO_VIDEO_ORG_MUTATION = gql`
  mutation TextToVideoHeyGen(
    $encodedOrgId: String!
    $sessionId: String
    $text: String!
    $openAiThreadId: String
    $role: Role
    $userSessionId: String
    $assistantId: String
  ) {
    textToVideoHeyGen(
      encodedOrgId: $encodedOrgId
      sessionId: $sessionId
      text: $text
      openAiThreadId: $openAiThreadId
      role: $role
      userSessionId: $userSessionId
      assistantId: $assistantId
    ) {
      status
      textResponse
      openAiThreadId
      userSessionId
    }
  }
`;

export const CLOSE_SESSION_MUTATION = gql`
  mutation CloseSession($sessionId: String!, $userSessionId: String!) {
    closeHeyGenSession(sessionId: $sessionId, userSessionId: $userSessionId) {
      status
    }
  }
`;

export const CLOSE_SESSION_ORG_MUTATION = gql`
  mutation CloseSession($encodedOrgId: String!, $sessionId: String!, $userSessionId: String!) {
    closeHeyGenSession(
      encodedOrgId: $encodedOrgId
      sessionId: $sessionId
      userSessionId: $userSessionId
    ) {
      status
    }
  }
`;

export const SAVE_SETTINGS_MUTATION = gql`
  mutation SaveSettings(
    $avatarName: String!
    $avatarVoiceId: String!
    $assistantId: String!
    $avatarPosterUrl: String!
  ) {
    saveConversationalAvatarSettings(
      avatarName: $avatarName
      avatarVoiceId: $avatarVoiceId
      assistantId: $assistantId
      avatarPosterUrl: $avatarPosterUrl
    ) {
      avatarName
      avatarVoiceId
      assistantId
      avatarPosterUrl
    }
  }
`;

export const SAVE_SETTINGS_ORG_MUTATION = gql`
  mutation SaveSettings(
    $encodedOrgId: String!
    $avatarName: String!
    $avatarVoiceId: String!
    $assistantId: String!
    $avatarPosterUrl: String!
  ) {
    saveConversationalAvatarSettings(
      encodedOrgId: $encodedOrgId
      avatarName: $avatarName
      avatarVoiceId: $avatarVoiceId
      assistantId: $assistantId
      avatarPosterUrl: $avatarPosterUrl
    ) {
      avatarName
      avatarVoiceId
      assistantId
      avatarPosterUrl
    }
  }
`;
