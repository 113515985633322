import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Collapse,
  Button,
  Fab,
  Snackbar,
  Grid,
  Container,
} from '@mui/material';
import { FileCopyOutlined as FileCopyOutlinedIcon } from '@mui/icons-material';

import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { frontendPath } from '../../../utils/environment';
import { translations } from '../../../utils/translationsSettings';
import { Context } from './types';
import useCheckPermissionsAndNavigate from './Permissions/CheckPermissionsAndNavigate';

const devMode = process.env.NODE_ENV !== 'production';

export default function WidgetScript() {
  const lang = useLanguage();
  const { basicOrgData } = useOutletContext<Context>();

  const [useAsyncScript, setUseAsyncScript] = useState(true);
  const [useDeferScript, setUseDeferScript] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showAsyncInfo, setShowAsyncInfo] = useState(false);
  const [showDeferInfo, setShowDeferInfo] = useState(false);

  const [copySuccess, setCopySuccess] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  let filePostFix = '';

  if (devMode && frontendPath?.includes('localhost')) {
    filePostFix = '-dev';
  } else if (frontendPath?.includes('app.staging.lifeinside.io')) {
    filePostFix = '-stage';
  }

  const getScriptValue = () => {
    if (!useAsyncScript && !useDeferScript) {
      return (
        `<script src='${frontendPath}/widget.1.0.0${filePostFix}.js'></script>\n` +
        `<script>` +
        `(function () {\n` +
        `  var settings = {\n` +
        `   id: "${basicOrgData.encodedId}",\n` +
        `  };\n` +
        `  WidgetWrapper.mount(settings);\n` +
        `}())\n` +
        `</script>`
      );
    }
    return (
      `<script>` +
      ` function runMountLifeInsideWidget() {\n` +
      `  var settings = {\n` +
      `   id: "${basicOrgData.encodedId}",\n` +
      `  };\n` +
      `  WidgetWrapper.mount(settings);\n` +
      `}\n` +
      `</script>\n` +
      `<script src='${frontendPath}/widget.1.0.0${filePostFix}.js'${
        useDeferScript ? ' defer="true"' : ''
      }${useAsyncScript ? ' async="true"' : ''} onload="runMountLifeInsideWidget()">\n` +
      `</script>\n`
    );
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(getScriptValue())
      .then(() => {
        setCopySuccess(true);
        setIsCopied(true);
      })
      .catch(() => {
        setCopySuccess(false);
        setIsCopied(true);
      });
  };

  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  if (userLoading) return <LoadingIndicator />;

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Container maxWidth="md">
          <Box className="inner">
            <Typography variant="titleLarge" component="h3">
              {translations.generalSettings.widgetScript[lang]}
            </Typography>
            <Typography variant="bodyMedium">
              {translations.generalSettings.widgetScriptInfo[lang]}
            </Typography>
            <FormControlLabel
              sx={{ marginTop: '16px' }}
              style={{ marginBottom: '0px' }}
              control={
                <Checkbox
                  id="use-async"
                  checked={useAsyncScript}
                  onChange={() => {
                    setUseAsyncScript(!useAsyncScript);
                    setShowMessage(true);
                  }}
                />
              }
              label={translations.generalSettings.script.async[lang]}
            />
            <Typography variant="bodyMedium">
              {translations.generalSettings.script.asyncShortDescription[lang]}
              {!showAsyncInfo && (
                <Button
                  type="button"
                  variant="text"
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '300',

                    padding: '4px',
                    marginBottom: '8px',
                    marginLeft: '0px',
                    height: '24px',
                  }}
                  onClick={() => {
                    setShowAsyncInfo(!showAsyncInfo);
                  }}
                >
                  {showAsyncInfo
                    ? translations.generalSettings.script.lessInfo[lang]
                    : translations.generalSettings.script.moreInfo[lang]}
                </Button>
              )}
              <Collapse in={showAsyncInfo}>
                <br />
                {translations.generalSettings.script.asyncDescription[lang]}
                <Button
                  type="button"
                  variant="text"
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '300',
                    padding: '4px',
                    marginBottom: '8px',
                    marginLeft: '0px',
                    height: '24px',
                  }}
                  onClick={() => {
                    setShowAsyncInfo(!showAsyncInfo);
                  }}
                >
                  {showAsyncInfo
                    ? translations.generalSettings.script.lessInfo[lang]
                    : translations.generalSettings.script.moreInfo[lang]}
                </Button>
              </Collapse>
            </Typography>
            <FormControlLabel
              sx={{ marginTop: '8px' }}
              style={{ marginBottom: '0px' }}
              control={
                <Checkbox
                  id="use-defer"
                  checked={useDeferScript}
                  onChange={() => {
                    setUseDeferScript(!useDeferScript);
                    setShowMessage(true);
                  }}
                />
              }
              label={translations.generalSettings.script.defer[lang]}
            />
            <Typography variant="bodyMedium">
              {translations.generalSettings.script.deferShortDescription[lang]}
              {!showDeferInfo && (
                <Button
                  type="button"
                  variant="text"
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '300',
                    padding: '4px',
                    marginBottom: '8px',
                    marginLeft: '0px',
                    height: '24px',
                  }}
                  onClick={() => {
                    setShowDeferInfo(!showDeferInfo);
                  }}
                >
                  {showDeferInfo
                    ? translations.generalSettings.script.lessInfo[lang]
                    : translations.generalSettings.script.moreInfo[lang]}
                </Button>
              )}
              <Collapse in={showDeferInfo}>
                <br />
                {translations.generalSettings.script.deferDescription[lang]}
                <Button
                  type="button"
                  variant="text"
                  disableElevation
                  disableRipple
                  disableTouchRipple
                  sx={{
                    textTransform: 'none',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '300',
                    marginLeft: '0px',
                    height: '24px',
                    padding: '4px',
                    marginBottom: '8px',
                  }}
                  onClick={() => {
                    setShowDeferInfo(!showDeferInfo);
                  }}
                >
                  {showDeferInfo
                    ? translations.generalSettings.script.lessInfo[lang]
                    : translations.generalSettings.script.moreInfo[lang]}
                </Button>
              </Collapse>
            </Typography>

            <br />
            <Box
              style={{
                position: 'relative',
              }}
            >
              <Box
                style={{
                  minHeight: 200,
                  backgroundColor: '#fbf7f1',
                  border: '1px solid #ccc',
                  padding: '8px',
                  transition: 'height 0.3s ease',
                }}
              >
                <Box
                  style={{
                    fontFamily: 'monospace',
                    backgroundColor: '#fbf7f1',
                    fontSize: 12,
                    margin: '0px',
                    marginBottom: '8px',
                    overflowY: 'auto',
                    marginRight: '64px',
                    lineHeight: '16px',
                    whiteSpace: 'pre-wrap',
                    transition: 'height 0.3s ease',
                  }}
                >
                  {getScriptValue()}
                </Box>
                <Fab
                  color="primary"
                  id="copy-to-clipboard"
                  name="copy-to-clipboard"
                  aria-label={translations.generalSettings.script.copyToClipboardFab[lang]}
                  style={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                  }}
                  onClick={handleCopyClick}
                  size="small"
                >
                  <FileCopyOutlinedIcon />
                </Fab>
              </Box>

              <Snackbar
                open={isCopied}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={
                  copySuccess
                    ? translations.generalSettings.script.copiedToClipboard[lang]
                    : translations.generalSettings.script.copyFailed[lang]
                }
              />
            </Box>
            <Collapse in={showMessage}>
              <Typography
                variant="bodyMedium"
                component="p"
                className="attention-text"
                style={{
                  marginLeft: '0px',
                  fontSize: '14px',
                  color: 'red',
                }}
              >
                {translations.generalSettings.script.attentionReImplementation[lang]}
              </Typography>
            </Collapse>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
}
