import { uniqueId } from 'lodash';

const translationsSignup = {
  succesScreenTitle: {
    en: 'Account Activation Successful',
    sv: 'Kontoverifiering Lyckades',
  },
  successScreenMessage: {
    en: `Congratulations! You've successfully activated your account at Life Inside`,
    sv: 'Grattis! Du har aktiverat ditt konto på Life Inside',
  },
  emailSentMessage: {
    en: 'An email with a reset password link has been sent out. To continue and login, follow the link in the email.',
    sv: 'Ett e-postmeddelande med en länk för att återställa lösenordet har skickats. För att fortsätta och logga in, följ länken i e-postmeddelandet.',
  },
  internalerror: {
    en: [
      'Internal error, please try again later or contact support at ',
      <a key={uniqueId()} href="mailto:support@lifeinside.io">
        support@lifeinside.io
      </a>,
    ],
    sv: [
      'Internt fel, försök igen senare eller kontakta supporten på ',
      <a key={uniqueId()} href="mailto:support@lifeinside.io">
        support@lifeinside.io
      </a>,
    ],
  },
  internalErrorCode: {
    1: {
      en: [
        'Looks like your organisation already exist, contact your organisation or contact our support at ',
        <a key={uniqueId()} href="mailto:support@lifeinside.io">
          support@lifeinside.io
        </a>,
        '.',
      ],
      sv: [
        'Det verkar som att din organisation redan finns, kontakta din organisation eller kontakta vår support på ',
        <a key={uniqueId()} href="mailto:support@lifeinside.io">
          support@lifeinside.io
        </a>,
        '.',
      ],
    },
    2: {
      en: [
        'Looks like your organisation already exist, contact your organisation or contact our support at ',
        <a key={uniqueId()} href="mailto:support@lifeinside.io">
          support@lifeinside.io
        </a>,
        '.',
      ],
      sv: [
        'Det verkar som att din organisation redan finns, kontakta din organisation eller kontakta vår support på ',
        <a key={uniqueId()} href="mailto:support@lifeinside.io">
          support@lifeinside.io
        </a>,
        '.',
      ],
    },
  },
  errorWrongVerificationCode: {
    en: 'Wrong verification code',
    sv: 'Fel verifikationskod',
  },
  signUp: {
    en: 'Sign up for',
    sv: 'Registrera dig för',
  },
  signUpLifeInside: {
    en: 'Life Inside',
    sv: 'Life Inside',
  },
  preSubHeaderMobile: {
    en: 'Set up your account on a desktop for the best experience.',
    sv: 'Skapa ditt konto på en dator för bästa upplevelsen.',
  },
  subHeader: {
    en: 'Create a free account or',
    sv: 'Skapa ett gratis konto eller',
  },
  signIn: {
    en: 'Sign In',
    sv: 'Logga in',
  },
  firstName: {
    en: 'First Name',
    sv: 'Förnamn',
  },
  lastName: {
    en: 'Last Name',
    sv: 'Efternamn',
  },
  phoneNumber: {
    en: 'Phone Number',
    sv: 'Telefonnummer',
  },
  email: {
    en: 'Email',
    sv: 'Email',
  },
  orgName: {
    en: 'Organisation Name',
    sv: 'Organisationsnamn',
  },
  domainTitle: {
    en: 'Domain',
    sv: 'Domän',
  },
  domain: {
    en: 'Domain, e.g. https://www.mycompany.com',
    sv: 'Domän, t.ex. https://www.mittföretag.se',
  },
  domainHelperText: {
    en: 'Please enter a valid domain',
    sv: 'Vänligen ange en giltig domän',
  },
  orgLang: {
    en: 'Organisation Language',
    sv: 'Organisationsspråk',
  },
  systemLanguage: {
    en: 'System Language',
    sv: 'Systemspråk',
  },
  approveConditions: {
    byClicking: {
      en: 'By clicking the',
      sv: 'Genom att klicka på',
    },
    youAgree: {
      en: "you agree to Life Inside's ",
      sv: 'godkänner du Life Insides ',
    },
    termsOfService: {
      en: 'Terms of Service',
      sv: 'Användarvillkor',
    },
    and: {
      en: "and acknowledge that Life Inside's",
      sv: 'och accepterar  Life Insides',
    },
    privacyPolicy: {
      en: 'Privacy Policy',
      sv: 'Integritetspolicy',
    },
    appliesToYou: {
      en: ' applies.',
      sv: '.',
    },
  },
  password: {
    en: 'Password',
    sv: 'Lösenord',
    condition: {
      minLength: {
        en: (value) => (value > 1 ? `${value} characters` : `${value} character`),
        sv: (value) => (value > 1 ? `${value} tecken` : `${value} tecken`),
      },
      minLowercase: {
        en: (value) =>
          value > 1 ? `${value} lowercase characters` : `${value} lowercase character`,
        sv: (value) => (value > 1 ? `${value} små bokstäver` : `${value} liten bokstav`),
      },
      minUppercase: {
        en: (value) =>
          value > 1 ? `${value} uppercase characters` : `${value} uppercase character`,
        sv: (value) => (value > 1 ? `${value} stora bokstäver` : `${value} stor bokstav`),
      },

      minNumbers: {
        en: (value) => (value > 1 ? `${value} numbers` : `${value} number`),
        sv: (value) => (value > 1 ? `${value} siffror` : `${value} siffra`),
      },
      minSymbols: {
        en: (value) => (value > 1 ? `${value} symbols` : `${value} symbol`),
        sv: (value) => (value > 1 ? `${value} symboler` : `${value} symbol`),
      },
    },
  },
  next: {
    en: 'Next',
    sv: 'Nästa',
  },
  completeRegistration: {
    en: 'Complete Registration',
    sv: 'Slutför registrering',
  },
  updateRegistration: {
    en: 'Update and complete registration',
    sv: 'Uppdatera och slutför registrering',
  },
  verifyPhoneNumberButtonLabel: {
    en: 'Verify phone number',
    sv: 'Verifiera telefonnummer',
  },
  resendVerificationCode: {
    en: 'Resend Verification Code',
    sv: 'Skicka ny verifikationskod',
  },
  verificationCode: {
    en: 'Verification Code',
    sv: 'Verifikationskod',
  },
  verificationPhoneNumber: {
    en: 'Phone Number Verification',
    sv: 'Verifiering av telefonnummer',
  },
  enterMfa: {
    en: 'Enter verification code',
    sv: 'Ange MFA-kod',
  },
  almostThere: {
    en: "You're almost there!",
    sv: 'Du är nästan klar!',
  },
  codeSentTo: {
    en: (number) => `Code dispatched to ${number?.startsWith('+') ? '' : '+'}${number}.`,
    sv: (number) => `Kod skickad till ${number?.startsWith('+') ? '' : '+'}${number}.`,
  },
  youAreSoonDone: {
    en: `Hooray! You're almost there with unlocking our amazing services! 🌟 Stay tuned for the magic code that'll grant you access 💼.`,
    sv: 'Hurra! Du är nästan klar med att låsa upp våra fantastiska tjänster! 🌟 Håll utkik efter den magiska koden som ger dig tillgång 💼.',
  },
  reCAPTCHA: {
    protected: {
      en: 'This site is protected by reCAPTCHA and the Google',
      sv: 'Denna webbplats skyddas av reCAPTCHA och Googles',
    },
    and: {
      en: 'and',
      sv: 'och',
    },
    apply: {
      en: 'apply',
      sv: 'gäller',
    },
  },
};

export default translationsSignup;
