import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import getTheme from '../../../common/SplitButton/SplitButtonThemes';
import { useStorylinesApi } from '../../../../api/storylines';
import { translations } from '../../../../utils/translations';

function StorylineSelector({
  encodedOrgId,
  itemId,
  isVideoRequest = false,
  setStorylineInclude,
  newStoryLineInclude,
  setNewStoryLineInclude,
}) {
  const lang = useLanguage();
  const storylinesApi = useStorylinesApi();
  const [storylineObjects, setStorylineObjects] = useState([]);

  useEffect(() => {
    if (itemId && isVideoRequest) {
      storylinesApi
        .getStorylinesForVideoRequestSettings({ encodedOrgId, videoRequestId: itemId })
        .then((result) => {
          setStorylineObjects(result?.data?.videoRequestStorylines?.storylines ?? []);
          setStorylineInclude(result?.data?.videoRequestStorylines?.includedStorylineIds ?? []);
          setNewStoryLineInclude(result?.data?.videoRequestStorylines?.includedStorylineIds ?? []);
        })
        .catch((error) => {});
    } else if (itemId) {
      storylinesApi
        .getStorylinesForVideoCardSettings({ encodedOrgId, videoCardId: itemId })
        .then((result) => {
          setStorylineObjects(result?.data?.videoCardStorylines?.storylines ?? []);
          setStorylineInclude(result?.data?.videoCardStorylines?.includedStorylineIds ?? []);
          setNewStoryLineInclude(result?.data?.videoCardStorylines?.includedStorylineIds ?? []);
        })
        .catch((error) => {});
    }
  }, [encodedOrgId, itemId, storylinesApi, setStorylineInclude, setNewStoryLineInclude]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setNewStoryLineInclude(value);
  };

  const theme = getTheme('active');
  return (
    <fieldset className="section video-card-storyline-section">
      <label id="storyline-section-label">
        <Tooltip title={translations.videoCard.selectStorylineTooltip[lang]}>
          <Typography variant="titleMedium">
            {translations.videoCard.selectStoryline[lang]}{' '}
          </Typography>
        </Tooltip>

        <Select
          labelId="storyline-section-label"
          id="storyline-section-label"
          multiple
          displayEmpty
          value={newStoryLineInclude}
          onChange={handleChange}
          input={theme.outlinedInput(newStoryLineInclude?.length > 0)}
          renderValue={(selected) => {
            const renderString = selected.map(
              (st) => storylineObjects.find((obj) => obj.id === st)?.title,
            );
            return selected?.length > 0
              ? renderString.join(', ')
              : translations.videoCard.selectStoryline[lang];
          }}
          style={{ width: '100%', minHeight: '40px', maxWidth: '856px' }}
        >
          {storylineObjects.map((stObject) => (
            <MenuItem key={stObject.id} value={stObject.id}>
              <Checkbox checked={newStoryLineInclude.indexOf(stObject.id) > -1} />
              {stObject.title}
            </MenuItem>
          ))}
        </Select>
      </label>
    </fieldset>
  );
}
StorylineSelector.propTypes = {
  encodedOrgId: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  newStoryLineInclude: PropTypes.arrayOf(PropTypes.number).isRequired,
  setStorylineInclude: PropTypes.func.isRequired,
  setNewStoryLineInclude: PropTypes.func.isRequired,
  isVideoRequest: PropTypes.bool,
};

export default StorylineSelector;
