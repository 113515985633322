import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
// import Button from '@mui/material/Button';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PropTypes from 'prop-types';
import { gqClient } from '../../utils/graphQL';
import { VIDEO_CARD_SUMMARY_FRAGMENT } from '../../fragments/videoCards';
import { parseQueryParams } from '../../utils/utils';
import './public.scss';
import VideoCard from './VideoCard';
import VideoCardAWS from './VideoCardAWS';
import Template from './Template';
// import FilterDrawer from '../common/FilterDrawer';
// import { translations } from '../../utils/translations';

// const getAllVideoCardsByOrgId = (encodedOrgId) => {
//   return gqClient.query({
//     query: gql`
//           ${VIDEO_CARD_SUMMARY_FRAGMENT}
//           query {
//             allVideoCardsByOrgId(encodedOrgId: "${encodedOrgId}") {
//               ...VideoCardSummary
//             }
//           }
//         `,
//   });
// };

const getOrganizationData = (orgNameSlug, encodedEndScreenId) => {
  return gqClient.query({
    query: gql`
        ${VIDEO_CARD_SUMMARY_FRAGMENT}
        query {
          organization(orgNameSlug: "${orgNameSlug}",
          ${encodedEndScreenId ? `encodedEndScreenId: "${encodedEndScreenId}",` : ''}){
            id
            orgName
            orgNameSlug
            encodedId
            doNotTrack
            promoteRecordVideoPortrait
            style {
                backgroundColor
                textColor
                btnColor
                btnTextColor
              }
              premiumFeatures {
                excludeFromVimeo
              }
            tagCategories {
              id
              name {
                sv
                en
              }
              tags {
                id,
                name {
                  sv
                  en
                }
                isHidden
              }
            }
            videoCards {
              ...VideoCardSummary
            }
          }
        }
      `,
  });
};

// const getVideosByTags = (encodedOrgId, tags) => {
//   const tagIds = tags.join(',');
//   return gqClient.query({
//     query: gql`
//           ${VIDEO_CARD_SUMMARY_FRAGMENT}
//           query {
//             videoCardsByTags(encodedOrgId: "${encodedOrgId}", tagIds: [${tagIds}]) {
//               ...VideoCardSummary
//             }
//           }
//         `,
//   });
// };

function Library() {
  return (
    <Template>
      <LibraryInner />
    </Template>
  );
}

const defaultBasicOrgData = {
  orgNameSlug: '',
  encodedId: '',
  premiumFeatures: {
    excludeFromVimeo: false,
  },
};

function LibraryInner({ basicOrgData = defaultBasicOrgData }) {
  const location = useLocation();
  const [orgData, setOrgData] = useState(null);
  const [videoCards, setVideoCards] = useState(null);
  // const [tagsToFilter, setTagsToFilter] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [, setEncodedEndScreenId] = useState(null);
  const [viewContext, setViewContext] = useState(null);
  const [videoOrder, setVideoOrder] = useState([]);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [drawer, setDrawer] = useState(false);
  // const [selectLocation, setSelectLocation] = React.useState([]);
  // const [selectDepartment, setSelectDepartment] = React.useState([]);
  // const [selectTheme, setSelectTheme] = React.useState([]);
  // const [removeFilter, setRemoveFilter] = useState(null);

  const [widgetMatches, setWidgetMatches] = useState([]);

  // const tagCategories = useMemo(() => {
  //   return orgData?.tagCategories.map((tagCategory) => ({
  //     ...tagCategory,
  //     tags: tagCategory.tags.filter((tag) => !tag.isHidden),
  //   }));
  // }, [orgData]);

  const videoFirstOrder = (video, order) => {
    const index = order.indexOf(video);
    if (index > 0) {
      const array = [...order];
      const element = order[index];
      array.splice(index, 1);
      array.splice(0, 0, element);
      return array;
    }
    return order;
  };

  useEffect(() => {
    const handleSetVideoCards = (data, order) => {
      setVideoCards(
        [...data].sort((a, b) => order.indexOf(a.encodedId) - order.indexOf(b.encodedId)),
      );
    };
    if (basicOrgData.orgNameSlug) {
      const { orgNameSlug } = basicOrgData;
      const encodedOrgId = basicOrgData.encodedId;

      const queryParams = parseQueryParams(location?.search);

      // Session to track and group videostats data
      if (queryParams?.session) {
        // Opened through widget modal
        setSessionId(queryParams.session);
        setViewContext('MODAL');
      } else {
        // create session if opened directly in browser
        const now = new Date();
        const random = Math.floor(Math.random() * 1000000);
        const uniqueSessionString = `${encodedOrgId}-${now.getTime()}-${random}`;
        setSessionId(uniqueSessionString);
        setViewContext('LIBRARY');
      }

      // Widget library id
      let encodedEndScreenId = null;
      if (queryParams?.widgetlibid) {
        encodedEndScreenId = queryParams.widgetlibid;
        setEncodedEndScreenId(encodedEndScreenId);
      }

      let widgetMatchesArray = [];
      if (queryParams?.matches) {
        widgetMatchesArray = JSON.parse(queryParams.matches);
        setWidgetMatches(widgetMatchesArray);
      }

      getOrganizationData(orgNameSlug, encodedEndScreenId).then((result) => {
        setOrgData(result.data.organization);
        const orderIds = result?.data?.organization?.videoCards?.map(
          (videoCard) => videoCard.encodedId,
        );

        const order =
          queryParams && queryParams.video
            ? videoFirstOrder(queryParams.video, orderIds)
            : orderIds;

        setVideoOrder(order);
        handleSetVideoCards(result?.data?.organization?.videoCards || [], order);
        const tags = [];
        result.data.organization.tagCategories.forEach((cat) => {
          cat.tags.forEach((tag) => {
            tags.push({
              id: tag.id,
              selected: false,
            });
          });
        });
        // setTagsToFilter(tags);
      });
    }
  }, [
    basicOrgData.orgNameSlug,
    basicOrgData.encodedId,
    location.search,
    location.pathname,
    location.hash,
    basicOrgData,
  ]);

  useEffect(() => {}, [videoOrder]);

  if (!orgData || !videoCards) {
    return <div />;
  }

  // const filterByTag = (tags) => {
  //   if (tags.length === 0) {
  //     getAllVideoCardsByOrgId(orgData.encodedId).then((result) => {
  //       const resultVideoCards = [...result?.data?.allVideoCardsByOrgId] || [];
  //       handleSetVideoCards(resultVideoCards);
  //     });
  //   } else {
  //     getVideosByTags(orgData.encodedId, tags).then((result) => {
  //       const resultVideoCards = [...result?.data?.videoCardsByTags] || [];
  //       handleSetVideoCards(resultVideoCards);
  //     });
  //   }
  // };

  // const handleDrawerToggle = (status) => {
  //   setIsDrawerOpen(status);
  //   setDrawer({ ...drawer, right: status });
  // };

  // const handleUpdateFilterState = (type, data) => {
  //   const { tagsToFilter: _tagsToFilter, location, department, theme } = data;
  //   setRemoveFilter(null);
  //   switch (type) {
  //     case 'TAGS_TO_FILTER':
  //       setTagsToFilter(_tagsToFilter);
  //       filterByTag(_tagsToFilter);
  //       break;
  //     case 'LOC_DEP_THE':
  //       setSelectLocation(location);
  //       setSelectDepartment(department);
  //       setSelectTheme(theme);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <div>
      {/* <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          startIcon={<FilterAltOutlinedIcon />}
          onClick={() => handleDrawerToggle(true)}
        >
          {translations.filter[lang]}
        </Button>
      </div> */}
      {/* <React.Fragment key="right">
        <FilterDrawer
          orgData={{ id: basicOrgData.id, tagCategories, encodedId: basicOrgData.encodedOrgId }}
          anchor="right"
          isOpen={isDrawerOpen}
          onClose={() => handleDrawerToggle(false)}
          handleUpdateFilterState={handleUpdateFilterState}
          removeFilter={removeFilter}
          drawerType="library"
          style={basicOrgData.style}
        />
      </React.Fragment> */}
      <VideoLibrary
        basicOrgData={orgData}
        videoCards={videoCards}
        sessionId={sessionId}
        viewContext={viewContext}
        widgetMatches={widgetMatches}
      />
    </div>
  );
}

export default Library;

function VideoLibrary({ videoCards, basicOrgData, sessionId, viewContext, widgetMatches }) {
  if (!sessionId || !viewContext) {
    return <div />;
  }
  const videoCardElems = _.map(videoCards, (videoCard, i) => {
    if (basicOrgData?.premiumFeatures?.excludeFromVimeo) {
      return (
        <VideoCardAWS
          key={i}
          index={i}
          data={videoCard}
          basicOrgData={basicOrgData}
          sessionId={sessionId}
          viewContext={viewContext}
          widgetMatches={widgetMatches}
        />
      );
    }
    return (
      <VideoCard
        key={i}
        index={i}
        data={videoCard}
        basicOrgData={basicOrgData}
        sessionId={sessionId}
        viewContext={viewContext}
        widgetMatches={widgetMatches}
      />
    );
  });

  return <div className="video-library">{videoCardElems}</div>;
}

LibraryInner.propTypes = {
  basicOrgData: PropTypes.shape({
    orgNameSlug: PropTypes.string,
    encodedId: PropTypes.string,
    premiumFeatures: PropTypes.shape({
      excludeFromVimeo: PropTypes.bool,
    }),
  }),
};

VideoLibrary.propTypes = {
  videoCards: PropTypes.arrayOf(
    PropTypes.shape({
      encodedId: PropTypes.string.isRequired,
    }),
  ).isRequired,
  basicOrgData: PropTypes.shape({
    orgNameSlug: PropTypes.string,
    encodedId: PropTypes.string,
    premiumFeatures: PropTypes.shape({
      excludeFromVimeo: PropTypes.bool,
    }),
  }).isRequired,
  sessionId: PropTypes.string.isRequired,
  viewContext: PropTypes.string.isRequired,
  widgetMatches: PropTypes.arrayOf(
    PropTypes.shape({
      someProperty: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
