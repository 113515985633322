import { formatTime } from 'src/utils/utils';
import { StatByTimestamp } from 'src/utils/video-card/utils';

export const parseDate = (date?: Date | string): Date | undefined => {
  if (typeof date === 'string') {
    const parsedDate = new Date(date);
    if (!Number.isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
    return undefined;
  }
  return date;
};

export const sumTotalCounts = (
  data: StatByTimestamp[],
  startDate?: Date | string,
  endDate?: Date | string,
): number => {
  if (!data?.length) return 0;

  const startDateObj = parseDate(startDate);
  const endDateObj = parseDate(endDate);

  return data.reduce((total, { timestamp, count }) => {
    const entryDate = new Date(timestamp);
    if ((startDateObj && entryDate < startDateObj) || (endDateObj && entryDate > endDateObj)) {
      return total;
    }
    return total + count;
  }, 0);
};

export const formatTotalTimePlayed = (totalTime: number, language: string): string => {
  return formatTime(totalTime, language);
};

export const sumAndFormatTotalTime = (
  playbackData: StatByTimestamp[],
  language: string,
): string => {
  const totalTimePlayed = sumTotalCounts(playbackData);
  return formatTime(totalTimePlayed, language);
};

export const sumTotalTimePlayed = (
  playbackData: StatByTimestamp[],
  startDate?: Date | string,
  endDate?: Date | string,
): number => {
  return sumTotalCounts(playbackData, startDate, endDate);
};

export const sumTotalImpressions = (
  impressionData: StatByTimestamp[],
  startDate?: Date | string,
  endDate?: Date | string,
): number => {
  return sumTotalCounts(impressionData, startDate, endDate);
};

export const sumTotalPlays = (
  playData: StatByTimestamp[],
  startDate?: Date | string,
  endDate?: Date | string,
): number => {
  return sumTotalCounts(playData, startDate, endDate);
};
