import { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import translationsEndScreen from '../../../utils/translationsEndScreen';
import { isValidHttpUrl } from '../../../utils/utils';
import { errorText, errorInputField } from '../../common/SharedStylingSettings';

export const typeCallToAction = 'callToAction';

export const getEndScreenTypeContentCallToAction = ({ endScreen }) => {
  if (
    !(endScreen?.type === typeCallToAction || endScreen?.meta?.endScreenType === typeCallToAction)
  ) {
    return null;
  }
  if (!endScreen?.id) {
    return { btnTextString: endScreen?.btnTextString, url: endScreen?.url, type: typeCallToAction };
  }
  return {
    btnTextString: endScreen?.meta?.endScreen?.btnTextString,
    url: endScreen?.meta?.endScreen?.url,
    type: typeCallToAction,
  };
};

export const hasChangesCallToAction = ({ currentEndScreen, prevEndScreen }) => {
  if (currentEndScreen?.type !== typeCallToAction) {
    return false;
  }
  if (prevEndScreen?.type !== typeCallToAction) {
    return true;
  }

  if (!prevEndScreen) {
    if (currentEndScreen?.url !== '') {
      return true;
    }
  } else {
    if (currentEndScreen?.url !== prevEndScreen?.url) {
      return true;
    }
    if (currentEndScreen?.btnTextString !== prevEndScreen?.btnTextString) {
      return true;
    }
  }
  return false;
};

export const canSaveCallToAction = ({ endScreen }) => {
  if (endScreen?.type !== typeCallToAction) {
    return false;
  }
  if (!endScreen?.url) {
    return false;
  }
  if (!endScreen?.btnTextString) {
    return false;
  }
  if (!isValidHttpUrl(endScreen?.url)) {
    return false;
  }
  if (endScreen?.btnTextString?.length > 20) {
    return false;
  }

  if (endScreen?.btnTextString?.length < 1) {
    return false;
  }

  return true;
};

export const canActivateCallToAction = ({ endScreen }) => {
  if (!canSaveCallToAction({ endScreen })) {
    return false;
  }
  return true;
};

export function CallToAction({ endScreen, setEndScreen, disabled = false }) {
  const lang = useLanguage();
  const [url, setUrl] = useState(endScreen?.url || '');
  const [urlValid, setUrlValid] = useState(true);

  const handleSetUrl = (value) => {
    if (!value?.trim()?.length > 0) {
      setUrlValid(false);
    } else {
      setUrlValid(true);
    }
    setUrl(value);
    setEndScreen({ ...endScreen, url: value });
  };

  return (
    <label htmlFor="url">
      <Typography variant="titleMedium" className={`${disabled ? '' : 'required-field'}`}>
        {translationsEndScreen.cta.link[lang]}
        {!urlValid && (
          <Typography variant="bodySmall" className="error-msg" style={{ ...errorText }}>
            {url?.length > 0
              ? translationsEndScreen.cta.invalidLink[lang]
              : translationsEndScreen.cta.linkError[lang]}
          </Typography>
        )}
      </Typography>
      <input
        name="url"
        id="url"
        type="text"
        value={url}
        onChange={(e) => handleSetUrl(e.target.value)}
        required
        disabled={disabled}
        onBlur={(e) => {
          if (!isValidHttpUrl(e.target.value)) {
            setUrlValid(false);
          } else {
            setUrlValid(true);
          }
        }}
        style={urlValid ? {} : { ...errorInputField }}
      />
    </label>
  );
}
export default CallToAction;

CallToAction.propTypes = {
  endScreen: PropTypes.object,
  setEndScreen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
