import { gql } from '@apollo/client';

export const USER_QUERY = gql`
  query Me($encodedOrgId: String!) {
    me(encodedOrgId: $encodedOrgId) {
      id
      encodedId
      superAdmin
    }
  }
`;

export const CONVERSATIONAL_AVATAR_SETTINGS_QUERY = gql`
  query ConversationalAvatarSettings($encodedOrgId: String!) {
    conversationalAvatarSettings(encodedOrgId: $encodedOrgId) {
      avatarName
      avatarVoiceId
      assistantId
      avatarPosterUrl
    }
  }
`;

export const CONVERSATIONAL_AVATAR_SETTINGS_POSTER_QUERY = gql`
  query ConversationalAvatarSettingsPoster($encodedOrgId: String!) {
    conversationalAvatarSettings(encodedOrgId: $encodedOrgId) {
      avatarPosterUrl
    }
  }
`;
