import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../../utils/translations';

function AdminHeader() {
  const lang = useLanguage();
  const { logout } = useAuth0();

  return (
    <header className="header">
      <div className="company-name">
        <Link to="/admin">Life inside ADMIN</Link>
      </div>
      <div>
        <Link to="/admin/conversational-avatar">
          <i className="icon">precision_manufacturing</i>
          {translations.admin.header.conversationalAvatar[lang]}
        </Link>
        <Link to="/admin/kill-switches">
          <i className="icon">list</i>
          {translations.admin.header.killSwitches[lang]}
        </Link>
        <Link to="/admin/updates">
          <i className="icon">list</i>
          {translations.admin.header.updates[lang]}
        </Link>
        <Link to="/admin/monthly-report">
          <i className="icon">mail</i>
          {translations.admin.header.monthlyReport[lang]}
        </Link>
        <Link to="/admin/edit-suggested-questions">
          <i className="icon">list</i>
          {translations.admin.header.questionSuggestion[lang]}
        </Link>
        <Link to="/admin/">
          <i className="icon">apartment</i>
          {translations.admin.header.allOrganizations[lang]}
        </Link>
        <Link to="/admin/new-org">
          <i className="icon">apartment</i>
          {translations.admin.header.createOrganization[lang]}
        </Link>
        <Link to="/admin/new-super-admin">
          <i className="icon">verified_user</i>
          {translations.admin.header.createSuperAdmin[lang]}
        </Link>
        <Link to="/admin/super-admins">
          <i className="icon">verified_user</i>
          {translations.admin.header.superAdmins[lang]}
        </Link>
        <Link to="/admin/onboarding-statistics">
          <i className="icon">analytics</i>
          {translations.admin.header.onboardingStatistics[lang]}
        </Link>
        <Button onClick={() => logout()} variant="text" disableElevation>
          <i className="icon">login</i>
          {translations.logout[lang]}
        </Button>
      </div>
    </header>
  );
}

export default AdminHeader;
