import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { useStorylinesApi } from '../../api/storylines';
import { translations } from '../../utils/translations';
import { translationsStoryline } from '../../utils/translationsStoryline';
import { getDefaultState } from '../../utils/utils';
import StorylineHead from './Storyline/StorylineHead';
import StorylineGrid from './Storyline/StorylineGrid';

export default function Storyline() {
  const lang = useLanguage();

  const navigate = useNavigate();
  const location = useLocation();
  let storylineData = location?.state?.storylineData;
  let succesMsg = location?.state?.succesMsg;

  const storylinesApi = useStorylinesApi();

  const [affectedItem, setAffectedItem] = useState(storylineData);
  const { basicOrgData, users, storylineObjects, videoFunnelObjects } = useOutletContext();
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [editStoryline, setEditStoryline] = useState(null);
  const [userOptions, setUserOptions] = useState([getDefaultState('user', lang)]);
  const [selectedUser, setSelectedUser] = useState(getDefaultState('user', lang));
  const [selectedStatus, setSelectedStatus] = useState(getDefaultState('status', lang));
  const [urls, setUrls] = useState([]);
  const [urlsJsonb, setUrlsJsonb] = useState([]);
  const [storylines, setStorylines] = useState(
    [...(storylineObjects?.current ?? []), ...(videoFunnelObjects?.current ?? [])]?.sort((a, b) =>
      a?.title ? (a?.title).localeCompare(b?.title) : 1,
    ),
  );
  const [info, setInfo] = useState(null);
  const [storylineSearchField, setStoryLineSearchField] = useState('');

  const statusOptions = [
    { value: 'LIVE', label: translationsStoryline.statusOptionsLive[lang] },
    { value: 'EDIT', label: translationsStoryline.statusOptionsEdit[lang] },
    { value: 'ALL', label: translationsStoryline.statusOptionsAll[lang] },
  ];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (succesMsg) {
        setInfo(succesMsg);
        if (storylineData) {
          setAffectedItem(storylineData);
        }
        succesMsg = null;
        window.history.replaceState({}, document.title);
      }
      storylinesApi
        .getStorylinesMinimal(basicOrgData.encodedId)
        .then((result) => {
          if (isMounted) {
            setStorylines(
              [
                ...(result?.data?.orgStorylinesMinimal?.storylines ?? []),
                ...(result?.data?.orgStorylinesMinimal?.videoFunnels ?? []),
              ]?.sort((a, b) => (a?.title ? (a?.title).localeCompare(b?.title) : 1)),
            );

            storylineObjects.current = result?.data?.orgStorylinesMinimal?.storylines ?? [];
            videoFunnelObjects.current = result?.data?.orgStorylinesMinimal?.videoFunnels ?? [];

            storylinesApi
              .getStorylinesExtended(basicOrgData.encodedId)
              .then((result) => {
                if (isMounted) {
                  users.current = result?.data?.orgStorylinesExtended?.users || [];
                  setUrls(result.data.orgStorylinesExtended.urls);
                  setUrlsJsonb(result.data.orgStorylinesExtended?.urlsJsonb ?? []);
                }
              })

              .catch((error) => {});
          }
        })
        .catch((err) => {});
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (info) {
        setTimeout(() => {
          setInfo(null);
          storylineData = null;
          setAffectedItem(null);
        }, 3000);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [info]);

  const stringIncludes = (stringToCompare, stringToSearchFor) => {
    return stringToCompare
      ?.toLowerCase()
      ?.trim()
      ?.includes(stringToSearchFor?.toLowerCase()?.trim());
  };

  const storylineHasUser = (st, userId) => {
    return userId === 'ALL_USERS' || (Number(userId) && st.user.id === Number(userId));
  };

  const storylineHasStatus = (st, status) => {
    return status === 'ALL' || st.status === status;
  };

  const storylineHasUrl = (st, stringToSearchFor) => {
    return (
      st?.urls?.some((url) => stringIncludes(url, stringToSearchFor)) ||
      st?.urlsJsonb?.some((urlObj) => stringIncludes(urlObj?.url, stringToSearchFor))
    );
  };

  const handleSelected = (e) => {
    if (e?.type === 'videoFunnel') {
      navigate(`/${basicOrgData.orgNameSlug}/backoffice/video-funnel`, {
        state: { videoFunnelData: e, urlsJsonb },
      });
    } else {
      navigate(`/${basicOrgData.orgNameSlug}/backoffice/storyline`, {
        state: { storylineData: e, urlsJsonb },
      });
    }
  };

  const handleCreateStoryline = (e) => {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/storyline`, {
      state: { storylineData: null, urlsJsonb },
    });
  };

  const handleCreateNewVideoFunnel = (e) => {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/video-funnel`, {
      state: { videoFunnelData: null, urlsJsonb },
    });
  };

  const handleSelectedVideoFunnel = (e) => {
    setEditVideoFunnel(e);
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/video-funnel`, {
      state: { videoFunnelData: e, urlsJsonb },
    });
  };

  const handleApply = () => {
    setDrawer({ ...drawer, right: false });
  };

  const clearState = () => {
    setSelectedUser(getDefaultState('user', lang));
    setSelectedStatus(getDefaultState('status', lang));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 340,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <AppBar position="static">
          <Typography
            variant="titleLarge"
            component="div"
            sx={{
              flexGrow: 1,
              p: 2.5,
              backgroundColor: '#2F54EB',
              color: 'white',
            }}
          >
            {translations.chooseFilters[lang]}
            <IconButton
              aria-label="clear"
              sx={{ color: 'white', pl: '118px', '&:hover': { background: 'none' } }}
              onClick={toggleDrawer('right', false)}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </AppBar>
      </div>

      <div className="dropdown-wrapper" style={{ padding: '24px' }}>
        <div>
          <InputLabel id="select-status-label">
            {translationsStoryline.selectStatusStoryline[lang]}
          </InputLabel>
          <Select
            input={theme.outlinedInput(false)}
            style={{ width: '100%', height: '40px' }}
            labelId="storyline-section-label"
            value={selectedStatus}
            onChange={(e) => handleStatusSelected(e.target.value)}
            renderValue={(selected) => {
              return selected ? selected.label : '';
            }}
          >
            {statusOptions.map((item) => {
              return (
                <MenuItem value={item} key={_.uniqueId(item?.id)}>
                  <ListItemText primary={item.label} key={_.uniqueId(item?.id)} />
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div style={{ paddingTop: '20px' }}>
          <InputLabel id="select-user-label">{translations.selectUser[lang]}</InputLabel>
          <Select
            input={theme.outlinedInput(false)}
            style={{ width: '100%', height: '40px' }}
            labelId="storyline-section-label"
            value={selectedUser}
            onChange={(e) => handleUserSelected(e.target.value)}
            renderValue={(selected) => {
              return selected ? selected.label : '';
            }}
          >
            {userOptions.map((item) => {
              return (
                <MenuItem value={item} key={_.uniqueId(item?.id)}>
                  <ListItemText primary={item.label} key={_.uniqueId(item?.id)} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>

      <Stack
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        padding="15px"
      >
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleApply()}
          disabled={!isApply}
        >
          {translations.applyButton[lang]}
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => clearState()}
          disabled={!isApply}
        >
          {translations.resetButton[lang]}
        </Button>
      </Stack>
    </Box>
  );

  const infoMsg = <div className="info-pop">{info}</div>;

  return (
    <div className="storyline">
      <div className="admin-library">
        {infoMsg}
        <StorylineHead
          setCreateNewIsOpen={handleCreateStoryline}
          storylineSearchField={storylineSearchField}
          setStoryLineSearchField={setStoryLineSearchField}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          statusOptions={statusOptions}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          users={users.current}
          basicOrgData={basicOrgData}
          setCreateNewVideoFunnelIsOpen={handleCreateNewVideoFunnel}
        />
        <StorylineGrid
          storylineObjects={storylines?.filter((st) => {
            if (storylineSearchField !== '') {
              return (
                (stringIncludes(st.title, storylineSearchField) ||
                  storylineHasUrl(st, storylineSearchField)) &&
                storylineHasUser(st, selectedUser?.value) &&
                storylineHasStatus(st, selectedStatus?.value)
              );
            }
            return (
              storylineHasUser(st, selectedUser?.value) &&
              storylineHasStatus(st, selectedStatus?.value)
            );
          })}
          handleSelected={(e) => {
            handleSelected(e);
          }}
          basicOrgData={basicOrgData}
          affectedItem={affectedItem}
        />
      </div>
    </div>
  );
}
