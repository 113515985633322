import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { isMobile } from 'react-device-detect';

import VideoCard from 'src/components/shared/VideoCard';
import { MediaItemState, initialState } from 'src/components/shared/VideoCard/MediaItemState';
import { getVideoLink, getStaticThumbnail, getAnimatedThumbnail } from 'src/utils/video-card/utils';
import { VideoCardDetailed } from '../../types';

export interface ReviewCardProps {
  video: VideoCardDetailed;
  isSelected: boolean;
  onSelectVideo: (encodedVideoId: string) => void;
  disabled: boolean;
}

function ReviewCard({ video, isSelected, onSelectVideo, disabled }: ReviewCardProps) {
  const [mediaItemState, setMediaItemState] = useState<MediaItemState>({
    ...initialState,
    staticThumbnail: getStaticThumbnail(video, 'nHD') || null,
    animatedThumbnail: getAnimatedThumbnail(video, 'nHD') || null,
    videoLink: getVideoLink(video, 'nHD') || null,
    isVideo: !!getVideoLink(video) || false,
  });

  const gridWidth = mediaItemState.gridColumnEnd > 1 ? 2.5 : mediaItemState.gridColumnEnd;

  return (
    <Grid
      item
      xs={6 * gridWidth}
      sm={4 * gridWidth}
      md={3 * gridWidth}
      lg={2 * gridWidth}
      xl={2 * gridWidth}
      key={video.id}
      role="gridcell"
      data-testid={`video-grid-item-${video.id}`}
    >
      <VideoCard
        videoCard={video}
        isMobile={isMobile}
        mediaItemState={mediaItemState}
        setMediaItemState={setMediaItemState}
        borderRadius={8}
        showTitle
        isSelected={isSelected}
        onSelectVideo={onSelectVideo}
        disabled={disabled}
      />
    </Grid>
  );
}

export default ReviewCard;
