import RequestsCard from './RequestsCard';
import getTheme from '../../common/SplitButton/SplitButtonThemes';
import { statuses } from '../../../utils/utils';
import LazyLoad from 'react-lazy-load';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const cardWidth = 250;
const cardHeight = 120;
const cardOffset = 600;

export default function RequestsGrid({
  requestObjects,
  handleSelected,
  encodedOrgId,
  updateVideoRequest,
  updateVideoCard,
  orgData,
  setAffectedItem,
  setInfo,
  setError,
  suggestedQuestions,
  orgRecipients,
  handleUpdateBoard,
  handleDuplicateItemClick,
}) {
  const theme = getTheme('passive');

  const cardIsReviewVideo = (card) => {
    return (
      (card?.status === statuses.REVIEW || card?.baseData?.status === statuses.REVIEW) &&
      (card?.type !== 'video_request' || card?.baseData?.type !== 'video_request')
    );
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={5}
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          {requestObjects
            ?.filter((card) => !cardIsReviewVideo(card))
            ?.map((request) => (
              <Grid item key={_.uniqueId(`${request.id}-grid-item`)}>
                <LazyLoad
                  key={_.uniqueId(request.id)}
                  offset={cardOffset}
                  height={cardHeight}
                  width={cardWidth}
                >
                  <RequestsCard
                    request={request}
                    cardHeight={cardHeight}
                    cardWidth={cardWidth}
                    cardOffset={cardOffset}
                    encodedOrgId={encodedOrgId}
                    key={_.uniqueId(`${request.id}-card`)}
                    handleSelected={(e) => {
                      handleSelected(e);
                    }}
                    updateVideoRequest={updateVideoRequest}
                    updateVideoCard={updateVideoCard}
                    theme={theme}
                    orgData={orgData}
                    setAffectedItem={setAffectedItem}
                    setInfo={(e) => {
                      setInfo(e);
                    }}
                    suggestedQuestions={suggestedQuestions}
                    orgRecipients={orgRecipients}
                    handleUpdateBoard={handleUpdateBoard}
                    handleDuplicateItemClick={(e) => {
                      handleDuplicateItemClick(e);
                    }}
                  />
                </LazyLoad>
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );
}
