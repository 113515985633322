import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, AlertTitle } from '@mui/material';
import { Navigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';
import ThemeContext from '../../utils/ThemeContext';
import { loginDispatcherPath } from './LoginDispatcher';

function Login() {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const setLanguage = useSetLanguage();
  const { loginWithRedirect } = useAuth0();
  const routeParams = useParams();
  const [searchParams] = useSearchParams();

  // Redirect "/?orgNameSlug=<orgName>" to "/<orgName>/login".
  if (searchParams.has('orgNameSlug')) {
    const orgNameSlug = searchParams.get('orgNameSlug');
    return <Navigate to={`/${orgNameSlug}/login`} replace />;
  }

  const orgNameSlug = routeParams.orgnameslug;

  const doLogin = () => {
    const returnTo = loginDispatcherPath({ orgNameSlug });
    loginWithRedirect({ appState: { returnTo } });
  };

  return (
    <div style={{ background: theme.palette.primary.main }}>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh', pt: 3, px: 3, pb: 1, minWidth: '100vw' }}
      >
        <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid container spacing={1}>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img src="/lifeinside_logo_horizontal-326x52.svg" width="184" alt="Life Inside" />
              </Box>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    color: 'white',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <img src="/globe-icon.svg" alt="Globe" style={{ width: 18, height: 18 }} />
                  <select
                    value={lang}
                    onChange={(e) => setLanguage(e.target.value as Language)}
                    style={{
                      flex: 1,
                      height: '100%',
                      paddingLeft: 8,
                      paddingRight: 32,
                      paddingTop: 6,
                      paddingBottom: 6,
                      backgroundColor: 'transparent',
                      color: 'white',
                      border: 'none',
                      fontSize: 14,
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      appearance: 'none',
                      backgroundImage: 'url(arrow_drop_down_24px.svg)',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right center',
                      backgroundSize: '24px',
                      MozAppearance: 'none',
                      WebkitAppearance: 'none',
                    }}
                  >
                    <option value="en">English</option>
                    <option value="sv">Svenska</option>
                  </select>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ position: 'relative' }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              sx={{
                border: '0px',
                borderRadius: '16px',
                p: 2,
                pt: 3,
                m: 2,
                maxWidth: '500px',
              }}
              elevation={0}
            >
              <CardHeader
                title={translations.login[lang]}
                subheader={translations.loginDescription[lang]}
                component="h1"
                sx={{
                  pt: 0,
                  pb: 0,
                  mt: 0,
                  mb: 0,
                }}
                titleTypographyProps={{
                  variant: 'headlineLargeBoldRecoleta',
                  align: 'center',
                }}
                subheaderTypographyProps={{
                  variant: 'titleMedium',
                  align: 'center',
                }}
              />
              <CardContent sx={{ mb: 0, pb: 0 }}>
                <Alert variant="standard" severity="info" sx={{ mb: 2 }}>
                  <AlertTitle>{translations.newLoginSystem[lang]}</AlertTitle>
                  {translations.newLoginSystemDescription[lang]}
                </Alert>
                <Button
                  variant="contained"
                  disableElevation
                  type="submit"
                  fullWidth
                  onClick={() => doLogin()}
                >
                  {translations.login[lang]}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container item lg={12} sm={12} xl={12} xs={12} md={12} spacing={1}>
          <Typography
            variant="bodySmall"
            color="text.light"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            © {new Date().getFullYear()} Life Inside
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
