import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Skeleton } from '@mui/material';

import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import UpdateCard from './UpdateCard';
import { translationsDashboard } from '../../../utils/translationsDashboard';

const GET_DASHBOARD_UPDATES_QUERY = gql`
  query GetDashboardUpdates($encodedOrgId: String!, $date: String!, $language: String!) {
    getDashboardUpdates(encodedOrgId: $encodedOrgId, date: $date, language: $language) {
      id
      title
      description
    }
  }
`;

function UpdatesCard({ basicOrgData }) {
  const lang = useLanguage();

  const encodedOrgId = basicOrgData.encodedId;
  const date = useMemo(() => new Date(), []);
  const { data, loading } = useQuery(GET_DASHBOARD_UPDATES_QUERY, {
    variables: { encodedOrgId, language: lang, date },
  });
  const updates = data?.getDashboardUpdates ?? [];

  return (
    <Card>
      <CardHeader
        title={translationsDashboard.updatesCard.updates[lang]}
        titleTypographyProps={{
          variant: 'titleMedium',
        }}
        subheaderTypographyProps={{
          variant: 'bodyMedium',
        }}
      />
      <CardContent>
        <Box>
          <List>
            <TransitionGroup>
              {updates?.map((up) => (
                <Collapse key={_.uniqueId(up.id)}>
                  <ListItem
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                    key={_.uniqueId(up.id)}
                  >
                    <Grid item lg={12} sm={12} md={12} xl={12} xs={12} key={_.uniqueId()}>
                      <UpdateCard updateCard={up} key={_.uniqueId(up.id)} />
                    </Grid>
                  </ListItem>
                </Collapse>
              ))}
            </TransitionGroup>

            {loading && <Skeleton />}

            {!loading && updates.length === 0 && (
              <ListItem>
                <Typography variant="bodyMedium">
                  {translationsDashboard.updatesCard.noUpdates[lang]}
                </Typography>
              </ListItem>
            )}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

export default UpdatesCard;

UpdatesCard.propTypes = {
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
  }).isRequired,
};
