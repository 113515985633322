import React, { useEffect, useState, useMemo } from 'react';
import { sub, add } from 'date-fns';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { useLanguage } from 'src/context/LanguageContext';
import formatDateString from '../formatDateString';
import formatDailyData from '../formatDailyData';
import StatsBoxByType from '../StatsBoxByType';
import { translationsDashboard } from '../../../utils/translationsDashboard';
import { dateDiffInDays } from '../../../utils/utils';

function ViewsChartCard({ dashboardStatsState }) {
  const lang = useLanguage();
  const startEndDate = useMemo(
    () => ({
      start:
        dashboardStatsState?.viewsChartCardData?.startDate ??
        formatDateString(sub(new Date(), { days: 59 })),
      end:
        dashboardStatsState?.viewsChartCardData?.endDate ??
        formatDateString(add(new Date(), { days: 1 })),
    }),
    [
      dashboardStatsState?.viewsChartCardData?.startDate,
      dashboardStatsState?.viewsChartCardData?.endDate,
    ],
  );

  const [statsData, setStatsData] = useState(
    dashboardStatsState?.viewsChartCardData?.analytics
      ? dashboardStatsState?.viewsChartCardData?.analytics
      : null,
  );

  const [dailyData, setDailyData] = useState(
    dashboardStatsState?.viewsChartCardData?.analytics
      ? formatDailyData(startEndDate, dashboardStatsState?.viewsChartCardData?.analytics)
      : null,
  );

  useEffect(() => {
    setStatsData(dashboardStatsState?.viewsChartCardData?.analytics);
    setDailyData(formatDailyData(startEndDate, dashboardStatsState?.viewsChartCardData?.analytics));
  }, [dashboardStatsState?.viewsChartCardData?.analytics, startEndDate]);

  if (!dashboardStatsState?.viewsChartCardData?.analytics || !dailyData || !statsData) return null;

  return (
    <Card>
      <CardHeader
        title={translationsDashboard.viewsChartCard.videosPresentedLastXDays[lang]({
          days: dateDiffInDays(new Date(startEndDate.start), new Date(startEndDate.end)),
        })}
        titleTypographyProps={{
          variant: 'titleMedium',
        }}
        subheaderTypographyProps={{
          variant: 'bodyMedium',
        }}
      />
      <CardContent>
        <Box style={{ width: '100%' }}>
          <StatsBoxByType
            field="presentedVideos"
            statsData={statsData}
            dailyData={dailyData}
            fullWidth
            displayStatsOverview={false}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

ViewsChartCard.propTypes = {
  dashboardStatsState: PropTypes.shape({
    viewsChartCardData: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      analytics: PropTypes.shape({
        presentedVideos: PropTypes.shape({
          current: PropTypes.number,
          previous: PropTypes.number,
          diff: PropTypes.number,
          currentMonth: PropTypes.arrayOf(
            PropTypes.shape({ date: PropTypes.string, count: PropTypes.number }),
          ),
        }),
      }),
    }),
  }).isRequired,
};

export default ViewsChartCard;
