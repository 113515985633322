import { useState, useEffect } from 'react';
import * as React from 'react';

import _ from 'lodash';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Typography from '@mui/material/Typography';
import { useLanguage } from 'src/context/LanguageContext';
import VideoRequest from '../VideoRequest';
import VideoCardSettings from '../VideoCardSettings';
import { useRequestsApi } from './RequestsApi';
import AlertDialog from '../../common/AlertDialog';
import { translationsRequest } from '../../../utils/translationsRequest';
import { translations } from '../../../utils/translations';

export default function requestCard({
  request,
  cardHeight,
  cardWidth,
  encodedOrgId,
  updateVideoRequest,
  updateVideoCard,
  theme,
  orgData,
  setAffectedItem,
  setInfo,
  suggestedQuestions,
  orgRecipients,
  handleUpdateBoard,
  handleDuplicateItemClick,
}) {
  const [requestObj, setRequestObj] = useState(request);
  const lang = useLanguage();
  const requestsApi = useRequestsApi();

  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [cardIsOpen, setCardIsOpen] = useState(false);

  const getRequestData = () => {
    if (requestObj?.baseData?.type === 'video_request' || requestObj?.type) {
      let isMounted = true;
      if (
        !requestObj?.lastPullFromDatabase ||
        (requestObj?.lastPullFromDatabase &&
          Math.floor(Math.abs(new Date() - requestObj.lastPullFromDatabase) / 1000) > 120)
      ) {
        requestsApi
          .getVideoRequest(encodedOrgId, requestObj.id)
          .then((res) => {
            if (isMounted) {
              setRequestObj({ ...res.data.videoRequest, lastPullFromDatabase: new Date() });
              updateVideoRequest({ ...res.data.videoRequest, lastPullFromDatabase: new Date() });
            }
          })
          .catch((err) => {});
      }

      return () => {
        isMounted = false;
      };
    }
    let isMounted = true;
    if (
      !requestObj?.lastPullFromDatabase ||
      (requestObj?.lastPullFromDatabase &&
        Math.floor(Math.abs(new Date() - requestObj.lastPullFromDatabase) / 1000) > 120)
    ) {
      requestsApi
        .getVideoCard(orgData.encodedId, requestObj?.id)
        .then((res) => {
          if (isMounted) {
            setRequestObj({ ...res.data.videoCard, lastPullFromDatabase: new Date() });
            updateVideoCard({ ...res.data.videoCard, lastPullFromDatabase: new Date() });
          }
        })
        .catch((err) => {});
    }
    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    getRequestData();
  }, []);

  const getQuestion = (questionObj) => {
    if (questionObj?.questions?.length > 0) {
      return questionObj.questions?.[0]?.[lang];
    }
    if (questionObj?.questions?.length === 1) {
      return questionObj.quesstions[0][lang];
    }
    if (questionObj?.question?.[lang]) {
      return questionObj.question[lang];
    }
    return '';
  };

  const getQuestionChip = (questionObj) => {
    if (questionObj?.questions?.length - 1 > 0) {
      return (
        <Chip
          label={
            <Typography variant="labelMedium">{`+ ${questionObj.questions.length - 1}`}</Typography>
          }
          size="small"
          style={{ marginLeft: '5px' }}
          disabled
          key={_.uniqueId(`questionChip_${questionObj?.questions?.length}` - 1)}
        />
      );
    }
    return '';
  };

  const getRecipient = (questionObj) => {
    if (questionObj?.recipients?.length > 0) {
      return questionObj.recipients?.[0]?.name;
    }
    if (questionObj?.recipients?.length === 1) {
      return questionObj.recipients[0]?.name;
    }
    if (questionObj?.recipient?.name) {
      return questionObj.recipient?.name;
    }
    if (questionObj?.actorName) {
      return questionObj?.actorName;
    }
    return '';
  };

  const getRecipientChip = (questionObj) => {
    if (questionObj?.recipients?.length - 1 > 0) {
      return (
        <Chip
          label={
            <Typography variant="labelMedium">
              {`+ ${questionObj?.recipients?.length - 1}`}
            </Typography>
          }
          size="small"
          style={{ marginLeft: '5px' }}
          disabled
          key={_.uniqueId(`recipientChip_${questionObj?.recipients?.length}` - 1)}
        />
      );
    }
    return '';
  };

  const getModuleVideoRepresentation = () => {
    if (requestObj?.type !== 'video_request') {
      return (
        <VideoCardSettings
          item={requestObj}
          close={() => setCardIsOpen(false)}
          orgData={orgData}
          setAffected={setAffectedItem}
          setInfo={setInfo}
          suggestedQuestions={suggestedQuestions}
          handleDuplicateItemClick={(itemToDuplicate) => {
            handleDuplicateItemClick(itemToDuplicate);
          }}
          handleUpdateBoard={(e) => handleUpdateBoard(e)}
          orgRecipients={orgRecipients}
          setVideoCard={(item) => {
            setRequestObj({ ...item, lastPullFromDatabase: new Date() });
            updateVideoCard({ ...item, lastPullFromDatabase: new Date() });
          }}
        />
      );
    }

    return (
      <VideoRequest
        item={requestObj}
        close={() => setCardIsOpen(false)}
        orgData={orgData}
        setAffected={setAffectedItem}
        setInfo={setInfo}
        suggestedQuestions={suggestedQuestions}
        handleDuplicateItemClick={(itemToDuplicate) => {
          handleDuplicateItemClick(itemToDuplicate);
        }}
        handleUpdateBoard={(e) => handleUpdateBoard(e)}
        orgRecipients={orgRecipients}
        setVideoRequest={(item) => {
          setRequestObj({ ...item, lastPullFromDatabase: new Date() });
          updateVideoRequest({ ...item, lastPullFromDatabase: new Date() });
        }}
      />
    );
  };

  const deleteItem = () => {
    if (requestObj?.baseData?.type === 'video_request' || requestObj?.type) {
      let isMounted = true;
      if (isMounted) {
        requestsApi
          .deleteVideoRequest(orgData.encodedId, requestObj.id, orgData.id)
          .then((res) => {
            handleUpdateBoard();
            setInfo(translations.videoCard.deleteSuccessMessage[lang]);
          })
          .catch((e) => {
            setError(translations.videoCard.deleteErrorMessage[lang]);
          });
      }

      return () => {
        isMounted = false;
      };
    }
    let isMounted = true;

    if (isMounted) {
      requestsApi
        .deleteVideoCard(orgData.encodedId, requestObj.id, orgData.id)
        .then((res) => {
          handleUpdateBoard();
          setInfo(translations.videoCard.deleteSuccessMessage[lang]);
        })
        .catch((e) => {
          setError(translations.videoCard.deleteErrorMessage[lang]);
        });
    }
    return () => {
      isMounted = false;
    };
  };

  return (
    <>
      {cardIsOpen && getModuleVideoRepresentation()}
      <AlertDialog
        open={simpleAlertDialogOpen}
        title={translationsRequest.deleteItemTitle[lang]}
        description={translationsRequest.deleteItemDescription[lang]}
        confirmBtnText={translationsRequest.deleteConfirm[lang]}
        cancelBtnText={translationsRequest.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogOpen(false);
          deleteItem();
        }}
        onCancel={() => {
          setSimpleAlertDialogOpen(false);
        }}
      />
      <Card
        key={_.uniqueId(request.id)}
        sx={{
          width: cardWidth,
          height: cardHeight,
          borderRadius: 2,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          shadowOpacity: 0,
          boxShadow: 'none',
          border: '1px solid #E5E5E5',
        }}
      >
        <CardActionArea
          disableRipple
          disableTouchRipple
          onClick={() => {
            setCardIsOpen(true);
          }}
          component="div"
        >
          <CardHeader
            action={
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => {
                  const temp = { ...bindToggle(popupState) };
                  const onClickValue = temp.onClick;

                  delete temp.onClick;

                  return (
                    <div>
                      <IconButton
                        aria-label={translations.videoCardSettings[lang]}
                        {...temp}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickValue(e);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Popper {...bindPopper(popupState)} transition>
                        {({ TransitionProps }) => {
                          return (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper>
                                <ClickAwayListener onClickAway={popupState.close}>
                                  <MenuList
                                    autoFocusItem
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={(e) => {
                                      if (e.key === 'Escape') {
                                        popupState.close();
                                      }
                                    }}
                                  >
                                    <MenuItem
                                      disableRipple
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        popupState.close;
                                        handleDuplicateItemClick(requestObj);
                                      }}
                                    >
                                      {translationsRequest.duplicateMenuItemTitle[lang]}
                                    </MenuItem>
                                    <MenuItem
                                      disableRipple
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        popupState.close;
                                        setSimpleAlertDialogOpen(true);
                                      }}
                                    >
                                      {translationsRequest.deleteMenuItemTitle[lang]}
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Fade>
                          );
                        }}
                      </Popper>
                    </div>
                  );
                }}
              </PopupState>
            }
            title={TypographyTitle({
              title: [
                getQuestion(requestObj?.baseData ?? requestObj),
                getQuestionChip(requestObj?.baseData ?? requestObj),
              ],
            })}
            subheader={TypographySubheader({
              subheader: [
                getRecipient(requestObj?.baseData ?? requestObj),
                getRecipientChip(requestObj?.baseData ?? requestObj),
              ],
            })}
          />
          <CardContent style={{ width: cardWidth, height: cardHeight }} />
        </CardActionArea>
      </Card>
    </>
  );

  function TypographyTitle({ title }) {
    return <Typography variant="titleSmall">{title && title}</Typography>;
  }

  function TypographySubheader({ subheader }) {
    return (
      <>
        <Typography variant="bodySmall">{subheader && subheader}</Typography>
        <br />
      </>
    );
  }
}
