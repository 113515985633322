import React from 'react';
import Grid from '@mui/material/Grid';

import PlannerHeader from './PlannerHeader';

function Planner() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      sx={{
        pt: 1,
        pl: 2,
        pr: 2,
      }}
    >
      <Grid item container>
        <PlannerHeader />
      </Grid>
    </Grid>
  );
}
export default Planner;
