import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ConversationalAvatar from 'src/components/admin/ConversationalAvatar/ConversationalAvatar';
import Layout from 'src/components/admin/ConversationalAvatar/Layout';

import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { outletContext } from './types';
import { useAvatarPoster, useAvatarSettings, useUserData } from './dataHooks';

function ConversationalAvatarPage() {
  const navigate = useNavigate();
  const basicContext: outletContext = useOutletContext();
  const { basicOrgData } = basicContext;
  const organization = basicOrgData;

  const {
    loading: userLoading,
    error: userError,
    isSuperAdmin,
  } = useUserData(organization.encodedId);
  const {
    data: avatarSettingsData,
    loading: avatarSettingsLoading,
    error: avatarSettingsError,
  } = useAvatarSettings(organization.encodedId, isSuperAdmin);
  const {
    data: avatarPosterData,
    loading: avatarPosterLoading,
    error: avatarPosterError,
  } = useAvatarPoster(organization.encodedId, isSuperAdmin);

  if (!organization?.premiumFeatures?.conversationalAvatar) {
    navigate('/backoffice', { replace: true });
    return null;
  }

  if (
    userLoading ||
    userError ||
    isSuperAdmin === undefined ||
    avatarPosterLoading ||
    avatarPosterError ||
    (isSuperAdmin && (avatarSettingsLoading || avatarSettingsError))
  ) {
    return (
      <Layout>
        <LoadingIndicator />
      </Layout>
    );
  }

  const posterUrl = isSuperAdmin
    ? avatarSettingsData?.conversationalAvatarSettings.avatarPosterUrl || 'empty'
    : avatarPosterData?.conversationalAvatarSettings.avatarPosterUrl;

  if (!posterUrl) {
    return (
      <Layout>
        <LoadingIndicator />
      </Layout>
    );
  }

  const avatarProps =
    isSuperAdmin && avatarSettingsData?.conversationalAvatarSettings
      ? {
          avatarName: avatarSettingsData.conversationalAvatarSettings.avatarName,
          voiceId: avatarSettingsData.conversationalAvatarSettings.avatarVoiceId,
          assistantId: avatarSettingsData.conversationalAvatarSettings.assistantId,
        }
      : {};

  return (
    <Layout organization={organization}>
      <ConversationalAvatar
        organization={organization}
        superAdmin={!!isSuperAdmin}
        avatarPosterUrl={posterUrl}
        {...avatarProps}
      />
    </Layout>
  );
}

export default ConversationalAvatarPage;
