import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { translations as translationsSettings } from '../../utils/translationsSettings';
import { translationsStoryline } from '../../utils/translationsStoryline';
import { fontFamily } from './SharedStylingSettings';

export default function SimpleDialogTitle({
  title,
  description,
  searchField,
  setSearchField,
  handleOnClose,
}) {
  const lang = useLanguage();

  return (
    <DialogTitle
      id="alert-dialog-title"
      style={{
        display: 'flex',
        marginRight: '20px',
        fontFamily,
      }}
    >
      <div>
        <Typography variant="titleLarge">{title}</Typography>
        {description && <Typography variant="titleLarge">{description}</Typography>}
      </div>

      {setSearchField && (
        <div className="search-field" style={{ marginLeft: 'auto', marginRight: '20px' }}>
          <form className="nosubmit">
            <label className="search-label" htmlFor="search">
              <Typography variant="">{translationsStoryline.storylineSearchField[lang]}</Typography>
              <i className="icon search">search</i>
            </label>
            <input
              className="nosubmit"
              type="search"
              name={`search_${Math.random().toString(36).substring(2, 9)}`} // Dynamic field name
              id="search"
              placeholder={translationsStoryline.storylineSearchField[lang]}
              value={searchField}
              onChange={(e) => {
                setSearchField(e.target.value);
              }}
            />
          </form>
        </div>
      )}
      <IconButton
        aria-label={translationsSettings.closeAriaLabel[lang]}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={handleOnClose}
      >
        <i className="icon">close</i>
      </IconButton>
    </DialogTitle>
  );
}

SimpleDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  searchField: PropTypes.string,
  setSearchField: PropTypes.func,
  handleOnClose: PropTypes.func.isRequired,
};
