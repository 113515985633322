import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import detectBrowserLanguage from '../utils/detectBrowserLanguage';
import { isLanguage } from '../utils/language';
import LocalStorageLanguageStore from './LocalStorageLanguageStore';

type SetSelectedLanguageFunc = React.Dispatch<React.SetStateAction<Language | null>>;
type SetDetectedLanguageFunc = React.Dispatch<React.SetStateAction<Language>>;

const LanguageContext = createContext<Language | null>(null);
const SetLanguageContext = createContext<SetSelectedLanguageFunc | null>(null);
const SetDetectedLanguageContext = createContext<SetDetectedLanguageFunc | null>(null);

interface LanguageProviderProps {
  language: Language;
  setSelectedLanguage: SetSelectedLanguageFunc;
  setDetectedLanguage: SetDetectedLanguageFunc;
  children: React.ReactNode;
}

// LanguageProvider provides language, setLanguage, and setDetectedLanguage contexts.
export function LanguageProvider({
  language,
  setSelectedLanguage,
  setDetectedLanguage,
  children,
}: LanguageProviderProps) {
  return (
    <LanguageContext.Provider value={language}>
      <SetLanguageContext.Provider value={setSelectedLanguage}>
        <SetDetectedLanguageContext.Provider value={setDetectedLanguage}>
          {children}
        </SetDetectedLanguageContext.Provider>
      </SetLanguageContext.Provider>
    </LanguageContext.Provider>
  );
}

interface LocalStorageLanguageProvider {
  localStorageKey: string;
  children: React.ReactNode;
}

// LocalStorageLanguageProvider wraps and maintains state for LanguageProvider, additionally
// storing user-selected language in LocalStorage.
export function LocalStorageLanguageProvider({
  localStorageKey,
  children,
}: LocalStorageLanguageProvider) {
  // selectedLanguage is language selected by the user.
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  // detectedLanguage is a best guess of language without user selection.
  const [detectedLanguage, setDetectedLanguage] = useState<Language>(detectBrowserLanguage());

  const store = useMemo(() => new LocalStorageLanguageStore(localStorageKey), [localStorageKey]);

  // Read userLanguage from local storage.
  useEffect(() => {
    const lang = store.get();
    if (lang != null) {
      setSelectedLanguage(lang);
    }
  }, [store, setSelectedLanguage]);

  // Store userLanguage changes to local storage.
  useEffect(() => {
    if (isLanguage(selectedLanguage)) {
      store.set(selectedLanguage);
    } else {
      store.clear();
    }
  }, [store, selectedLanguage]);

  // Prefer selected language, fallback to detected.
  const language: Language = selectedLanguage ?? detectedLanguage;

  return (
    <LanguageProvider
      language={language}
      setSelectedLanguage={setSelectedLanguage}
      setDetectedLanguage={setDetectedLanguage}
    >
      {children}
    </LanguageProvider>
  );
}

// useLanguageContext returns language from context, or throws an error if the
// context is not provided. The value returned from this function is the
// language that should be used for translations.
export const useLanguage = () => {
  const language = useContext(LanguageContext);
  if (language === null) {
    throw new Error('must be used within LanguageProvider');
  }
  return language;
};

// useSetLanguage returns setLanguage from context, or throws an error if the
// context is not provided. The setLanguage function sets the user selected language.
export const useSetLanguage = () => {
  const setLanguage = useContext(SetLanguageContext);
  if (setLanguage === null) {
    throw new Error('must be used within LanguageProvider');
  }
  return setLanguage;
};

// useSetDetectedLanguage returns setDetectedLanguage from context, or throws
// an error if the context is not provided. The setDetectedLanguage function sets
// the detected language, which is used as fallback if the user has not themselves
// selected a language.
export const useSetDetectedLanguage = () => {
  const setDetectedLanguage = useContext(SetDetectedLanguageContext);
  if (setDetectedLanguage === null) {
    throw new Error('must be used within LanguageProvider');
  }
  return setDetectedLanguage;
};
