import PropTypes from 'prop-types';

function SearchOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M15.9767 14.4716H15.1867L14.9067 14.2016C16.1067 12.8016 16.7267 10.8916 16.3867 8.86157C15.9167 6.08157 13.5967 3.86157 10.7967 3.52157C6.5667 3.00157 3.0067 6.56157 3.5267 10.7916C3.8667 13.5916 6.0867 15.9116 8.8667 16.3816C10.8967 16.7216 12.8067 16.1016 14.2067 14.9016L14.4767 15.1816V15.9716L18.7267 20.2216C19.1367 20.6316 19.8067 20.6316 20.2167 20.2216C20.6267 19.8116 20.6267 19.1416 20.2167 18.7316L15.9767 14.4716ZM9.9767 14.4716C7.4867 14.4716 5.4767 12.4616 5.4767 9.97157C5.4767 7.48157 7.4867 5.47157 9.9767 5.47157C12.4667 5.47157 14.4767 7.48157 14.4767 9.97157C14.4767 12.4616 12.4667 14.4716 9.9767 14.4716Z"
        fill={fill}
      />
    </svg>
  );
}

export default SearchOutlinedIcon;

SearchOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
