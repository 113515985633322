import { Route } from 'react-router';

import AdminTemplate from '../components/admin/AdminTemplate';
import AllQuestions from '../components/admin/AllQuestions';
import CreateNewOrg from '../components/admin/CreateNewOrg';
import CreateNewOrgUser from '../components/admin/CreateNewOrgUser';
import CreateSuperAdmin from '../components/admin/CreateSuperAdmin';
import EditOrg from '../components/admin/EditOrg';
import EditOrgSettings from '../components/admin/EditOrgSettings';
import EditSuggestedQuestions from '../components/admin/EditSuggestedQuestions';
import EditUser from '../components/admin/EditUser';
import DeleteUser from '../components/admin/DeleteUser';
import KillSwitches from '../components/admin/KillSwitches';
import MonthlyReport from '../components/admin/MonthlyReport';
import OnboardingStats from '../components/admin/OnboardingStats';
import Organizations from '../components/admin/Organizations';
import SubtitleRequest from '../components/admin/SubtitleRequest';
import SubtitleUpload from '../components/admin/SubtitleUpload';
import SuperAdmins from '../components/admin/SuperAdmins';
import Updates from '../components/admin/Updates';
import ConversationalAvatar from '../components/admin/ConversationalAvatar';
import EditUserPermissions from '../components/admin/EditUserPermissions';
import ResetUserMFAs from '../components/admin/ResetUserMFAs';

const adminRoutes = (
  <>
    <Route path="/admin/" element={<AdminTemplate />}>
      <Route index element={<Organizations />} />
      <Route path="organizations" element={<Organizations />} />
      <Route path="edit-org-settings/:orgid" element={<EditOrgSettings />} />
      <Route path="edit-org/:orgid" element={<EditOrg />} />
      <Route path="edit-user/:userid" element={<EditUser />} />
      <Route path="edit-user-permissions/:userid" element={<EditUserPermissions />} />
      <Route path="delete-user/:userid" element={<DeleteUser />} />
      <Route path="reset-user-mfas/:userid" element={<ResetUserMFAs />} />

      <Route path="super-admins" element={<SuperAdmins />} />
      <Route path="new-super-admin" element={<CreateSuperAdmin />} />
      <Route path="new-org-user/:orgid" element={<CreateNewOrgUser />} />

      <Route path="subtitlerequest" element={<SubtitleRequest />} />

      <Route path=":encodedId/subtitleUpload" element={<SubtitleUpload />} />

      <Route path="monthly-report" element={<MonthlyReport />} />
      <Route path="updates" element={<Updates />} />
      <Route path="kill-switches" element={<KillSwitches />} />
      <Route path="edit-suggested-questions" element={<EditSuggestedQuestions />} />
      <Route path="all-questions" element={<AllQuestions />} />

      <Route path="new-org" element={<CreateNewOrg />} />
      <Route path="onboarding-statistics" element={<OnboardingStats />} />
    </Route>

    <Route path="admin/conversational-avatar" element={<ConversationalAvatar />} />
  </>
);

export default adminRoutes;
