/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';

import { Alert, Backdrop, Button } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useLanguage } from 'src/context/LanguageContext';

import { isValidPhoneNumberInput } from 'src/utils/utils';
import validator from 'validator';
import { translations } from '../../utils/translations';
import PhoneNumberInput from '../back-office/Recipient/PhoneNumberInput';
import LoadingIndicator from '../common/LoadingIndicator';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input)
  }
`;

export default function CreateNewOrgUser() {
  const params = useParams();
  const lang = useLanguage();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [unsubscribeEmails, setUnsubscribeEmails] = useState(false);

  const [error, setError] = useState<boolean | string>(false);

  const orgId = Number(params.orgid);

  const [createUser, { data, loading }] = useMutation(CREATE_USER_MUTATION, {
    onError: (err) => setError(`Error creating user: ${err.message}`),
  });

  if (!orgId) {
    return <Alert severity="error">Invalid organization ID.</Alert>;
  }

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validator.isEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!isValidPhoneNumberInput({ phoneNumber, lang, orgRecipients: null, editRecipient: null })) {
      setError('Please enter a valid phone number.');
      return;
    }

    createUser({
      variables: {
        input: {
          email,
          firstName,
          lastName,
          phoneNumber,
          orgId,
          unsubscribeEmails,
        },
      },
    });
  };

  if (data) {
    return (
      <div className="create-new-page">
        <Alert severity="success">
          User created successfully!
          <div>{data.createUser}</div>
        </Alert>
      </div>
    );
  }

  return (
    <>
      <Backdrop open={loading}>
        <LoadingIndicator />
      </Backdrop>
      <div className="create-new-page">
        {error && <Alert severity="error">{error}</Alert>}
        <h1>
          {translations.admin.Organizations.createnewUser[lang]} {orgId}
        </h1>
        <div>
          <form onSubmit={handleCreateUser}>
            <label>
              <span>
                Email *
                {email && !validator.isEmail(email) && (
                  <span style={{ color: 'red' }}> Must be valid email</span>
                )}
              </span>
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              <span>{translations.admin.Organizations.UserTel[lang]}</span>
              <PhoneNumberInput
                actorPhoneNumber={phoneNumber}
                setActorPhoneNumber={setPhoneNumber}
                disabled={false}
                orgRecipients={null}
                editRecipient={null}
              />
            </label>
            <label>
              <span>{translations.admin.Organizations.UserFirstName[lang]}</span>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </label>
            <label>
              <span>{translations.admin.Organizations.UserLastName[lang]}</span>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </label>
            <label htmlFor="unsubscribeEmails">
              <input
                type="checkbox"
                id="unsubscribeEmails"
                checked={unsubscribeEmails}
                onChange={() => setUnsubscribeEmails(!unsubscribeEmails)}
              />{' '}
              <span>Unsubscribe emails</span>
            </label>

            <Button
              variant="contained"
              type="submit"
              disabled={
                !isValidPhoneNumberInput({
                  phoneNumber,
                  lang,
                  orgRecipients: null,
                  editRecipient: null,
                }) ||
                phoneNumber?.length < 5 ||
                !validator.isEmail(email) ||
                firstName?.trim() === '' ||
                lastName?.trim() === ''
              }
            >
              {translations.admin.Organizations.create[lang]}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
