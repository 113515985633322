import { useState, useEffect } from 'react';
import _ from 'lodash';
import './back-office.scss';
import Button from '@mui/material/Button';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// import Stack from '@mui/material/Stack';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';
import { frontendPath } from '../../utils/environment';
import {
  statuses,
  actions,
  isValidEmail,
  isValidPhoneNumberInput,
  isValidHttpUrl,
  getVimeoVideoIdByItem,
} from '../../utils/utils';
import Tags from '../common/Tags';
import ConfirmPop from '../common/ConfirmPop';
import SettingsFooter from './VideoCardSettingsFooter';
import VideoActionOption from './VideoCardSettings/VideoActionSection/VideoActionOption';
import VideoCardOverlay from './VideoCardSettings/VideoActionSection/VideoCardOverlay';
import VideoStorylineSection from './VideoCardSettings/VideoStorylineSection/VideoStorylineSection';
import RecipientInputField from './Recipient/VideoCardRecipientInputField';
import QuestionInputField from './VideoCardSettings/QuestionsInput/VideoQuestionInputField';
import OnOffButton from '../common/special/OnOffButton';
import PhoneNumberInput from './Recipient/PhoneNumberInput';
import { useVideoRequestActions } from './VideoCardSettings/VideoRequest/actions';

function VideoRequest({
  item,
  close,
  orgData,
  setAffected,
  setInfo,
  suggestedQuestions,
  duplicateItem,
  handleDuplicateItemClick,
  handleUpdateBoard,
  orgRecipients,
  setVideoRequest,
}) {
  const formatTags = (dbTags) => {
    if (!dbTags) return [];
    return _.map(dbTags, (t) => ({
      id: t.id,
      selected: true,
    }));
  };

  const lang = useLanguage();
  const videoRequestActions = useVideoRequestActions();
  const [InteractionOptionAllowed] = useState(orgData?.premiumFeatures?.interactionOption ?? false);
  const [textMessageAllowed] = useState(orgData?.premiumFeatures?.textMessage ?? false);
  const [hasAutoRequest] = useState(orgData?.premiumFeatures?.autoRequests ?? false);
  const [subtitleOption] = useState(orgData?.premiumFeatures?.subtitleAllowed ?? false);
  const [includeSubtitle] = useState(orgData?.premiumFeatures?.includeSubtitle ?? false);
  const [videoRequestId, setVideoRequestId] = useState(item?.id ?? '');
  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState(item?.questions ?? []);
  const [internalNote, setInternalNote] = useState(item?.internalNote?.[lang] ?? '');

  const [itemTags, setItemTags] = useState((item && formatTags(item?.tags)) ?? []);
  const [hideFromLibrary, setHideFromLibrary] = useState(item?.hideFromLibrary ?? false);
  const [selectedInteraction, setSelectedInteraction] = useState(
    item?.interactionOption?.type ?? null,
  );
  const [itemUser, setItemUser] = useState(item?.user ?? null);

  const [error, setError] = useState(null);

  const [storylineInclude, setStorylineInclude] = useState([]);
  const [newStoryLineInclude, setNewStoryLineInclude] = useState([]);

  const [confirmPopData, setConfirmPopData] = useState(null);
  const [recipient, setRecipient] = useState(item?.recipient ? item?.recipient : null);

  function determineRecipients(item) {
    if (item?.recipients?.length > 0) {
      return [...item.recipients];
    }
    if (item?.recipient) {
      return [item.recipient];
    }
    return [];
  }

  const [recipients, setRecipients] = useState(determineRecipients(item));

  const [contactForm, setContactForm] = useState(
    (item &&
      item.interactionOption &&
      item.interactionOption.type === 'contactForm' && {
        type: item.interactionOption.type || 'contactForm',
        msg: item.interactionOption.msg[lang] || '',
        confirmationMsg: item.interactionOption.confirmationMsg[lang] || '',
        email: item.interactionOption.email || '',
        btnText: item.interactionOption.btnText[lang] || '',
      }) || {
      type: 'contactForm',
      msg: '',
      confirmationMsg: '',
      email: '',
      btnText: '',
    },
  );
  const [callToAction, setCallToAction] = useState(
    (item &&
      item.interactionOption &&
      item.interactionOption.type === 'callToAction' && {
        type: item.interactionOption.type || 'callToAction',
        msg: item.interactionOption.msg[lang] || '',
        url: item.interactionOption.url || '',
        btnText: item.interactionOption.btnText[lang] || '',
      }) || {
      type: 'callToAction',
      msg: '',
      url: '',
      btnText: '',
    },
  );

  const storylineHasChanges = () => {
    return !(
      storylineInclude.length === newStoryLineInclude.length &&
      storylineInclude.every((element, index) => element === newStoryLineInclude[index])
    );
  };

  const hasChanges = () => {
    if (item) {
      if (
        item.id !== videoRequestId ||
        item?.recipient?.id !== recipient?.id ||
        (!item?.recipient?.id && item.internalNote[lang] !== internalNote) ||
        storylineHasChanges() ||
        callToActionHasChanges() ||
        contactFormHasChanges() ||
        !_.isEqual(formatTags(item.tags), itemTags)
      ) {
        return true;
      }
    }
  };

  const createDuplicateVideoRequest = (item) => {
    const tagIds = [];

    _.forEach(item?.tags, (t) => {
      tagIds.push(t.id);
    });

    if (item) {
      return videoRequestActions.createVideoRequest({
        lang,
        encodedOrgId: orgData.encodedId,
        orgId: orgData.id,
        status: statuses.SUGGESTION,
        questions: getQuestions(),
        internalNote:
          item?.internalNote && item?.internalNote[lang] ? item?.internalNote[lang] : '',
        tags: tagIds ?? [],
        contactForm: getContactForm(),
        callToAction: getCallToAction(),
      });
    }
  };

  const createEmptyVideoRequest = () =>
    videoRequestActions.createVideoRequest({
      lang,
      encodedOrgId: orgData.encodedId,
      orgId: orgData.id,
      status: statuses.SUGGESTION,
      questions: getQuestions(),
      internalNote: '',
      tags: [],
      contactForm: getContactForm(),
      callToAction: getCallToAction(),
    });

  useEffect(() => {
    // INITIALIZE

    if (item && duplicateItem) {
      createDuplicateVideoRequest(duplicateItem)
        .then((res) => {
          setVideoRequestId(res.data.createVideoRequest.id);
          setItemUser(res.data.createVideoRequest.user);
          handleUpdateBoard();
        })
        .catch((err) => {
          setError(err);
        });
    } else if (!item) {
      // Save as new draft
      createEmptyVideoRequest()
        .then((res) => {
          setVideoRequestId(res.data.createVideoRequest.id);
          setItemUser(res.data.createVideoRequest.user);
          handleUpdateBoard();
        })

        .catch((err) => {
          setError(err);
        });
    }
  }, []);

  const beforeClose = () => {
    if (hasChanges() || (!item && videoRequestId)) {
      setConfirmPopData({
        title: translations.videoCard.closeBeforeSave[lang],
        description: translations.videoCard.areYouSureClose[lang],
        onCancel: () => setConfirmPopData(null),
        onConfirm: () => {
          if (!item && videoRequestId) {
            // Delete item with id = videoRequestId
            deleteItem();
          } else {
            close();
          }
        },
      });
    } else {
      close();
    }
  };

  document.onkeydown = (event) => {
    const evt = event || window.event;
    let isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      beforeClose();
    }
  };

  const validateQuestion = (value) => {
    if (value?.length > 65) {
      setError(translations.errors.TEXT_TOO_LONG[lang]);
    } else {
      setError(null);
      setQuestion(value);
    }
  };

  const getTagIds = () => {
    const tagIds = [];
    _.forEach(itemTags, (t) => {
      if (t.selected) {
        tagIds.push(t.id);
      }
    });
    return tagIds;
  };
  const getItemUploadLink = (orgId, itemId) => {
    if (orgId && itemId) {
      return `${frontendPath}/${orgId}/video/upload/${itemId}`;
    }
  };

  const errors = {
    NO_QUESTION: 'NO_QUESTION',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_EMAIL_CONTACT_FORM: 'INVALID_EMAIL_CONTACT_FORM',
    INVALID_URL: 'INVALID_URL',
    NO_CTA_MSG: 'NO_CTA_MSG',
    NO_CTA_BTN: 'NO_CTA_BTN',
    NO_INTERNAL_NOTE: 'NO_INTERNAL_NOTE',
    NO_ACTOR_NAME: 'NO_ACTOR_NAME',
    NO_ACTOR_TITLE: 'NO_ACTOR_TITLE',
    NO_VIDEO: 'NO_VIDEO',
    NO_UPLOAD_LINK: 'NO_UPLOAD_LINK',
    NO_PHONE_NUMBER: 'NO_PHONE_NUMBER',
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  };

  const validationError = (isDraft) => {
    const itemStatus = getItemsStatus();
    switch (itemStatus) {
      case statuses.SUGGESTION:
        if (isDraft) {
          return question || questions.length > 0 ? false : errors.NO_QUESTION;
        }
        if (!question && questions.length === 0) {
          return errors.NO_QUESTION;
        }
        if (!internalNote && !duplicateItem) {
          return errors.NO_INTERNAL_NOTE;
        }
        if (InteractionOptionAllowed) {
          if (selectedInteraction === 'contactForm' && !isValidEmail(contactForm.email)) {
            return errors.INVALID_EMAIL_CONTACT_FORM;
          }
          if (selectedInteraction === 'callToAction') {
            if (!isValidHttpUrl(callToAction.url)) {
              return errors.INVALID_URL;
            }
            if (!callToAction.btnText) {
              return errors.NO_CTA_BTN;
            }
            if (!callToAction.msg) {
              return errors.NO_CTA_MSG;
            }
          }
        }
        return false;

      case statuses.PENDING:
        if (!question && questions.length === 0) {
          return errors.NO_QUESTION;
        }
        if (actorEmail?.length > 0 && !isValidEmail(actorEmail)) {
          return errors.INVALID_EMAIL;
        }
        if (!internalNote) {
          return errors.NO_INTERNAL_NOTE;
        }
        if (InteractionOptionAllowed) {
          if (selectedInteraction === 'contactForm' && !isValidEmail(contactForm.email)) {
            return errors.INVALID_EMAIL_CONTACT_FORM;
          }
          if (selectedInteraction === 'callToAction') {
            if (!isValidHttpUrl(callToAction.url)) {
              return errors.INVALID_URL;
            }
            if (!callToAction.btnText) {
              return errors.NO_CTA_BTN;
            }
            if (!callToAction.msg) {
              return errors.NO_CTA_MSG;
            }
          }
        }
        return false;
      case statuses.REVIEW:
      case statuses.APPROVED:
      case statuses.LIVE:
        if (!question && questions.length === 0) {
          return errors.NO_QUESTION;
        }
        if (InteractionOptionAllowed) {
          if (selectedInteraction === 'contactForm' && !isValidEmail(contactForm.email)) {
            return errors.INVALID_EMAIL_CONTACT_FORM;
          }
          if (selectedInteraction === 'callToAction') {
            if (!isValidHttpUrl(callToAction.url)) {
              return errors.INVALID_URL;
            }
            if (!callToAction.btnText) {
              return errors.NO_CTA_BTN;
            }
            if (!callToAction.msg) {
              return errors.NO_CTA_MSG;
            }
          }
        }
        return false;
      case statuses.ARCHIVED:
        break;
    }
  };

  const getActorPhoneNumberIsValid = () => {
    if (recipient) {
      return isValidPhoneNumberInput({ phoneNumber: recipient?.phoneNumber, lang });
    }

    if (recipients?.length > 0) {
      return recipients?.some((r) => {
        return r?.phoneNumber && isValidPhoneNumberInput({ phoneNumber: r?.phoneNumber, lang });
      });
    }

    return false;
  };

  const getRecipientIds = () => {
    return recipients?.map((r) => r?.id);
  };

  const saveOrUpdate = async (isDraft, newStatus = statuses.SUGGESTION) => {
    if (validationError(isDraft)) {
      throw new Error(validationError(isDraft));
    }
    return videoRequestActions
      .updateVideoRequest({
        encodedOrgId: orgData.encodedId,
        lang,
        requestId: videoRequestId,
        orgId: orgData.id,
        status: newStatus,
        questions: getQuestions(),
        internalNote,
        tags: getTagIds(),
        contactForm: getContactForm(),
        callToAction: getCallToAction(),
        hideFromLibrary,
        newStoryLineInclude,
        recipientIds: getRecipientIds(),
      })
      .then((res) => {
        setAffected(res.data.updateVideoRequest.id);
        setVideoRequest(res.data.updateVideoRequest);
        handleUpdateBoard();
        return res.data.updateVideoRequest;
      });
  };

  const save = () => {
    let isMounted = true;
    saveOrUpdate(true, getItemsStatus())
      .then(() => {
        if (isMounted) {
          close();
        }
      })
      .catch((e) => {
        if (isMounted) {
          setError(e.message);
        }
      });
    return () => {
      isMounted = false;
    };
  };

  const deleteItemConfirm = () => {
    setConfirmPopData({
      title: translations.videoCard.deleteItem[lang],
      description: translations.videoCard.areYouSureDelete[lang],
      onCancel: () => setConfirmPopData(null),
      onConfirm: deleteItem,
    });
  };
  const deleteItem = () => {
    if (videoRequestId) {
      videoRequestActions
        .deleteVideoRequest(orgData.encodedId, videoRequestId, orgData.id)
        .then((res) => {
          handleUpdateBoard();
          close();
          setInfo(translations.videoCard.deleteSuccessMessage[lang]);
        })
        .catch((e) => {
          setError(e.message);
        });
    } else {
      close();
    }
  };

  const sendReminder = () => {
    videoRequestActions
      .sendReminderLink({
        encodedOrgId: orgData.encodedId,
        orgId: orgData.id,
        videoRequestId: item.id,
        sendText: false,
        sendEmail: true,
      })
      .then((a) => {
        setInfo(translations.successFeedback.REMINDER[lang]);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const sendTextMsgReminder = () => {
    videoRequestActions
      .sendReminderLink({
        encodedOrgId: orgData.encodedId,
        orgId: orgData.id,
        videoRequestId: item.id,
        sendText: textMessageAllowed && true,
        sendEmail: false,
      })
      .then((a) => {
        setInfo(translations.successFeedback.REMINDER[lang]);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const sendTextMsgAndEmailReminder = () => {
    if (textMessageAllowed && getActorPhoneNumberIsValid()) {
      videoRequestActions
        .sendReminderLink({
          encodedOrgId: orgData.encodedId,
          orgId: orgData.id,
          videoRequestId: item.id,
          sendText: textMessageAllowed && true,
          sendEmail: true,
        })
        .then((a) => {
          setInfo(translations.successFeedback.REMINDER[lang]);
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  const getItemsStatus = () => {
    return item ? item.status : statuses.SUGGESTION;
  };
  const saveAndNext = (action) => {
    const vimeoVideoId = item && getVimeoVideoIdByItem(item, lang);

    switch (action) {
      case actions.SEND_REQUEST:
      case actions.SEND_SMS_EMAIL_REQUEST:
        if (!question && questions.length === 0) {
          setError(errors.NO_QUESTION);
        } else if (!internalNote) {
          setError(errors.NO_INTERNAL_NOTE);
        } else if (
          textMessageAllowed &&
          action === actions.SEND_SMS_EMAIL_REQUEST &&
          !getActorPhoneNumberIsValid()
        ) {
          setError(errors.NO_PHONE_NUMBER);
        } else if (
          textMessageAllowed &&
          action === actions.SEND_SMS_EMAIL_REQUEST &&
          !getActorPhoneNumberIsValid()
        ) {
          setError(errors.INVALID_PHONE_NUMBER);
        } else {
          saveOrUpdate(false, statuses.PENDING)
            .then((result) => {
              if (result.encodedId) {
                videoRequestActions
                  .sendUploadLink({
                    encodedOrgId: orgData.encodedId,
                    orgId: orgData.id,
                    videoRequestId: result.id,
                    sendText: textMessageAllowed && action === actions.SEND_SMS_EMAIL_REQUEST,
                  })
                  .then((e) => {
                    setInfo(translations.successFeedback.PENDING[lang]);
                    handleUpdateBoard();
                    close();
                  })
                  .catch((e) => {
                    setError(errors.NO_UPLOAD_LINK);
                  });
              } else {
                setError(errors.NO_UPLOAD_LINK);
              }
            })
            .catch((e) => {
              setError(e.message);
            });
        }
        break;
      case actions.APPROVE:
        saveOrUpdate(false, statuses.APPROVED)
          .then(() => {
            setInfo(translations.successFeedback.APPROVED[lang]);
            close();
          })
          .catch((e) => {
            setError(e.message);
          });

        break;
      case actions.PUBLISH:
        saveOrUpdate(false, statuses.LIVE)
          .then(() => {
            setInfo(translations.successFeedback.LIVE[lang]);
            close();
          })
          .catch((e) => {
            setError(e.message);
          });

        break;
      case actions.ARCHIVE:
        saveOrUpdate(false, statuses.ARCHIVED)
          .then(() => {
            close();
          })
          .catch((e) => {
            setError(e.message);
          });
        break;
      case actions.UNPUBLISH:
      case actions.UNARCHIVE:
        saveOrUpdate(false, statuses.APPROVED)
          .then(() => {
            close();
          })
          .catch((e) => {
            setError(e.message);
          });
        break;
    }
  };

  const onChangeTags = (newTags) => {
    const newItemTags = newTags.map((tag) => {
      return { id: tag, selected: true };
    });

    setItemTags(newItemTags);
  };

  const getQuestionObject = () => {
    if (question) {
      return {
        sv: question,
        en: question,
        videoRequestId,
      };
    }
    return null;
  };

  const getQuestions = () => {
    return [
      ...questions?.map((questionObj) => {
        if (typeof questionObj === 'string') {
          return {
            sv: questionObj,
            en: questionObj,
            videoRequestId: videoRequestId || null,
          };
        }
        return {
          sv: questionObj?.question ?? questionObj?.sv ?? '',
          en: questionObj?.question ?? questionObj?.en ?? '',
          questionId: questionObj?.id || null,
          videoRequestId: questionObj?.videoRequestId || videoRequestId || null,
          answeredTimeStampTz: questionObj?.answeredTimeStampTz || '',
          videoCardId: questionObj?.videoCardId || null,
        };
      }),
      getQuestionObject(),
    ].filter(Boolean);
  };

  const getContactForm = () => {
    if (InteractionOptionAllowed) {
      switch (selectedInteraction) {
        case 'contactForm': {
          return {
            type: contactForm.type,
            msg: { [lang]: contactForm.msg },
            confirmationMsg: { [lang]: contactForm.confirmationMsg },
            email: contactForm.email,
            btnText: { [lang]: contactForm.btnText },
          };
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  const contactFormHasChanges = () => {
    if (InteractionOptionAllowed) {
      if (
        item?.interactionOption?.type === 'contactForm' &&
        selectedInteraction !== 'contactForm'
      ) {
        return true;
      }
      if (selectedInteraction === 'contactForm') {
        return (
          item?.interactionOption?.msg[lang] !== contactForm?.msg ||
          item?.interactionOption?.confirmationMsg[lang] !== contactForm?.confirmationMsg ||
          item?.interactionOption?.email !== contactForm?.email ||
          item?.interactionOption?.btnText[lang] !== contactForm?.btnText
        );
      }

      return false;
    }
    return false;
  };

  const getCallToAction = () => {
    if (InteractionOptionAllowed) {
      switch (selectedInteraction) {
        case 'callToAction': {
          return {
            type: callToAction.type,
            msg: { [lang]: callToAction.msg },
            btnText: { [lang]: callToAction.btnText },
            url: callToAction.url,
          };
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  const callToActionHasChanges = () => {
    if (InteractionOptionAllowed) {
      if (
        item?.interactionOption?.type === 'callToAction' &&
        selectedInteraction !== 'callToAction'
      ) {
        return true;
      }
      if (selectedInteraction === 'callToAction') {
        return (
          item?.interactionOption?.msg[lang] !== callToAction?.msg ||
          item?.interactionOption?.btnText[lang] !== callToAction?.btnText ||
          item?.interactionOption?.url !== callToAction?.url
        );
      }

      return false;
    }
    return false;
  };

  const handleSelectedOption = (e) => {
    setSelectedInteraction(e);
  };

  const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleCallToActionChange = (e) => {
    const { name, value } = e.target;
    setCallToAction((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="modal-background" onClick={beforeClose}>
      <div className="modal video-settings" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <Typography variant="titleLarge">
            {item?.id
              ? item?.status === statuses.PENDING
                ? `${translations.videoRequest.videoRequest[lang]}`
                : `${translations.videoRequest.editVideoRequest[lang]}`
              : `${translations.videoRequest.createNewRequest[lang]}`}
          </Typography>
          <IconButton onClick={beforeClose} className="close-btn">
            <i className="icon">close</i>
          </IconButton>
        </div>
        <div className="modal-content scrollable">
          <div className="texts">
            {error ? (
              <div className="validation-error">
                {error in translations.errors ? translations.errors[error][lang] : error}
              </div>
            ) : null}
            <fieldset className="section">
              <label>
                <QuestionInputField
                  item={item}
                  questions={questions}
                  setQuestions={setQuestions}
                  orgQuestions={suggestedQuestions}
                  basicOrgData={orgData}
                  validateQuestion={(newInputValue) => {
                    validateQuestion(newInputValue || '');
                  }}
                  question={question}
                />
              </label>
            </fieldset>

            {item?.status === statuses.PENDING ? (
              <fieldset className="section">
                <label>
                  <Typography variant="titleMedium">
                    {translations.videoRequest.recipient[lang]}
                  </Typography>
                </label>
                <div className="cols">
                  <Tooltip title={translations.videoRequest.publicVisibility[lang]}>
                    <label>
                      <Typography variant="titleMedium">
                        {translations.videoRequest.name[lang]}
                      </Typography>

                      <input type="text" value={item?.recipient?.name} disabled readOnly />
                    </label>
                  </Tooltip>
                  <Tooltip title={translations.videoRequest.publicVisibility[lang]}>
                    <label>
                      <Typography variant="titleMedium">
                        {translations.videoRequest.jobTitle[lang]}
                      </Typography>

                      <input type="text" value={item?.recipient?.title} disabled readOnly />
                    </label>
                  </Tooltip>
                </div>

                <Tooltip title={translations.videoCard.internalVisibility[lang]}>
                  <label htmlFor="actorEmail">
                    <Typography variant="titleMedium">
                      {translations.videoCard.email[lang]}
                    </Typography>

                    <input
                      type="email"
                      name="actorEmail"
                      id="actorEmail"
                      value={item?.recipient?.email}
                      disabled
                      readOnly
                    />
                  </label>
                </Tooltip>
                {textMessageAllowed && (
                  <Tooltip title={translations.videoCard.internalVisibility[lang]}>
                    <label htmlFor="actorPhoneNumber">
                      <Typography variant="titleMedium">
                        {translations.videoCard.phoneNumber[lang]}
                      </Typography>
                      {PhoneNumberInput({
                        actorPhoneNumber: item?.recipient?.phoneNumber,
                        setActorPhoneNumber: null,
                        disabled: true,
                      })}
                    </label>
                  </Tooltip>
                )}
                <Tooltip title={translations.videoCard.internalVisibility[lang]}>
                  <label htmlFor="internalNote">
                    <Typography variant="titleMedium">
                      {translations.videoCard.messageToTeamMember[lang]}
                    </Typography>
                    <textarea
                      name="internalNote"
                      id="internalNote"
                      value={internalNote}
                      disabled
                      readOnly
                    />
                  </label>
                </Tooltip>
              </fieldset>
            ) : (
              <RecipientInputField
                videoCard={item}
                recipients={recipients}
                setRecipients={(e) => {
                  setRecipients(e);
                }}
                orgRecipients={orgRecipients}
                textMessageAllowed={textMessageAllowed ?? false}
                internalNote={internalNote}
                setInternalNote={setInternalNote}
                basicOrgData={orgData}
                hasAutoRequest={hasAutoRequest}
              />
            )}

            <VideoStorylineSection
              encodedOrgId={orgData?.encodedId}
              itemId={Number(videoRequestId)}
              isVideoRequest
              storylineInclude={storylineInclude}
              setStorylineInclude={setStorylineInclude}
              newStoryLineInclude={newStoryLineInclude}
              setNewStoryLineInclude={setNewStoryLineInclude}
            />

            <fieldset className="section">
              <label>
                <Typography variant="titleMedium">{translations.videoLibrary[lang]}</Typography>
              </label>

              <label className="checkbox-label">
                <Tooltip title={translations.videoCard.hideFromLibraryInfo[lang]}>
                  <input
                    type="checkbox"
                    checked={hideFromLibrary}
                    onChange={() => setHideFromLibrary(!hideFromLibrary)}
                  />
                </Tooltip>
                <Tooltip title={translations.videoCard.hideFromLibraryInfo[lang]}>
                  <Typography variant="titleMedium">
                    {translations.videoCard.hideFromLibrary[lang]}
                  </Typography>
                </Tooltip>
              </label>
            </fieldset>

            <div className="tags">
              <label id="tags-selector">
                <Tooltip title={translations.videoCard.selectTagsInfo[lang]}>
                  <Typography variant="titleMedium">
                    {translations.videoCard.selectTags[lang]}
                  </Typography>
                </Tooltip>
                <Tags
                  previouslySelected={itemTags}
                  categories={orgData.tagCategories}
                  onChangeChecked={onChangeTags}
                  orgId={orgData.id}
                  encodedOrgId={orgData.encodedId}
                />
              </label>
            </div>
            <fieldset className="section" />
            <VideoActionOption
              selectedInteraction={selectedInteraction}
              setSelectedInteraction={handleSelectedOption}
              contactForm={contactForm}
              setContactForm={setContactForm}
              handleContactFormChange={handleContactFormChange}
              callToAction={callToAction}
              setCallToAction={setCallToAction}
              handleCallToActionChange={handleCallToActionChange}
              InteractionOptionAllowed={InteractionOptionAllowed}
            />
            <ConsentSection item={item} />
          </div>
          <VideoSection
            videoRequestId={videoRequestId}
            item={item}
            uploadLink={getItemUploadLink(orgData?.encodedId, item?.encodedId)}
            doNotTrack={orgData.doNotTrack}
            interactionOption={selectedInteraction}
            textColor="#ffffff"
            contactForm={contactForm}
            callToAction={callToAction}
            question={question}
            orgId={orgData.id}
            encodedOrgId={orgData.encodedId}
            InteractionOptionAllowed={InteractionOptionAllowed}
            subtitleOption={subtitleOption || includeSubtitle}
            itemUser={itemUser}
          />
        </div>
        <SettingsFooter
          itemStatus={getItemsStatus()}
          save={save}
          saveAndNext={saveAndNext}
          deleteItem={deleteItemConfirm}
          sendReminder={sendReminder}
          sendTextMsgReminder={sendTextMsgReminder}
          textMessageAllowed={textMessageAllowed}
          sendTextMsgAndEmailReminder={sendTextMsgAndEmailReminder}
          actorPhoneNumberIsValid={getActorPhoneNumberIsValid()}
          handleDuplicateItemClick={async () => {
            if (hasChanges() || (!item && videoRequestId)) {
              const updatedItem = await saveOrUpdate(false, getItemsStatus());
              if (updatedItem != null) {
                handleDuplicateItemClick(updatedItem);
                close();
              }
            } else {
              handleDuplicateItemClick(item);
              close();
            }
          }}
        />
        {confirmPopData ? (
          <ConfirmPop
            title={confirmPopData.title}
            description={confirmPopData.description}
            onCancel={confirmPopData.onCancel}
            onConfirm={confirmPopData.onConfirm}
          />
        ) : null}
      </div>
    </div>
  );
}

export default VideoRequest;

function VideoSection({
  item,
  videoRequestId,
  uploadLink,
  textColor,
  interactionOption,
  contactForm,
  callToAction,
  question,
  orgId,
  InteractionOptionAllowed,
  itemUser,
}) {
  const lang = useLanguage();
  const [videoLoaded, setVideoLoaded] = useState(null);
  const [previewInteraction, setPreviewInteraction] = useState(true);
  const togglePreview = () => setPreviewInteraction(!previewInteraction);
  const infoBox = ({ header, infoDetails }) => {
    return (
      <div className="info-box">
        <Typography variant="titleMedium">{header}</Typography>
        {infoDetails &&
          infoDetails.map((detail) => (
            <Typography variant="bodyMedium" key={_.uniqueId(header)}>
              {' '}
              {detail}{' '}
            </Typography>
          ))}
      </div>
    );
  };

  const stringToUpperCase = (textToUppCase) => {
    if (!textToUppCase) {
      return textToUppCase;
    }
    const modified = textToUppCase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
    return modified || textToUppCase;
  };

  const videoInfoBox = () => {
    if (item) {
      if (item.status === statuses.PENDING) {
        if (item.lastReminderTime) {
          return infoBox({
            header: translations.videoCard.reminderHeader[lang],
            infoDetails: [
              translations.videoCard.reminderSentDateTime[lang]({
                date: new Date(item.lastReminderTime),
              }),
              item?.lastReminderByUser
                ? translations.videoCard.reminderSentByUser[lang]({
                    userName: stringToUpperCase(item?.lastReminderByUser),
                  })
                : translations.videoCard.reminderSentBySystem[lang],
            ],
          });
        }
        if (item.requestedByUser) {
          return infoBox({
            header: translations.videoCard.requestSent[lang],
            infoDetails: [
              translations.videoCard.reminderSentDateTime[lang]({
                date: new Date(item.requestTime),
              }),
              translations.videoCard.reminderSentByUser[lang]({
                userName: stringToUpperCase(item.requestedByUser),
              }),
            ],
          });
        }
      }
      if (item.status === statuses.SUGGESTION) {
        return infoBox({
          header: translations.videoCard.notYetSentOut[lang],
          infoDetails: [
            translations.videoCard.createdByUser[lang]({
              userName: stringToUpperCase(
                `${itemUser?.firstName || ''} ${itemUser?.lastName || ''}`,
              ),
            }),
          ],
        });
      }
    }
    return null;
  };

  return (
    <div className={`video ${videoLoaded ? 'video-loaded' : ''}`}>
      {interactionOption ? (
        <div>
          <OnOffButton
            isOn={previewInteraction}
            onClick={togglePreview}
            text={translations.videoCard.previewInteraction[lang]}
          />
        </div>
      ) : (
        <div style={{ height: '27px' }} />
      )}
      <div className="video-wrapper no-video">
        {InteractionOptionAllowed && interactionOption && previewInteraction && (
          <div>
            <VideoCardOverlay
              textColor={textColor}
              interactionOption={interactionOption}
              contactForm={contactForm}
              callToAction={callToAction}
              question={question}
              orgId={orgId}
            />
          </div>
        )}
        <img src="/images/icons/film-camera-icon.svg" alt="" />
        {item?.status === statuses.PENDING && (
          <span>{translations.videoCard.waitingOnCollegue[lang]}</span>
        )}
      </div>
      <Button
        variant="outlined"
        disableElevation
        startIcon={<FileUploadOutlinedIcon />}
        fullWidth
        target="_blank"
        rel="noreferrer"
        href={uploadLink}
      >
        {translations.videoCard.goToUpload[lang]}
      </Button>

      <Button
        variant="outlined"
        disableElevation
        startIcon={<ContentPasteOutlinedIcon />}
        fullWidth
        onClick={() => navigator.clipboard.writeText(uploadLink)}
      >
        {translations.videoCard.copyUploadLink[lang]}
      </Button>
      {videoInfoBox()}
    </div>
  );
}

function ConsentSection({ item }) {
  const lang = useLanguage();

  if (!item || item.status === statuses.SUGGESTION || item.status === statuses.PENDING) return null;
  const soMeConsent = item.consentTexts?.soMeConsent;
  const uploadConsent = item.consentTexts?.uploadConsent || translations.defaultUploadConsent[lang];
  return (
    <div className="consent-section">
      <Typography variant="titleMedium">{translations.UserConsents[lang]}</Typography>
      <ul>
        <li>{uploadConsent}</li>
        <li style={{ color: !soMeConsent ? 'red' : 'black' }}>
          {soMeConsent ? item.consentTexts?.soMeConsent : translations.noSoMeConsent[lang]}
        </li>
      </ul>
    </div>
  );
}
