/* eslint-disable import/prefer-default-export */

import { memo } from 'react';
import { Checkbox } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useLanguage } from 'src/context/LanguageContext';
import videoCollectorTranslations from '../VideoCollectorTranslations';
import { VideoCollectorDetailed } from '../types';

interface UserSavePreferenceRow {
  id: number;
  field: string;
  enabled: boolean;
  required: boolean;
  onEnabledChange: (enabled: boolean) => void;
  onRequiredChange: (required: boolean) => void;
}

const createRows = ({
  lang,
  saveName,
  saveNameRequired,
  saveTitle,
  saveTitleRequired,
  saveEmail,
  saveEmailRequired,
  savePhone,
  savePhoneRequired,
  onChange,
}: {
  lang: Language;
  saveName: boolean;
  saveNameRequired: boolean;
  saveTitle: boolean;
  saveTitleRequired: boolean;
  saveEmail: boolean;
  saveEmailRequired: boolean;
  savePhone: boolean;
  savePhoneRequired: boolean;
  onChange: (change: Partial<VideoCollectorDetailed>) => void;
}) => {
  const rows: UserSavePreferenceRow[] = [
    {
      id: 1,
      field: videoCollectorTranslations.name[lang],
      enabled: saveName,
      required: saveNameRequired,
      onEnabledChange: (newSaveName) => {
        onChange({ saveName: newSaveName });
      },
      onRequiredChange: (newSaveNameRequired) => {
        onChange({ saveNameRequired: newSaveNameRequired });
      },
    },
    {
      id: 2,
      field: videoCollectorTranslations.title[lang],
      enabled: saveTitle,
      required: saveTitleRequired,
      onEnabledChange: (newSaveTitle) => {
        onChange({ saveTitle: newSaveTitle });
      },
      onRequiredChange: (newSaveTitleRequired) => {
        onChange({ saveTitleRequired: newSaveTitleRequired });
      },
    },
    {
      id: 3,
      field: videoCollectorTranslations.email[lang],
      enabled: saveEmail,
      required: saveEmailRequired,
      onEnabledChange: (newSaveEmail) => {
        onChange({ saveEmail: newSaveEmail });
      },
      onRequiredChange: (newSaveEmailRequired) => {
        onChange({ saveEmailRequired: newSaveEmailRequired });
      },
    },
    {
      id: 4,
      field: videoCollectorTranslations.phone[lang],
      enabled: savePhone,
      required: savePhoneRequired,
      onEnabledChange: (newSavePhone) => {
        onChange({ savePhone: newSavePhone });
      },
      onRequiredChange: (newSavePhoneRequired) => {
        onChange({ savePhoneRequired: newSavePhoneRequired });
      },
    },
  ];
  return rows;
};

const createColumns = ({ lang }: { lang: Language }) => {
  const cols: GridColDef<UserSavePreferenceRow>[] = [
    {
      field: 'field',
      headerName: '',
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: 'enabled',
      flex: 1,
      headerName: videoCollectorTranslations.enabled[lang],
      renderCell: (cellParams: GridRenderCellParams<UserSavePreferenceRow, boolean>) => (
        <Checkbox
          checked={cellParams.value}
          onChange={(event) => cellParams.row.onEnabledChange(event.target.checked)}
        />
      ),
      editable: false,
      sortable: false,
      filterable: false,
    },
    {
      field: 'required',
      flex: 1,
      headerName: videoCollectorTranslations.required[lang],
      renderCell: (cellParams: GridRenderCellParams<UserSavePreferenceRow, boolean>) => (
        <Checkbox
          checked={cellParams.value}
          disabled={!cellParams.row.enabled}
          onChange={(event) => cellParams.row.onRequiredChange(event.target.checked)}
        />
      ),
      editable: false,
      sortable: false,
      filterable: false,
    },
  ];
  return cols;
};

interface UserSavePreferencesInputProps {
  saveName: VideoCollectorDetailed['saveName'];
  saveNameRequired: VideoCollectorDetailed['saveNameRequired'];
  saveTitle: VideoCollectorDetailed['saveTitle'];
  saveTitleRequired: VideoCollectorDetailed['saveTitleRequired'];
  saveEmail: VideoCollectorDetailed['saveEmail'];
  saveEmailRequired: VideoCollectorDetailed['saveEmailRequired'];
  savePhone: VideoCollectorDetailed['savePhone'];
  savePhoneRequired: VideoCollectorDetailed['savePhoneRequired'];
  onChange: (change: Partial<VideoCollectorDetailed>) => void;
}

const UserSavePreferencesInput = memo(function UserSavePreferencesInput({
  saveName,
  saveNameRequired,
  saveTitle,
  saveTitleRequired,
  saveEmail,
  saveEmailRequired,
  savePhone,
  savePhoneRequired,
  onChange,
}: UserSavePreferencesInputProps) {
  const lang = useLanguage();
  const rows = createRows({
    lang,
    saveName,
    saveNameRequired,
    saveTitle,
    saveTitleRequired,
    saveEmail,
    saveEmailRequired,
    savePhone,
    savePhoneRequired,
    onChange,
  });
  const columns = createColumns({ lang });
  return (
    <DataGrid rows={rows} columns={columns} rowSelection={false} disableColumnMenu hideFooter />
  );
});

export default UserSavePreferencesInput;
