import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import QRCode from 'qrcode';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import ThemeContext from 'src/utils/ThemeContext';
import { Fab } from '@mui/material';
import slugify from 'slugify';

import { useLanguage } from 'src/context/LanguageContext';
import SplitButton, { SplitButtonOption } from '../SplitButton';
import FileDownloadIcon from '../icons/fileDownload/FileDownloadIcon';
import CopyOutlinedIcon from '../icons/copy/CopyOutlinedIcon';

const translation = {
  copyUrlToClipboard: {
    en: 'Copy URL to clipboard',
    sv: 'Kopiera URL till urklipp',
  },
  copiedToClipboard: {
    en: 'Copied to clipboard',
    sv: 'Kopierat till urklipp',
  },
  copyFailed: {
    en: 'Copy failed',
    sv: 'Kopiering misslyckades',
  },
  downloadAs: {
    en: 'Download as',
    sv: 'Ladda ner som',
  },
};

interface QRCodeComponentProps {
  text: string;
  fileName?: string;
  allowDownload?: boolean;
  showText?: boolean;
}

const downloadFormatOptions = ['png', 'svg', 'webp', 'jpeg'];

function QRCodeComponent({
  text,
  fileName = '',
  allowDownload = false,
  showText = false,
}: QRCodeComponentProps) {
  const [JSX, setJSX] = useState<JSX.Element | null>(null);
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const [copySuccess, setCopySuccess] = useState(false); // State to track copy success
  const [isCopied, setIsCopied] = useState(false); // State to control Snackbar
  const textRef = useRef(null);

  const convertSVGElementToJSX = useCallback((element: SVGElement): JSX.Element => {
    // Convert attributes to a format that JSX understands
    const attributes = Array.from(element.attributes).reduce(
      (acc: { [key: string]: string }, attr) => {
        let attrName = attr.name;
        // Convert attributes to camelCase if necessary
        if (attrName === 'shape-rendering') {
          attrName = 'shapeRendering';
        }
        acc[attrName] = attr.value;
        return acc;
      },
      {},
    );

    // Add data-testid attribute if the element is an SVG
    if (element.tagName.toLowerCase() === 'svg') {
      attributes['data-testid'] = 'qrcode-svg';
    }

    // Convert child nodes to JSX
    const children = Array.from(element.childNodes)
      .map((child) => {
        if (child.nodeType === 1) {
          // Element node
          return convertSVGElementToJSX(child as SVGElement);
        }
        if (child.nodeType === 3) {
          // Text node
          return child.textContent;
        }
        return null;
      })
      .filter(Boolean);

    return React.createElement(element.tagName, attributes, ...children);
  }, []);

  useEffect(() => {
    QRCode.toString(text, { type: 'svg' })
      .then((svgString) => {
        const svgElement = new DOMParser().parseFromString(svgString, 'image/svg+xml')
          .documentElement as unknown as SVGElement;
        setJSX(convertSVGElementToJSX(svgElement));
      })
      .catch((err) => {
        console.error(err);
        QRCode.toDataURL(text, { type: 'image/webp' })
          .then((url) => {
            setJSX(<img src={url} alt="" />);
          })
          .catch((error) => {
            console.error(error);
          });
      });
  }, [text, convertSVGElementToJSX]);

  const downloadFile = (href: string, filename: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = href;
    downloadLink.download = filename;
    downloadLink.click();
  };

  const handleDownload = (format: 'webp' | 'svg' | 'png' | 'jpeg') => {
    const fileNameSlug = slugify(fileName || text, {
      remove: /[*+~.()'"!:@*?]/g,
    }).toLowerCase();
    if (format === 'svg') {
      QRCode.toString(text, { type: 'svg' })
        .then((svgString) => {
          downloadFile(`data:image/svg+xml;base64,${btoa(svgString)}`, `${fileNameSlug}.svg`);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // Handles 'webp', 'png', 'jpeg'
      QRCode.toDataURL(text, { type: `image/${format}` })
        .then((url) => {
          downloadFile(url, `${fileNameSlug}.${format}`);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const selectText = () => {
    if (textRef.current) {
      const range = document.createRange();
      range.selectNodeContents(textRef.current);

      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const splitButtonOptions: SplitButtonOption[] = downloadFormatOptions.map((format) => ({
    label: `${translation.downloadAs[lang]} ${format.toUpperCase()}`,
    muiIcon: format === 'png' ? <FileDownloadIcon fill={theme.palette.text.light} /> : undefined,
    onClick: () => handleDownload(format as 'webp' | 'svg' | 'png' | 'jpeg'),
    disabled: false,
  }));

  const handleCopyClick = () => {
    selectText();
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(true);
        setIsCopied(true);
      })
      .catch(() => {
        setCopySuccess(false);
        setIsCopied(true);
      });
  };

  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  if (!JSX || !text) {
    return null;
  }

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
      data-testid="qrcode-component"
    >
      {JSX}
      {allowDownload && <SplitButton options={splitButtonOptions} disableElevation />}
      {showText && (
        <Stack
          sx={{
            position: 'relative',
            maxWidth: '100%',
          }}
        >
          <Typography
            ref={textRef}
            variant="bodyLarge"
            sx={{
              backgroundColor: theme.palette.background.disabled,
              borderRadius: 0.75,
              px: 0.5,
              py: 1,
              wordWrap: 'break-word',
            }}
          >
            {text}
          </Typography>
          <Fab
            color="primary"
            sx={{
              position: 'absolute',
              bottom: -32,
              right: -24,
              zIndex: 1,
            }}
            onClick={handleCopyClick}
            size="small"
            id="copy-to-clipboard"
            name="copy-to-clipboard"
            aria-label={translation.copyUrlToClipboard[lang]}
          >
            <CopyOutlinedIcon />
          </Fab>
        </Stack>
      )}
      <Snackbar
        open={isCopied}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={copySuccess ? translation.copiedToClipboard[lang] : translation.copyFailed[lang]}
      />
    </Stack>
  );
}

export default QRCodeComponent;
