import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

import { useLanguage } from 'src/context/LanguageContext';
import { VideoCard, getQuestion, getName, getTitle } from 'src/utils/video-card/utils';

const typographyStyleQuestion = (playFullVideo: boolean, isMobileDevice: boolean) => {
  return isMobileDevice
    ? {
        pb: '0.25rem',
        px: '0.25rem',
        pt: playFullVideo ? '0.5rem' : '0.25rem',
        fontSize: '	0.6rem',
      }
    : {
        pb: '0.75rem',
        px: '0.5rem',
        pt: playFullVideo ? '1rem' : '0.25rem',
        fontSize: '0.9rem',
      };
};

const typographyStyleNameTitle = (isMobileDevice: boolean) => {
  return isMobileDevice
    ? { p: '0.25rem', fontSize: '	0.6rem' }
    : { p: '0.5rem', fontSize: '0.9rem' };
};

interface MediaInfoOverlayProps {
  videoCard: VideoCard;
  mouseHover: boolean;
  playFullVideo: boolean;
  isMobileDevice?: boolean;
  borderRadius?: string | number;
  isSelected?: boolean;
  onSelectVideo?: (encodedVideoId: string) => void;
  disabled?: boolean;
}

function MediaInfoOverlay({
  videoCard,
  mouseHover,
  playFullVideo,
  isMobileDevice = false,
  borderRadius = 0,
  isSelected = false,
  onSelectVideo = undefined,
  disabled = true,
}: MediaInfoOverlayProps) {
  const lang = useLanguage();
  const question = getQuestion(videoCard, lang);
  const name = getName(videoCard);
  const title = getTitle(videoCard, lang, !!name);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mouseHover || isMobileDevice) {
      overlayRef.current?.focus();
    }
  }, [mouseHover, isMobileDevice]);

  const shouldFadeIn: boolean = !!((mouseHover || isMobileDevice) && !!question);

  return (
    <Fade
      in={shouldFadeIn}
      timeout={{ appear: 500, enter: 500, exit: 2500 }}
      data-testid="fade-component"
    >
      <Box ref={overlayRef} tabIndex={-1} aria-live="polite">
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            height: 'auto',
            background:
              'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 75%, rgba(0,0,0,0) 100%)',
            borderTopLeftRadius:
              typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
            borderTopRightRadius:
              typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
            sx={typographyStyleQuestion(playFullVideo, isMobileDevice)}
            style={{
              transition: 'all 0.75s ease-in-out',
              width: '100%',
            }}
            onClick={() =>
              !disabled && onSelectVideo && videoCard.id && onSelectVideo(videoCard.encodedId)
            }
          >
            <Typography
              variant="titleSmall"
              color="text.light"
              role="heading"
              aria-label={`Video title ${question}`}
              aria-level={2}
              data-testid={`video-question-${videoCard.id}`}
            >
              {question}
            </Typography>
            {typeof isSelected === 'boolean' && onSelectVideo && (
              <Checkbox
                disabled={disabled}
                checked={isSelected}
                sx={{
                  mt: 0,
                  pt: 0,
                  color: 'tertiary.main',
                  '&.Mui-checked': {
                    color: 'tertiary.main',
                  },
                }}
                style={{
                  padding: '0px',
                  margin: '0px',
                }}
                inputProps={{ 'aria-label': `Select video ${name || 'unnamed'}` }}
                data-testid={`select-video-${videoCard.id}`}
              />
            )}
          </Stack>
        </Box>
        {name && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              height: 'auto',
              background:
                'linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 75%, rgba(0,0,0,0) 100%)',
              borderBottomLeftRadius:
                typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
              borderBottomRightRadius:
                typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
            }}
            role="region"
            aria-label={`Information about ${name}`}
          >
            <Typography
              variant="titleSmall"
              color="text.light"
              sx={typographyStyleNameTitle(isMobileDevice)}
              component="h2"
              data-testid={`video-name-title-${videoCard.id}`}
            >
              {name}
              {title && `${title}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Fade>
  );
}

export default MediaInfoOverlay;
