export const calculateAspectRatioStyles = (
  width: number,
  height: number,
): {
  aspectRatio: number;
  gridColumnEnd: number;
  gridRowEnd: number;
} => {
  const naturalAspectRatio = width / height;

  // Calculate the distance to each target aspect ratio
  const distanceToSquare = Math.abs(naturalAspectRatio - 1); // Distance to 1:1
  const distanceToWide = Math.abs(naturalAspectRatio - 2); // Distance to 2:1
  const distanceToTall = Math.abs(naturalAspectRatio - 0.5); // Distance to 1:2

  // Determine the closest aspect ratio by finding the minimum distance
  const minDistance = Math.min(distanceToSquare, distanceToWide, distanceToTall);

  let aspectRatio;
  let gridColumnEnd;
  let gridRowEnd;

  // Assign styles based on the closest aspect ratio
  if (minDistance === distanceToSquare) {
    aspectRatio = 1; // Closest to Square (1:1)
    gridColumnEnd = 1;
    gridRowEnd = 1;
  } else if (minDistance === distanceToWide) {
    aspectRatio = 2; // Closest to Wide (2:1)
    gridColumnEnd = 2;
    gridRowEnd = 1;
  } else {
    aspectRatio = 0.5; // Closest to Tall (1:2)
    gridColumnEnd = 1;
    gridRowEnd = 2;
  }

  return {
    aspectRatio,
    gridColumnEnd,
    gridRowEnd,
  };
};

export default calculateAspectRatioStyles;
