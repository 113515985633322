import PropTypes from 'prop-types';

function PlannerOutlinedIcon({ fill = '#FFFFFF' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.675 20.375L20.375 19.675L18.5 17.8V15H17.5V18.2L19.675 20.375ZM5 21C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V11.7C20.6833 11.55 20.3583 11.4207 20.025 11.312C19.6917 11.2033 19.35 11.1243 19 11.075V5H5V19H11.05C11.1 19.3667 11.1793 19.7167 11.288 20.05C11.3967 20.3833 11.5257 20.7 11.675 21H5ZM5 18V19V5V11.075V11V18ZM7 17H11.075C11.125 16.65 11.2043 16.3083 11.313 15.975C11.4217 15.6417 11.5423 15.3167 11.675 15H7V17ZM7 13H13.1C13.6333 12.5 14.2293 12.0833 14.888 11.75C15.5467 11.4167 16.2507 11.1917 17 11.075V11H7V13ZM7 9H17V7H7V9ZM18 23C16.6167 23 15.4373 22.5123 14.462 21.537C13.4867 20.5617 12.9993 19.3827 13 18C13 16.6167 13.4877 15.4373 14.463 14.462C15.4383 13.4867 16.6173 12.9993 18 13C19.3833 13 20.5627 13.4877 21.538 14.463C22.5133 15.4383 23.0007 16.6173 23 18C23 19.3833 22.5123 20.5627 21.537 21.538C20.5617 22.5133 19.3827 23.0007 18 23Z"
        fill={fill}
      />
    </svg>
  );
}

export default PlannerOutlinedIcon;

PlannerOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
