import React, { useEffect, useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { gql } from '@apollo/client';
import parse from 'html-react-parser';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient, ApolloClient } from 'src/context/ApolloClientContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import SimpleDivider from '../../common/SimpleDivider';
import { translations } from '../../../utils/translationsSettings';
import useCheckPermissionsAndNavigate from './Permissions/CheckPermissionsAndNavigate';
import { Context } from './types';

interface UpdateOrgSettingsGeneralArgs {
  backOfficeClient: ApolloClient;
  encodedOrgId: string;
  widgetIsActive: boolean;
  tagFinderSelector?: string;
  disallowSuperadmin?: boolean;
  doNotTrack?: boolean;
  storylineOverride?: boolean;
  customTexts: string;
}

const updateOrgSettingsGeneral = ({
  backOfficeClient,
  encodedOrgId,
  widgetIsActive,
  tagFinderSelector,
  disallowSuperadmin,
  storylineOverride,
  doNotTrack,
  customTexts,
}: UpdateOrgSettingsGeneralArgs) => {
  return backOfficeClient.mutate({
    mutation: gql`
        mutation($encodedOrgId: String, $widgetIsActive: Boolean, $disallowSuperadmin: Boolean, $doNotTrack: Boolean, $storylineOverride: Boolean, $tagFinderSelector: String) {
          updateOrgSettingsGeneral(encodedOrgId: $encodedOrgId, input: {
            widgetIsActive: $widgetIsActive,
            disallowSuperadmin: $disallowSuperadmin,
            doNotTrack: $doNotTrack,
            storylineOverride: $storylineOverride,
            tagFinderSelector: $tagFinderSelector,
            customTexts: ${customTexts}
          }) {
            statusMessage
          }
        }
      `,
    variables: {
      encodedOrgId,
      widgetIsActive,
      tagFinderSelector,
      disallowSuperadmin,
      doNotTrack,
      storylineOverride,
    },
  });
};

function GeneralSettings() {
  const lang = useLanguage();
  const backOfficeClient = useBackOfficeApolloClient();

  const { basicOrgData } = useOutletContext<Context>();
  // Define states with their types
  const [widgetStatus, setWidgetStatus] = useState<boolean>(basicOrgData.widgetIsActive);
  const [info, setInfo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [disallowSuperadminBtn, setDisallowSuperadminBtn] = useState<boolean>(
    basicOrgData.disallowSuperadmin || false,
  );
  const [doNotTrackBtn] = useState<boolean>(basicOrgData.doNotTrack || false);
  const [storylineOverride, setStorylineOverride] = useState<boolean>(
    basicOrgData.storylineOverride || false,
  );
  const [tagFinderSelector] = useState<string | undefined>(basicOrgData.tagFinderSelector);

  const [uploadConsentText, setUploadConsentText] = useState<string>(
    decodeURIComponent(basicOrgData.customTexts?.uploadConsent || ''),
  );
  const [showUploadConsentTextPreview, setShowUploadConsentTextPreview] = useState<boolean>(false);
  const [consentHeight, setConsentHeight] = useState<number>(0);

  const [soMeConsentText, setSoMeConsentText] = useState<string>(
    decodeURIComponent(basicOrgData.customTexts?.soMeConsent?.text || ''),
  );
  const [showSoMeConsentTextPreview, setShowSoMeConsentTextPreview] = useState<boolean>(false);
  const [soMeConsentHeight, setSoMeConsentHeight] = useState<number>(0);
  const [enableSoMeConsent, setEnableSoMeConsent] = useState<boolean>(
    basicOrgData.customTexts?.soMeConsent?.enable ?? true,
  );
  const [soMeConsentMandatory, setSoMeConsentMandatory] = useState<boolean>(
    basicOrgData.customTexts?.soMeConsent?.mandatory ?? false,
  );
  const consentRef = useRef<HTMLTextAreaElement>(null);
  const soMeConsentRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }, [error]);

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  const saveChanges = () => {
    const customTexts = `{
      uploadConsent: """${encodeURIComponent(uploadConsentText)}""",
      soMeConsent: {text:"""${encodeURIComponent(
        soMeConsentText,
      )}""", enable:${enableSoMeConsent}, mandatory: ${soMeConsentMandatory}}
    }`;
    updateOrgSettingsGeneral({
      backOfficeClient,
      encodedOrgId: basicOrgData.encodedId,
      widgetIsActive: widgetStatus,
      tagFinderSelector,
      disallowSuperadmin: disallowSuperadminBtn,
      doNotTrack: doNotTrackBtn,
      storylineOverride,
      customTexts,
    })
      .then(() => {
        setInfo(translations.savedSuccess[lang]);
      })
      .catch(() => {
        setError(translations.savedError[lang]);
      });
  };

  const infoMsg = <div className="info-pop">{info}</div>;
  const errorMsg = <div className="error-pop">{error}</div>;
  if (userLoading) return <LoadingIndicator />;

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Container maxWidth="md">
          <div className="inner">
            {infoMsg}
            {errorMsg}
            <div className="btn-row">
              <Button variant="contained" color="primary" disableElevation onClick={saveChanges}>
                {translations.save[lang]}
              </Button>
            </div>
            <br />
            <div>
              <label htmlFor="widget-status-switch" className="switch">
                <Typography variant="bodyLarge">
                  {translations.generalSettings.widgetIsActive[lang]}
                </Typography>
                <Switch
                  id="widget-status-switch"
                  color="secondary"
                  checked={widgetStatus}
                  onChange={() => setWidgetStatus(!widgetStatus)}
                />
              </label>

              <SimpleDivider style={{ marginTop: '16px', marginBottom: '16px' }} />
              <Typography variant="titleLarge">
                {translations.generalSettings.consentTexts[lang]}
              </Typography>
              <div className="consent-text-block">
                <div className="title-section">
                  <span>
                    <label htmlFor="upload-consent-text" className="title-section">
                      <i className="icon">verified_user</i>
                      <Typography variant="bodyLarge">
                        {translations.generalSettings.generalConsentTexts[lang]}
                      </Typography>
                    </label>
                  </span>
                  {basicOrgData.premiumFeatures?.editableConsentTexts && (
                    <Button
                      color="secondary"
                      onClick={() => {
                        if (consentRef?.current?.clientHeight) {
                          setConsentHeight(consentRef?.current?.clientHeight);
                        }
                        setShowUploadConsentTextPreview(!showUploadConsentTextPreview);
                      }}
                    >
                      {showUploadConsentTextPreview
                        ? translations.generalSettings.consentTextsHidePreview[lang]
                        : translations.generalSettings.consentTextsShowPreview[lang]}
                    </Button>
                  )}
                  <Typography variant="labelSmall" color="error">
                    {!basicOrgData.premiumFeatures?.editableConsentTexts &&
                      translations.premiumFeature[lang]}
                  </Typography>
                </div>

                {!showUploadConsentTextPreview ? (
                  <textarea
                    id="upload-consent-text"
                    ref={consentRef}
                    disabled={!basicOrgData.premiumFeatures?.editableConsentTexts}
                    onChange={(e) => setUploadConsentText(e.target.value)}
                    value={uploadConsentText}
                    style={{
                      height: `${consentHeight}px`,
                    }}
                  />
                ) : (
                  <div
                    className="preview-box"
                    style={{
                      height: `${consentHeight}px`,
                    }}
                  >
                    <label className="terms" htmlFor="upload-consent-text-preview">
                      <input
                        id="upload-consent-text-preview"
                        type="checkbox"
                        aria-label={translations.generalSettings.consentTextAriaLabel[lang]}
                        required
                        name="upload_consent"
                        value="false"
                      />
                      {parse(uploadConsentText?.trim()?.replace(/\n/g, '<br />'))}
                      <b style={{ color: 'red' }}>*</b>
                    </label>
                  </div>
                )}
              </div>

              <div className="consent-text-block">
                <div style={{ paddingBottom: '20px' }}>
                  <label className="label-title-with-dscr" htmlFor="soMe-consent-enable-switch">
                    <input
                      id="soMe-consent-enable-switch"
                      type="checkbox"
                      checked={enableSoMeConsent}
                      onChange={() => setEnableSoMeConsent(!enableSoMeConsent)}
                    />
                    <Typography variant="bodyLarge">
                      {translations.generalSettings.soMeConsentEnableLabel[lang]}
                    </Typography>
                  </label>
                  <Typography variant="bodySmall">
                    {translations.generalSettings.soMeConsentEnableDescription[lang]}
                  </Typography>
                </div>

                <div className="title-section">
                  <span>
                    <label htmlFor="soMe-consent-text">
                      <i className="icon">fact_check</i>{' '}
                      <Typography variant="bodyLarge">
                        {translations.generalSettings.soMeConsentTexts[lang]}
                      </Typography>
                    </label>
                  </span>
                  {basicOrgData.premiumFeatures?.editableConsentTexts && (
                    <Button
                      color="secondary"
                      disabled={
                        !basicOrgData.premiumFeatures?.editableConsentTexts || !enableSoMeConsent
                      }
                      onClick={() => {
                        if (soMeConsentRef?.current?.clientHeight) {
                          setSoMeConsentHeight(soMeConsentRef?.current?.clientHeight);
                        }
                        setShowSoMeConsentTextPreview(!showSoMeConsentTextPreview);
                      }}
                    >
                      {showSoMeConsentTextPreview
                        ? translations.generalSettings.consentTextsHidePreview[lang]
                        : translations.generalSettings.consentTextsShowPreview[lang]}
                    </Button>
                  )}
                  <Typography variant="labelSmall">
                    {!basicOrgData.premiumFeatures?.editableConsentTexts &&
                      translations.premiumFeature[lang]}
                  </Typography>
                </div>
                {!showSoMeConsentTextPreview ? (
                  <textarea
                    id="soMe-consent-text"
                    ref={soMeConsentRef}
                    disabled={
                      !basicOrgData.premiumFeatures?.editableConsentTexts || !enableSoMeConsent
                    }
                    onChange={(e) => setSoMeConsentText(e.target.value)}
                    value={soMeConsentText}
                    style={{
                      height: `${soMeConsentHeight}px`,
                    }}
                  />
                ) : (
                  <div
                    className="preview-box"
                    style={{
                      height: `${soMeConsentHeight}px`,
                    }}
                  >
                    <label className="terms" htmlFor="upload-some-consent-text-preview">
                      <input
                        type="checkbox"
                        id="upload-some-consent-text-preview"
                        aria-label={translations.generalSettings.consentTextAriaLabel[lang]}
                        disabled={
                          !basicOrgData.premiumFeatures?.editableConsentTexts || !enableSoMeConsent
                        }
                        required
                        name="upload_consent"
                        value="false"
                      />
                      {parse(soMeConsentText?.trim()?.replace(/\n/g, '<br />'))}
                      {soMeConsentMandatory && <b style={{ color: 'red' }}>*</b>}
                    </label>
                  </div>
                )}
                <div style={{ paddingLeft: '20px', paddingBottom: '20px' }}>
                  <label className="label-title-with-dscr" htmlFor="soMe-consent-mandatory-switch">
                    <input
                      id="soMe-consent-mandatory-switch"
                      disabled={
                        !basicOrgData.premiumFeatures?.editableConsentTexts || !enableSoMeConsent
                      }
                      type="checkbox"
                      checked={soMeConsentMandatory}
                      onChange={() => setSoMeConsentMandatory(!soMeConsentMandatory)}
                    />
                    <Typography variant="bodyLarge">
                      {translations.generalSettings.soMeConsentMandatory[lang]}
                    </Typography>
                  </label>
                  <Typography variant="bodySmall">
                    {translations.generalSettings.soMeConsentMandatoryDescription[lang]}
                  </Typography>
                </div>
              </div>
              <SimpleDivider style={{ paddingTop: '16px', marginBottom: '8px' }} />

              <label htmlFor="do-not-track-switch">
                <input
                  id="do-not-track-switch"
                  type="checkbox"
                  checked={disallowSuperadminBtn}
                  onChange={() => setDisallowSuperadminBtn(!disallowSuperadminBtn)}
                />
                <Typography variant="bodyLarge">
                  {translations.generalSettings.disallowSuperadmins[lang]}
                </Typography>
              </label>
              {!storylineOverride && (
                <div style={{ paddingTop: '0px' }}>
                  <label className="label-title-with-dscr" htmlFor="storyline-override-switch">
                    <input
                      id="storyline-override-switch"
                      type="checkbox"
                      checked={storylineOverride}
                      onChange={() => setStorylineOverride(!storylineOverride)}
                    />
                    <Typography variant="bodySmall">
                      {translations.generalSettings.storylineOverride[lang]}
                    </Typography>
                  </label>
                  <Typography variant="bodySmall">
                    {translations.generalSettings.storylineOverrideInfo[lang]}
                  </Typography>
                </div>
              )}
              <br />
            </div>
            <div className="btn-row">
              <Button variant="contained" color="primary" disableElevation onClick={saveChanges}>
                {translations.save[lang]}
              </Button>
            </div>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}

export default GeneralSettings;
