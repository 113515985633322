import { useState, useEffect, RefObject } from 'react';

const useIntersectionObserver = (ref: RefObject<HTMLElement>, visibilityThreshold: number) => {
  const rootMargin = `${visibilityThreshold}px`;
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting || entry?.boundingClientRect?.top <= visibilityThreshold) {
          setIsIntersecting(true);
          if (currentRef) {
            observer.unobserve(currentRef);
          }
        }
      },
      {
        rootMargin,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, visibilityThreshold, rootMargin]);

  if (!ref) {
    return false;
  }

  return isIntersecting;
};

export default useIntersectionObserver;
