// src/hooks/useOrgUsers.ts
import { useQuery } from '@apollo/client';
import { User } from './types';
import { GET_ORG_USERS } from './queries';

interface UseOrgUsersParams {
  encodedOrgId: string;
  userDataExists: boolean;
}

function useOrgUsers({ encodedOrgId, userDataExists }: UseOrgUsersParams) {
  const { data, loading, error, refetch } = useQuery<{ getOrgUsers: User[] }>(GET_ORG_USERS, {
    variables: { encodedOrgId },
    skip: !userDataExists,
  });

  return {
    orgUsers: data?.getOrgUsers,
    loading,
    error,
    refetch,
  };
}

export default useOrgUsers;
