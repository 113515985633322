import { getVideoLink, VideoCardStatus } from 'src/utils/video-card/utils';
import { VideoCardDetailed } from '../../types';

const filterValidVideoCards = (
  videoCards: VideoCardDetailed[] | undefined,
  statuses?: VideoCardStatus[] | null,
): VideoCardDetailed[] => {
  return (
    videoCards?.filter(
      (video: VideoCardDetailed) =>
        !!getVideoLink(video) &&
        (!statuses || statuses.length === 0 || statuses.includes(video.status)),
    ) || []
  );
};

export default filterValidVideoCards;
