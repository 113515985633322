import React, { useState, useMemo, useCallback } from 'react';
import { Backdrop, Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import VideoTablePagination from 'src/components/common/TablePagination';
import VideoRow from './VideoRow';
import VideoTableHead from './VideoTableHead';

import { EnhancedVideoCardType, Order, VideosProps } from './types';
import useFetchVideos from './useFetchVideos';

function Videos({
  lang,
  encodedOrgId,
  selectedDuration,
  dates = { startDate: null, endDate: null },
  videosData,
  setVideosData,
}: VideosProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof EnhancedVideoCardType>('totalImpressions');

  const { loading, error } = useFetchVideos(encodedOrgId, selectedDuration, dates, setVideosData);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof EnhancedVideoCardType) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const sortedData = useMemo(() => {
    if (!videosData) return [];

    return [...videosData].sort((a, b) => {
      const aValue = orderBy === 'question' ? a?.question?.[lang] ?? '' : a[orderBy] ?? 0;
      const bValue = orderBy === 'question' ? b?.question?.[lang] ?? '' : b[orderBy] ?? 0;

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [order, orderBy, videosData, lang]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [sortedData, page, rowsPerPage]);

  if (error || !videosData || videosData.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={{ p: 0, pt: 4, position: 'relative' }} data-testid="videos-box">
      <Backdrop
        open={loading}
        style={{ opacity: 0.2 }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      />
      {loading && (
        <Box
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <LoadingIndicator backgroundColor="transparent" />
        </Box>
      )}
      <Paper elevation={0} data-testid="videos-paper" key="videos-paper">
        <TableContainer data-testid="videos-table-container">
          <Table aria-label="videos table" data-testid="videos-table">
            <VideoTableHead
              order={order}
              orderBy={orderBy as keyof EnhancedVideoCardType}
              onRequestSort={handleRequestSort}
              lang={lang}
            />
            <TableBody>
              {paginatedData.map((video) => (
                <VideoRow key={`${video.id}`} video={video} lang={lang} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <VideoTablePagination
          count={videosData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          lang={lang}
        />
      </Paper>
    </Box>
  );
}

export default Videos;
