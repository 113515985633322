import PropTypes from 'prop-types';

function ReviewOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 13.9999H9.05L14.05 8.99988C14.2 8.84988 14.3127 8.67888 14.388 8.48688C14.4633 8.29488 14.5007 8.10755 14.5 7.92488C14.5 7.74155 14.4583 7.56255 14.375 7.38788C14.2917 7.21321 14.1833 7.05055 14.05 6.89988L13.15 5.94988C13 5.79988 12.8333 5.68721 12.65 5.61188C12.4667 5.53655 12.275 5.49921 12.075 5.49988C11.8917 5.49988 11.7043 5.53755 11.513 5.61288C11.3217 5.68821 11.1507 5.80055 11 5.94988L6 10.9499V13.9999ZM7.5 12.4999V11.5499L10.025 9.02488L10.525 9.47488L10.975 9.97488L8.45 12.4999H7.5ZM10.525 9.47488L10.975 9.97488L10.025 9.02488L10.525 9.47488ZM11.175 13.9999H18V11.9999H13.175L11.175 13.9999ZM2 21.9999V3.99988C2 3.44988 2.196 2.97888 2.588 2.58688C2.98 2.19488 3.45067 1.99921 4 1.99988H20C20.55 1.99988 21.021 2.19588 21.413 2.58788C21.805 2.97988 22.0007 3.45055 22 3.99988V15.9999C22 16.5499 21.804 17.0209 21.412 17.4129C21.02 17.8049 20.5493 18.0005 20 17.9999H6L2 21.9999ZM5.15 15.9999H20V3.99988H4V17.1249L5.15 15.9999Z"
        fill={fill}
      />
    </svg>
  );
}

export default ReviewOutlinedIcon;

ReviewOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
