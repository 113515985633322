import PropTypes from 'prop-types';

function AddOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill={fill} />
    </svg>
  );
}

export default AddOutlinedIcon;
AddOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
