const videoCollectorTranslations = {
  deleteConfirm: {
    en: 'Delete',
    sv: 'Radera',
  },
  noCancel: {
    en: 'No, Cancel',
    sv: 'Nej, Avbryt',
  },
  areYouSureDelete: {
    en: 'Are you sure you want to delete this video collector?',
    sv: 'Är du säker på att du vill radera denna video collector?',
  },
  deleteItem: {
    en: 'Delete Video Collector',
    sv: 'Radera Video Collector',
  },
  previewDialogTitle: {
    en: 'Save changes before preview?',
    sv: 'Spara ändringar innan förhandsgranskning?',
  },
  previewDialogBody: {
    en: 'You have unsaved changes that will not be visible when previewing. Do you want to save your changes first before opening the preview?',
    sv: 'Du har gjort ändringar som inte sparats än. Vill du spara dessa innan du öppnar förhandsgranskningen?',
  },
  previewDialogConfirmSaveAndPreview: {
    en: 'Save & Preview',
    sv: 'Spara & Förhandsgranska',
  },
  qrCode: {
    en: 'QR Code',
    sv: 'QR-kod',
  },
  settings: {
    en: 'Settings',
    sv: 'Inställningar',
  },
  uploaderSettings: {
    en: 'User Information Preferences',
    sv: 'Användarinformation inställningar',
  },
  restrictQuestion: {
    en: 'Restrict to Pre-set Titles/Questions',
    sv: 'Begränsa till förinställda titlar/frågor',
  },
  restrictQuestionTooltip: {
    en: 'Enabling this will require uploaders to select from provided video titles or questions, preventing the entry of custom responses.',
    sv: 'Om detta är aktiverat kommer uppladdare att behöva välja från tillhandahållna videotitlar eller frågor, vilket förhindrar inmatning av anpassade svar.',
  },
  allowVideoLibrary: {
    en: 'Show library on upload page',
    sv: 'Visa biblioteket på uppladdningssidan',
  },
  allowVideoLibraryTooltip: {
    en: 'If enabled, this specific there will be a video library on the upload page, allowing visitors to view other videos. If disabled, the page will be hidden, making it inaccessible to visitors.',
    sv: 'Om aktiverat, kommer det att finnas ett videobibliotek på uppladdningssidan, vilket gör det möjligt för besökare att se andra videos. Om inaktiverat, kommer sidan att vara dold, vilket gör den otillgänglig för besökare.',
  },
  allowVideoLibraryReview: {
    en: 'Show not reviewed videos on upload page',
    sv: 'Visa ej granskade videos på uppladdningssidan',
  },
  allowVideoLibraryReviewTooltip: {
    en: 'If enabled, videos will be immediately displayed on this specific upload page upon upload, without needing admin review. If disabled, videos will be hidden until an admin approves them.',
    sv: 'Om aktiverat, kommer videor att visas direkt på denna specifika uppladdningssida vid uppladdning, utan att behöva admin granskning. Om inaktiverat, kommer videor att vara dolda tills en admin godkänner dem.',
  },
  password: {
    en: 'Password',
    sv: 'Lösenord',
  },
  emailRequiresSaveEmailTooltip: {
    en: 'Requires that the setting for saving email of the user is enabled.',
    sv: 'Kräver att inställningen för att spara användarens email är påslagen.',
  },
  thankYouEmailContent: {
    en: 'Email sent on upload',
    sv: 'Mail efter uppladdning',
  },
  thankYouEmailContentTooltip: {
    en: 'Email sent to the user after they have uploaded a video.',
    sv: 'Email som skickas till användaren efter att den har laddat upp en video.',
  },
  thankYouEmailDefaultContent: {
    en: 'Thank you for your video! The company admins will now review the video. You will be notified as soon as it has been approved 😀',
    sv: 'Tack för din video! Företagets administratörer kommer nu att granska videon. Du kommer få en notis så snart den blivit godkänd 😀',
  },
  approvedEmailContent: {
    en: 'Email sent on approval',
    sv: 'Mail efter godkännande',
  },
  approvedEmailContentTooltip: {
    en: 'Email sent to the user after their video has been approved. Great place for a promo code!',
    sv: 'Email som skickas till användaren efter att dess video blivit godkänd. Perfekt ställe för en promo-kod!',
  },
  approvedEmailDefaultContent: {
    en: 'Thanks again, your video was just approved! ⭐',
    sv: 'Tack igen för din video, din video har precis godkänts! ⭐',
  },
  endDate: {
    en: 'End Date',
    sv: 'Slutdatum',
  },
  startDate: {
    en: 'Start Date',
    sv: 'Startdatum',
  },
  videoQuestionsTitles: {
    en: 'Video Questions/Titles',
    sv: 'Video Frågor/Titlar',
  },
  description: {
    en: 'Description',
    sv: 'Beskrivning',
  },
  enabled: {
    en: 'Enabled',
    sv: 'Aktiverad',
  },
  required: {
    en: 'Required',
    sv: 'Måste fyllas i',
  },
  name: {
    en: 'Name',
    sv: 'Namn',
  },
  title: {
    en: 'Title',
    sv: 'Titel',
  },
  email: {
    en: 'Email',
    sv: 'Email',
  },
  phone: {
    en: 'Phone',
    sv: 'Telefon',
  },
  backButton: {
    en: 'Navigate back',
    sv: 'Navigera tillbaka',
  },
  save: {
    en: 'Save',
    sv: 'Spara',
  },
  activate: {
    en: 'Activate',
    sv: 'Aktivera',
  },
  deactivate: {
    en: 'Deactivate',
    sv: 'Avaktivera',
  },
  preview: {
    en: 'Preview',
    sv: 'Förhandsgranska',
  },
  selected: {
    en: (count: number) => (count === 1 ? 'Selected' : `Selected`),
    sv: (count: number) => (count === 1 ? 'Vald' : `Valda`),
  },
  selectAll: {
    en: 'Select All',
    sv: 'Välj Alla',
  },
  deselectAll: {
    en: 'Deselect All',
    sv: 'Avmarkera Alla',
  },
  delete: {
    en: 'Delete',
    sv: 'Radera',
  },
  downloadQRCode: {
    en: 'Download QR Code',
    sv: 'Ladda ner QR-kod',
  },
  review: {
    en: 'Review',
    sv: 'Granska',
  },
  videos: {
    en: (count: number) => (count === 1 ? 'Video' : `Videos`),
    sv: (count: number) => (count === 1 ? 'Video' : `Videos`),
  },
  processing: {
    en: 'processing',
    sv: 'behandlas',
  },
  newVideoCollector: {
    en: 'New Collector',
    sv: 'Ny Collector',
  },
  videoCollector: {
    en: 'Video Collector',
    sv: 'Video Collector',
  },
  videoCollectorSearchField: {
    en: 'Search',
    sv: 'Sök',
  },
  moreVideos: {
    en: 'More Videos',
    sv: 'Fler Videor',
  },
  responsible: {
    en: 'Responsible',
    sv: 'Ansvarig',
  },
  responsibleTooltip: {
    en: 'The responsible user receives emails related to this VideoCollector, e.g. when a new video is uploaded.',
    sv: 'Den ansvariga användaren får email om aktivtet på denna VideoCollector, tex. när en ny video laddas upp.',
  },
};

export default videoCollectorTranslations;
