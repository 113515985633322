import parse from 'html-react-parser';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

function UpdateCard({ updateCard }) {
  return (
    <Card
      sx={{
        position: 'relative',
      }}
    >
      <CardHeader
        title={parse(
          (decodeURIComponent(updateCard.title) ?? updateCard.title ?? '')
            ?.trim()
            ?.replace(/\n/g, '<br />'),
        )}
        style={{
          paddingBottom: updateCard?.description ? '10px' : '25px',
          paddingLeft: '25px',
        }}
        titleTypographyProps={{
          variant: 'titleMedium',
        }}
        subheaderTypographyProps={{
          variant: 'bodyMedium',
        }}
      />
      {updateCard?.description && (
        <CardContent
          style={{
            paddingTop: '0px',
            paddingBottom: '24px',
            paddingLeft: '24px',
          }}
        >
          <Typography variant="bodyMedium">
            {parse(
              (decodeURIComponent(updateCard?.description) ?? updateCard?.description ?? '')
                ?.trim()
                ?.replace(/\n/g, '<br />'),
            )}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}

UpdateCard.propTypes = {
  updateCard: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default UpdateCard;
