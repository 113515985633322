import { gql, useQuery } from '@apollo/client';
import LoadingIndicator from '../common/LoadingIndicator';

type AdminUserData = {
  id: number;
  superAdmin: boolean;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  jobTitle: {
    en: string | null;
    sv: string | null;
  };
};

interface SuperAdminsQueryResult {
  superAdmins: AdminUserData[];
}

const SUPER_ADMINS_QUERY = gql`
  query SuperAdmins {
    superAdmins {
      id
      superAdmin
      email
      firstName
      lastName
      phoneNumber
      jobTitle {
        en
        sv
      }
    }
  }
`;

function SuperAdmins() {
  const { data, loading } = useQuery<SuperAdminsQueryResult>(SUPER_ADMINS_QUERY);
  const users = data?.superAdmins ?? [];

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <h1>All super admins</h1>
      <div>
        {users.map((u) => {
          return (
            <div key={u.id}>
              <ul>
                <li>Id: {u.id}</li>
                <li>firstName: {u.firstName}</li>
                <li>lastName: {u.lastName}</li>
                <li>Email: {u.email}</li>
                <li>PhoneNumber: {u.phoneNumber}</li>
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SuperAdmins;
