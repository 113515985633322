/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

import { VIDEO_CARD_DETAILS_FRAGMENT } from './videoCards';

const CALL_TO_ACTION_DETAIL_FRAGMENT = gql`
  fragment CallToActionDetails on CallToAction {
    title
    type
    msgString
    url
    btnTextString
    btnText {
      sv
      en
    }
    msg {
      sv
      en
    }
  }
`;

const CONTACT_FORM_DETAIL_FRAGMENT = gql`
  fragment ContactFormDetails on ContactForm {
    title
    msgString
    confirmationMsgString
    email
    type
    btnTextString
    submitBtnTextString
    btnText {
      sv
      en
    }
    confirmationMsg {
      sv
      en
    }
    msg {
      sv
      en
    }
    disableThankYouEmail
  }
`;

const END_SCREEN_VIDEO_LIBRARY_DETAIL_FRAGMENT = gql`
  ${VIDEO_CARD_DETAILS_FRAGMENT}
  fragment EndScreenVideoLibraryDetails on EndScreenVideoLibrary {
    title
    btnTextString
    videoCards {
      ...VideoCardDetails
    }
  }
`;

export const END_SCREEN_DETAIL_FRAGMENT = gql`
  ${CALL_TO_ACTION_DETAIL_FRAGMENT}
  ${CONTACT_FORM_DETAIL_FRAGMENT}
  ${END_SCREEN_VIDEO_LIBRARY_DETAIL_FRAGMENT}
  fragment EndScreenDetails on EndScreen {
    id
    startDate
    endDate
    orgId
    user {
      id
      firstName
      lastName
    }
    userId
    created
    updated
    status
    meta {
      title
      ctaTimingStart
      ctaTimingEnd
      endScreenType
      endScreen {
        __typename
        ... on ContactForm {
          ...ContactFormDetails
        }
        ... on CallToAction {
          ...CallToActionDetails
        }
        ... on EndScreenVideoLibrary {
          ...EndScreenVideoLibraryDetails
        }
      }
    }
  }
`;
