import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useBackOfficeStatsApolloClient } from 'src/context/ApolloClientContext';
import { useMemo } from 'react';
import {
  StatsByTypeTotalResult,
  StatsConfig,
  generateStatsQueryPart,
} from '../Analytics/Overview/analyticsOverviewApi';

export interface StoryIdentifier {
  storylineId?: number;
  videoFunnelId?: number;
}

export interface FetchStoryAnalyticsDataParams {
  identifier: StoryIdentifier;
  encodedOrgId: string;
}

export interface StoryAnalyticsDataTotal {
  identifier: StoryIdentifier;
  totalStats: StatsByTypeTotalResult;
}

export class StoryStatsApi {
  #backOfficeStatsClient: ApolloClient<NormalizedCacheObject>;

  constructor(backOfficeStatsClient: ApolloClient<NormalizedCacheObject>) {
    this.#backOfficeStatsClient = backOfficeStatsClient;
  }

  async fetchStoryAnalyticsData({
    identifier,
    encodedOrgId,
  }: FetchStoryAnalyticsDataParams): Promise<StoryAnalyticsDataTotal | null> {
    const statsConfig: StatsConfig = {
      playback: ['impression', 'timePlayed'],
      interactions: ['total'],
    };
    const statsPart = generateStatsQueryPart(statsConfig, false);

    const query = gql`
      query storyAnalyticsDataTotal($encodedOrgId: String!, $identifier: StoryIdentifierInput!) {
        storyAnalyticsDataTotal(encodedOrgId: $encodedOrgId, identifier: $identifier) {
          identifier {
            videoFunnelId
            storylineId
          }
          totalStats {
            ${statsPart}
          }
        }
      }
    `;

    const result = await this.#backOfficeStatsClient.query({
      query,
      variables: { encodedOrgId, identifier },
    });

    return result.data.storyAnalyticsDataTotal[0];
  }
}

export const useStoryStatsApi = (): StoryStatsApi => {
  const backOfficeStatsClient = useBackOfficeStatsApolloClient();
  return useMemo(() => new StoryStatsApi(backOfficeStatsClient), [backOfficeStatsClient]);
};
