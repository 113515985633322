import React, { useContext } from 'react';

import Typography from '@mui/material/Typography';
import ThemeContext from 'src/utils/ThemeContext';

function CopyrightText() {
  const theme = useContext(ThemeContext);
  return (
    <Typography
      variant="bodySmall"
      color={theme.palette.text.light}
      align="center"
      sx={{
        display: 'block',
      }}
      component="p"
      role="paragraph"
    >
      © {new Date().getFullYear()} Life Inside
    </Typography>
  );
}

export default CopyrightText;
