/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useContext } from 'react';
import Box from '@mui/material/Box';

// import Button from '@mui/material/Button';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CloseOutlined } from '@mui/icons-material';
import ThemeContext from 'src/utils/ThemeContext';
import { useLanguage } from 'src/context/LanguageContext';
import translationsPublicVideoCollector from './translations';

interface VideoUploadInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function VideoUploadInput({ onChange }: VideoUploadInputProps) {
  const lang = useLanguage();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [blur, setBlur] = useState(false);

  const [valid, setValid] = useState(false);

  const theme = useContext(ThemeContext);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<number | null>(null);

  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileSelected(true);
      setVideoPreviewUrl(URL.createObjectURL(file));
      setFileName(file.name);
      setFileSize(file.size);
      onChange(event);
      if (file.size > 1) {
        setValid(true);
      }
    } else {
      setFileSelected(false);
      setVideoPreviewUrl(null);
      setFileName(null);
      setFileSize(null);
      setValid(false);
    }
  };

  const handleIconButtonClick = () => {
    setBlur(true);
    if (inputFileRef?.current && inputFileRef) {
      inputFileRef.current.click();
    }
  };

  const deselectVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setFileSelected(false);
    setVideoPreviewUrl(null);
    setFileName(null);
    setFileSize(null);
    setValid(false);
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  return (
    <Box>
      <label htmlFor="file">
        <Box
          sx={{
            p: 0,
            m: 0,
            width: '100%',
            height: '100%',
            minHeight: '190px',
            borderRadius: 1,
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.default',
          }}
        >
          {videoPreviewUrl && (
            <Box sx={{ display: 'contents' }}>
              <Box sx={{ mt: 2, position: 'relative' }}>
                <video
                  src={videoPreviewUrl}
                  controls={false}
                  style={{ maxWidth: '400px', maxHeight: '134px', borderRadius: '8px' }}
                />

                <Button
                  onClick={(e) => deselectVideo(e)}
                  component="button"
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: -16,
                    right: -16,
                    backgroundColor: 'transparent',
                    p: 0.25,
                    m: 0,
                    minWidth: 'unset',
                  }}
                  aria-label="close video"
                >
                  <CloseOutlined />
                </Button>
              </Box>
              {fileName && fileSize && (
                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                  {`${fileName} (${(fileSize / 1024 / 1024).toFixed(2)} MB)`}
                </Typography>
              )}
            </Box>
          )}
          {!fileSelected && (
            <Button
              onClick={handleIconButtonClick}
              component="label"
              sx={{
                p: 0,
                width: '100%',
                height: '100%',
                minHeight: '190px',
                borderRadius: 1,
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                m: 0,
                bgcolor: 'background.default',
                border: blur && !valid ? `1px solid ${theme.palette.error.main}` : 'transparent',
                '&:hover': {
                  border: `1px solid ${
                    blur && !valid ? theme.palette.error.main : theme.palette.primary.main
                  }`,
                },
              }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              <img src="/images/icons/film-icon.svg" alt="" />
              <Typography variant="titleMedium" gutterBottom sx={{ mt: 2 }}>
                {translationsPublicVideoCollector.SelectVideo[lang]}
              </Typography>
            </Button>
          )}
        </Box>
      </label>
      <input
        ref={inputFileRef}
        onChange={handleFileChange}
        type="file"
        accept="video/*"
        name="file_data"
        id="file"
        required
        capture={false}
        style={{ display: 'none' }}
      />
    </Box>
  );
}

export default VideoUploadInput;
