import React, { useState, useEffect, useContext } from 'react';
import LazyLoad from 'react-lazy-load';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import { pink } from '../../common/SharedStylingSettings';
import { useVideoFunnelsApi } from '../../../api/videoFunnels';
import { storylineStatuses } from '../../../utils/utils';
import { useApiUtilsStoryline } from '../../../utils/api-utils-storyline';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import StorylineCardHeader from './StorylineCardHeader';

import ThemeContext from '../../../utils/ThemeContext';
// import EmptyStoryline from '../../common/icons/emptyStoryline/EmptyStoryline';
import VideoFunnelOutlinedIcon from '../../common/icons/videoFunnel/VideoFunnelOutlined';
import StorylineOutlinedIcon from '../../common/icons/storyline/StorylineOutlined';
import StoryMedia from '../StoryMedia';

export default function StorylineCard({
  storyline,
  handleSelected,
  // cardHeight,
  cardWidth,
  basicOrgData,
  affectedItem = false,
}) {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const apiUtilsStoryline = useApiUtilsStoryline();
  const videoFunnelsApi = useVideoFunnelsApi();
  const storylineIsActive = storyline?.status === storylineStatuses.LIVE;

  const [storylineData, setStorylineData] = useState(storyline);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (
        !storylineData?.lastPullFromDatabase ||
        (storylineData?.lastPullFromDatabase &&
          Math.floor(Math.abs(new Date() - storylineData.lastPullFromDatabase) / 1000) > 120)
      ) {
        if (storyline?.type === 'videoFunnel') {
          videoFunnelsApi
            .fetchVideoFunnel({
              id: storylineData.id,
              encodedOrgId: basicOrgData.encodedId,
              orgId: basicOrgData.id,
            })
            .then((res) => {
              if (isMounted) {
                setStorylineData({
                  ...storylineData,
                  ...res.data.fetchVideoFunnel,
                  lastPullFromDatabase: new Date(),
                });
              }
            })

            .catch(() => {});
        } else {
          apiUtilsStoryline
            .getVideoCardsByStorylineId({
              encodedOrgId: basicOrgData.encodedId,
              storylineId: storylineData.id,
              statuses: [],
            })
            .then((res) => {
              if (isMounted) {
                setStorylineData({
                  ...storylineData,
                  lastPullFromDatabase: new Date(),
                  videoCards: res.data.videoCardsByStorylineIdAndTags.videoCards,
                });
              }
            })
            .catch(() => {});
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    apiUtilsStoryline,
    basicOrgData.encodedId,
    basicOrgData.id,
    storyline?.type,
    storylineData,
    videoFunnelsApi,
  ]);

  const numVideos = () => {
    if (storyline?.type === 'videoFunnel') {
      const videoNodes = storylineData?.videoFunnelNodes?.filter(
        (node) => !!node?.data?.videoCard?.id,
      );
      return videoNodes?.length ?? 0;
    }
    if (storylineData?.videoCards?.length > 0) {
      return storylineData?.videoCards?.length;
    }
    return 0;
  };

  // function handleImageError(e) {
  //   e.target.style.display = 'none';
  // }

  return (
    <Card
      elevation={0}
      sx={{
        width: `${cardWidth}px`,
        height: '100%',
        p: 0,
        borderRadius: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
        display: 'flex',
        transition: 'transform 0.2s ease-in-out',
        tabIndex: 1,
        ':hover': {
          transform: 'scale(1.03)',
          cursor: 'pointer',
        },
      }}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSelected(storyline);
        }
      }}
      onClick={() => handleSelected(storyline)}
      style={{
        ...(affectedItem
          ? {
              border: `0.5px solid ${pink}`,
              boxShadow: `0px 0px 2px 0px ${pink}`,
            }
          : {}),
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <StorylineCardHeader
            background={
              storylineIsActive ? theme.palette.secondary.main : theme.palette.text.secondary
            }
            circle={
              storylineIsActive ? theme.palette.background.papper : theme.palette.background.default
            }
          />
        </Box>
        <LazyLoad offset={600} width={cardWidth} height={cardWidth * (10 / 16)}>
          <StoryMedia
            cardWidth={cardWidth}
            isActive={storylineIsActive}
            srcImage={
              storyline?.urlsJsonb?.filter((urlObj) => !!urlObj?.srcImage)?.[0]?.srcImage ||
              undefined
            }
          />
        </LazyLoad>
        {/* <LazyLoad offset={600} width={cardWidth} height={cardWidth * (10 / 16)}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 1,
                width: cardWidth,
                height: `${cardWidth * (10 / 16)}px`,
                overflow: 'hidden',
              }}
            >
              <EmptyStoryline
                mountainFill={
                  storylineIsActive ? theme.palette.secondary.main : theme.palette.divider
                }
                circleFill={storylineIsActive ? theme.palette.tertiary.main : theme.palette.divider}
                backgroundFill={
                  storylineIsActive ? theme.palette.divider : theme.palette.background.default
                }
              />
            </Box>
            <CardMedia
              component="img"
              height={`${cardWidth * (10 / 16)}px`}
              width={`${cardWidth}px`}
              image={
                storyline?.urlsJsonb?.filter((urlObj) => !!urlObj?.srcImage)?.[0]?.srcImage || ''
              }
              onError={(e) => handleImageError(e)}
              alt=""
              sx={{
                position: 'relative',
                zIndex: 2,
              }}
            />
          </Box>
        </LazyLoad> */}
        <CardContent
          sx={{
            p: 1,
            ':last-child': {
              paddingBottom: 1,
            },
          }}
        >
          <Grid container spacing={0} direction="row" alignItems="top" justifyContent="center">
            <Grid item lg={11} sm={11} md={11} xl={11} xs={11}>
              <Typography variant="titleMedium" color="text.primary">
                {storyline?.title ?? ''}
              </Typography>
            </Grid>
            <Grid item lg={1} sm={1} md={1} xl={1} xs={1}>
              {storyline?.type === 'videoFunnel' ? (
                <Tooltip
                  title={translationsStoryline.videoFunnel.title[lang]}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -20],
                        },
                      },
                    ],
                  }}
                >
                  <Box>
                    <VideoFunnelOutlinedIcon
                      fill={
                        storylineIsActive
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary
                      }
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={translationsStoryline.storyline?.[lang]}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -20],
                        },
                      },
                    ],
                  }}
                >
                  <Box>
                    <StorylineOutlinedIcon
                      fill={
                        storylineIsActive
                          ? theme.palette.secondary.main
                          : theme.palette.text.secondary
                      }
                    />
                  </Box>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          {numVideos() > 0 && (
            <Typography variant="bodyMedium">
              {` 
                ${numVideos()}
                ${
                  numVideos() > 1
                    ? translationsStoryline.videos[lang]
                    : translationsStoryline.video[lang]
                }`}
            </Typography>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}

StorylineCard.propTypes = {
  storyline: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    urlsJsonb: PropTypes.arrayOf(
      PropTypes.shape({
        srcImage: PropTypes.string,
      }),
    ),
  }).isRequired,
  handleSelected: PropTypes.func.isRequired,
  cardWidth: PropTypes.number.isRequired,
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  affectedItem: PropTypes.bool,
};
