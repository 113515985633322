import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import {
  Autocomplete,
  TextField,
  TextareaAutosize,
  Typography,
  Box,
  Tooltip,
  Stack,
  Checkbox,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import { useQuery, gql } from '@apollo/client';
import { uniqBy, unionWith } from 'lodash';
import { isAfter, parseISO } from 'date-fns';

import { useLanguage } from 'src/context/LanguageContext';
import { useCurrentUser } from 'src/context/CurrentUserContext';
import HelpIcon from '../../../common/icons/help/HelpIcon';
import videoCollectorTranslations from '../VideoCollectorTranslations';
import QRCodeComponent from '../../../common/QRCodeComponent';
import { LocalQuestion, User, VideoCollectorDetailed, VideoCollectorQuestion } from '../types';
import { publicPath } from '../paths';
import UserSavePreferencesInput from './UserSavePreferencesInput';
import { useOnFieldChange } from './useOnFieldChange';

// TempQuestion is a question that is being entered, it turns into a LocalQuestion
// as the user finishes entering the question.
type TempQuestion = string;
type QuestionInput = VideoCollectorQuestion | TempQuestion;

const questionsEqual = (questionA: QuestionInput, questionB: QuestionInput) => {
  if (typeof questionA === 'string' && typeof questionB === 'string') {
    // TempQuestion.
    return questionA === questionB;
  }
  if (typeof questionA === 'object' && typeof questionB === 'object') {
    // VideoCollectorQuestion.
    return questionA.__typename === questionB.__typename && questionA.id === questionB.id;
  }
  return false;
};

function isTempQuestion(question: QuestionInput): question is TempQuestion {
  return typeof question === 'string';
}

let localQuestionIdCount = 0;
const tempToLocalQuestion = (question: TempQuestion) => {
  // eslint-disable-next-line no-plusplus
  const id = --localQuestionIdCount;
  const localQuestion: LocalQuestion = {
    __typename: 'LocalQuestion',
    id,
    question,
  };
  return localQuestion;
};

const isStartDateAfterEndDate = (startDate: string | null, endDate: string | null) => {
  if (startDate == null || endDate == null || startDate === endDate) {
    return false;
  }
  return isAfter(parseISO(startDate), parseISO(endDate));
};

interface OrgUsersResponse {
  organizationUsers: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    email: string;
  }[];
}

export const ORG_USERS_QUERY = gql`
  query OrganizationUsers($encodedOrgId: String!) {
    organizationUsers(encodedOrgId: $encodedOrgId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ORG_QUESTIONS_QUERY = gql`
  query GetOrgQuestions($encodedOrgId: String!, $orgId: Int!) {
    getOrgQuestions(encodedOrgId: $encodedOrgId, orgId: $orgId) {
      __typename
      id
      question
    }
  }
`;

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  '&:focus-visible': {
    outlineColor: theme.palette.primary.main,
  },
}));

interface SettingsProps {
  basicOrgData: {
    id: number;
    encodedId: string;
  };
  videoCollector: VideoCollectorDetailed;
  onChange: (change: Partial<VideoCollectorDetailed>) => void;
}

function Settings({ basicOrgData, videoCollector, onChange }: SettingsProps) {
  const lang = useLanguage();
  const [initialVideoCollector] = useState(videoCollector);
  const currentUser = useCurrentUser();

  const publicUrl = publicPath({
    encodedOrgId: basicOrgData.encodedId,
    encodedVideoCollectorId: videoCollector.encodedId,
  });

  // Lookup organization users.
  const { data: orgUsersData, loading: orgUsersLoading } = useQuery<OrgUsersResponse>(
    ORG_USERS_QUERY,
    {
      variables: { encodedOrgId: basicOrgData.encodedId },
      fetchPolicy: 'cache-and-network',
    },
  );

  // Options for "responsible" is the organization users, the currently selected
  // user (which might not be part of organization, if superadmin), and the
  // currently logged in user.
  const orgUsers = orgUsersData?.organizationUsers ?? [];
  let responsibleOptions: User[] = [currentUser];
  if (initialVideoCollector.responsible != null) {
    responsibleOptions.push(initialVideoCollector.responsible);
  }
  responsibleOptions.push(...orgUsers);
  responsibleOptions = uniqBy(responsibleOptions, 'id');

  // Lookup organization questions.
  const { data: orgQuestionsData, loading: orgQuestionsLoading } = useQuery(ORG_QUESTIONS_QUERY, {
    variables: {
      encodedOrgId: basicOrgData.encodedId,
      orgId: basicOrgData.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  // Options for questions to select from is a union between:
  // - Currently selected questions.
  // - Initially selected custom questions.
  // - Pre-defined organization questions.
  const initialCustomQuestions = initialVideoCollector.questions.filter(
    (q) => q.__typename === 'VideoCollectorCustomQuestion',
  );
  const questionOptions = unionWith(
    videoCollector.questions,
    initialCustomQuestions,
    orgQuestionsData?.getOrgQuestions,
    questionsEqual,
  );
  const questionsLoading = orgQuestionsLoading;

  const onTitleChange = useOnFieldChange(onChange, 'title');
  const onDescriptionChange = useOnFieldChange(onChange, 'description');
  const onUsePasswordChange = useOnFieldChange(onChange, 'usePassword');
  const onPasswordChange = useOnFieldChange(onChange, 'password');
  const onThankYouEmailContentChange = useOnFieldChange(onChange, 'thankYouEmailContent');
  const onApprovedEmailContentChange = useOnFieldChange(onChange, 'approvedEmailContent');
  const onAllowVideoLibraryChange = useOnFieldChange(onChange, 'allowVideoLibrary');
  const onAllowVideoLibraryNotReviewedChange = useOnFieldChange(
    onChange,
    'allowVideoLibraryNotReviewed',
  );
  const onResponsibleChange = useOnFieldChange(onChange, 'responsible');
  const onQuestionsChange = (questionInputs: QuestionInput[]) => {
    // Transform TempQuestion(s) -> LocalQuestion(s).
    const questions: VideoCollectorQuestion[] = questionInputs.map((question) =>
      isTempQuestion(question) ? tempToLocalQuestion(question) : question,
    );
    onChange({ questions });
  };
  const onAllowUserEnteredQuestionsChanged = useOnFieldChange(
    onChange,
    'allowUserEnteredQuestions',
  );

  const onStartDateChange = (startDate: string) => {
    // Rewrite empty -> null.
    const newStartDate = startDate || null;
    // Ensure startDate is not after endDate, by modifying endDate.
    let newEndDate = videoCollector.endDate;
    if (isStartDateAfterEndDate(newStartDate, newEndDate)) {
      newEndDate = newStartDate;
    }
    onChange({ startDate: newStartDate, endDate: newEndDate });
  };

  const onEndDateChange = (endDate: string) => {
    // Rewrite empty -> null.
    const newEndDate = endDate || null;
    // Ensure startDate is not after endDate, by modifying startDate.
    let newStartDate = videoCollector.startDate;
    if (isStartDateAfterEndDate(newStartDate, newEndDate)) {
      newStartDate = newEndDate;
    }
    onChange({ startDate: newStartDate, endDate: newEndDate });
  };

  return (
    <>
      <Grid
        item
        container
        spacing={3}
        role="gridcell"
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        data-testid="video-collector-settings-gridcell"
        xs={12}
        xl={6}
        sm={12}
        md={6}
        lg={6}
      >
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-title"
        >
          <label htmlFor="video-collector-titel">
            <Typography variant="titleMedium" gutterBottom>
              {videoCollectorTranslations.title[lang]}
            </Typography>
            <TextField
              id="video-collector-titel"
              name="video-collector-titel"
              value={videoCollector.title}
              onChange={(e) => onTitleChange(e.target.value)}
              sx={{
                backgroundColor: 'background.paper',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
              fullWidth
            />
          </label>
        </Grid>
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-description"
        >
          <label htmlFor="video-collector-description">
            <Typography variant="titleMedium" gutterBottom>
              {videoCollectorTranslations.description[lang]}
            </Typography>
            <StyledTextareaAutosize
              id="video-collector-description"
              name="video-collector-description"
              sx={{
                backgroundColor: 'background.paper',
                resize: 'none',
              }}
              value={videoCollector.description}
              onChange={(e) => onDescriptionChange(e.target.value)}
              style={{
                minHeight: '30vh',
                resize: 'none',
                padding: '8px',
                borderColor: 'transparent',
              }}
            />
          </label>
        </Grid>
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-questions-titles"
        >
          <label htmlFor="video-collector-questions-titles">
            <Typography variant="titleMedium" gutterBottom>
              {videoCollectorTranslations.videoQuestionsTitles[lang]}
              <Autocomplete
                freeSolo
                disablePortal
                id="video-collector-questions-titles"
                size="small"
                loading={questionsLoading}
                multiple
                options={questionOptions}
                getOptionLabel={(option) => (isTempQuestion(option) ? option : option.question)}
                getOptionKey={(option) =>
                  isTempQuestion(option)
                    ? `TempQuestion:${option}`
                    : `${option.__typename}:${option.id}`
                }
                isOptionEqualToValue={questionsEqual}
                value={videoCollector.questions}
                onChange={(_e, value) => onQuestionsChange(value)}
                sx={{
                  backgroundColor: 'background.paper',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                fullWidth
                renderInput={(renderInputParams) => (
                  <TextField {...renderInputParams} sx={{ p: 0 }} />
                )}
              />
            </Typography>
          </label>
        </Grid>
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-require-question-title"
        >
          <label htmlFor="video-collector-require-question-title">
            <Stack direction="row" spacing={6} alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  checked={!videoCollector.allowUserEnteredQuestions}
                  onChange={(e) => onAllowUserEnteredQuestionsChanged(!e.target.checked)}
                  id="video-collector-require-question-title"
                  name="video-collector-require-question-title"
                />
                <Typography variant="titleMedium">
                  {videoCollectorTranslations.restrictQuestion[lang]}
                </Typography>
                <Tooltip title={videoCollectorTranslations.restrictQuestionTooltip[lang]}>
                  <Box
                    sx={{
                      m: 0,
                      p: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <HelpIcon />
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
          </label>
        </Grid>
        <Grid
          item
          container
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-start-end-dates"
          spacing={3}
        >
          <Grid
            item
            xs={6}
            xl={6}
            sm={6}
            md={6}
            lg={6}
            role="gridcell"
            data-testid="video-collector-settings-gridcell-video-collector-start-date"
          >
            <label htmlFor="video-collector-start-date">
              <Typography variant="titleMedium" gutterBottom>
                {videoCollectorTranslations.startDate[lang]}
              </Typography>
              <TextField
                id="video-collector-start-date"
                name="video-collector-start-date"
                type="date"
                value={videoCollector.startDate ?? ''}
                onChange={(e) => onStartDateChange(e.target.value)}
                sx={{
                  backgroundColor: 'background.paper',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                fullWidth
              />
            </label>
          </Grid>

          <Grid
            item
            xs={6}
            xl={6}
            sm={6}
            md={6}
            lg={6}
            role="gridcell"
            data-testid="video-collector-settings-gridcell-video-collector-end-date"
          >
            <label htmlFor="video-collector-end-date">
              <Typography variant="titleMedium" gutterBottom>
                {videoCollectorTranslations.endDate[lang]}
              </Typography>
              <TextField
                id="video-collector-end-date"
                name="video-collector-end-date"
                type="date"
                value={videoCollector.endDate ?? ''}
                onChange={(e) => onEndDateChange(e.target.value)}
                sx={{
                  backgroundColor: 'background.paper',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                fullWidth
              />
            </label>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-password"
        >
          <label htmlFor="video-collector-password">
            <Stack direction="row" spacing={6} alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  checked={videoCollector.usePassword}
                  onChange={(e) => onUsePasswordChange(e.target.checked)}
                />
                <Typography variant="titleMedium" gutterBottom>
                  {videoCollectorTranslations.password[lang]}
                </Typography>
              </Stack>
              <TextField
                value={videoCollector.password}
                onChange={(e) => onPasswordChange(e.target.value)}
                autoComplete="off"
                id="video-collector-password"
                name="video-collector-password"
                sx={{
                  backgroundColor: 'background.paper',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                disabled={!videoCollector.usePassword}
                fullWidth
              />
            </Stack>
          </label>
        </Grid>

        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-thank-you-email"
        >
          <label htmlFor="video-collector-thank-you-email-content">
            <Stack direction="row" spacing={2} alignItems="center" sx={{ pb: 1 }}>
              <Typography variant="titleMedium" gutterBottom>
                {videoCollectorTranslations.thankYouEmailContent[lang]}
              </Typography>

              {videoCollector.saveEmail ? (
                <Tooltip title={videoCollectorTranslations.thankYouEmailContentTooltip[lang]}>
                  <Box sx={{ m: 0, p: 0, display: 'flex', justifyContent: 'center' }}>
                    <HelpIcon />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip title={videoCollectorTranslations.emailRequiresSaveEmailTooltip[lang]}>
                  <Box sx={{ m: 0, p: 0, display: 'flex', justifyContent: 'center' }}>
                    <ErrorIcon />
                  </Box>
                </Tooltip>
              )}
            </Stack>

            <StyledTextareaAutosize
              id="video-collector-thank-you-email-content"
              name="video-collector-thank-you-email-content"
              sx={{
                backgroundColor: 'background.paper',
                resize: 'none',
              }}
              value={videoCollector.thankYouEmailContent}
              onChange={(e) => onThankYouEmailContentChange(e.target.value)}
              disabled={!videoCollector.saveEmail}
              style={{
                minHeight: '15vh',
                resize: 'none',
                padding: '8px',
                borderColor: 'transparent',
              }}
            />
          </label>
        </Grid>

        {/* approvedEmailContent */}
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-approved-email"
        >
          <label htmlFor="video-collector-approved-email-content">
            <Stack direction="row" spacing={2} alignItems="center" sx={{ pb: 1 }}>
              <Typography variant="titleMedium" gutterBottom>
                {videoCollectorTranslations.approvedEmailContent[lang]}
              </Typography>

              {videoCollector.saveEmail ? (
                <Tooltip title={videoCollectorTranslations.approvedEmailContentTooltip[lang]}>
                  <Box sx={{ m: 0, p: 0, display: 'flex', justifyContent: 'center' }}>
                    <HelpIcon />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip title={videoCollectorTranslations.emailRequiresSaveEmailTooltip[lang]}>
                  <Box sx={{ m: 0, p: 0, display: 'flex', justifyContent: 'center' }}>
                    <ErrorIcon />
                  </Box>
                </Tooltip>
              )}
            </Stack>

            <StyledTextareaAutosize
              id="video-collector-approved-email-content"
              name="video-collector-approved-email-content"
              sx={{
                backgroundColor: 'background.paper',
                resize: 'none',
              }}
              value={videoCollector.approvedEmailContent}
              onChange={(e) => onApprovedEmailContentChange(e.target.value)}
              disabled={!videoCollector.saveEmail}
              style={{
                minHeight: '15vh',
                resize: 'none',
                padding: '8px',
                borderColor: 'transparent',
              }}
            />
          </label>
        </Grid>

        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-video-library"
        >
          <label htmlFor="video-collector-video-library">
            <Stack direction="row" spacing={6} alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  checked={videoCollector.allowVideoLibrary}
                  onChange={(e) => onAllowVideoLibraryChange(e.target.checked)}
                />
                <Typography variant="titleMedium">
                  {videoCollectorTranslations.allowVideoLibrary[lang]}
                </Typography>
                <Tooltip title={videoCollectorTranslations.allowVideoLibraryTooltip[lang]}>
                  <Box
                    sx={{
                      m: 0,
                      p: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <HelpIcon />
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
          </label>
        </Grid>

        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-video-library-review"
        >
          <label htmlFor="video-collector-video-library-review">
            <Stack direction="row" spacing={6} alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Checkbox
                  id="video-collector-video-library-review"
                  name="video-collector-video-library-review"
                  disabled={!videoCollector.allowVideoLibrary}
                  checked={
                    videoCollector.allowVideoLibrary && videoCollector.allowVideoLibraryNotReviewed
                  }
                  onChange={(e) => onAllowVideoLibraryNotReviewedChange(e.target.checked)}
                />
                <Typography variant="titleMedium">
                  {videoCollectorTranslations.allowVideoLibraryReview[lang]}
                </Typography>
                <Tooltip title={videoCollectorTranslations.allowVideoLibraryReviewTooltip[lang]}>
                  <Box
                    sx={{
                      m: 0,
                      p: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <HelpIcon />
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
          </label>
        </Grid>

        <Grid item xs={12} role="gridcell">
          <label htmlFor="video-collector-responsible">
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="titleMedium" gutterBottom>
                {videoCollectorTranslations.responsible[lang]}
              </Typography>
              <Tooltip title={videoCollectorTranslations.responsibleTooltip[lang]}>
                <Box
                  sx={{
                    m: 0,
                    p: 0,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <HelpIcon />
                </Box>
              </Tooltip>
            </Stack>
            <Autocomplete
              disablePortal
              id="video-collector-responsible"
              size="small"
              loading={orgUsersLoading}
              options={responsibleOptions}
              getOptionLabel={(option) =>
                `${option.firstName ?? ''} ${option.lastName ?? ''} <${option.email}>`
              }
              getOptionKey={(option) => `${option.id}`}
              value={videoCollector.responsible}
              onChange={(_e, value) => onResponsibleChange(value)}
              sx={{
                backgroundColor: 'background.paper',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
              fullWidth
              renderInput={(renderInputParams) => (
                <TextField {...renderInputParams} sx={{ p: 0 }} />
              )}
            />
          </label>
        </Grid>

        <Grid item container xs={12} xl={12} sm={12} md={12} lg={12} role="gridcell">
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12} role="gridcell">
            <Typography variant="titleLarge">
              {videoCollectorTranslations.uploaderSettings[lang]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            xl={12}
            sm={12}
            md={12}
            lg={12}
            role="gridcell"
            sx={{
              minWidth: '10px',
            }}
          >
            <UserSavePreferencesInput
              saveName={videoCollector.saveName}
              saveNameRequired={videoCollector.saveNameRequired}
              saveTitle={videoCollector.saveTitle}
              saveTitleRequired={videoCollector.saveTitleRequired}
              saveEmail={videoCollector.saveEmail}
              saveEmailRequired={videoCollector.saveEmailRequired}
              savePhone={videoCollector.savePhone}
              savePhoneRequired={videoCollector.savePhoneRequired}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        role="gridcell"
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        data-testid="video-collector-settings-gridcell"
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
      >
        <Grid
          item
          xs={12}
          xl={12}
          sm={12}
          md={12}
          lg={12}
          role="gridcell"
          data-testid="video-collector-settings-gridcell-video-collector-qr-code"
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2.5 }}>
            <Box maxWidth={300}>
              <QRCodeComponent
                text={publicUrl.toString()}
                allowDownload
                showText
                fileName={`${videoCollector.title} ${publicUrl}`}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
