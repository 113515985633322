import PropTypes from 'prop-types';

function LifeInsidePlayLogoFilledIcon({ fill = '#9400FE' }) {
  return (
    <svg viewBox="0 0 36 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.953125 0.833008V44.7494L35.6064 22.7912L0.953125 0.833008Z" fill={fill} />
    </svg>
  );
}

export default LifeInsidePlayLogoFilledIcon;

LifeInsidePlayLogoFilledIcon.propTypes = {
  fill: PropTypes.string,
};
