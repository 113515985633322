import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { gql } from '@apollo/client';

import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import Loader from '../common/Loader';

import './widget-demo.scss';

import { widgetPath, backendPostPath } from '../../utils/environment';
import { translations } from '../../utils/translationsWidgetDemo';
import { isValidHttpUrl } from '../../utils/utils';

const useFetchDomains = (encodedOrgId) => {
  const backOfficeClient = useBackOfficeApolloClient();
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    let canceled = false;

    const fetchDomains = async () => {
      try {
        const response = await backOfficeClient.query({
          query: gql`
            query GetWhitelistedDomains($encodedOrgId: String) {
              getWhitelistedDomains(encodedOrgId: $encodedOrgId) {
                id
                domainUrl
                orgId
                imageLink {
                  src
                  srcFullPage
                  updatedAt
                }
              }
            }
          `,
          variables: { encodedOrgId },
        });
        if (!canceled) {
          setDomains(response?.data?.getWhitelistedDomains || []);
        }
      } catch {
        // empty.
      }
    };

    setDomains([]);
    if (encodedOrgId) {
      fetchDomains();
    }
    return () => {
      canceled = true;
    };
  }, [backOfficeClient, encodedOrgId]);

  return domains;
};

const getImageSrc = (domains, orgId) => {
  const domainsWithImage = domains.filter(
    (domain) => domain.orgId === orgId && domain.imageLink && domain.imageLink.srcFullPage,
  );

  if (domainsWithImage.length > 0) {
    return domainsWithImage[0].imageLink.srcFullPage;
  }

  const firstValidDomain = domains.find(
    (domain) => domain.orgId === orgId && isValidHttpUrl(domain.domainUrl),
  );

  if (firstValidDomain) {
    return `${backendPostPath}/get-url-as-img/${encodeURIComponent(firstValidDomain.domainUrl)}`;
  }

  return null;
};

// Custom hook for loading an image
const useLoadImage = (src, fallbackUrl) => {
  const [imageSource, setImageSource] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    if (!src) return;

    const image = new Image();
    image.onload = () => {
      setImageSource(image);
      setImageLoading(false);
    };
    image.onerror = () => {
      // Handle the error, e.g., set a fallback image
      setImageSource({ src: fallbackUrl });
      setImageLoading(false);
    };
    image.src = src;
  }, [src, fallbackUrl]);

  return { imageSource, imageLoading };
};

function WidgetDemo() {
  const { basicOrgData } = useOutletContext();
  const encodedOrgId = basicOrgData.encodedId;
  const orgId = basicOrgData.id;
  if (!encodedOrgId) return null;

  return <WidgetDemoInner encodedOrgId={encodedOrgId} orgId={Number(orgId)} />;
}

function WidgetDemoInner({ encodedOrgId, orgId }) {
  const domains = useFetchDomains(encodedOrgId);
  const imageUrl = getImageSrc(domains, orgId);
  const fallbackUrl = 'https://www.lifeinside.io/';
  const { imageSource, imageLoading } = useLoadImage(imageUrl, fallbackUrl);

  useEffect(() => {
    const openWidget = () => {
      if (encodedOrgId) {
        const initScript = document.createElement('script');

        const inlineScript = document.createTextNode(`
            (function () {
              const settings = {
              id: '${encodedOrgId}',
              demoPage: "true",
              previewMode: "true",
            }
            WidgetWrapper.mount(settings)
            }())
            `);
        initScript.id = 'owli-widget';
        inlineScript.id = 'owli-widget';

        initScript.appendChild(inlineScript);
        initScript.async = true;

        document.body.appendChild(initScript);

        return () => {
          document.body.removeChild(initScript);
          document.body.removeChild(inlineScript);
        };
      }
      return null;
    };

    const script = document.createElement('script');
    script.src = widgetPath;

    script.async = true;
    script.onload = () => {
      openWidget();
    };

    document.body.appendChild(script);
    return () => {
      const allSuspects = document.body.getElementsByTagName('script');

      for (let i = allSuspects?.length; i >= 0; i -= 1) {
        if (
          allSuspects[i]?.src?.includes(widgetPath) ||
          allSuspects[i]?.src?.includes('/widget.2.0.0') ||
          allSuspects[i]?.src?.includes('/widget.3.0.0')
        ) {
          allSuspects[i]?.parentNode?.removeChild(allSuspects[i]);
        }
      }
      const elem = document?.getElementById('owli-widget');
      const inlineScript = document?.getElementById('owli-widget-script');
      inlineScript?.parentNode?.removeChild(inlineScript);
      return elem?.parentNode?.removeChild(elem);
    };
  }, [encodedOrgId]);

  return (
    <div className="widget-demo">
      {imageLoading ? <LoadingSite /> : <DemoBanner />}
      <img src={imageSource?.src} alt="" />
    </div>
  );
}

WidgetDemoInner.propTypes = {
  encodedOrgId: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
};

function LoadingSite() {
  const lang = useLanguage();
  return (
    <div className="demo-view-overlay">
      <div className="loading-demo-view">
        <Loader />
        <span>{translations.generationDemoView[lang]}</span>
        <br />
        <span style={{ color: 'white', fontSize: '16px' }}>{translations.loadingInfo[lang]}</span>
      </div>
    </div>
  );
}

function DemoBanner() {
  const lang = useLanguage();
  return (
    <div className="demo-banner">
      <span>{translations.demoPageInfo[lang]}</span>
    </div>
  );
}

export default WidgetDemo;
