import React from 'react';
import { Collapse, Stack, LinearProgress, Typography } from '@mui/material';

interface UploadProgressProps {
  isUploadingLoading: boolean;
  uploadProgress: number;
  videoFile: File | null;
}

function UploadProgress({ isUploadingLoading, uploadProgress, videoFile }: UploadProgressProps) {
  if (!videoFile) return null;

  // Calculate uploaded size in MB
  const uploadedSizeMB = (videoFile.size / 1024 / 1024) * (uploadProgress / 100);
  const totalSizeMB = videoFile.size / 1024 / 1024;
  const progressLabel = `${uploadedSizeMB.toFixed(2)} MB / ${totalSizeMB.toFixed(2)} MB (${Math.round(uploadProgress)}%)`;

  return (
    <Collapse in={isUploadingLoading} collapsedSize={0} data-testid="upload-progress-collapse">
      <Stack direction="column" spacing={0} sx={{ width: '100%', px: 1, my: 1 }}>
        <LinearProgress
          value={uploadProgress}
          variant="determinate"
          data-testid="upload-progress-bar"
          aria-valuenow={Math.round(uploadProgress)}
          aria-valuemin={0}
          aria-valuemax={100}
          role="progressbar"
          aria-label="Upload progress"
        />
        <Typography variant="bodySmall" data-testid="upload-progress-text">
          {videoFile.name}, {progressLabel}
        </Typography>
      </Stack>
    </Collapse>
  );
}

export default UploadProgress;
