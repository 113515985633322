import React, { useEffect, useState } from 'react';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import translationsEndScreen from '../../../utils/translationsEndScreen';
import { isValidEmail } from '../../../utils/utils';
import { errorText, errorInputField } from '../../common/SharedStylingSettings';

export const typeContactForm = 'contactForm';

export interface EndScreen {
  id?: number;
  type?: string;
  email?: string;
  btnTextString?: string;
  submitBtnTextString?: string;
  confirmationMsgString?: string;
  disableThankYouEmail?: boolean;
  meta?: {
    endScreenType?: string;
    endScreen?: {
      email?: string;
      btnTextString?: string;
      submitBtnTextString?: string;
      confirmationMsgString?: string;
      disableThankYouEmail?: boolean;
    };
  };
}

interface GetEndScreenTypeContentContactFormProps {
  endScreen: EndScreen;
}

export const getEndScreenTypeContentContactForm = ({
  endScreen,
}: GetEndScreenTypeContentContactFormProps) => {
  if (
    !(endScreen?.type === typeContactForm || endScreen?.meta?.endScreenType === typeContactForm)
  ) {
    return null;
  }

  if (!endScreen?.id) {
    return {
      email: endScreen?.email,
      btnTextString: endScreen?.btnTextString,
      submitBtnTextString: endScreen?.submitBtnTextString,
      confirmationMsgString: endScreen?.confirmationMsgString,
      type: typeContactForm,
      disableThankYouEmail: endScreen?.disableThankYouEmail,
    };
  }
  return {
    email: endScreen?.meta?.endScreen?.email,
    btnTextString: endScreen?.meta?.endScreen?.btnTextString,
    submitBtnTextString: endScreen?.meta?.endScreen?.submitBtnTextString,
    confirmationMsgString: endScreen?.meta?.endScreen?.confirmationMsgString,
    type: typeContactForm,
    disableThankYouEmail: endScreen?.meta?.endScreen?.disableThankYouEmail,
  };
};

interface HasChangesContactFormProps {
  currentEndScreen: EndScreen;
  prevEndScreen: EndScreen;
}

export const hasChangesContactForm = ({
  currentEndScreen,
  prevEndScreen,
}: HasChangesContactFormProps) => {
  if (currentEndScreen?.type !== typeContactForm) {
    return false;
  }
  if (prevEndScreen?.type !== typeContactForm) {
    return true;
  }
  if (currentEndScreen?.email !== prevEndScreen?.email) {
    return true;
  }
  if (currentEndScreen?.submitBtnTextString !== prevEndScreen?.submitBtnTextString) {
    return true;
  }
  if (currentEndScreen?.confirmationMsgString !== prevEndScreen?.confirmationMsgString) {
    return true;
  }
  if (currentEndScreen?.btnTextString !== prevEndScreen?.btnTextString) {
    return true;
  }
  if (currentEndScreen?.disableThankYouEmail !== prevEndScreen?.disableThankYouEmail) {
    return true;
  }

  return false;
};

interface CanSaveContactFormProps {
  endScreen: EndScreen;
}

export const canSaveContactForm = ({ endScreen }: CanSaveContactFormProps) => {
  if (endScreen?.type !== typeContactForm) {
    return false;
  }
  if (!endScreen?.email) {
    return false;
  }
  if (!endScreen?.submitBtnTextString) {
    return false;
  }

  if (!endScreen?.btnTextString) {
    return false;
  }

  if (!endScreen?.confirmationMsgString) {
    return false;
  }
  if (endScreen?.confirmationMsgString?.length > 100) {
    return false;
  }

  if (endScreen?.confirmationMsgString?.length < 1) {
    return false;
  }

  if (endScreen?.submitBtnTextString?.length > 20) {
    return false;
  }

  if (endScreen?.btnTextString?.length > 20) {
    return false;
  }

  if (endScreen?.submitBtnTextString?.length < 1) {
    return false;
  }

  if (endScreen?.btnTextString?.length < 1) {
    return false;
  }

  if (!isValidEmail(endScreen?.email)) {
    return false;
  }

  return true;
};

interface CanActivateContactFormProps {
  endScreen: EndScreen;
}

export const canActivateContactForm = ({ endScreen }: CanActivateContactFormProps) => {
  if (!canSaveContactForm({ endScreen })) {
    return false;
  }
  return true;
};

interface ContactFormProps {
  endScreen: EndScreen;
  setEndScreen: (endScreen: EndScreen) => void;
  disabled?: boolean;
}

export function ContactForm({ endScreen, setEndScreen, disabled = false }: ContactFormProps) {
  const lang = useLanguage();

  const [confirmationMsgString, setConfirmationMsgString] = useState<string>(
    endScreen?.confirmationMsgString ?? translationsEndScreen.cf.confirmSubmitMessageDefault[lang],
  );

  const [confirmationMsgStringValid, setConfirmationMsgStringValid] = useState<boolean>(true);

  const [email, setEmail] = useState<string>(endScreen?.email ?? '');
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [submitBtnTextString, setSubmitBtnTextString] = useState<string>(
    endScreen.submitBtnTextString ?? translationsEndScreen.cf.submitBtnTextDefault[lang],
  );
  const [submitBtnTextStringValid, setSubmitBtnTextStringValid] = useState<boolean>(true);

  const [disableThankYouEmail, setDisableThankYouEmail] = useState<boolean>(
    typeof endScreen?.disableThankYouEmail === 'boolean' ? endScreen.disableThankYouEmail : false,
  );

  useEffect(() => {
    if (!endScreen.id && !endScreen.submitBtnTextString && !endScreen.confirmationMsgString) {
      setEndScreen({
        ...endScreen,
        submitBtnTextString: translationsEndScreen.cf.submitBtnTextDefault.en,
        confirmationMsgString: translationsEndScreen.cf.confirmSubmitMessageDefault.en,
        disableThankYouEmail: false,
      });
    }
  }, [endScreen, setEndScreen]);

  const handleSetConfirmationMsgString = (value: string) => {
    setConfirmationMsgStringValid(() => {
      if (value.length === 0) {
        return false;
      }
      if (value.length > 100) {
        return false;
      }
      return true;
    });

    setConfirmationMsgString(value);
    setEndScreen({ ...endScreen, confirmationMsgString: value });
  };

  const handleSetEmail = (value: string) => {
    setEmail(value);
    setEndScreen({ ...endScreen, email: value });
  };

  const handleSetSubmitBtnTextString = (value: string) => {
    if (!(value?.length > 0) || value?.length > 20) {
      setSubmitBtnTextStringValid(false);
    } else {
      setSubmitBtnTextStringValid(true);
    }
    setSubmitBtnTextString(value);
    setEndScreen({ ...endScreen, submitBtnTextString: value });
  };

  const handleDisableThankYouEmail = (value: boolean) => {
    setDisableThankYouEmail(value);
    setEndScreen({ ...endScreen, disableThankYouEmail: value });
  };

  return (
    <>
      <label htmlFor="email" data-testid="email-label">
        <Tooltip title={translationsEndScreen.cf.emailDescription[lang]}>
          <Typography
            variant="titleMedium"
            className={`${disabled ? '' : 'required-field'}`}
            sx={{ position: 'relative' }}
            data-testid="email-typography"
          >
            {translationsEndScreen.cf.email[lang]}
            {!emailValid && (
              <Typography
                variant="bodySmall"
                className="error-msg"
                sx={{ ...errorText }}
                data-testid="email-error"
              >
                {email?.length > 0
                  ? translationsEndScreen.cf.invalidEmail[lang]
                  : translationsEndScreen.cf.emailError[lang]}
              </Typography>
            )}
          </Typography>
        </Tooltip>
        <input
          name="email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => handleSetEmail(e.target.value)}
          onBlur={() => {
            if (!isValidEmail(email)) {
              setEmailValid(false);
            } else {
              setEmailValid(true);
            }
          }}
          required
          disabled={disabled}
          style={emailValid ? {} : { ...errorInputField }}
          data-testid="email-input"
        />
      </label>

      <label htmlFor="submitBtnText" data-testid="submitBtnText-label">
        <Tooltip title={translationsEndScreen.cf.submitBtnTextDescription[lang]}>
          <Typography
            variant="titleMedium"
            className={`${disabled ? '' : 'required-field'}`}
            sx={{ position: 'relative' }}
            data-testid="submitBtnText-typography"
          >
            {translationsEndScreen.cf.submitBtnText[lang]}
            {!submitBtnTextStringValid && (
              <Typography
                variant="titleMedium"
                className="error-msg"
                sx={{ ...errorText }}
                data-testid="submitBtnText-error"
              >
                {submitBtnTextString?.length > 20
                  ? translationsEndScreen.cf.submitBtnTextStringValidErrorTooLong[lang]
                  : translationsEndScreen.cf.submitBtnTextStringValidError[lang]}
              </Typography>
            )}
          </Typography>
        </Tooltip>
        <input
          name="submitBtnText"
          id="submitBtnText"
          type="text"
          value={submitBtnTextString}
          onChange={(e) => handleSetSubmitBtnTextString(e.target.value)}
          required
          disabled={disabled}
          style={submitBtnTextStringValid ? {} : { ...errorInputField }}
          data-testid="submitBtnText-input"
        />
      </label>

      <label htmlFor="confirmationString" data-testid="confirmationString-label">
        <Tooltip title={translationsEndScreen.cf.confirmSubmitMessageDescription[lang]}>
          <Typography
            variant="titleMedium"
            className={`${disabled ? '' : 'required-field'}`}
            sx={{ position: 'relative' }}
            data-testid="confirmationString-typography"
          >
            {translationsEndScreen.cf.confirmSubmitMessage[lang]}
            {!confirmationMsgStringValid && (
              <Typography
                variant="titleMedium"
                className="error-msg"
                sx={{ ...errorText }}
                data-testid="confirmationString-error"
              >
                {confirmationMsgString?.length > 100
                  ? translationsEndScreen.cf.confirmSubmitMessageStringValidErrorTooLong[lang]
                  : translationsEndScreen.cf.confirmSubmitMessageStringValidError[lang]}
              </Typography>
            )}
          </Typography>
        </Tooltip>
        <textarea
          name="confirmationString"
          id="confirmationString"
          value={confirmationMsgString}
          onChange={(e) => handleSetConfirmationMsgString(e.target.value)}
          required
          disabled={disabled}
          data-testid="confirmationString-textarea"
          maxLength={101}
        />
      </label>

      <Divider sx={{ mb: 1 }} data-testid="settings-divider" />
      <Stack spacing={2} data-testid="email-settings-stack">
        <Typography variant="titleMedium" data-testid="email-settings-typography">
          {translationsEndScreen.cf.emailSettings[lang]}
        </Typography>
        <label htmlFor="emailEnabled" data-testid="emailEnabled-label">
          <Stack direction="row" spacing={2} data-testid="emailEnabled-stack">
            <input
              type="checkbox"
              id="emailEnabled"
              checked={disableThankYouEmail}
              onChange={(e) => handleDisableThankYouEmail(e.target.checked)}
              disabled={disabled}
              data-testid="emailEnabled-checkbox"
            />
            <Typography variant="titleMedium" data-testid="email-disable-typography">
              {translationsEndScreen.cf.emailDisable[lang]}
            </Typography>
          </Stack>
        </label>
      </Stack>
    </>
  );
}

export default ContactForm;
