export const translationsDashboard = {
  onboarding: {
    learnMoreAbout: {
      en: 'Learn more about',
      sv: 'Läs mer om',
    },
    watchOurVideoTutorials: {
      en: 'Watch our video tutorials',
      sv: 'Titta på våra videotutorials',
    },
    getStarted: {
      en: 'Get Started',
      sv: 'Kom igång',
    },
    videoFunnel: {
      en: 'Video Funnel',
      sv: 'Video Tratt',
    },
    endScreens: {
      en: 'End Screens',
      sv: 'Slutskärmar',
    },
  },
  updatesCard: {
    updates: {
      en: 'Updates',
      sv: 'Uppdateringar',
    },
    noUpdates: {
      en: 'No updates right now.',
      sv: 'Inga uppdateringar just nu.',
    },
  },
  helloCard: {
    goodMorning: {
      en: 'Good morning',
      sv: 'Godmorgon',
    },
    goodAfternoon: {
      en: 'Good afternoon',
      sv: 'God eftermiddag',
    },
    goodEvening: {
      en: 'Good evening',
      sv: 'God kväll',
    },
    workingLate: {
      en: 'Working late?',
      sv: 'Jobbar du sent?',
    },
    earlyBird: {
      en: 'Whoa, early bird',
      sv: 'Wow, tidigt uppe',
    },
    videosUploadedThisWeek: {
      en: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `This week you and your coworkers have uploaded ${count} ${
          count !== 0 ? 'new ' : ''
        }${videoString}.  ${count !== 0 ? 'Great job!' : ''}`;
      },
      sv: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Denna vecka har du och dina kollegor laddat upp ${count} ${
          count !== 0 ? 'nya ' : ''
        }${videoString}.  ${count !== 0 ? 'Bra jobbat!' : ''}`;
      },
    },
    videosUploadedThisMonth: {
      en: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `This month you and your coworkers have uploaded ${count} ${
          count !== 0 ? 'new ' : ''
        }${videoString}.  ${count !== 0 ? 'Great job!' : ''}`;
      },
      sv: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Denna här månaden har du och dina kollegor laddat upp ${count} ${
          count !== 0 ? 'nya ' : ''
        }${videoString}.  ${count !== 0 ? 'Bra jobbat!' : ''}`;
      },
    },
    videosUploadedLastTwentyEightDays: {
      en: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `In the last 28 days you and your coworkers have uploaded ${count} ${
          count !== 0 ? 'new ' : ''
        }${videoString}.  ${count !== 0 ? 'Great job!' : ''}`;
      },
      sv: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `De senaste 28 dagarna har du och dina kollegor laddat upp ${count} ${
          count !== 0 ? 'nya ' : ''
        }${videoString}. ${count !== 0 ? 'Bra jobbat!' : ''}`;
      },
    },
    videosUploadedThisYear: {
      en: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `This year you and your coworkers have uploaded ${count} ${videoString}. ${
          count !== 0 ? 'Great job!' : ''
        }`;
      },
      sv: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Det här året har du och dina kollegor laddat upp ${count} ${videoString}. ${
          count !== 0 ? 'Bra jobbat!' : ''
        }`;
      },
    },
    videosUploadedAllTime: {
      en: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Since start you and your coworkers have uploaded ${count} ${videoString}. ${
          count !== 0 ? 'Great job!' : ''
        }`;
      },
      sv: ({ count }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Sedan start har du och dina kollegor laddat upp ${count} ${videoString}. ${
          count !== 0 ? 'Bra jobbat!' : ''
        }`;
      },
    },
    thisWeek: {
      nrVideosUploaded: {
        en: ({ count }) => {
          let videoString = 'videos';
          if (count === 1) {
            videoString = 'video';
          }
          return `This week you and your coworkers have uploaded ${count} ${
            count !== 0 ? 'new ' : ''
          }${videoString}.  ${count !== 0 ? 'Great job!' : ''}`;
        },
        sv: ({ count }) => {
          let videoString = 'videos';
          if (count === 1) {
            videoString = 'video';
          }
          return `Denna vecka har du och dina kollegor laddat upp ${count} ${
            count !== 0 ? 'nya ' : ''
          }${videoString}.  ${count !== 0 ? 'Bra jobbat!' : ''}`;
        },
      },
    },
  },
  dataCards: {
    storylineLiveRightNow: {
      en: ({ count }) => {
        let storylineString = 'Storylines';
        if (count === 1) {
          storylineString = 'Storyline';
        }
        return `${storylineString} live right now`;
      },
      sv: ({ count }) => {
        let storylineString = 'Storylines';
        if (count === 1) {
          storylineString = 'Storyline';
        }
        return `${storylineString} live just nu`;
      },
    },
    storiesLiveRightNow: {
      en: ({ count }) => {
        let storiesString = 'Stories';
        if (count === 1) {
          storiesString = 'Stories';
        }
        return `${storiesString} live right now`;
      },
      sv: ({ count }) => {
        let storiesString = 'Stories';
        if (count === 1) {
          storiesString = 'Stories';
        }
        return `${storiesString} live just nu`;
      },
    },
    procentOfViewersPlayVideo: {
      en: 'procent of viewers play video',
      sv: 'procent av tittarna spelar video',
    },
    playedVideosLastDays: {
      en: ({ count, nrDays = 60 }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Played ${videoString} last ${nrDays} days`;
      },
      sv: ({ count, nrDays = 60 }) => {
        let videoString = 'videos';
        if (count === 1) {
          videoString = 'video';
        }
        return `Spelade ${videoString} senaste ${nrDays} dagarna`;
      },
    },
    videosLiveRightNow: {
      en: ({ count }) => {
        let videoString = 'Videos';
        if (count === 1) {
          videoString = 'Video';
        }

        return `${videoString} live right now`;
      },
      sv: ({ count }) => {
        let videoString = 'Videos';
        if (count === 1) {
          videoString = 'Video';
        }
        return `${videoString} live just nu`;
      },
    },
  },
  viewsChartCard: {
    videosPresentedLastXDays: {
      en({ days }) {
        return `Videos presented last ${days} days`;
      },
      sv({ days }) {
        return `Video presenterade senaste ${days} dagarna`;
      },
    },
  },
  popularVideosCard: {
    mostPopularVideos: {
      en: 'Most popular videos right now',
      sv: 'Mest populära videos just nu',
    },
  },
  popularStorylineCard: {
    mostPopularStoryline: {
      en: 'Most popular Stories right now',
      sv: 'Mest populära Stories just nu',
    },
  },
  toDoViewCard: {
    pendingReviews: {
      description: {
        en: ({ count }) => {
          let videoString = 'videos';
          if (count === 1) {
            videoString = 'video';
          }
          return `You have ${count} new ${videoString} ready to be reviewed!`;
        },
        sv: ({ count }) => {
          let videoString = 'nya videos';
          if (count === 1) {
            videoString = 'ny video';
          }
          return `Du har ${count} ${videoString} redo för att granskas!`;
        },
      },
      helpCondition: {
        en: 'If you have new videos ready for review, this task is created and it is finished when all videos have been reviewed.',
        sv: 'Om du har nya videor redo för granskning, skapas denna uppgift och den är klar när alla videor har granskats.',
      },
    },
    videoRequestNew: {
      description: {
        en: 'Create your first video request to start collecting videos.',
        sv: 'Skapa din första videoförfrågan för att börja samla in videor.',
      },
      helpCondition: {
        en: "If you're new to the platform and haven't created a video request yet, this task will be created to remind you to create your first video request in order to start collecting videos. It will be marked as completed once a request has been created.",
        sv: 'Om du är ny på plattformen och inte skapat en videoförfrågan ännu, kommer den här uppgiften att skapas för att påminna dig att skapa din första videoförfrågan för att börja samla in videor. Den kommer att markeras som klar när en förfrågan har skapats.',
      },
    },
    videoRequestDaysSinceNewRequest: {
      description: {
        en: 'Time to send out a new video request!',
        sv: 'Det är dags att skicka en ny videoförfrågan!',
      },
      helpCondition: {
        en: "If it's been a while since you've sent out a video request, this task is created and it is finished when a new video request is sent.",
        sv: 'Om det har gått ett tag sedan du skickade en videoförfrågan, skapas denna uppgift och den är klar när en ny videoförfrågan skickas.',
      },
    },
    videoRequestsDaysSinceNewRequestSent: {
      description: {
        en: "It's been a while since you sent out a video request, why not send some more and get new content?",
        sv: 'Det har varit en stund sedan du skickade en videoförfrågan, varför inte skicka fler och få nytt innehåll?',
      },
      helpCondition: {
        en: "If it's been a while since you sent out a video request, this task is created to remind you to send more requests and get new content, it will be marked as completed once a video request has been sent out.",
        sv: 'Om det har varit ett tag sedan du skickade en videoförfrågan, skapas denna uppgift för att påminna dig att skicka fler förfrågningar och få nytt innehåll, den kommer att markeras som klar när en videoförfrågan har skickats ut.',
      },
    },
    videoRequestPending: {
      description: {
        en: "Don't forget to follow up on your pending video requests, send out a reminder.",
        sv: 'Glöm inte att följa upp dina väntande videofrågan, skicka en påminnelse.',
      },
      helpCondition: {
        en: 'If you have a video request that has been pending for a while, this task is created and it is finished when you send out a reminder to follow up on it.',
        sv: 'Om du har en videoförfrågan som har varit i väntar på svar ett tag, skapas den här uppgiften och den är klar när du skickar ut en påminnelse för att följa upp den.',
      },
    },
    storylineNew: {
      description: {
        en: 'Create your first Storyline.',
        sv: 'Skapa din första Storyline.',
      },
      helpCondition: {
        en: "If you are a new user to the platform and haven't created a Storyline yet, this task is created and it is finished when you have created your first Storyline.",
        sv: 'Om du är en ny användare på plattformen och inte har skapat en Storyline ännu, skapas denna uppgift och den är klar när du har skapat din första Storyline.',
      },
    },
    storylineLive: {
      description: {
        en: 'Review your inactive Storylines, update or remove them for a fresh start.',
        sv: 'Granska dina inaktiva Storylines, uppdatera eller ta bort dem för en nystart.',
      },
      helpCondition: {
        en: 'If you have Storylines that have been inactive for a while, this task is created and it is finished when you have reviewed and updated or deleted them.',
        sv: 'Om du har Storylines som har varit inaktiva ett tag, skapas denna uppgift och den är klar när du har granskat och uppdaterat eller tagit bort dem.',
      },
    },
    storylineDaysOldLive: {
      description: {
        en: 'Regularly check and update your Storylines to keep them fresh and relevant.',
        sv: 'Kolla regelbundet och uppdatera dina Storylines för att hålla dem fräscha och relevanta.',
      },
      helpCondition: {
        en: 'If you have Storylines that have been live for a while but not updated in the past month, this task is created and it is finished when you update one of those Storylines.',
        sv: 'Om du har Storylines som har varit live ett tag men inte uppdaterats under den senaste månaden, skapas denna uppgift och den är klar när du uppdaterar en av dessa Storylines.',
      },
    },
    storylineDaysSinceLastCreation: {
      description: {
        en: 'Time to create a new Storyline.',
        sv: 'Dags att skapa en ny Storyline.',
      },
      helpCondition: {
        en: "If you haven't created a new Storyline in a while, this task is created to remind you to create one. It will be marked as completed once a new Storyline has been created.",
        sv: 'Om du inte har skapat en ny Storyline på ett tag, skapas denna uppgift för att påminna dig att skapa en. Den kommer att markeras som klar när en ny Storyline har skapats.',
      },
    },
    videoCardNew: {
      description: {
        en: "It looks like you haven't added any videos to the platform yet, why not send out a video request or upload one directly?",
        sv: 'Det verkar som att du inte har lagt till några videor på plattformen än, varför inte skicka ut en videoförfrågan eller ladda upp en direkt?',
      },
      helpCondition: {
        en: 'If you are a new user to the platform and have not uploaded any videos, this task is created. It will be marked as completed once a video is received or uploaded.',
        sv: 'Om du är en ny användare på plattformen och inte har laddat upp några videor, skapas denna uppgift. Den kommer att markeras som klar när en video har tagits emot eller laddats upp.',
      },
    },
    videoCardApproved: {
      description: {
        en: 'You have videos that are approved and ready to be published to the library, added them to a Storyline.',
        sv: 'Du har videor som är godkända och redo att publiceras till biblioteket, lägg till på en Storyline.',
      },
      helpCondition: {
        en: "If you have approved videos that haven't been published yet, this task is created and it is finished when all the videos are published to the library.",
        sv: 'Om du har godkända videor som ännu inte har publicerats, skapas den här uppgiften och den är klar när alla videorna publiceras till biblioteket.',
      },
    },
    videoCardNoStoryline: {
      description: {
        en: "Don't let your videos go to waste! Make sure they're included in a Storyline by adding them to an existing one or creating a new one.",
        sv: 'Låt inte dina videor gå till spillo! Se till att de ingår i en Storyline genom att lägga till dem i en existerande eller skapa en ny.',
      },
      helpCondition: {
        en: 'This is created when you have videos that are not included in any Storyline. It will be marked as completed once all videos have been added to a Storyline.',
        sv: 'Detta skapas när du har videor som inte ingår i någon Storyline. Den kommer att markeras som klar när alla videor har lagts till en Storyline.',
      },
    },
    videoCardDaysSinceLastRecipientUpload: {
      description: {
        en: "It's been a while since you've received a video response, send out a batch of requests.",
        sv: 'Det har varit ett tag sedan du fick en ny videosvar, skicka ut fler videoförfrågan.',
      },
      helpCondition: {
        en: "If it's been a while since you received a video response, this task is finished when you send out a new batch of video requests.",
        sv: 'Om det har gått ett tag sedan du fick ett videosvar, är den här uppgiften klar när du skickar ut en ny grupp med videoförfrågningar.',
      },
    },
    videoCardDaysSinceLastUpload: {
      description: {
        en: 'Time to upload a video?',
        sv: 'Dags att ladda upp en video?',
      },
      helpCondition: {
        en: "If it's been a while since you last uploaded a video, this task is created and it will be finished when you upload a new video to the platform.",
        sv: 'Detta skapas när det var ett tag sedan du laddade upp en video. Den kommer att markeras som klar när du laddar upp en video till plattformen.',
      },
    },
    orgLessThan5VideosSinceStart: {
      description: {
        en: "Want a helping hand to get started? Let's schedule a meeting!",
        sv: 'Behöver du hjälp att komma igång? Boka in ett möte med oss!',
      },
      helpCondition: {
        en: 'If you have less than 5 videos after a month of using Life Inside, this task is created and it is finished when you schedule a meeting with our customer success team.',
        sv: 'Om du har mindre än 5 videor efter en månads användning av Life Inside, skapas detta uppdrag och det är klart när du bokar ett möte med vårt kundframgångsteam.',
      },
    },
    orgNoAutoRemindersSettings: {
      description: {
        en: 'Did you know that you can automatically send out reminders with our system?',
        sv: 'Visste du att du kan skicka ut påminnelser automatiskt med vårt system?',
      },
      helpCondition: {
        en: 'If automatic reminder notifications are not set up, this task is created and it is finished when you enable the automation feature.',
        sv: 'Om automatiska påminnelsemeddelanden inte är konfigurerade, skapas denna uppgift och den är klar när du aktiverar automatiseringsfunktionen.',
      },
    },
    orgUpdateRemindersSettings: {
      description: {
        en: 'Check your automatic reminders settings.',
        sv: 'Kolla över dina inställningar för automatiska påminnelser.',
      },
      helpCondition: {
        en: "If it's been a while since you last updated your automatic reminders settings, this task is created and it is finished when you update them.",
        sv: 'Om det har gått ett tag sedan du senast uppdaterade dina inställningar för automatiska påminnelser, skapas denna uppgift och den är klar när du uppdaterar dem.',
      },
    },
    orgInactiveRemindersSettings: {
      description: {
        en: 'Do you want to enable automatic reminders?',
        sv: 'Vill du aktivera automatiska påminnelser?',
      },
      helpCondition: {
        en: 'This task is created if your automatic reminder settings are turned off. It will be marked as completed once you turn on the automatic reminders.',
        sv: 'Detta skapas om dina inställningar för automatiska påminnelser är inaktiva. Den kommer att markeras som klar när du aktiverar automatiska påminnelser.',
      },
    },
    orgNoAutoRequestsSettings: {
      description: {
        en: 'Want to make video requests even easier? Consider setting up automatic video requests for your organization.',
        sv: 'Visste du att du kan ställa in systemet för att skicka ut videoförfrågningar automatiskt?',
      },
      helpCondition: {
        en: 'If the automation for sending out video requests is not enabled, this task is created and it will be marked as completed once you enable it.',
        sv: 'Om automatiseringen för att skicka ut videoförfrågningar inte är aktiverad, skapas denna uppgift och den kommer att markeras som klar när du aktiverar den.',
      },
    },
    orgUpdateRequestsSettings: {
      description: {
        en: "Take a moment to review your organization's automatic video request settings.",
        sv: 'Kolla över inställningarna för automatiska videoförfrågningar för din organisation.',
      },
      helpCondition: {
        en: 'If the automatic video request feature is turned off, this task is created and it will be marked as completed when you turn it on',
        sv: 'Om automatisk videoförfrågan är avstängd, skapas denna uppgift och den kommer att markeras som klar när du aktiverar den',
      },
    },
    orgInactiveRequestsSettings: {
      description: {
        en: 'Want to make video requests even easier? Try turning on automatic video requests!',
        sv: 'Ser ut som att inställningarna för automatiska videoförfrågningar är avaktiverade, vill du aktivera dem?',
      },
      helpCondition: {
        en: 'If the automatic video request feature is turned off for your organization, this task is created and it is finished when you update the settings to turn it on.',
        sv: 'Om funktionen för automatiska videoförfrågningar är avaktiverad för din organisation, skapas denna uppgift och den är klar när du uppdaterar inställningarna för att aktivera den.',
      },
    },
  },
};

export default translationsDashboard;
