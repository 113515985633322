import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Recaptcha.styles.scss';
import { useLanguage } from 'src/context/LanguageContext';

interface RecaptchaProps {
  hidden?: boolean;
  reCaptchaRef: React.RefObject<ReCAPTCHA>;
}

const siteKey = '6Le4ZKweAAAAAG6X5tWdchABDtsfeM9B9IqGE6pv';

function Recaptcha({ hidden = false, reCaptchaRef }: RecaptchaProps) {
  const lang = useLanguage();
  return (
    <ReCAPTCHA
      ref={reCaptchaRef}
      size={hidden ? 'invisible' : 'normal'}
      sitekey={siteKey}
      hl={lang}
      className={hidden ? 'hidden' : ''}
      data-testid="recaptcha-element"
    />
  );
}

export default Recaptcha;
