import { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LazyLoad from 'react-lazy-load';
import _ from 'lodash';
import StorylineVideoCard from './StorylineVideoCard';

function StorylineVideoLibrary({
  editableStoryline,
  storylineCards,
  basicOrgData,
  randomizeVideoOrder,
  handleSelected,
  storylineVideoCards,
  moveCard,
  theme,
}) {
  const height = 410;
  const width = height * 0.516;

  const [dynamicDimensions, setDynamicDimensions] = useState(
    storylineCards?.map((v, index) => ({
      indexOfItem: index,
      width,
      height,
      ...(v?.type === 'video_request' ? { videoRequestId: v.id } : { videoCardId: v.id }),
    })) ?? [],
  );

  useEffect(() => {
    setDynamicDimensions(
      storylineCards?.map((v, index) => {
        const dimension = dynamicDimensions?.find((c) => {
          if (v?.type === 'video_request') {
            return v.id === c.videoRequestId;
          }
          return v.id === c.videoCardId;
        });

        return {
          indexOfItem: index,
          width: dimension?.width ?? width,
          height: dimension?.height ?? height,
          ...(v?.type === 'video_request' ? { videoRequestId: v.id } : { videoCardId: v.id }),
        };
      }) ?? [],
    );
  }, [storylineCards]);

  const handleSetDynamicDimensions = (e) => {
    const items = [...dynamicDimensions];
    const filteredItems =
      items?.filter((v) => {
        if (v?.videoCardId && e?.videoCardId) {
          return v?.videoCardId !== e?.videoCardId;
        }
        if (v?.videoRequestId && e?.videoRequestId) {
          return v?.videoRequestId !== e?.videoRequestId;
        }

        return true;
      }) ?? [];

    setDynamicDimensions([...filteredItems, e]);
  };

  return (
    <div className="storyline-video-library">
      <DndProvider backend={HTML5Backend}>
        {storylineCards?.map((videoCard, index) => (
          <LazyLoad
            key={videoCard.id}
            width={
              (dynamicDimensions?.find((v) => {
                if (videoCard?.type === 'video_request') {
                  return videoCard.id === v.videoRequestId;
                }
                return videoCard.id === v.videoCardId;
              })?.width ?? width) * 1.1
            }
            height={height * 1.1}
            offset={500}
          >
            <StorylineVideoCard
              key={videoCard.id}
              data={videoCard}
              basicOrgData={basicOrgData}
              editableStoryline={editableStoryline}
              selected={_.findIndex(storylineVideoCards, { id: videoCard.id }) >= 0}
              indexOfItem={randomizeVideoOrder ? -1 : index}
              handleSelected={(e) => {
                handleSelected(videoCard);
              }}
              moveCard={moveCard}
              theme={theme}
              cardHeight={height}
              cardWidth={width}
              dynamicDimensions={dynamicDimensions}
              setDynamicDimensions={(e) => {
                handleSetDynamicDimensions(e);
              }}
            />
          </LazyLoad>
        ))}
      </DndProvider>
    </div>
  );
}

export default StorylineVideoLibrary;
