import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../../../utils/translations';
import { statuses } from '../../../../utils/utils';
import 'react-phone-input-2/lib/bootstrap.css';
import PhoneNumberInput from '../../Recipient/PhoneNumberInput';

function VideoRequestSection({
  videoCard,
  actorEmail,
  actorPhoneNumber,
  internalNote,
  setActorEmail,
  setActorPhoneNumber,
  setInternalNote,
  actorTitle,
  actorName,
  textMessageAllowed,
  recipient,
}) {
  const lang = useLanguage();

  if ((!videoCard || videoCard.status === statuses.SUGGESTION) && !recipient) {
    return (
      <fieldset className="send-section section">
        <label htmlFor="actorEmail">
          <Tooltip title={translations.videoCard.internalVisibility[lang]}>
            <Typography variant="mediumTitle" className="required-field">
              {translations.videoCard.email[lang]}
            </Typography>
          </Tooltip>
          <input
            type="email"
            name="actorEmail"
            id="actorEmail"
            value={actorEmail}
            onChange={(e) => setActorEmail(e.target.value)}
            required={actorName?.length > 0 || actorTitle?.length > 0}
            disabled={recipient}
          />
        </label>
        {textMessageAllowed && (
          <label htmlFor="actorPhoneNumber">
            <Tooltip title={translations.videoCard.internalVisibilityPhone[lang]}>
              <Typography variant="titleMedium">
                {translations.videoCard.phoneNumber[lang]}
              </Typography>
            </Tooltip>
            {PhoneNumberInput({
              actorPhoneNumber,
              setActorPhoneNumber,
              lang,
              disabled: !!recipient,
            })}
          </label>
        )}

        <label htmlFor="internalNote">
          <Tooltip title={translations.videoCard.internalVisibility[lang]}>
            <Typography variant="titleMedium" className="required-field">
              {translations.videoCard.messageToTeamMember[lang]}
            </Typography>
          </Tooltip>

          <textarea
            name="internalNote"
            id="internalNote"
            value={internalNote}
            onChange={(e) => setInternalNote(e.target.value)}
          />
        </label>
      </fieldset>
    );
  }

  if (videoCard?.status === statuses.REVIEW && (!videoCard?.actorEmail || !videoCard?.actorTitle)) {
    return (
      <fieldset className="send-section section">
        <label htmlFor="actorEmail">
          <Tooltip title={translations.videoCard.internalVisibility[lang]}>
            <Typography variant="titleMedium">{translations.videoCard.email[lang]}</Typography>
          </Tooltip>

          <input
            type="email"
            name="actorEmail"
            id="actorEmail"
            value={actorEmail}
            onChange={(e) => setActorEmail(e.target.value)}
            disabled={recipient}
          />
        </label>

        {textMessageAllowed && (
          <label htmlFor="actorPhoneNumber">
            <Tooltip title={translations.videoCard.internalVisibilityPhone[lang]}>
              <Typography variant="titleMedium">
                {translations.videoCard.phoneNumber[lang]}
              </Typography>
            </Tooltip>

            {PhoneNumberInput({
              actorPhoneNumber,
              setActorPhoneNumber,
              lang,
              disabled: !!recipient,
            })}
          </label>
        )}

        <label htmlFor="internalNote">
          <Tooltip title={translations.videoCard.internalVisibility[lang]}>
            <Typography variant="titleMedium">
              {translations.videoCard.messageToTeamMember[lang]}
            </Typography>
          </Tooltip>
          <textarea
            name="internalNote"
            id="internalNote"
            value={internalNote}
            onChange={(e) => setInternalNote(e.target.value)}
          />
        </label>
      </fieldset>
    );
  }

  if (videoCard.status === statuses.PENDING) {
    return (
      <div className="section">
        <div>
          <label htmlFor="actorEmail">
            <Tooltip title={translations.videoCard.internalVisibility[lang]}>
              <Typography variant="titleMedium" className="required-field">
                {translations.videoCard.email[lang]}
              </Typography>
            </Tooltip>
            <input
              type="email"
              name="actorEmail"
              id="actorEmail"
              readOnly
              disabled
              value={actorEmail}
            />
          </label>

          {textMessageAllowed && (
            <label htmlFor="actorPhoneNumber">
              <Tooltip title={translations.videoCard.internalVisibilityPhone[lang]}>
                <Typography variant="titleMedium">
                  {translations.videoCard.phoneNumber[lang]}
                </Typography>
              </Tooltip>
              {PhoneNumberInput({ actorPhoneNumber, setActorPhoneNumber, lang, disabled: true })}
            </label>
          )}

          <label htmlFor="internalNote">
            <Tooltip title={translations.videoCard.internalVisibility[lang]}>
              <Typography variant="titleMedium" className="required-field">
                {translations.videoCard.messageToTeamMember[lang]}
              </Typography>
            </Tooltip>
            <textarea
              name="internalNote"
              id="internalNote"
              readOnly
              disabled
              value={internalNote}
            />
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className="section">
      <div>
        <label htmlFor="actorEmail">
          <Tooltip title={translations.videoCard.internalVisibility[lang]}>
            <Typography
              variant="titleMedium"
              className={videoCard?.status === statuses.REVIEW ? 'required-field' : ''}
            >
              {translations.videoCard.email[lang]}
            </Typography>
          </Tooltip>

          <input
            type="email"
            name="actorEmail"
            id="actorEmail"
            readOnly
            disabled
            value={actorEmail}
          />
        </label>

        {textMessageAllowed && (
          <label htmlFor="actorPhoneNumber">
            <Tooltip title={translations.videoCard.internalVisibilityPhone[lang]}>
              <Typography variant="titleMedium">
                {translations.videoCard.phoneNumber[lang]}
              </Typography>
            </Tooltip>
            {PhoneNumberInput({ actorPhoneNumber, setActorPhoneNumber, lang, disabled: true })}
          </label>
        )}

        <label htmlFor="internalNote">
          <Tooltip title={translations.videoCard.internalVisibility[lang]}>
            <Typography
              variant="titleMedium"
              className={videoCard?.status === statuses.REVIEW ? 'required-field' : ''}
            >
              {translations.videoCard.messageToTeamMember[lang]}
            </Typography>
          </Tooltip>
          <textarea name="internalNote" id="internalNote" readOnly disabled value={internalNote} />
        </label>
      </div>
    </div>
  );
}

export default VideoRequestSection;
