import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Lock } from '@mui/icons-material';

import { useLanguage } from 'src/context/LanguageContext';
import { TabData } from './types';
import TabIcon from './TabIcon';
import TabLabel from './TabLabel';
import NavigatorTranslations from './NavigatorTranslations';

interface TabProps {
  tab: TabData;
  isActive: boolean;
}

function Tab({ tab, isActive }: TabProps) {
  const lang = useLanguage();
  const isPremiumLock = tab.isPremium && tab.disabled;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (isPremiumLock) {
      return;
    }
    if (tab.action) {
      tab.action();
    }
  };

  const renderContent = (
    <>
      <TabIcon
        tab={tab}
        isActive={!isPremiumLock && isActive}
        isHovered={!isPremiumLock && isHovered}
      />
      <TabLabel
        tab={tab}
        isActive={!isPremiumLock && isActive}
        isHovered={!isPremiumLock && isHovered}
      />
    </>
  );
  if (isPremiumLock) {
    return (
      <Tooltip title={NavigatorTranslations.premiumFeature[lang]} placement="right">
        <Grid
          role="button"
          container
          alignItems="center"
          spacing={0}
          direction="column"
          justifyContent="center"
          style={{ cursor: 'not-allowed', opacity: 0.5, position: 'relative' }}
          data-testid={`premium-tab-${tab.label}`}
          data-premium={`${isPremiumLock}`}
        >
          {renderContent}
          <Lock
            style={{
              position: 'absolute',
              top: '8px',
              right: '32px',
              transform: 'translateY(-50%)',
            }}
            fontSize="small"
            data-testid="lock-icon"
          />
        </Grid>
      </Tooltip>
    );
  }

  if (tab.action) {
    return (
      <Grid
        role="button"
        container
        alignItems="center"
        spacing={0}
        direction="column"
        justifyContent="center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
        data-testid={`action-tab-${tab.label}`}
        data-active={`${isActive}`}
      >
        {renderContent}
      </Grid>
    );
  }

  return (
    <Grid
      role="button"
      container
      component={NavLink}
      to={tab.path ?? ''}
      alignItems="center"
      spacing={0}
      direction="column"
      justifyContent="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      target={tab.openInNewTab ? '_blank' : ''}
      rel={tab.openInNewTab ? 'noopener noreferrer' : ''}
      data-testid={`link-tab-${tab.label}`}
      data-active={`${isActive}`}
    >
      {renderContent}
    </Grid>
  );
}

export default Tab;
