import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router';
import StoriesFilledIcon from 'src/components/common/icons/stories/StoriesFilled';
import TopCharts from './TopCharts';
import BestPerformance from './BestPerformance';
import TimeCard from './TimeCards';
import TotalImpressionsCard from './TotalImpressionsCard';
import LibraryFilledIcon from '../../../common/icons/library/LibraryFilled';
import ThemeContext from '../../../../utils/ThemeContext';
import { useAnalyticsOverviewApi } from './analyticsOverviewApi';

interface OverviewProps {
  selectedDuration: string;
  dates?: {
    startDate: Date | null;
    endDate: Date | null;
  };
}

interface BasicOrgData {
  orgNameSlug: string;
  encodedId: string;
}

interface OutletContext {
  basicOrgData: BasicOrgData;
}

function Overview({ selectedDuration, dates = { startDate: null, endDate: null } }: OverviewProps) {
  const { basicOrgData }: OutletContext = useOutletContext();

  const theme = useContext(ThemeContext);
  const analyticsOverviewApi = useAnalyticsOverviewApi();

  const [totalImpressionsData, setTotalImpressionsData] = useState([]);
  const [totalPlaysData, setTotalPlaysData] = useState([]);

  const [totalTimePlayedData, setTotalTimePlayedData] = useState([]);
  const [totalInteractionsData, setTotalInteractionsData] = useState([]);
  const [topViewTimeVideoCardData, setTopViewTimeVideoCardData] = useState(null);
  const [topImpressionsVideoCardData, setTopImpressionsVideoCardData] = useState(null);
  const [topVideoCardsData, setTopVideoCardsData] = useState([]);
  const [topStoriesData, setTopStoriesData] = useState([]);

  useEffect(() => {
    const encodedOrgId = basicOrgData.encodedId;
    const numberDuration = Number(selectedDuration);
    const variables: {
      startDate?: Date | null;
      endDate?: Date | null;
      encodedOrgId: string;
    } = { encodedOrgId };

    try {
      if (selectedDuration === 'custom') {
        variables.startDate = dates.startDate;
        variables.endDate = dates.endDate;
      } else if (typeof numberDuration === 'number' && !Number.isNaN(numberDuration)) {
        const calculatedStartDate = new Date();
        calculatedStartDate.setDate(calculatedStartDate.getDate() - (numberDuration - 1));
        const calculatedEndDate = new Date();
        variables.startDate = calculatedStartDate;
        variables.endDate = calculatedEndDate;
      }
    } catch (error) {
      // console.debug('file: Overview.js ~ error:', error);
    }

    const fetchData = async () => {
      try {
        const response = await analyticsOverviewApi.getTotalStatsData(variables);
        setTotalImpressionsData(response.playback.impression);
        setTotalTimePlayedData(response.playback.timePlayed);
        setTotalInteractionsData(response.interactions.total);
        setTotalPlaysData(response.playback.play);
      } catch (error) {
        // console.debug('file: Overview.js ~ error:', error);
      }
    };
    const fetchBestPerformanceViewTime = async () => {
      try {
        const response = await analyticsOverviewApi.getBestPerformanceViewTimeVideoCard(variables);

        if (response?.length > 0) {
          setTopViewTimeVideoCardData(response[0]);
        }
      } catch (error) {
        // console.debug('file: Overview.js ~ error:', error);
      }
    };

    const fetchBestPerformanceImpressions = async () => {
      try {
        const response =
          await analyticsOverviewApi.getBestPerformanceImpressionVideoCard(variables);

        if (response?.length > 0) {
          setTopImpressionsVideoCardData(response[0]);
          setTopVideoCardsData(response);
        }
      } catch (error) {
        // console.debug('file: Overview.js ~ error:', error);
      }
    };
    const fetchTopStories = async () => {
      try {
        const response = await analyticsOverviewApi.getBestPerformanceImpressionStory(variables);

        setTopStoriesData(response);
      } catch (error) {
        // console.error('Error fetching top stories:', error);
      }
    };

    fetchTopStories();

    fetchData();
    fetchBestPerformanceViewTime();
    fetchBestPerformanceImpressions();
  }, [
    selectedDuration,
    basicOrgData.encodedId,
    dates.endDate,
    dates.startDate,
    analyticsOverviewApi,
  ]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={3}
      sx={{
        pt: 4,
      }}
    >
      <Grid item container spacing={3}>
        <Grid item xs={12} xl={8} sm={12} md={12} lg={8}>
          <TotalImpressionsCard
            selectedDuration={selectedDuration}
            impressionsData={totalImpressionsData}
            playsData={totalPlaysData}
          />
        </Grid>
        <Grid item container xs={12} xl={4} sm={12} md={12} lg={4} spacing={2}>
          <Grid item xs={12} xl={6} sm={12} md={6} lg={6}>
            <TimeCard
              titleType="interactions"
              data={totalInteractionsData}
              type="interactions"
              selectedDuration={selectedDuration}
            />
          </Grid>
          <Grid item xs={12} xl={6} sm={12} md={6} lg={6}>
            <TimeCard
              titleType="total"
              data={totalTimePlayedData}
              type="time"
              selectedDuration={selectedDuration}
            />
          </Grid>
          <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
            <BestPerformance
              topViewTimeVideoCardData={topViewTimeVideoCardData}
              topImpressionsVideoCardData={topImpressionsVideoCardData}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} xl={4} sm={12} md={12} lg={4}>
          <TopCharts
            titleType="videos"
            icon={<LibraryFilledIcon fill={theme.palette.primary.main} />}
            data={topVideoCardsData}
          />
        </Grid>
        <Grid item xs={12} xl={4} sm={12} md={12} lg={4}>
          <TopCharts
            titleType="stories"
            icon={<StoriesFilledIcon fill={theme.palette.primary.main} />}
            data={topStoriesData}
          />
        </Grid>
        {/* <Grid item xs={12} xl={4} sm={12} md={12} lg={4}>
          <TopCharts
            titleType="endscreen"
            icon={<EndScreensFilledIcon fill={theme.palette.primary.main} />}
            dataset={createTopData(topEndScreenValues).map((item) => ({
              ...item,
              video: 'End screen 1 test test ',
            }))}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
}

Overview.propTypes = {
  selectedDuration: PropTypes.string.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
};

export default Overview;
