import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';

const translations = {
  Loading: {
    en: 'Loading...',
    sv: 'Laddar...',
  },
};

type BackgroundColor = 'background.default' | 'transparent';

interface LoadingIndicatorProps {
  backgroundColor?: BackgroundColor;
}

function LoadingIndicator({ backgroundColor = 'background.default' }: LoadingIndicatorProps) {
  const lang = useLanguage();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: 3,
        p: 4,
        minWidth: '300px',
        borderRadius: '16px',
        backgroundColor,
      }}
      data-testid="loading-indicator-box"
    >
      <Stack spacing={4} direction="column" alignItems="center">
        <CircularProgress color="secondary" data-testid="loading-indicator-progress" />
        <Typography
          variant="bodyMedium"
          color="text.secondary"
          role="alert"
          aria-live="polite"
          data-testid="loading-indicator-message"
        >
          {translations.Loading[lang]}
        </Typography>
      </Stack>
    </Box>
  );
}

export default LoadingIndicator;
