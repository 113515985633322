import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { gql } from '@apollo/client';
import { useOutletContext } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
  getDate,
  getMonth,
  add,
  getYear,
  differenceInDays,
  format,
  setDay,
  getDay,
  lastDayOfMonth,
} from 'date-fns';
import _ from 'lodash';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { translations } from '../../../utils/translationsSettings';

import useCheckPermissionsAndNavigate from './Permissions/CheckPermissionsAndNavigate';

const getReminderSettingsForOrg = (backOfficeClient, encodedOrgId) => {
  return backOfficeClient.query({
    query: gql`
      query GetAutomaticReminderSettings($encodedOrgId: String) {
        getAutomaticReminderSettings(encodedOrgId: $encodedOrgId) {
          id
          orgId
          deleteDays
          deleteActive
          reminderInterval {
            days
            sms
            email
          }
          active
        }
      }
    `,
    variables: { encodedOrgId },
  });
};

const getAutoSettingsForOrg = (backOfficeClient, encodedOrgId) => {
  return backOfficeClient.query({
    query: gql`
      query GetAutomaticRequestSettings($encodedOrgId: String) {
        getAutomaticRequestSettings(encodedOrgId: $encodedOrgId) {
          intervalType
          intervalValue
          numberQuestions
          active
          lastSent
          sendSms
          requestDaysSinceLastSent
          videoCardDaysSinceLastAnswered
          message
        }
      }
    `,
    variables: { encodedOrgId },
  });
};

const saveAutomationSettings = (
  backOfficeClient,
  encodedOrgId,
  automationActive,
  intervalType,
  intervalValue,
  numberQuestions,
  sendSms,
  message,
) => {
  const input = {
    intervalType,
    intervalValue,
    numberQuestions,
    active: automationActive,
    sendSms,
    message: encodeURIComponent(message),
  };
  return backOfficeClient.mutate({
    mutation: gql`
      mutation SaveAutomaticRequestSettings(
        $encodedOrgId: String
        $input: AutomaticRequestSettingsInput
      ) {
        saveAutomaticRequestSettings(encodedOrgId: $encodedOrgId, input: $input) {
          statusMessage
        }
      }
    `,
    variables: { encodedOrgId, input },
  });
};

const saveReminderSettings = (
  backOfficeClient,
  encodedOrgId,
  reminderActive,
  deleteDays,
  deleteActive,
  reminderInterval,
) => {
  const reminderIntervalInput = _.map(reminderInterval, ({ days, sms, email }) => ({
    days: days ? Number(days) : 0,
    sms,
    email,
  }));
  const input = {
    active: reminderActive,
    deleteDays,
    deleteActive,
    reminderInterval: reminderIntervalInput,
  };
  return backOfficeClient.mutate({
    mutation: gql`
      mutation SaveAutomaticReminderSettings(
        $encodedOrgId: String
        $input: AutomaticReminderSettingsInput
      ) {
        saveAutomaticReminderSettings(encodedOrgId: $encodedOrgId, input: $input) {
          statusMessage
        }
      }
    `,
    variables: { encodedOrgId, input },
  });
};

const intervalDaysPerType = {
  WEEKLY: 7,
  BIWEEKLY: 7,
  MONTHLY: 31,
};
const intervalDays = {
  MONTHLY: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
  ],
  BIWEEKLY: [
    { value: 1, label: 'monday' },
    { value: 2, label: 'tuesday' },
    { value: 3, label: 'wednesday' },
    { value: 4, label: 'thursday' },
    { value: 5, label: 'friday' },
    { value: 6, label: 'saturday' },
    { value: 0, label: 'sunday' },
  ],
  WEEKLY: [
    { value: 1, label: 'monday' },
    { value: 2, label: 'tuesday' },
    { value: 3, label: 'wednesday' },
    { value: 4, label: 'thursday' },
    { value: 5, label: 'friday' },
    { value: 6, label: 'saturday' },
    { value: 0, label: 'sunday' },
  ],
};
export default function Automation() {
  const lang = useLanguage();
  const backOfficeClient = useBackOfficeApolloClient();
  const { basicOrgData } = useOutletContext();

  const [automationActivated, setAutomationActivated] = useState(false);
  const [remindersActivated, setRemindersActivated] = useState(false);
  const [reqInterval, setReqInterval] = useState('MONTHLY');
  const [intervalDay, setIntervalDay] = useState(1);
  const [numQuestions, setNumQuestions] = useState(3);
  const [lastSent, setLastSent] = useState(null);
  const [message, setMessage] = useState('');

  const [deleteRequestsActivated, setDeleteRequestsActivated] = useState(false);
  const [sendSms, setSendSms] = useState(false);

  const [deleteRequestAfterDays, setDeleteRequestAfterDays] = useState(30);
  const [reminderIntervals, setReminderIntervals] = useState(null);

  const [automationSettings, setAutomationSettings] = useState(false);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }, [error]);

  useEffect(() => {
    getAutoSettingsForOrg(backOfficeClient, basicOrgData.encodedId).then((res) => {
      const data = res.data.getAutomaticRequestSettings;
      if (data) {
        setAutomationActivated(data.active);
        setIntervalDay(data.intervalValue);
        setReqInterval(data.intervalType?.toUpperCase());
        setNumQuestions(data.numberQuestions);
        if (data.lastSent) {
          setLastSent(new Date(parseInt(data?.lastSent)) ?? null);
        }
        setSendSms(data.sendSms);
        setAutomationSettings(true);
        setMessage(data?.message ?? '');
      }
    });
    getReminderSettingsForOrg(backOfficeClient, basicOrgData.encodedId).then((res) => {
      const data = res.data.getAutomaticReminderSettings;
      if (data) {
        setRemindersActivated(data.active);
        setDeleteRequestAfterDays(data.deleteDays);
        setDeleteRequestsActivated(data.deleteActive);
        setReminderIntervals(data.reminderInterval);
      } else {
        setReminderIntervals([{ days: 3, sms: false, email: true }]);
      }
    });
  }, []);

  useEffect(() => {
    if (automationSettings) {
      setIntervalDay(1);
    }
  }, [reqInterval]);

  const saveChanges = () => {
    saveAutomationSettings(
      backOfficeClient,
      basicOrgData.encodedId,
      automationActivated,
      reqInterval,
      intervalDay,
      numQuestions,
      sendSms,
      message,
    )
      .then((r) => {
        saveReminderSettings(
          backOfficeClient,
          basicOrgData.encodedId,
          remindersActivated,
          deleteRequestAfterDays,
          deleteRequestsActivated,
          reminderIntervals,
        )
          .then((r) => {
            setInfo(translations.savedSuccess[lang]);
          })
          .catch((e) => {
            setError(translations.savedError[lang]);
          });
      })
      .catch((e) => {
        setError(translations.savedError[lang]);
      });
  };

  const getNextValidDateByMonth = (isThisMonth, today) => {
    const nextMonth = add(today, { months: 1 });
    const month = isThisMonth ? getMonth(today) : getMonth(nextMonth);
    const year = isThisMonth ? getYear(today) : getYear(nextMonth);
    const lastDayOfNextMonth = getDate(lastDayOfMonth(month));
    const sendOutDay = intervalDay < lastDayOfNextMonth ? intervalDay : lastDayOfNextMonth;

    return new Date(year, month, sendOutDay);
  };
  const getNextValidDateByWeek = (isThisWeek, today) => {
    const week = isThisWeek ? today : add(today, { weeks: 1 });
    return setDay(week, intervalDay, { weekStartsOn: 1 });
  };
  const getNextAutoReqDate = () => {
    const today = new Date();
    const sendOutTime = 13;
    if (reqInterval === 'MONTHLY') {
      const todaysDate = getDate(today);

      const lastDayOfCurrentMonth = lastDayOfMonth(today);
      const isThisMonth = todaysDate < getDate(lastDayOfCurrentMonth) && intervalDay > todaysDate;

      return getNextValidDateByMonth(isThisMonth, today);
    }
    if (reqInterval === 'WEEKLY') {
      const currentWeekDay = getDay(today);
      const isThisWeek = (intervalDay === 0 && currentWeekDay > 0) || currentWeekDay < intervalDay;
      return getNextValidDateByWeek(isThisWeek, today);
    }
    if (reqInterval === 'BIWEEKLY') {
      const currentWeekDay = getDay(today);
      if (!lastSent) {
        const isThisWeek =
          (intervalDay === 0 && currentWeekDay > 0) || currentWeekDay < intervalDay;
        return getNextValidDateByWeek(isThisWeek, today);
      }
      const daysSinceLast = differenceInDays(today, lastSent);
      const daysUntilNext = 14 - daysSinceLast;

      const nextSendWeek = add(today, { days: daysUntilNext });

      return setDay(nextSendWeek, intervalDay, { weekStartsOn: 1 });
    }
  };
  const modifyReminderIntervalDays = (i, val) => {
    const intervalsCopy = _.cloneDeep(reminderIntervals);
    intervalsCopy[i].days = val;
    setReminderIntervals(intervalsCopy);
  };
  const modifyReminderInterval = (i, type) => {
    const intervalsCopy = _.cloneDeep(reminderIntervals);
    intervalsCopy[i][type] = !reminderIntervals[i][type];
    setReminderIntervals(intervalsCopy);
  };
  const removeReminderInterval = (i) => {
    const intervalsCopy = _.cloneDeep(reminderIntervals);
    _.remove(intervalsCopy, (val, key) => key === i);
    setReminderIntervals(intervalsCopy);
  };
  const addReminderInterval = () => {
    const intervalsCopy = _.cloneDeep(reminderIntervals);
    intervalsCopy.push({ days: 3, sms: false, email: false });
    setReminderIntervals(intervalsCopy);
  };
  const hasAutoRequest = basicOrgData?.premiumFeatures?.autoRequests;
  const hasAutoReminder = basicOrgData?.premiumFeatures?.autoReminders;

  if (userLoading) return <LoadingIndicator />;

  const infoMsg = <div className="info-pop">{info}</div>;
  const errorMsg = <div className="error-pop">{error}</div>;

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Container maxWidth="md">
          <div className="inner">
            {infoMsg}
            {errorMsg}
            <div>
              <div className="btn-row">
                <Button variant="contained" color="primary" disableElevation onClick={saveChanges}>
                  {translations.save[lang]}
                </Button>
              </div>
              <div className="section">
                <Typography variant="labelSmall" color="error">
                  {!hasAutoRequest && translations.premiumFeature[lang]}
                </Typography>

                <label className="switch">
                  <Typography variant="BodyLarge">
                    {translations.automationSettings.activateAutomatedRequests[lang]}
                  </Typography>
                  <Switch
                    color="secondary"
                    checked={hasAutoRequest ? automationActivated : false}
                    disabled={!hasAutoRequest}
                    onChange={() => setAutomationActivated(!automationActivated)}
                  />
                </label>
                <div style={{ opacity: automationActivated && hasAutoRequest ? 1 : 0.3 }}>
                  <Typography variant="titleLarge">
                    {translations.automationSettings.intervalBetweenAutomatedRequests[lang]}
                  </Typography>
                  <br />

                  <FormControl sx={{ m: 1, minWidth: 200 }} className="form-control">
                    <InputLabel id="request-interval-label">
                      {translations.automationSettings.interval[lang]}
                    </InputLabel>
                    <Select
                      labelId="request-interval-label"
                      id="request-interval-select"
                      value={reqInterval}
                      label="Interval"
                      onChange={(e) => setReqInterval(e.target.value)}
                      disabled={!automationActivated}
                    >
                      <MenuItem value="MONTHLY">
                        {translations.automationSettings.monthly[lang]}
                      </MenuItem>
                      <MenuItem value="BIWEEKLY">
                        {translations.automationSettings.biweekly[lang]}
                      </MenuItem>
                      <MenuItem value="WEEKLY">
                        {translations.automationSettings.weekly[lang]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 200 }} className="form-control">
                    <InputLabel id="day-interval-label">
                      {translations.automationSettings.dayOf[lang]}
                    </InputLabel>
                    <Select
                      labelId="day-interval-label"
                      id="day-interval-select"
                      value={intervalDay}
                      label="Day of"
                      onChange={(e) => setIntervalDay(e.target.value)}
                      disabled={!automationActivated}
                    >
                      {_.map(intervalDays[reqInterval], (day) => {
                        return (
                          <MenuItem key={day.value} value={day.value}>
                            {translations.automationSettings[day.label]?.[lang] || day.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <Typography variant="bodyLarge">
                    {translations.automationSettings.nextRequest[lang]}:{' '}
                    {format(getNextAutoReqDate(), 'yyyy-MM-dd')
                      ? format(getNextAutoReqDate(), 'yyyy-MM-dd')
                      : '- - -'}
                  </Typography>
                  <br />
                  <Typography variant="bodyLarge">
                    {translations.automationSettings.lastRequest[lang]}:{' '}
                    {lastSent ? (
                      format(lastSent, 'yyyy-MM-dd')
                    ) : (
                      <i>{translations.automationSettings.noPreviousRequests[lang]}</i>
                    )}
                  </Typography>
                  <label>
                    <Typography variant="bodyLarge">
                      {translations.automationSettings.sendRequestViaSMS[lang]}
                    </Typography>
                    <Switch
                      color="secondary"
                      checked={sendSms}
                      onChange={() => setSendSms(!sendSms)}
                    />
                  </label>
                  <label>
                    <Typography variant="bodyLarge">
                      {translations.automationSettings.sendRequestViaEmail[lang]}
                    </Typography>
                    <Switch color="secondary" checked disabled />
                  </label>
                </div>
              </div>

              <div
                className="section"
                style={{ opacity: automationActivated && hasAutoRequest ? 1 : 0.3 }}
              >
                <Typography variant="titleLarge">
                  {translations.automationSettings.message[lang]}
                </Typography>
                <br />
                <FormControl sx={{ m: 1, minWidth: 200 }} className="form-control" id="messageArea">
                  <textarea
                    name="messageArea"
                    id="messageArea"
                    style={{ width: '100%', height: '100px', minWidth: '560px' }}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </FormControl>
              </div>

              <div
                className="section"
                style={{ opacity: automationActivated && hasAutoRequest ? 1 : 0.3 }}
              >
                <Typography variant="titleLarge">
                  {translations.automationSettings.numberOfQuestions[lang]}
                </Typography>
                <br />
                <Typography variant="bodyLarge">
                  {translations.automationSettings.numberOfQuestionsInfo[lang]}
                </Typography>
                <br />

                <FormControl sx={{ m: 1, minWidth: 200 }} className="form-control">
                  <InputLabel id="num-questions-label">
                    {translations.automationSettings.numQuestions[lang]}
                  </InputLabel>
                  <Select
                    labelId="num-questions-label"
                    id="num-questions-select"
                    value={numQuestions}
                    label="Num. questionsf"
                    onChange={(e) => setNumQuestions(e.target.value)}
                    disabled={!automationActivated}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="section">
                <Typography variant="labelSmall" color="error">
                  {!hasAutoRequest && translations.premiumFeature[lang]}
                </Typography>

                <label htmlFor="remindersActivated-toggle">
                  <Typography variant="bodyLarge" id="remindersActivated-toggle">
                    {translations.automationSettings.activateReminders[lang]}
                  </Typography>
                  <Switch
                    color="secondary"
                    id="remindersActivated-toggle"
                    checked={hasAutoReminder ? remindersActivated : false}
                    disabled={!hasAutoReminder}
                    onChange={() => setRemindersActivated(!remindersActivated)}
                  />
                </label>
                <div style={{ opacity: remindersActivated && hasAutoReminder ? 1 : 0.3 }}>
                  <Typography variant="titleLarge">
                    {translations.automationSettings.reminders[lang]}
                  </Typography>
                  <br />
                  <Typography variant="bodyLarge">
                    {translations.automationSettings.remindersInfo[lang]}
                  </Typography>
                  <table className="reminders-table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{translations.automationSettings.delay[lang]}</th>
                        <th>{translations.automationSettings.sms[lang]}</th>
                        <th colSpan={3}>{translations.automationSettings.email[lang]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(reminderIntervals, (rI, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <TextField
                                id="outlined-number"
                                label={translations.automationSettings.days[lang]}
                                type="number"
                                value={rI.days}
                                onChange={(e) => modifyReminderIntervalDays(i, e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </td>
                            <td>
                              <Checkbox
                                aria-label={translations.automationSettings.smsAriaLabel[lang]}
                                checked={rI.sms}
                                onChange={() => modifyReminderInterval(i, 'sms')}
                              />
                            </td>
                            <td>
                              <Checkbox
                                aria-label={translations.automationSettings.emailAriaLabel[lang]}
                                checked={rI.email}
                                onChange={() => modifyReminderInterval(i, 'email')}
                              />
                            </td>
                            <td className="less-padding">
                              <IconButton
                                onClick={() => removeReminderInterval(i)}
                                aria-label={translations.automationSettings.removeReminder[lang]}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={6}>
                          <Button
                            startIcon={<AddCircleIcon />}
                            onClick={() => addReminderInterval()}
                          >
                            {' '}
                            {translations.automationSettings.addReminder[lang]}
                          </Button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div className="section">
                <Typography variant="labelSmall" color="error">
                  {!hasAutoRequest && translations.premiumFeature[lang]}
                </Typography>

                <label>
                  <Typography variant="bodyLarge">
                    {translations.automationSettings.activateAutomaticDelete[lang]}
                  </Typography>
                  <Switch
                    color="secondary"
                    checked={hasAutoRequest ? deleteRequestsActivated : false}
                    disabled={!hasAutoRequest}
                    onChange={() => setDeleteRequestsActivated(!deleteRequestsActivated)}
                  />
                </label>
                <div style={{ opacity: deleteRequestsActivated && hasAutoRequest ? 1 : 0.3 }}>
                  <Typography variant="titleLarge">
                    {translations.automationSettings.deleteRequests[lang]}
                  </Typography>
                  <br />
                  <Typography variant="bodyLarge">
                    {translations.automationSettings.deleteRequestsInfo[lang]}
                  </Typography>

                  <label className="with-end-label">
                    <TextField
                      id="outlined-number"
                      label="Days"
                      type="number"
                      value={deleteRequestAfterDays}
                      onChange={(e) => {
                        setDeleteRequestAfterDays(Number(e.target.value));
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: '8px' }}
                    />
                    <Typography variant="bodyLarge">
                      {translations.automationSettings.daysAfter[lang]}
                    </Typography>
                  </label>
                </div>
              </div>
            </div>
            <div className="btn-row">
              <Button variant="contained" color="primary" disableElevation onClick={saveChanges}>
                {translations.save[lang]}
              </Button>
            </div>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}
