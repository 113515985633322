import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAdminApolloClient } from 'src/context/ApolloClientContext';

const ORG_NAME_SLUG_SEARCH_PARAM_KEY = 'orgNameSlug';

interface LoginDispatcherPathParams {
  orgNameSlug: string | undefined;
}

export const loginDispatcherPath = ({ orgNameSlug }: LoginDispatcherPathParams) => {
  const params = new URLSearchParams();
  if (orgNameSlug) {
    params.set(ORG_NAME_SLUG_SEARCH_PARAM_KEY, orgNameSlug);
  }
  return `/login-dispatch?${params.toString()}`;
};

interface AdminMeResponse {
  adminUser: {
    id: number;
  };
}

const ADMIN_ME_QUERY = gql`
  query AdminUser {
    adminUser {
      id
    }
  }
`;

// The LoginDispatcher component redirects the user:
//  - to /admin, if they are superadmin
//  - to specific back office, if back office preference provided
//  - to /select-backoffice
export default function LoginDispatcher() {
  const navigate = useNavigate();
  const adminClient = useAdminApolloClient();
  const [searchParams] = useSearchParams();

  const orgNameSlug = searchParams.get(ORG_NAME_SLUG_SEARCH_PARAM_KEY);
  const backOfficePath = orgNameSlug ? `/${orgNameSlug}/backoffice/` : '/select-backoffice';

  const { data, loading } = useQuery<AdminMeResponse>(ADMIN_ME_QUERY, { client: adminClient });

  useEffect(() => {
    if (data != null) {
      // Successfully looked up "me" as admin, user is admin.
      navigate('/admin', { replace: true });
    } else if (!loading) {
      // Lookup of "me" as admin failed, user is not admin.
      navigate(backOfficePath, { replace: true });
    }
  }, [data, loading, backOfficePath, navigate]);

  return null;
}
