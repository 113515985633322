const translations = {
  text: {
    en: 'This site is protected by reCAPTCHA and the Google ',
    sv: 'Denna sida är skyddad av reCAPTCHA och Google ',
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    sv: 'Integritetspolicy',
  },
  privacyPolicyLink: {
    en: 'https://policies.google.com/privacy',
    sv: 'https://policies.google.com/privacy?hl=sv',
  },
  and: {
    en: 'and ',
    sv: 'och ',
  },
  termsOfService: {
    en: 'Terms of Service',
    sv: 'Användarvillkor',
  },
  termsOfServiceLink: {
    en: 'https://policies.google.com/terms',
    sv: 'https://policies.google.com/terms?hl=sv',
  },
  apply: {
    en: 'apply.',
    sv: 'tillämpas.',
  },
};

export default translations;
