import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { EnhancedStoryType, Order } from './types';
import translations from './translations';

interface StoriesTableHeadProps {
  order: Order;
  orderBy: keyof EnhancedStoryType;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof EnhancedStoryType) => void;
  lang: Language;
}

function StoriesTableHead({ order, orderBy, onRequestSort, lang }: StoriesTableHeadProps) {
  const createSortHandler =
    (property: keyof EnhancedStoryType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sortDirection={orderBy === 'title' ? order : false}
          style={{ padding: '16px 12px' }}
        >
          <TableSortLabel
            active={orderBy === 'title'}
            direction={orderBy === 'title' ? order : 'asc'}
            onClick={createSortHandler('title')}
            aria-label={`${translations.ariaLabel.sortBy[lang]} ${translations.title[lang]}`}
            data-testid="sort-title"
          >
            <Typography variant="titleSmall" component="div">
              {translations.title[lang]}
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell sortDirection={orderBy === 'totalImpressions' ? order : false}>
          <TableSortLabel
            active={orderBy === 'totalImpressions'}
            direction={orderBy === 'totalImpressions' ? order : 'asc'}
            onClick={createSortHandler('totalImpressions')}
            aria-label={`${translations.ariaLabel.sortBy[lang]} ${translations.impressions[lang]}`}
            data-testid="sort-totalImpressions"
          >
            <Typography variant="titleSmall" component="div">
              {translations.impressions[lang]}
            </Typography>
          </TableSortLabel>
        </TableCell>
        <TableCell sortDirection={orderBy === 'totalTimePlayed' ? order : false}>
          <TableSortLabel
            active={orderBy === 'totalTimePlayed'}
            direction={orderBy === 'totalTimePlayed' ? order : 'asc'}
            onClick={createSortHandler('totalTimePlayed')}
            aria-label={`${translations.ariaLabel.sortBy[lang]} ${translations.viewTime[lang]}`}
            data-testid="sort-totalTimePlayed"
          >
            <Typography variant="titleSmall" component="div">
              {translations.viewTime[lang]}
            </Typography>
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default StoriesTableHead;
