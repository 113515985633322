import { DataGrid } from '@mui/x-data-grid';

export default function DataGridTable(props) {
  return (
    <DataGrid
      pageSize={12}
      disableRowSelectionOnClick
      pageSizeOptions={[5]}
      {...props}
      sx={
        ({
          border: 'none',
          fontFamily: ['Inter', 'sans-serif'].join(','),

          '& .MuiDataGrid-columnHeaderTitle': {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            /* identical to box height, or 143% */
            letterSpacing: '0.1px',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-row': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            border: 'none',
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            background: '#f2f4f9',
          },
          '& .MuiDataGrid-row:hover': {},
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cellContent': {
            fontFamily: ['Inter', 'sans-serif'].join(','),
            fontSize: '14px',
            fontWeight: 400,
          },
          '& .MuiDataGrid-row:nth-of-type(2n)': {
            background: '#fff',
            '&:hover': {},
          },
        },
        { ...props.sx })
      }
    />
  );
}
