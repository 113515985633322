import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { statuses } from '../../../utils/utils';
import { translations } from '../../../utils/translations';
import style from './styles.module.scss';

const ORGANIZATIONS_QUERY = gql`
  query Organizations {
    organizations {
      id
      orgName
      orgNameSlug
      widgetIsActive
      videoCards {
        status
      }
      users {
        id
      }
    }
  }
`;

function countVideosByStatus(videos, status) {
  return videos.filter((video) => video.status === status).length;
}

export default function OnboardingStats() {
  const lang = useLanguage();
  const scopedTranslations = translations.admin.onboardingStatistics;

  const { data, loading } = useQuery(ORGANIZATIONS_QUERY);
  const organizations = data?.organizations ?? [];

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={style.container}>
      <h1>{scopedTranslations.header[lang]}</h1>
      <table className={style.table}>
        <thead className={style.header}>
          <tr>
            <th>{scopedTranslations.table.orgName[lang]}</th>
            <th>{scopedTranslations.table.adminNumber[lang]}</th>
            <th>{scopedTranslations.table.suggestionsNumber[lang]}</th>
            <th>{scopedTranslations.table.pendingNumber[lang]}</th>
            <th>{scopedTranslations.table.reviewNumber[lang]}</th>
            <th>{scopedTranslations.table.approvedNumber[lang]}</th>
            <th>{scopedTranslations.table.liveNumber[lang]}</th>
            <th>{scopedTranslations.table.isWidgetLive[lang]}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {organizations.map((organization) => {
            return (
              <tr key={organization.id}>
                <td>{organization.orgName}</td>
                <td>{organization.users.length}</td>
                <td>{countVideosByStatus(organization.videoCards, statuses.SUGGESTION)}</td>
                <td>{countVideosByStatus(organization.videoCards, statuses.PENDING)}</td>
                <td>{countVideosByStatus(organization.videoCards, statuses.REVIEW)}</td>
                <td>{countVideosByStatus(organization.videoCards, statuses.APPROVED)}</td>
                <td>{countVideosByStatus(organization.videoCards, statuses.LIVE)}</td>
                <td>
                  {organization.widgetIsActive ? translations.yes[lang] : translations.no[lang]}
                </td>
                <td>
                  <Link to={`/${organization.orgNameSlug}/backoffice/analytics`}>
                    {scopedTranslations.table.analytics[lang]}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
