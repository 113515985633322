import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import HeaderTitle from '../../common/HeaderTitle';

export default function VideoFunnelHeader({ isEdit, onBackClick }) {
  const lang = useLanguage();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={onBackClick}>
        <ArrowBackIcon fontSize="large" />
      </IconButton>
      <HeaderTitle
        title={
          isEdit
            ? `${translationsStoryline.editVideoFunnel[lang]}`
            : `${translationsStoryline.createNewVideoFunnel[lang]}`
        }
      />
    </Box>
  );
}
