import { uniqueId } from 'lodash';

export const translations = {
  closeAriaLabel: {
    en: 'Close',
    sv: 'Stäng',
  },
  cancel: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  closeBeforeSave: {
    en: 'Close before save?',
    sv: 'Stäng utan att spara?',
  },
  areYouSureClose: {
    en: 'Are you sure you want to close without saving?',
    sv: 'Är du säker på att du vill stänga utan att spara?',
  },
  confirm: {
    en: 'Confirm',
    sv: 'Bekräfta',
  },
  noCancel: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  save: {
    en: 'Save',
    sv: 'Spara',
  },
  premiumFeature: {
    en: 'Premium Feature',
    sv: 'Premiumfunktion',
  },
  savedSuccess: {
    en: 'Saved successfully',
    sv: 'Sparat',
  },
  savedError: {
    en: 'Error saving',
    sv: 'Fel vid sparning',
  },
  failedToFetchData: {
    en: 'Failed to fetch data',
    sv: 'Kunde inte hämta data',
  },
  category: {
    delete: {
      en: 'Delete',
      sv: 'Ta bort',
    },
    save: {
      en: 'Save',
      sv: 'Spara',
    },
    addCategory: {
      en: 'Add category',
      sv: 'Lägg till kategori',
    },
    editCategory: {
      en: 'Edit category',
      sv: 'Redigera kategori',
    },
    deleteTitle: {
      en: 'Delete category',
      sv: 'Ta bort kategori',
    },
    deleteDescription: {
      en: 'Are you sure you want to delete this category?',
      sv: 'Är du säker på att du vill ta bort denna kategori?',
    },
    deleteSubDescription: {
      en: "Questions within this category won't be deleted. They will be moved to the 'Uncategorized' category.",
      sv: 'Frågor inom denna kategori kommer inte att tas bort. De kommer att flyttas till kategorin "Okategoriserade".',
    },
    deleteConfirmBtnText: {
      en: 'Delete!',
      sv: 'Ta bort!',
    },
    deleteCancelBtnText: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
    createCategory: {
      en: 'Create category',
      sv: 'Skapa kategori',
    },
    newCategoryLabel: {
      en: 'Create category',
      sv: 'Skapa kategori',
    },
    categoriesAndQuestions: {
      en: 'Categories & Questions',
      sv: 'Kategorier & Frågor',
    },
    uncategorized: {
      en: 'Uncategorized',
      sv: 'Okategoriserad',
    },
    addCategory: {
      en: 'Add Category',
      sv: 'Lägg till kategori',
    },
  },
  question: {
    createQuestion: {
      en: 'Create Question',
      sv: 'Skapa fråga',
    },
    newQuestionLabel: {
      en: 'Question ',
      sv: 'Fråga ',
    },
    newQuestionCategoryLabel: {
      en: 'Category ',
      sv: 'Kategori ',
    },
    addQuestion: {
      en: 'Add Question',
      sv: 'Lägg till fråga',
    },
    editQuestion: {
      en: 'Edit Question',
      sv: 'Redigera fråga',
    },
    showMore: {
      en: 'Show questions',
      sv: 'Visa frågor',
    },
    delete: {
      en: 'Delete',
      sv: 'Ta bort',
    },
    save: {
      en: 'Save',
      sv: 'Spara',
    },
    deleteTitle: {
      en: 'Delete question',
      sv: 'Ta bort fråga',
    },
    deleteDescription: {
      en: 'Are you sure you want to delete this question?',
      sv: 'Är du säker på att du vill ta bort denna fråga?',
    },
    deleteConfirmBtnText: {
      en: 'Delete!',
      sv: 'Ta bort!',
    },
    deleteCancelBtnText: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
    dataGrid: {
      header: {
        question: {
          en: 'Question',
          sv: 'Fråga',
        },
        automationActivated: {
          en: 'Automation activated',
          sv: 'Automation aktiverad',
        },
      },
    },
  },
  recipient: {
    updateSuccess: {
      en: 'Recipient updated successfully',
      sv: 'Mottagare uppdaterad',
    },
    createSuccess: {
      en: 'Recipient created successfully',
      sv: 'Mottagare skapad',
    },
    updateError: {
      en: 'Error updating recipient',
      sv: 'Fel vid uppdatering av mottagare',
    },
    createError: {
      en: 'Error creating recipient',
      sv: 'Fel vid skapande av mottagare',
    },
    deleteSuccess: {
      en: 'Recipient deleted',
      sv: 'Mottagare borttagen',
    },
    deleteError: {
      en: 'Error deleting recipient',
      sv: 'Fel vid borttagning av mottagare',
    },
    importFromCsv: {
      en: 'Import from .csv',
      sv: 'Importera från .csv',
    },
    importRecipientsFromCsv: {
      en: 'Import recipients from .csv',
      sv: 'Importera mottagare från .csv-fil',
    },
    noRecipientsUploaded: {
      en: 'No uploaded recipients yet.',
      sv: 'Inga uppladdade mottagare än.',
    },
    added: {
      en: 'added',
      sv: 'tillagd',
    },
    upload: {
      en: 'Upload',
      sv: 'Ladda upp',
    },
    anotherFile: {
      en: 'another file',
      sv: 'annan fil',
    },
    csvFile: {
      en: '.csv file',
      sv: '.csv-fil',
    },
    fillFile: {
      en: 'Fill out the template with your recipients.',
      sv: 'Fyll i mallen med information om dina mottagare.',
    },
    verifyData: {
      en: 'Verify and approve data',
      sv: 'Verifiera och godkänn data',
    },
    approveAndImport: {
      en: 'Approve & import',
      sv: 'Godkänn & importera',
    },
    downloadTemplate: {
      en: 'Download csv template ',
      sv: 'Ladda ner csv-mall',
    },
    addRecipient: {
      en: 'Add recipient',
      sv: 'Lägg till mottagare',
    },
    search: {
      en: 'Search',
      sv: 'Sök',
    },
    dataGrid: {
      header: {
        noRowsLabel: {
          en: 'No rows',
          sv: 'Inga rader',
        },
        firstName: {
          en: 'First name',
          sv: 'Förnamn',
        },
        lastName: {
          en: 'Last name',
          sv: 'Efternamn',
        },
        title: {
          en: 'Title/Role',
          sv: 'Titel/Roll',
        },
        leader: {
          en: 'Leadership position',
          sv: 'Ledarposition',
        },
        email: {
          en: 'E-mail',
          sv: 'E-post',
        },
        edit: {
          en: 'Edit',
          sv: 'Redigera',
        },
        phoneNumber: {
          en: 'Phone',
          sv: 'Telefon',
        },
        receiveAutoRequests: {
          en: 'Automation',
          sv: 'Automatisering',
        },
      },
    },
  },
  settingsMenu: {
    settings: {
      en: 'Settings',
      sv: 'Inställningar',
    },
    general: {
      en: 'General',
      sv: 'Generella',
    },
    widgetScript: {
      en: 'Widget script',
      sv: 'Widget script',
    },
    automation: {
      en: 'Automation',
      sv: 'Automatisering',
    },
    recipients: {
      en: 'Recipients',
      sv: 'Mottagare',
    },
    questions: {
      en: 'Questions',
      sv: 'Frågor',
    },
    styling: {
      en: 'Styling',
      sv: 'Stil',
    },
    videoRequestEmail: {
      en: 'Video Request email',
      sv: 'Videoförfrågan email',
    },
    permissions: {
      en: 'Permissions',
      sv: 'Behörigheter',
    },
  },
  generalSettings: {
    widgetScript: {
      en: 'Widget script',
      sv: 'Widget script',
    },
    widgetScriptInfo: {
      sv: [
        'För att aktivera widgeten på din webbplats måste du kopiera och implementera skriptet på din webbplats. Om du vill ändra widgetens skript måste du kopiera och återimplementera skriptet på din webbplats. Observera att både ',
        <strong key={uniqueId()}> async </strong>,
        ' och ',
        <strong key={uniqueId()}>defer</strong>,
        '-inställningarna inte är kompatibla med ',
        <strong key={uniqueId()}>Google Tag Manager</strong>,
        '.',
      ],
      en: [
        'To enable the widget on your website, you must copy and implement the script onto your site. Modifying the widget script requires you to copy and re-implement the script on your website. Please note that both the ',
        <strong key={uniqueId()}> async </strong>,
        ' and ',
        <strong key={uniqueId()}>defer</strong>,
        ' settings are not compatible with ',
        <strong key={uniqueId()}>Google Tag Manager</strong>,
        '.',
      ],
    },
    script: {
      attentionReImplementation: {
        en: 'Attention: Modifying the widget script settings means that you will need to copy and re-implement the script on your website to implement the change.',
        sv: 'Observera: Om du ändrar widgetens skriptinställningar innebär det att du måste kopiera och återimplementera skriptet på din webbplats för att genomföra ändringen.',
      },
      copyToClipboardFab: {
        en: 'Copy to clipboard',
        sv: 'Kopiera till urklipp',
      },
      copiedToClipboard: {
        en: 'Copied to clipboard',
        sv: 'Kopierat till urklipp',
      },
      copyFailed: {
        en: 'Copy failed',
        sv: 'Kopiering misslyckades',
      },
      moreInfo: {
        en: 'More info',
        sv: 'Mer info',
      },
      lessInfo: {
        en: 'Less info',
        sv: 'Mindre info',
      },
      useAsync: {
        en: 'Use async',
        sv: 'Använd async',
      },
      async: {
        en: 'Async (Recommended)',
        sv: 'Async (Rekommenderas)',
      },
      asyncShortDescription: {
        en: 'Using the “async” on a script tag enhances webpage performance by enabling independent script loading, preventing rendering blockages, and promoting faster user interaction.',
        sv: 'Att använda “async” på en skripttagg förbättrar webbsidans prestanda genom att möjliggöra oberoende laddning av skript, förhindra renderingsblockeringar och främja snabbare användarinteraktion.',
      },
      asyncDescription: {
        en: [
          `When you use the "async" attribute on a script tag, it brings great advantages to your webpage! It allows the script to be loaded independently, without blocking the rendering of the rest of the page. This means that your webpage will continue to load and display its content smoothly while the script is being fetched.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `The beauty of "async" is that it promotes a faster and more responsive user experience. As soon as the script is downloaded, it will be executed promptly, even if other resources on the page are still loading. This enables your users to interact with your webpage and enjoy its functionality without unnecessary delays.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `By using "async," you can optimize the performance of your webpage, especially when you have scripts that don't rely on other elements of the page to function correctly. It allows those scripts to be loaded independently, reducing any potential bottlenecks and ensuring a smooth user experience.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `It's important to keep in mind that when using "async," the order in which the scripts are executed may not be guaranteed, particularly if they depend on each other. However, this trade-off is well worth it when it comes to achieving faster loading times and improved interactivity for your users.`,
        ],
        sv: [
          `När du använder attributet "async" på en script-tag ger det fantastiska fördelar för din webbsida! Det gör det möjligt för skriptet att laddas oberoende, utan att blockera renderingen av resten av sidan. Det innebär att din webbsida kommer att fortsätta ladda och visa sitt innehåll smidigt medan skriptet hämtas.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Det underbara med "async" är att det främjar en snabbare och mer responsiv användarupplevelse. Så snart skriptet har hämtats kommer det att utföras omedelbart, även om andra resurser på sidan fortfarande håller på att laddas. Det gör att dina användare kan interagera med din webbsida och njuta av dess funktionalitet utan onödiga fördröjningar.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Genom att använda "async" kan du optimera prestandan för din webbsida, särskilt när du har skript som inte är beroende av andra element på sidan för att fungera korrekt. Det gör det möjligt för dessa skript att laddas oberoende, vilket minskar eventuella flaskhalsar och säkerställer en smidig användarupplevelse.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Det är viktigt att komma ihåg att när du använder "async" kan ordningen i vilken skripten utförs inte garanteras, särskilt om de är beroende av varandra. Men den här avvägningen är väl värd det när det gäller att uppnå snabbare laddningstider och förbättrad interaktivitet för dina användare.`,
        ],
      },
      useDefer: {
        en: 'Use defer',
        sv: 'Använd defer',
      },
      defer: {
        en: 'Defer',
        sv: 'Defer',
      },
      deferShortDescription: {
        en: 'Using the “defer” on a script tag enhances webpage performance by loading scripts in the background while parsing the page.',
        sv: 'Att använda “defer” på en skripttagg förbättrar webbsidans prestanda genom att ladda skript i bakgrunden samtidigt som sidan analyseras.',
      },
      deferDescription: {
        en: [
          `Defer is a powerful attribute that brings numerous benefits to your webpage! When you use the "defer" attribute on a script tag, it introduces a smart loading mechanism that enhances both performance and functionality.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `With "defer," the script is loaded in the background while the page is being parsed. However, it patiently waits until the parsing of the entire page is complete before executing. This ensures that the script is executed in the exact order it appears in the HTML, promoting a seamless and reliable user experience.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `By deferring the script execution, you allow the browser to prioritize rendering the page's content first. This means that users can start interacting with and enjoying your webpage's visual elements without any delays caused by script execution. It's a fantastic way to ensure that your webpage loads quickly and smoothly.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `One of the key advantages of using "defer" is its compatibility with scripts that rely on accessing or manipulating the DOM (Document Object Model). Since the DOM structure is fully available by the time the deferred script executes, it can seamlessly interact with the HTML elements, enhancing the interactivity and functionality of your webpage.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `In summary, "defer" empowers you to optimize your webpage's loading process by loading scripts in the background while prioritizing content rendering. It ensures the proper order of script execution and enables seamless integration with the DOM. By leveraging "defer," you create a positive user experience that combines speedy page loading and smooth interaction.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Embrace the power of "defer" and unlock the full potential of your webpage's performance and functionality!`,
        ],

        sv: [
          `Defer är en kraftfull attribut som ger många fördelar för din webbsida! När du använder attributet "defer" på en script-tag introduceras en smart laddningsmekanism som förbättrar både prestanda och funktionalitet.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Med "defer" laddas skriptet i bakgrunden medan sidan analyseras. Det väntar tålmodigt tills hela sidan har analyserats klart innan det utförs. Detta säkerställer att skriptet utförs i exakt den ordning det visas i HTML, vilket främjar en sömlös och pålitlig användarupplevelse.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Genom att skjuta upp skriptets utförande tillåter du webbläsaren att prioritera renderingen av sidans innehåll först. Det innebär att användare kan börja interagera med och njuta av webbsidans visuella element utan några fördröjningar orsakade av skriptutförandet. Det är ett fantastiskt sätt att säkerställa att din webbsida laddas snabbt och smidigt.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `En av de viktigaste fördelarna med att använda "defer" är dess kompatibilitet med skript som är beroende av att komma åt eller manipulera DOM (Document Object Model). Eftersom DOM-strukturen är helt tillgänglig när det fördröjda skriptet utförs kan det sömlöst interagera med HTML-elementen och förbättra webbsidans interaktivitet och funktionalitet.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Sammanfattningsvis ger "defer" dig möjlighet att optimera laddningsprocessen för din webbsida genom att ladda skript i bakgrunden samtidigt som innehållet prioriteras för rendering. Det säkerställer rätt ordning för skriptutförande och möjliggör sömlös integration med DOM. Genom att dra nytta av "defer" skapar du en positiv användarupplevelse som kombinerar snabb sidladdning och smidig interaktion.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `Omfamna kraften hos "defer" och lås upp hela potentialen för din webbsidas prestanda och funktionalitet!`,
        ],
      },
    },
    widgetIsActive: {
      en: 'Widget is active',
      sv: 'Widget är aktiv',
    },
    widgetSnippet: {
      en: 'Widget snippet',
      sv: 'Widget-kod',
    },
    widgetSnippetInfo: {
      en: 'Copy and paste on your site',
      sv: 'Kopiera och klistra in på din webbplats',
    },
    tagFinderSelector: {
      en: 'Tag finder selector',
      sv: 'Taggsökarväljare',
    },
    tagFinderSelectorInfo: {
      en: 'ClassName on your site where to search for matching tags. Example: h1, .tags, #tags',
      sv: 'ClassName på din webbplats där du kan söka efter matchande taggar. Exempel: h1, .tags, #tags',
    },
    disallowSuperadmins: {
      en: 'Disallow super admins from entering BackOffice',
      sv: 'Tillåt ej super-admins att besöka Backoffice',
    },
    doNotTrack: {
      en: 'Do not track',
      sv: 'Stäng av spårning',
    },
    doNotTrackInfo: {
      en: 'Turning "ON" this parameter will block tracking of any session data, including cookies and analytics. This will have the same effect as enabling a Do Not Track setting in your browser.',
      sv: 'Stäng av spårning för att blockera att widgeten spårar och lagrar sessionsdata, cookies och statistik.',
    },
    storylineOverride: {
      en: 'Storyline override',
      sv: 'Tvinga storylines',
    },
    storylineOverrideInfo: {
      en: 'By activating this mode, the widget will only appear where there are a defined storyline with url.',
      sv: 'Widgeten dyker enbart upp där det finns en definierad storyline.',
    },
    consentTexts: {
      en: 'Consent texts',
      sv: 'Texter för samtycke',
    },
    generalConsentTexts: {
      en: 'General consent text',
      sv: 'Generell text för samtycke',
    },
    consentTextsShowPreview: {
      en: 'Show preview',
      sv: 'Förhandsgranska',
    },
    consentTextsHidePreview: {
      en: 'Hide preview',
      sv: 'Döljs förhandsgranskning',
    },
    consentTextAriaLabel: {
      en: 'Consent text preview',
      sv: 'Text för samtycke förhandsgranskning',
    },
    soMeConsentTexts: {
      en: 'Consent text for sharing videos in social media',
      sv: 'Text för samtycke att dela videor på sociala medier',
    },
    soMeConsentEnableLabel: {
      en: 'Enable Social Media Consent',
      sv: 'Aktivera Social Media samtycke',
    },
    soMeConsentEnableDescription: {
      en: 'By enabling this feature, users who upload a video can choose to give consent for the video to be shared on social media platforms.',
      sv: 'Genom att aktivera den här funktionen kan användare som laddar upp en video välja att ge sitt samtycke för att videon ska delas på sociala medieplattformar.',
    },

    soMeConsentMandatory: {
      en: 'Social Media Consent is mandatory',
      sv: 'Social Media samtycke är obligatoriskt',
    },
    soMeConsentMandatoryDescription: {
      en: 'Makes it mandatory for users to agree to sharing their video content on social media platforms.',
      sv: 'Gör det obligatoriskt för användare att samtycka till att dela sitt videomaterial på sociala medieplattformar.',
    },
  },
  automationSettings: {
    removeReminder: {
      en: 'Remove reminder',
      sv: 'Ta bort påminnelse',
    },
    emailAriaLabel: {
      en: 'Email toggle automation',
      sv: 'E-post aktivera automatiskt',
    },
    smsAriaLabel: {
      en: 'SMS toggle automation',
      sv: 'SMS aktivera automatiskt',
    },
    message: {
      en: 'Message to recipient(s)',
      sv: 'Meddelande till mottagare',
    },
    activateAutomatedRequests: {
      en: 'Activate automated requests',
      sv: 'Aktivera automatiska frågeutskick',
    },
    intervalBetweenAutomatedRequests: {
      en: 'Interval between automated requests',
      sv: 'Intervall för automatiska utskick',
    },
    interval: {
      en: 'Interval',
      sv: 'Intervall',
    },
    monthly: {
      en: 'Monthly',
      sv: 'Månatligen',
    },
    weekly: {
      en: 'Weekly',
      sv: 'Veckovis',
    },
    biweekly: {
      en: 'Biweekly',
      sv: 'Varannan vecka',
    },
    dayOf: {
      en: 'Day of',
      sv: 'Dag per',
    },
    monday: {
      en: 'Monday',
      sv: 'Måndag',
    },
    tuesday: {
      en: 'Tuesday',
      sv: 'Tisdag',
    },
    wednesday: {
      en: 'Wednesday',
      sv: 'Onsdag',
    },
    thursday: {
      en: 'Thursday',
      sv: 'Torsdag',
    },
    friday: {
      en: 'Friday',
      sv: 'Fredag',
    },
    saturday: {
      en: 'Saturday',
      sv: 'Lördag',
    },
    sunday: {
      en: 'Sunday',
      sv: 'Söndag',
    },
    nextRequest: {
      en: 'Next request',
      sv: 'Nästa utskick',
    },
    lastRequest: {
      en: 'Last request',
      sv: 'Föregående utskick',
    },
    noPreviousRequests: {
      en: 'No automatic requests sent yet',
      sv: 'Inga automatiska utskick har skickats än',
    },
    sendRequestViaSMS: {
      en: 'Send request via SMS',
      sv: 'Skicka utskick via SMS',
    },
    sendRequestViaEmail: {
      en: 'Send request via Email',
      sv: 'Skicka utskick via E-post',
    },
    numberOfQuestions: {
      en: 'Number of questions',
      sv: 'Antal frågor',
    },
    numberOfQuestionsInfo: {
      en: 'Number of alternative questions to answer per automated request to recipient',
      sv: 'Antal frågor som skickas ut mer utskick till mottagaren',
    },
    numQuestions: {
      en: 'Num. questions',
      sv: 'Antal',
    },
    activateReminders: {
      en: 'Activate automated reminders',
      sv: 'Aktivera automatiska påminnelser',
    },
    reminders: {
      en: 'Reminders',
      sv: 'Påminnelser',
    },
    remindersInfo: {
      en: 'Send automatic reminders to the recipient if no response is posted',
      sv: 'Skicka ut automatiska påminnelser till mottagaren om inget svar skickats in',
    },
    delay: {
      en: 'Reminder after first send out',
      sv: 'Påminnelse efter första utskicket',
    },
    sms: {
      en: 'SMS',
      sv: 'SMS',
    },
    email: {
      en: 'E-mail',
      sv: 'E-post',
    },
    addReminder: {
      en: 'Add reminder',
      sv: 'Lägg till påminnelse',
    },
    activateAutomaticDelete: {
      en: 'Activate automatic deletion of old, unanswered requests',
      sv: 'Aktivera automatisk borttagning av gamla, obesvarade utskick',
    },
    deleteRequests: {
      en: 'Delete requests',
      sv: 'Ta bort utskick',
    },
    deleteRequestsInfo: {
      en: 'The request will be deleted and recipients will not be able to answer any longer',
      sv: 'Utskicket kommer tas bort och mottagaren kommer inte längre kunna svara',
    },
    days: {
      en: 'Days',
      sv: 'Dagar',
    },
    daysAfter: {
      en: 'Days after last reminder/request',
      sv: 'Dagar efter senaste påminnelse/utskick',
    },
  },
  styling: {
    customWidgetStyling: {
      en: 'Custom widget styling',
      sv: 'Anpassad widget-stil',
    },
    customCTLLabel: {
      en: 'Custom CTA label for the widget',
      sv: 'Redigera CTA text för widgeten',
    },
    customCTLLabelError: {
      en: ({ maxLength }) => `Text to long, max ${maxLength} characters allowed`,
      sv: ({ maxLength }) => `Text för lång, max ${maxLength} tecken tillåtna`,
    },
    widgetBottomMarginLabel: {
      en: 'Widget bottom margin (px)',
      sv: 'Marginal nedanför widget (px)',
    },
    widgetTopMarginLabel: {
      en: ({ placement, marginType = 'px' }) => `Widget ${placement} margin (${marginType})`,
      sv: ({ placement, marginType = 'px' }) => `Marginal ${placement} widget (${marginType})`,
    },
    widgetBasedMarginLabel: {
      en: 'Use "%" margin (px used as default)',
      sv: 'Använd "%" marginal (px används som standard)',
    },
    widgetBottomMarginError: {
      en: 'Incorrect range. Example:',
      sv: 'Felaktigt intervall. Exempel:',
    },
    widgetSideMarginLabel: {
      en: ({ placement, marginType = 'px' }) => `Widget ${placement} side margin (${marginType})`,
      sv: ({ placement, marginType = 'px' }) =>
        `Marginal till ${placement} om widget (${marginType})`,
    },
    left: {
      en: 'left',
      sv: 'vänster',
    },
    right: {
      en: 'right',
      sv: 'höger',
    },
    top: {
      en: 'top',
      sv: 'top',
    },
    bottom: {
      en: 'bottom',
      sv: 'bottom',
    },
    default: {
      en: 'default',
      sv: 'standard',
    },
    range: {
      en: 'range',
      sv: 'intervall',
    },
    hideDialogTitle: {
      en: 'Hide widget video title in default view',
      sv: 'Dölj widget video titel i standardvy',
    },
    widgetSideMarginError: {
      en: 'Incorrect range. Example:',
      sv: 'Felaktigt intervall. Exempel:',
    },
    widgetPlacementLabel: {
      en: 'Place Widget on the left side of your page (Shows on the right side by default)',
      sv: 'Placera widgeten till vänster på din sida (Visas på höger sida som standard)',
    },
    widgetPlacementLabelTop: {
      en: 'Place Widget on the top of your page (Shows on the bottom by default)',
      sv: 'Placera widgeten högst upp på sidan (visas längst ned som standard)',
    },
    save: {
      en: 'Save',
      sv: 'Spara',
    },
    logo: {
      en: 'Logo',
      sv: 'Logotyp',
    },
    widgetPlacement: {
      en: 'Widget Placement',
      sv: 'Widget Placement',
    },
    hideCTA: {
      en: 'Hide CTA button on widget',
      sv: 'Dölj CTA knapp på widget',
    },
    mobile: {
      en: 'Mobile',
      sv: 'Mobil',
    },
    desktop: {
      en: 'Desktop',
      sv: 'Desktop',
    },
    separateDesktopMobilePlacement: {
      en: 'Use separate desktop and mobile placement',
      sv: 'Använd separat desktop och mobil placering',
    },
    enableHideWidget: {
      en: 'Hide widget on close, will show again on reload',
      sv: 'Dölj widgeten vid stängning, kommer visas igen vid uppdatering',
    },
    accentTextColor: {
      en: 'Accent Text Color',
      sv: 'Accent Textfärg',
    },
    accentBackColor: {
      en: 'Accent Background Color',
      sv: 'Accent Bakgrundsfärg',
    },
    textColor: {
      en: 'Text Color',
      sv: 'Text Färg',
    },
    backColor: {
      en: 'Background Color',
      sv: 'Bakgrundsfärg',
    },
    widgetStyling: {
      en: 'Widget Styling',
      sv: 'Widget Styling',
    },
  },
  simpleDialog: {
    ariaLabelledby: {
      en: 'Title for the dialog',
      sv: 'Titel för dialogrutan',
    },
    ariaDescribedby: {
      en: 'Description for the dialog',
      sv: 'Beskrivning för dialogrutan',
    },
  },
};

export default translations;
