import React, { useState } from 'react';
import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// import ThemeContext from 'src/utils/ThemeContext';
import { Stack } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import translationsPublicVideoCollector from './translations';

interface PasswordProtectedProps {
  checkPassword: (password: string) => void;
  checkPasswordLoading: boolean;
  checkPasswordOk: boolean;
  checkPasswordError: boolean;
  children: (password: string) => JSX.Element;
}

function PasswordProtected({
  checkPassword,
  checkPasswordLoading,
  checkPasswordOk,
  checkPasswordError,
  children,
}: PasswordProtectedProps) {
  const lang = useLanguage();
  const [password, setPassword] = useState('');

  if (checkPasswordOk) {
    return children(password);
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderRadius: '16px',
        p: 2,
        pt: 3,
        m: 2,
        maxWidth: '400px',
      }}
    >
      <Stack
        spacing={2}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          checkPassword(password);
        }}
      >
        <Typography
          variant="headlineLargeBoldRecoleta"
          align="center"
          sx={{
            width: '100%',
          }}
        >
          {translationsPublicVideoCollector.AccessRestricted[lang]}
        </Typography>
        <Typography
          variant="bodyMedium"
          align="center"
          sx={{
            width: '100%',
          }}
        >
          {translationsPublicVideoCollector.PasswordProtected[lang]}
        </Typography>
        <Box>
          <Typography variant="bodyMedium">
            {translationsPublicVideoCollector.Password[lang]}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={translationsPublicVideoCollector.Password[lang]}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={checkPasswordLoading}
            error={checkPasswordError}
            helperText={
              checkPasswordError ? translationsPublicVideoCollector.IncorrectPassword[lang] : null
            }
            sx={{
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'background.paper',
              },
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => checkPassword(password)}
          disabled={checkPasswordLoading}
        >
          {checkPasswordLoading
            ? translationsPublicVideoCollector.Checking[lang]
            : translationsPublicVideoCollector.Continue[lang]}
          {checkPasswordLoading && <CircularProgress size={20} />}
        </Button>
      </Stack>
    </Box>
  );
}

export default PasswordProtected;
