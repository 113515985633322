import { useState, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { useLanguage } from 'src/context/LanguageContext';
import { useDashboardApi } from './DashboardApi';
import { translationsDashboard } from '../../../utils/translationsDashboard';

import ToDoCard from './ToDoCard';
import { availableToDos } from './ToDoHelper';
import { statuses } from '../../../utils/utils';

function ToDoViewCard({ basicOrgData, dashboardStats, videoCards }) {
  const lang = useLanguage();
  const dashboardApi = useDashboardApi();
  const [videosToBeReviewed, setVideosToBeReviewed] = useState(
    dashboardStats?.current?.videosToBeReviewed ??
      videoCards?.filter((video) => video.status === statuses.REVIEW)?.length ??
      0,
  );

  const [toDos, setToDos] = useState(dashboardStats?.current?.toDos ?? []);

  const getToDosActions = () => {
    const toDosTemp = [];
    if (videosToBeReviewed > 0) {
      toDosTemp.push({
        completed: false,
        description: translationsDashboard.toDoViewCard.pendingReviews.description[lang]({
          count: videosToBeReviewed,
        }),
        id: _.uniqueId(availableToDos.videoRequestOpenReview),
        action: availableToDos.videoRequestOpenReview,
        disallowDismiss: true,
      });
    }
    return toDosTemp;
  };

  const handleDismiss = (item) => {
    setToDos((prev) => [...prev.filter((i) => i.id !== item.id)]);
    dashboardApi
      .dismissToDoItem(basicOrgData?.encodedId, item.id)
      .then((response) => {
        setVideosToBeReviewed(res?.data?.dismissToDoItem?.videosToBeReviewed);

        dashboardStats.current.videosToBeReviewed = res?.data?.dismissToDoItem?.videosToBeReviewed;
        dashboardStats.current.toDos = res?.data?.dismissToDoItem?.toDos;
        handleAddToDos(res?.data?.dismissToDoItem?.toDos);
      })

      .catch((error) => {});
  };

  const handleAddToDos = (items) => {
    const tmp = toDos?.flatMap((i) => i.id);
    const filter = items?.filter((t) => {
      return !tmp?.includes(t.id);
    });
    if (filter?.length > 0) {
      setToDos((prev) => [...filter, ...prev]);
    }
  };

  useEffect(() => {
    dashboardApi
      .getToDoViewCardStats(basicOrgData?.encodedId)
      .then((res) => {
        setVideosToBeReviewed(res?.data?.toDoViewCardStats?.videosToBeReviewed);

        dashboardStats.current.videosToBeReviewed =
          res?.data?.toDoViewCardStats?.videosToBeReviewed;
        dashboardStats.current.toDos = res?.data?.toDoViewCardStats?.toDos;
        handleAddToDos(res?.data?.toDoViewCardStats?.toDos);
      })
      .catch((err) => {});
  }, [dashboardApi]);

  return (
    <Card>
      <CardHeader
        title="To-do"
        titleTypographyProps={{
          variant: 'titleMedium',
        }}
        subheaderTypographyProps={{
          variant: 'bodyMedium',
        }}
      />
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          {getToDosActions()?.map((todo) => (
            <Grid item lg={12} sm={12} md={12} xl={12} xs={12} key={_.uniqueId()}>
              <ToDoCard toDoItem={todo} key={_.uniqueId(todo?.id)} basicOrgData={basicOrgData} />
            </Grid>
          ))}
          {getToDosActions()?.length > 0 && toDos?.length > 0 && (
            <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
              <Divider variant="fullWidth" sx={{ borderBottomWidth: '2px' }} />
            </Grid>
          )}
          {toDos?.length > 0 && (
            <Grid item lg={12} sm={12} md={12} xl={12} xs={12} key={_.uniqueId()}>
              <List>
                <TransitionGroup>
                  {toDos?.map((todo) => (
                    <Collapse key={_.uniqueId()}>
                      <ListItem style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Grid item lg={12} sm={12} md={12} xl={12} xs={12} key={_.uniqueId()}>
                          <ToDoCard
                            toDoItem={todo}
                            key={_.uniqueId(todo?.id)}
                            basicOrgData={basicOrgData}
                            handleDismiss={handleDismiss}
                          />
                        </Grid>
                      </ListItem>
                    </Collapse>
                  ))}
                </TransitionGroup>
              </List>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ToDoViewCard;
