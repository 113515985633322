import ListItemText from '@mui/material/ListItemText';

const styling = {
  primary: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontSize: '14px',
    fontWeight: 400,
  },
};

export default function SimpleListItemText(props) {
  return <ListItemText {...props} primaryTypographyProps={styling.primary} />;
}
