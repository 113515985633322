import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';

import { VideoCollectorDetailed } from '../../types';

import Video from './Video';

import filterValidVideoCards from './filterVideoCards';

export interface VideosProps {
  videoCollector: VideoCollectorDetailed;
}

function Videos({ videoCollector }: VideosProps) {
  const videoCards = useMemo(() => {
    return filterValidVideoCards(videoCollector.videoCards, ['LIVE', 'ARCHIVED', 'APPROVED']);
  }, [videoCollector.videoCards]);

  return (
    <Grid item container spacing={3} role="grid" data-testid="videos-grid-container">
      {videoCards.map((video) => (
        <Video video={video} data-testid={`video-${video.id}`} key={video.id} />
      ))}
    </Grid>
  );
}

export default Videos;
