import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel, Typography, Tooltip } from '@mui/material';
import { EnhancedVideoCardType, Order } from './types';
import translations from './translations';

interface VideoTableHeadProps {
  order: Order;
  orderBy: keyof EnhancedVideoCardType;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof EnhancedVideoCardType) => void;
  lang: Language;
}

interface ColumnConfig {
  id: keyof EnhancedVideoCardType | 'question';
  label: string;
  tooltip: string;
}

const columns = (lang: Language): ColumnConfig[] => [
  {
    id: 'question',
    label: translations.title[lang],
    tooltip: translations.tooltips.title[lang],
  },
  {
    id: 'totalImpressions',
    label: translations.impressions[lang],
    tooltip: translations.tooltips.impressions[lang],
  },
  {
    id: 'totalPlays',
    label: translations.plays[lang],
    tooltip: translations.tooltips.plays[lang],
  },
  {
    id: 'totalPlayRate',
    label: translations.playRate[lang],
    tooltip: translations.tooltips.playRate[lang],
  },
  {
    id: 'totalTimePlayed',
    label: translations.viewTime[lang],
    tooltip: translations.tooltips.viewTime[lang],
  },
];

function VideoTableHead({ order, orderBy, onRequestSort, lang }: VideoTableHeadProps) {
  const createSortHandler =
    (property: keyof EnhancedVideoCardType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns(lang).map((column) => (
          <TableCell
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
            style={{ padding: '16px 12px' }}
          >
            <Tooltip enterDelay={750} title={column.tooltip}>
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
                aria-label={`${translations.ariaLabel.sortBy[lang]} ${column.label}`}
                data-testid={`sort-${column.id}`}
              >
                <Typography variant="titleSmall" component="div">
                  {column.label}
                </Typography>
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default VideoTableHead;
