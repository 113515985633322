import React from 'react';
import {
  Button,
  Grid,
  CardContent,
  CardHeader,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Alert,
  Collapse,
  IconButton,
  Backdrop,
} from '@mui/material';
import { uniqueId } from 'lodash';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { useLanguage } from 'src/context/LanguageContext';
import { signupStatuses } from '../../../utils/utils';
import translationsSignup from '../../../utils/translationsSignup';
import StepOneSignup, { formDataShape } from './StepOneSignup';
import SignUpButton from './SignUpButton';
import GDPRCompliant from '../gdpr-compliant';
import Recaptcha from '../recaptcha';

function SignUpCard({
  isLoading,
  setIsLoading,
  onSubmitAsync,
  setErrorMsg,
  activeStep,
  setStatus,
  status,
  setActiveStep,
  steps,
  formData,
  handleChange,
  token = null,
  handleOnSubmit,
  isStepsValid,
  setToken,
  isStepValid,
  errorMsg = null,
  reCaptchaRef = null,
}) {
  const lang = useLanguage();
  return (
    <>
      <CardHeader
        title={
          <>
            {translationsSignup.signUp[lang]}{' '}
            <span
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {translationsSignup.signUpLifeInside[lang]}
            </span>
          </>
        }
        subheader={
          <>
            {isMobile ? (
              <>
                {translationsSignup.preSubHeaderMobile[lang]}
                <br />
              </>
            ) : null}
            {translationsSignup.subHeader[lang]}
            <Button
              variant="text"
              color="primary"
              disableElevation
              disableRipple
              sx={{
                textTransform: 'none',
                color: '#0288D1',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '24px',
                letterSpacing: 0.15,
                wordwrap: 'break-word',
                p: 0,
                m: 0,
                pl: 0.45,
                pb: 0.3,
                height: '24px',
              }}
              to="/login"
              component={NavLink}
              disabled={isLoading}
            >
              {translationsSignup.signIn[lang]}
            </Button>
          </>
        }
        component="h1"
        sx={{
          pt: 0,
          pb: 0,
          mt: 0,
          mb: 0,
        }}
        titleTypographyProps={{
          align: 'center',
          color: '#121212',
          fontSize: '32px',
          fontFamily: 'Recoleta-Bold',
          fontWeight: '700',
          lineHeight: 40 / 32,
          wordWrap: 'break-word',
        }}
        subheaderTypographyProps={{
          align: 'center',
          color: '#121212',
          fontSize: '16px',
          fontFamily: 'Inter',
          fontWeight: '500',
          lineHeight: 24 / 16,
          letterSpacing: 0.15,
          wordWrap: 'break-word',
        }}
      />

      <CardContent sx={{ mb: 0, pb: 0 }}>
        <form onSubmit={onSubmitAsync}>
          <Grid container spacing={2}>
            <Grid item sx={{ width: '100%' }}>
              <Grid
                container
                spacing={0}
                sx={{ width: '100%' }}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item sx={{ width: '100%' }} lg={1} sm={1} xl={1} xs={1} md={1}>
                  {activeStep > 0 && (
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={() => {
                        setStatus(signupStatuses.updatingInput);
                        setActiveStep(Math.max(activeStep - 1, 0));
                      }}
                      disabled={isLoading}
                    >
                      <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item sx={{ width: '100%' }} lg={10} sm={10} xl={10} xs={10} md={10}>
                  <Stepper activeStep={activeStep} alternativeLabel sx={{ fontFamily: 'Inter' }}>
                    {steps.map((_, index) => (
                      <Step
                        key={uniqueId()}
                        value={index}
                        sx={{
                          fontFamily: 'Inter',
                          '& .MuiStepLabel-root .Mui-completed': {
                            color: '#9400FE',
                          },
                          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                            color: '#9400FE',
                          },
                          '& .MuiStepLabel-root .Mui-active': {
                            color: '#9400FE',
                          },
                          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                            color: '#9400FE',
                          },
                        }}
                      >
                        <StepLabel sx={{ fontFamily: 'Inter' }} />
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item sx={{ width: '100%' }} lg={1} sm={1} xl={1} xs={1} md={1}>
                  <Box />
                </Grid>
              </Grid>
            </Grid>

            <StepOneSignup
              formData={formData}
              handleChange={handleChange}
              activeStep={activeStep}
              status={status}
              token={token}
              steps={steps}
              isDisabled={isLoading}
            />
            {activeStep === steps.length - 1 && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{
                    color: '#121212',
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    lineHeight: 16 / 12,
                    wordWrap: 'break-word',
                  }}
                >
                  {translationsSignup.approveConditions.byClicking[lang]} {`"`}
                  {token?.length > 0
                    ? translationsSignup.updateRegistration[lang]
                    : translationsSignup.completeRegistration[lang]}
                  {`" `}
                  {translationsSignup.approveConditions.youAgree[lang]}{' '}
                  <a
                    href="https://www.lifeinside.io/agreement/service-agreement-free-version"
                    target="_blank"
                    style={{
                      color: '#0288D1',
                      fontSize: '12px',
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      textDecoration: 'underline',
                      lineHeight: 16 / 12,
                      letterSpacing: 0.4,
                      wordWrap: 'break-word',
                    }}
                    rel="noreferrer"
                  >
                    {translationsSignup.approveConditions.termsOfService[lang]}
                  </a>{' '}
                  {translationsSignup.approveConditions.and[lang]}{' '}
                  <a
                    href="https://www.lifeinside.io/privacy-policy"
                    target="_blank"
                    style={{
                      color: '#0288D1',
                      fontSize: '12px',
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      textDecoration: 'underline',
                      lineHeight: 16 / 12,
                      letterSpacing: 0.4,
                      wordWrap: 'break-word',
                    }}
                    rel="noreferrer"
                  >
                    {translationsSignup.approveConditions.privacyPolicy[lang]}
                  </a>
                  {translationsSignup.approveConditions.appliesToYou[lang]}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <SignUpButton
                status={status}
                isLoading={isLoading}
                activeStep={activeStep}
                handleOnSubmit={handleOnSubmit}
                isStepsValid={isStepsValid}
                setIsLoading={setIsLoading}
                token={token}
                setToken={setToken}
                setStatus={setStatus}
                onSubmitAsync={onSubmitAsync}
                setErrorMsg={setErrorMsg}
                steps={steps}
                setActiveStep={setActiveStep}
                isStepValid={isStepValid}
              />
              <Collapse in={!!errorMsg}>
                <Alert severity="error" sx={{ mt: 3 }}>
                  {errorMsg}
                </Alert>
              </Collapse>
            </Grid>
            <GDPRCompliant includeLogo longText />
          </Grid>
          <Backdrop
            invisible
            open={status === signupStatuses.pendingReCaptcha}
            onClick={() => {
              reCaptchaRef.current.reset();
              setStatus(signupStatuses.initial);
              setIsLoading(false);
            }}
          />
          <Recaptcha reCaptchaRef={reCaptchaRef} hidden />
        </form>
      </CardContent>
    </>
  );
}

SignUpCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSubmitAsync: PropTypes.func.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  formData: PropTypes.shape({
    firstName: formDataShape.isRequired,
    lastName: formDataShape.isRequired,
    phoneNumber: formDataShape.isRequired,
    email: formDataShape.isRequired,
    orgName: formDataShape.isRequired,
    domain: formDataShape.isRequired,
    orgLang: formDataShape.isRequired,
    phoneNumberVerificationCode: formDataShape.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  token: PropTypes.string,
  handleOnSubmit: PropTypes.func.isRequired,
  isStepsValid: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  isStepValid: PropTypes.func.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  reCaptchaRef: PropTypes.shape({
    current: PropTypes.shape({
      reset: PropTypes.func.isRequired,
    }),
  }),
};

export default SignUpCard;
