import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import DataGridTable from '../../common/DataGridTable';
import { StyleDialogLarge } from '../../common/SimpleStyledDialog/StyleDialogLarge';
import getTheme from '../../common/SplitButton/SplitButtonThemes';
import { useRecipientActions } from './actions';
import { translations } from '../../../utils/translationsSettings';
import { translations as translationsBase } from '../../../utils/translations';

export default function CsvImportDialog(props) {
  const recipientActions = useRecipientActions();
  const [csvData, setCsvData] = useState([]);

  const { onClose, onCloseUpdate, open, basicOrgData, orgRecipients } = props;
  const hasTextMessage = basicOrgData?.premiumFeatures?.textMessage ?? false;
  const handleClose = () => {
    onClose();
  };
  const uploadCsv = () => {
    recipientActions
      .createRecipients(basicOrgData.encodedId, csvData)
      .then((res) => {
        onCloseUpdate(res.data.createRecipients.recipients);
      })
      .catch((err) => {});
  };
  const theme = getTheme('active');
  const lang = useLanguage();

  const columns = [
    {
      field: 'firstName',
      headerName: translations.recipient.dataGrid.header.firstName[lang],
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: translations.recipient.dataGrid.header.lastName[lang],
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'title',
      headerName: translations.recipient.dataGrid.header.title[lang],
      minWidth: 130,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'leader',
      headerName: translations.recipient.dataGrid.header.leader[lang],
      minWidth: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.row.leader ? 'YES' : 'NO';
      },
    },
    {
      field: 'email',
      headerName: translations.recipient.dataGrid.header.email[lang],
      minWidth: 250,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    ...(hasTextMessage
      ? [
          {
            field: 'phoneNumber',
            headerName: translations.recipient.dataGrid.header.phoneNumber[lang],
            minWidth: 250,
            disableColumnMenu: true,
          },
        ]
      : []),
    {
      field: 'receiveAutoRequests',
      headerName: translations.recipient.dataGrid.header.receiveAutoRequests[lang],
      minWidth: 200,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return params.row.receiveAutoRequests ? 'YES' : 'NO';
      },
    },
  ];
  return (
    <StyleDialogLarge onClose={handleClose} open={open}>
      <div className="csv-import">
        <DialogTitle>
          {translations.recipient.importRecipientsFromCsv[lang]}
          <IconButton
            aria-label={translationsBase.videoCard.closeAriaLabel[lang]}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={(e) => {
              handleClose();
            }}
          >
            <i className="icon">close</i>
          </IconButton>
        </DialogTitle>
        <CsvForm setCsvData={setCsvData} csvData={csvData} orgId={basicOrgData.id} />

        <div style={{ padding: '0 30px 30px' }}>
          {csvData?.length > 0 && (
            <div className="btn-row">
              <Typography variant="titleMedium">
                {translations.recipient.verifyData[lang]}
              </Typography>
              <Button onClick={uploadCsv}>{translations.recipient.approveAndImport[lang]}</Button>
            </div>
          )}
          <DataGridTable
            slots={{
              NoRowsOverlay: () => EmptyRows(),
            }}
            rows={csvData}
            columns={columns}
            pageSize={12}
            sx={{ width: 1000, opacity: csvData?.length > 0 ? 1 : 0.4 }}
          />
        </div>
      </div>
    </StyleDialogLarge>
  );
}

function CsvForm({ setCsvData, csvData, orgId }) {
  const lang = useLanguage();
  const [file, setFile] = useState();

  const fileReader = new FileReader();
  useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };

      fileReader.readAsText(file);
    }
  }, [file]);
  const importCsv = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };
  const types = {
    id: Number,
    orgId: Number,
    firstName: String,
    lastName: String,
    title: String,
    leader: Boolean,
    email: String,
    phoneNumber: 'PhoneNumber',
    receiveAutoRequests: Boolean,
  };
  const typeCaster = (key, value) => {
    const theType = types[key];

    if (theType === Boolean) {
      return value.toUpperCase() === 'TRUE';
    }
    if (theType === 'PhoneNumber') {
      return value.indexOf('+') !== 0 ? value : _.trim(value, '+');
    }
    if (theType === Number) {
      return Number(value);
    }
    return value;
  };
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i, key) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object, headerRaw, index) => {
        const header = _.trim(headerRaw);
        const rawValue = _.trim(_.trim(values[index], '\r'));
        if (header in types) {
          object[header] = typeCaster(header, rawValue);
        }
        return object;
      }, {});
      obj.id = key;
      obj.orgId = orgId;
      return obj;
    });
    setCsvData(array);
  };

  return (
    <fieldset className="section">
      <label htmlFor="recipientFirstName" />

      <div htmlFor="csvFileInput" className={`upload-area ${file ? 'minimized' : ''}`}>
        {file ? (
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <GroupAddIcon />
            <Typography variant="titleLarge">
              <Grid
                container
                spacing={4}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="bodyMedium">
                  "{file.name}" {translations.recipient.added[lang]}.
                </Typography>
              </Grid>
            </Typography>
            <Typography variant="titleLarge">
              <label className="">
                {translations.recipient.anotherFile[lang]}
                <input type="file" id="csvFileInput" accept=".csv" onChange={importCsv} />
              </label>
            </Typography>
          </Grid>
        ) : (
          <>
            <GroupAddIcon />
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography variant="bodyMedium">
                <Typography variant="titleMedium">1.</Typography>{' '}
                <Button download href="/recipients-csv-template.csv">
                  {translations.recipient.downloadTemplate[lang]}{' '}
                </Button>
              </Typography>
              <Typography variant="bodyMedium">
                <Typography variant="titleMedium">2.</Typography>{' '}
                {translations.recipient.fillFile[lang]}
              </Typography>
              <Typography variant="bodyMedium">
                <Typography variant="titleMedium">3.</Typography>{' '}
                <label className="btn primary-btn">
                  {translations.recipient.upload[lang]} {translations.recipient.csvFile[lang]}{' '}
                  <input type="file" id="csvFileInput" accept=".csv" onChange={importCsv} />
                </label>
              </Typography>
            </Grid>
          </>
        )}
      </div>
    </fieldset>
  );
}

function EmptyRows() {
  const lang = useLanguage();
  return (
    <div style={{ textAlign: 'center', padding: 40 }}>
      {translations.recipient.noRecipientsUploaded[lang]}
    </div>
  );
}
