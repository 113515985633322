import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box, Select, MenuItem, Typography, Stack, SelectChangeEvent } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useLocation, useOutletContext } from 'react-router-dom';
import ThemeContext from '../../utils/ThemeContext';

import Overview from './Analytics/Overview/Overview';
import TabSelector from '../common/TabSelector/TabSelector';
import translationsAnalytics from '../../utils/translationsAnalytics';
import Videos from './Analytics/Videos';
import { EnhancedVideoCardType } from './Analytics/Videos/types';
import Stories from './Analytics/Stories';
import { EnhancedStoryType } from './Analytics/Stories/types';

interface BasicOrgData {
  orgNameSlug: string;
  lang: Language;
  encodedId: string;
}

interface OutletContext {
  basicOrgData: BasicOrgData;
}

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

const durationOptionDays = (lang: Language, days: number) => {
  return { value: days, label: translationsAnalytics.durationDays[lang](days) };
};

const durationOptions = (lang: Language) => [
  durationOptionDays(lang, 7),
  durationOptionDays(lang, 28),
  durationOptionDays(lang, 90),
  durationOptionDays(lang, 365),
  { value: 'lifetime', label: translationsAnalytics.durationLifetime[lang] },
  { value: 'custom', label: translationsAnalytics.durationCustom[lang] },
];

export const availableTabs = (orgNameSlug: string) => [
  {
    value: 'overview',
    label: 'Overview',
    path: `/${orgNameSlug}/backoffice/analytics-2`,
    disabled: false,
  },
  {
    value: 'stories',
    label: 'Stories',
    path: `/${orgNameSlug}/backoffice/analytics-2/stories`,
    disabled: false,
  },
  {
    value: 'videos',
    label: 'Videos',
    path: `/${orgNameSlug}/backoffice/analytics-2/videos`,
    disabled: false,
  },
  // {
  //   value: 'end-screens',
  //   label: 'End Screens',
  //   path: `/${orgNameSlug}/backoffice/analytics-2/end-screens`,
  //   disabled: true,
  // },
  // {
  //   value: 'visitors',
  //   label: 'Visitors',
  //   path: `/${orgNameSlug}/backoffice/analytics-2/visitors`,
  //   disabled: true,
  // },
];

function Analytics() {
  const { basicOrgData }: OutletContext = useOutletContext();
  const theme = useContext(ThemeContext);

  const [activeTab, setActiveTab] = useState<string>(
    availableTabs(basicOrgData.orgNameSlug)?.find((ta) => ta.value === 'overview')?.value ??
      'overview',
  );
  const location = useLocation();

  const [selectedDuration, setSelectedDuration] = useState<string>('7');
  const [dates, setDates] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const [videosData, setVideosData] = useState<EnhancedVideoCardType[]>([]);
  const [storiesData, setStoriesData] = useState<EnhancedStoryType[]>([]);

  const isCustomSelected = selectedDuration === 'custom';

  const handleStartDateChange = (newStartDate: Date | null) => {
    setDates((prevDates) => {
      if (newStartDate && prevDates.endDate && newStartDate > prevDates.endDate) {
        return { startDate: newStartDate, endDate: newStartDate };
      }

      return { ...prevDates, startDate: newStartDate };
    });
  };

  const handleEndDateChange = (newEndDate: Date | null) => {
    setDates((prevDates) => {
      if (newEndDate && prevDates.startDate && newEndDate < prevDates.startDate) {
        return { startDate: newEndDate, endDate: newEndDate };
      }
      return { ...prevDates, endDate: newEndDate };
    });
  };

  const longestLabelLength = Math.max(
    ...durationOptions(basicOrgData.lang).map((option) => option.label.length),
  );

  useEffect(() => {
    const locationPathname = location.pathname;

    const tab = availableTabs(basicOrgData.orgNameSlug).find((ta) =>
      locationPathname.endsWith(ta.value),
    );

    if (tab && tab.value) {
      setActiveTab(tab.value);
    } else {
      setActiveTab(
        availableTabs(basicOrgData.orgNameSlug)?.find((ta) => ta.value === 'overview')?.value ??
          'overview',
      );
    }
  }, [location.pathname, basicOrgData.orgNameSlug]);

  const viewContent = () => {
    if (activeTab === 'overview') {
      return <Overview selectedDuration={String(selectedDuration)} dates={dates} />;
    }
    if (activeTab === 'videos') {
      return (
        <Videos
          lang={basicOrgData.lang}
          encodedOrgId={basicOrgData.encodedId}
          videosData={videosData}
          setVideosData={setVideosData}
          selectedDuration={String(selectedDuration)}
          dates={dates}
        />
      );
    }
    if (activeTab === 'stories') {
      return (
        <Stories
          lang={basicOrgData.lang}
          encodedOrgId={basicOrgData.encodedId}
          selectedDuration={String(selectedDuration)}
          dates={dates}
          storiesData={storiesData}
          setStoriesData={setStoriesData}
        />
      );
    }

    return <div>Not implemented</div>;
  };

  return (
    <Box sx={{ p: 1, px: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <Grid
            container
            direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
            justifyContent={{
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'flex-start',
              lg: 'space-between',
              xl: 'space-between',
            }}
            alignItems={{
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'flex-start',
              lg: 'center',
              xl: 'center',
            }}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <Typography variant="headlineLargeBoldRecoleta">
                {translationsAnalytics.analytics[basicOrgData.lang]}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={isCustomSelected ? 6 : 8}
              xl={isCustomSelected ? 6 : 8}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <TabSelector
                    availableTabs={availableTabs(basicOrgData.orgNameSlug)}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={isCustomSelected ? 4 : 2}
              xl={isCustomSelected ? 4 : 2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Stack direction="row" spacing={1}>
                <Box sx={{ width: `${longestLabelLength * 1.3}ch` }}>
                  <Select
                    value={selectedDuration}
                    onChange={(e: SelectChangeEvent<string>) => {
                      const { value } = e.target;
                      if (value !== 'custom' && (dates.startDate || dates.endDate)) {
                        setDates({
                          startDate: null,
                          endDate: null,
                        });
                      } else if (value === 'custom') {
                        const endDate = new Date();
                        const startDate = new Date(
                          endDate.setDate(endDate.getDate() - (Number(selectedDuration) - 1)),
                        );
                        setDates({
                          startDate,
                          endDate,
                        });
                      }
                      setSelectedDuration(value);
                    }}
                    size="small"
                    sx={{
                      background: 'white',
                      border: 'none',
                      borderWidth: '0px',
                      width: '100%',
                      fontSize: '14px',
                      boxShadow: 'none',
                      textAlign: 'right',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                  >
                    {durationOptions(basicOrgData.lang).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {isCustomSelected && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={dates.startDate}
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          placeholder: translationsAnalytics.startDate[basicOrgData.lang],
                          sx: {
                            background: theme.palette.background.paper,
                          },
                          size: 'small',
                        },
                      }}
                    />

                    <DatePicker
                      value={dates.endDate}
                      onChange={handleEndDateChange}
                      slotProps={{
                        textField: {
                          placeholder: translationsAnalytics.endDate[basicOrgData.lang],
                          variant: 'outlined',
                          sx: {
                            background: theme.palette.background.paper,
                          },
                          size: 'small',
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          {viewContent()}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Analytics;
