import React, { useState, useContext } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Alert,
} from '@mui/material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import ThemeContext from '../../utils/ThemeContext';

interface User {
  id: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
}

export interface KillSwitch {
  id: number;
  featureName: string;
  isEnabled: boolean;
  lastModified: string;
  modifiedByUser: User;
}

interface KillSwitchInput {
  id: number;
  isEnabled: boolean;
  token: string | null;
}

interface GetKillSwitchesResponse {
  getKillSwitches: KillSwitch[];
}

export const GET_KILL_SWITCHES_QUERY = gql`
  query GetKillSwitches {
    getKillSwitches {
      id
      featureName
      isEnabled
      lastModified
      modifiedByUser {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

interface UpdateKillSwitchSettingsVariables {
  input: KillSwitchInput;
}

interface UpdateKillSwitchSettingsResponse {
  updateKillSwitchSettings: {
    token: string;
  };
}

export const UPDATE_KILL_SWITCH_SETTINGS_MUTATION = gql`
  mutation UpdateKillSwitchSettings($input: KillSwitchInput!) {
    updateKillSwitchSettings(input: $input) {
      token
    }
  }
`;

interface UserDetailsProps {
  user: User | null;
}

function UserDetails({ user }: UserDetailsProps) {
  if (!user) {
    return <Typography variant="bodySmall">N/A</Typography>;
  }

  return (
    <Box>
      <Typography variant="bodySmall">ID: {user.id}, </Typography>

      <Typography variant="bodySmall">Email: {user.email}</Typography>
      <br />
      <Typography variant="bodySmall">
        Name: {user.firstName} {user.lastName}
      </Typography>
    </Box>
  );
}

export default function KillSwitches() {
  const theme = useContext(ThemeContext);

  // token is a token retreived from first call to update, and which must be
  // used in the confirm update call.
  const [token, setToken] = useState<string | null>(null);

  // toggled is the KillSwitch settings that is "selected" for toggling.
  const [toggled, setToggled] = useState<KillSwitch | null>(null);

  const {
    data: getData,
    loading: getLoading,
    error: getError,
    refetch: getRefetch,
  } = useQuery<GetKillSwitchesResponse>(GET_KILL_SWITCHES_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const [updateKillSwitchSettings, { loading: updateLoading, error: updateError }] = useMutation<
    UpdateKillSwitchSettingsResponse,
    UpdateKillSwitchSettingsVariables
  >(UPDATE_KILL_SWITCH_SETTINGS_MUTATION);

  const killSwitches = getData?.getKillSwitches ?? [];

  const anyError = getError || updateError || null;

  // startToggle starts toggling a killSwitch, fetching a token which we
  // include in the following update call, if the user confirms the action.
  const startToggle = (killSwitch: KillSwitch) => {
    setToggled(killSwitch);
    setToken(null);

    // Fetch token for toggled killSwitch.
    const input: KillSwitchInput = {
      id: killSwitch.id,
      isEnabled: !killSwitch.isEnabled,
      token: null,
    };
    updateKillSwitchSettings({ variables: { input } })
      .then((res) => {
        const newToken = res.data!.updateKillSwitchSettings.token;
        setToken(newToken);
      })
      .catch((err) => {
        console.error('updateKillSwitchSettings error', err);
      });
  };

  const cancelToggle = () => {
    setToken(null);
    setToggled(null);
  };

  const confirmToggle = () => {
    // Update toggled kill switch using token retreived earlier.
    const input: KillSwitchInput = {
      id: toggled!.id,
      isEnabled: !toggled!.isEnabled,
      token,
    };
    updateKillSwitchSettings({ variables: { input } })
      .then(() => {
        setToken(null);
        setToggled(null);
        return getRefetch();
      })
      .catch((err) => {
        console.error('updateKillSwitchSettings error', err);
      });
  };

  const columns: GridColDef<KillSwitch>[] = [
    {
      field: 'featureName',
      headerName: 'Feature Name',
      flex: 1,
      sortable: true,
      type: 'string',
      editable: false,
    },
    {
      field: 'lastModified',
      headerName: 'Last Modified',
      flex: 1,
      sortable: true,
      type: 'string',
      editable: false,
      renderCell: (params) => {
        return <span>{new Date(Number(params.value)).toLocaleString()}</span>;
      },
    },

    {
      field: 'modifiedByUser',
      headerName: 'Last Modified By',
      flex: 1,
      sortable: true,
      type: 'string',
      editable: false,
      renderCell: (params) => <UserDetails user={params.value} />,
    },

    {
      field: 'isEnabled',
      headerName: 'Status',
      flex: 1,
      sortable: true,
      type: 'boolean',
      renderCell: (params) => (
        <IconButton onClick={() => startToggle(params.row)} disabled={updateLoading}>
          {params.value ? (
            <ToggleOnIcon style={{ color: 'green' }} />
          ) : (
            <ToggleOffIcon style={{ color: 'red' }} />
          )}
        </IconButton>
      ),
    },
  ];

  const renderContent = () => {
    if (anyError) {
      return (
        <Alert severity="error">
          Error: <code>{anyError.message}</code>
        </Alert>
      );
    }

    return (
      <>
        <DataGrid
          rows={killSwitches}
          columns={columns}
          loading={getLoading}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnFilter
        />
        <Dialog
          open={toggled != null}
          onClose={() => cancelToggle()}
          sx={{
            '& .MuiPaper-root': {
              overflowY: 'scroll',
              borderRadius: '20px',
            },
          }}
        >
          <DialogTitle>
            <Typography variant="titleLargeRecoletaBold"> Confirm Action</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="titleMedium">
                Are you sure you want to {toggled?.isEnabled ? 'disable' : 'enable'} the feature{' '}
                {`"`}
                {toggled?.featureName}
                {`"`}?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              p: 0.5,
            }}
          >
            <Button
              onClick={() => cancelToggle()}
              color="primary"
              variant="outlined"
              disabled={updateLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                confirmToggle();
              }}
              color="error"
              disabled={updateLoading}
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return <div style={{ height: 'auto', width: '100%' }}>{renderContent()}</div>;
}
