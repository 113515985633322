import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VideoCard as VideoCardType } from 'src/utils/video-card/utils';
import { useBackOfficeStatsApolloClient } from 'src/context/ApolloClientContext';
import { GET_ALL_VIDEOS_WITH_STATS } from './queries';
import formatDateString from '../../formatDateString';
import { sumTotalImpressions, sumTotalPlays, sumTotalTimePlayed } from '../Overview/dataUtils';
import { EnhancedVideoCardType } from './types';

const useFetchVideos = (
  encodedOrgId: string,
  selectedDuration: string,
  dates: { startDate: Date | null; endDate: Date | null },
  setVideosData: (data: EnhancedVideoCardType[]) => void,
) => {
  const backOfficeStatsClient = useBackOfficeStatsApolloClient();
  const [fetchVideos, { loading, error }] = useLazyQuery(GET_ALL_VIDEOS_WITH_STATS, {
    client: backOfficeStatsClient,
  });

  useEffect(() => {
    const variables: {
      encodedOrgId: string;
      startDate?: string;
      endDate?: string;
    } = { encodedOrgId };

    if (selectedDuration === 'custom') {
      variables.startDate = dates.startDate ? formatDateString(dates.startDate) : undefined;
      variables.endDate = dates.endDate ? formatDateString(dates.endDate) : undefined;
    } else if (!Number.isNaN(Number(selectedDuration))) {
      const numberDuration = Number(selectedDuration);
      const calculatedStartDate = new Date();
      calculatedStartDate.setDate(calculatedStartDate.getDate() - (numberDuration - 1));
      const calculatedEndDate = new Date();
      variables.startDate = formatDateString(calculatedStartDate);
      variables.endDate = formatDateString(calculatedEndDate);
    }

    fetchVideos({ variables }).then((response) => {
      const { data } = response;
      if (data?.getAllVideosWithStats) {
        const enhancedData = data.getAllVideosWithStats.map((video: VideoCardType) => {
          const totalImpressions = video.stats?.playback?.impression
            ? sumTotalImpressions(
                video.stats.playback.impression,
                variables.startDate,
                variables.endDate,
              )
            : 0;

          const totalTimePlayed = video.stats?.playback?.timePlayed
            ? sumTotalTimePlayed(
                video.stats.playback.timePlayed,
                variables.startDate,
                variables.endDate,
              )
            : 0;

          const totalPlays = video.stats?.playback?.play
            ? sumTotalPlays(video.stats.playback.play, variables.startDate, variables.endDate)
            : 0;

          const totalPlayRate =
            totalImpressions && totalPlays ? (totalPlays / totalImpressions) * 100 : 0;

          return { ...video, totalImpressions, totalTimePlayed, totalPlays, totalPlayRate };
        });
        setVideosData(enhancedData);
      }
    });
  }, [encodedOrgId, selectedDuration, dates, fetchVideos, setVideosData]);

  return { loading, error };
};

export default useFetchVideos;
