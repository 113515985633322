import React from 'react';
import { Outlet, Route } from 'react-router';
import { ApolloProvider } from '@apollo/client';

import {
  useAdminApolloClient,
  useAdminApolloClientUnsafe,
  useBackOfficeApolloClient,
  useBackOfficeApolloClientUnsafe,
  useBackOfficeStatsApolloClientUnsafe,
} from 'src/context/ApolloClientContext';

import LoginDispatcher from 'src/components/public/LoginDispatcher';
import adminRoutes from './AdminRoutes';
import backOfficeRoutes from './BackOfficeRoutes';

// WithBackOfficeApolloClientRequired renders children if a non-null
// backOfficeClient is available through context.
function WithBackOfficeApolloClientRequired({ children }: { children: React.ReactElement }) {
  const backOfficeClient = useBackOfficeApolloClientUnsafe();
  if (backOfficeClient == null) {
    return null;
  }
  return children;
}

// WithBackOfficeApolloClientRequired renders children if a non-null
// backOfficeStatsClient is available through context.
function WithBackOfficeStatsApolloClientRequired({ children }: { children: React.ReactElement }) {
  const backOfficeStatsClient = useBackOfficeStatsApolloClientUnsafe();
  if (backOfficeStatsClient == null) {
    return null;
  }
  return children;
}

// WithBackOfficeApolloClientRequired renders children if a non-null
// adminClient is available through context.
function WithAdminApolloClientRequired({ children }: { children: React.ReactElement }) {
  const adminClient = useAdminApolloClientUnsafe();
  if (adminClient == null) {
    return null;
  }
  return children;
}

// DefaultBackOfficeApolloClientProvider renders children within an ApolloProvider,
// setting the default ApolloClient (used by default for e.g. useQuery) to the
// backOfficeClient.
function DefaultBackOfficeApolloClientProvider({ children }: { children: React.ReactElement }) {
  const backOfficeClient = useBackOfficeApolloClient();
  return <ApolloProvider client={backOfficeClient}>{children}</ApolloProvider>;
}

// DefaultAdminApolloClientProvider renders children within an ApolloProvider,
// setting the default ApolloClient (used by default for e.g. useQuery) to the
// adminClient.
function DefaultAdminApolloClientProvider({ children }: { children: React.ReactElement }) {
  const adminClient = useAdminApolloClient();
  return <ApolloProvider client={adminClient}>{children}</ApolloProvider>;
}

function BackOfficeRoutesOutlet() {
  return (
    <WithBackOfficeApolloClientRequired>
      <WithBackOfficeStatsApolloClientRequired>
        <DefaultBackOfficeApolloClientProvider>
          <Outlet />
        </DefaultBackOfficeApolloClientProvider>
      </WithBackOfficeStatsApolloClientRequired>
    </WithBackOfficeApolloClientRequired>
  );
}

function AdminRoutesOutlet() {
  return (
    <WithAdminApolloClientRequired>
      <DefaultAdminApolloClientProvider>
        <Outlet />
      </DefaultAdminApolloClientProvider>
    </WithAdminApolloClientRequired>
  );
}

const authenticatedRoutes = (
  <>
    <Route element={<BackOfficeRoutesOutlet />}>{backOfficeRoutes}</Route>
    <Route element={<AdminRoutesOutlet />}>{adminRoutes}</Route>

    <Route
      path="/login-dispatch"
      element={
        <WithAdminApolloClientRequired>
          <LoginDispatcher />
        </WithAdminApolloClientRequired>
      }
    />
  </>
);

export default authenticatedRoutes;
