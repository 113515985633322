import { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { gqClient } from '../../utils/graphQL';
import { translations } from '../../utils/translations';
import AppWrapper from './AppWrapper';
import './public.scss';
import Loader from '../common/Loader';
import MadeWithLoveFooter from './MadeWithLoveFooter';
import BackgroundCircles from './BackgroundCircles';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

function EmailPreferences(props) {
  const [encodedOrgId, setEncodedOrgId] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const params = useParams();
  useEffect(() => {
    const { usertoken, encodedorgid } = params;
    setEncodedOrgId(encodedorgid);
    setUserToken(usertoken);
  }, []);
  if (!userToken || !encodedOrgId) return null;
  return (
    <AppWrapper encodedOrgId={encodedOrgId}>
      <EmailPreferencesInner token={userToken} />
    </AppWrapper>
  );
}

export default EmailPreferences;

const getUserEmailPreferences = (token, encodedOrgId) => {
  return gqClient.query({
    query: gql`
        query {
          userEmailPreferences(input: {token: "${token}", encodedOrgId: "${encodedOrgId}"}){
              email
              unsubscribeEmails
          }
        }
    `,
  });
};

const setEmailPreferences = (token, encodedOrgId, unsubscribeEmails) => {
  return gqClient.mutate({
    mutation: gql`
        mutation {
          setEmailPreferences(input: {token: "${token}", encodedOrgId: "${encodedOrgId}", unsubscribeEmails: ${unsubscribeEmails}}){
            statusMessage
          }
        }
    `,
  });
};

export function EmailPreferencesInner({ basicOrgData, token }) {
  const lang = 'en';

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [unsubscribeEmails, setUnsubscribeEmails] = useState(false);

  useEffect(() => {
    getUserEmailPreferences(token, basicOrgData.encodedId)
      .then((response) => {
        setEmail(response.data.userEmailPreferences.email);
        setUnsubscribeEmails(!response.data.userEmailPreferences.unsubscribeEmails);
      })
      .catch((e) => {
        setError(e.message);
      });
  }, []);
  useEffect(() => {}, [email, unsubscribeEmails, error, success]);

  const errorMsg = error && <div className="error-pop">{error}</div>;
  const successMsg = success ? (
    <div className="info-pop relative">{success}</div>
  ) : (
    <div className="info-pop relative-empty-filler" />
  );

  const submit = () => {
    setEmailPreferences(token, basicOrgData.encodedId, !unsubscribeEmails)
      .then((res) => {
        setSuccess(translations.emailPreference.successUpdate[lang]);
        setTimeout(() => {
          setSuccess(null);
        }, 4000);
      })
      .catch((e) => {
        setError(e.message);
        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  };

  const disabled = email === null || email === '' || unsubscribeEmails === null;

  const content = (
    <div>
      {disabled && <div className="email-preferences-loading">{!errorMsg && <Loader />}</div>}
      <div className="fieldset-wrapper">
        <fieldset disabled={disabled}>
          <h1>{translations.emailPreference.emailPreferenceHeader[lang]}</h1>
          <label className="checkbox-label" htmlFor="unsubscribeEmails">
            <input
              type="checkbox"
              name="unsubscribeEmails"
              id="unsubscribeEmails"
              checked={unsubscribeEmails}
              onChange={() => setUnsubscribeEmails(!unsubscribeEmails)}
            />
            <span>{translations.emailPreference.newsletterProductNews[lang]}</span>
          </label>
          <label htmlFor="email">
            <span>{translations.email[lang]}</span>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              readOnly="readonly"
              disabled="disabled"
            />
          </label>
          <div className="fieldset-footer">
            <Button variant="contained" disableElevation type="submit" onClick={() => submit()}>
              {translations.emailPreference.updatePreferenceBtn[lang]}
            </Button>
          </div>
          {successMsg}
        </fieldset>
        <BackgroundCircles />
      </div>
    </div>
  );

  return (
    <div>
      {errorMsg}
      <div className="email-preference header">
        <a
          className="btn action-btn login-btn"
          target="_blank"
          href={`/${basicOrgData.orgNameSlug}/backoffice`}
          rel="noreferrer"
        >
          <i className="icon">lock</i>
          <span>{translations.login[lang]}</span>
        </a>
      </div>
      <div className="email-preferences public">
        <img
          className="logo"
          src="/images/life-inside-logo-pink.svg"
          width="123"
          alt="Life Inside"
          aria-hidden="true"
        />
        {content}
        <MadeWithLoveFooter />
      </div>
    </div>
  );
}
