import { gql } from '@apollo/client/index';

export const VIDEO_CARD_SUMMARY_FRAGMENT = gql`
  fragment VideoCardSummary on VideoCard {
    id
    index
    encodedId
    actorName
    orgId
    recipient {
      id
      firstName
      lastName
      title
      name
    }
    actorTitle {
      sv
      en
    }
    tags {
      id
      name {
        sv
        en
      }
    }
    videoLink {
      sv
      en
      FHD
      HD
      qHD
      nHD
      ntsc
      originalVideo
    }
    subtitles {
      label
      src
      srcLang
      default
    }
    question {
      en
      sv
      videoRequestId
      questionId
      videoCardId
      answeredTimeStampTz
    }
    staticThumbnails {
      base_link
      FHD
      HD
      qHD
      nHD
      ntsc
    }
    animatedThumbnails {
      FHD
      HD
      qHD
      nHD
      ntsc
    }
  }
`;

export const VIDEO_CARD_DETAILS_FRAGMENT = gql`
  fragment VideoCardDetails on VideoCard {
    id
    index
    encodedId
    status
    actorName
    actorEmail
    orgId
    actorTitle {
      sv
      en
    }
    lastReminderTime
    lastReminderByUser
    requestTime
    requestedByUser
    videoLink {
      sv
      en
      FHD
      HD
      qHD
      nHD
      ntsc
      originalVideo
    }
    subtitles {
      label
      src
      srcLang
      default
    }

    question {
      sv
      en
      videoRequestId
      questionId
      videoCardId
      answeredTimeStampTz
    }
    internalNote {
      sv
      en
    }
    tags {
      id
      name {
        sv
        en
      }
    }
    user {
      id
      firstName
      lastName
    }
    interactionOption {
      __typename
      ... on ContactForm {
        msg {
          sv
          en
        }
        confirmationMsg {
          sv
          en
        }
        email
        type
        btnText {
          sv
          en
        }
      }
      ... on CallToAction {
        type
        msg {
          sv
          en
        }
        url
        btnText {
          sv
          en
        }
      }
    }
    hideFromLibrary
    actorPhoneNumber
    consentTexts {
      soMeConsent
      uploadConsent
    }
    created
    recipient {
      id
      orgId
      firstName
      lastName
      phoneNumber
      email
      title
      receiveAutoRequests
      leader
      name
    }
    recipients {
      id
      orgId
      firstName
      lastName
      name
      phoneNumber
      email
      title
      receiveAutoRequests
      leader
    }
    staticThumbnails {
      base_link
      FHD
      HD
      qHD
      nHD
      ntsc
    }
    animatedThumbnails {
      FHD
      HD
      qHD
      nHD
      ntsc
    }
  }
`;
