import { useCallback, useEffect, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';
import { useParams, useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import AlertDialog from 'src/components/common/AlertDialog';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import VideoCollectorSettingsHeader from './VideoCollectorSettingsHeader';
import VideoCollectorSettingsFooter from './VideoCollectorSettingsFooter';
import Settings from './Settings';
import { VideoCollectorDetailed, VideoCollectorInput } from '../types';
import { ActiveTab, RouteOutletContext } from './types';
import { publicPathWithPreviewToken } from '../paths';
import Videos from './Videos';
import ReviewLibrary from './Review/ReviewLibrary';
import { VIDEO_COLLECTOR_FIELDS } from './fragments';
import videoCollectorTranslations from '../VideoCollectorTranslations';

export interface GetVideoCollectorResponse {
  getVideoCollector: VideoCollectorDetailed;
}

export const GET_VIDEO_COLLECTOR_QUERY = gql`
  ${VIDEO_COLLECTOR_FIELDS}
  query GetVideoCollector($encodedVideoCollectorId: String!) {
    getVideoCollector(encodedVideoCollectorId: $encodedVideoCollectorId) {
      ...VideoCollectorFields
    }
  }
`;

export interface DeleteVideoCollectorResponse {
  deleteVideoCollector: VideoCollectorDetailed[];
}

export const DELETE_VIDEO_COLLECTOR_MUTATION = gql`
  ${VIDEO_COLLECTOR_FIELDS}
  mutation DeleteVideoCollector($encodedVideoCollectorId: String!) {
    deleteVideoCollector(encodedVideoCollectorId: $encodedVideoCollectorId) {
      ...VideoCollectorFields
    }
  }
`;

export interface UpdateVideoCollectorResponse {
  updateVideoCollector: VideoCollectorDetailed;
}

export const UPDATE_VIDEO_COLLECTOR_MUTATION = gql`
  ${VIDEO_COLLECTOR_FIELDS}
  mutation UpdateVideoCollector($encodedVideoCollectorId: String!, $input: VideoCollectorInput!) {
    updateVideoCollector(encodedVideoCollectorId: $encodedVideoCollectorId, input: $input) {
      ...VideoCollectorFields
    }
  }
`;

export interface CreateVideoCollectorPreviewTokenResponse {
  createVideoCollectorPreviewToken: string;
}

export const CREATE_VIDEO_COLLECTOR_PREVIEW_TOKEN_MUTATION = gql`
  mutation CreateVideoCollectorPreviewToken($encodedVideoCollectorId: String!) {
    createVideoCollectorPreviewToken(encodedVideoCollectorId: $encodedVideoCollectorId)
  }
`;

const videoCollectorInput = (videoCollector: VideoCollectorDetailed): VideoCollectorInput => ({
  title: videoCollector.title,
  description: videoCollector.description,
  status: videoCollector.status,
  password: videoCollector.password,
  usePassword: videoCollector.usePassword,
  thankYouEmailContent: videoCollector.thankYouEmailContent,
  approvedEmailContent: videoCollector.approvedEmailContent,
  allowVideoLibrary: videoCollector.allowVideoLibrary,
  allowVideoLibraryNotReviewed: videoCollector.allowVideoLibraryNotReviewed,
  allowUserEnteredQuestions: videoCollector.allowUserEnteredQuestions,
  saveName: videoCollector.saveName,
  saveNameRequired: videoCollector.saveNameRequired,
  saveTitle: videoCollector.saveTitle,
  saveTitleRequired: videoCollector.saveTitleRequired,
  saveEmail: videoCollector.saveEmail,
  saveEmailRequired: videoCollector.saveEmailRequired,
  savePhone: videoCollector.savePhone,
  savePhoneRequired: videoCollector.savePhoneRequired,
  startDate: videoCollector.startDate,
  endDate: videoCollector.endDate,
  videoCardIds: videoCollector.videoCards?.map((card) => card.id),
  orgQuestionIds: videoCollector.questions
    .filter((question) => question.__typename === 'OrgQuestion')
    .map((orgQuestion) => orgQuestion.id),
  customQuestions: videoCollector.questions
    .filter((question) => question.__typename !== 'OrgQuestion')
    .map((customQuestion) => customQuestion.question),
  responsibleId: videoCollector.responsible?.id ?? null,
});

function VideoCollectorSettings() {
  const lang = useLanguage();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<ActiveTab>('settings');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { basicOrgData } = useOutletContext<RouteOutletContext>();

  const params = useParams();
  const encodedVideoCollectorId = params.videocollectorid!;

  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef<Element>();

  const { data: initialData, loading: getInitialLoading } = useQuery<GetVideoCollectorResponse>(
    GET_VIDEO_COLLECTOR_QUERY,
    {
      variables: { encodedVideoCollectorId },
      fetchPolicy: 'cache-first',
    },
  );
  const initialVideoCollector = initialData?.getVideoCollector;

  const [hasChanges, setHasChanges] = useState(false);
  const [updatedVideoCollector, setUpdatedVideoCollector] = useState<VideoCollectorDetailed | null>(
    null,
  );
  const videoCollector = updatedVideoCollector ?? initialVideoCollector;

  const onVideoCollectorChanged = useCallback(
    (change: Partial<VideoCollectorDetailed>) => {
      if (!initialVideoCollector) {
        return;
      }
      setHasChanges(true);
      setUpdatedVideoCollector((prevState) => ({
        ...(prevState ?? initialVideoCollector),
        ...change,
      }));
    },
    [initialVideoCollector, setUpdatedVideoCollector],
  );

  const isStatusActive = videoCollector?.status === 'LIVE';

  const [deleteVideoCollector, { loading: deleteLoading }] = useMutation(
    DELETE_VIDEO_COLLECTOR_MUTATION,
  );

  const [updateVideoCollector, { loading: updateLoading }] = useMutation(
    UPDATE_VIDEO_COLLECTOR_MUTATION,
  );

  const [createPreviewToken, { loading: createPreviewTokenLoading }] =
    useMutation<CreateVideoCollectorPreviewTokenResponse>(
      CREATE_VIDEO_COLLECTOR_PREVIEW_TOKEN_MUTATION,
      { variables: { encodedVideoCollectorId } },
    );

  const anyLoading =
    getInitialLoading || deleteLoading || updateLoading || createPreviewTokenLoading;
  const disableActions = anyLoading || videoCollector == null;

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.getBoundingClientRect().height);
    }
  }, []);

  const navigateBack = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    pathParts.pop();
    const newPath = `/${pathParts.join('/')}`;
    navigate(newPath);
  };

  const handleOnBackClick = () => {
    navigateBack();
  };

  const handleOnSaveClicked = () => {
    if (disableActions) {
      return;
    }

    const input = videoCollectorInput({ ...videoCollector });
    updateVideoCollector({
      variables: { encodedVideoCollectorId, input },
      onCompleted: () => navigateBack(),
    });
  };

  const handleOnActivateClicked = () => {
    if (disableActions) {
      return;
    }

    // Toggle between LIVE and EDIT status.
    const newStatus = isStatusActive ? 'EDIT' : 'LIVE';
    const input = videoCollectorInput({ ...videoCollector, status: newStatus });

    updateVideoCollector({
      variables: { encodedVideoCollectorId, input },
      onCompleted: () => navigateBack(),
    });
  };

  const openPreviewTab = async () => {
    const result = await createPreviewToken();
    if (result.data?.createVideoCollectorPreviewToken == null) {
      throw new Error('could not create preview token');
    }
    const previewToken = result.data.createVideoCollectorPreviewToken;
    const path = publicPathWithPreviewToken({
      encodedOrgId: basicOrgData.encodedId,
      encodedVideoCollectorId,
      previewToken,
    });
    window.open(path);
  };

  const saveAndOpenPreviewTab = async () => {
    if (disableActions) {
      return;
    }
    const input = videoCollectorInput({ ...videoCollector });
    await updateVideoCollector({ variables: { encodedVideoCollectorId, input } });
    setHasChanges(false);
    await openPreviewTab();
  };

  const handlePreviewDialogOnlyPreviewClicked = () => {
    openPreviewTab()
      .catch((err) => console.error('openPreview', err))
      .finally(() => {
        setPreviewDialogOpen(false);
      });
  };

  const handlePreviewDialogSaveAndPreviewClicked = () => {
    saveAndOpenPreviewTab()
      .catch((err) => console.error('saveAndOpenPreviewTab', err))
      .finally(() => {
        setPreviewDialogOpen(false);
      });
  };

  const handleOnPreviewClicked = () => {
    if (disableActions) {
      return;
    }
    if (hasChanges) {
      setPreviewDialogOpen(true);
    } else {
      handlePreviewDialogOnlyPreviewClicked();
    }
  };

  const handleOnDeleteClicked = () => {
    deleteVideoCollector({
      variables: {
        encodedVideoCollectorId,
      },
      onCompleted: () => navigateBack(),
    });
  };

  const renderContent = () => {
    if (getInitialLoading) {
      return (
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      );
    }

    if (videoCollector == null) {
      return <p>Not found.</p>;
    }

    switch (activeTab) {
      case 'review':
        return (
          <ReviewLibrary
            videoCollector={videoCollector}
            ref={footerRef}
            disabled={disableActions}
            onChange={onVideoCollectorChanged}
          />
        );
      case 'videos':
        return <Videos videoCollector={videoCollector} />;
      default:
        return (
          <Settings
            basicOrgData={basicOrgData}
            videoCollector={videoCollector}
            onChange={onVideoCollectorChanged}
          />
        );
    }
  };

  const renderFooter = () => {
    if (activeTab === 'settings') {
      return (
        <VideoCollectorSettingsFooter
          ref={footerRef}
          disabled={disableActions}
          isActive={isStatusActive}
          onDeleteClicked={() => setDeleteDialogOpen(true)}
          onPreviewClicked={handleOnPreviewClicked}
          onActivateClicked={handleOnActivateClicked}
          onSaveClicked={handleOnSaveClicked}
        />
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <AlertDialog
        open={deleteDialogOpen}
        title={videoCollectorTranslations.deleteItem[lang]}
        description={videoCollectorTranslations.areYouSureDelete[lang]}
        confirmBtnText={videoCollectorTranslations.deleteConfirm[lang]}
        cancelBtnText={videoCollectorTranslations.noCancel[lang]}
        onConfirm={() => {
          setDeleteDialogOpen(false);
          handleOnDeleteClicked();
        }}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
      />
      <AlertDialog
        open={previewDialogOpen}
        title={videoCollectorTranslations.previewDialogTitle[lang]}
        description={videoCollectorTranslations.previewDialogBody[lang]}
        confirmBtnText={videoCollectorTranslations.previewDialogConfirmSaveAndPreview[lang]}
        cancelBtnText={videoCollectorTranslations.preview[lang]}
        confirmBtnColor="primary"
        onConfirm={() => {
          handlePreviewDialogSaveAndPreviewClicked();
        }}
        onCancel={() => {
          handlePreviewDialogOnlyPreviewClicked();
        }}
        onClose={() => {
          setPreviewDialogOpen(false);
        }}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        sx={{
          pt: 2,
          pl: 3,
          pr: 2,
          pb: footerHeight / 8 + 3,
        }}
        role="grid"
        data-testid="video-collector-settings-grid"
      >
        <VideoCollectorSettingsHeader
          onBackClick={handleOnBackClick}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          videoCards={videoCollector?.videoCards}
        />
        {renderContent()}
        {renderFooter()}
      </Grid>
    </Box>
  );
}

export default VideoCollectorSettings;
