import { useState, useEffect, useMemo } from 'react';
import LazyLoad from 'react-lazy-load';
import Box from '@mui/material/Box';

import { useLanguage } from 'src/context/LanguageContext';
import { useApiUtilsStoryline } from '../../../utils/api-utils-storyline';
import { statuses } from '../../../utils/utils';
import VideoCard from '../VideoLibrary/VideoCard';
import getTheme from '../../common/SplitButton/SplitButtonThemes';

export default function VideoFunnelVideoLibrary({
  basicOrgData,
  selectedVideoCards,
  setSelectedVideoCards,
  maxVideos,
  searchField,
  editSelected = true,
  disabled = false,
  selectAllVideos,
}) {
  const lang = useLanguage();
  const apiUtilsStoryline = useApiUtilsStoryline();
  const height = 410;
  const width = height * 0.516;

  const [allVideoCards, setAllVideoCards] = useState([]);
  const [selectAllLastState, setSelectAllLastState] = useState(selectAllVideos);

  useEffect(() => {
    apiUtilsStoryline
      .getAllVideoCardsByOrgId(basicOrgData.encodedId, [statuses.LIVE])
      .then((res) => {
        setAllVideoCards([
          ...(res?.data?.allLiveVideoCardsByOrgIdByStatus?.videoCards ?? []),
          ...(res?.data?.allLiveVideoCardsByOrgIdByStatus?.VideoRequests ?? []),
        ]);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {}, [selectedVideoCards]);

  const videoCards = useMemo(() => {
    if (searchField === '') {
      return allVideoCards
        ?.filter((vc) => (editSelected ? true : selectedVideoCards?.find((v) => v.id === vc.id)))
        ?.sort((a, b) => a?.question[lang]?.localeCompare(b?.question[lang]));
    }
    return allVideoCards
      ?.filter((vc) => {
        const searchFields =
          `${vc?.question?.[lang]?.toLowerCase()} ` +
          `${vc?.actorEmail?.toLowerCase()} ` +
          `${vc?.actorName?.toLowerCase()} ` +
          `${vc?.actorPhoneNumber?.toLowerCase()} ` +
          `${vc?.actorTitle?.[lang]?.toLowerCase()} ` +
          `${vc?.recipient?.email?.toLowerCase()} ` +
          `${vc?.recipient?.firstName?.toLowerCase()} ` +
          `${vc?.recipient?.lastName?.toLowerCase()} ` +
          `${vc?.recipient?.name?.toLowerCase()} ` +
          `${vc?.recipient?.phoneNumber?.toLowerCase()} ` +
          `${vc?.recipient?.title?.toLowerCase()} ` +
          `${vc?.tags?.join(' ')?.toLowerCase()} `;
        return (
          (editSelected ? true : selectedVideoCards?.find((v) => v.id === vc.id)) &&
          searchFields?.includes(searchField?.toLowerCase())
        );
      })
      ?.sort((a, b) => a?.question[lang]?.localeCompare(b?.question[lang]));
  }, [searchField, allVideoCards, editSelected, selectedVideoCards]);

  useEffect(() => {
    if (selectAllVideos !== selectAllLastState) {
      if (selectAllVideos) {
        setSelectedVideoCards([...videoCards]);
      } else {
        setSelectedVideoCards([]);
      }
      setSelectAllLastState(selectAllVideos);
    }
  }, [selectAllVideos]);

  const [dynamicDimensions, setDynamicDimensions] = useState(
    allVideoCards?.map((v, index) => ({
      indexOfItem: index,
      width,
      height,
      ...(v?.type === 'video_request' ? { videoRequestId: v.id } : { videoCardId: v.id }),
    })) ?? [],
  );
  useEffect(() => {
    setDynamicDimensions(
      allVideoCards?.map((v, index) => {
        const dimension = dynamicDimensions?.find((c) => {
          if (v?.type === 'video_request') {
            return v.id === c.videoRequestId;
          }
          return v.id === c.videoCardId;
        });

        return {
          indexOfItem: index,
          width: dimension?.width ?? width,
          height: dimension?.height ?? height,
          ...(v?.type === 'video_request' ? { videoRequestId: v.id } : { videoCardId: v.id }),
        };
      }) ?? [],
    );
  }, [allVideoCards]);

  const handleSetDynamicDimensions = (e) => {
    const items = [...dynamicDimensions];
    const filteredItems =
      items?.filter((v) => {
        if (v?.videoCardId && e?.videoCardId) {
          return v?.videoCardId !== e?.videoCardId;
        }
        if (v?.videoRequestId && e?.videoRequestId) {
          return v?.videoRequestId !== e?.videoRequestId;
        }

        return true;
      }) ?? [];

    setDynamicDimensions([...filteredItems, e]);
  };

  const handleSelected = (e) => {
    const indexOfItem = _.findIndex(selectedVideoCards, { id: e.id });
    if (indexOfItem >= 0) {
      const items = selectedVideoCards;
      items?.splice(indexOfItem, 1);
      setSelectedVideoCards([...items]);
    } else if (!maxVideos) {
      setSelectedVideoCards([...selectedVideoCards, e]);
    } else if (maxVideos === 1) {
      setSelectedVideoCards([e]);
    } else if (selectedVideoCards?.length < maxVideos) {
      setSelectedVideoCards([...selectedVideoCards, e]);
    }
  };

  const theme = getTheme('active');
  return (
    <Box
      style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}
      disabled={disabled}
    >
      {videoCards?.map((videoCard) => (
        <LazyLoad
          key={videoCard.id}
          width={
            (dynamicDimensions?.find((v) => {
              if (videoCard?.type === 'video_request') {
                return videoCard.id === v.videoRequestId;
              }
              return videoCard.id === v.videoCardId;
            })?.width ?? width) * 1.1
          }
          height={height * 1.1}
          offset={500}
        >
          <VideoCard
            key={videoCard.id}
            playMode={false}
            theme={theme}
            videoCard={videoCard}
            orgData={basicOrgData}
            updateVideoCard={() => {}}
            height={height}
            width={width}
            padding={height * 0.025}
            setAffectedItem={() => {}}
            setInfo={() => {}}
            handleUpdateBoard={() => {}}
            suggestedQuestions={[]}
            orgRecipients={[]}
            setError={() => {}}
            handleDuplicateItemClick={() => {}}
            storylineEditMode
            onClick={(e) => {
              handleSelected(videoCard);
            }}
            storylinePickVideoCardMode
            storylineIndexOfItem={-1}
            storylineSelected={_.findIndex(selectedVideoCards, { id: videoCard.id }) >= 0}
            dynamicDimensions={dynamicDimensions}
            setDynamicDimensions={(e) => {
              handleSetDynamicDimensions(e);
            }}
            reachedMaxVideos={
              maxVideos > 0 &&
              selectedVideoCards?.length >= maxVideos &&
              !selectedVideoCards?.find((v) => v.id === videoCard.id)
            }
            disabled={disabled}
          />
        </LazyLoad>
      ))}
    </Box>
  );
}
