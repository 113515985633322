import React, { useState, useEffect, useRef } from 'react';
import LazyLoad from 'react-lazy-load';

import _ from 'lodash';
import { gql } from '@apollo/client';
import { WebVTTParser } from 'webvtt-parser';

import { useLanguage } from 'src/context/LanguageContext';
import { gqClient } from '../../utils/graphQL';

import VideoControls from './VideoControlsHtml5';

const parser = new WebVTTParser();

// createVideoStatsEvent
const sendVideoStats = ({
  encodedOrgId,
  videoCardId,
  sessionId,
  statsType,
  viewContext,
  matches = [],
}) => {
  const matchesString = matches
    ?.map((match) =>
      [
        `{`,
        [
          `expressionType:"${match.expressionType}"`,
          `expressionValue:"${match.expressionValue}"`,
          match.storylineId ? `storylineId:${match.storylineId}` : '',
          match.videoFunnelId ? `videoFunnelId:${match.videoFunnelId}` : '',
          match.override ? `override:true` : '',
        ]
          ?.filter((o) => o !== '')
          ?.join(','),
        `}`,
      ]
        ?.filter((o) => o !== '')
        ?.join(''),
    )
    ?.filter((o) => o !== '')
    ?.join(',');
  const url = window.location.href;

  return gqClient.mutate({
    mutation: gql`
        mutation {
          createVideoStatsEvent(input: {
                                        encodedOrgId: "${encodedOrgId}",
                                        sessionId: "${sessionId}",
                                        videoCardId: ${videoCardId},
                                        context: "${viewContext}",
                                        statsType: "${statsType}",
                                        ${matches?.length > 0 ? `matches: [${matchesString}],` : ''}
                                        url: "${url}"
                                        }){
            statusMessage
          }
        }
    `,
  });
};

function VideoCard(props) {
  const lang = useLanguage();

  const videoPlayerRef = useRef();
  const activeVideoData = props.data;
  const { width = 244, height = 500, padding = 10 } = props;
  const videoWidth = width - padding * 2;
  const videoHeight = videoWidth * (16 / 9);
  const { basicOrgData, index } = props;
  const defaultQuality = 'nHD';
  const doNotTrack = props.basicOrgData.doNotTrack ? props.basicOrgData.doNotTrack : false;
  const { sessionId } = props;
  const [videoDimensions, setVideoDimensions] = useState({ width: 240, height: 426 });

  const { widgetMatches } = props;

  const [isPlaying, setIsPlaying] = useState(false);

  const [hasStartedPlaying, seHasStartedPlaying] = useState(false);

  useEffect(() => {
    if (!hasStartedPlaying && isPlaying) {
      seHasStartedPlaying(true);
    }
  }, [isPlaying]);

  const [volume, setVolume] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [viewCount, setViewCount] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const [videoSubtitles, setVideoSubtitles] = useState(null);
  const [activeSubtitle, setActiveSubtitle] = useState(null);
  const [currentCue, setCurrentCue] = useState(null);
  const [selectedSubtitleLang, setSelectedSubtitleLang] = useState(lang);
  const [selectedQuality, setSelectedQuality] = useState(defaultQuality);
  const [availableQualities, setAvailableQualites] = useState(null);
  const [videoProgress, setVideoProgress] = useState({ playedSeconds: 0, playedPerc: 0 });
  const [videoIsLandscape, setVideoIsLandscape] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  const [url, setUrl] = useState(null);
  const [imageSource, setImageSource] = useState(() => {
    let imageUrl;
    if (activeVideoData?.staticThumbnails?.ntsc) {
      imageUrl = activeVideoData?.staticThumbnails.ntsc;
    } else if (activeVideoData?.staticThumbnails?.nHD) {
      imageUrl = activeVideoData?.staticThumbnails.nHD;
    } else if (activeVideoData?.staticThumbnails?.qHD) {
      imageUrl = activeVideoData?.staticThumbnails.qHD;
    } else if (activeVideoData?.staticThumbnails?.HD) {
      imageUrl = activeVideoData?.staticThumbnails.HD;
    } else if (activeVideoData?.staticThumbnails?.FHD) {
      imageUrl = activeVideoData?.staticThumbnails.FHD;
    }

    if (imageUrl) {
      const tester = new Image();
      tester.onload = function () {
        const { width } = this;
        const { height } = this;
        handleVideoDimension({
          videoWidth: width,
          videoHeight: height,
        });
      };

      tester.src = imageUrl;
      return tester;
    }
  });

  const replayVideo = () => {
    seek(0);
  };
  const toggleVolume = () => {
    const newVolume = volume === 1 ? 0 : 1;
    setVolume(newVolume);
  };

  useEffect(() => {
    if (activeVideoData.videoLink?.qHD) {
      setUrl(activeVideoData.videoLink.qHD);
    } else if (activeVideoData.videoLink?.nHD) {
      setUrl(activeVideoData.videoLink.nHD);
    } else if (activeVideoData.videoLink?.ntsc) {
      setUrl(activeVideoData.videoLink.ntsc);
    }

    let imageUrl;
    if (activeVideoData?.staticThumbnails?.ntsc) {
      imageUrl = activeVideoData?.staticThumbnails.ntsc;
    } else if (activeVideoData?.staticThumbnails?.nHD) {
      imageUrl = activeVideoData?.staticThumbnails.nHD;
    } else if (activeVideoData?.staticThumbnails?.qHD) {
      imageUrl = activeVideoData?.staticThumbnails.qHD;
    } else if (activeVideoData?.staticThumbnails?.HD) {
      imageUrl = activeVideoData?.staticThumbnails.HD;
    } else if (activeVideoData?.staticThumbnails?.FHD) {
      imageUrl = activeVideoData?.staticThumbnails.FHD;
    }

    if (imageUrl) {
      const tester = new Image();
      tester.onload = function () {
        const { width } = this;
        const { height } = this;
        handleVideoDimension({
          videoWidth: width,
          videoHeight: height,
        });
      };

      tester.src = imageUrl;
      setImageSource(tester);
    }
  }, [activeVideoData]);

  useEffect(() => {
    if (videoSubtitles) {
      if (selectedSubtitleLang) {
        const track = _.find(videoSubtitles, (t) => t.srcLang === selectedSubtitleLang);

        if (track) {
          setActiveSubtitle(track);
        } else if (videoSubtitles.length > 0) {
          const tmpSub = videoSubtitles[0];
          setActiveSubtitle(tmpSub);
          setSelectedSubtitleLang(tmpSub.srcLang);
        }
      } else {
        setActiveSubtitle(null);
      }
    }
  }, [selectedSubtitleLang, videoSubtitles]);

  useEffect(() => {
    setCurrentCue(null);
  }, [activeSubtitle]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  };
  useEffect(() => {
    const video = document.querySelector(`#video-player-${index}`);
    if (isFullScreen) {
      document.addEventListener('keydown', escFunction);
      handleVideoDimension(video, true);
    } else {
      document.removeEventListener('keydown', escFunction);
      handleVideoDimension(video);
    }
  }, [isFullScreen]);

  const handleVideoDimension = (video, fullscreen = false) => {
    if (!video) return;
    const isLandscape = video.videoWidth > video.videoHeight;
    setVideoIsLandscape(isLandscape);
    if (fullscreen) {
      const preferredHeight = window.innerHeight - 100;

      setVideoDimensions({
        width: video.videoWidth * (preferredHeight / video.videoHeight),
        height: preferredHeight,
      });
      return;
    }
    if (isLandscape) {
      const preferredWidth = videoWidth * 1.6;
      // const preferredWidth = videoWidth;
      setVideoDimensions({
        width: preferredWidth,
        height: video.videoHeight * (preferredWidth / video.videoWidth),
      });
    } else {
      const preferredWidth = videoWidth;
      setVideoDimensions({
        width: preferredWidth,
        height: video.videoHeight * (preferredWidth / video.videoWidth),
      });
    }
    setTimeout(() => {
      setIsVideoVisible(true);
    }, 500);
  };

  const seek = (secs) => {
    if (videoPlayerRef.current?.fastSeek) {
      videoPlayerRef.current.fastSeek(secs);
    } else {
      videoPlayerRef.current.currentTime = secs;
    }
  };

  const changeVideoProgress = (newProgressPerc) => {
    seek(newProgressPerc * videoPlayerRef.current.duration);
  };

  const onReady = (e) => {
    // if (!hasStartedPlaying && !isPlaying && e) {
    //   setIsPlaying(true);
    //   // videoPlayerRef.current.play();
    // }

    sendVideoStats({
      encodedOrgId: basicOrgData.encodedId,
      videoCardId: activeVideoData.id,
      sessionId,
      statsType: 'IMPRESSION',
      viewContext: props.viewContext,
      ...(widgetMatches?.length > 0 && { matches: widgetMatches }),
    });

    // handleVideoDimension(video, isFullScreen);
    initSubtitles();
  };

  const initSubtitles = async () => {
    Promise.all(
      _.map(activeVideoData.subtitles, async (sub) => {
        return fetch(sub.src).then((r) => {
          return r.text().then((d) => {
            const tree = parser.parse(d, 'metadata');
            if (tree?.cues) {
              return {
                ...sub,
                cues: tree.cues,
              };
            }
          });
        });
      }),
    ).then((subs) => {
      setVideoSubtitles(subs);
    });
  };

  const onVideoProgress = (progressData) => {
    const { playedSeconds, played, loadedSeconds, loaded } = progressData;
    setVideoProgress({ playedSeconds, playedPerc: played });
    if (activeSubtitle) {
      const cue = _.find(activeSubtitle?.cues, (c) => {
        return c.startTime <= playedSeconds && c.endTime > playedSeconds;
      });
      if (cue && !_.isEqual(cue?.text, currentCue?.text)) {
        setCurrentCue(cue);
      }
    } else {
      setCurrentCue(null);
    }
  };
  const handleOnTimeUpdate = (e) => {
    const video = e.target;
    if (!video) return;
    const { currentTime } = video;
    const { duration } = video;
    const progress = currentTime / duration;
    const progressData = {
      playedSeconds: currentTime,
      played: progress,
      loadedSeconds: duration,
      loaded: duration,
    };
    onVideoProgress(progressData);
  };

  const onVideoPlay = () => {
    if (!hasStartedPlaying) {
      setIsPlaying(true);
    }

    sendVideoStats({
      encodedOrgId: basicOrgData.encodedId,
      videoCardId: activeVideoData.id,
      sessionId,
      statsType: 'PLAY',
      viewContext: props.viewContext,
      ...(widgetMatches?.length > 0 && { matches: widgetMatches }),
    });
  };
  const onVideoPause = () => {
    setIsPlaying(false);

    sendVideoStats({
      encodedOrgId: basicOrgData.encodedId,
      videoCardId: activeVideoData.id,
      sessionId,
      statsType: 'PAUSE',
      viewContext: props.viewContext,
      ...(widgetMatches?.length > 0 && { matches: widgetMatches }),
    });
  };
  const onVideoEnded = () => {
    setIsPlaying(false);

    sendVideoStats({
      encodedOrgId: basicOrgData.encodedId,
      videoCardId: activeVideoData.id,
      sessionId,
      statsType: 'END',
      viewContext: props.viewContext,
      ...(widgetMatches?.length > 0 && { matches: widgetMatches }),
    });

    if (activeVideoData.interactionOption) {
      setShowLeadGen(true);
    }
  };
  const orientationClass =
    videoDimensions.width >= videoDimensions.height ? 'landscape' : 'portrait';
  const visibilityClass = isVideoVisible ? 'visible' : 'not-visible';

  // const tags = activeVideoData?.tags?.map((t) => {
  //   return (
  //     <span className="tag" key={t.id}>
  //       {t.name[lang]}
  //     </span>
  //   );
  // });

  useEffect(() => {
    if (!hasStartedPlaying) {
      if (isPlaying && isLoadingVideo) {
        setIsLoadingVideo(false);
      }
    }
  }, [isPlaying]);

  return (
    <div className="video-card-aws" style={{ width: `${videoDimensions.width}px`, height: '100%' }}>
      <div className="title">{activeVideoData.question[lang]}</div>
      <div className={`video-player-wrapper ${isFullScreen ? 'fullscreen' : ''}`}>
        <div>
          <VideoControls
            videoProgress={videoProgress}
            activeVideo={activeVideoData}
            viewCount={viewCount}
            isPlaying={isPlaying}
            setIsPlaying={(e) => {
              setIsPlaying(e);
              if (e) {
                videoPlayerRef.current.play();
              } else {
                videoPlayerRef.current.pause();
              }
            }}
            sessionId={sessionId}
            showControls
            availableSubtitles={activeVideoData?.subtitles?.map((sub) => ({
              value: sub.srcLang,
              label: sub.label,
            }))}
            toggleVolume={toggleVolume}
            volume={volume}
            orgData={basicOrgData}
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            subtitles={videoSubtitles}
            selectedSubtitleLang={selectedSubtitleLang}
            setSelectedSubtitleLang={setSelectedSubtitleLang}
            replayVideo={replayVideo}
            availableQualities={availableQualities}
            selectedQuality={selectedQuality}
            setSelectedQuality={setSelectedQuality}
            changeVideoProgress={changeVideoProgress}
          />
          <LazyLoad
            offset={index < 8 ? 0 : 800}
            width={videoDimensions.width}
            height={videoDimensions.height}
          >
            <>
              <video
                ref={videoPlayerRef}
                id={`video-player-${index}`}
                url={url}
                width={videoDimensions.width}
                height={videoDimensions.height}
                controls={false}
                src={url}
                onLoad={onReady}
                onProgress={onVideoProgress}
                onEnded={onVideoEnded}
                muted={volume === 0}
                onCanPlay={(e) => {
                  onReady(e);
                  if (isPlaying) {
                    videoPlayerRef.current.play();
                    setIsLoadingVideo(false);
                  } else {
                    setIsLoadingVideo(true);
                  }
                }}
                onPlay={onVideoPlay}
                onPause={onVideoPause}
                playsInline
                poster={imageSource?.src}
                onTimeUpdate={(e) => {
                  handleOnTimeUpdate(e);
                }}
              />
            </>
          </LazyLoad>
          <div className="actor">
            <div className="subtitle-wrapper">
              <span className="subtitle-cue">{currentCue?.text}</span>
            </div>
            <span className="actor-name">
              {activeVideoData?.recipient?.name ?? activeVideoData?.actorName ?? ''}
            </span>
            <span className="sep">|</span>
            <span className="actor-title">
              {activeVideoData?.recipient?.title ?? activeVideoData?.actorTitle[lang] ?? ''}
            </span>
          </div>
        </div>
      </div>
      {/* <div className="tags">{tags}</div> */}
    </div>
  );
}

export default VideoCard;
