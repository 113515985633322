import { gql } from '@apollo/client';
import { gqClient } from '../../../utils/graphQL';

export const updateAccount = async ({
  firstName,
  lastName,
  phoneNumber,
  email,
  orgName,
  domain,
  orgLang,
  token,
}) => {
  const mutation = gql`
    mutation UpdateAccountCreation(
      $firstName: String!
      $lastName: String!
      $phoneNumber: String!
      $email: String!
      $orgName: String!
      $domain: String!
      $orgLang: String!
      $token: String!
    ) {
      updateAccountCreation(
        input: {
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          email: $email
          orgName: $orgName
          domain: $domain
          orgLang: $orgLang
          token: $token
        }
      ) {
        status
        token
      }
    }
  `;

  const variables = {
    firstName,
    lastName,
    phoneNumber,
    email,
    orgName,
    domain,
    orgLang,
    token,
  };

  try {
    const response = await gqClient.mutate({ mutation, variables });
    if (response.errors) {
      return { error: response.errors[0].message };
    }

    const { status, token: newToken } = response.data.updateAccountCreation;

    return { status, token: newToken };
  } catch (error) {
    return { error };
  }
};

export const resendVerificationCode = async ({ token }) => {
  const mutation = gql`
    mutation ResendVerificationCode($token: String!) {
      resendVerificationCode(token: $token) {
        token
        status
      }
    }
  `;
  const variables = { token };

  try {
    const response = await gqClient.mutate({ mutation, variables });
    if (response.errors) {
      return { error: response.errors[0].message };
    }

    const { token: newToken, status } = response.data.resendVerificationCode;

    return { token: newToken, status };
  } catch (error) {
    return { error };
  }
};

export const verifyPhoneNumber = async ({ phoneNumberVerificationCode, token }) => {
  const mutation = gql`
    mutation VerifyPhoneNumberAccountCreation(
      $phoneNumberVerificationCode: String!
      $token: String!
    ) {
      verifyPhoneNumberAccountCreation(
        input: { phoneNumberVerificationCode: $phoneNumberVerificationCode, token: $token }
      ) {
        user {
          id
        }
        organization {
          id
          orgName
          orgNameSlug
        }
      }
    }
  `;

  const variables = {
    phoneNumberVerificationCode,
    token,
  };

  try {
    const response = await gqClient.mutate({ mutation, variables });
    if (response.errors) {
      return { error: response.errors[0].message };
    }

    const { user, organization } = response.data.verifyPhoneNumberAccountCreation;

    return { user, organization };
  } catch (error) {
    return { error };
  }
};

export const initiateAccountCreation = async ({
  firstName,
  lastName,
  phoneNumber,
  email,
  orgName,
  domain,
  orgLang,
  reCAPTCHAToken,
}) => {
  const mutation = gql`
    mutation InitiateAccountCreation(
      $firstName: String!
      $lastName: String!
      $phoneNumber: String!
      $email: String!
      $orgName: String!
      $domain: String!
      $orgLang: String!
      $reCAPTCHAToken: String!
    ) {
      initiateAccountCreation(
        reCAPTCHAToken: $reCAPTCHAToken
        input: {
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          email: $email
          orgName: $orgName
          domain: $domain
          orgLang: $orgLang
        }
      ) {
        token
        status
      }
    }
  `;

  const variables = {
    firstName,
    lastName,
    phoneNumber,
    email,
    orgName,
    domain,
    orgLang,
    reCAPTCHAToken,
  };

  try {
    const response = await gqClient.mutate({ mutation, variables });
    if (response.errors) {
      return { error: response.errors[0].message };
    }

    const { token, status } = response.data.initiateAccountCreation;

    return { token, status };
  } catch (error) {
    return { error };
  }
};

export const verifyPhoneNumberAccountCreation = ({ code, token }) => {
  const mutation = gql`
    mutation VerifyPhoneNumberAccountCreation($code: String!, $token: String!) {
      verifyPhoneNumberAccountCreation(input: { code: $code, token: $token }) {
        statusMessage
      }
    }
  `;

  try {
    const variables = { code, token };
    return gqClient.mutate({ mutation, variables });
  } catch (error) {
    return { error };
  }
};
