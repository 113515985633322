import PropTypes from 'prop-types';

function UploadOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 8.25V10.5H1.5V8.25H0V10.5C0 11.325 0.675 12 1.5 12H10.5C11.325 12 12 11.325 12 10.5V8.25H10.5ZM2.25 3.75L3.3075 4.8075L5.25 2.8725V9H6.75V2.8725L8.6925 4.8075L9.75 3.75L6 0L2.25 3.75Z"
        fill={fill}
      />
    </svg>
  );
}

export default UploadOutlinedIcon;

UploadOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
