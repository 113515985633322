import React from 'react';
import { TextField, Grid, Typography, Select, MenuItem } from '@mui/material';
import { uniqueId } from 'lodash';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import translationsSignup from '../../../utils/translationsSignup';
import { isValidHttpUrl, isValidPhoneNumberInput } from '../../../utils/utils';

const Languages = [
  { code: 'en', name: 'English', icon: '🇬🇧' },
  { code: 'sv', name: 'Svenska', icon: '🇸🇪' },
].sort((a, b) => (a.name > b.name ? 1 : -1));

function StepOneSignup({ formData, handleChange, activeStep, steps, isDisabled }) {
  const lang = useLanguage();
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    orgName,
    domain,
    orgLang,
    phoneNumberVerificationCode,
  } = formData;

  const handleOnChange = (e) => {
    const { value, name } = e.target;

    if (name === 'firstName') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: validator.isAscii(value),
        },
      });
    } else if (name === 'lastName') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: validator.isAscii(value),
        },
      });
    } else if (name === 'phoneNumber') {
      handleChange({
        target: {
          name,
          value,
          valid: isValidPhoneNumberInput({ phoneNumber: value, lang }),
        },
      });
    } else if (name === 'email') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: validator.isEmail(value),
        },
      });
    } else if (name === 'orgName') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: validator.isAscii(value),
        },
      });
    } else if (name === 'domain') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: validator.isURL(value) && isValidHttpUrl(value, true),
        },
      });
    } else if (name === 'orgLang') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: true,
        },
      });
    } else if (name === 'approveConditions') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: value,
        },
      });
    } else if (name === 'phoneNumberVerificationCode') {
      handleChange({
        ...e,
        target: {
          ...e.target,
          name,
          value,
          valid: value.length === 6 && validator.isNumeric(value),
        },
      });
    }
  };

  return (
    <>
      {firstName.step === activeStep && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#121212',
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  lineHeight: '24px',
                  letterSpacing: 0.15,
                  wordWrap: 'break-word',
                }}
              >
                {translationsSignup.firstName[lang]}
              </Typography>
              <TextField
                fullWidth
                name="firstName"
                variant="outlined"
                value={firstName.value}
                onChange={handleOnChange}
                required
                autoComplete="given-name"
                minLength={1}
                error={
                  (firstName.value?.length > 0 || firstName.hasOnBlur) &&
                  validator.isAscii(firstName.value) === false
                }
                onBlur={() => handleChange({ target: { name: 'firstName', hasOnBlur: true } })}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#121212',
                  fontSize: '16px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  lineHeight: '24px',
                  letterSpacing: 0.15,
                  wordWrap: 'break-word',
                }}
              >
                {translationsSignup.lastName[lang]}
              </Typography>
              <TextField
                fullWidth
                name="lastName"
                variant="outlined"
                value={lastName.value}
                onChange={handleOnChange}
                required
                autoComplete="family-name"
                minLength={1}
                error={
                  (lastName.value?.length > 0 || lastName.hasOnBlur) &&
                  validator.isAscii(lastName.value) === false
                }
                onBlur={() => handleChange({ target: { name: 'lastName', hasOnBlur: true } })}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {phoneNumber.step === activeStep && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#121212',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.phoneNumber[lang]}
          </Typography>
          <PhoneInput
            enableSearch
            name="phoneNumber"
            id="phoneNumber"
            value={phoneNumber.value}
            priority={{ se: 1 }}
            preferredCountries={['se']}
            country="se"
            type="tel"
            inputProps={{
              name: 'phoneNumber',
            }}
            autoFormat
            onChange={(numberInput) =>
              handleOnChange({ target: { name: 'phoneNumber', value: numberInput } })
            }
            onBlur={() => handleChange({ target: { name: 'phoneNumber', hasOnBlur: true } })}
            autoComplete="tel"
            isValid={(value) => {
              return (
                (!phoneNumber.hasOnBlur && phoneNumber.value?.length <= 3) ||
                ((!phoneNumber.hasOnBlur || phoneNumber.value?.length > 3) &&
                  isValidPhoneNumberInput({ phoneNumber: value, lang }))
              );
            }}
            disabled={isDisabled}
          />
        </Grid>
      )}
      {email.step === activeStep && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#121212',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.email[lang]}
          </Typography>
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            value={email.value}
            onChange={handleOnChange}
            required
            autoComplete="email"
            minLength={3}
            error={
              (email.hasOnBlur || email.value?.length > 0) &&
              validator.isEmail(email.value) === false
            }
            onBlur={() => handleChange({ target: { name: 'email', hasOnBlur: true } })}
            disabled={isDisabled}
          />
        </Grid>
      )}

      {orgName.step === activeStep && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#121212',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.orgName[lang]}
          </Typography>
          <TextField
            fullWidth
            name="orgName"
            variant="outlined"
            value={orgName.value}
            onChange={handleOnChange}
            required
            autoComplete="organization"
            minLength={3}
            error={
              (orgName.hasOnBlur || orgName.value?.length > 0) &&
              validator.isAscii(orgName.value) === false
            }
            onBlur={() => handleChange({ target: { name: 'orgName', hasOnBlur: true } })}
            disabled={isDisabled}
          />
        </Grid>
      )}
      {domain.step === activeStep && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#121212',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.domainTitle[lang]}
          </Typography>
          <TextField
            fullWidth
            name="domain"
            variant="outlined"
            value={domain.value}
            onChange={handleOnChange}
            required
            type="url"
            autoComplete="url"
            minLength={3}
            helperText={
              (domain.hasOnBlur || domain.value?.length > 0) &&
              (validator.isURL(domain.value) === false ||
                isValidHttpUrl(domain.value, true) === false) &&
              translationsSignup.domainHelperText[lang]
            }
            error={
              (domain.hasOnBlur || domain.value?.length > 0) &&
              (validator.isURL(domain.value) === false ||
                isValidHttpUrl(domain.value, true) === false)
            }
            placeholder={translationsSignup.domain[lang]}
            onBlur={() => handleChange({ target: { name: 'domain', hasOnBlur: true } })}
            disabled={isDisabled}
          />
        </Grid>
      )}
      {orgLang.step === activeStep && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#121212',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.systemLanguage[lang]}
          </Typography>
          <Select
            value={orgLang.value}
            onChange={handleOnChange}
            fullWidth
            name="orgLang"
            variant="outlined"
            required
            autoComplete="language"
            placeholder={translationsSignup.orgLang[lang]}
            onBlur={() => handleChange({ target: { name: 'orgLang', hasOnBlur: true } })}
            size="small"
            disabled={isDisabled}
          >
            {Languages.map((langItem) => (
              <MenuItem key={uniqueId(langItem.code)} value={langItem.code} disableRipple>
                {langItem.icon} {langItem.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      {activeStep === steps.length && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#121212',
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: 24 / 16,
              letterSpacing: 0.15,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.verificationPhoneNumber[lang]}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#121212',
              fontSize: '14px',
              fontFamily: 'Inter',
              fontWeight: '400',
              lineHeight: 20 / 14,
              wordWrap: 'break-word',
            }}
          >
            {translationsSignup.codeSentTo[lang](phoneNumber.value)}
          </Typography>
          <TextField
            type="text"
            name="phoneNumberVerificationCode"
            placeholder="______"
            autoComplete="one-time-code"
            value={phoneNumberVerificationCode.value}
            inputMode="numeric"
            pattern="[0-9]*"
            required
            inputProps={{
              min: 0,
              style: { textAlign: 'center', letterSpacing: '1rem', fontSize: '2.5rem' },
              maxLength: 6,
            }}
            onChange={handleOnChange}
            autoFocus
            onBlur={() =>
              handleChange({ target: { name: 'phoneNumberVerificationCode', hasOnBlur: true } })
            }
            error={
              (phoneNumberVerificationCode.hasOnBlur ||
                phoneNumberVerificationCode.value?.length > 0) &&
              (phoneNumberVerificationCode.value?.length !== 6 ||
                !validator.isNumeric(phoneNumberVerificationCode.value))
            }
            disabled={isDisabled}
          />
        </Grid>
      )}
    </>
  );
}

export const formDataShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  error: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  hasOnBlur: PropTypes.bool.isRequired,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

StepOneSignup.propTypes = {
  formData: PropTypes.shape({
    firstName: formDataShape.isRequired,
    lastName: formDataShape.isRequired,
    phoneNumber: formDataShape.isRequired,
    email: formDataShape.isRequired,
    orgName: formDataShape.isRequired,
    domain: formDataShape.isRequired,
    orgLang: formDataShape.isRequired,
    phoneNumberVerificationCode: formDataShape.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default StepOneSignup;
