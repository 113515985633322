import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
// import '../../style/widget.scss';

import { gql } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { gqClient } from '../../utils/graphQL';

import {
  IconPlay,
  IconSettings,
  IconViews,
  IconMuted,
  IconUnMuted,
  IconCC,
  IconFullScreen,
  IconExitFullScreen,
  IconBack,
  IconForward,
  IconPause,
} from '../common/icons';

const sendVideoStats = ({ encodedOrgId, videoCardId, sessionId, statsType, viewContext }) => {
  const url = window.location.href;

  return gqClient.mutate({
    mutation: gql`
        mutation {
          createVideoStatsEvent(input: {
                                        encodedOrgId: "${encodedOrgId}",
                                        sessionId: "${sessionId}",
                                        videoCardId: ${videoCardId},
                                        context: "${viewContext}",
                                        statsType: "${statsType}"
                                        url: "${url}"
                                        }){
            statusMessage
          }
        }
    `,
  });
};

export default function VideoControls({
  videoProgress,
  activeVideo,
  viewCount,
  subtitles,
  selectedSubtitleLang,
  setSelectedSubtitleLang,
  toggleVolume,
  volume,
  sessionId,
  orgData,
  isPlaying,
  setIsPlaying,
  isFullScreen,
  setIsFullScreen,
  showControls,
  replayVideo,
  availableQualities,
  selectedQuality,
  setSelectedQuality,
  changeVideoProgress,
  availableSubtitles,
  allowFullscreen = true,
}) {
  const [fadeInControls, setFadeInControls] = useState(false);
  useEffect(() => {
    if (showControls) {
      setFadeInControls(true);
      setTimeout(() => setFadeInControls(false), 2000);
    }
  }, [showControls]);

  const togglePlaying = () => {
    if (isPlaying) {
      setIsPlaying(false);

      if (sessionId) {
        sendVideoStats({
          encodedOrgId: orgData.encodedId,
          videoCardId: activeVideo.id,
          sessionId,
          statsType: 'CLICK_PAUSE',
          viewContext: props.viewContext,
        });
      }
    } else {
      setIsPlaying(true);
      if (sessionId) {
        sendVideoStats({
          encodedOrgId: orgData.encodedId,
          videoCardId: activeVideo.id,
          sessionId,
          statsType: 'CLICK_PLAY',
          viewContext: props.viewContext,
        });
      }
    }
  };
  /* const replay = () => {
    if (activeVideo.id && sessionId && orgData.encodedId) {
      sendVideoStats(orgData.encodedId, activeVideo.id, sessionId, 'CLICK_REWIND',window.location.href);
    }
    replayVideo();
  }; */
  const changeProgress = (event) => {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const width = event.target.offsetWidth;
    const percentage = x / width;
    changeVideoProgress(percentage);
  };
  const toggleFullScreen = () => {
    if (isFullScreen) {
      if (sessionId) {
        sendVideoStats({
          encodedOrgId: orgData.encodedId,
          videoCardId: activeVideo.id,
          sessionId,
          statsType: 'CLICK_EXIT_FULLSCREEN',
          viewContext: props.viewContext,
        });
      }
      if (isPlaying) {
        setIsPlaying(false);
      }
    } else if (sessionId) {
      sendVideoStats({
        encodedOrgId: orgData.encodedId,
        videoCardId: activeVideo.id,
        sessionId,
        statsType: 'CLICK_FULLSCREEN',
        viewContext: props.viewContext,
      });
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className="player-controls">
      <div className="player-controls-top">
        {isFullScreen && (
          <button onClick={toggleFullScreen} className="esc-btn btn-ghost">
            Close <span>[ESC]</span>
          </button>
        )}
        <div className="progress-bar-wrapper" onClick={(e) => changeProgress(e)}>
          <div className="progress-bar" style={{ width: `${videoProgress.playedPerc * 100}%` }} />
        </div>
        <div className="player-controls-top-buttons">
          <div />
          <div className="player-actions">
            <SubtitleControl
              showControls={showControls}
              subtitles={subtitles}
              selectedSubtitleLang={selectedSubtitleLang}
              setSelectedSubtitleLang={setSelectedSubtitleLang}
              availableSubtitles={availableSubtitles}
            />
            <QualityControl
              showControls={showControls}
              qualities={availableQualities}
              selectedQuality={selectedQuality}
              setSelectedQuality={setSelectedQuality}
            />
            {/* {showControls && (
            <Button className="btn-ghost" onClick={replay}>
              {IconReplay}
            </Button>
          )} */}
            {showControls && (
              <button
                className={`btn-ghost ${volume === 0 ? 'is-muted' : ''}`}
                onClick={toggleVolume}
              >
                {volume === 0 ? IconMuted : IconUnMuted}
              </button>
            )}
            {showControls && allowFullscreen && !isFullScreen && (
              <button className="btn-ghost" aria-haspopup onClick={toggleFullScreen}>
                {isFullScreen ? IconExitFullScreen : IconFullScreen}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="player-controls-mid">
        <button
          className={`play-btn ${isPlaying ? 'pause' : 'play'} ${fadeInControls && 'fade-in'}`}
          onClick={togglePlaying}
        >
          {isPlaying ? IconPause : IconPlay}
        </button>
      </div>
    </div>
  );
}

function QualityControl({ showControls, qualities, selectedQuality, setSelectedQuality }) {
  if (showControls && qualities) {
    const availableQualities = _.map(qualities, (q) => ({ value: q.value, label: q.label }));
    const initalSelectedIndex = _.findIndex(availableQualities, (s) => s.value === selectedQuality);
    return (
      <CustomSelect
        options={availableQualities}
        buttonIcon={IconSettings}
        initialSelected={initalSelectedIndex}
        onSelect={setSelectedQuality}
      />
    );
  }
  return null;
}

function SubtitleControl({
  showControls,
  subtitles,
  selectedSubtitleLang,
  setSelectedSubtitleLang,
  availableSubtitles,
}) {
  if (showControls && (subtitles || availableSubtitles?.length > 0)) {
    const availableSubs =
      availableSubtitles ?? _.map(subtitles, (s) => ({ value: s.srcLang, label: s.label }));

    if (!availableSubs?.find((sub) => sub.label === 'None')) {
      if (availableSubtitles) {
        availableSubtitles?.push({ label: 'None', value: null });
      }
    }

    const initalSelectedIndex = _.findIndex(
      availableSubtitles,
      (s) => s.value === selectedSubtitleLang,
    );
    return (
      <CustomSelect
        options={availableSubtitles}
        buttonIcon={IconCC}
        initialSelected={initalSelectedIndex}
        onSelect={setSelectedSubtitleLang}
      />
    );
  }
  return null;
}

function CustomSelect({ options, buttonIcon, onSelect, initialSelected }) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialSelected);

  const optionsListRef = useRef(null);
  useOnOutside(optionsListRef, () => setIsOptionsOpen(false));

  useEffect(() => {
    if (selectedOption >= 0) {
      onSelect(options[selectedOption]?.value);
    }
  }, [selectedOption]);

  if (!options || options.length === 0) return null;

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(selectedOption - 1 >= 0 ? selectedOption - 1 : options.length - 1);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(selectedOption == options.length - 1 ? 0 : selectedOption + 1);
        break;
      default:
        break;
    }
  };

  return (
    <div className="custom-select">
      <div className="custom-select-container">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={`btn-ghost ${isOptionsOpen ? 'expanded' : ''}`}
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
        >
          {buttonIcon || options[selectedOption].label}
        </button>
        <ul
          ref={optionsListRef}
          className={`options ${isOptionsOpen ? 'show' : ''}`}
          role="listbox"
          aria-activedescendant={options[selectedOption]?.value}
          tabIndex={-1}
          onKeyDown={handleListKeyDown}
        >
          {options.map((option, index) => (
            <li
              id={option.value}
              key={option.value}
              role="option"
              aria-selected={selectedOption === index}
              tabIndex={0}
              onKeyDown={handleKeyDown(index)}
              onClick={() => {
                setSelectedThenCloseDropdown(index);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function useOnOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // If click outside of element, run callback
        callback();
      }
    }
    if (isMobile) {
      // Bind the event listener
      document.addEventListener('touch', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      if (isMobile) {
        document.addEventListener('touch', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [ref]);
}
