import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { translations as translationsSettings } from '../../../../utils/translationsSettings';
import { translations } from '../../../../utils/translations';
import { StyleDialog } from '../../../common/SimpleStyledDialog/StyleDialog';
import AlertDialog from '../../../common/AlertDialog';
import { useQuestionsActions } from './Actions';

export default function QuestionDialog(props) {
  const lang = useLanguage();
  const questionsActions = useQuestionsActions();
  const { basicOrgData } = useOutletContext();
  const { open, onClose, onCreate, editQuestion, categories } = props;
  const [question, setQuestion] = useState(editQuestion?.question || '');
  const [questionValid, setQuestionValid] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(
    editQuestion?.categoryId && categories?.find((c) => editQuestion.categoryId === c.id)
      ? categories?.find((c) => editQuestion.categoryId === c.id)
      : categories?.find((c) => !c.id),
  );
  const [enableAutoRequest, setEnableAutoRequest] = useState(
    basicOrgData?.premiumFeatures?.autoRequests ? editQuestion?.enableAutoRequest || true : false,
  );

  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);

  const hasChanges = () => {
    const questionTrimmed = question?.trim();
    if (editQuestion) {
      return (
        (editQuestion?.question
          ? questionTrimmed !== editQuestion.question
          : questionTrimmed !== '') ||
        (editQuestion.categoryId && categories?.find((c) => editQuestion.categoryId === c.id)
          ? selectedCategory?.id !== editQuestion.categoryId
          : selectedCategory?.id)
      );
    }
    return selectedCategory?.id || questionTrimmed !== '';
  };

  const handleClose = () => {
    if (hasChanges()) {
      setSimpleAlertDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategory(value);
  };

  const handleDelete = () => {
    setSimpleAlertDialogOpenDelete(false);
    questionsActions
      .deleteQuestion({
        id: editQuestion.id,
        orgId: basicOrgData.id,
        encodedOrgId: basicOrgData.encodedId,
      })
      .then((response) => {
        onClose(response.data.deleteOrgQuestion);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleCreate = () => {
    if (question !== '') {
      questionsActions
        .createQuestion({
          encodedOrgId: basicOrgData.encodedId,
          question: question?.trim(),
          orgId: basicOrgData.id,
          enableAutoRequest,
          categoryId: selectedCategory?.id || null,
        })
        .then((response) => {
          onCreate(response.data.createOrgQuestion, selectedCategory?.id || null);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const handleUpdateQuestion = () => {
    if (editQuestion) {
      questionsActions
        .updateQuestion({
          encodedOrgId: basicOrgData.encodedId,
          question: question?.trim(),
          orgId: basicOrgData.id,
          enableAutoRequest,
          categoryId: selectedCategory?.id || null,
          id: editQuestion.id,
          questionCategoryId: selectedCategory?.id,
        })
        .then((response) => {
          onClose(response.data.updateOrgQuestion);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  let dialogTitle = translationsSettings.question.addQuestion[lang];

  if (editQuestion) {
    dialogTitle = translationsSettings.question.editQuestion[lang];
  }

  const validateQuestion = (question, callbackName) => {
    eval(`${callbackName}`)(question);
    const isValid = question?.length > 0 && question?.length <= 65;
    if (!isValid) {
      eval(`${callbackName}Valid(false)`);
    } else {
      eval(`${callbackName}Valid(true)`);
    }
  };
  return (
    <>
      <AlertDialog
        open={simpleAlertDialogOpen}
        title={translationsSettings.closeBeforeSave[lang]}
        description={translationsSettings.areYouSureClose[lang]}
        confirmBtnText={translationsSettings.confirm[lang]}
        cancelBtnText={translationsSettings.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogOpen(false);
          onClose();
        }}
        onCancel={() => {
          setSimpleAlertDialogOpen(false);
        }}
      />
      <AlertDialog
        open={simpleAlertDialogOpenDelete}
        title={translationsSettings.question.deleteTitle[lang]}
        description={translationsSettings.question.deleteDescription[lang]}
        confirmBtnText={translationsSettings.question.deleteConfirmBtnText[lang]}
        cancelBtnText={translationsSettings.question.deleteCancelBtnText[lang]}
        onConfirm={() => {
          handleDelete();

          setSimpleAlertDialogOpenDelete(false);
        }}
        onCancel={() => {
          setSimpleAlertDialogOpenDelete(false);
        }}
      />
      <StyleDialog
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            borderRadius: '20px',
            '& .MuiPaper-rounded': {
              borderRadius: '8px',
            },
          },
          '& .MuiDialogContent-root': {
            minWidth: '600px',
            paddingTop: '00px',
            paddingRight: '40px',
            paddingBottom: '0px',
            paddingLeft: '30px',
            '& .section': {
              paddingTop: '10px',
              paddingRight: '0px',
              paddingBottom: '15px',
              paddingLeft: '0px',
            },
          },
          '& .MuiPaper-root .dialog-footer': {
            textAlign: 'center',
            flex: 'none',
            padding: '8px 16px',
            borderTop: '1px solid #ededee',
            display: 'flex',
            justifyContent: 'flex-end',
            boxSizing: 'border-box',
            color: '#000',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
        }}
      >
        <DialogTitle>
          {dialogTitle}
          <IconButton
            aria-label={translationsSettings.closeAriaLabel[lang]}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={(e) => {
              handleClose();
            }}
          >
            <i className="icon">close</i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <fieldset className="section">
            <label htmlFor="question" className={`${questionValid ? '' : 'error'}`}>
              <Typography variant="titleMedium" className="required-field">
                {translationsSettings.question.newQuestionLabel[lang]}
              </Typography>
              <Typography variant="labelSmall" className="error-msg" style={{ color: '#f14649' }}>
                {questionValid ? null : translations.errors.TEXT_TOO_LONG[lang]}
              </Typography>
              <input
                name="question"
                id="question"
                type="text"
                value={question}
                onChange={(e) => validateQuestion(e.target.value, 'setQuestion')}
                required
              />
            </label>
            <label htmlFor="category">
              <Typography variant="titleMedium" className="required-field">
                {translationsSettings.question.newQuestionCategoryLabel[lang]}
              </Typography>
              <Select
                labelId="category"
                id="category"
                value={selectedCategory}
                onChange={handleChange}
                renderValue={(selected) => {
                  return selected?.title || '';
                }}
                style={{ width: '100%' }}
              >
                {categories?.map((category) => (
                  <MenuItem key={_.uniqueId(category.id)} value={category}>
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
            </label>
            {!basicOrgData?.premiumFeatures?.autoRequests && (
              <Typography variant="titleMedium" className="premium-feature-info">
                {translationsSettings.premiumFeature[lang]}
              </Typography>
            )}
            <label htmlFor="enableAutoRequest" className="checkbox-label">
              <input
                type="checkbox"
                name="enableAutoRequest"
                id="enableAutoRequest"
                disabled={!basicOrgData?.premiumFeatures?.autoRequests}
                checked={enableAutoRequest}
                onChange={() => {
                  setEnableAutoRequest(!enableAutoRequest);
                }}
              />
              <Typography variant="titleMedium">
                {translationsSettings.question.dataGrid.header.automationActivated[lang]}
              </Typography>
            </label>
          </fieldset>
        </DialogContent>
        <div className="dialog-footer">
          <DialogActions
            sx={{
              padding: '0px',
            }}
          >
            {editQuestion ? (
              <>
                <Button
                  onClick={() => {
                    setSimpleAlertDialogOpenDelete(true);
                  }}
                >
                  <img src="/images/icons/bin-icon.svg" alt="" />
                  {translationsSettings.question.delete[lang]}
                </Button>
                <Button variant="contained" onClick={handleUpdateQuestion}>
                  {translationsSettings.question.save[lang]}
                </Button>
              </>
            ) : (
              <>
                <Button variant="outlined" onClick={handleClose}>
                  {translationsSettings.cancel[lang]}
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleCreate}
                  autoFocus
                  disabled={question === '' || !questionValid}
                >
                  {translationsSettings.question.createQuestion[lang]}
                </Button>
              </>
            )}
          </DialogActions>
        </div>
      </StyleDialog>
    </>
  );
}
