import React, { useMemo } from 'react';
import {
  TableCell,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { translations } from './translations';
import { User, PermissionType } from './types';

export interface UserRowProps {
  user: User;
  onPermissionChange: (userId: User['id'], permission: PermissionType) => void;
  disableChange: boolean;
  lang: Language;
}

const UserRow = React.memo(({ user, onPermissionChange, disableChange, lang }: UserRowProps) => {
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    onPermissionChange(user.id, event.target.value as PermissionType);
  };

  const selectWidth = useMemo(() => {
    const longestPermission = Object.values(translations.permissionTypes)
      .map((perm) => perm[lang])
      .reduce((acc, curr) => (curr.length > acc.length ? curr : acc), '');

    const textWidth = longestPermission.length * 12;
    return `${textWidth}px`;
  }, [lang]);

  return (
    <TableRow data-testid={`user-row-${user.id}`}>
      <TableCell data-testid={`user-first-name-${user.id}`}>{user.firstName}</TableCell>
      <TableCell data-testid={`user-last-name-${user.id}`}>{user.lastName}</TableCell>
      <TableCell data-testid={`user-email-${user.id}`}>{user.email}</TableCell>
      <TableCell>
        <FormControl data-testid={`form-control-permission-${user.id}`}>
          <Select
            size="small"
            value={user.userPermissions?.[0]?.name ?? ''}
            onChange={handleSelectChange}
            disabled={disableChange}
            // aria-label={`${translations.ariaLabel[lang]} ${user.firstName} ${user.lastName}`}
            data-testid={`select-permission-${user.id}`}
            displayEmpty
            aria-invalid={disableChange ? 'true' : 'false'}
            sx={{ width: selectWidth }}
            inputProps={{
              'aria-label': `${translations.ariaLabel[lang]} ${user.firstName} ${user.lastName}`,
            }}
          >
            {Object.entries(translations.permissionTypes).map(([key, value]) => (
              <MenuItem key={key} value={key} data-testid={`option-${key}-${user.id}`}>
                {value[lang]}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="srOnly" component="span" id={`permission-help-${user.id}`}>
            {translations.userRow.helpText[lang]}
          </Typography>
        </FormControl>
      </TableCell>
    </TableRow>
  );
});

export default UserRow;
