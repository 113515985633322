import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Legend,
  LegendType,
} from 'recharts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useLanguage } from 'src/context/LanguageContext';
import ThemeContext from '../../../../utils/ThemeContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';

interface DataItem {
  timestamp: string;
  count: number;
}

interface TotalImpressionsCardProps {
  selectedDuration: string;
  impressionsData: DataItem[];
  playsData: DataItem[];
}

interface VisibleDatasets {
  impressions: boolean;
  plays: boolean;
}

function TotalImpressionsCard({
  selectedDuration,
  impressionsData,
  playsData,
}: TotalImpressionsCardProps) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [visibleDatasets, setVisibleDatasets] = useState<VisibleDatasets>({
    impressions: true,
    plays: true,
  });

  const { totalImpressions, maxImpressions } = useMemo(() => {
    const result = impressionsData.reduce(
      (acc, curr) => {
        return {
          total: acc.total + curr.count,
          max: Math.max(acc.max, curr.count),
        };
      },
      { total: 0, max: -Infinity },
    );

    return {
      totalImpressions: result.total,
      maxImpressions: result.max,
    };
  }, [impressionsData]);

  const { totalPlays, maxPlays } = useMemo(() => {
    const result = playsData.reduce(
      (acc, curr) => {
        return {
          total: acc.total + curr.count,
          max: Math.max(acc.max, curr.count),
        };
      },
      { total: 0, max: -Infinity },
    );

    return {
      totalPlays: result.total,
      maxPlays: result.max,
    };
  }, [playsData]);

  const maxDomain = useMemo(() => {
    return Math.max(
      visibleDatasets.impressions ? maxImpressions : 0,
      visibleDatasets.plays ? maxPlays : 0,
    );
  }, [maxImpressions, maxPlays, visibleDatasets.impressions, visibleDatasets.plays]);

  useEffect(() => {
    setLoading(true);
    setError('');

    const timer = setTimeout(() => {
      if (!impressionsData || impressionsData.length === 0) {
        setError(translationsAnalytics.totalImpressionsCard.error?.[lang]);
      }
      setLoading(false);
    }, 10000);

    if (impressionsData.length > 0) {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [impressionsData, selectedDuration, lang]);

  const handleLegendClick = (dataKey: keyof typeof visibleDatasets) => {
    setVisibleDatasets((prev) => {
      const isOnlyOneActive = Object.values(prev).filter(Boolean).length === 1;

      if (isOnlyOneActive && prev[dataKey]) {
        const newState = { ...prev };
        Object.keys(newState).forEach((key) => {
          newState[key as keyof typeof visibleDatasets] = key !== dataKey;
        });
        return newState;
      }

      return {
        ...prev,
        [dataKey]: !prev[dataKey],
      };
    });
  };

  const renderLegend = () => {
    const legendItems = [
      {
        id: 'impressions',
        type: 'rect' as LegendType,
        value: translationsAnalytics.totalImpressionsCard.impressions?.[lang],
        color: visibleDatasets.impressions ? theme.palette.secondary.main : theme.palette.grey[400],
        tooltip: translationsAnalytics.tooltips?.impressions?.[lang],
      },
      {
        id: 'plays',
        type: 'rect' as LegendType,
        value: translationsAnalytics.totalImpressionsCard.plays?.[lang],
        color: visibleDatasets.plays ? theme.palette.primary.main : theme.palette.grey[400],
        tooltip: translationsAnalytics.tooltips?.plays?.[lang],
      },
    ];

    return (
      <Legend
        verticalAlign="top"
        align="right"
        payload={legendItems.map((item) => ({
          id: item.id,
          type: item.type,
          value: (
            <Tooltip enterDelay={750} title={item.tooltip || ''} arrow>
              <span>{item.value}</span>
            </Tooltip>
          ),
          color: item.color,
        }))}
        onClick={(e) => {
          if (e && e.id) {
            handleLegendClick(e.id as keyof typeof visibleDatasets);
          }
        }}
        wrapperStyle={{
          cursor: 'pointer',
          marginTop: '-10px',
        }}
      />
    );
  };

  const titleText = () => {
    const totalImpressionsText = totalImpressions.toLocaleString();
    const totalPlaysText = totalPlays.toLocaleString();
    const playRate = totalImpressions > 0 ? ((totalPlays / totalImpressions) * 100).toFixed(0) : 0;

    const title = translationsAnalytics.totalImpressionsCard.title.summary[lang](
      totalImpressionsText,
      totalPlaysText,
      playRate,
    );
    return title;
  };

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: '420px',
        p: 3,
        borderRadius: '15px',
        paddingLeft: '0px',
      }}
      style={{ position: 'relative' }}
      elevation={0}
    >
      <CardHeader
        title={titleText()}
        titleTypographyProps={{
          variant: 'titleLarge',
        }}
        sx={{
          textAlign: 'left',
          p: 0,
          m: 0,
          pl: 2,
          ml: 2,
        }}
      />
      <CardContent
        style={{ position: 'relative' }}
        sx={{
          width: '100%',
          height: '100%',
          p: 0,
          m: 0,
          ':last-child': {
            p: 0,
          },
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={impressionsData}>
            <XAxis
              type="category"
              dataKey="timestamp"
              axisLine={false}
              tickMargin={10}
              minTickGap={60}
              tick={{
                fontSize: 14,
              }}
              tickLine={false}
              tickFormatter={(tick) => {
                return tick.split(' ')[0];
              }}
            />
            <YAxis
              type="number"
              tickMargin={10}
              tick={{
                fontSize: 14,
              }}
              axisLine={false}
              tickLine={false}
              domain={maxDomain ? [0, maxDomain] : ['auto', 'auto']}
              padding={{ top: 10, bottom: 10 }}
            />
            <defs>
              <linearGradient id="impressionsCount" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={theme.palette.secondary.main} />
                <stop offset="85%" stopColor="rgba(145, 70, 255, 0)" />
              </linearGradient>
              <linearGradient id="playsCount" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={theme.palette.primary.main} />
                <stop offset="85%" stopColor="rgba(145, 70, 255, 0)" />
              </linearGradient>
            </defs>
            {visibleDatasets.impressions && (
              <Area
                type="monotone"
                dataKey="count"
                stroke={theme.palette.secondary.main}
                fill="url(#impressionsCount)"
                name={translationsAnalytics.totalImpressionsCard.impressions?.[lang]}
              />
            )}
            {visibleDatasets.plays && (
              <Area
                type="monotone"
                dataKey="count"
                data={playsData}
                stroke={theme.palette.primary.main}
                fill="url(#playsCount)"
                name={translationsAnalytics.totalImpressionsCard.plays?.[lang]}
              />
            )}
            <RechartsTooltip
              contentStyle={{
                background: theme.palette.background.paper,
                borderRadius: '10px',
                color: theme.palette.text.main,
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                border: '0.5px solid #D8D8D8',
              }}
              formatter={(value, name) => [value, name]}
              labelFormatter={(label) => label}
              labelStyle={{
                color: theme.palette.text.main,
              }}
              itemStyle={{
                color: theme.palette.text.main,
              }}
            />
            {renderLegend()}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
      {(loading || error) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            flexDirection: 'column',
          }}
        >
          {loading && <CircularProgress size={50} />}
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            {error}
          </Typography>
        </Box>
      )}
    </Card>
  );
}

export default TotalImpressionsCard;
