import Button from '@mui/material/Button';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';
import { statuses, actions } from '../../utils/utils';
import SplitButton from '../common/SplitButton/SplitButton';

import './back-office.scss';

function SettingsFooter({
  itemStatus,
  save,
  saveAndNext,
  deleteItem,
  hasError,
  sendReminder,
  sendTextMsgReminder,
  sendTextMsgAndEmailReminder,
  textMessageAllowed,
  actorPhoneNumberIsValid,
  handleDuplicateItemClick,
  disabled,
  disabledLive,
}) {
  const lang = useLanguage();

  switch (itemStatus) {
    case statuses.SUGGESTION: {
      const options = actorPhoneNumberIsValid
        ? [
            { label: translations.videoCard.sendSMSEmailToTeamMember[lang], type: 'sms-email' },
            {
              label: translations.videoCard.sendEmailToTeamMember[lang],
              type: 'email',
            },
          ]
        : [
            {
              label: translations.videoCard.sendEmailToTeamMember[lang],
              type: 'email',
            },
            { label: translations.videoCard.sendSMSEmailToTeamMember[lang], type: 'sms-email' },
          ];
      return (
        <div className="modal-footer">
          <div>
            <Button onClick={deleteItem}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>

            <Button onClick={handleDuplicateItemClick}>
              <img src="/images/icons/duplicate-video-icon.svg" alt="" />
              {translations.videoCard.duplicateItem[lang]}
            </Button>
          </div>
          <div>
            <Button color="primary" onClick={save} disableElevation>
              {translations.videoCard.saveDraft[lang]}
            </Button>
            {textMessageAllowed ? (
              <SplitButton
                variant="contained"
                color="primary"
                disableElevation
                options={options}
                menuPlacement="top-end"
                handleClick={(option) => {
                  if (option?.type === 'email') {
                    saveAndNext(actions.SEND_REQUEST);
                  }
                  if (option?.type === 'sms-email') {
                    saveAndNext(actions.SEND_SMS_EMAIL_REQUEST);
                  }
                }}
              />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => saveAndNext(actions.SEND_REQUEST)}
              >
                {translations.videoCard.sendToTeamMember[lang]}
              </Button>
            )}
          </div>
        </div>
      );
    }
    case statuses.PENDING: {
      const options = [
        { label: translations.videoCard.sendTextMessageEmailReminder[lang], type: 'sms-email' },

        {
          label: translations.videoCard.sendEmailReminder[lang],
          type: 'email',
          img: '/images/icons/alarm-icon.svg',
        },
        { label: translations.videoCard.sendTextMessageReminder[lang], type: 'sms' },
      ];

      return (
        <div className="modal-footer">
          <div>
            <Button onClick={deleteItem}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>

            <Button onClick={handleDuplicateItemClick}>
              <img src="/images/icons/duplicate-video-icon.svg" alt="" />
              {translations.videoCard.duplicateItem[lang]}
            </Button>
          </div>

          <div>
            {textMessageAllowed ? (
              <SplitButton
                variant="contained"
                color="primary"
                disableElevation
                options={options}
                menuPlacement="top-end"
                handleClick={(option) => {
                  if (option?.type === 'email') {
                    sendReminder();
                  }
                  if (option?.type === 'sms') {
                    sendTextMsgReminder();
                  }
                  if (option?.type === 'sms-email') {
                    sendTextMsgAndEmailReminder();
                  }
                }}
              />
            ) : (
              <Button variant="contained" color="primary" disableElevation onClick={sendReminder}>
                <img src="/images/icons/alarm-icon.svg" alt="" />
                {translations.videoCard.sendReminder[lang]}
              </Button>
            )}

            <Button variant="contained" color="primary" disableElevation onClick={save}>
              {translations.videoCard.save[lang]}
            </Button>
          </div>
        </div>
      );
    }
    case statuses.REVIEW: {
      let options = [];

      if (disabledLive) {
        options = [{ label: translations.videoCard.approve[lang], type: actions.APPROVE }];
      } else {
        options = [
          {
            label: translations.videoCard.approveAndPublish[lang],
            type: actions.PUBLISH,
            disabledLive,
          },
          { label: translations.videoCard.approve[lang], type: actions.APPROVE },
        ];
      }

      return (
        <div className="modal-footer">
          <div>
            <Button onClick={deleteItem}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>

            <Button onClick={handleDuplicateItemClick} disabled={disabled}>
              <img src="/images/icons/duplicate-video-icon.svg" alt="" />
              {translations.videoCard.duplicateItem[lang]}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={save}
              disabled={disabled}
            >
              {translations.videoCard.save[lang]}
            </Button>
            <SplitButton
              variant="contained"
              color="primary"
              disableElevation
              options={options}
              menuPlacement="top-end"
              disabled={hasError || disabled}
              handleClick={(option) => {
                if (option?.type === actions.APPROVE) {
                  saveAndNext(actions.APPROVE);
                }
                if (option?.type === actions.PUBLISH) {
                  saveAndNext(actions.PUBLISH);
                }
              }}
            />
          </div>
        </div>
      );
    }
    case statuses.APPROVED: {
      const options = [
        {
          label: translations.videoCard.approveAndPublish[lang],
          type: actions.PUBLISH,
          disabledLive,
        },
      ];
      return (
        <div className="modal-footer">
          <div>
            <Button onClick={deleteItem}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>

            <Button onClick={handleDuplicateItemClick}>
              <img src="/images/icons/duplicate-video-icon.svg" alt="" />
              {translations.videoCard.duplicateItem[lang]}
            </Button>
          </div>
          <div>
            <Button variant="contained" color="primary" disableElevation onClick={save}>
              {translations.videoCard.save[lang]}
            </Button>
            <SplitButton
              variant="contained"
              disableElevation
              options={options}
              menuPlacement="top-end"
              disabled={hasError || disabled}
              handleClick={(option) => {
                if (option?.type === actions.PUBLISH) {
                  saveAndNext(actions.PUBLISH);
                }
              }}
            />
          </div>
        </div>
      );
    }
    case statuses.LIVE: {
      return (
        <div className="modal-footer">
          <div>
            <Button onClick={deleteItem}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>
            <Button onClick={handleDuplicateItemClick}>
              <img src="/images/icons/duplicate-video-icon.svg" alt="" />
              {translations.videoCard.duplicateItem[lang]}
            </Button>
          </div>
          <div>
            <Button onClick={() => saveAndNext(actions.ARCHIVE)}>
              <img src="/images/icons/archive-icon.svg" alt="" />
              {translations.videoCard.archive[lang]}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              startIcon={<VideocamOffOutlinedIcon />}
              onClick={() => saveAndNext(actions.UNPUBLISH)}
            >
              {translations.videoCard.unpublish[lang]}
            </Button>
            <Button variant="contained" color="primary" disableElevation onClick={save}>
              {translations.videoCard.save[lang]}
            </Button>
          </div>
        </div>
      );
    }
    case statuses.ARCHIVED: {
      return (
        <div className="modal-footer">
          <div>
            <Button onClick={deleteItem}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translations.videoCard.delete[lang]}
            </Button>
            <Button onClick={handleDuplicateItemClick}>
              <img src="/images/icons/duplicate-video-icon.svg" alt="" />
              {translations.videoCard.duplicateItem[lang]}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              disableElevation
              onClick={() => saveAndNext(actions.UNARCHIVE)}
            >
              {translations.videoCard.unarchive[lang]}
            </Button>
            <Button variant="contained" color="primary" disableElevation onClick={save}>
              {translations.videoCard.save[lang]}
            </Button>
          </div>
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
}

export default SettingsFooter;
