import { MarkerType } from 'reactflow';

export function arrowEndMarker(width = 20, height = 20) {
  if (typeof width !== 'number') {
    throw new Error('width must be a number');
  }
  if (width <= 0) {
    throw new Error('width must be a positive number');
  }
  if (typeof height !== 'number') {
    throw new Error('height must be a number');
  }
  if (height <= 0) {
    throw new Error('height must be a positive number');
  }

  return {
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width,
      height,
    },
  };
}
