export const SubtitleTranslation = {
  actorName: {
    en: 'Name',
    sv: 'Namn',
  },
  actorTitle: {
    en: 'Title',
    sv: 'Titel',
  },
  cancel: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  confirmUpload: {
    en: 'Confirm Upload',
    sv: 'Bekräfta uppladdning',
  },
  deleteTitle: {
    en: 'Delete subtitle',
    sv: 'Ta bort undertext',
  },
  deleteDescription: {
    en: 'Are you sure you want to delete this subtitle?',
    sv: 'Är du säker på att du vill ta bort denna undertext?',
  },
  deleteConfirmBtnText: {
    en: 'Delete!',
    sv: 'Ta bort!',
  },
  deleteCancelBtnText: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  languageTitle: {
    en: 'Language',
    sv: 'Språk',
  },
  defaultLanguage: {
    en: 'Default',
    sv: 'Standard',
  },
  orgName: {
    en: 'Org Name',
    sv: 'Org Namn',
  },
  VideoCardID: {
    en: 'Video Card ID',
    sv: 'Video Card ID',
  },
  RequestedDate: {
    en: 'Requested Date',
    sv: 'Begärt datum',
  },
  VideoLink: {
    en: 'Video Link',
    sv: 'Video Länk',
  },
  download: {
    en: 'Download',
    sv: 'Ladda ner',
  },
  downloadVideo: {
    en: 'Download video',
    sv: 'Ladda ner video',
  },
  UploadSubtitle: {
    en: 'Upload Subtitle',
    sv: 'Ladda upp Undertext',
  },
  setDefault: {
    en: 'Set Default',
    sv: 'Sätt som standard',
  },
  subtitleTitle: {
    en: 'Subtitle',
    sv: 'Undertext',
  },
  DeleteRequest: {
    en: 'Delete Request',
    sv: 'Ta bort begäran',
  },
  happyScribeStatus: {
    en: 'Happy Scribe Status',
    sv: 'Happy Scribe Status',
  },
  happyScribeNoRef: {
    en: 'Happy Scribe ref not found',
    sv: 'Happy Scribe ref hittades inte',
  },
  happyScribeUpload: {
    en: 'Upload to Happy Scribe',
    sv: 'Ladda upp till Happy Scribe',
  },
  happyScribeEdit: {
    en: 'Edit on Happy Scribe',
    sv: 'Redigera i Happy Scribe',
  },
  happyScribeUploadText: {
    en: 'Select the main spoken langue in video to start automatic subtitle generation',
    sv: 'Välj det huvudsakliga talade språket i videon för att starta automatisk undertextgenerering',
  },
  TranslationordersList: {
    en: 'Translation orders List',
    sv: 'Översättningsbeställningar Lista',
  },
  videoTitle: {
    en: 'Video Title',
    sv: 'Videotitel',
  },
  downloadVimeo: {
    en: 'Download from Vimeo',
    sv: 'Ladda ner från Vimeo',
  },
  downloadAWS: {
    en: 'AWS Download ',
    sv: 'AWS Ladda ner ',
  },
  subtitleShouldBeDefault: {
    en: 'Set subtitle as default',
    sv: 'Ange undertext som standard',
  },
  Upload: {
    en: 'Upload',
    sv: 'Ladda upp',
  },
  Delete: {
    en: 'Delete',
    sv: 'Ta Bort',
  },
  completed: {
    en: ' Mark as Completed & Delete Request',
    sv: 'Markera som slutförd & Ta bort förfrågan',
  },
  Thanks: {
    en: 'Thanks',
    sv: 'Tack',
  },
  SelectLang: {
    en: 'Select Subtitle Language',
    sv: 'Välj språk på undertext',
  },
  FileUploaded: {
    en: 'File has been successfully uploaded',
    sv: 'Filen har laddats upp',
  },
  SelectSubtitle: {
    en: 'Select Subtitle File.',
    sv: 'Välj undertextfil.',
  },
  FileFormat: {
    en: 'File format must be in',
    sv: 'Filformatet måste vara i',
  },
  clickToSelect: {
    en: 'Click this area to select file',
    sv: 'Klicka för att välja fil',
  },
  wereExcited: {
    en: 'We are excited to see the results.',
    sv: 'Vi är spända inför att se resultatet.',
  },
  Select: {
    en: 'Select Language',
    sv: 'Välj språk',
    nl: 'Selecteer taal',
    da: 'Vælg sprog',
    fr: 'Sélectionnez la langue',
    de: 'Sprache auswählen',
    it: 'Seleziona la lingua',
    no: 'Velg språk',
    es: 'Seleccionar idioma',
    fi: 'Valitse kieli',
  },
  languageAcronym: {
    en: 'English',
    sv: 'Svenska',
    nl: 'Nederlands',
    da: 'Dansk',
    fr: 'Français',
    de: 'Deutsch',
    it: 'Italiano',
    no: 'Norsk',
    es: 'Español',
    fi: 'Suomi',
  },
  languageAcronyms: {
    en: {
      en: 'English',
      sv: 'Engelska',
      nl: 'Engels',
      da: 'Engelsk',
      fr: 'Anglais',
      de: 'Englisch',
      it: 'Inglese',
      no: 'Engelsk',
      es: 'Inglés',
      fi: 'Englanti',
    },
    sv: {
      en: 'Swedish',
      sv: 'Svenska',
      nl: 'Zweeds',
      da: 'Svensk',
      fr: 'Suédois',
      de: 'Schwedisch',
      it: 'Svedese',
      no: 'Svensk',
      es: 'Sueco',
      fi: 'Ruotsin',
    },
    nl: {
      en: 'Dutch',
      sv: 'Holländska',
      nl: 'Nederlands',
      da: 'Hollandsk',
      fr: 'Néerlandais',
      de: 'Niederländisch',
      it: 'Olandese',
      no: 'Nederlandsk',
      es: 'Holandés',
      fi: 'Hollanti',
    },
    da: {
      en: 'Danish',
      sv: 'Danska',
      nl: 'Deens',
      da: 'Dansk',
      fr: 'Danois',
      de: 'Dänisch',
      it: 'Danese',
      no: 'Dansk',
      es: 'Danés',
      fi: 'Tanskan',
    },
    fr: {
      en: 'French',
      sv: 'Franska',
      nl: 'Frans',
      da: 'Fransk',
      fr: 'Français',
      de: 'Französisch',
      it: 'Francese',
      no: 'Fransk',
      es: 'Francés',
      fi: 'Ranskan',
    },
    de: {
      en: 'German',
      sv: 'Tyska',
      nl: 'Duits',
      da: 'Tysk',
      fr: 'Allemand',
      de: 'Deutsch',
      it: 'Tedesco',
      no: 'Tysk',
      es: 'Alemán',
      fi: 'Saksan',
    },
    no: {
      en: 'Norwegian',
      sv: 'Norska',
      nl: 'Noors',
      da: 'Norsk',
      fr: 'Norvégien',
      de: 'Norwegisch',
      it: 'Norvegese',
      no: 'Norsk',
      es: 'Noruego',
      fi: 'Norjan',
    },
    it: {
      en: 'Italian',
      sv: 'Italienska',
      nl: 'Italiaans',
      da: 'Italiensk',
      fr: 'Italien',
      de: 'Italienisch',
      it: 'Italiano',
      no: 'Italiensk',
      es: 'Italiano',
      fi: 'Italian',
    },
    es: {
      en: 'Spanish',
      sv: 'Spanska',
      nl: 'Spaans',
      da: 'Spansk',
      fr: 'Espagnol',
      de: 'Spanisch',
      it: 'Spagnolo',
      no: 'Spansk',
      es: 'Español',
      fi: 'Espanja',
    },
    fi: {
      en: 'Finnish',
      sv: 'Finska',
      nl: 'Fins',
      da: 'Finsk',
      fr: 'Finlandais',
      de: 'Finnisch',
      it: 'Finlandese',
      no: 'Finsk',
      es: 'Finlandés',
      fi: 'Suomalainen',
    },
  },
  swedish: {
    en: 'Swedish',
    sv: 'Svenska',
    nl: 'Zweeds',
    da: 'Svensk',
    fr: 'Suédois',
    de: 'Schwedisch',
    it: 'Svedese',
    no: 'Svensk',
    es: 'Sueco',
    fi: 'Ruotsin',
  },
  english: {
    en: 'English',
    sv: 'Engelska',
    nl: 'Engels',
    da: 'Engelsk',
    fr: 'Anglais',
    de: 'Englisch',
    it: 'Inglese',
    no: 'Engelsk',
    es: 'Inglés',
    fi: 'Englanti',
  },
  dutch: {
    en: 'Dutch',
    sv: 'Holländska',
    nl: 'Nederlands',
    da: 'Hollandsk',
    fr: 'Néerlandais',
    de: 'Niederländisch',
    it: 'Olandese',
    no: 'Nederlandsk',
    es: 'Holandés',
    fi: 'Hollanti',
  },
  danish: {
    en: 'Danish',
    sv: 'Danska',
    nl: 'Deens',
    da: 'Dansk',
    fr: 'Danois',
    de: 'Dänisch',
    it: 'Danese',
    no: 'Dansk',
    es: 'Danés',
    fi: 'Tanskan',
  },
  french: {
    en: 'French',
    sv: 'Franska',
    nl: 'Frans',
    da: 'Fransk',
    fr: 'Français',
    de: 'Französisch',
    it: 'Francese',
    no: 'Fransk',
    es: 'Francés',
    fi: 'Ranskan',
  },
  german: {
    en: 'German',
    sv: 'Tyska',
    nl: 'Duits',
    da: 'Tysk',
    fr: 'Allemand',
    de: 'Deutsch',
    it: 'Tedesco',
    no: 'Tysk',
    es: 'Alemán',
    fi: 'Saksan',
  },
  italian: {
    en: 'Italian',
    sv: 'Italienska',
    nl: 'Italiaans',
    da: 'Italiensk',
    fr: 'Italien',
    de: 'Italienisch',
    it: 'Italiano',
    no: 'Italiensk',
    es: 'Italiano',
    fi: 'Italian',
  },
  norwegian: {
    en: 'Norwegian',
    sv: 'Norska',
    nl: 'Noors',
    da: 'Norsk',
    fr: 'Norvégien',
    de: 'Norwegisch',
    it: 'Norvegese',
    no: 'Norsk',
    es: 'Noruego',
    fi: 'Norjan',
  },
  spanish: {
    en: 'Spanish',
    sv: 'Spanska',
    nl: 'Spaans',
    da: 'Spansk',
    fr: 'Espagnol',
    de: 'Spanisch',
    it: 'Spagnolo',
    no: 'Spansk',
    es: 'Español',
    fi: 'Espanja',
  },
  finnish: {
    en: 'Finnish',
    sv: 'Finska',
    nl: 'Fins',
    da: 'Finsk',
    fr: 'Finlandais',
    de: 'Finnisch',
    it: 'Finlandese',
    no: 'Finsk',
    es: 'Finlandés',
    fi: 'Suomalainen',
  },
  Error: {
    en: 'please Refresh the Page and select a Subtitle Language first',
    sv: 'Uppdatera sidan och välj ett språk för undertext först',
  },
  info: {
    en: 'Video encodeID: ',
    sv: 'Video encodeID: ',
  },
  wrongFile: {
    en: 'The Subtitle File is not matched with the Video ID , you can proceed anyway! ',
    sv: 'Undertextfilen matchas inte med video-ID, du kan fortsätta ändå!',
  },
  noUploadedSubtitles: {
    en: 'There are no subtitles uploaded for this video',
    sv: 'Det finns inga undertexter i det här videon',
  },
  languageUploaded: {
    en: 'uploaded',
    sv: 'uppladdad',
  },
  languageReUpload: {
    en: 'reupload',
    sv: 'ladda upp igen',
  },
  englishUploaded: {
    en: 'English uploaded',
    sv: 'Engelsk uppladdad',
  },
  englishNotUploaded: {
    en: 'English NOT uploaded',
    sv: 'Engelsk INTE uppladdad',
  },
  swedishUploaded: {
    en: 'Swedish uploaded',
    sv: 'Svensk uppladdad',
  },
  swedishNotUploaded: {
    en: 'Swedish NOT uploaded',
    sv: 'Svensk INTE uppladdad',
  },
  FHD: {
    en: 'Full HD 1080p',
    sv: 'Full HD 1080p',
  },
  HD: {
    en: 'HD 720p',
    sv: 'HD 720p',
  },
  qHD: {
    en: 'qHD 540p',
    sv: 'qHD 540p',
  },
  nHD: {
    en: 'nHD 360p',
    sv: 'nHD 360p',
  },
  originalVideo: {
    en: 'Original Video',
    sv: 'Original Video',
  },
  subtitle: {
    en: {
      en: 'English',
      sv: 'Engelska',
    },
    sv: {
      en: 'Swedish',
      sv: 'Svenska',
    },
  },
};

export default SubtitleTranslation;
