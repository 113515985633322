import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import './App.scss';
import './fonts.scss';
import { BrowserRouter } from 'react-router-dom';
import { Routes, useNavigate } from 'react-router';
import { Theme } from '@mui/system';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth0, Auth0Provider, AppState } from '@auth0/auth0-react';

import { gqClient, useAuthenticatedApolloClients } from './utils/graphQL';
import {
  AdminApolloClientProvider,
  ApolloClient,
  BackOfficeApolloClientProvider,
  BackOfficeStatsApolloClientProvider,
  PublicApolloClientProvider,
} from './context/ApolloClientContext';
import { LocalStorageLanguageProvider } from './context/LanguageContext';
import ThemeContext from './utils/ThemeContext';
import { auth0BackendAudience, auth0ClientId, auth0Domain } from './utils/environment';
import createAppTheme from './components/common/MUIStyling/createAppTheme';
import useAuth0AccessToken from './auth/useAuth0AccessToken';

import appRoutes from './route/AppRoutes';

const LOCAL_STORAGE_LANGUAGE_KEY = 'lifeinside_language';

function AppProviders({
  adminClient,
  backOfficeClient,
  backOfficeStatsClient,
  publicClient,
  theme,
  children,
}: {
  adminClient: ApolloClient | null;
  backOfficeClient: ApolloClient | null;
  backOfficeStatsClient: ApolloClient | null;
  publicClient: ApolloClient;
  theme: Theme;
  children: React.ReactNode;
}) {
  return (
    <AdminApolloClientProvider client={adminClient}>
      <BackOfficeApolloClientProvider client={backOfficeClient}>
        <BackOfficeStatsApolloClientProvider client={backOfficeStatsClient}>
          <PublicApolloClientProvider client={publicClient}>
            <LocalStorageLanguageProvider localStorageKey={LOCAL_STORAGE_LANGUAGE_KEY}>
              <ThemeContext.Provider value={theme}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
              </ThemeContext.Provider>
            </LocalStorageLanguageProvider>
          </PublicApolloClientProvider>
        </BackOfficeStatsApolloClientProvider>
      </BackOfficeApolloClientProvider>
    </AdminApolloClientProvider>
  );
}

function App() {
  const { loginWithRedirect, logout, error: auth0Error } = useAuth0();
  const { accessToken, error: accessTokenError } = useAuth0AccessToken();
  const theme = useMemo(() => createAppTheme(), []);

  useEffect(() => {
    if (auth0Error != null) {
      // If Auth0 fails to determine the auth state of the current user we cannot
      // do much other than forcing a logout and hoping that fixes the error.

      // eslint-disable-next-line no-console
      console.error('auth0Error', auth0Error);
      logout();
    } else if (accessTokenError != null) {
      // If we have a "good" auth state, but fail to get access token, we are
      // in a pretty weird state. Possibly user did not approve our API..?
      // (Re)try login and hope that fixes error.

      // eslint-disable-next-line no-console
      console.error('accessTokenError', accessTokenError);
      loginWithRedirect();
    }
  }, [auth0Error, accessTokenError, logout, loginWithRedirect]);

  // Create authenticated ApolloClient instances.
  const { adminClient, backOfficeClient, backOfficeStatsClient } =
    useAuthenticatedApolloClients(accessToken);

  return (
    <AppProviders
      theme={theme}
      adminClient={adminClient}
      backOfficeClient={backOfficeClient}
      backOfficeStatsClient={backOfficeStatsClient}
      publicClient={gqClient}
    >
      <>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: { backgroundColor: theme.palette.background.default },
          }}
        />
        <Helmet>
          <meta charSet="utf-8" />

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
            rel="stylesheet"
          />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <title>Life Inside</title>
        </Helmet>
        <Routes>{appRoutes}</Routes>
      </>
    </AppProviders>
  );
}

function Auth0ProviderWithNavigate({ children }: { children: React.ReactElement }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    const returnTo = appState?.returnTo || window.location.pathname;
    navigate(returnTo, { replace: true });
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0-callback`,
        audience: auth0BackendAudience,
        scope: 'openid',
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}

function Auth0App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
}

export default Auth0App;
