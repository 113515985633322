import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import lightThemeOptions from './themeSettings';

const createAppTheme = () => {
  const baseTheme = createTheme(lightThemeOptions);

  return responsiveFontSizes(baseTheme, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    variants: [
      'displayLarge',
      'displayMedium',
      'displaySmall',
      'headlineLarge',
      'headlineLargeRecoleta',
      'headlineLargeBoldRecoleta',
      'headlineMedium',
      'headlineSmall',
      'headlineSmallBold',
      'headlineSmallBoldRecoleta',
      'titleLarge',
      'titleLargeRecoletaBold',
      'titleLargeRecoleta',
      'titleLargeBold',
      'titleMedium',
      'titleMediumRecoleta',
      'titleSmall',
      'labelLarge',
      'labelMedium',
      'labelMediumSemiBold',
      'labelMediumBold',
      'labelSmall',
      'bodyLarge',
      'bodyMedium',
      'bodyMediumBold',
      'bodySmall',
      'srOnly',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'button',
      'caption',
      'overline',
    ],
  });
};

export default createAppTheme;
