import PropTypes from 'prop-types';

function VideoFunnelOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1216_13957)">
        <path
          d="M14.1071 3.92077C14.1071 5.03953 13.2002 5.94647 12.0814 5.94647C10.9627 5.94647 10.0557 5.03953 10.0557 3.92077C10.0557 2.80201 10.9627 1.89508 12.0814 1.89508C13.2002 1.89508 14.1071 2.80201 14.1071 3.92077Z"
          stroke={fill}
          strokeWidth="1.79015"
        />
        <path
          d="M6.94648 10.3276C6.94648 11.4464 6.03954 12.3533 4.92078 12.3533C3.80201 12.3533 2.89508 11.4464 2.89508 10.3276C2.89508 9.20887 3.80201 8.30194 4.92078 8.30194C6.03954 8.30194 6.94648 9.20887 6.94648 10.3276Z"
          stroke={fill}
          strokeWidth="1.79015"
        />
        <path
          d="M21.362 10.3276C21.362 11.4464 20.4551 12.3533 19.3363 12.3533C18.2175 12.3533 17.3106 11.4464 17.3106 10.3276C17.3106 9.20887 18.2175 8.30194 19.3363 8.30194C20.4551 8.30194 21.362 9.20887 21.362 10.3276Z"
          stroke={fill}
          strokeWidth="1.79015"
        />
        <path
          d="M11.2334 6.65308H12.9293V11.4899L12.7967 11.2876C12.6655 11.0876 12.5192 10.8978 12.3591 10.72L12.1756 10.516L11.7516 10.092L11.2334 9.66807V6.65308Z"
          fill={fill}
        />
        <path
          d="M7.65283 9.47961H8.02972C10.7356 9.47961 12.9291 11.6731 12.9291 14.379H11.2331C11.2331 12.6098 9.79892 11.1755 8.02971 11.1755H7.69994L7.65283 9.47961Z"
          fill={fill}
        />
        <path
          d="M12.5522 10.8929L13.0233 10.516L13.4473 10.2334L13.9184 9.95071L14.0433 9.89519C14.2423 9.80674 14.4486 9.73571 14.6599 9.68289L14.9077 9.62094L15.0229 9.59214C15.3219 9.5174 15.6288 9.47961 15.937 9.47961H16.6036L16.5565 11.1755H16.1797H16.1728C15.9269 11.1755 15.6817 11.2025 15.4416 11.2558C15.1188 11.3275 14.8073 11.447 14.5192 11.609C14.3082 11.7277 14.1099 11.869 13.929 12.0298L13.7771 12.1648L13.4473 12.5417L12.9762 11.5995L12.5522 10.8929Z"
          fill={fill}
        />
        <path
          d="M11.2334 14.3789H12.9293V22.1048L12.4234 22.6389C12.2375 22.835 11.9252 22.835 11.7394 22.6389L11.2334 22.1048V14.3789Z"
          fill={fill}
        />
        <path
          d="M12.9292 19.7023L15.1904 17.2997C15.8613 16.9475 16.6157 17.6018 16.3618 18.3157L16.3211 18.4303L12.9292 22.1049V19.7023Z"
          fill={fill}
        />
        <path
          d="M12.9292 19.7023L15.1904 17.2997C15.8613 16.9475 16.6157 17.6018 16.3618 18.3157L16.3211 18.4303L12.9292 22.1049V19.7023Z"
          fill={fill}
        />
        <path
          d="M11.2334 19.7218L8.97215 17.3388C8.30079 16.9892 7.54928 17.6449 7.8047 18.3575L7.84153 18.4602L11.2334 22.1049V19.7218Z"
          fill={fill}
        />
        <path
          d="M11.2334 19.7218L8.97215 17.3388C8.30127 16.9894 7.54693 17.6384 7.80077 18.3465L7.84153 18.4602L11.2334 22.1049V19.7218Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1216_13957">
          <rect width="20.257" height="22" fill="transparent" transform="translate(2 1)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VideoFunnelOutlinedIcon;

VideoFunnelOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
