import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function EditTextInput({ text, setText, label, labelDescription, required, style }) {
  return (
    <Box style={{ minWidth: '500px', ...style?.box }}>
      <label htmlFor="textEdit">
        {label?.length > 0 && (
          <Tooltip title={labelDescription}>
            <Typography variant="titleMedium" className={required ? 'required-field' : ''}>
              {label}
            </Typography>
          </Tooltip>
        )}
        <input
          type="text"
          name="textEdit"
          id="textEdit"
          onChange={(e) => setText(e.target.value)}
          value={text}
          required={required}
          style={{ width: '100%', ...style?.input }}
        />
      </label>
    </Box>
  );
}

EditTextInput.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
};
