import Card from '@mui/material/Card';
import { styled } from '@mui/system';
import { fontFamily } from './SharedStylingSettings';

const StyledCard = styled(Card)(({ theme }) => ({
  fontFamily,
  fontSize: '14px',
  fontWeight: 400,
  textDecoration: 'none',
  textTransform: 'none',
  boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
  borderRadius: '16px',
  backgroundColor: 'rgb(242, 244, 249)',
  marginBottom: '30px',
  '& .MuiCardActions-root': {
    padding: '0px',
  },
}));

export default function SimpleCard(props) {
  return <StyledCard {...props} />;
}
