/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client/index';

export const WHITELISTED_DOMAIN_FRAGMENT = gql`
  fragment WhitelistedDomainDetails on WhitelistedDomain {
    domainUrl
    orgId
    id
  }
`;
