import React from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsDashboard } from '../../../utils/translationsDashboard';

const tutorials = (lang) => [
  {
    title: translationsDashboard.onboarding.getStarted[lang],
    imgSrc: '/images/onboarding/onboarding-how-to-get-started.jpg',
    link: 'https://www.canva.com/design/DAFmXHTgETA/n3TWw7vPvScIkVoagJCo7w/view?utm_content=DAFmXHTgETA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#1',
  },
  {
    title: translationsDashboard.onboarding.videoFunnel[lang],
    imgSrc: '/images/onboarding/onboarding-video-funnel.jpg',
    link: 'https://www.canva.com/design/DAFhNoQBsnk/1HJQE3c0XNBioTUJQZKopQ/view?utm_content=DAFhNoQBsnk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#1',
  },
  {
    title: translationsDashboard.onboarding.endScreens[lang],
    imgSrc: '/images/onboarding/onboarding-end-screens.jpg',
    link: 'https://www.canva.com/design/DAFlm9TTU3s/MMSgkY8z107CDny5E1qXQA/view?utm_content=DAFlm9TTU3s&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink',
  },
];

function TutorialCards() {
  const lang = useLanguage();

  return (
    <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
      <Typography variant="titleMedium" gutterBottom>
        {translationsDashboard.onboarding.watchOurVideoTutorials[lang]}
      </Typography>
      <Grid container spacing={2} sx={{ pt: 1 }}>
        {tutorials(lang).map((tutorial) => (
          <Grid item xs={12} sm={4} key={tutorial.title}>
            <Paper
              elevation={0}
              sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <a
                href={tutorial.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${translationsDashboard.onboarding.learnMoreAbout[lang]} ${tutorial.title}`}
              >
                <Box
                  component="img"
                  sx={{
                    width: 192,
                    height: 108,
                    borderRadius: 1,
                    mb: 1,
                  }}
                  src={tutorial.imgSrc}
                  alt={tutorial.title}
                />
                <Typography variant="srOnly">{`${translationsDashboard.onboarding.learnMoreAbout[lang]} ${tutorial.title}`}</Typography>
              </a>
              <Typography variant="bodyMedium">{tutorial.title}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default TutorialCards;
