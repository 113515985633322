import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { getStaticThumbnail } from '../../../utils/video-card/utils';
import ThemeContext from '../../../utils/ThemeContext';
import StaticThumbnail from './StaticThumbnail';
import { VideoCard } from './types';

const styledOuterBox = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  m: 0,
  p: 0,
  width: '100%',
  height: '100%',
  borderRadius: 2,
};

const styledInnerBox = {
  position: 'absolute',
  borderRadius: 2,
  width: '100%',
  height: '100%',
};

interface AdditionalVideosThumbnailProps {
  additionalVideosCount: number;
  videoCollectorId: number;
  videoCard: VideoCard;
}

function AdditionalVideosThumbnail({
  additionalVideosCount,
  videoCollectorId,
  videoCard,
}: AdditionalVideosThumbnailProps) {
  const theme = useContext(ThemeContext);

  if (additionalVideosCount < 1) {
    return (
      <Grid
        item
        xs={1}
        key={`more-videos-${videoCollectorId}`}
        role="gridcell"
        data-testid="additional-videos-thumbnail"
      >
        <StaticThumbnail src={getStaticThumbnail(videoCard)} />
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={1}
      key={`more-videos-${videoCollectorId}`}
      role="gridcell"
      data-testid="additional-videos-thumbnail"
    >
      <Box sx={styledOuterBox}>
        <StaticThumbnail src={getStaticThumbnail(videoCard)} />
        <Box
          sx={{
            ...styledInnerBox,
            backgroundColor: theme.palette.softGray80,
          }}
        />
        <Typography variant="bodyMedium" sx={{ position: 'absolute' }} color="text.secondary">
          {additionalVideosCount}+
        </Typography>
      </Box>
    </Grid>
  );
}

export default AdditionalVideosThumbnail;
