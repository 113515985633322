export const translations = {
  generationDemoView: {
    en: 'Generating demo view...',
    sv: 'Genererar demo vy...',
  },
  loadingInfo: {
    en: 'This may take a few seconds',
    sv: 'Detta kan ta några sekunder',
  },
  demoPageInfo: {
    en: 'This webpage screenshot is for demonstration purposes only and lacks interactivity.',
    sv: 'Denna webbsidans skärmdump är endast avsedd för demonstration och saknar interaktivitet.',
  },
};

export default translations;
