import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import videoCollectorTranslations from '../VideoCollectorTranslations';
import BackIcon from '../../../common/icons/back/Back';
import TabSelector from '../../../common/TabSelector/TabSelector';
import { ActiveTab } from './types';
import { VideoCardDetailed } from '../types';
import filterValidVideoCards from './Videos/filterVideoCards';

interface VideoCollectorSettingsHeaderProps {
  onBackClick: () => void;
  activeTab: ActiveTab;
  setActiveTab: (activeTab: ActiveTab) => void;
  videoCards?: VideoCardDetailed[] | undefined;
}

function VideoCollectorSettingsHeader({
  onBackClick,
  activeTab,
  setActiveTab,
  videoCards = [],
}: VideoCollectorSettingsHeaderProps) {
  const lang = useLanguage();

  const handleOnBackClick = () => {
    onBackClick();
  };

  const availableTabs = [
    {
      value: 'settings',
      label: videoCollectorTranslations.settings[lang],
      disabled: false,
    },
    {
      value: 'videos',
      label: videoCollectorTranslations.videos[lang](0),
      disabled: false,
    },
    {
      value: 'review',
      label: videoCollectorTranslations.review[lang],
      disabled: false,
      number:
        videoCards && videoCards?.length > 0
          ? filterValidVideoCards(videoCards, ['REVIEW']).length
          : undefined,
    },
  ];

  return (
    <Grid
      item
      xs={12}
      xl={12}
      sm={12}
      md={12}
      lg={12}
      role="banner"
      container
      direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
      justifyContent={{
        xs: 'flex-start',
        sm: 'flex-start',
        md: 'flex-start',
        lg: 'space-between',
        xl: 'space-between',
      }}
      alignItems={{
        xs: 'flex-start',
        sm: 'flex-start',
        md: 'flex-start',
        lg: 'center',
        xl: 'center',
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
        <Stack direction="row" spacing={3}>
          <IconButton
            onClick={handleOnBackClick}
            data-testid="back-button"
            aria-label={videoCollectorTranslations.backButton[lang]}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="headlineLargeBoldRecoleta">
            {videoCollectorTranslations.videoCollector[lang]}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <TabSelector
              availableTabs={availableTabs}
              activeTab={activeTab}
              setActiveTab={(val) => {
                setActiveTab(val as ActiveTab);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
    </Grid>
  );
}

export default VideoCollectorSettingsHeader;
