import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GDPRIcon from 'src/components/common/icons/GDPR';
import { useLanguage } from 'src/context/LanguageContext';
import translationsGDPR from './translationsGDPR';

interface GDPRCompliantProps {
  includeLogo?: boolean;
  longText?: boolean;
}

function GDPRCompliant({ includeLogo = false, longText = false }: GDPRCompliantProps) {
  const lang = useLanguage();

  return (
    <Grid
      item
      container
      xs={12}
      spacing={0}
      justifyContent="space-between"
      alignItems="center"
      role="grid"
    >
      <Grid
        item
        xs={includeLogo ? 6 : 12}
        xl={includeLogo ? 6 : 12}
        sm={includeLogo ? 6 : 12}
        md={includeLogo ? 6 : 12}
        lg={includeLogo ? 6 : 12}
      >
        <Typography
          sx={{
            color: '#6D6F77',
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: '400',
            lineHeight: 16 / 12,
            wordWrap: 'break-word',
          }}
        >
          {translationsGDPR.isCompliant[lang]}
          {longText ? '. ' : ', '}
          <Link
            href="https://www.lifeinside.io/gdpr"
            target="_blank"
            sx={{
              color: '#0288D1',
              fontSize: '12px',
              fontFamily: 'Inter',
              fontWeight: '400',
              textDecoration: 'underline',
              lineHeight: 16 / 12,
              letterSpacing: 0.4,
              wordWrap: 'break-word',
            }}
            rel="noreferrer"
          >
            {translationsGDPR.learnMore[lang]}
          </Link>
          {longText && ` ${translationsGDPR.isCompliantContent[lang]}`}.
        </Typography>
      </Grid>
      {includeLogo && (
        <Grid item xs={6} xl={4} sm={4} md={4} lg={4}>
          <GDPRIcon />
        </Grid>
      )}
    </Grid>
  );
}

export default GDPRCompliant;
