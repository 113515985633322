import { uniqueId } from 'lodash';

export const translationsStoryline = {
  playVideoAriaLabel: {
    en: 'Play video',
    sv: 'Spela video',
  },
  newStoryline: {
    en: 'Storyline',
    sv: 'Storyline',
  },
  storyline: {
    en: 'Storyline',
    sv: 'Storyline',
  },
  videoFunnel: {
    en: 'Video Funnel',
    sv: 'Video Funnel',
  },
  newVideoFunnel: {
    en: 'Video Funnel',
    sv: 'Video Funnel',
  },
  allStorylines: {
    en: 'Storylines',
    sv: 'Storylines',
  },
  allStories: {
    en: 'Stories',
    sv: 'Stories',
  },
  editStoryline: {
    en: 'Edit Storyline',
    sv: 'Redigera Storyline',
  },
  createNewStoryline: {
    en: 'Create Storyline',
    sv: 'Lägg till Storyline',
  },
  editVideoFunnel: {
    en: 'Edit Video Funnel',
    sv: 'Redigera Video Funnel',
  },
  createNewVideoFunnel: {
    en: 'Create Video Funnel',
    sv: 'Lägg till Video Funnel',
  },
  createNewStorylineInfo: {
    en: 'Create a new storyline with video',
    sv: 'Skapa en storyline med videos',
  },
  editStorylineInfo: {
    en: 'Edit details of the storyline',
    sv: 'Redigera detaljerna på storyline',
  },
  selected: {
    en: 'Selected',
    sv: 'Vald',
  },
  deleteItem: {
    en: 'Delete storyline',
    sv: 'Ta bort storyline',
  },
  areYouSureDelete: {
    en: 'Are you sure you want to delete this item? It cannot be undone and all associated storyline-data will be removed',
    sv: 'Är du säker att du vill ta bort storyline? Det kan inte ångras och associerad storyline-data kommer tas bort.',
  },
  fieldsetLegend: {
    en: 'A set of Input fields for Title and Url for Storyline',
    sv: 'En uppsättning inmatningsfält för titel och URL för Storyline',
  },
  fieldsetLegendVideos: {
    en: 'A Input field for selecting videos for Storyline',
    sv: 'Ett inmatningsfält för att välja videos för Storyline',
  },
  title: {
    en: 'Title',
    sv: 'Titel',
  },
  titleTooltipVideoFunnel: {
    en: 'Name of Video Funnel',
    sv: 'Namn på Video Funnel',
  },
  shuffle: {
    en: 'Shuffle',
    sv: 'Shuffle',
  },
  titleInternalName: {
    en: 'Name of storyline',
    sv: 'Namn på storyline',
  },
  urlTitle: {
    en: 'Link',
    sv: 'Länk',
  },
  urlTitleInfo: {
    en: 'Http adress to show storyline on',
    sv: 'Http adress för att visa storyline på',
  },
  excludeWidgetLibraryBtn: {
    en: `Exclude 'View Library' Button`,
    sv: `Dölj 'Visa bibliotek' knappen`,
  },
  excludeWidgetLibraryBtnTooltip: {
    en: `Enable this checkbox to exclude the 'View Library' button from the widget, preventing users from accessing the library.`,
    sv: `Aktivera denna kryssruta för att dölja knappen 'Visa bibliotek' från widgeten, vilket förhindrar användare från att komma.`,
  },
  excludeWidgetLibraryBtnInfo: {
    en: 'Will hide it for this storyline',
    sv: 'Kommer döljas för denna storyline',
  },
  videos: {
    en: 'Videos',
    sv: 'Videos',
  },
  video: {
    en: 'Video',
    sv: 'Video',
  },
  editStorylineVideos: {
    en: 'Edit Videos',
    sv: 'Redigera Videos',
  },
  setStorylineVideos: {
    en: 'Set Videos',
    sv: 'Fastställ Videos',
  },
  randomizeVideoOrder: {
    en: 'Randomize Video Order',
    sv: 'Slumpmässig videoordning',
  },
  videoStatus: {
    en: 'Filter video status',
    sv: 'Filtrera videostatus',
  },
  addAllVideos: {
    en: 'Add All',
    sv: 'Lägg till Alla',
  },
  allUsers: {
    en: 'All users',
    sv: 'Alla användare',
  },
  statusSelectorText: {
    en: 'Storyline status',
    sv: 'Storyline status',
  },
  statusOptionsLive: {
    en: 'Active',
    sv: 'Aktiv',
  },
  statusOptionsEdit: {
    en: 'Inactive',
    sv: 'Inaktiv',
  },
  statusOptionsAll: {
    en: 'All statuses',
    sv: 'Alla status',
  },

  selectStatusStoryline: {
    en: 'Select Status',
    sv: 'Välj status',
  },

  storylineSearchField: {
    en: 'Search',
    sv: 'Sök',
  },
  storylineFilterStatusOptions: {
    en: 'Filtering on status on storylines cards',
    sv: 'Filtrering på status på storylines korten ',
  },
  storylineFilterUserOptions: {
    en: 'Filtering on user on storylines cards',
    sv: 'Filtrering på user på storylines korten ',
  },
  activate: {
    en: 'Activate',
    sv: 'Aktivera',
  },
  activeCard: {
    en: 'Active',
    sv: 'Aktiv',
  },
  deactivate: {
    en: 'Deactivate',
    sv: 'Avaktivera',
  },
  inactiveCard: {
    en: 'Inactive',
    sv: 'Inaktiv',
  },
  delete: {
    en: 'Delete',
    sv: 'Ta bort',
  },
  save: {
    en: 'Save',
    sv: 'Spara',
  },
  selectVideoCardAriaLabel: {
    en: 'Select Video Card',
    sv: 'Välj Videokort',
  },
  noVideoNewVideoStatus: {
    SUGGESTION: {
      en: 'Request waiting to be sent',
      sv: 'Videoförfrågan väntar på att skickas',
    },
    PENDING: {
      en: 'Awaiting Response',
      sv: 'Väntar på svar',
    },
    REVIEW: {
      en: 'Video Under Review',
      sv: 'Video Under Granskning',
    },
    APPROVED: {
      en: 'Ready for Library Launch',
      sv: 'Redo för bibliotekslansering',
    },
    LIVE: {
      en: 'Live',
      sv: 'Live',
    },
    ARCHIVED: {
      en: 'Safely Stored',
      sv: 'Arkiverade',
    },
    DELETED: {
      en: 'Video is deleted',
      sv: 'Videon är borttagen',
    },
  },
  errors: {
    TITLE_TEXT_TOO_LONG: {
      en: 'The title text is too long',
      sv: 'Titel texten är för lång',
    },
    URL_USED: {
      en: 'Link is already in use.',
      sv: 'Länk används redan',
    },
    WHEN_CONDITION_USED: {
      en: 'Oops! It looks like one of your conditions is already being used.',
      sv: 'Hoppsan! Det verkar som att en av dina villkor redan används.',
    },
    WHEN_CONDITION_USED_LONG: {
      en: 'Whoops! It seems like one of your conditions is used more than once in this Storyline.',
      sv: 'Oj då! Det verkar som att en av dina villkor redan används mer än en gång i denna Storyline.',
    },
    WHEN_CONDITION_USED_BY_OTHER: {
      en: 'Oops! It looks like one of your conditions is already being used by another Storyline. Please try a different condition.',
      sv: 'Hoppsan! Det verkar som att en av dina villkor redan används av en annan Storyline. Var vänlig prova ett annat villkor.',
    },
    WHEN_CONDITION_USED_BY_OTHER_SHORT: {
      en: 'This condition is already being used by another Storyline.',
      sv: 'Detta villkor används redan av en annan Storyline.',
    },
    WHEN_CONDITION_MUST_HAVE_VALUE: {
      en: 'Oops! You must have at least one when condition with a value to be able to save.',
      sv: 'Oj där! Du måste ha minst ett när-villkor med ett värde för att kunna spara.',
    },
    INVALID_URL: {
      en: 'Invalid link',
      sv: 'Ogiltig länk',
    },
    OOPS_INVALID_LINK: {
      en: 'Whoops, it looks like the one of the links you entered is not valid',
      sv: 'Oj, det verkar som att en länk du angivit inte är giltig',
    },
    SORRY_ABOUT_INVALID_LINK: {
      en: "We're sorry, it looks like one of the links you entered is not valid. Please check the link and try again!",
      sv: 'Vi ber om ursäkt, det verkar som att en av länkarna du angav inte är giltig. Var vänlig kontrollera länken och försök igen!',
    },
    NO_URL: {
      en: 'You must enter a link to activate',
      sv: 'Du måste ange en länk för att aktivera',
    },
    NO_URL_SAVED: {
      en: 'You must enter a link to save a active storyline',
      sv: 'Du måste ange en länk för att spara en aktiv storyline',
    },
    NO_VIDEOS_TO_ACTIVATE: {
      en: 'You must select videos to activate storyline',
      sv: 'Du måste välja videos för att aktivera storyline',
    },
    NO_VIDEOS_ACTIVE: {
      en: 'You must select videos to save a active storyline',
      sv: 'Du måste välja videos för att spara en aktiv storyline',
    },
    NO_LIVE_VIDEOS_ACTIVE: {
      en: 'To save an active Storyline, you must select at least one video that is live in the published library',
      sv: 'För att spara en aktiv Storyline måste du välja minst en video som är live i den publicerade biblioteket',
    },
    ACTIVATE_STORYLINE_WITH_LIVE_VIDEOS: {
      en: 'To activate an Storyline, you must select at least one video that is live and published to the library',
      sv: 'För att aktivera en Storyline måste du välja minst en video som är live och publicerade till videobiblioteket',
    },
    NO_TITLE: {
      en: 'You must enter a title',
      sv: 'Du måste ange en titel',
    },
  },
  successFeedback: {
    LIVE: {
      en: 'Great! The Storyline is live!',
      sv: 'Grymt! Storyline är live!',
    },
    deactivate: {
      en: 'Storyline has been deactivated!',
      sv: 'Storyline har avaktiverats!',
    },
    updated: {
      en: 'The Storyline has been updated!',
      sv: 'Storyline har uppdaterats!',
    },
    created: {
      en: 'The Storyline has been created!',
      sv: 'Storyline har skapats!',
    },
    deleted: {
      en: 'Storyline has been deleted!',
      sv: 'Storyline har blivit borttagen!',
    },
  },
  storylineCardImageAltText: {
    en: 'Screenshot of webpage',
    sv: 'Skärmdump av webbsida',
  },
  or: {
    en: 'Or',
    sv: 'Eller',
  },
  when: {
    en: 'When',
    sv: 'När',
  },
  websiteLink: {
    en: 'Website Link (URL)',
    sv: 'Webbsida länk (URL)',
  },
  override: {
    en: 'Override',
    sv: 'Tvinga',
  },
  availableWhenConditions: {
    exactMatch: {
      en: 'exact match',
      sv: 'exakt matchning',
      tooltipHelpText: {
        en: [
          "The widget will only appear on your website pages that match the exact URL you entered in the targeting rules. For example, if you set the Website URL as 'https://lifeinside.io/', the widget won't show up on 'https://lifeinside.io/get-life-inside/'.",
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "If you use query parameters in the URL, make sure to include them in the targeting rules. If you don't include them, the widget won't match the URL and won't appear on your page.",
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Now, for the override option, this is a special setting that applies to the exact match option for a storyline. If you tick the override option, it means that only the videos in the current storyline will be displayed and no other storylines will appear on that specific URL. This is helpful if you only want one specific storyline to appear on a particular page and not any other storylines. So, if you have multiple storylines on the same URL with different rules and tick the override option, NO other storyline - other than the one you have selected - can or will be shown on that specific URL.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "When you set up your widget, it's important to make sure that you enter the correct website URL in the targeting rules. This means that you need to double-check that you've included the correct format, such as 'www' or 'https'",
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "If you're unsure about how to use it properly, please don't hesitate to contact us in the chat for more help. We're always here to assist you!",
        ],
        sv: [
          "Widgeten kommer endast att visas på webbsidor som matchar exakt den URL du angav i regeln. Till exempel, om du anger webbplatsens URL som 'https://lifeinside.io/', visas inte widgeten på 'https://lifeinside.io/get-life-inside/'.",
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Om du använder frågeparametrar i URL:en, se till att inkludera dem i regeln. Om du inte inkluderar dem, matchar inte widgeten URL:en och visas inte på din sida.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Nu till överstyrningsalternativet. Detta är en särskild inställning som gäller för exakt matchning-alternativet för en berättelse. Om du markerar överstyrningsalternativet innebär det att endast videor i den nuvarande berättelsen kommer att visas och inga andra berättelser visas på den specifika URL:en. Detta är användbart om du bara vill att en specifik berättelse ska visas på en särskild sida och inte några andra berättelser. Så om du har flera berättelser på samma URL med olika regler och markerar överstyrningsalternativet, kan INGEN annan berättelse än den du har valt visas på den specifika URL:en.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "När du konfigurerar din widget är det viktigt att se till att du anger rätt webbplats-URL i regeln. Detta innebär att du behöver dubbelkolla att du har inkluderat rätt format, såsom 'www' eller 'https'",
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Om du är osäker på hur du använder det korrekt, tveka inte att kontakta oss i chatten för mer hjälp. Vi finns alltid här för att hjälpa dig!',
        ],
      },
    },
    contains: {
      en: 'contains',
      sv: 'innehåller',
      tooltipHelpText: {
        en: [
          'Your widget will appear on any page that includes the value you entered in the targeting rule, even if there are query parameters. For example, if you set the targeting rule as Website URL | contains | "https://lifeinside.io/", the widget will show up on all pages that include "https://lifeinside.io/", like "https://lifeinside.io/about" and "https://lifeinside.io/get-life-inside/".',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `make sure that your widget appears on your website, you need to make sure that the website URL you enter in the targeting rules matches your actual website URL. Even small differences like including or not including "www" can cause the targeting rules to not match your website, and your widget won't show up.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "If you need any help with this, feel free to contact us in the chat. We're always happy to help!",
        ],
        sv: [
          'Din widget kommer att visas på alla sidor som inkluderar värdet du angav i regeln, även om det finns frågeparametrar. Till exempel, om du ställer in regeln som Website URL | contains | "https://lifeinside.io/", kommer widgeten att visas på alla sidor som inkluderar "https://lifeinside.io/", som "https://lifeinside.io/about" och "https://lifeinside.io/get-life-inside/".',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'För att se till att din widget visas på din webbplats måste du se till att webbplatsens URL som du anger i reglerna matchar din faktiska webbplats-URL. Även små skillnader som att inkludera eller inte inkludera "www" kan orsaka att målreglerna inte matchar din webbplats och din widget visas inte.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Om du behöver hjälp med detta, tveka inte att kontakta oss i chatten. Vi hjälper dig gärna!',
        ],
      },
    },
    beginsWith: {
      en: 'begins with',
      sv: 'börjar med',
      tooltipHelpText: {
        en: [
          'This targeting rule means that your widget will only show up on pages that have a URL starting with the value you specified. For example, if you set the targeting rule as Website URL | begins with | https://lifeinside.io/, the widget will appear on pages like https://lifeinside.io/ and https://lifeinside.io/get-life-inside/, but not on pages like https://about.lifeinside.io/.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          `To make sure your widget appears on your website, it's important that the URL you enter in the targeting rule matches the actual URL of your website. Even small differences like the inclusion or omission of "www" at the beginning of the URL could cause problems and your widget may not appear on your page.`,
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "If you need any help with this, please feel free to contact us in the chat. We're always here to help!",
        ],
        sv: [
          'Det här regeln innebär att din widget endast kommer visas på sidor som har en URL som börjar med det värde du angett. Till exempel, om du ställer in regeln som Webbplats-URL | börjar med | https://lifeinside.io/, kommer widgeten visas på sidor som https://lifeinside.io/ och https://lifeinside.io/get-life-inside/, men inte på sidor som https://about.lifeinside.io/.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'För att se till att din widget visas på din webbplats, är det viktigt att URL:en du anger i regeln matchar den faktiska URL:en på din webbplats. Även små skillnader som inkluderingen eller utelämnandet av "www" i början av URL:en kan orsaka problem och din widget kanske inte visas på din sida.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Om du behöver hjälp med detta, tveka inte att kontakta oss i chatten. Vi finns alltid här för att hjälpa till!',
        ],
      },
    },
    endsWith: {
      en: 'ends with',
      sv: 'slutar med',
      tooltipHelpText: {
        en: [
          'This targeting rule means that your widget will only appear on pages with a URL that ends with a specific value. For example, if you set the targeting rule as Website URL | ends with | "/article", the widget will show up on pages like https://lifeinside.io/article and https://lifeinside.io/get-life-inside/article, but not on pages like https://lifeinside.io/get-life-inside.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "To ensure that your widget appears on your website, it's important to make sure that the live URL of your website matches the input you entered in the targeting rule. Even small differences in the end of the URL could cause problems with the targeting rules not matching your live website, and your widget may not appear on your page.",
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "If you need any help with this, don't hesitate to contact us in the chat. We're always happy to help!",
        ],
        sv: [
          'Den här regeln innebär att din widget bara kommer att visas på sidor med en URL som slutar med ett specifikt värde. Till exempel, om du ställer in regeln som Webbplats-URL | slutar med | "/article", kommer widgeten att visas på sidor som https://lifeinside.io/article och https://lifeinside.io/get-life-inside/article, men inte på sidor som https://lifeinside.io/get-life-inside.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'För att se till att din widget visas på din webbplats är det viktigt att se till att din webbplats URL matchar det du skrivit in i regeln. Även små skillnader i slutet av URL:en kan orsaka problem med att riktlinjerna inte matchar din webbplats och din widget kanske inte visas på din sida.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Om du behöver hjälp med detta, tveka inte att kontakta oss i chatten. Vi hjälper dig gärna!',
        ],
      },
    },
    wildCard: {
      en: 'wildcard',
      sv: 'joker',
      tooltipHelpText: {
        en: [
          'The wildcard option is a powerful feature that allows you to target specific pages more effectively. Instead of having to create a separate rule for every page, you can include a wildcard character (*) in your URL to represent any combination of characters. This means that the widget will be displayed on any page that matches the pattern you specify, regardless of the specific characters in the URL.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'For example, if you want to display the widget on all blog articles, you could use the rule Website URL | matches wildcard | https://lifeinside.io/blog/*. This would match any URL that starts with "https://lifeinside.io/blog/" and has any combination of characters after it. You can include * more than once in your URL.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          "It's important to note that the wildcard option should be used with care, as it can potentially match more pages than you intended. If you're unsure about how to use it properly, please don't hesitate to contact us in the chat for more help. We're always here to assist you!",
        ],
        sv: [
          'Joker-alternativet är en kraftfull funktion som gör att du kan rikta in dig på specifika sidor mer effektivt. Istället för att skapa en separat regel för varje sida kan du inkludera ett joker-tecken (*) i din URL för att representera vilken kombination av tecken som helst. Detta innebär att widgeten visas på vilken sida som helst som matchar det mönster du specificerar, oavsett vilka specifika tecken som finns i URL:en. Du kan inkludera * mer än en gång i din URL.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Till exempel, om du vill visa widgeten på alla bloggartiklar, kan du använda regeln "Website URL | matchar joker | https://lifeinside.io/blog/*". Detta skulle matcha vilken URL som helst som börjar med "https://lifeinside.io/blog/" och har vilken kombination av tecken som helst efter det.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Det är viktigt att notera att joker-alternativet bör användas med försiktighet, eftersom det potentiellt kan matcha fler sidor än du avsåg. Om du är osäker på hur du använder det på rätt sätt, tveka inte att kontakta oss i chatten för mer hjälp. Vi är alltid här för att hjälpa dig!',
        ],
      },
    },
  },
  showStorylineWidget: {
    en: 'Show Widget',
    sv: 'Visa Widget',
  },
  hideStorylineWidget: {
    en: 'Hide Widget',
    sv: 'Dölj Widget',
  },
  addRule: {
    en: 'Add rule',
    sv: 'Lägg regel',
  },
  addExclusionRule: {
    en: 'Add exclusion rule',
    sv: 'Lägg till uteslutningsregel',
  },

  videoFunnel: {
    title: {
      en: 'Video Funnel',
      sv: 'Video Funnel',
    },
    deleteItem: {
      en: 'Delete Funnel',
      sv: 'Ta bort Funnel',
    },
    ctaTitlePlaceholder: {
      en: 'Button Text',
      sv: 'Knapp-text',
    },
    videoTitle: {
      en: 'Video Title',
      sv: 'Videotitel',
    },
    tooltips: {
      expandSettings: {
        en: 'Expand settings',
        sv: 'Expandera inställningar',
      },
      collapseSettings: {
        en: 'Collapse settings',
        sv: 'Minimera inställningar',
      },
      handleTooltip: {
        en: 'Click and drag to connect',
        sv: 'Klicka och dra för att ansluta',
      },
      editVideoTitle: {
        en: [
          'Edit video title:',
          <br key={uniqueId()} />,
          'This action will change the title of the selected video within the Video Funnel only. It will not affect the title of the same video used in other Video Funnels or outside of the Video Funnel system.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          'Original title:',
          <br key={uniqueId()} />,
        ],
        sv: [
          'Redigera videons titel:',
          <br key={uniqueId()} />,
          'Denna åtgärd kommer att ändra titeln på den valda videon endast inom Video Funnel-systemet. Det kommer inte att påverka titeln på samma video som används i andra Video Funnels eller utanför Video Funnel-systemet.',
          <br key={uniqueId()} />,
          <br key={uniqueId()} />,
          ,
          'Originaltitel:',
          <br key={uniqueId()} />,
        ],
      },
    },
    hidePreview: {
      en: 'Hide preview',
      sv: 'Dölj förhandsgranskning',
    },
    showPreview: {
      en: 'Preview',
      sv: 'Förhandsgranskning',
    },
    clickToAddVideo: {
      en: 'Click to add video',
      sv: 'Klicka för att lägga till video',
    },
    uploadVideo: {
      en: 'Upload video',
      sv: 'Ladda upp video',
    },
    selectEndScreen: {
      en: 'Select End Screen',
      sv: 'Välj End Screen',
    },
    selectEndScreens: {
      en: 'Select End Screens',
      sv: 'Välj End Screens',
    },
    createEndScreen: {
      en: 'Create End Screen',
      sv: 'Skapa End Screen',
    },
    addVideoCTAEndScreen: {
      en: 'Add video',
      sv: 'Lägg till video',
    },
    ctaTimingDescription: {
      en: 'Customize when your buttons starts to appear on your video.',
      sv: 'Anpassa när dina knappar börjar dyka upp på din video.',
    },
    ctaTiming: {
      en: 'Button timing',
      sv: 'Knapp timing',
    },
    showAfter: {
      en: 'Show after',
      sv: 'Visa efter',
    },
    timing: {
      en: 'Timing',
      sv: 'Tid aktivering',
    },
    videoNodeOptions: {
      en: 'Video Customization',
      sv: 'Videoinställningar',
    },
    ctaOptions: {
      en: 'Call-to-Action options',
      sv: 'Call-to-Action alternativ',
    },
    addSelectedVideo: {
      en: 'Add selected video',
      sv: 'Lägg till vald video',
    },
    addSelectedVideos: {
      en: 'Add selected videos',
      sv: 'Lägg till valda videos',
    },
    addVideo: {
      en: 'Add video',
      sv: 'Lägg till video',
    },
    cancel: {
      en: 'Cancel',
      sv: 'Avbryt',
    },
    addEndScreen: {
      en: 'Add End Screen',
      sv: 'Lägg till End Screen',
    },
    selectVideo: {
      en: 'Select video',
      sv: 'Välj video',
    },
    exitFullScreenAriaLabel: {
      en: 'Exit full screen',
      sv: 'Avsluta fullskärm',
    },
    enterFullScreenAriaLabel: {
      en: 'Enter full screen',
      sv: 'Fullskärm',
    },

    customCTLLabelError: {
      en: ({ maxLength }) => `Text to long, max ${maxLength} characters allowed`,
      sv: ({ maxLength }) => `Text för lång, max ${maxLength} tecken tillåtna`,
    },
    videoFunnelLabel: {
      en: 'Video Funnel',
      sv: 'Video Funnel',
    },
    start: {
      en: 'Start',
      sv: 'Start',
    },
    successFeedback: {
      LIVE: {
        en: 'Great! The Video Funnel is live!',
        sv: 'Grymt! Video Funnel är live!',
      },
      deactivate: {
        en: 'Video Funnel has been deactivated!',
        sv: 'Video Funnel har avaktiverats!',
      },
      updated: {
        en: 'The Video Funnel has been updated!',
        sv: 'Video Funnel har uppdaterats!',
      },
      created: {
        en: 'The Video Funnel has been created!',
        sv: 'Video Funnel har skapats!',
      },
      deleted: {
        en: 'Video Funnel has been deleted!',
        sv: 'Video Funnel har blivit borttagen!',
      },
    },
    deleteFunction: {
      deleteItem: {
        en: 'Delete Video Funnel',
        sv: 'Ta bort Video Funnel',
      },
      areYouSureDelete: {
        en: 'Are you sure you want to delete this Video Funnel? It cannot be undone and all associated data will be removed.',
        sv: 'Är du säker på att du vill ta bort denna Video Funnel? Det går inte att ångra och all associerad data kommer att tas bort.',
      },
      deleteConfirm: {
        en: 'Delete',
        sv: 'Ta bort',
      },
      noCancel: {
        en: 'Cancel',
        sv: 'Avbryt',
      },
    },
    removeObjects: {
      remove: {
        en: 'Remove',
        sv: 'Ta bort',
      },
      removeItem: {
        en: ({ count }) => {
          if (count > 1) {
            return 'Remove items';
          }
          return 'Remove item';
        },
        sv: ({ count }) => {
          if (count > 1) {
            return 'Ta bort objekt';
          }
          return 'Ta bort objekt';
        },
      },
      removeConnection: {
        en: ({ count }) => {
          if (count > 1) {
            return 'Remove connections';
          }
          return 'Remove connection';
        },
        sv: ({ count }) => {
          if (count > 1) {
            return 'Ta bort anslutningar';
          }
          return 'Ta bort anslutning';
        },
      },
      removeConnectionAndItem: {
        en: ({ itemCount, connectionCount }) => {
          const itemText = itemCount > 1 ? 'items' : 'item';
          const connectionText = connectionCount > 1 ? 'connections' : 'connection';
          return `Remove ${itemCount > 0 ? `${itemCount} ${itemText}` : ``}${
            itemCount > 0 && connectionCount > 0 ? ` and ` : ``
          } ${connectionCount > 0 ? `${connectionCount} ${connectionText}` : ``}`;
        },
        sv: ({ itemCount, connectionCount }) => {
          const itemText = itemCount > 1 ? 'objekt' : 'objekt';
          const connectionText = connectionCount > 1 ? 'anslutningar' : 'anslutning  ';
          return `Ta bort ${itemCount > 0 ? `${itemCount} ${itemText}` : ``}${
            itemCount > 0 && connectionCount > 0 ? ` och ` : ``
          } ${connectionCount > 0 ? `${connectionCount} ${connectionText}` : ``}`;
        },
      },
    },
  },
};

export default translationsStoryline;
