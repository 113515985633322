import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { gql } from '@apollo/client';
import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import { translations } from '../../utils/translationsEditSuggestedQuestions';
import './editSuggestedQuestions.scss';

const SUGGESTED_QUESTIONS_RESPONSE_FRAGMENT = gql`
  fragment SuggestedQuestionsResponseDetails on SuggestedQuestionsResponse {
    questions {
      sv {
        id
        question
        language
        categoryId
        categoryTitle
        forLeaders
        forTeamMember
        created
      }
      en {
        id
        question
        language
        categoryId
        categoryTitle
        forLeaders
        forTeamMember
        created
      }
    }
    questionCategories {
      en {
        id
        title
        forLeaders
        forTeamMember
        language
        created
      }
      sv {
        id
        title
        forLeaders
        forTeamMember
        language
        created
      }
    }
    status
  }
`;

const getQuestions = (adminClient) => {
  return adminClient.query({
    query: gql`
      ${SUGGESTED_QUESTIONS_RESPONSE_FRAGMENT}
      query {
        suggestedQuestions {
          ...SuggestedQuestionsResponseDetails
        }
      }
    `,
  });
};

const addSuggestedQuestion = ({
  adminClient,
  language,
  question,
  categoryId,
  forLeaders = false,
  forTeamMember = true,
}) => {
  return adminClient.mutate({
    mutation: gql`
    ${SUGGESTED_QUESTIONS_RESPONSE_FRAGMENT}
      mutation {
        addSuggestedQuestion(
          input: {language: "${language}",
                  question: "${question}",
                  ${categoryId !== 'none' ? `categoryId: ${categoryId},` : ''}
                  forLeaders: ${forLeaders},
                  forTeamMember: ${forTeamMember},}) {
            ...SuggestedQuestionsResponseDetails
          }
       }
    `,
  });
};

const addSuggestedCategory = ({
  adminClient,
  title,
  forLeaders = false,
  forTeamMember = true,
  language,
}) => {
  return adminClient.mutate({
    mutation: gql`
    ${SUGGESTED_QUESTIONS_RESPONSE_FRAGMENT}
      mutation {
        addSuggestedCategory(
          input: {title: "${title}"
                  forLeaders: ${forLeaders}
                  forTeamMember: ${forTeamMember}
                  language: "${language}",
                  }) {
            ...SuggestedQuestionsResponseDetails
          }
       }
    `,
  });
};

const deleteSuggestedQuestion = (adminClient, id) => {
  return adminClient.mutate({
    mutation: gql`
    ${SUGGESTED_QUESTIONS_RESPONSE_FRAGMENT}
      mutation {
        deleteSuggestedQuestion(
          input: {
            id: ${id},
          }
        ) {
          ...SuggestedQuestionsResponseDetails
        }
      }
    `,
  });
};

const deleteSuggestedCategory = (adminClient, id) => {
  return adminClient.mutate({
    mutation: gql`
    ${SUGGESTED_QUESTIONS_RESPONSE_FRAGMENT}
      mutation {
        deleteSuggestedCategory(
          input: {
            id: ${id},
          }
        ) {
          ...SuggestedQuestionsResponseDetails
        }
      }
    `,
  });
};

export default function EditSuggestedQuestions() {
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const [newQuestion, setNewQuestion] = useState('');
  const [newCategory, setNewCategory] = useState('');

  const [newQuestionForLeaders, setNewQuestionForLeaders] = useState(false);
  const [newQuestionForTeamMember, setNewQuestionForTeamMember] = useState(false);
  const [newQuestionForAll, setNewQuestionForAll] = useState(true);
  const [newQuestionCatId, setNewQuestionCatId] = useState('');

  const [newCategoryForLeaders, setNewCategoryForLeaders] = useState(false);
  const [newCategoryForTeamMember, setNewCategoryForTeamMember] = useState(false);
  const [newCategoryForAll, setNewCategoryForAll] = useState(true);

  const [activeLang, setActiveLang] = useState('en');
  const [error, setError] = useState(null);

  const [englishQuestions, setEnglishQuestions] = useState([]);
  const [englishCategories, setEnglishCategories] = useState([]);
  const [swedishQuestions, setSwedishQuestions] = useState([]);
  const [swedishCategories, setSwedishCategories] = useState([]);

  const [activeQuestions, setActiveQuestions] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);

  const handleQuestions = (suggestedQuestionSv, suggestedQuestionEn, language) => {
    const qEn = suggestedQuestionEn ? [...suggestedQuestionEn] : [];
    const qSv = suggestedQuestionSv ? [...suggestedQuestionSv] : [];
    const sortedQuestionsSv =
      qSv?.sort((a, b) => (a?.question?.toLowerCase() > b?.question?.toLowerCase() ? 1 : -1)) || [];

    const sortedQuestionsEn =
      qEn?.sort((a, b) => {
        if (a?.categoryId === b?.categoryId) {
          return a?.question?.toLowerCase() > b?.question?.toLowerCase() ? 1 : -1;
        }
        return a?.categoryTitle?.toLowerCase() > b?.categoryTitle?.toLowerCase() ? 1 : -1;
      }) || [];

    setSwedishQuestions(sortedQuestionsSv);
    setEnglishQuestions(sortedQuestionsEn);
    if (language === 'sv') {
      setActiveQuestions(sortedQuestionsSv);
    }
    if (language === 'en') {
      setActiveQuestions(sortedQuestionsEn);
    }
  };

  const handleCategories = (suggestedCategoriesSv, suggestedCategoriesEn, language) => {
    const cEn = suggestedCategoriesEn ? [...suggestedCategoriesEn] : [];
    const cSv = suggestedCategoriesSv ? [...suggestedCategoriesSv] : [];

    const sortedCategoriesSv =
      cSv?.sort((a, b) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1)) || [];

    const sortedCategoriesEn =
      cEn?.sort((a, b) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1)) || [];

    setSwedishCategories(sortedCategoriesSv);
    setEnglishCategories(sortedCategoriesEn);
    if (language === 'sv') {
      setActiveCategories(sortedCategoriesSv);
    }
    if (language === 'en') {
      setActiveCategories(sortedCategoriesEn);
    }
  };
  const handleResponse = (
    suggestedQuestionSv,
    suggestedQuestionEn,
    suggestedCategoriesSv,
    suggestedCategoriesEn,
    language,
  ) => {
    handleQuestions(suggestedQuestionSv, suggestedQuestionEn, language);
    handleCategories(suggestedCategoriesSv, suggestedCategoriesEn, language);
  };

  const handleSubmission = (event) => {
    event.preventDefault();
    const valueQuestion = event.target.question.value;
    const valueQuestionForTeamMember = event.target.questionForTeamMember.checked;
    const valueQuestionForLeader = event.target.questionForLeaders?.checked;
    const valueQuestionCatId = event.target.questionCategoryId.value || null;

    if (valueQuestion === '' || activeLang === 'none') {
      alert('Please enter a question and select a language');
    } else if (valueQuestion?.length >= 65) {
      alert('Please enter a question less than 65 characters');
    } else {
      const confirmation = window.confirm(translations.verifyAdd[lang]);
      if (confirmation) {
        if (activeLang === 'en') {
          handleResponse(
            swedishQuestions,
            [
              ...englishQuestions,
              {
                question: valueQuestion,
                language: activeLang,
                id: valueQuestionCatId ?? valueQuestion + activeLang,
                forLeaders: valueQuestionForTeamMember,
                forTeamMember: valueQuestionForLeader,
              },
            ],
            swedishCategories,
            englishCategories,
          );
        }

        if (activeLang === 'sv') {
          handleResponse(
            [
              ...swedishQuestions,
              {
                question: valueQuestion,
                language: activeLang,
                id: valueQuestionCatId ?? valueQuestion + activeLang,
                forLeaders: valueQuestionForTeamMember,
                forTeamMember: valueQuestionForLeader,
              },
            ],
            englishQuestions,
            swedishCategories,
            englishCategories,
          );
        }

        addSuggestedQuestion({
          adminClient,
          language: activeLang,
          question: valueQuestion,
          categoryId: valueQuestionCatId,
          forLeaders: valueQuestionForLeader,
          forTeamMember: valueQuestionForTeamMember,
        }).then((res) => {
          const suggestedQuestion = res.data.addSuggestedQuestion.questions;
          const suggestedCategories = res.data.addSuggestedQuestion.questionCategories;
          handleResponse(
            suggestedQuestion?.sv,
            suggestedQuestion?.en,
            suggestedCategories?.sv,
            suggestedCategories?.en,
            activeLang,
          );

          if (suggestedQuestion.status === 'FAILED') {
            setError(translations.addSuggestedQuestionError[lang]);
            setTimeout(() => {
              setError(null);
            }, 4000);
          } else {
            setNewQuestion('');
            setNewQuestionCatId('');
          }
        });
      }
    }
  };

  const handleCategorySubmission = (event) => {
    event.preventDefault();
    const valueCategory = event.target.category.value;

    const valueCategoryForTeamMember = event.target.categoryForTeamMember.checked;
    const valueCategoryForLeader = event.target.categoryForLeaders?.checked;

    if (valueCategory === '' || activeLang === 'none') {
      alert('Please enter a title and select a language');
    } else if (valueCategory?.length >= 65) {
      alert('Please enter a title less than 65 characters');
    } else {
      const confirmation = window.confirm(translations.verifyAdd[lang]);
      if (confirmation) {
        if (activeLang === 'en') {
          handleResponse(swedishQuestions, englishQuestions, swedishCategories, [
            ...englishCategories,
            {
              title: valueCategory,
              language: activeLang,
              id: valueCategory + activeLang,
              forLeaders: valueCategoryForLeader,
              forTeamMember: valueCategoryForTeamMember,
            },
          ]);
        }

        if (activeLang === 'sv') {
          handleResponse(
            swedishQuestions,
            englishQuestions,
            [
              ...swedishCategories,
              {
                title: valueCategory,
                language: activeLang,
                id: valueCategory + activeLang,
                forLeaders: valueCategoryForLeader,
                forTeamMember: valueCategoryForTeamMember,
              },
            ],
            englishCategories,
          );
        }

        addSuggestedCategory({
          adminClient,
          language: activeLang,
          title: valueCategory,
          forLeaders: valueCategoryForLeader,
          forTeamMember: valueCategoryForTeamMember,
        }).then((res) => {
          const suggestedQuestion = res.data.addSuggestedCategory.questions;
          const suggestedCategories = res.data.addSuggestedCategory.questionCategories;
          handleResponse(
            suggestedQuestion?.sv,
            suggestedQuestion?.en,
            suggestedCategories?.sv,
            suggestedCategories?.en,
            activeLang,
          );

          if (suggestedQuestion.status === 'FAILED') {
            setError(translations.addSuggestedQuestionError[lang]);
            setTimeout(() => {
              setError(null);
            }, 4000);
          } else {
            setNewCategory('');
          }
        });
      }
    }
  };

  useEffect(() => {
    getQuestions(adminClient).then((res) => {
      const suggestedQuestions = res.data.suggestedQuestions.questions;
      const suggestedCategories = res.data.suggestedQuestions.questionCategories;

      handleResponse(
        suggestedQuestions?.sv || [],
        suggestedQuestions?.en || [],
        suggestedCategories?.sv || [],
        suggestedCategories?.en || [],
        activeLang,
      );
    });
  }, []);

  useEffect(() => {}, [
    newQuestion,
    activeLang,
    englishQuestions,
    swedishQuestions,
    activeCategories,
    activeQuestions,
    newCategoryForLeaders,
    setNewCategoryForLeaders,
    newCategoryForTeamMember,
    setNewCategoryForTeamMember,
    newQuestionForAll,
    newCategoryForAll,
  ]);

  const handleDeleteSuggestedQuestion = (question) => {
    const confirmation = window.confirm(translations.verifyDelete[lang]);
    if (confirmation) {
      if (question?.language === 'en') {
        const filteredEnglishQuestions =
          englishQuestions?.filter((q) => q?.id !== question?.id) || [];
        handleResponse(
          filteredEnglishQuestions,
          englishQuestions,
          swedishCategories,
          englishCategories,
        );
      }

      if (category?.language === 'sv') {
        const filteredSwedishQuestions = swedishQuestions?.filter((q) => q?.id !== question?.id);
        handleResponse(
          filteredSwedishQuestions,
          englishQuestions,
          swedishCategories,
          englishCategories,
        );
      }
      deleteSuggestedQuestion(adminClient, question.id)
        .then((res) => {
          const suggestedQuestion = res.data.deleteSuggestedQuestion.questions;
          const suggestedCategories = res.data.deleteSuggestedQuestion.questionCategories;
          handleResponse(
            suggestedQuestion?.sv,
            suggestedQuestion?.en,
            suggestedCategories?.sv,
            suggestedCategories?.en,
            activeLang,
          );

          if (suggestedQuestion.status === 'FAILED') {
            setError(translations.addSuggestedQuestionError[lang]);
            setTimeout(() => {
              setError(null);
            }, 4000);
          }
        })
        .catch((err) => {});
    }
  };

  const handleDeleteSuggestedCategory = (category) => {
    const confirmation = window.confirm(translations.verifyDelete[lang]);
    if (confirmation) {
      if (category?.language === 'en') {
        const filteredEnglishCategories =
          englishCategories?.filter((c) => c?.id !== category?.id) || [];
        handleResponse(
          englishQuestions,
          englishQuestions,
          swedishCategories,
          filteredEnglishCategories,
        );
      }

      if (category?.language === 'sv') {
        const filteredSwedishCategories = swedishCategories?.filter((c) => c?.id !== category?.id);
        handleResponse(
          swedishQuestions,
          englishQuestions,
          filteredSwedishCategories,
          englishCategories,
        );
      }
      deleteSuggestedCategory(category.id)
        .then((res) => {
          const suggestedQuestion = res.data.deleteSuggestedCategory.questions;
          const suggestedCategories = res.data.deleteSuggestedCategory.questionCategories;

          handleResponse(
            suggestedQuestion?.sv,
            suggestedQuestion?.en,
            suggestedCategories?.sv,
            suggestedCategories?.en,
            activeLang,
          );

          if (suggestedQuestion.status === 'FAILED') {
            setError(translations.addSuggestedQuestionError[lang]);
            setTimeout(() => {
              setError(null);
            }, 4000);
          }
        })
        .catch((err) => {});
    }
  };

  const errorMsg = error && <div className="error-pop">{error}</div>;

  const handleSetActiveLang = (newActiveLang) => {
    setActiveLang(newActiveLang);
    if (newActiveLang === 'en') {
      setActiveQuestions(englishQuestions);
      setActiveCategories(englishCategories);
    }
    if (newActiveLang === 'sv') {
      setActiveQuestions(swedishQuestions);
      setActiveCategories(swedishCategories);
    }
  };

  return (
    <div>
      {errorMsg}
      <div className="header-row">
        <h1>
          {translations.headerP1[lang]}
          {newQuestion === 'Fråga' ? (
            <Link to="/admin/all-questions" className="no-underline">
              {translations.headerP2[lang]}
            </Link>
          ) : (
            translations.headerP2[lang]
          )}
          {': '}
          <select
            id="lang"
            name="lang"
            onChange={(e) => handleSetActiveLang(e.target.value)}
            value={activeLang}
          >
            <option value="en">{translations.english[lang]}</option>
            <option value="sv">{translations.swedish[lang]}</option>
          </select>
        </h1>
      </div>
      <div>
        <form onSubmit={handleSubmission}>
          <div className="form-group">
            <label htmlFor="question">
              {translations.question[lang]}
              <input
                id="question"
                type="text"
                onChange={(e) => setNewQuestion(e.target.value)}
                value={newQuestion}
                required
              />
            </label>

            <label htmlFor="questionForAll">
              {translations.forAll[lang]}
              <input
                id="questionForAll"
                type="checkbox"
                checked={newQuestionForAll}
                onChange={(e) => {
                  if (newQuestionForAll && !newQuestionForTeamMember && !newQuestionForLeaders) {
                    setNewQuestionForAll(true);
                  } else if (
                    !newQuestionForAll &&
                    (newQuestionForTeamMember || newQuestionForLeaders)
                  ) {
                    setNewQuestionForAll(true);
                    setNewQuestionForTeamMember(false);
                    setNewQuestionForLeaders(false);
                  } else {
                    setNewQuestionForAll(!newQuestionForAll);
                  }
                }}
              />
            </label>

            <label htmlFor="questionForTeamMember">
              {translations.forTeamMember[lang]}
              <input
                id="questionForTeamMember"
                type="checkbox"
                checked={newQuestionForTeamMember}
                onChange={(e) => {
                  if (newQuestionForAll && !newQuestionForTeamMember && !newQuestionForLeaders) {
                    setNewQuestionForAll(false);
                    setNewQuestionForTeamMember(!newQuestionForTeamMember);
                  } else if (
                    !newQuestionForAll &&
                    newQuestionForTeamMember &&
                    !newQuestionForLeaders
                  ) {
                    setNewQuestionForAll(true);
                    setNewQuestionForTeamMember(!newQuestionForTeamMember);
                  } else {
                    setNewQuestionForTeamMember(!newQuestionForTeamMember);
                  }
                }}
              />
            </label>

            <label htmlFor="questionForLeaders">
              {translations.forLeaders[lang]}
              <input
                id="questionForLeaders"
                type="checkbox"
                checked={newQuestionForLeaders}
                onChange={(e) => {
                  if (newQuestionForAll && !newQuestionForTeamMember && !newQuestionForLeaders) {
                    setNewQuestionForAll(false);
                    setNewQuestionForLeaders(!newQuestionForLeaders);
                  } else if (
                    !newQuestionForAll &&
                    !newQuestionForTeamMember &&
                    newQuestionForLeaders
                  ) {
                    setNewQuestionForAll(true);
                    setNewQuestionForLeaders(!newQuestionForLeaders);
                  } else {
                    setNewQuestionForLeaders(!newQuestionForLeaders);
                  }
                }}
              />
            </label>

            <label htmlFor="questionCategoryId">
              {translations.selectCategory[lang]}
              <select
                id="questionCategoryId"
                name="questionCategoryId"
                onChange={(e) => setNewQuestionCatId(e.target.value)}
                value={newQuestionCatId}
              >
                <option key={null} value={null}>
                  {translations.none[lang]}
                </option>
                {activeCategories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <Button type="submit" variant="contained" disableElevation>
            <i className="icon">add</i>
            {translations.addQuestion[lang]}
          </Button>
        </form>
        <hr className="solid" />
        <form onSubmit={handleCategorySubmission}>
          <div className="form-group">
            <label htmlFor="category">
              {translations.category[lang]}
              <input
                id="category"
                type="text"
                onChange={(e) => setNewCategory(e.target.value)}
                value={newCategory}
                required
              />
            </label>

            <label htmlFor="categoryForAll" aria-label={translations.forAll[lang]}>
              <input
                id="categoryForAll"
                type="checkbox"
                checked={newCategoryForAll}
                onChange={(e) => {
                  if (newCategoryForAll && !newCategoryForTeamMember && !newCategoryForLeaders) {
                    setNewCategoryForAll(true);
                  } else if (
                    !newCategoryForAll &&
                    (newCategoryForTeamMember || newCategoryForLeaders)
                  ) {
                    setNewCategoryForAll(true);
                    setNewCategoryForTeamMember(false);
                    setNewCategoryForLeaders(false);
                  } else {
                    setNewCategoryForAll(!newCategoryForAll);
                  }
                }}
              />
            </label>

            <label htmlFor="categoryForTeamMember">
              {translations.forTeamMember[lang]}
              <input
                id="categoryForTeamMember"
                type="checkbox"
                checked={newCategoryForTeamMember}
                onChange={(e) => {
                  if (newCategoryForAll && !newCategoryForTeamMember && !newCategoryForLeaders) {
                    setNewCategoryForAll(false);
                    setNewCategoryForTeamMember(!newCategoryForTeamMember);
                  } else if (
                    !newCategoryForAll &&
                    newCategoryForTeamMember &&
                    !newCategoryForLeaders
                  ) {
                    setNewCategoryForAll(true);
                    setNewCategoryForTeamMember(!newCategoryForTeamMember);
                  } else {
                    setNewCategoryForTeamMember(!newCategoryForTeamMember);
                  }
                }}
              />
            </label>

            <label htmlFor="categoryForLeaders">
              {translations.forLeaders[lang]}
              <input
                id="categoryForLeaders"
                type="checkbox"
                checked={newCategoryForLeaders}
                onChange={(e) => {
                  if (newCategoryForAll && !newCategoryForTeamMember && !newCategoryForLeaders) {
                    setNewCategoryForAll(false);
                    setNewCategoryForLeaders(!newCategoryForLeaders);
                  } else if (
                    !newCategoryForAll &&
                    !newCategoryForTeamMember &&
                    newCategoryForLeaders
                  ) {
                    setNewCategoryForAll(true);
                    setNewCategoryForLeaders(!newCategoryForLeaders);
                  } else {
                    setNewCategoryForLeaders(!newCategoryForLeaders);
                  }
                }}
              />
            </label>
          </div>
          <Button type="submit" variant="contained" disableElevation>
            <i className="icon">add</i>
            {translations.addCategory[lang]}
          </Button>
        </form>
      </div>
      <hr className="solid" />
      <div className="list-view">
        <div className="leftList">
          <table className="table" style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th className="table-header-name">{translations.question[lang]}</th>
                <th>{translations.delete[lang]}</th>
              </tr>
            </thead>
            <tbody>
              {activeQuestions.map((question) => (
                <tr key={question.id} style={{ borderBottom: '1px solid ' }}>
                  <td style={{ display: 'grid' }}>
                    {question.question}
                    <span
                      style={{
                        marginLeft: '10px',
                        marginBottom: '5px',
                        marginTop: '-5px',
                        fontSize: '12px',
                      }}
                    >
                      {question.categoryTitle}{' '}
                      {question?.forLeaders && <b>{translations.leader[lang]}</b>}{' '}
                      {question?.forTeamMember && <b>{translations.teamMember[lang]}</b>}
                    </span>
                  </td>
                  <td>
                    <Button
                      onClick={() => handleDeleteSuggestedQuestion(question.id)}
                      color="warning"
                    >
                      <span aria-hidden="true">&times;</span>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="rightList" style={{ marginLeft: '20px' }}>
          <table className="table" style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th className="table-header-name">{translations.category[lang]}</th>
                <th> # </th>
                <th>{translations.delete[lang]}</th>
              </tr>
            </thead>
            <tbody>
              {[...activeCategories, { title: translations.notCategorized[lang], id: null }].map(
                (category) => (
                  <tr key={category.id} style={{ borderBottom: '1px solid ' }}>
                    <td>
                      {category.title}
                      <span
                        style={{
                          marginLeft: '10px',
                          marginBottom: '5px',
                          marginTop: '-5px',
                          fontSize: '12px',
                        }}
                      >
                        {category?.forLeaders && <b>{translations.leader[lang]}</b>}{' '}
                        {category?.forTeamMember && <b>{translations.teamMember[lang]}</b>}
                      </span>
                    </td>
                    <td>
                      {activeQuestions.reduce(
                        (acc, cur) => (cur.categoryId === category.id ? ++acc : acc),
                        0,
                      )}
                    </td>
                    <td>
                      {category.id && (
                        <Button
                          onClick={() => handleDeleteSuggestedCategory(category.id)}
                          color="warning"
                        >
                          <span aria-hidden="true">&times;</span>
                        </Button>
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
