const translations = {
  title: {
    en: 'Title',
    sv: 'Titel',
  },
  impressions: {
    en: 'Impressions',
    sv: 'Visningar',
  },
  viewTime: {
    en: 'View time',
    sv: 'Visningstid',
  },
  ariaLabel: {
    sortBy: {
      en: 'Sort by',
      sv: 'Sortera efter',
    },
  },
};

export default translations;
