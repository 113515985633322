export const HappyScribeSupportedLanguages = [
  {
    code: 'af-ZA',
    language: 'Afrikaans (South Africa)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'am-ET',
    language: 'Amharic (Ethiopia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-DZ',
    language: 'Arabic (Algeria)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-BH',
    language: 'Arabic (Bahrain)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-EG',
    language: 'Arabic (Egypt)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-IQ',
    language: 'Arabic (Iraq)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-IL',
    language: 'Arabic (Israel)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-JO',
    language: 'Arabic (Jordan)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-KW',
    language: 'Arabic (Kuwait)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-LB',
    language: 'Arabic (Lebanon)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-MA',
    language: 'Arabic (Morocco)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-OM',
    language: 'Arabic (Oman)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-QA',
    language: 'Arabic (Qatar)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-SA',
    language: 'Arabic (Saudi Arabia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-PS',
    language: 'Arabic (State of Palestine)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-TN',
    language: 'Arabic (Tunisia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-AE',
    language: 'Arabic (United Arab Emirates)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ar-YE',
    language: 'Arabic (Yemen)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'hy-AM',
    language: 'Armenian (Armenia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'az-AZ',
    language: 'Azerbaijani (Azerbaijan)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'eu-ES',
    language: 'Basque (Spain)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'bn-BD',
    language: 'Bengali (Bangladesh)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'bn-IN',
    language: 'Bengali (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'bg-BG',
    language: 'Bulgarian (Bulgaria)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ca-ES',
    language: 'Catalan (Spain)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'yue-Hant-HK',
    language: 'Chinese, Cantonese (Traditional, Hong Kong)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'cmn-Hans-CN',
    language: 'Chinese, Mandarin (Simplified, China)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'cmn-Hans-HK',
    language: 'Chinese, Mandarin (Simplified, Hong Kong)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'cmn-Hant-TW',
    language: 'Chinese, Mandarin (Traditional, Taiwan)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'hr-HR',
    language: 'Croatian (Croatia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'cs-CZ',
    language: 'Czech (Czech Republic)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'da-DK',
    language: 'Danish (Denmark)',
    humanServiceAvailable: false,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'nl-NL',
    language: 'Dutch (Netherlands)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'nl-BE',
    language: 'Dutch (Belgium)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-AU',
    language: 'English (Australia)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-CA',
    language: 'English (Canada)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-GH',
    language: 'English (Ghana)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-IN',
    language: 'English (India)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-IE',
    language: 'English (Ireland)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-KE',
    language: 'English (Kenya)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-NZ',
    language: 'English (New Zealand)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-NG',
    language: 'English (Nigeria)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-PH',
    language: 'English (Philippines)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-ZA',
    language: 'English (South Africa)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-TZ',
    language: 'English (Tanzania)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-HK',
    language: 'English (Hong Kong)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-PK',
    language: 'English (Pakistan)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-SG',
    language: 'English (Singapore)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'en-GB',
    language: 'English (United Kingdom)',
    humanServiceAvailable: true,
    active: true,
  },
  {
    code: 'en-US',
    language: 'English (United States)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'fil-PH',
    language: 'Filipino (Philippines)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'fi-FI',
    language: 'Finnish (Finland)',
    humanServiceAvailable: false,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'fr-CA',
    language: 'French (Canada)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'fr-FR',
    language: 'French (France)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'fr-BE',
    language: 'French (Belgium)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'fr-CH',
    language: 'French (Switzerland)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'gl-ES',
    language: 'Galician (Spain)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ka-GE',
    language: 'Georgian (Georgia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'de-DE',
    language: 'German (Germany)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'de-AT',
    language: 'German (Austria)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'de-CH',
    language: 'German (Switzerland)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'el-GR',
    language: 'Greek (Greece)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'gu-IN',
    language: 'Gujarati (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'he-IL',
    language: 'Hebrew (Israel)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'hi-IN',
    language: 'Hindi (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'hu-HU',
    language: 'Hungarian (Hungary)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'is-IS',
    language: 'Icelandic (Iceland)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'id-ID',
    language: 'Indonesian (Indonesia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'it-IT',
    language: 'Italian (Italy)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'it-CH',
    language: 'Italian (Switzerland)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'ja-JP',
    language: 'Japanese (Japan)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'jv-ID',
    language: 'Javanese (Indonesia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'kn-IN',
    language: 'Kannada (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'km-KH',
    language: 'Khmer (Cambodia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ko-KR',
    language: 'Korean (South Korea)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'lo-LA',
    language: 'Lao (Laos)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'lv-LV',
    language: 'Latvian (Latvia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'lt-LT',
    language: 'Lithuanian (Lithuania)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ms-MY',
    language: 'Malay (Malaysia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ml-IN',
    language: 'Malayalam (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'mr-IN',
    language: 'Marathi (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ne-NP',
    language: 'Nepali (Nepal)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'nb-NO',
    language: 'Norwegian Bokmål (Norway)',
    humanServiceAvailable: false,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'fa-IR',
    language: 'Persian (Iran)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'pl-PL',
    language: 'Polish (Poland)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'pt-BR',
    language: 'Portuguese (Brazil)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'pt-PT',
    language: 'Portuguese (Portugal)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'ro-RO',
    language: 'Romanian (Romania)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ru-RU',
    language: 'Russian (Russia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sr-RS',
    language: 'Serbian (Serbia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'si-LK',
    language: 'Sinhala (Srilanka)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sk-SK',
    language: 'Slovak (Slovakia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sl-SI',
    language: 'Slovenian (Slovenia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'es-AR',
    language: 'Spanish (Argentina)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-BO',
    language: 'Spanish (Bolivia)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-CL',
    language: 'Spanish (Chile)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-CO',
    language: 'Spanish (Colombia)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-CR',
    language: 'Spanish (Costa Rica)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-DO',
    language: 'Spanish (Dominican Republic)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-EC',
    language: 'Spanish (Ecuador)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-SV',
    language: 'Spanish (El Salvador)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-GT',
    language: 'Spanish (Guatemala)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-HN',
    language: 'Spanish (Honduras)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-MX',
    language: 'Spanish (Mexico)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-NI',
    language: 'Spanish (Nicaragua)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-PA',
    language: 'Spanish (Panama)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-PY',
    language: 'Spanish (Paraguay)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-PE',
    language: 'Spanish (Peru)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-PR',
    language: 'Spanish (Puerto Rico)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-ES',
    language: 'Spanish (Spain)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'es-US',
    language: 'Spanish (United States)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-UY',
    language: 'Spanish (Uruguay)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'es-VE',
    language: 'Spanish (Venezuela)',
    humanServiceAvailable: true,
    active: false,
  },
  {
    code: 'su-ID',
    language: 'Sundanese (Indonesia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sw-KE',
    language: 'Swahili (Kenya)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sw-TZ',
    language: 'Swahili (Tanzania)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sv-SE',
    language: 'Swedish (Sweden)',
    humanServiceAvailable: false,
    active: true,
    defaultServiceLanguage: true,
  },
  {
    code: 'ta-IN',
    language: 'Tamil (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ta-MY',
    language: 'Tamil (Malaysia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ta-SG',
    language: 'Tamil (Singapore)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ta-LK',
    language: 'Tamil (Sri Lanka)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'te-IN',
    language: 'Telugu (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'th-TH',
    language: 'Thai (Thailand)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'tr-TR',
    language: 'Turkish (Turkey)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'uk-UA',
    language: 'Ukrainian (Ukraine)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ur-IN',
    language: 'Urdu (India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'ur-PK',
    language: 'Urdu (Pakistan)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'vi-VN',
    language: 'Vietnamese (Vietnam)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'zu-ZA',
    language: 'Zulu (South Africa)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'sq-AL',
    language: 'Albanian (Albania)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'bs-BA',
    language: 'Bosnian (Bosnia and Herzegovina)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'my-MM',
    language: 'Burmese (Myanmar)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'et-EE',
    language: 'Estonian (Estonia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'mk-MK',
    language: 'Macedonian (North Macedonia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'mn-MN',
    language: 'Mongolian (Mongolia)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'pa-Guru-IN',
    language: 'Punjabi (Gurmukhi India)',
    humanServiceAvailable: false,
    active: false,
  },
  {
    code: 'uz-UZ',
    language: 'Uzbek (Uzbekistan)',
    humanServiceAvailable: false,
    active: false,
  },
];

export const fetchActiveLanguages = () => {
  return HappyScribeSupportedLanguages?.filter((lang) => lang?.active)?.sort((a, b) =>
    a.language.localeCompare(b.language),
  );
};

export const findLanguage = (language) => {
  // Exact match
  const exactMatch = HappyScribeSupportedLanguages?.find((lang) => lang.code === language);
  if (exactMatch) {
    return exactMatch;
  }

  // First part with default true match

  const defaultTrueMatch = HappyScribeSupportedLanguages?.find(
    (lang) => lang.language.slice(0, 2) === language.slice(0, 2) && lang.defaultServiceLanguage,
  );

  if (defaultTrueMatch) {
    return defaultTrueMatch;
  }

  // First part match
  const firstPartMatch = HappyScribeSupportedLanguages?.find(
    (lang) => lang.language.slice(0, 2) === language.slice(0, 2),
  );

  if (firstPartMatch) {
    return firstPartMatch;
  }

  return {
    code: 'en-US',
    language: 'English (United States)',
    humanServiceAvailable: true,
    active: true,
    defaultServiceLanguage: true,
  };
};
