import { gql } from '@apollo/client';
import { STATS_BY_TIMESTAMP_FRAGMENT } from '../Overview/analyticsOverviewApi';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_STORIES_WITH_STATS = gql`
  ${STATS_BY_TIMESTAMP_FRAGMENT}
  query GetAllStoriesWithStats($encodedOrgId: String!, $startDate: String, $endDate: String) {
    getAllStoriesWithStats(encodedOrgId: $encodedOrgId, startDate: $startDate, endDate: $endDate) {
      identifier {
        videoFunnelId
        storylineId
      }
      stats {
        playback {
          impression {
            ...StatByTimestamp
          }
          timePlayed {
            ...StatByTimestamp
          }
        }
      }
      urlsJsonb {
        url
        srcImage
        imageUpdatedAt
        srcImageFullPage
        conditionWhen
        excludedWhen
        storylineId
        override
      }
      title
      orgId
      status
    }
  }
`;
