import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { useDashboardApi } from './DashboardApi';
import HelloCard from './HelloCard';
import ViewsChartCard from './ViewsChartCard';
import PopularVideosCard from './PopularVideosCard';
import ToDoViewCard from './ToDoViewCard';
import UpdatesCard from './UpdatesCard';
// import PopularStoryline from './PopularStorylineCard';
import DataCard from './DataCard';
import TutorialCards from './TutorialCards';

export default function DashboardPage() {
  const dashboardApi = useDashboardApi();
  const {
    basicOrgData,
    signedInUser,
    dashboardStats,
    videoCards,
    storylineObjects,
    videoFunnelObjects,
  } = useOutletContext();

  const [dashboardStatsState, setDashboardStats] = useState(dashboardStats.current);

  useEffect(() => {
    if (!basicOrgData?.encodedId) {
      return;
    }
    dashboardApi
      .getDashboardData(basicOrgData.encodedId)
      .then((response) => {
        const { allDashboardData } = response.data;

        setDashboardStats(allDashboardData);
        dashboardStats.current = {
          ...dashboardStats.current,
          ...allDashboardData,
        };
      })
      .catch(() => {});
  }, [
    dashboardApi,
    basicOrgData.encodedId,
    dashboardStats,
    dashboardStatsState,
    setDashboardStats,
  ]);

  if (!basicOrgData) {
    return <div />;
  }

  return (
    <Box
      component="main"
      sx={{
        pt: 6,
        pb: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item lg={7} sm={12} xl={7} xs={12} container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <HelloCard
                signedInUser={signedInUser}
                basicOrgData={basicOrgData}
                dashboardStats={dashboardStats}
                dashboardStatsState={dashboardStatsState}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12} container spacing={3}>
              <DataCard
                basicOrgData={basicOrgData}
                dashboardStats={dashboardStats}
                dashboardStatsState={dashboardStatsState}
                videoCards={videoCards}
                storylineObjects={storylineObjects.current}
                videoFunnelObjects={videoFunnelObjects.current}
              />
            </Grid>

            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <ViewsChartCard
                basicOrgData={basicOrgData}
                dashboardStats={dashboardStats}
                dashboardStatsState={dashboardStatsState}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <TutorialCards />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <PopularVideosCard
                basicOrgData={basicOrgData}
                dashboardStats={dashboardStats}
                dashboardStatsState={dashboardStatsState}
              />
            </Grid>
          </Grid>
          <Grid item lg={5} sm={12} xl={5} xs={12} container spacing={3}>
            <Grid item lg={12} sm={12} xl={12} xs={12} sx={{ pt: 0 }}>
              <UpdatesCard basicOrgData={basicOrgData} />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <ToDoViewCard
                basicOrgData={basicOrgData}
                dashboardStats={dashboardStats}
                dashboardStatsState={dashboardStatsState}
                videoCards={videoCards}
              />
            </Grid>
            {/* <Grid item lg={12} sm={12} xl={12} xs={12}>
              <PopularStoryline
                basicOrgData={basicOrgData}
                dashboardStats={dashboardStats}
                dashboardStatsState={dashboardStatsState}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
