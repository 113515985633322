import LazyLoad from 'react-lazy-load';
import _ from 'lodash';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import StorylineCard from './StorylineCard';

const cardWidth = 304;
// const cardHeight = 380;

function StorylineGrid({
  storylineObjects = [],
  handleSelected = () => {},
  basicOrgData = {},
  affectedItem = null,
}) {
  const isAffectedItem = ({ item }) => {
    if (!affectedItem) {
      return false;
    }
    if (affectedItem?.type === 'videoFunnel') {
      return item.type === 'videoFunnel' && Number(item.id) === Number(affectedItem?.id);
    }
    return item.type !== 'videoFunnel' && Number(item.id) === Number(affectedItem?.id);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        mt: 2,
        mb: 2,
        ml: 3,
        mr: 3,
      }}
    >
      <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="baseline">
        {storylineObjects?.map((storyline) => (
          <Grid item key={_.uniqueId(`${storyline.id}-grid-item`)}>
            <LazyLoad
              key={_.uniqueId(`${storyline.id}-lazyload`)}
              offset={600}
              width={cardWidth}
              // height={cardHeight}
            >
              <StorylineCard
                storyline={storyline}
                key={_.uniqueId(`${storyline.id}-card`)}
                handleSelected={(e) => {
                  handleSelected(e);
                }}
                cardWidth={cardWidth}
                // cardHeight={cardHeight}
                basicOrgData={basicOrgData}
                affectedItem={isAffectedItem({ item: storyline })}
              />
            </LazyLoad>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

StorylineGrid.propTypes = {
  storylineObjects: PropTypes.arrayOf(PropTypes.shape({})),
  handleSelected: PropTypes.func,
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
    id: PropTypes.number,
  }),
  affectedItem: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
};

export default StorylineGrid;
