import { useEffect, useState } from 'react';
import { useAuth0, GenericError } from '@auth0/auth0-react';

export type Auth0AccessTokenError = 'login_required' | 'consent_required' | 'unknown';

const useAuth0AccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accessTokenError, setAccessTokenError] = useState<Auth0AccessTokenError | null>(null);

  useEffect(() => {
    const getAccessToken = async () => {
      let token: string | null = null;
      let error: Auth0AccessTokenError | null = null;
      try {
        token = await getAccessTokenSilently();
      } catch (err) {
        const reason = (err as GenericError)?.error;
        if (reason === 'login_required' || reason === 'consent_required') {
          error = reason;
        } else {
          error = 'unknown';
        }
      }
      return { token, error };
    };

    const abortController = new AbortController();
    if (isAuthenticated) {
      getAccessToken().then(({ token, error }) => {
        if (!abortController.signal.aborted) {
          setAccessToken(token);
          setAccessTokenError(error);
        }
      });
    } else {
      setAccessToken(null);
      setAccessTokenError(null);
    }
    return () => abortController.abort();
  }, [isAuthenticated, getAccessTokenSilently]);

  return { accessToken, error: accessTokenError };
};

export default useAuth0AccessToken;
