import { useMemo } from 'react';
import { gql } from '@apollo/client/index';
import _ from 'lodash';

import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';

const RECIPIENT_DETAIL_FRAGMENT = gql`
  fragment Recipient on Recipient {
    id
    firstName
    email
    title
    phoneNumber
    receiveAutoRequests
    leader
    lastName
    name
  }
`;

export class RecipientActions {
  /**
   * @type {import('src/context/ApolloClientContext').ApolloClient}
   */
  #backOfficeClient;

  constructor(backOfficeClient) {
    this.#backOfficeClient = backOfficeClient;
  }

  createRecipient({
    encodedOrgId,
    orgId,
    firstName,
    email,
    title,
    phoneNumber,
    receiveAutoRequests = true,
    leader = false,
    lastName,
  }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${RECIPIENT_DETAIL_FRAGMENT}
        mutation CreateRecipient($encodedOrgId: String, $input: RecipientInput) {
          createRecipient(encodedOrgId: $encodedOrgId, input: $input) {
            recipient {
              ...Recipient
            }
            recipients {
              ...Recipient
            }
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: {
          orgId,
          firstName,
          email,
          title,
          phoneNumber,
          receiveAutoRequests,
          leader,
          lastName,
        },
      },
    });
  }

  createRecipients(encodedOrgId, recipients) {
    const recipientsInput = _.map(
      recipients,
      ({ orgId, firstName, lastName, title, leader, email, phoneNumber, receiveAutoRequests }) => {
        const input = {
          orgId,
          firstName,
          lastName,
          title,
          leader,
          email,
          phoneNumber,
          receiveAutoRequests,
        };
        return input;
      },
    );

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${RECIPIENT_DETAIL_FRAGMENT}
        mutation CreateRecipients($encodedOrgId: String, $recipients: [RecipientInput]) {
          createRecipients(encodedOrgId: $encodedOrgId, recipients: $recipients) {
            recipients {
              ...Recipient
            }
          }
        }
      `,
      variables: {
        encodedOrgId,
        recipients: recipientsInput,
      },
    });
  }

  updateRecipient({
    encodedOrgId,
    orgId,
    firstName,
    email,
    title,
    phoneNumber,
    receiveAutoRequests,
    leader,
    lastName,
    id,
  }) {
    const input = {
      id,
      orgId,
      ...(firstName ? { firstName } : {}),
      ...(email ? { email } : {}),
      ...(title ? { title } : {}),
      ...(phoneNumber != null ? { phoneNumber } : {}),
      ...(receiveAutoRequests != null ? { receiveAutoRequests } : {}),
      ...(leader != null ? { leader } : {}),
      ...(lastName ? { lastName } : {}),
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${RECIPIENT_DETAIL_FRAGMENT}
        mutation UpdateRecipient($encodedOrgId: String, $input: RecipientInput) {
          updateRecipient(encodedOrgId: $encodedOrgId, input: $input) {
            recipient {
              ...Recipient
            }
            recipients {
              ...Recipient
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  deleteRecipient({ encodedOrgId, orgId, id }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${RECIPIENT_DETAIL_FRAGMENT}
        mutation DeleteRecipient($encodedOrgId: String, $orgId: Int, $id: Int) {
          deleteRecipient(encodedOrgId: $encodedOrgId, orgId: $orgId, id: $id) {
            ...Recipient
          }
        }
      `,
      variables: { encodedOrgId, orgId, id },
    });
  }

  recipientsByOrgId({ encodedOrgId, orgId }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${RECIPIENT_DETAIL_FRAGMENT}
        mutation RecipientsByOrgId($encodedOrgId: String!, $orgId: Int!) {
          recipientsByOrgId(encodedOrgId: $encodedOrgId, orgId: $orgId) {
            ...Recipient
          }
        }
      `,
      variables: { encodedOrgId, orgId },
    });
  }
}

export const useRecipientActions = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new RecipientActions(backOfficeClient), [backOfficeClient]);
};
