import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { gql } from '@apollo/client';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { translations as translationsSettings } from '../../../utils/translationsSettings';
import { translations } from '../../../utils/translations';

import useCheckPermissionsAndNavigate from './Permissions/CheckPermissionsAndNavigate';

const updateOrgSettingsStyle = ({ backOfficeClient, encodedOrgId, style }) => {
  return backOfficeClient.mutate({
    mutation: gql`
        mutation($encodedOrgId: String) {
          updateOrgSettingsStyle(encodedOrgId: $encodedOrgId, input: {
            style: ${style},
          }) {
            statusMessage
          }
        }
      `,
    variables: {
      encodedOrgId,
    },
  });
};
const updateOrgLogo = (backOfficeClient, encodedOrgId, file) => {
  return backOfficeClient.mutate({
    mutation: gql`
      mutation ($encodedOrgId: String, $file: Upload!) {
        updateOrgLogo(encodedOrgId: $encodedOrgId, file: $file) {
          logoPath
        }
      }
    `,
    variables: {
      file,
      encodedOrgId,
    },
  });
};

export default function GeneralSettings(props) {
  const lang = useLanguage();
  const backOfficeClient = useBackOfficeApolloClient();
  const { basicOrgData } = useOutletContext();

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  const hasAdvancedWidgetStyling = !!basicOrgData?.premiumFeatures?.advancedWidgetStyling;
  const hasAdvancedWidgetBehavior = !!basicOrgData?.premiumFeatures?.advancedWidgetBehavior;
  const hasAdvStyleVidTitleDialog = !!basicOrgData?.premiumFeatures?.advStyleVidTitleDialog;

  const hasAdvancedWidgetStylingMobile =
    !!basicOrgData?.premiumFeatures?.advancedWidgetStylingMobile;

  const [backgroundColor, setBackgroundColor] = useState(basicOrgData.style?.backgroundColor || '');
  const [backgroundColorValid, setBackgroundColorValid] = useState(true);
  const [textColor, setTextColor] = useState(basicOrgData.style?.textColor || '');

  const [isEdit, setIsEdit] = useState(false);

  const [enableHideWidget, setEnableHideWidget] = useState(
    !!(hasAdvancedWidgetBehavior && basicOrgData.style?.enableHideWidget),
  );

  const [widgetPositionTop, setWidgetPositionTop] = useState(
    !!(hasAdvancedWidgetStyling && basicOrgData.style?.widgetPositionTop),
  );
  const [widgetProcentBasedMargin, setWidgetProcentBasedMargin] = useState(
    !!(hasAdvancedWidgetStyling && basicOrgData.style?.widgetProcentBasedMargin),
  );

  const bottomMarginMax = 100;
  const bottomMarginMin = 0;
  const defaultBottomMargin = 10;
  const [bottomMargin, setBottomMargin] = useState(
    hasAdvancedWidgetStyling &&
      basicOrgData.style?.widgetBottomMargin !== null &&
      basicOrgData.style?.widgetBottomMargin >= bottomMarginMin &&
      basicOrgData.style?.widgetBottomMargin <= bottomMarginMax
      ? basicOrgData.style?.widgetBottomMargin
      : defaultBottomMargin,
  );

  const [bottomMarginValid, setBottomMarginValid] = useState(true);

  const sideMarginMax = 100;
  const sideMarginMin = 0;
  const defaultSideMargin = 15;
  const [sideMargin, setSideMargin] = useState(
    hasAdvancedWidgetStyling &&
      basicOrgData.style?.widgetSideMargin !== null &&
      basicOrgData.style?.widgetSideMargin >= sideMarginMin &&
      basicOrgData.style?.widgetSideMargin <= sideMarginMax
      ? basicOrgData.style?.widgetSideMargin
      : defaultSideMargin,
  );

  const [sideMarginValid, setSideMarginValid] = useState(true);

  const [widgetPositionLeft, setWidgetPositionLeft] = useState(
    basicOrgData.style?.widgetPositionLeft || false,
  );

  const [separateDesktopMobile, setSeparateDesktopMobile] = useState(
    (hasAdvancedWidgetStylingMobile && basicOrgData.style?.separateDesktopMobile) ?? false,
  );

  const [widgetHideDialogTitle, setWidgetHideDialogTitle] = useState(
    (hasAdvStyleVidTitleDialog && basicOrgData.style?.widgetHideDialogTitle) ?? false,
  );

  // MOBILE

  const [widgetPositionTopMobile, setWidgetPositionTopMobile] = useState(
    !!(hasAdvancedWidgetStylingMobile && basicOrgData.style?.widgetPositionTopMobile),
  );
  const [widgetProcentBasedMarginMobile, setWidgetProcentBasedMarginMobile] = useState(
    !!(hasAdvancedWidgetStylingMobile && basicOrgData.style?.widgetProcentBasedMarginMobile),
  );

  const [bottomMarginMobile, setBottomMarginMobile] = useState(
    hasAdvancedWidgetStylingMobile &&
      basicOrgData.style?.widgetBottomMarginMobile !== null &&
      basicOrgData.style?.widgetBottomMarginMobile >= bottomMarginMin &&
      basicOrgData.style?.widgetBottomMarginMobile <= bottomMarginMax
      ? basicOrgData.style?.widgetBottomMarginMobile
      : defaultBottomMargin,
  );

  const [bottomMarginValidMobile, setBottomMarginMobileValid] = useState(true);

  const [sideMarginMobile, setSideMarginMobile] = useState(
    hasAdvancedWidgetStylingMobile &&
      basicOrgData.style?.widgetSideMarginMobile !== null &&
      basicOrgData.style?.widgetSideMarginMobile >= sideMarginMin &&
      basicOrgData.style?.widgetSideMarginMobile <= sideMarginMax
      ? basicOrgData.style?.widgetSideMarginMobile
      : defaultSideMargin,
  );

  const [sideMarginValidMobile, setSideMarginMobileValid] = useState(true);

  const [widgetPositionLeftMobile, setWidgetPositionLeftMobile] = useState(
    basicOrgData.style?.widgetPositionLeftMobile || false,
  );

  const [widgetHideDialogTitleMobile, setWidgetHideDialogTitleMobile] = useState(
    (hasAdvStyleVidTitleDialog && basicOrgData.style?.widgetHideDialogTitleMobile) ?? false,
  );

  const [excludeWidgetLibraryBtn, setExcludeWidgetLibraryBtn] = useState(
    basicOrgData.style?.excludeWidgetLibraryBtn || false,
  );
  const [textColorValid, setTextColorValid] = useState(true);

  const [btnColor, setBtnColor] = useState(basicOrgData.style?.btnColor || '');
  const [btnColorValid, setBtnColorValid] = useState(true);

  const [btnTextColor, setBtnTextColor] = useState(basicOrgData.style?.btnTextColor || '');
  const [btnTextColorValid, setBtnTextColorValid] = useState(true);

  const [ctaLabel, setCtaLabel] = useState(basicOrgData.style?.ctaLabel);
  const ctaLabelMaxLength = 33;
  const [ctaLabelValid, setCtaLabelValid] = useState(true);

  const [minimizedWidgetTitle] = useState(basicOrgData.style?.minimizedWidgetTitle);

  const [logo, setLogo] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [showUpload, setShowUpload] = useState(true);

  const [uploadConsentText] = useState(basicOrgData.customTexts?.uploadConsent);
  const [soMeConsentText] = useState(basicOrgData.customTexts?.soMeConsent);

  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (
      backgroundColor !== basicOrgData.style?.backgroundColor ||
      textColor !== basicOrgData.style?.textColor ||
      btnColor !== basicOrgData.style?.btnColor ||
      btnTextColor !== basicOrgData.style?.btnTextColor ||
      ctaLabel !== basicOrgData.style?.ctaLabel ||
      enableHideWidget !== (basicOrgData?.style?.enableHideWidget ?? false) ||
      excludeWidgetLibraryBtn !==
        (basicOrgData?.style?.excludeWidgetLibraryBtn
          ? basicOrgData?.style?.excludeWidgetLibraryBtn
          : false) ||
      logoFile ||
      sideMargin !==
        (basicOrgData?.style?.widgetSideMargin ? basicOrgData?.style?.widgetSideMargin : false) ||
      bottomMargin !==
        (basicOrgData?.style?.widgetBottomMargin
          ? basicOrgData?.style?.widgetBottomMargin
          : false) ||
      widgetPositionLeft !==
        (basicOrgData?.style?.widgetPositionLeft
          ? basicOrgData?.style?.widgetPositionLeft
          : false) ||
      widgetPositionTop !==
        (basicOrgData.style?.widgetPositionTop ? basicOrgData.style?.widgetPositionTop : false) ||
      widgetProcentBasedMargin !==
        (basicOrgData.style?.widgetProcentBasedMargin
          ? basicOrgData.style?.widgetProcentBasedMargin
          : false) ||
      sideMarginMobile !==
        (basicOrgData?.style?.widgetSideMarginMobile
          ? basicOrgData?.style?.widgetSideMarginMobile
          : false) ||
      bottomMarginMobile !==
        (basicOrgData?.style?.widgetBottomMarginMobile
          ? basicOrgData?.style?.widgetBottomMarginMobile
          : false) ||
      widgetPositionLeftMobile !==
        (basicOrgData?.style?.widgetPositionLeftMobile
          ? basicOrgData?.style?.widgetPositionLeftMobile
          : false) ||
      widgetPositionTopMobile !==
        (basicOrgData.style?.widgetPositionTopMobile
          ? basicOrgData.style?.widgetPositionTopMobile
          : false) ||
      widgetProcentBasedMarginMobile !==
        (basicOrgData.style?.widgetProcentBasedMarginMobile
          ? basicOrgData.style?.widgetProcentBasedMarginMobile
          : false) ||
      separateDesktopMobile !==
        (basicOrgData.style?.separateDesktopMobile
          ? basicOrgData.style?.separateDesktopMobile
          : false) ||
      widgetHideDialogTitle !==
        (basicOrgData.style?.widgetHideDialogTitle
          ? basicOrgData.style?.widgetHideDialogTitle
          : false) ||
      widgetHideDialogTitleMobile !==
        (basicOrgData.style?.widgetHideDialogTitleMobile
          ? basicOrgData.style?.widgetHideDialogTitleMobile
          : false)
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [
    backgroundColor,
    textColor,
    btnColor,
    btnTextColor,
    ctaLabel,
    widgetPositionLeft,
    excludeWidgetLibraryBtn,
    logoFile,
    widgetPositionTop,
    widgetProcentBasedMargin,
    enableHideWidget,
    sideMargin,
    bottomMargin,
    separateDesktopMobile,
    widgetPositionLeftMobile,
    widgetPositionTopMobile,
    widgetProcentBasedMarginMobile,
    sideMarginMobile,
    bottomMarginMobile,
    widgetHideDialogTitle,
    widgetHideDialogTitleMobile,
  ]);

  useEffect(() => {
    if (info) {
      setTimeout(() => {
        setInfo(null);
      }, 4000);
    }
  }, [info]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }, [error]);

  useEffect(() => {
    setLogo(basicOrgData.logoPath);
    if (basicOrgData.logoPath) {
      setShowUpload(false);
    }
  }, []);

  const onUploadImg = (data) => {
    const { validity } = data.target;
    const file = data.target.files[0];

    if (file.size > 10000000) {
      alert('The image is too large, please select another one');
      data.target.value = null;
      setLogoFile(null);
    } else if (validity.valid) {
      setLogoFile(file);
    } else {
      data.target.value = null;
      setLogoFile(null);
    }
  };

  const saveLogo = () => {
    if (logoFile) {
      updateOrgLogo(backOfficeClient, basicOrgData.encodedId, logoFile)
        .then((res) => {
          setInfo(translations.savedSuccess[lang]);
          setLogo(res.data.updateOrgLogo.logoPath);
          setShowUpload(false);
        })
        .catch((e) => {
          setError(translations.savedError[lang]);
        });
    }
  };
  const saveChanges = () => {
    if (backgroundColorValid && textColorValid && btnColorValid && btnTextColorValid) {
      const style = `{
        backgroundColor: "${backgroundColor}",
        textColor: "${textColor}",
        btnColor: "${btnColor}",
        btnTextColor: "${btnTextColor}",
        widgetPositionLeft: ${widgetPositionLeft},
        excludeWidgetLibraryBtn: ${excludeWidgetLibraryBtn},
        ctaLabel: "${ctaLabel}",
        minimizedWidgetTitle: "${minimizedWidgetTitle}",
        widgetBottomMargin: ${bottomMargin},
        widgetSideMargin: ${sideMargin},
        widgetPositionTop: ${widgetPositionTop},
        widgetProcentBasedMargin: ${widgetProcentBasedMargin},
        enableHideWidget: ${enableHideWidget},
        widgetBottomMarginMobile: ${bottomMarginMobile},
        widgetSideMarginMobile: ${sideMarginMobile},
        widgetPositionTopMobile: ${widgetPositionTopMobile},
        widgetProcentBasedMarginMobile: ${widgetProcentBasedMarginMobile},
        widgetPositionLeftMobile: ${widgetPositionLeftMobile},
        separateDesktopMobile: ${separateDesktopMobile},
        widgetHideDialogTitle: ${widgetHideDialogTitle},
        widgetHideDialogTitleMobile: ${widgetHideDialogTitleMobile},
      }`;
      const customTexts = `{
        uploadConsent: "${uploadConsentText}",
        soMeConsent: "${soMeConsentText}",
      }`;
      updateOrgSettingsStyle({
        backOfficeClient,
        encodedOrgId: basicOrgData.encodedId,
        style,
      })
        .then((r) => {
          setInfo(translations.savedSuccess[lang]);
        })
        .catch((e) => {
          setError(translations.savedError[lang]);
        });
    } else {
      alert('Correct the errors before saving');
    }
  };
  const validateSetColor = (color, callbackName) => {
    eval(`${callbackName}`)(color);
    const isValid = /^#[0-9A-F]{6}$/i.test(color);
    if (!isValid) {
      eval(`${callbackName}Valid(false)`);
    } else {
      eval(`${callbackName}Valid(true)`);
    }
  };

  const validateMarginRange = (number, min, max, callbackName) => {
    eval(`${callbackName}`)(number);
    const isValid = number && number >= min && number <= max;
    if (!isValid) {
      eval(`${callbackName}Valid(false)`);
    } else {
      eval(`${callbackName}Valid(true)`);
    }
  };

  const validateTextLength = (text, max, callbackName) => {
    eval(`${callbackName}`)(text);
    const isValid = text?.length <= max;
    if (!isValid) {
      eval(`${callbackName}Valid(false)`);
    } else {
      eval(`${callbackName}Valid(true)`);
    }
  };

  if (userLoading) return <LoadingIndicator />;

  const infoMsg = <div className="info-pop">{info}</div>;
  const errorMsg = <div className="error-pop">{error}</div>;

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
      <Grid item>
        <Container maxWidth={separateDesktopMobile ? '' : 'md'}>
          <div className="inner styling">
            {infoMsg}
            {errorMsg}

            <div className="btn-row" style={{ maxWidth: '1300px' }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={saveChanges}
                disabled={
                  !backgroundColorValid ||
                  !textColorValid ||
                  !btnColorValid ||
                  !btnTextColorValid ||
                  !bottomMarginValid ||
                  !sideMarginValid ||
                  !ctaLabelValid ||
                  !isEdit
                }
              >
                {translationsSettings.styling.save[lang]}
              </Button>
            </div>

            <div style={{ maxWidth: 'unset' }}>
              <Typography variant="titleLarge">
                {translationsSettings.styling.widgetStyling[lang]}
              </Typography>

              <label
                className={`${backgroundColorValid ? '' : 'error'} color-select-label`}
                style={{ marginTop: '16px' }}
              >
                <span className="color-block" style={{ backgroundColor }} />
                <Typography variant="bodyLarge">
                  {translationsSettings.styling.backColor[lang]}
                </Typography>
                <Typography className="error-msg" variant="bodySmall" color="error">
                  {backgroundColorValid ? null : 'Incorrect color format. Example: #000000'}
                </Typography>
                <input
                  type="text"
                  value={backgroundColor}
                  onChange={(e) => validateSetColor(e.target.value, 'setBackgroundColor')}
                />
              </label>
              <label className={`${textColorValid ? '' : 'error'} color-select-label`}>
                <span className="color-block" style={{ backgroundColor: textColor }} />
                <Typography variant="bodyLarge">
                  {translationsSettings.styling.textColor[lang]}
                </Typography>
                <Typography className="error-msg" variant="bodySmall" color="error">
                  {textColorValid ? null : 'Incorrect color format. Example: #000000'}
                </Typography>

                <input
                  type="text"
                  value={textColor}
                  onChange={(e) => validateSetColor(e.target.value, 'setTextColor')}
                />
              </label>
              <label className={`${btnColorValid ? '' : 'error'} color-select-label`}>
                <span className="color-block" style={{ backgroundColor: btnColor }} />
                <Typography variant="bodyLarge">
                  {translationsSettings.styling.accentBackColor[lang]}
                </Typography>
                <Typography className="error-msg" variant="bodySmall" color="error">
                  {btnColorValid ? null : 'Incorrect color format. Example: #000000'}
                </Typography>
                <input
                  type="text"
                  value={btnColor}
                  onChange={(e) => validateSetColor(e.target.value, 'setBtnColor')}
                />
              </label>
              <label className={`${btnTextColorValid ? '' : 'error'} color-select-label`}>
                <span className="color-block" style={{ backgroundColor: btnTextColor }} />
                <Typography variant="bodyLarge">
                  {translationsSettings.styling.accentTextColor[lang]}
                </Typography>
                <Typography className="error-msg" variant="bodySmall" color="error">
                  {btnTextColorValid ? null : 'Incorrect color format. Example: #000000'}
                </Typography>

                <input
                  type="text"
                  value={btnTextColor}
                  onChange={(e) => validateSetColor(e.target.value, 'setBtnTextColor')}
                />
              </label>
              <label className={`${ctaLabelValid ? '' : 'error'}`}>
                <span className="color-block" style={{ backgroundColor: textColor }} />
                <Typography variant="bodyLarge">
                  {translationsSettings.styling.customCTLLabel[lang]}
                </Typography>
                <Typography className="error-msg" variant="bodySmall" color="error">
                  {ctaLabelValid
                    ? null
                    : translationsSettings.styling.customCTLLabelError[lang]({
                        maxLength: ctaLabelMaxLength,
                      })}
                </Typography>
                <input
                  type="text"
                  value={ctaLabel}
                  onChange={(e) =>
                    validateTextLength(e.target.value, ctaLabelMaxLength, 'setCtaLabel')
                  }
                />
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={excludeWidgetLibraryBtn}
                  onChange={() => setExcludeWidgetLibraryBtn(!excludeWidgetLibraryBtn)}
                />
                <Typography variant="bodyLarge">
                  {translationsSettings.styling.hideCTA[lang]}
                </Typography>
              </label>

              {hasAdvancedWidgetBehavior && (
                <label>
                  <input
                    type="checkbox"
                    checked={enableHideWidget}
                    onChange={() => setEnableHideWidget(!enableHideWidget)}
                  />
                  <Typography variant="bodyLarge">
                    {translationsSettings.styling.enableHideWidget[lang]}
                  </Typography>
                </label>
              )}

              <Divider variant="fullWidth" sx={{ borderBottomWidth: '1px', margin: '15px' }} />
              <Typography variant="titleLarge">
                {translationsSettings.styling.widgetPlacement[lang]}
              </Typography>
              {hasAdvancedWidgetStylingMobile && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      checked={separateDesktopMobile}
                      onChange={() => setSeparateDesktopMobile(!separateDesktopMobile)}
                    />
                    <Typography variant="bodyLarge">
                      {translationsSettings.styling.separateDesktopMobilePlacement[lang]}
                    </Typography>
                  </label>
                  <Divider variant="fullWidth" sx={{ borderBottomWidth: '1px', margin: '15px' }} />
                </>
              )}

              <Grid container spacing={2}>
                <Grid item>
                  {hasAdvancedWidgetStylingMobile && separateDesktopMobile && (
                    <Typography variant="titleMedium">
                      {translationsSettings.styling.desktop[lang]}
                    </Typography>
                  )}
                  <label>
                    <input
                      type="checkbox"
                      checked={widgetPositionLeft}
                      onChange={() => setWidgetPositionLeft(!widgetPositionLeft)}
                    />
                    <Typography variant="bodyLarge">
                      {translationsSettings.styling.widgetPlacementLabel[lang]}
                    </Typography>
                  </label>

                  {hasAdvancedWidgetStyling && (
                    <>
                      <label>
                        <input
                          type="checkbox"
                          checked={widgetPositionTop}
                          onChange={() => setWidgetPositionTop(!widgetPositionTop)}
                        />
                        <Typography variant="bodyLarge">
                          {translationsSettings.styling.widgetPlacementLabelTop[lang]}
                        </Typography>
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          checked={widgetProcentBasedMargin}
                          onChange={() => setWidgetProcentBasedMargin(!widgetProcentBasedMargin)}
                        />
                        <Typography variant="bodyLarge">
                          {translationsSettings.styling.widgetBasedMarginLabel[lang]}
                        </Typography>
                      </label>

                      <label className={`${bottomMarginValid ? '' : 'error'}`}>
                        <span className="color-block" style={{ backgroundColor: textColor }} />
                        <Typography variant="bodyLarge">
                          {translationsSettings.styling.widgetTopMarginLabel[lang]({
                            placement: widgetPositionTop
                              ? translationsSettings.styling.top[lang]
                              : translationsSettings.styling.bottom[lang],
                            marginType: widgetProcentBasedMargin ? '%' : 'px',
                          })}
                        </Typography>
                        <Typography className="error-msg" color="error" variant="bodySmall">
                          {bottomMarginValid
                            ? null
                            : `${translationsSettings.styling.widgetBottomMarginError[lang]} ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`}
                        </Typography>

                        <input
                          type="number"
                          value={bottomMargin}
                          placeholder={`${translationsSettings.styling.default[lang]}: ${defaultBottomMargin}, ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`}
                          onChange={(e) =>
                            validateMarginRange(
                              e.target.value,
                              bottomMarginMin,
                              bottomMarginMax,
                              'setBottomMargin',
                            )
                          }
                          min={bottomMarginMin}
                          max={bottomMarginMax}
                        />
                      </label>
                      <label className={`${sideMarginValid ? '' : 'error'}`}>
                        <span className="color-block" style={{ backgroundColor: textColor }} />
                        <Typography variant="bodyLarge">
                          {translationsSettings.styling.widgetSideMarginLabel[lang]({
                            placement: widgetPositionLeft
                              ? translationsSettings.styling.left[lang]
                              : translationsSettings.styling.right[lang],
                            marginType: widgetProcentBasedMargin ? '%' : 'px',
                          })}
                        </Typography>
                        <Typography className="error-msg" color="error" variant="bodySmall">
                          {sideMarginValid
                            ? null
                            : `${translationsSettings.styling.widgetSideMarginError[lang]} ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`}
                        </Typography>

                        <input
                          type="number"
                          value={sideMargin}
                          placeholder={`${translationsSettings.styling.default[lang]}: ${defaultSideMargin}, ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`}
                          onChange={(e) =>
                            validateMarginRange(
                              e.target.value,
                              sideMarginMin,
                              sideMarginMax,
                              'setSideMargin',
                            )
                          }
                          min={sideMarginMin}
                          max={sideMarginMax}
                        />
                      </label>
                      {hasAdvStyleVidTitleDialog && (
                        <>
                          <Typography variant="titleMedium">
                            {translationsSettings.styling.customWidgetStyling[lang]}
                          </Typography>
                          <label htmlFor="widgetHideDialogTitle">
                            <input
                              id="widgetHideDialogTitle"
                              name="widgetHideDialogTitle"
                              type="checkbox"
                              checked={widgetHideDialogTitle}
                              onChange={() => setWidgetHideDialogTitle(!widgetHideDialogTitle)}
                            />
                            <Typography variant="bodyLarge">
                              {translationsSettings.styling.hideDialogTitle[lang]}
                            </Typography>
                          </label>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                {hasAdvancedWidgetStylingMobile && separateDesktopMobile && (
                  <>
                    <Grid item>
                      <Divider variant="fullWidth" orientation="vertical" sx={{ margin: '15px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="titleMedium">
                        {translationsSettings.styling.mobile[lang]}
                      </Typography>
                      <label>
                        <input
                          type="checkbox"
                          checked={widgetPositionLeftMobile}
                          onChange={() => setWidgetPositionLeftMobile(!widgetPositionLeftMobile)}
                        />
                        <Typography variant="bodyLarge">
                          {translationsSettings.styling.widgetPlacementLabel[lang]}
                        </Typography>
                      </label>
                      {hasAdvancedWidgetStyling && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              checked={widgetPositionTopMobile}
                              onChange={() => setWidgetPositionTopMobile(!widgetPositionTopMobile)}
                            />
                            <Typography variant="bodyLarge">
                              {translationsSettings.styling.widgetPlacementLabelTop[lang]}
                            </Typography>
                          </label>

                          <label>
                            <input
                              type="checkbox"
                              checked={widgetProcentBasedMarginMobile}
                              onChange={() =>
                                setWidgetProcentBasedMarginMobile(!widgetProcentBasedMarginMobile)
                              }
                            />
                            <Typography variant="bodyLarge">
                              {translationsSettings.styling.widgetBasedMarginLabel[lang]}
                            </Typography>
                          </label>

                          <label className={`${bottomMarginValidMobile ? '' : 'error'}`}>
                            <span className="color-block" style={{ backgroundColor: textColor }} />
                            <Typography variant="bodyLarge">
                              {translationsSettings.styling.widgetTopMarginLabel[lang]({
                                placement: widgetPositionTopMobile
                                  ? translationsSettings.styling.top[lang]
                                  : translationsSettings.styling.bottom[lang],
                                marginType: widgetProcentBasedMarginMobile ? '%' : 'px',
                              })}
                            </Typography>
                            <Typography className="error-msg" color="error" variant="bodySmall">
                              {bottomMarginValidMobile
                                ? null
                                : `${translationsSettings.styling.widgetBottomMarginError[lang]} ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`}
                            </Typography>

                            <input
                              type="number"
                              value={bottomMarginMobile}
                              placeholder={`${translationsSettings.styling.default[lang]}: ${defaultBottomMargin}, ${translationsSettings.styling.range[lang]} ${bottomMarginMin}-${bottomMarginMax}`}
                              onChange={(e) =>
                                validateMarginRange(
                                  e.target.value,
                                  bottomMarginMin,
                                  bottomMarginMax,
                                  'setBottomMarginMobile',
                                )
                              }
                              min={bottomMarginMin}
                              max={bottomMarginMax}
                            />
                          </label>
                          <label className={`${sideMarginValidMobile ? '' : 'error'}`}>
                            <span className="color-block" style={{ backgroundColor: textColor }} />
                            <Typography variant="bodyLarge">
                              {translationsSettings.styling.widgetSideMarginLabel[lang]({
                                placement: widgetPositionLeftMobile
                                  ? translationsSettings.styling.left[lang]
                                  : translationsSettings.styling.right[lang],
                                marginType: widgetProcentBasedMarginMobile ? '%' : 'px',
                              })}
                            </Typography>
                            <Typography className="error-msg" color="error" variant="bodySmall">
                              {sideMarginValidMobile
                                ? null
                                : `${translationsSettings.styling.widgetSideMarginError[lang]} ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`}
                            </Typography>

                            <input
                              type="number"
                              value={sideMarginMobile}
                              placeholder={`${translationsSettings.styling.default[lang]}: ${defaultSideMargin}, ${translationsSettings.styling.range[lang]} ${sideMarginMin}-${sideMarginMax}`}
                              onChange={(e) =>
                                validateMarginRange(
                                  e.target.value,
                                  sideMarginMin,
                                  sideMarginMax,
                                  'setSideMarginMobile',
                                )
                              }
                              min={sideMarginMin}
                              max={sideMarginMax}
                            />
                          </label>
                          {hasAdvStyleVidTitleDialog && (
                            <>
                              <Typography variant="titleMedium">
                                {translationsSettings.styling.customWidgetStyling[lang]}
                              </Typography>
                              <label htmlFor="widgetHideDialogTitleMobile">
                                <input
                                  id="widgetHideDialogTitleMobile"
                                  name="widgetHideDialogTitleMobile"
                                  type="checkbox"
                                  checked={widgetHideDialogTitleMobile}
                                  onChange={() =>
                                    setWidgetHideDialogTitleMobile(!widgetHideDialogTitleMobile)
                                  }
                                />
                                <Typography variant="bodyLarge">
                                  {translationsSettings.styling.hideDialogTitle[lang]}
                                </Typography>
                              </label>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>

              <Divider style={{ margin: '16px 0px' }} />
              <Typography variant="titleLarge">
                {translationsSettings.styling.logo[lang]}
              </Typography>
              <div>
                {logo && <img style={{ backgroundColor }} src={logo} width={100} alt="" />}
                {showUpload ? (
                  <div style={{ paddingBottom: '20px' }}>
                    <label style={{ backgroundColor, padding: '5px' }}>
                      <Typography variant="bodyLarge">
                        {logo
                          ? translations.backOffice['Change logo'][lang]
                          : translations.backOffice['Upload logo'][lang]}
                      </Typography>
                      <input
                        type="file"
                        onChange={onUploadImg}
                        accept="image/png, image/jpeg"
                        style={{ margin: '8px' }}
                      />
                    </label>
                    <Button color="secondary" disabled={!logoFile} onClick={saveLogo}>
                      {translations.backOffice['Upload logo'][lang]}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button color="secondary" onClick={() => setShowUpload(true)}>
                      {translations.backOffice['Change logo'][lang]}
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className="btn-row" style={{ maxWidth: '1300px' }}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={saveChanges}
                disabled={
                  !backgroundColorValid ||
                  !textColorValid ||
                  !btnColorValid ||
                  !btnTextColorValid ||
                  !bottomMarginValid ||
                  !sideMarginValid ||
                  !ctaLabelValid ||
                  !isEdit
                }
              >
                {translationsSettings.styling.save[lang]}
              </Button>
            </div>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}
