import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { StyleDialog } from '../../../common/SimpleStyledDialog/StyleDialog';
import AlertDialog from '../../../common/AlertDialog';
import { useQuestionsActions } from './Actions';
import { translations } from '../../../../utils/translationsSettings';

export default function CategoryDialog(props) {
  const lang = useLanguage();
  const questionsActions = useQuestionsActions();
  const { basicOrgData } = useOutletContext();
  const { open, onClose, onCreate, editCategory, categories } = props;
  const [category, setCategory] = useState(editCategory?.title || '');

  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);

  const hasChanges = () => {
    const categoryTrimmed = category?.trim();
    if (editCategory) {
      return editCategory.title ? categoryTrimmed !== editCategory.title : categoryTrimmed !== '';
    }
    return categoryTrimmed !== '';
  };

  const handleClose = () => {
    if (hasChanges()) {
      setSimpleAlertDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleDelete = () => {
    setSimpleAlertDialogOpenDelete(false);
    questionsActions
      .deleteCategory({
        id: editCategory.id,
        orgId: basicOrgData.id,
        encodedOrgId: basicOrgData.encodedId,
      })
      .then((response) => {
        onClose(response.data.deleteOrgCategory);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleCreate = () => {
    if (category !== '') {
      questionsActions
        .createCategory({
          encodedOrgId: basicOrgData.encodedId,
          title: category?.trim(),
          orgId: basicOrgData.id,
        })
        .then((response) => {
          onCreate(response.data.createOrgCategory);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const handleUpdateQuestion = () => {
    if (editCategory) {
      questionsActions
        .updateCategory({
          encodedOrgId: basicOrgData.encodedId,
          title: category?.trim(),
          orgId: basicOrgData.id,
          id: editCategory.id,
        })
        .then((response) => {
          onClose(response.data.updateOrgCategory);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  let dialogTitle = translations.category.addCategory[lang];

  if (editCategory) {
    dialogTitle = translations.category.editCategory[lang];
  }

  return (
    <>
      <AlertDialog
        open={simpleAlertDialogOpen}
        title={translations.closeBeforeSave[lang]}
        description={translations.areYouSureClose[lang]}
        confirmBtnText={translations.confirm[lang]}
        cancelBtnText={translations.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogOpen(false);
          onClose();
        }}
        onCancel={() => {
          setSimpleAlertDialogOpen(false);
        }}
      />
      <AlertDialog
        open={simpleAlertDialogOpenDelete}
        title={translations.category.deleteTitle[lang]}
        description={translations.category.deleteDescription[lang]}
        subDescription={translations.category.deleteSubDescription[lang]}
        confirmBtnText={translations.category.deleteConfirmBtnText[lang]}
        cancelBtnText={translations.category.deleteCancelBtnText[lang]}
        onConfirm={() => {
          handleDelete();
          setSimpleAlertDialogOpenDelete(false);
        }}
        onCancel={() => {
          setSimpleAlertDialogOpenDelete(false);
        }}
      />
      <StyleDialog
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            overflowY: 'scroll',
            borderRadius: '20px',
            '& .MuiPaper-rounded': {
              borderRadius: '8px',
            },
          },
          '& .MuiDialogContent-root': {
            minWidth: '600px',
            paddingTop: '00px',
            paddingRight: '40px',
            paddingBottom: '0px',
            paddingLeft: '30px',
            '& .section': {
              paddingTop: '10px',
              paddingRight: '0px',
              paddingBottom: '15px',
              paddingLeft: '0px',
            },
          },
          '& .MuiPaper-root .dialog-footer': {
            textAlign: 'center',
            flex: 'none',
            padding: '8px 16px',
            borderTop: '1px solid #ededee',
            display: 'flex',
            justifyContent: 'flex-end',
            boxSizing: 'border-box',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
          },
        }}
      >
        <DialogTitle>
          {dialogTitle}
          <IconButton
            aria-label={translations.closeAriaLabel[lang]}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={(e) => {
              handleClose();
            }}
          >
            <i className="icon">close</i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <fieldset className="section">
            <label htmlFor="category">
              <Typography variant="titleMedium" className="required-field">
                {translations.category.newCategoryLabel[lang]}
              </Typography>
              <input
                name="category"
                id="category"
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </label>
          </fieldset>
        </DialogContent>
        <div className="dialog-footer">
          <DialogActions
            sx={{
              padding: '0px',
            }}
          >
            {editCategory ? (
              <>
                <Button
                  onClick={() => {
                    setSimpleAlertDialogOpenDelete(true);
                  }}
                >
                  <img src="/images/icons/bin-icon.svg" alt="" />
                  {translations.category.delete[lang]}
                </Button>
                <Button variant="contained" onClick={handleUpdateQuestion}>
                  {translations.category.save[lang]}
                </Button>
              </>
            ) : (
              <>
                <Button variant="outlined" onClick={handleClose}>
                  {translations.cancel[lang]}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCreate}
                  autoFocus
                  disabled={category === ''}
                >
                  {translations.category.createCategory[lang]}
                </Button>
              </>
            )}
          </DialogActions>
        </div>
      </StyleDialog>
    </>
  );
}
