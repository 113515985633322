import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ThemeContext from 'src/utils/ThemeContext';
import { NavLink } from 'react-router-dom';
import PageFooter from '../../public/page-footer';
import LanguageSelector from './LanguageSelector';
import { Organization } from './types';

interface LayoutProps {
  children: React.ReactNode;
  organization?: Organization;
}

function Layout({ children, organization = undefined }: LayoutProps) {
  const theme = useContext(ThemeContext);
  const orgSlugName = organization?.orgNameSlug;

  const navLink = orgSlugName ? `/${orgSlugName}/backoffice` : '/admin';

  return (
    <Box
      style={{ position: 'relative', background: theme.palette.primary.main }}
      data-testid="layout-box"
    >
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh', pt: 3, px: 0, pb: 1, minWidth: '100vw' }}
        data-testid="layout-grid"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
          data-testid="header-grid"
        >
          <Grid
            container
            spacing={3}
            sx={{
              px: 3,
            }}
            data-testid="logo-grid"
          >
            <Grid item lg={6} sm={12} xl={6} xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                data-testid="logo-box"
                sx={{ width: '100%' }}
              >
                <NavLink to={navLink} aria-label="Go to admin page">
                  <img
                    src="/lifeinside_logo_horizontal-326x52.svg"
                    style={{ width: '100%', maxWidth: '184px', height: 'auto' }}
                    alt="Life Inside"
                    data-testid="logo-image"
                  />
                </NavLink>
              </Box>
            </Grid>
            {!orgSlugName && (
              <Grid item lg={6} sm={12} xl={6} xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                  data-testid="language-box"
                >
                  <LanguageSelector />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            md={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            data-testid="children-grid"
            sx={{ p: 3 }}
          >
            {children}
          </Grid>
        </Grid>
        <PageFooter data-testid="page-footer" />
      </Grid>
    </Box>
  );
}

export default Layout;
