import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

export type ValidatePhoneNumberResult =
  | 'VALID'
  | 'INVALID_COUNTRY'
  | 'NOT_A_NUMBER'
  | 'TOO_SHORT'
  | 'TOO_LONG'
  | 'INVALID_LENGTH'
  | 'INVALID'
  | 'NOT_POSSIBLE';

type ValidatePhoneNumberFunc = (phoneNumber: string) => ValidatePhoneNumberResult;
export const validatePhoneNumber: ValidatePhoneNumberFunc = (phoneNumber) => {
  const phoneNumberLengthResult = validatePhoneNumberLength(`+${phoneNumber}`);
  if (phoneNumberLengthResult !== undefined) {
    return phoneNumberLengthResult;
  }
  if (!isPossiblePhoneNumber(`+${phoneNumber}`)) {
    return 'NOT_POSSIBLE';
  }
  if (!isValidPhoneNumber(`+${phoneNumber}`)) {
    return 'INVALID';
  }
  return 'VALID';
};
