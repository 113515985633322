import React, { useContext } from 'react';
import { Stack, Button, Typography, TextField, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

import { useLanguage } from 'src/context/LanguageContext';
import AddOutlinedIcon from '../../common/icons/planner/AddOutlinedIcon';
import UploadOutlinedIcon from '../../common/icons/planner/UploadOutlinedIcon';
import translationsPlanner from '../../../utils/translationsPlanner';
import ThemeContext from '../../../utils/ThemeContext';

function PlannerHeader() {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);

  const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
      border: 'none',
      outline: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
    },
  }));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      <Box>
        <Typography align="center" variant="headlineLargeBoldRecoleta">
          {translationsPlanner.plannerTitle[lang]}
        </Typography>
        <Button sx={{ marginLeft: 4 }}>
          <AddOutlinedIcon />
          {translationsPlanner.videoRequest[lang]}
        </Button>

        <Button sx={{ marginLeft: 4 }}>
          <UploadOutlinedIcon />
          {translationsPlanner.uploadVideo[lang]}
        </Button>
      </Box>
      <StyledTextField
        placeholder={translationsPlanner.search[lang]}
        InputProps={{
          startAdornment: <SearchIcon />,
          style: {
            borderRadius: 50,
            paddingRight: 12,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />
    </Stack>
  );
}

export default PlannerHeader;
