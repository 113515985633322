import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { gql } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import parse from 'html-react-parser';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

import './upload.scss';

import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';
import { gqClient } from '../../utils/graphQL';
import { parseQueryParams } from '../../utils/utils';
import { translations } from '../../utils/translations';
import { backendPostPath, frontendPath, mediaUploadPath } from '../../utils/environment';
import ThemeContext from '../../utils/ThemeContext';
import AppWrapper from './AppWrapper';
import Loader from '../common/Loader';

const StepCircle = styled(Paper)(({ theme }) => ({
  height: 34,
  width: 34,
  padding: '0 7px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.light,
  ...theme.typography.bodyMediumBold,
}));

const getOrganizationData = (encodedOrgId) => {
  return gqClient.query({
    query: gql`
          query {
            organization(encodedOrgId: "${encodedOrgId}"){
              id
              orgName
              orgNameSlug
              encodedId
              lang
              doNotTrack
              promoteRecordVideoPortrait
              customTexts {
                uploadConsent
                soMeConsent {
                  text
                  enable
                  mandatory
                }
              }
              style {
                backgroundColor
                textColor
                btnColor
                btnTextColor
                widgetBottomMargin
                widgetSideMargin
                widgetPositionTop
                widgetProcentBasedMargin
                enableHideWidget
                widgetPositionLeftMobile
                widgetBottomMarginMobile
                widgetSideMarginMobile
                widgetPositionTopMobile
                widgetProcentBasedMarginMobile
                separateDesktopMobile
                widgetHideDialogTitle
                widgetHideDialogTitleMobile
              }
            }
          }
        `,
  });
};
const prepareVideoRequestVideoUpload = (orgId, encodedVideoRequestId, lang, question) => {
  const questionObj = `{
    ${question?.sv ? `sv: "${question.sv}",` : ''}
    ${question?.en ? `en: "${question.en}",` : ''}
    ${question?.questionId ? `questionId: ${question.questionId},` : ''}
    ${question?.videoCardId ? `videoCardId: ${question.videoCardId},` : ''}
    ${question?.answeredTimeStampTz ? `answeredTimeStampTz: "${question.answeredTimeStampTz}"` : ''}
    ${question?.videoRequestId ? `videoRequestId: "${question.videoRequestId}"` : ''}
  }`;
  return gqClient.query({
    query: gql`
        query {
          prepareVideoRequestVideoUpload(input: {encodedVideoRequestId: "${encodedVideoRequestId}", encodedOrgId: "${orgId}", lang: "${lang}", question: ${questionObj}})
        }
    `,
  });
};

const prepareVideoCardVideoUpload = (orgId, encodedVideoCardId, lang) => {
  return gqClient.query({
    query: gql`
        query {
          prepareVideoCardVideoUpload(input: {encodedVideoCardId: "${encodedVideoCardId}", encodedOrgId: "${orgId}", lang: "${lang}"})
        }
    `,
  });
};

const getVideoCardData = (orgId, encodedVideoCardId, lang) => {
  return gqClient.query({
    query: gql`
        query {
          getVideoCardDataBeforeUpload(input: {encodedVideoCardId: "${encodedVideoCardId}", encodedOrgId: "${orgId}", lang: "${lang}"}){
            id
            videoLink {
              sv
              en
            }
            question {
              sv
              en
            }
          }
        }
    `,
  });
};

const getVideoRequestData = (orgId, encodedVideoCardId, lang) => {
  return gqClient.query({
    query: gql`
        query {
          getVideoRequestDataBeforeUpload(input: {encodedVideoRequestId: "${encodedVideoCardId}", encodedOrgId: "${orgId}", lang: "${lang}"}){
            id
            questions {
              sv
              en
              questionId
              videoCardId
              answeredTimeStampTz
            }
          }
        }
    `,
  });
};

const getOrganizationDataFromVideoId = (videoCardId) => {
  return gqClient.query({
    query: gql`
        query {
          getOrganizationDataFromVideoId(encodedVideoCardId: "${videoCardId}"){
              id
              orgName
              orgNameSlug
              encodedId
              lang
              doNotTrack
              customTexts {
                uploadConsent
                soMeConsent {
                  text
                  enable
                  mandatory
                }
              }
              promoteRecordVideoPortrait
              style {
                backgroundColor
                textColor
                btnColor
                btnTextColor
                widgetPositionLeftMobile
                widgetBottomMarginMobile
                widgetSideMarginMobile
                widgetPositionTopMobile
                widgetProcentBasedMarginMobile
                separateDesktopMobile
                widgetHideDialogTitle
                widgetHideDialogTitleMobile
              }
            }
          }
    `,
  });
};
const getOrganizationDataFromVideoRequestId = (videoRequestId) => {
  return gqClient.query({
    query: gql`
        query {
          getOrganizationDataFromVideoRequestId(encodedVideoRequestId: "${videoRequestId}"){
              id
              orgName
              orgNameSlug
              encodedId
              lang
              doNotTrack
              customTexts {
                uploadConsent
                soMeConsent {
                  text
                  enable
                  mandatory
                }
              }
              promoteRecordVideoPortrait
              style {
                backgroundColor
                textColor
                btnColor
                btnTextColor
                widgetPositionLeftMobile
                widgetBottomMarginMobile
                widgetSideMarginMobile
                widgetPositionTopMobile
                widgetProcentBasedMarginMobile
                separateDesktopMobile
                widgetHideDialogTitle
                widgetHideDialogTitleMobile
              }
            }
          }
    `,
  });
};

function Upload(props) {
  const location = useLocation();
  const [orgData, setOrgData] = useState(null);

  const [encodedOrgId, setEncodedOrgId] = useState(null);
  const [videoCardId, setVideoCardId] = useState(null);
  const isVideoRequest = location.pathname.includes('/video/upload/');
  const params = useParams();
  useEffect(() => {
    const { encodedorgid, videocardid } = params;

    if (videocardid?.endsWith('.')) {
      window.history.replaceState(null, null, window.location.pathname?.slice(0, -1));
    }

    setEncodedOrgId(encodedorgid);
    setVideoCardId((videocardid?.endsWith('.') ? videocardid.slice(0, -1) : videocardid).trim());
    if (encodedorgid) {
      getOrganizationData(encodedorgid).then((result) => {
        setOrgData(result.data.organization);
      });
    } else if (isVideoRequest) {
      getOrganizationDataFromVideoRequestId(videocardid)
        .then((res) => {
          setEncodedOrgId(res.data.getOrganizationDataFromVideoRequestId.encodedId);
          setOrgData(res.data.getOrganizationDataFromVideoRequestId);
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      getOrganizationDataFromVideoId(videocardid)
        .then((res) => {
          setEncodedOrgId(res.data.getOrganizationDataFromVideoId.encodedId);
          setOrgData(res.data.getOrganizationDataFromVideoId);
        })
        .catch((e) => {
          alert(e);
        });
    }
  }, []);

  if (!encodedOrgId || !videoCardId || !orgData) return null;
  return (
    <AppWrapper encodedOrgId={encodedOrgId} className="upload">
      <UploadInner
        encodedOrgId={encodedOrgId}
        videoCardId={videoCardId}
        searchQuery={location.search}
        isVideoRequest={isVideoRequest}
        orgData={orgData}
      />
    </AppWrapper>
  );
}

export default Upload;

function UploadInner(props) {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const setLanguage = useSetLanguage();

  const [success, setSuccess] = useState(false);
  const [videoCard, setVideoCard] = useState(null);
  const [videoRequest, setVideoRequest] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [acceptedUploadConsent, setAcceptedUploadConsent] = useState(false);
  const [acceptedSoMeConsent, setAcceptedSoMeConsent] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const { basicOrgData: orgData, videoCardId: encodedVideoCardId, isVideoRequest } = props;

  const moveToFolder = (videoId) => {
    const data = {
      videoId,
      orgId: props.encodedOrgId,
      frontendPath,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return axios.post(`${backendPostPath}/move-video-to-folder`, data, {
      headers,
    });
  };
  function createSessionId(encId) {
    // create sessionId
    const now = new Date();
    const random = Math.floor(Math.random() * 1000000);
    return `${encId}-${now.getTime()}-${random}`;
  }

  useEffect(() => {
    const queryParams = parseQueryParams(props.searchQuery);
    if ('some_consent' in queryParams) {
      setSuccess(true);
    } else {
      // Check if videocard has video already, if not proceed, if yes: abort
      if (!isVideoRequest) {
        getVideoCardData(props.encodedOrgId, encodedVideoCardId, lang)
          .then((response) => {
            setVideoCard(response.data.getVideoCardDataBeforeUpload);
          })
          .catch((e) => {
            // Error: e.message
            alert(translations.upload.noLongerValid[lang]);
            window.location = `${frontendPath}/${props.orgData?.orgNameSlug}`;
          });
      } else {
        getVideoRequestData(props.encodedOrgId, encodedVideoCardId, lang)
          .then((response) => {
            const videoRequestResp = response.data.getVideoRequestDataBeforeUpload;
            if (videoRequestResp?.questions?.length < 2) {
              setSelectedQuestionObject(videoRequestResp.questions[0]);
            }
            setVideoRequest(videoRequestResp);
          })
          .catch((e) => {
            alert(`ERROR: ${e.message}`);
            // window.location = `${frontendPath}/${props.orgData?.orgNameSlug}`;
          });
      }
    }
  }, []);
  const onFileChange = (e) => {
    setFileSelected(true);
    setFileSelected(e.target.value.replace(/.*[\/\\]/, ''));
  };

  const prepareSubmitUpload = (e) => {
    setSubmitStatus('IN_PROGRESS');
    e.preventDefault();
    const form = document.getElementById('upload-form');

    if (isVideoRequest) {
      prepareVideoRequestVideoUpload(
        props.encodedOrgId,
        encodedVideoCardId,
        lang,
        selectedQuestionObject,
      )
        .then((response) => {
          const token = response.data.prepareVideoRequestVideoUpload;
          const form = document.getElementById('upload-form');

          form.action = `${mediaUploadPath}/${
            props.encodedOrgId
          }-${encodedVideoCardId}?token=${encodeURIComponent(
            token,
          )}&some_consent=${acceptedSoMeConsent}&redirect=${encodeURIComponent(
            `${frontendPath}/${props.encodedOrgId}/video/upload/${encodedVideoCardId}?some_consent=${acceptedSoMeConsent}`,
          )}`;
          form.submit();
        })
        .catch((error) => {});
    } else {
      prepareVideoCardVideoUpload(props.encodedOrgId, encodedVideoCardId, lang)
        .then((response) => {
          const token = response.data.prepareVideoCardVideoUpload;
          form.action = `${mediaUploadPath}/${
            props.encodedOrgId
          }-${encodedVideoCardId}?token=${encodeURIComponent(
            token,
          )}&some_consent=${acceptedSoMeConsent}&redirect=${encodeURIComponent(
            `${frontendPath}/${props.encodedOrgId}/upload/${encodedVideoCardId}?some_consent=${acceptedSoMeConsent}`,
          )}`;
          form.submit();
        })
        .catch((error) => {});
    }
  };

  const [selectedQuestionObject, setSelectedQuestionObject] = useState('');
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedQuestionObject(value);
  };

  const multiQuestionSelector = (
    <Select
      labelId="storyline-section-label"
      id="storyline-section-label"
      displayEmpty
      value={selectedQuestionObject}
      onChange={handleChange}
      required
      placeholder={selectedQuestionObject ? 'none' : translations.upload.selectQuestion[lang]}
      renderValue={(selected) => {
        return selected ? selected[lang] : translations.upload.selectQuestion[lang];
      }}
      style={{ width: '100%' }}
    >
      <MenuItem value="">
        <em>none</em>
      </MenuItem>
      {videoRequest?.questions
        ?.filter((question) => !(question?.videoCardId || question?.answeredTimeStampTz))
        .map((stObject, i) => (
          <MenuItem
            key={`mi-${stObject.id}`}
            autoWidth
            value={stObject}
            style={{ width: '560px', whiteSpace: 'normal', wordBreak: 'break-word' }}
          >
            {stObject[lang]}
          </MenuItem>
        ))}
    </Select>
  );
  const questionContent =
    isVideoRequest && videoRequest?.questions?.length > 1
      ? multiQuestionSelector
      : videoRequest?.questions?.[0]?.[lang]
        ? videoRequest?.questions?.[0]?.[lang]
        : videoCard?.question[lang];

  const questionHeader =
    isVideoRequest && videoRequest?.questions?.length > 1
      ? translations.upload.uploadVideoForMultiQuestions[lang]
      : translations.upload.uploadVideoForQuestion[lang];

  let content;

  const steps = [
    {
      index: 1,
      title: translations.upload.step1Title[lang],
      description: orgData?.promoteRecordVideoPortrait
        ? translations.upload.step1[lang]
        : translations.upload.step1NoPortrait[lang],
    },
    {
      index: 2,
      title: translations.upload.step2Title[lang],
      description: translations.upload.step2[lang],
    },
    {
      index: 3,
      title: translations.upload.step3Title[lang],
      description: translations.upload.step3[lang],
    },
  ];

  const inputFileRef = useRef(null);

  const handleIconButtonClick = () => {
    inputFileRef.current.click();
  };

  if (videoCard || videoRequest || success) {
    content = (
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh', pt: 3, px: 3, pb: 1, minWidth: '100vw' }}
      >
        <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid container spacing={1}>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img src="/lifeinside_logo_horizontal-326x52.svg" width="184" alt="Life Inside" />
              </Box>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    color: 'white',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <img src="/globe-icon.svg" alt="Globe" style={{ width: 18, height: 18 }} />
                  <select
                    value={lang}
                    onChange={(e) => setLanguage(e.target.value)}
                    style={{
                      flex: 1,
                      height: '100%',
                      paddingLeft: 8,
                      paddingRight: 32,
                      paddingTop: 6,
                      paddingBottom: 6,
                      backgroundColor: 'transparent',
                      color: 'white',
                      border: 'none',
                      fontSize: 14,
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      appearance: 'none',
                      backgroundImage: 'url(arrow_drop_down_24px.svg)',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right center',
                      backgroundSize: '24px',
                      MozAppearance: 'none',
                      WebkitAppearance: 'none',
                    }}
                  >
                    <option value="en">English</option>
                    <option value="sv">Svenska</option>
                  </select>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            md={12}
            style={{ position: 'relative' }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              sx={{
                border: '0px',
                borderRadius: '16px',
                p: 2,
                pt: 3,
                m: 2,
                maxWidth: '660px',
              }}
              elevation={0}
            >
              {!success && (
                <CardHeader
                  title={questionHeader}
                  component="h1"
                  sx={{
                    pt: 0,
                    pb: 0,
                    mt: 0,
                    mb: 0,
                  }}
                  titleTypographyProps={{
                    variant: 'headlineLargeBoldRecoleta',
                    align: 'center',
                  }}
                />
              )}
              <CardContent sx={{ mb: 0, pb: 0 }}>
                {success ? (
                  <Grid container spacing={2}>
                    <Grid item sx={{ width: '100%' }}>
                      <Typography variant="bodyMediumBold">
                        {translations.upload.thankYouUpload[lang]}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <form
                    method="POST"
                    id="upload-form"
                    action=""
                    encType="multipart/form-data"
                    onSubmit={prepareSubmitUpload}
                  >
                    <Grid container spacing={2}>
                      <Grid item sx={{ width: '100%' }}>
                        <Typography variant="bodyMediumBold">{questionContent}</Typography>
                      </Grid>

                      {/* <Grid item sx={{ width: '100%' }}>
                        <Typography variant="bodyMediumBold">
                          {translations.upload.threeSimpleSteps[lang]}
                        </Typography>
                        <Typography variant="bodySmall">
                          {' '}
                          {translations.upload.threeSimpleSteps2[lang]}
                        </Typography>
                      </Grid> */}

                      {steps.map((step) => (
                        <Grid
                          item
                          sx={{ width: '100%' }}
                          key={`${step.index}-${step.title?.trim()}`}
                        >
                          <Box
                            key={step.index}
                            sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%' }}
                          >
                            <StepCircle elevation={0} theme={theme}>
                              {step.index}
                            </StepCircle>
                            <Box sx={{ flex: '1 1 0' }}>
                              <Typography variant="bodyMediumBold">{step.title}</Typography>
                              <br />
                              <Typography variant="bodyMedium">{step.description}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}

                      <Grid
                        item
                        container
                        sx={{ width: '100%' }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="bodySmall"
                          align="center"
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          {translations.upload.needSomeTips[lang]}
                        </Typography>
                      </Grid>

                      {submitStatus !== 'IN_PROGRESS' && (
                        <>
                          <Grid item sx={{ width: '100%' }}>
                            <label
                              htmlFor="file"
                              className={`upload-area ${fileSelected && 'file-selected'}`}
                              style={{ cursor: 'pointer' }}
                            >
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  p: 4,
                                  bgcolor: theme.palette.divider,
                                  borderRadius: '8px',
                                  overflow: 'hidden',
                                  border: '1px dotted black',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <IconButton
                                  onClick={handleIconButtonClick}
                                  component="label"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                  }}
                                  elevation={0}
                                  disableRipple
                                  disableFocusRipple
                                  disableTouchRipple
                                >
                                  <img src="/images/icons/film-icon.svg" alt="" />
                                </IconButton>
                                {fileSelected ? (
                                  <Typography variant="titleMedium" gutterBottom sx={{ mt: 2 }}>
                                    {translations.upload.greatVideo[lang]}
                                  </Typography>
                                ) : (
                                  <Typography variant="titleMedium" gutterBottom sx={{ mt: 2 }}>
                                    {translations.upload.preStep[lang]}
                                  </Typography>
                                )}
                              </Box>
                            </label>
                          </Grid>
                          <Grid item sx={{ width: '100%' }}>
                            <label className="terms" htmlFor="upload_consent">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="secondary"
                                    id="upload_consent"
                                    type="checkbox"
                                    name="upload_consent"
                                    checked={acceptedUploadConsent}
                                    required
                                    onChange={() => {
                                      setAcceptedUploadConsent(!acceptedUploadConsent);
                                    }}
                                    sx={{
                                      '& + .MuiFormControlLabel-asterisk': {
                                        display: 'none',
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Stack direction="row" alignItems="center" gap={0}>
                                    <Typography variant="bodySmall">
                                      {parse(
                                        (
                                          decodeURIComponent(orgData.customTexts?.uploadConsent) ??
                                          orgData.customTexts?.uploadConsent ??
                                          ''
                                        )
                                          ?.trim()
                                          ?.replace(/\n/g, '<br />'),
                                      )}
                                    </Typography>
                                    <Typography
                                      aria-hidden="true"
                                      variant="titleLarge"
                                      color="error"
                                    >
                                      *
                                    </Typography>
                                  </Stack>
                                }
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 24,
                                  },
                                  '& .MuiFormControlLabel-asterisk': {
                                    color: 'error.main',
                                    display: 'none',
                                  },
                                }}
                              />
                            </label>
                          </Grid>
                          {orgData.customTexts?.soMeConsent?.enable && (
                            <Grid item sx={{ width: '100%' }}>
                              <label className="terms" htmlFor="some_consent">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="secondary"
                                      id="some_consent"
                                      type="checkbox"
                                      name="some_consent"
                                      required={
                                        orgData.customTexts?.soMeConsent?.mandatory ?? false
                                      }
                                      value={acceptedSoMeConsent}
                                      onChange={() => setAcceptedSoMeConsent(!acceptedSoMeConsent)}
                                      sx={{
                                        '& + .MuiFormControlLabel-asterisk': {
                                          display: 'none',
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Stack direction="row" alignItems="center" gap={0}>
                                      <Typography variant="bodySmall">
                                        {parse(
                                          (
                                            decodeURIComponent(
                                              orgData.customTexts?.soMeConsent?.text,
                                            ) ??
                                            orgData.customTexts?.soMeConsent?.text ??
                                            ''
                                          )
                                            ?.trim()
                                            ?.replace(/\n/g, '<br />'),
                                        )}
                                      </Typography>
                                      {(orgData.customTexts?.soMeConsent?.mandatory ?? false) && (
                                        <Typography
                                          aria-hidden="true"
                                          variant="titleLarge"
                                          color="error"
                                        >
                                          *
                                        </Typography>
                                      )}
                                    </Stack>
                                  }
                                  sx={{
                                    '& .MuiSvgIcon-root': {
                                      fontSize: 24,
                                    },
                                    '& .MuiFormControlLabel-asterisk': {
                                      color: 'error.main',
                                      display: 'none',
                                    },
                                  }}
                                />
                              </label>
                            </Grid>
                          )}
                          <Grid item sx={{ width: '100%' }}>
                            <Button
                              type="submit"
                              variant="contained"
                              disabled={
                                submitStatus === 'IN_PROGRESS' ||
                                !acceptedUploadConsent ||
                                !fileSelected
                              }
                              fullWidth
                            >
                              {translations.upload.uploadVideo[lang]}
                            </Button>
                          </Grid>
                        </>
                      )}

                      {submitStatus === 'IN_PROGRESS' && (
                        <>
                          <Grid item sx={{ width: '100%' }}>
                            <Loader />
                          </Grid>
                          <Grid item sx={{ width: '100%' }}>
                            <Typography variant="bodyMediumBold" gutterBottom>
                              {translations.upload.bePatient[lang]}
                            </Typography>
                            <br />
                            <Typography variant="bodySmall">
                              {translations.upload.bePatientInfo[lang]}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <input
                      ref={inputFileRef}
                      onChange={onFileChange}
                      type="file"
                      accept="video/mp4,video/x-m4v,video/*"
                      name="file_data"
                      id="file"
                      required
                      capture={false}
                      style={{ display: 'none' }}
                    />
                    <input
                      type="checkbox"
                      name="upload_consent"
                      value={acceptedUploadConsent}
                      style={{ display: 'none' }}
                    />
                    <input
                      type="checkbox"
                      name="some_consent"
                      value={acceptedSoMeConsent}
                      style={{ display: 'none' }}
                    />
                  </form>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container item lg={12} sm={12} xl={12} xs={12} md={12} spacing={1}>
          <Typography
            variant="bodySmall"
            color="text.light"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            © {new Date().getFullYear()} Life Inside
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <div style={{ position: 'relative', background: theme.palette.primary.main }}>
        <div className="upload-loading">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: 'relative', background: theme.palette.primary.main }}>{content}</div>
  );
}
