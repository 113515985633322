import { ALL_LANGUAGES } from './language';

export default function detectBrowserLanguage(): Language {
  const fallback: Language = 'en';
  const userLanguage = navigator.language;
  if (userLanguage) {
    return ALL_LANGUAGES.find((lang) => userLanguage.includes(lang)) ?? fallback;
  }
  return fallback;
}
