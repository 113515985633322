import { isLanguage } from 'src/utils/language';

// LocalStorageLanguageStore is a wrapper around LocalStorage for storing a
// Language at a specified key.
export default class LocalStorageLanguageStore {
  protected readonly key: string;

  constructor(key: string) {
    this.key = key;
  }

  set(language: Language) {
    try {
      localStorage.setItem(this.key, language);
    } catch (_err) {
      // Empty
    }
  }

  get(): Language | null {
    try {
      const lang = localStorage.getItem(this.key);
      return isLanguage(lang) ? lang : null;
    } catch (_err) {
      return null;
    }
  }

  clear() {
    try {
      localStorage.removeItem(this.key);
    } catch (_err) {
      // Empty
    }
  }
}
