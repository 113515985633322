import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { Typography, useMediaQuery } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import LoadingIndicator from '../../common/LoadingIndicator';
import MediaGrid from './media-grid/MediaGrid';
import ResponsiveVideoUpload, { VideoUploadData } from './ResponsiveVideoUpload';
import { mediaUploadPath } from '../../../utils/environment';
import { VideoCollector } from './types';
import { HideAlertFunc, ShowAlertFunc } from './useAlerts';

const LARGE_SCREEN_QUERY = '(min-width:600px)';

interface VideoCollectorResponse {
  videoCollector: VideoCollector;
}

export const VIDEO_COLLECTOR_QUERY = gql`
  query VideoCollector(
    $encodedOrgId: String!
    $encodedVideoCollectorId: String!
    $password: String
    $previewToken: String
  ) {
    videoCollector(
      encodedOrgId: $encodedOrgId
      encodedVideoCollectorId: $encodedVideoCollectorId
      password: $password
      previewToken: $previewToken
    ) {
      id
      title
      description
      allowVideoLibrary
      allowUserEnteredQuestions
      saveName
      saveNameRequired
      saveTitle
      saveTitleRequired
      saveEmail
      saveEmailRequired
      savePhone
      savePhoneRequired
      encodedVideoCardIds
      videoToken
      organization {
        id
        promoteRecordVideoPortrait
        customTexts {
          uploadConsent
          soMeConsent {
            text
            enable
            mandatory
          }
        }
      }
      questions {
        question
      }
    }
  }
`;

interface VideoCollectorUploadTokenInput {
  question: string;
  actorName: string | null;
  actorTitle: string | null;
  actorEmail: string | null;
  actorPhone: string | null;
  uploadConsent: string;
  soMeConsent: string | null;
}

interface CreateVideoCollectorUploadTokenResponse {
  createVideoCollectorUploadToken: string;
}

const CREATE_VIDEO_COLLECTOR_UPLOAD_TOKEN_MUTATION = gql`
  mutation CreateVideoCollectorUploadToken(
    $encodedOrgId: String!
    $encodedVideoCollectorId: String!
    $password: String
    $input: VideoCollectorUploadTokenInput!
    $reCaptchaToken: String!
    $previewToken: String
  ) {
    createVideoCollectorUploadToken(
      encodedOrgId: $encodedOrgId
      encodedVideoCollectorId: $encodedVideoCollectorId
      password: $password
      input: $input
      reCaptchaToken: $reCaptchaToken
      previewToken: $previewToken
    )
  }
`;

interface PublicVideoCollectorProps {
  encodedOrgId: string;
  encodedVideoCollectorId: string;
  password: string | null;
  previewToken: string | null;
  showAlert: ShowAlertFunc;
  hideAlert: HideAlertFunc;
}

function PublicVideoCollector({
  encodedOrgId,
  encodedVideoCollectorId,
  password,
  previewToken,
  showAlert,
  hideAlert,
}: PublicVideoCollectorProps) {
  const cardRef = useRef(null);
  // Fetch VideoCollector data.
  const { data: videoCollectorData, loading: statusLoading } = useQuery<VideoCollectorResponse>(
    VIDEO_COLLECTOR_QUERY,
    {
      variables: { encodedOrgId, encodedVideoCollectorId, password, previewToken },
    },
  );
  const videoCollector = videoCollectorData?.videoCollector;

  const allowVideoLibrary = videoCollector?.allowVideoLibrary ?? false;
  const encodedVideoCardIds = videoCollector?.encodedVideoCardIds ?? [];
  const hasVideoCards = allowVideoLibrary && encodedVideoCardIds.length > 0;

  const isLargeScreen = useMediaQuery(LARGE_SCREEN_QUERY);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // Upload handling.
  const [createToken] = useMutation<CreateVideoCollectorUploadTokenResponse>(
    CREATE_VIDEO_COLLECTOR_UPLOAD_TOKEN_MUTATION,
  );

  const handleVideoUpload = async (videoUploadData: VideoUploadData): Promise<void> => {
    if (!videoUploadData.reCaptchaToken) {
      throw new Error('reCaptchaToken == null');
    }

    const tokenInput: VideoCollectorUploadTokenInput = {
      question: videoUploadData.question,
      actorName: videoUploadData.actorName,
      actorTitle: videoUploadData.actorTitle,
      actorEmail: videoUploadData.actorEmail,
      actorPhone: videoUploadData.actorPhone,
      uploadConsent: videoUploadData.uploadConsent,
      soMeConsent: videoUploadData.soMeConsent,
    };
    const variables = {
      encodedOrgId,
      encodedVideoCollectorId,
      password,
      input: tokenInput,
      reCaptchaToken: videoUploadData.reCaptchaToken,
      previewToken,
    };

    const { data: tokenData } = await createToken({ variables });
    const uploadToken = tokenData?.createVideoCollectorUploadToken;
    if (uploadToken == null) {
      throw new Error('uploadToken == null');
    }

    // Construct file upload URL with token included
    const uploadUrl = `${mediaUploadPath}/${encodedOrgId}-vc_${encodedVideoCollectorId}?token=${uploadToken}`;

    // Return a promise to handle the async upload and progress tracking
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', uploadUrl, true);

      // Setup progress event listener for tracking upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;

          setUploadProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(); // Resolve the promise on successful upload
        } else {
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Network error occurred during file upload'));
      };

      const body = new FormData();
      body.append('file_data', videoUploadData.videoFile);

      xhr.send(body);
    });
  };

  if (videoCollector == null || statusLoading) {
    return <LoadingIndicator />;
  }

  const getResponsiveDisplayMode = () => {
    if (hasVideoCards) {
      if (isLargeScreen) {
        return 'dialog';
      }
      return 'drawer';
    }
    return 'inline';
  };

  const renderDescription = () => {
    return (
      <Typography
        component="p"
        sx={{ p: 0, m: 0, py: 1, whiteSpace: 'pre-line' }}
        align="center"
        variant="bodyMedium"
      >
        {videoCollector.description}
      </Typography>
    );
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={0}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        lg={12}
        sm={12}
        xl={12}
        xs={12}
        md={12}
        sx={{
          mx: { xl: '24%', lg: '24%', md: '14%', sm: '10%', xs: 1 },
          py: 3,
        }}
      >
        <Grid
          item
          sx={{
            ...(hasVideoCards && {
              width: '100%',
            }),
          }}
        >
          <Box
            ref={cardRef}
            sx={{
              p: { xl: 3, lg: 3, md: 2, sm: 2, xs: 1 },
              borderRadius: '16px',
              backgroundColor: 'background.paper',
            }}
          >
            {videoCollector?.title?.length > 0 && (
              <Typography
                component="h1"
                sx={{
                  p: 0,
                  m: 0,
                  pt: 1,
                }}
                align="center"
                variant="headlineLargeBoldRecoleta"
              >
                {videoCollector.title}
              </Typography>
            )}
            {videoCollector?.description?.length > 0 && renderDescription()}
            {hasVideoCards && (
              <MediaGrid
                encodedVideoCardIds={encodedVideoCardIds}
                encodedOrgId={encodedOrgId}
                encodedVideoCollectorId={encodedVideoCollectorId}
                videoToken={videoCollector.videoToken}
              />
            )}
            <ResponsiveVideoUpload
              containerRef={cardRef}
              videoCollector={videoCollector}
              onVideoUpload={handleVideoUpload}
              displayMode={getResponsiveDisplayMode()}
              uploadProgress={uploadProgress}
              showAlert={showAlert}
              hideAlert={hideAlert}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PublicVideoCollector;
