import { useMemo } from 'react';
import { gql } from '@apollo/client/index';

import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';

import { storylineStatuses } from '../utils/utils';
import { VIDEO_FUNNEL_DETAILS_FRAGMENT } from '../fragments/videoFunnels';
import { END_SCREEN_DETAIL_FRAGMENT } from '../fragments/endScreens';

export class EndScreensApi {
  private backOfficeClient: ApolloClient;

  constructor(backOfficeClient: ApolloClient) {
    this.backOfficeClient = backOfficeClient;
  }

  async createEndScreen({
    encodedOrgId,
    orgId,
    contactForm,
    callToAction,
    endScreenVideoLibrary,
    title,
    endScreenType,
    status,
  }: {
    encodedOrgId: string;
    orgId: number;
    contactForm: unknown;
    callToAction: unknown;
    endScreenVideoLibrary: unknown;
    title: string;
    endScreenType: unknown;
    status: unknown;
  }) {
    try {
      const { data, errors } = await this.backOfficeClient.mutate({
        mutation: gql`
          ${END_SCREEN_DETAIL_FRAGMENT}
          mutation createEndScreen($input: EndScreenInput!) {
            createEndScreen(encodedOrgId: "${encodedOrgId}", input: $input) {
              ...EndScreenDetails
            }
          }`,
        variables: {
          input: {
            orgId,
            status: status || storylineStatuses.EDIT,
            meta: {
              callToAction: callToAction || null,
              contactForm: contactForm || null,
              endScreenVideoLibrary: endScreenVideoLibrary || null,
              endScreenType: endScreenType || null,
              title: title || null,
            },
          },
        },
      });

      if (errors) {
        throw new Error(errors.join(', '));
      }
      return data.createEndScreen;
    } catch (error) {
      // handle error
      throw new Error((error as Error).message);
    }
  }

  getAllEndScreens({
    encodedOrgId,
    orgId,
    statusToRead,
  }: {
    encodedOrgId: string;
    orgId: number;
    statusToRead: unknown;
  }) {
    if (!encodedOrgId || !orgId) {
      throw new Error('Missing arguments');
    }

    return this.backOfficeClient.query({
      query: gql`
        ${END_SCREEN_DETAIL_FRAGMENT}
        query ($encodedOrgId: String!, $orgId: Int!, $statusToRead: [String]) {
          getAllEndScreens(
            encodedOrgId: $encodedOrgId
            orgId: $orgId
            statusToRead: $statusToRead
          ) {
            ...EndScreenDetails
          }
        }
      `,
      variables: {
        encodedOrgId,
        orgId,
        statusToRead,
      },
    });
  }

  async updateEndScreen({
    encodedOrgId,
    orgId,
    id,
    contactForm,
    callToAction,
    title,
    endScreenVideoLibrary,
    status = storylineStatuses.EDIT,
    endScreenType,
    deactivateConfirmed = false,
  }: {
    encodedOrgId: string;
    orgId: number;
    id: number;
    contactForm: unknown;
    callToAction: unknown;
    title: unknown;
    endScreenVideoLibrary: unknown;
    status: unknown;
    endScreenType: unknown;
    deactivateConfirmed: unknown;
  }) {
    try {
      const { data, errors } = await this.backOfficeClient.mutate({
        mutation: gql`
          ${END_SCREEN_DETAIL_FRAGMENT}
          mutation updateEndScreen($input: EndScreenInput!) {
            updateEndScreen(encodedOrgId: "${encodedOrgId}", input: $input) {
              ...EndScreenDetails
            }
          }`,
        variables: {
          input: {
            id,
            orgId,
            status,
            deactivateConfirmed,
            meta: {
              callToAction: callToAction || null,
              contactForm: contactForm || null,
              endScreenVideoLibrary: endScreenVideoLibrary || null,
              title: title || null,
              endScreenType: endScreenType || null,
            },
          },
        },
      });

      if (errors) {
        throw new Error(errors.join(', '));
      }
      return data.updateEndScreen;
    } catch (error) {
      // handle error
      throw new Error((error as Error).message);
    }
  }

  async deleteEndScreen({
    encodedOrgId,
    orgId,
    id,
    deleteConfirmed = false,
  }: {
    encodedOrgId: string;
    orgId: number;
    id: number;
    deleteConfirmed: boolean | null;
  }) {
    try {
      const { data, errors } = await this.backOfficeClient.mutate({
        mutation: gql`
          ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
          mutation deleteEndScreen(
            $encodedOrgId: String!
            $orgId: Int!
            $id: Int!
            $deleteConfirmed: Boolean
          ) {
            deleteEndScreen(
              encodedOrgId: $encodedOrgId
              orgId: $orgId
              id: $id
              deleteConfirmed: $deleteConfirmed
            ) {
              ...VideoFunnelDetails
            }
          }
        `,
        variables: {
          encodedOrgId,
          orgId,
          id,
          deleteConfirmed,
        },
      });

      if (errors) {
        throw new Error(errors.join(', '));
      }
      return data.deleteEndScreen;
    } catch (error) {
      // handle error
      throw new Error((error as Error).message);
    }
  }

  async canInactivateEndScreen({
    encodedOrgId,
    orgId,
    id,
  }: {
    encodedOrgId: string;
    orgId: number;
    id: number;
  }) {
    try {
      const { data, errors } = await this.backOfficeClient.query({
        query: gql`
          ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
          query canInactivateEndScreen($encodedOrgId: String!, $orgId: Int!, $id: Int!) {
            canInactivateEndScreen(encodedOrgId: $encodedOrgId, orgId: $orgId, id: $id) {
              ...VideoFunnelDetails
            }
          }
        `,
        variables: {
          encodedOrgId,
          orgId,
          id,
        },
      });

      if (errors) {
        throw new Error(errors.join(', '));
      }
      return data.canInactivateEndScreen;
    } catch (error) {
      // handle error
      throw new Error((error as Error).message);
    }
  }
}

export const useEndScreensApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new EndScreensApi(backOfficeClient), [backOfficeClient]);
};
