const translationsEndScreen = {
  selected: {
    en: 'Selected',
    sv: 'Vald',
  },
  editSelected: {
    en: 'Edit selected',
    sv: 'Redigera valda',
  },
  setSelected: {
    en: 'Set selected',
    sv: 'Sätt valda',
  },
  selectAllVideos: {
    en: 'Select all',
    sv: 'Välj alla',
  },
  saveSuccess: {
    en: 'End Screen saved successfully',
    sv: 'End Screen sparades',
  },
  createSaveSuccess: {
    en: 'End Screen created successfully',
    sv: 'End Screen skapades',
  },
  deleteSuccess: {
    en: 'End Screen deleted successfully',
    sv: 'End Screen togs bort',
  },
  saveFailed: {
    en: 'Failed to save End Screen, please try again if the problem persists contact support',
    sv: 'Misslyckades med att spara End Screen, försök igen om problemet kvarstår kontakta support',
  },
  deleteFailed: {
    en: 'Failed to delete End Screen, please try again if the problem persists contact support',
    sv: 'Misslyckades med att ta bort End Screen, försök igen om problemet kvarstår kontakta support',
  },
  titleError: {
    en: 'Title is required',
    sv: 'Titel är obligatorisk',
  },

  deleteInVideoFunnel: {
    en: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `I'm sorry, but it seems that there was an error while deleting the End Screen. The End Screen you are trying to delete is currently being used in the following contexts:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `It is used in ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `It is used as ${videoFunnelNodeCount} Video Funnel card(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `It is connected in ${videoFunnelEdgeCount} connection(s) within those Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Deleting the End Screen will break the functionality of the Video Funnels where it is used, and they will not work as expected. Please consider the impact of removing the End Screen before proceeding with the deletion.`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Here are the titles of the Video Funnels where the End Screen is used:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Do you still want to proceed with the deletion of this End Screen?`,
    ],
    sv: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `Tyvärr uppstod ett fel när du försökte ta bort End Screen. End Screen du försöker ta bort används för närvarande i följande sammanhang:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `Den används i ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `Den används som ${videoFunnelNodeCount} Video Funnel kort.` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `Den är ansluten i ${videoFunnelEdgeCount} anslutning(ar) inom dessa Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Att ta bort End Screen kommer att bryta funktionaliteten för de videotunnlar där den används, och de kommer inte att fungera som förväntat. Var vänlig och beakta konsekvenserna av att ta bort End Screen innan du fortsätter med borttagningen.`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Här är titlarna på de Video Funnels där End Screen används:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Vill du fortfarande fortsätta med borttagningen av denna End Screen?`,
    ],
  },
  deactivateInVideoFunnel: {
    en: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `The End Screen you are trying to deactivate is currently being used in the following contexts:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `It is used in ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `It is used as ${videoFunnelNodeCount} Video Funnel card(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `It is connected in ${videoFunnelEdgeCount} connection(s) within those Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Deactivating the End Screen will break the functionality of the Video Funnels where it is used, and they will not work as expected. Please consider the impact of deactivating the End Screen before proceeding.`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Here are the titles of the Video Funnels where the End Screen is used:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Do you still want to proceed with the deactivation of this End Screen?`,
    ],
    sv: ({ videoFunnelNodeCount, videoFunnelEdgeCount, videoFunnelTitles, videoFunnelCount }) => [
      `End Screen du försöker inaktivera används för närvarande i följande sammanhang:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      videoFunnelCount > 0 ? `Den används i ${videoFunnelCount} Video Funnel(s).` : '',
      <br key={_.uniqueId()} />,
      videoFunnelNodeCount > 0 ? `Den används som ${videoFunnelNodeCount} Video Funnel kort.` : '',
      <br key={_.uniqueId()} />,
      videoFunnelEdgeCount > 0
        ? `Den är ansluten i ${videoFunnelEdgeCount} anslutning(ar) inom dessa Video Funnel(s).`
        : '',
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Att inaktivera End Screen kommer att bryta funktionaliteten för de Video Funnels där den används, och de kommer inte att fungera som förväntat. Var vänlig och beakta konsekvenserna av att inaktivera End Screen innan du fortsätter`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      `Här är titlarna på de Video Funnels där End Screen används:`,
      <br key={_.uniqueId()} />,
      <br key={_.uniqueId()} />,
      (videoFunnelTitles?.length > 0 ? videoFunnelTitles : [])?.flatMap((title) => [
        title,
        <br key={_.uniqueId()} />,
      ]),
      <br key={_.uniqueId()} />,
      `Vill du fortfarande fortsätta med inaktiveringen av denna End Screen?`,
    ],
  },

  deactivateConfirmBtnText: {
    en: 'Deactivate!',
    sv: 'Avaktivera!',
  },

  title: {
    en: 'Title',
    sv: 'Titel',
  },
  closeBeforeSave: {
    en: 'Close before save?',
    sv: 'Stäng utan att spara?',
  },
  areYouSureClose: {
    en: 'Are you sure you want to close without saving?',
    sv: 'Är du säker på att du vill stänga utan att spara?',
  },
  confirmCloseWithoutSaving: {
    en: "I'm sure",
    sv: 'Jag är säker',
  },
  noCancelCloseWithoutSaving: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  deleteTitle: {
    en: 'Delete End Screen',
    sv: 'Ta bort End Screen',
  },
  deleteDescription: {
    en: 'Are you sure you want to delete this End Screen?',
    sv: 'Är du säker på att du vill ta bort denna End Screen?',
  },

  confirmDeleteTitle: {
    en: 'Confirm delete End Screen',
    sv: 'Bekräfta borttagning av End Screen',
  },

  confirmDeactivateTitle: {
    en: 'Confirm deactivation End Screen',
    sv: 'Bekräfta inaktivering av End Screen',
  },

  deleteConfirmBtnText: {
    en: 'Delete!',
    sv: 'Ta bort!',
  },
  deleteCancelBtnText: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
  cta: {
    message: {
      en: 'Message',
      sv: 'Meddelande',
    },
    link: {
      en: 'Link',
      sv: 'Länk',
    },
    buttonText: {
      en: 'Button text',
      sv: 'Knapp text',
    },
    buttonTextError: {
      en: 'Button text is required',
      sv: 'Knapp text är obligatorisk',
    },
    buttonTextErrorTooLong: {
      en: 'Button text is too long',
      sv: 'Knapp text är för lång',
    },
    invalidLink: {
      en: 'Invalid link',
      sv: 'Ogiltig länk',
    },
    linkError: {
      en: 'Link is required',
      sv: 'Länk är obligatorisk',
    },
  },
  cf: {
    emailDisable: {
      en: 'Disable confirmation email',
      sv: 'Inaktivera e-postbekräftelse',
    },
    emailSettings: {
      en: 'Email settings',
      sv: 'E-postinställningar',
    },
    buttonText: {
      en: 'Button text',
      sv: 'Text på knapp',
    },
    confirmSubmitMessage: {
      en: 'Confirm submit message',
      sv: 'Bekräftelsemeddelande',
    },
    confirmSubmitMessageDefault: {
      en: 'Thank you for your message!\nWe will get back to you as soon as possible.',
      sv: 'Tack för ditt meddelande!\nVi återkommer till dig så snart som möjligt.',
    },
    confirmSubmitMessageDescription: {
      en: 'Message displayed after submitting the form',
      sv: 'Meddelande som visas efter att formuläret har skickats',
    },
    confirmSubmitMessageStringValidError: {
      en: 'Confirm submit message is required',
      sv: 'Bekräftelsemeddelande är obligatorisk',
    },
    confirmSubmitMessageStringValidErrorTooLong: {
      en: 'Confirm submit message is too long',
      sv: 'Bekräftelsemeddelande är för lång',
    },
    submitBtnTextDefault: {
      en: 'Send',
      sv: 'Skicka',
    },
    submitBtnText: {
      en: 'Submit button text',
      sv: 'Text på skicka knappen',
    },
    submitBtnTextDescription: {
      en: 'Text on the submit button',
      sv: 'Text på skicka knappen',
    },
    email: {
      en: 'E-mail',
      sv: 'E-post',
    },
    emailDescription: {
      en: 'Your email will be forwarded to this address. Please keep in mind that providing an email is necessary, and rest assured that it will not be shown publicly.',
      sv: 'E-postmeddelandet kommer att vidarebefordras till denna adress. Observera att e-postadressen är obligatorisk och inte kommer att visas offentligt.',
    },
    invalidEmail: {
      en: 'Invalid e-mail',
      sv: 'Ogiltig e-post',
    },
    emailError: {
      en: 'E-mail is required',
      sv: 'E-post är obligatorisk',
    },
    submitBtnTextStringValidError: {
      en: 'Submit button text is required',
      sv: 'Text på skicka knappen är obligatorisk',
    },
    submitBtnTextStringValidErrorTooLong: {
      en: 'Submit button text is too long',
      sv: 'Text på skicka knappen är för lång',
    },
    message: {
      en: 'Message',
      sv: 'Meddelande',
    },
    messageDescription: {
      en: 'Message that will be displayed with the form',
      sv: 'Meddelande som kommer att visas med formuläret',
    },
  },
  activate: {
    en: 'Activate',
    sv: 'Aktivera',
  },
  deactivate: {
    en: 'Deactivate',
    sv: 'Avaktivera',
  },
  saveActivateAdd: {
    en: 'Save',
    sv: 'Spara',
  },
  save: {
    en: 'Save',
    sv: 'Spara',
  },
  delete: {
    en: 'Delete',
    sv: 'Ta bort',
  },
  selectEndScreenType: {
    en: 'Type',
    sv: 'Typ',
  },
  selectEndScreen: {
    en: 'Select End Screen',
    sv: 'Välj End Screen',
  },
  callToAction: {
    en: 'Call-To-Action',
    sv: 'Call-To-Action',
  },
  contactForm: {
    en: 'Contact form',
    sv: 'Kontaktformulär',
  },
  endScreenVideoLibrary: {
    en: 'Video library',
    sv: 'Video bibliotek',
  },
  editEndScreen: {
    en: 'Edit End Screen',
    sv: 'Redigera End Screen',
  },
  createEndScreen: {
    en: 'Create End Screen',
    sv: 'Skapa End Screen',
  },
  createEndScreenShort: {
    en: 'End Screen',
    sv: 'End Screen',
  },
  endScreenDescriptionCreateAddActivate: {
    en: 'Create a new end screen and add it to the Video Funnel',
    sv: 'Skapa en ny end screen och lägg till den i Video Funnel',
  },
  cancel: {
    en: 'Cancel',
    sv: 'Avbryt',
  },
};

export default translationsEndScreen;
