import { useState, useEffect } from 'react';
import { Position, Handle, useReactFlow } from 'reactflow';
import { useOutletContext } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useLanguage } from 'src/context/LanguageContext';
import SimpleCardHeader from '../../common/SimpleCardHeader';
import translationsEndScreen from '../../../utils/translationsEndScreen';
import EndScreenInput from '../EndScreen/EndScreenInput';
import connectableFn from './connectableFn';

function VideoFunnelEmptyVideoNode(props) {
  const { data, isConnectable, type, id } = props;
  const isDragging = data?.isDragging ? data?.isDragging : false;
  const isTarget = data?.isTarget ? data?.isTarget : false;

  const { getEdges, getNodes } = useReactFlow();

  const lang = useLanguage();
  const { basicOrgData } = useOutletContext();
  const isVideoLibraryEndScreen = data?.endScreen?.meta?.endScreenType === 'endScreenVideoLibrary';

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    data.expanded = expanded;
  }, [expanded]);

  const hasSourceConnections = data?.id && connectableFn({ id: data.id, direction: 'source' });

  const hasTargetConnections = data?.id && connectableFn({ id: data.id, direction: 'target' });

  const hasSourceConnectionHandelA =
    data?.id && connectableFn({ id: data.id, direction: 'source', sourceHandle: 'a' });

  const isValidConnection = (connection) => {
    if (connection.sourceHandle === 'a' && hasSourceConnectionHandelA) {
      return false;
    }
    const targetNode = getNodes()?.find((node) => node.id === connection.target);
    if (
      !(
        targetNode?.type === 'videoFunnelVideoNode' ||
        targetNode?.type === 'videoFunnelEmptyVideoNode'
      )
    ) {
      return false;
    }

    return (
      connection.target !== connection.source &&
      getEdges()?.filter(
        (edge) => edge.target === connection.target && edge.source === connection.source,
      )?.length === 0
    );
  };

  return (
    <div
      className={`videoFunnel-end-screen-node${hasTargetConnections ? ' hasTarget' : ''}${
        expanded ? ' expanded' : ''
      }`}
      style={{
        transition: 'padding 0.3s, width 0.3s, height 0.3s',
      }}
    >
      <Card
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          background: 'transparent',
          boxShadow: 'unset',
          overflow: 'visible',
        }}
      >
        <SimpleCardHeader
          style={{ paddingBottom: '0px' }}
          title={translationsEndScreen?.[data?.endScreen?.meta?.endScreenType]?.[lang]}
        />
        <CardContent style={{ width: '100%', height: '100%' }}>
          <EndScreenInput
            title={data?.endScreen?.meta?.title}
            setTitle={(value) => {}}
            endScreen={data?.endScreen?.meta?.endScreen}
            setEndScreen={(value) => {}}
            type={data?.endScreen?.meta?.endScreenType}
            disabled
            basicOrgData={basicOrgData}
            orgHasEndScreen
            nodeMode
            expandedInput={expanded}
          />
        </CardContent>

        <Button
          // variant="" color=""
          className="expand-collapse-btn"
          onClick={() => setExpanded(!expanded)}
          sx={{ boxShadow: 2 }}
        >
          {expanded ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
        </Button>
      </Card>

      <Handle
        id="a"
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        isValidConnection={isValidConnection}
      />
    </div>
  );
}

export default VideoFunnelEmptyVideoNode;
