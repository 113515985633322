import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ThemeContext from 'src/utils/ThemeContext';
import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';
import PageFooter from '../page-footer';
import AlertsContainer from './AlertsContainer';
import { AlertsState } from './types';
import { HideAlertFunc } from './useAlerts';

interface LayoutProps {
  children: React.ReactNode;
  alerts: AlertsState;
  hideAlert: HideAlertFunc;
}

function Layout({ children, alerts, hideAlert }: LayoutProps) {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const setLanguage = useSetLanguage();

  return (
    <Box style={{ position: 'relative', background: theme.palette.primary.main }}>
      <Box
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '60vw',
          maxWidth: 600,
          zIndex: 2000,
        }}
      >
        <AlertsContainer alerts={alerts} hideAlert={hideAlert} />
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh', pt: 3, px: 0, pb: 1, minWidth: '100vw' }}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0}
        >
          <Grid
            container
            spacing={3}
            sx={{
              px: 3,
            }}
          >
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img src="/lifeinside_logo_horizontal-326x52.svg" width="184" alt="Life Inside" />
              </Box>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    color: 'white',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <img src="/globe-icon.svg" alt="Globe" style={{ width: 18, height: 18 }} />
                  <select
                    value={lang}
                    onChange={(e) => setLanguage(e.target.value as Language)}
                    style={{
                      flex: 1,
                      height: '100%',
                      paddingLeft: 8,
                      paddingRight: 32,
                      paddingTop: 6,
                      paddingBottom: 6,
                      backgroundColor: 'transparent',
                      color: 'white',
                      border: 'none',
                      fontSize: 14,
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      appearance: 'none',
                      backgroundImage: 'url(arrow_drop_down_24px.svg)',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right center',
                      backgroundSize: '24px',
                      MozAppearance: 'none',
                      WebkitAppearance: 'none',
                    }}
                  >
                    <option value="en">English</option>
                    <option value="sv">Svenska</option>
                  </select>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            md={12}
            style={{ position: 'relative' }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {children}
          </Grid>
        </Grid>
        <PageFooter showReCAPTCHA />
      </Grid>
    </Box>
  );
}

export default Layout;
