import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import VideoFunnelVideoLibrary from '../VideoFunnel/VideoFunnelVideoLibrary';
import { translations } from '../../../utils/translations';
import SimpleTextField from '../../common/SimpleTextField';

export const typeVideoLibrary = 'endScreenVideoLibrary';

export const getEndScreenTypeContentVideoLibraryEndScreen = ({ endScreen, save = false }) => {
  if (
    !(endScreen?.type === typeVideoLibrary || endScreen?.meta?.endScreenType === typeVideoLibrary)
  ) {
    return null;
  }
  if (!endScreen?.id) {
    return {
      videoCardIds: endScreen?.videoCards?.flatMap((vc) => vc.id) ?? [],
      ...(save ? {} : { videoCards: endScreen?.videoCards }),
      btnTextString: endScreen?.btnTextString,
      type: typeVideoLibrary,
    };
  } else {
    return {
      videoCardIds: endScreen?.meta?.endScreen?.videoCards?.flatMap((vc) => vc.id) ?? [],
      ...(save ? {} : { videoCards: endScreen?.meta?.endScreen?.videoCards }),
      btnTextString: endScreen?.meta?.endScreen?.btnTextString,
      type: typeVideoLibrary,
    };
  }
};

export const hasChangesVideoLibraryEndScreen = ({ currentEndScreen, prevEndScreen }) => {
  if (currentEndScreen?.type !== typeVideoLibrary) {
    return false;
  }
  if (prevEndScreen?.type !== typeVideoLibrary) {
    return true;
  }

  if (currentEndScreen?.btnTextString !== prevEndScreen?.btnTextString) {
    return true;
  }

  if (!prevEndScreen) {
    if (currentEndScreen?.videoCards?.length > 0) {
      return true;
    }
  } else {
    if (currentEndScreen?.videoCards?.length !== prevEndScreen?.videoCards?.length) {
      return true;
    } else if (currentEndScreen?.videoCards?.length === prevEndScreen?.videoCards?.length) {
      return !prevEndScreen?.videoCards?.every((pvc) =>
        currentEndScreen?.videoCards?.find((cvc) => cvc.id === pvc.id),
      );
    }
  }
  return false;
};

export const canSaveVideoLibraryEndScreen = ({ endScreen }) => {
  if (endScreen?.type !== typeVideoLibrary) {
    return false;
  }

  if (!endScreen?.btnTextString) {
    return false;
  }

  if (endScreen?.btnTextString?.length > 20) {
    return false;
  }

  if (endScreen?.btnTextString?.length < 1) {
    return false;
  }

  if (!endScreen?.videoCards || endScreen?.videoCards?.length === 0) {
    return false;
  }

  return true;
};

export const canActivateVideoLibraryEndScreen = ({ endScreen }) => {
  if (!canSaveVideoLibraryEndScreen({ endScreen })) {
    return false;
  }
  return true;
};

export default function VideoLibraryEndScreen({
  basicOrgData,
  endScreen,
  setEndScreen,
  disabled,
  editSelected = true,
  nodeMode = false,
  expandedInput = true,
  selectAllVideos,
}) {
  const lang = useLanguage();
  const [selectedVideoCards, setSelectedVideoCards] = useState(endScreen?.videoCards ?? []);
  const [searchField, setSearchField] = useState('');

  const handleSelectVideo = (videos) => {
    setSelectedVideoCards(videos);
    setEndScreen({ ...endScreen, videoCards: videos ?? [] });
  };

  return (
    <Box>
      {!nodeMode && (
        <Box style={{ width: '100%', marginBottom: '16px', display: 'flex' }}>
          <Box
            style={{
              marginLeft: '0px',
              marginRight: 'auto',
              marginBottom: 'auto',
              marginTop: 'auto',
            }}
          >
            <div>
              <Typography variant="titleMedium" className="required-field">
                {translations.allVideos[lang]}
              </Typography>
            </div>
          </Box>
          <Box
            style={{ marginLeft: 'auto', marginRight: '0px' }}
            display="flex"
            flexDirection="column"
          >
            <FormControl sx={{ m: 1, minWidth: 400, marginLeft: 'auto', marginRight: '0px' }}>
              <SimpleTextField
                type="search"
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                placeholder={translations.search[lang]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </Box>
      )}
      <Collapse in={expandedInput} timeout="auto" unmountOnExit>
        <VideoFunnelVideoLibrary
          basicOrgData={basicOrgData}
          selectedVideoCards={selectedVideoCards}
          setSelectedVideoCards={handleSelectVideo}
          searchField={searchField}
          editSelected={editSelected}
          disabled={disabled}
          selectAllVideos={selectAllVideos}
        />
      </Collapse>
    </Box>
  );
}

VideoLibraryEndScreen.propTypes = {
  endScreen: PropTypes.object,
  setEndScreen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  basicOrgData: PropTypes.object.isRequired,
};
