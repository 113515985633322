import { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { pink } from '@mui/material/colors';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';

import AppBar from '@mui/material/AppBar';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import getTheme from './SplitButton/SplitButtonThemes';
import { translationsStoryline } from '../../utils/translationsStoryline';
import { statuses, getDefaultState } from '../../utils/utils';
import { translations } from '../../utils/translations';

function FilterDrawer({
  orgData = {},
  users = [],
  handleUpdateBoard = () => {},
  anchor = '',
  isOpen = false,
  onClose = () => {},
  handleUpdateFilterState = () => {},
  removeFilter = () => {},
  drawerType = '',
  style = {},
  incStatusFilter,
  incSelectLocation,
  incSelectDepartment,
  incSelectTheme,
  incSelectedUser,
  incTagsToFilter,
}) {
  const theme = getTheme('active');
  const lang = useLanguage();
  const backOfficeClient = useBackOfficeApolloClient();
  const [showArchived, setShowArchived] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [selectLocation, setSelectLocation] = useState(
    incSelectLocation?.length > 0 ? incSelectLocation : [],
  );
  const [selectDepartment, setSelectDepartment] = useState(
    incSelectDepartment?.length > 0 ? incSelectDepartment : [],
  );
  const [selectTheme, setSelectTheme] = useState(incSelectTheme?.length > 0 ? incSelectTheme : []);
  const [selectedUser, setSelectedUser] = useState(
    incSelectedUser?.length > 0 ? incSelectedUser : getDefaultState('user', lang),
  );
  const [error, setError] = useState(null);
  const [tagsToFilter, setTagsToFilter] = useState(
    incTagsToFilter?.length > 0 ? incTagsToFilter : [],
  );
  const [isApply, setIsApply] = useState(false);
  const [showAddSection, setShowAddSection] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [confirmPopData, setConfirmPopData] = useState(null);
  const [videoStatusFilter, setVideoStatusFilter] = useState(
    incStatusFilter?.length > 0 ? incStatusFilter : [statuses.LIVE],
  );

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setVideoStatusFilter(value);
  };

  const handleOnClose = (type) => {
    switch (type) {
      case 'Location':
      case 'Plats':
      case 'Avdelning':
      case 'Department':
      case 'Tema':
      case 'Theme':
        setShowAddSection(false);
        setNewTagName('');
        break;
      default:
        break;
    }
  };

  const handleDynamicState = (type) => {
    switch (type) {
      case 'Location':
      case 'Plats':
        return selectLocation;
      case 'Avdelning':
      case 'Department':
        return selectDepartment;
      case 'Tema':
      case 'Theme':
        return selectTheme;
      default:
        return [];
    }
  };

  const handleFilterChange = (event, type) => {
    const {
      target: { value },
    } = event;
    if (value.includes('create_tag')) {
      setShowAddSection(true);
    }
    switch (type) {
      case 'Location':
      case 'Plats':
        setSelectLocation(value);
        handleUpdateFilterState('LOCATION', { location: value });
        break;
      case 'Avdelning':
      case 'Department':
        setSelectDepartment(value);
        handleUpdateFilterState('DEPARTMENT', { department: value });
        break;
      case 'Tema':
      case 'Theme':
        setSelectTheme(value);
        handleUpdateFilterState('THEME', { theme: value });
        break;
      default:
        break;
    }
  };

  const deleteTagConfirm = (event, id) => {
    event.stopPropagation();
    setConfirmPopData({
      title: translations.tag.deleteItem[lang],
      description: translations.tag.areYouSureDelete[lang],
      onCancel: () => setConfirmPopData(null),
      onConfirm: () => deleteTagConfirmed(id),
    });
    handleUpdateFilterState('DELETE_TAG', {
      delTag: {
        title: translations.tag.deleteItem[lang],
        description: translations.tag.areYouSureDelete[lang],
        onCancel: () => setConfirmPopData(null),
        onConfirm: () => deleteTagConfirmed(id),
      },
    });
    onClose();
  };

  const deleteTagConfirmed = (id) => {
    if (!id) {
      setError(translations.savedError[lang]);
    }

    deleteTag(orgData.encodedId, id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        setError(translations.savedError[lang]);
      });
  };

  const deleteTag = (encodedOrgId, id) => {
    return backOfficeClient.mutate({
      mutation: gql`
          mutation {
            deleteTag(encodedOrgId: "${encodedOrgId}", tagId: ${id}) {
              statusMessage
            }
          }
        `,
    });
  };

  const validateNewTagName = (value) => {
    setNewTagName(value);
  };

  const createTag = (catId) => {
    const categoryId = catId;
    if (newTagName) {
      createNewTag(orgData.encodedId, lang, orgData.id, categoryId, newTagName)
        .then((r) => {
          handleUpdateBoard();
          setNewTagName('');
        })
        .catch((e) => {
          setError(translations.savedError[lang]);
        });
    }
  };

  const createNewTag = (encodedOrgId, lang, orgId, categoryId, name) => {
    return backOfficeClient.mutate({
      mutation: gql`
          mutation {
            createTag(encodedOrgId: "${encodedOrgId}", input:{ orgId: ${orgId}, categoryId: ${categoryId}, name: {${lang}: "${name}"}}) {
              id
            }
          }
        `,
    });
  };

  const handleUserSelected = (e) => {
    setSelectedUser(e);
    handleUpdateFilterState('UPDATE_USER', { updateuser: e });
    handleUpdateBoard();
  };

  const toggleArchived = () => {
    setShowArchived(!showArchived);
    handleUpdateFilterState('SHOW_ARCHIVE', { archive: !showArchived });
  };

  const handleApply = () => {
    updateIds(selectLocation, selectDepartment, selectTheme, videoStatusFilter);
    handleUpdateBoard();
    onClose();
  };

  const clearState = () => {
    setDefaultFilters();
    updateIds();
    handleUpdateBoard();
  };

  const setDefaultFilters = () => {
    setSelectLocation([]);
    setSelectDepartment([]);
    setSelectTheme([]);
    setSelectedUser(getDefaultState('user', lang));
    setShowArchived(false);
    setVideoStatusFilter([statuses.LIVE]);
    handleUpdateFilterState('SHOW_ARCHIVE', { archive: false });
  };

  const updateIds = (
    locationData = [],
    departmentData = [],
    themeData = [],
    videoStatusFilterData = [statuses.LIVE],
  ) => {
    const temp = [...locationData, ...departmentData, ...themeData];
    const ids = temp.map((item) => item.id);
    setTagsToFilter(ids);
    handleUpdateFilterState('TAGS_TO_FILTER', {
      tagsToFilter: ids,
      videoStatusFilter: videoStatusFilterData,
    });
  };

  useEffect(() => {
    if (removeFilter) {
      switch (removeFilter.type) {
        case 'location':
          {
            const ids = selectLocation.map((item) => {
              return item.id;
            });
            const filterId = tagsToFilter.filter((id) => !ids.includes(id));
            setTagsToFilter(filterId);
            setSelectLocation([]);
            handleUpdateFilterState('DELETE_LOC_CHIP', {
              location: [],
              tagsToFilter: filterId,
            });
          }
          break;
        case 'department':
          {
            const ids = selectDepartment.map((item) => {
              return item.id;
            });
            const filterId = tagsToFilter.filter((id) => !ids.includes(id));
            setTagsToFilter(filterId);
            setSelectDepartment([]);
            handleUpdateFilterState('DELETE_DEP_CHIP', {
              department: [],
              tagsToFilter: filterId,
            });
          }
          break;
        case 'theme':
          {
            const ids = selectTheme.map((item) => {
              return item.id;
            });
            const filterId = tagsToFilter.filter((id) => !ids.includes(id));
            setTagsToFilter(filterId);
            setSelectTheme([]);
            handleUpdateFilterState('DELETE_THEME_CHIP', {
              theme: [],
              tagsToFilter: filterId,
            });
          }
          break;
        case 'user':
          {
            setSelectedUser(getDefaultState('user', lang));
            handleUpdateFilterState('DELETE_USER_CHIP', {
              updateuser: getDefaultState('user', lang),
            });
          }
          break;
        default:
          break;
      }
    }
  }, [removeFilter]);

  useEffect(() => {
    if (isOpen) {
      const tempLocation = selectLocation.filter((item) => tagsToFilter.includes(item.id));
      const tempDepartment = selectDepartment.filter((item) => tagsToFilter.includes(item.id));
      const tempTheme = selectTheme.filter((item) => tagsToFilter.includes(item.id));

      setSelectLocation(tempLocation);
      setSelectDepartment(tempDepartment);
      setSelectTheme(tempTheme);
      handleUpdateFilterState('LOC_DEP_THE', {
        location: tempLocation,
        department: tempDepartment,
        theme: tempTheme,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      selectLocation?.length > 0 ||
      selectDepartment?.length > 0 ||
      selectTheme?.length > 0 ||
      showArchived !== false ||
      selectedUser?.value !== 'ALL_USERS' ||
      tagsToFilter?.length > 0 ||
      (drawerType === 'storylineCreateEdit' && videoStatusFilter.length > 0)
    ) {
      setIsApply(true);
    } else {
      setIsApply(false);
    }
  }, [selectLocation, selectDepartment, selectTheme, selectedUser, showArchived]);

  useEffect(() => {
    if (users && users.length) {
      const newOptions = _.map(users, (user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      setUserOptions([
        {
          value: 'ALL_USERS',
          label: `${translations.allUsers[lang]}`,
        },
        ...newOptions,
      ]);
    }
  }, [users, lang]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 340,
        }}
        role="presentation"
      >
        <div className="Video-filter-App-bar-and-checkbox">
          <AppBar position="static">
            <Typography
              variant="titleLarge"
              component="div"
              sx={{
                flexGrow: 1,
                p: 2.5,

                color: 'white',
              }}
            >
              {translations.chooseFilters[lang]}
            </Typography>
          </AppBar>
        </div>

        <div className="dropdown-wrapper">
          <div style={{ padding: '24px 24px', paddingBottom: '35px' }}>
            {orgData?.tagCategories
              ?.filter((item) => (['library'].includes(drawerType) ? item.tags.length : true))
              .map((item, index) => {
                return (
                  <div key={index}>
                    <InputLabel id="demo-multiple-checkbox-label">{item.name[lang]}</InputLabel>
                    <Select
                      className="select-wrap"
                      labelId="storyline-section-label"
                      multiple
                      onClose={() => {
                        handleOnClose(item.name[lang]);
                      }}
                      value={handleDynamicState(item.name[lang])}
                      displayEmpty
                      onChange={(event) => {
                        handleFilterChange(event, item.name[lang]);
                      }}
                      input={theme.outlinedInput(false)}
                      renderValue={(selected) => {
                        const renderString = selected
                          ?.filter((item) => item !== 'create_tag')
                          ?.map((st) => st.name[lang]);
                        return selected?.length > 0 ? renderString?.join(', ') : '';
                      }}
                    >
                      {item.tags.map((tagD, index) => {
                        return (
                          <MenuItem key={tagD.id} value={tagD}>
                            <Checkbox
                              sx={
                                ['library'].includes(drawerType) && style?.backgroundColor
                                  ? {
                                      [`&, &.${checkboxClasses.checked}`]: {},
                                    }
                                  : {}
                              }
                              checked={
                                !!handleDynamicState(item.name[lang]).find(
                                  (t1) => t1.id === tagD.id,
                                )
                              }
                            />
                            <ListItemText primary={tagD.name[lang]} />
                            {!['library', 'storylineCreateEdit'].includes(drawerType) && (
                              <IconButton
                                aria-label="clear"
                                size="small"
                                sx={{
                                  '&:hover': { backgroundColor: 'transparent' },
                                }}
                                onClick={(event) => deleteTagConfirm(event, tagD.id)}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            )}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value="create_tag">
                        {!showAddSection &&
                          !['library', 'storylineCreateEdit'].includes(drawerType) && (
                            <div>
                              <Typography
                                variant="labelLarge"
                                sx={{
                                  flexDirection: 'row',
                                  lineHeight: '15px',
                                  letterSpacing: '-0.5px',
                                  marginTop: '10px',
                                  marginBottom: '8px',
                                }}
                              >
                                <Stack
                                  spacing={1}
                                  direction="row"
                                  alignItems="flex-start"
                                  padding="3px"
                                >
                                  <i className="icon">add</i>
                                  <ListItemText primary={translations.addTag[lang]} />
                                </Stack>
                              </Typography>
                            </div>
                          )}
                      </MenuItem>
                      <div className="add-tag-section">
                        {showAddSection && (
                          <div>
                            <Stack
                              spacing={1}
                              alignItems="flex-start"
                              padding="24px"
                              boxShadow="none"
                            >
                              <input
                                className="small"
                                type="text"
                                value={newTagName}
                                onChange={(e) => {
                                  validateNewTagName(e.target.value);
                                }}
                                onClickCapture={stopImmediatePropagation}
                                onKeyDown={(e) => e.stopPropagation()}
                              />

                              <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                startIcon={<AddIcon />}
                                onClick={() => createTag(item.id)}
                              >
                                {translations.createTag[lang]}
                              </Button>
                            </Stack>
                          </div>
                        )}
                      </div>
                    </Select>
                  </div>
                );
              })}
          </div>
        </div>

        <Divider variant="middle" />

        {!['library', 'storylineCreateEdit'].includes(drawerType) && (
          <div className="user-wrapper">
            <div style={{ padding: '24px' }}>
              <InputLabel id="demo-multiple-checkbox-label">
                {translations.selectUser[lang]}
              </InputLabel>
              <Select
                input={theme.outlinedInput(false)}
                style={{ width: '100%', height: '40px' }}
                labelId="storyline-section-label"
                value={selectedUser}
                onChange={(e) => handleUserSelected(e.target.value)}
                renderValue={(selected) => {
                  return selected ? selected?.label : '';
                }}
              >
                {userOptions?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
        )}

        {!['library', 'storylineCreateEdit'].includes(drawerType) && (
          <FormGroup sx={{ flexGrow: 1, pl: 4 }}>
            <FormControlLabel
              sx={{
                '.MuiTypography-root': { fontSize: '16px' },
              }}
              control={
                <Checkbox
                  checked={showArchived}
                  onChange={toggleArchived}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    '&.Mui-checked': {
                      color: pink[600],
                    },
                  }}
                />
              }
              label={translations.showArchived[lang]}
            />
          </FormGroup>
        )}
        {['storylineCreateEdit'].includes(drawerType) && (
          <div style={{ padding: '24px' }}>
            <Select
              labelId="storyline-section-label"
              multiple
              displayEmpty
              value={videoStatusFilter}
              onChange={handleStatusChange}
              input={theme.outlinedInput(false)}
              renderValue={(selected) => {
                const renderString = selected.map((st) => translations.status[st][lang]);
                return selected?.length > 0
                  ? renderString.join(', ')
                  : translationsStoryline.videoStatus[lang];
              }}
              style={{ width: '100%', height: '40px' }}
            >
              {Object.keys(statuses).map((vcStat) => {
                return (
                  <MenuItem key={vcStat} value={vcStat}>
                    <Checkbox checked={videoStatusFilter.indexOf(vcStat) > -1} />
                    {translations.status[vcStat][lang]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}

        <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => handleApply()}
            disabled={!isApply}
          >
            {translations.applyButton[lang]}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => clearState()}
            disabled={!isApply}
          >
            {translations.resetButton[lang]}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default FilterDrawer;
