import PhoneInput from 'react-phone-input-2';

import { useLanguage } from 'src/context/LanguageContext';
import { isValidPhoneNumberInput } from '../../../utils/utils';
import 'react-phone-input-2/lib/bootstrap.css';

function PhoneNumberInput({
  actorPhoneNumber,
  setActorPhoneNumber,
  disabled,
  orgRecipients,
  editRecipient,
}) {
  const lang = useLanguage();

  return (
    <PhoneInput
      enableSearch
      name="actorPhoneNumber"
      id="actorPhoneNumber"
      value={actorPhoneNumber}
      onChange={(phone) => {
        setActorPhoneNumber(phone);
      }}
      priority={{ se: 1 }}
      preferredCountries={['se']}
      country="se"
      inputProps={{
        name: 'actorPhoneNumber',
      }}
      disabled={disabled}
      enableAreaCodeStretch
      isValid={(value) => {
        return isValidPhoneNumberInput({ phoneNumber: value, lang, orgRecipients, editRecipient });
      }}
    />
  );
}

export default PhoneNumberInput;
