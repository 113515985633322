import React, { CSSProperties } from 'react';

const thumbnailStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
};

interface StaticThumbnailProps {
  src: string | undefined;
}

function StaticThumbnail({ src }: StaticThumbnailProps) {
  if (src === undefined) {
    return null;
  }
  return <img src={src} alt="" style={thumbnailStyle} data-testid="static-thumbnail-image" />;
}

export default StaticThumbnail;
