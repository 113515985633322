import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from '../../common/LoadingIndicator';
import ConversationalAvatar from './ConversationalAvatar';
import Layout from './Layout';
import { useAdminUserData, useAvatarSettings } from './dataHooks';

function ConversationalAvatarPage() {
  const navigate = useNavigate();
  const {
    data: adminUserData,
    loading: adminUserLoading,
    error: adminUserError,
  } = useAdminUserData();
  const {
    data: avatarSettingsData,
    loading: avatarSettingsLoading,
    error: avatarSettingsError,
  } = useAvatarSettings();
  const isValidUser = adminUserData?.adminUser?.id != null;

  useEffect(() => {
    if (adminUserError || (!adminUserLoading && !isValidUser)) {
      navigate('/login', { replace: true });
    }
  }, [navigate, isValidUser, adminUserLoading, adminUserError]);

  if (
    adminUserLoading ||
    avatarSettingsLoading ||
    avatarSettingsError ||
    !isValidUser ||
    adminUserError ||
    !avatarSettingsData
  ) {
    return (
      <Layout>
        <LoadingIndicator />
      </Layout>
    );
  }

  return (
    <Layout>
      <ConversationalAvatar
        assistantId={avatarSettingsData?.conversationalAvatarSettings.assistantId}
        avatarName={avatarSettingsData?.conversationalAvatarSettings.avatarName}
        voiceId={avatarSettingsData?.conversationalAvatarSettings.avatarVoiceId}
        avatarPosterUrl={avatarSettingsData?.conversationalAvatarSettings.avatarPosterUrl}
        superAdmin
      />
    </Layout>
  );
}

export default ConversationalAvatarPage;
