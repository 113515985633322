import { useQuery } from '@apollo/client';
import {
  ConversationalAvatarSettingsPosterResult,
  ConversationalAvatarSettingsResult,
  UserResult,
} from './types';
import {
  CONVERSATIONAL_AVATAR_SETTINGS_POSTER_QUERY,
  CONVERSATIONAL_AVATAR_SETTINGS_QUERY,
  USER_QUERY,
} from './queries';

export const useUserData = (encodedOrgId: string) => {
  const { data, loading, error } = useQuery<UserResult>(USER_QUERY, {
    variables: { encodedOrgId },
  });

  return { data, loading, error, isSuperAdmin: data?.me?.superAdmin };
};

export const useAvatarSettings = (encodedOrgId: string, isSuperAdmin: boolean | undefined) => {
  const { data, loading, error } = useQuery<ConversationalAvatarSettingsResult>(
    CONVERSATIONAL_AVATAR_SETTINGS_QUERY,
    {
      variables: { encodedOrgId },
      skip: !isSuperAdmin,
    },
  );

  return { data, loading, error };
};

export const useAvatarPoster = (encodedOrgId: string, isSuperAdmin: boolean | undefined) => {
  const { data, loading, error } = useQuery<ConversationalAvatarSettingsPosterResult>(
    CONVERSATIONAL_AVATAR_SETTINGS_POSTER_QUERY,
    {
      variables: { encodedOrgId },
      skip: isSuperAdmin,
    },
  );

  return { data, loading, error };
};
