import { useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import translationsEndScreen from '../../../utils/translationsEndScreen';
import { typeContactForm } from './ContactForm';
import { typeCallToAction } from './CallToAction';
import { typeVideoLibrary } from './VideoLibraryEndScreen';
import { selectInputField, errorText, errorInputField } from '../../common/SharedStylingSettings';
import SimpleMenuItem from '../../common/SimpleMenuItem';
import SimpleDivider from '../../common/SimpleDivider';

import { EndScreenInputType } from './EndScreenInputType';

const interactiveToolsOptions = (lang) => [
  { value: typeCallToAction, label: translationsEndScreen.callToAction[lang] },
  { value: typeContactForm, label: translationsEndScreen.contactForm[lang] },
  { value: typeVideoLibrary, label: translationsEndScreen.endScreenVideoLibrary[lang] },
];

function EndScreenInput({
  title,
  setTitle,
  endScreen,
  setEndScreen,
  type,
  setType,
  disabled = false,
  orgHasEndScreen = false,
  basicOrgData,
  editSelected = false,
  nodeMode = false,
  expandedInput = true,
  selectAllVideos,
}) {
  const lang = useLanguage();

  const fullScreen = !!(type === typeVideoLibrary && !nodeMode);
  const columns = 17;
  const fullScreenColumnsSmall = 4;
  const fullScreenColumnsLarge = parseInt((columns - fullScreenColumnsSmall) / 2, 10);

  const [btnTextString, setBtnTextString] = useState(endScreen?.btnTextString ?? '');
  const [btnTextStringValid, setBtnTextStringValid] = useState(true);

  const [titleValid, setTitleValid] = useState(true);

  const handleSetTitle = (value) => {
    if (!value?.trim()?.length > 0) {
      setTitleValid(false);
    } else {
      setTitleValid(true);
    }
    setTitle(value);
    setEndScreen({ ...endScreen, title: value });
  };

  const handleSetBtnTextString = (value) => {
    if (!value?.trim()?.length > 0 || value?.trim()?.length > 20) {
      setBtnTextStringValid(false);
    } else {
      setBtnTextStringValid(true);
    }
    setBtnTextString(value);
    setEndScreen({ ...endScreen, btnTextString: value });
  };

  return (
    <fieldset style={{ padding: '0px' }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ paddingBottom: '16px' }}
        columns={columns}
      >
        {!nodeMode && (
          <Grid
            item
            xs={columns}
            sm={columns}
            md={fullScreen ? Math.max(fullScreenColumnsSmall, columns / 2) : columns}
            lg={fullScreen ? Math.max(fullScreenColumnsSmall, columns / 2) : columns}
            xl={fullScreen ? fullScreenColumnsSmall : columns}
          >
            <label htmlFor="type">
              <Typography
                variant="titleMedium"
                className={`${disabled ? '' : 'required-field'}`}
                sx={{ position: 'relative' }}
              >
                {' '}
                {translationsEndScreen.selectEndScreenType[lang]}
              </Typography>
              <Select
                className="select-end-screen"
                name="type"
                id="type"
                value={type}
                onChange={(e) => {
                  if (!e.target.value) {
                    setType('');
                  } else {
                    setType(e.target.value);
                  }
                }}
                placeholder={type ? 'none' : translationsEndScreen.selectEndScreen[lang]}
                disabled={!orgHasEndScreen || disabled}
                style={{ width: type === typeVideoLibrary ? 'auto' : '100%', minWidth: '300px' }}
                displayEmpty
                renderValue={(value) => {
                  return value?.length > 0
                    ? translationsEndScreen?.[value]?.[lang]
                    : translationsEndScreen.selectEndScreen[lang];
                }}
                sx={{
                  ...selectInputField(type),
                }}
              >
                <SimpleMenuItem value="" key={_.uniqueId('empty')} title="" empty />

                {interactiveToolsOptions(lang)?.map((option) => {
                  return (
                    <SimpleMenuItem
                      value={option?.value ?? ''}
                      key={_.uniqueId(option?.value)}
                      title={option.label ?? ''}
                    />
                  );
                })}
              </Select>
            </label>
          </Grid>
        )}
        <Grid
          item
          xs={columns}
          sm={columns}
          md={fullScreen ? Math.max(fullScreenColumnsLarge + 2, columns / 2) : columns}
          lg={fullScreen ? Math.max(fullScreenColumnsLarge + 2, columns / 2) : columns}
          xl={fullScreen ? fullScreenColumnsLarge : columns}
        >
          <TransitionGroup>
            <CSSTransition timeout={500} classNames="item">
              <Collapse timeout={500} in={!!type}>
                <label htmlFor="title">
                  <Typography
                    variant="titleMedium"
                    className={`${disabled ? '' : 'required-field'}`}
                    sx={{ position: 'relative' }}
                  >
                    {translationsEndScreen.title[lang]}
                    <Typography variant="bodySmall" className="error-msg" style={{ ...errorText }}>
                      {titleValid ? null : translationsEndScreen.titleError[lang]}
                    </Typography>
                  </Typography>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => handleSetTitle(e.target.value)}
                    required
                    disabled={disabled}
                    style={{
                      width: type === typeVideoLibrary ? 'auto' : '100%',
                      minWidth: '500px',
                      ...(titleValid ? {} : { ...errorInputField }),
                    }}
                  />
                </label>
              </Collapse>
            </CSSTransition>
          </TransitionGroup>
        </Grid>
        <Grid
          item
          xs={columns}
          sm={columns}
          md={fullScreen ? Math.max(fullScreenColumnsLarge + 2, columns / 2) : columns}
          lg={fullScreen ? Math.max(fullScreenColumnsLarge + 2, columns / 2) : columns}
          xl={fullScreen ? fullScreenColumnsLarge : columns}
        >
          <TransitionGroup>
            <CSSTransition timeout={500} classNames="item">
              <Collapse timeout={500} in={!!type}>
                <label htmlFor="btnTextString">
                  <Typography
                    variant="titleMedium"
                    className={`${disabled ? '' : 'required-field'}`}
                    sx={{ position: 'relative' }}
                  >
                    {translationsEndScreen.cta.buttonText[lang]}
                    <Typography variant="bodySmall" className="error-msg" style={{ ...errorText }}>
                      {btnTextStringValid
                        ? null
                        : btnTextString?.length > 0
                          ? translationsEndScreen.cta.buttonTextErrorTooLong[lang]
                          : translationsEndScreen.cta.buttonTextError[lang]}
                    </Typography>
                  </Typography>
                  <input
                    name="btnTextString"
                    id="btnTextString"
                    type="text"
                    value={btnTextString}
                    onChange={(e) => handleSetBtnTextString(e.target.value)}
                    disabled={disabled}
                    required
                    style={btnTextStringValid ? {} : { ...errorInputField }}
                    maxLength={21}
                  />
                </label>
              </Collapse>
            </CSSTransition>
          </TransitionGroup>
        </Grid>
      </Grid>
      {!!type && <SimpleDivider style={{ marginBottom: '8px' }} />}
      <TransitionGroup>
        <CSSTransition timeout={500} classNames="item">
          <Collapse timeout={500} in={!!type && expandedInput}>
            <EndScreenInputType
              type={type}
              endScreen={endScreen}
              setEndScreen={setEndScreen}
              disabled={disabled}
              basicOrgData={basicOrgData}
              editSelected={editSelected}
              nodeMode={nodeMode}
              expandedInput={expandedInput}
              selectAllVideos={selectAllVideos}
            />
          </Collapse>
        </CSSTransition>
      </TransitionGroup>
    </fieldset>
  );
}

EndScreenInput.propTypes = {
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  endScreen: PropTypes.object,
  setEndScreen: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  basicOrgData: PropTypes.object.isRequired,
  editSelected: PropTypes.bool,
  orgHasEndScreen: PropTypes.bool,
};

export default EndScreenInput;
