import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_FUNNEL_DETAILS_FRAGMENT } from '../fragments/videoFunnels';

export class VideoFunnelsApi {
  private backOfficeClient: ApolloClient;

  constructor(backOfficeClient: ApolloClient) {
    this.backOfficeClient = backOfficeClient;
  }

  createVideoFunnel({
    encodedOrgId,
    title,
    orgId,
    status,
    urlsJsonb,
    startDate,
    endDate,
    videoFunnelNodes,
    videoFunnelEdges,
  }: {
    encodedOrgId: string;
    title: unknown;
    orgId: unknown;
    status: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    urlsJsonb: any;
    startDate: unknown;
    endDate: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoFunnelNodes: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoFunnelEdges: any;
  }) {
    return this.backOfficeClient.mutate({
      mutation: gql`
      ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
      mutation {
        createVideoFunnel(encodedOrgId: "${encodedOrgId}", input: {
          orgId: ${orgId},
          ${status ? `status: "${status}",` : ''}
          ${title ? `title: "${title}",` : ''}
          ${urlsJsonb?.length > 0 ? `urlsJsonb: [${urlsJsonb}],` : ''}
          ${startDate ? `startDate: "${startDate}",` : ''}
          ${endDate ? `endDate: "${endDate}",` : ''}
          ${videoFunnelNodes?.length > 0 ? `videoFunnelNodes: [${videoFunnelNodes}],` : ''}
          ${videoFunnelEdges?.length > 0 ? `videoFunnelEdges: [${videoFunnelEdges}],` : ''}
        }){
            ...VideoFunnelDetails
        }
      }
    `,
    });
  }

  updateVideoFunnel({
    id,
    encodedOrgId,
    title,
    orgId,
    status,
    urlsJsonb,
    startDate,
    endDate,
    videoFunnelNodes,
    videoFunnelEdges,
  }: {
    id: number;
    encodedOrgId: string;
    title: unknown;
    orgId: number;
    status: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    urlsJsonb: any;
    startDate: unknown;
    endDate: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoFunnelNodes: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoFunnelEdges: any;
  }) {
    return this.backOfficeClient.mutate({
      mutation: gql`
              mutation {
                updateVideoFunnel(encodedOrgId: "${encodedOrgId}", input: {
                  id: ${id},
                  orgId: ${orgId},
                  ${status ? `status: "${status}",` : ''}
                  ${title ? `title: "${title}",` : ''}
                  ${urlsJsonb?.length > 0 ? `urlsJsonb: [${urlsJsonb}],` : ''}
                  ${startDate ? `startDate: "${startDate}",` : ''}
                  ${endDate ? `endDate: "${endDate}",` : ''}
                  ${videoFunnelNodes?.length > 0 ? `videoFunnelNodes: [${videoFunnelNodes}],` : ''}
                  ${videoFunnelEdges?.length > 0 ? `videoFunnelEdges: [${videoFunnelEdges}],` : ''}
                }){
                  statusMessage
                }
              }
            `,
    });
  }

  deleteVideoFunnel({
    id,
    encodedOrgId,
    orgId,
  }: {
    id: number;
    encodedOrgId: string;
    orgId: number;
  }) {
    return this.backOfficeClient.mutate({
      mutation: gql`
        mutation DeleteVideoFunnel($encodedOrgId: String!, $input: VideoFunnelInput) {
          deleteVideoFunnel(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: {
          id,
          orgId,
        },
      },
    });
  }

  fetchVideoFunnel({
    id,
    encodedOrgId,
    orgId,
  }: {
    id: number;
    encodedOrgId: string;
    orgId: number;
  }) {
    return this.backOfficeClient.query({
      query: gql`
        ${VIDEO_FUNNEL_DETAILS_FRAGMENT}
        query FetchVideoFunnel($encodedOrgId: String!, $input: VideoFunnelInput) {
          fetchVideoFunnel(encodedOrgId: $encodedOrgId, input: $input) {
            ...VideoFunnelDetails
          }
        }
      `,
      variables: {
        encodedOrgId,
        input: {
          id,
          orgId,
        },
      },
    });
  }
}

export const useVideoFunnelsApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new VideoFunnelsApi(backOfficeClient), [backOfficeClient]);
};
