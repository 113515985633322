import { frontendPath } from 'src/utils/environment';

export const publicPath = ({
  encodedOrgId,
  encodedVideoCollectorId,
}: {
  encodedOrgId: string;
  encodedVideoCollectorId: string;
}) => {
  return new URL(`${frontendPath}/${encodedOrgId}/collect/video/${encodedVideoCollectorId}`);
};

export const publicPathWithPreviewToken = ({
  encodedOrgId,
  encodedVideoCollectorId,
  previewToken,
}: {
  encodedOrgId: string;
  encodedVideoCollectorId: string;
  previewToken: string;
}) => {
  const url = publicPath({ encodedOrgId, encodedVideoCollectorId });
  url.searchParams.append('previewToken', previewToken);
  return url;
};
