import React, { useContext } from 'react';
import ThemeContext from 'src/utils/ThemeContext';

interface EmptyStorylineProps {
  mountainFill?: string;
  circleFill?: string;
  backgroundFill?: string;
  mountainFillInactive?: string;
  circleFillInactive?: string;
  backgroundFillInactive?: string;
  isActive?: boolean;
}

function EmptyStoryline({
  mountainFill = undefined,
  circleFill = undefined,
  backgroundFill = undefined,
  mountainFillInactive = undefined,
  circleFillInactive = undefined,
  backgroundFillInactive = undefined,
  isActive = false,
}: EmptyStorylineProps) {
  const theme = useContext(ThemeContext);

  const mountainColor = () => {
    if (isActive) {
      return mountainFill || theme.palette.secondary.main;
    }
    return mountainFillInactive || theme.palette.divider;
  };

  const circleColor = () => {
    if (isActive) {
      return circleFill || theme.palette.tertiary.main;
    }
    return circleFillInactive || theme.palette.divider;
  };

  const backgroundColor = () => {
    if (isActive) {
      return backgroundFill || theme.palette.divider;
    }
    return backgroundFillInactive || theme.palette.background.default;
  };

  return (
    <svg viewBox="0 0 305 203" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1096_13129)">
        <path d="M0.200012 0L0.200012 203L304.2 203L304.2 0L0.200012 0Z" fill={backgroundColor()} />
        <path
          d="M0.200012 203L108.149 71L172.524 152.636L217.689 110.022L304.2 203H0.200012Z"
          fill={mountainColor()}
        />
        <circle cx="184.2" cy="59" r="25" fill={circleColor()} />
      </g>
      <defs>
        <clipPath id="clip0_1096_13129">
          <rect width="304" height="202.667" fill="white" transform="translate(0.200012)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EmptyStoryline;
