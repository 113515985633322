import React, { useContext, useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import { Card, CardContent, CardHeader, Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import ThemeContext from '../../../../utils/ThemeContext';
import translationsAnalytics from '../../../../utils/translationsAnalytics';

function TopCharts({ data, titleType, icon }) {
  const lang = useLanguage();
  const theme = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const xAxisDomain = [0, 'dataMax'];

  const displayData = useMemo(() => {
    if (data) {
      const processedData = data
        ?.map((dataPoint) => {
          const title = dataPoint?.question?.[lang] || dataPoint?.title || '';
          return {
            dataPoint: title,
            value: dataPoint?.stats?.playback?.impression?.reduce((acc, cur) => acc + cur.count, 0),
          };
        })
        .sort((a, b) => b.value - a.value);

      setLoading(false);
      setError('');
      return processedData;
    }

    return [];
  }, [data, lang]);

  return (
    <Card
      sx={{
        height: '236px',
        borderRadius: '8px',
        position: 'relative',
      }}
      elevation={0}
    >
      {(loading || error) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            flexDirection: 'column',
          }}
        >
          {loading && <CircularProgress size={50} />}
          <Typography variant="titleLarge" gutterBottom sx={{ mt: 2 }}>
            {error}
          </Typography>
        </Box>
      )}
      <CardHeader
        sx={{
          pb: 1,
        }}
        title={translationsAnalytics.topCharts?.[titleType]?.[lang]}
        avatar={icon}
        titleTypographyProps={{
          variant: 'titleLarge',
        }}
      />
      <CardContent sx={{ p: 0, m: 0 }}>
        <ResponsiveContainer height={190}>
          <BarChart
            data={displayData}
            layout="vertical"
            margin={{ top: 5, right: 30, left: -8, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="1 8" />
            <XAxis type="number" domain={xAxisDomain} fontSize={12} />
            <YAxis
              dataKey="dataPoint"
              type="category"
              width={190}
              fontSize={14}
              fontWeight={600}
              tickLine={false}
            />
            <Tooltip
              contentStyle={{
                background: theme.palette.background.paper,
                borderRadius: '10px',
                color: theme.palette.text.main,
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                border: '0.5px solid #D8D8D8',
              }}
              formatter={(count) => [
                count,
                translationsAnalytics.totalImpressionsCard.impressions?.[lang],
              ]}
              labelFormatter={(label) => [label]}
              labelStyle={{
                color: theme.palette.text.main,
              }}
              itemStyle={{
                color: theme.palette.text.main,
              }}
            />
            <Bar dataKey="value" fill={theme.palette.primary.main} barSize={20} barGap={2} />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}

TopCharts.propTypes = {
  icon: PropTypes.element.isRequired,
  titleType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.objectOf(PropTypes.string),
      stats: PropTypes.shape({
        playback: PropTypes.shape({
          impression: PropTypes.arrayOf(
            PropTypes.shape({
              count: PropTypes.number,
            }),
          ),
        }),
      }),
    }),
  ).isRequired,
};

export default TopCharts;
