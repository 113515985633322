import { useState } from 'react';
import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { translations } from '../../../../utils/translations';

const sendContactFormMessage = (
  backOfficeClient,
  orgId,
  fromName,
  fromEmail,
  message,
  adminEmail,
  question,
) => {
  const input = { orgId, question, fromName, fromEmail, message, adminEmail };
  return backOfficeClient.mutate({
    mutation: gql`
      mutation SendContactFormMessageEmailAdmin($input: ContactFromMessageInput) {
        sendContactFormMessageEmailAdmin(input: $input) {
          statusMessage
        }
      }
    `,
    variables: { input },
  });
};

function CallToActionComponent({ textColor, btnColor, btnBgColor, callToAction }) {
  const openInNewTab = () => {
    window.open(callToAction.url, '_blank').focus();
  };

  return (
    <div className="section">
      <Typography variant="titleMedium" style={{ color: textColor }}>
        {callToAction.msg}
      </Typography>

      <Button
        variant="contained"
        style={{
          backgroundColor: btnBgColor,
          background: btnBgColor,
          color: btnColor,
          width: 'auto',
          height: 'auto',
          minWidth: '50px',
          minHeight: '36px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        onClick={openInNewTab}
      >
        {callToAction.btnText}
      </Button>
    </div>
  );
}

function ContactFormComponent({ orgId, textColor, btnColor, btnBgColor, contactForm, question }) {
  const lang = useLanguage();
  const backOfficeClient = useBackOfficeApolloClient();
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  const validateEmail = (textString) => {
    if (
      textString.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g,
      )
    ) {
      return true;
    }
    alert('Email must be valid');
    return false;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return;
    }
    const message = msg.replace(/(?:\r\n|\r|\n)/g, '<br />');
    sendContactFormMessage(
      backOfficeClient,
      orgId,
      name,
      email,
      message,
      contactForm.email,
      question,
    )
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        alert(`Something went wrong ${error.message}`);
      });
  };

  if (submitted) {
    return (
      <div className="thank-you-msg-container">
        <Typography variant="titleMedium" style={{ color: textColor }}>
          {translations.videoCard.contactFormThankYou[lang]}
        </Typography>
        <br />
        <Typography variant="titleMedium" style={{ color: textColor }}>
          {translations.videoCard.contactFormThankMsg[lang]}
        </Typography>
      </div>
    );
  }
  return (
    <form className="form-wrapper" onSubmit={handleOnSubmit}>
      <fieldset className="section">
        <Typography variant="titleMedium" style={{ color: textColor }}>
          {translations.videoCard.contactFormContactUs[lang]}
        </Typography>
        <label>
          <input
            type="text"
            placeholder={translations.videoCard.namePlaceholder[lang]}
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            required
          />
        </label>
        <label>
          <input
            type="text"
            placeholder={translations.videoCard.emailPlaceholder[lang]}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            required
          />
        </label>
        <label className="text-area-container">
          <textarea
            placeholder={translations.videoCard.textAreaPlaceholder[lang]}
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            name="textarea"
            className="textarea"
            required
          />
        </label>
        <input
          className="btn action-btn"
          type="submit"
          value={translations.videoCard.contactFormBtnText[lang]}
          style={{
            backgroundColor: btnBgColor,
            background: btnBgColor,
            color: btnColor,
            width: 'auto',
            height: 'auto',
            minWidth: '50px',
            minHeight: '36px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </fieldset>
    </form>
  );
}

function InteractionOptionComponent({
  textColor,
  btnColor,
  btnBgColor,
  interactionOption,
  contactForm,
  callToAction,
  question,
  orgId,
}) {
  if (interactionOption) {
    switch (interactionOption) {
      case 'callToAction': {
        return (
          <CallToActionComponent
            textColor={textColor}
            btnColor={btnColor}
            btnBgColor={btnBgColor}
            interactionOption={interactionOption}
            callToAction={callToAction}
          />
        );
      }
      case 'contactForm': {
        return (
          <ContactFormComponent
            textColor={textColor}
            btnColor={btnColor}
            btnBgColor={btnBgColor}
            interactionOption={interactionOption}
            contactForm={contactForm}
            question={question}
            orgId={orgId}
          />
        );
      }
      default: {
        return null;
      }
    }
  }
  return null;
}

export default function VideoCardOverlay({
  textColor,
  btnColor,
  btnBgColor,
  interactionOption,
  contactForm,
  callToAction,
  question,
  orgId,
}) {
  return (
    <div className="owli-video-card-overlay">
      <div className="owli-video-card-overlay-inner-wrapper">
        <InteractionOptionComponent
          textColor={textColor}
          btnColor={btnColor}
          btnBgColor={btnBgColor}
          interactionOption={interactionOption}
          contactForm={contactForm}
          callToAction={callToAction}
          question={question}
          orgId={orgId}
        />
      </div>
    </div>
  );
}
