export const translations = {
  headerP1: {
    en: 'Suggested ',
    sv: 'Förslagna ',
  },
  headerP2: {
    en: 'Questions',
    sv: 'Frågor',
  },
  question: {
    en: 'Question:',
    sv: 'Fråga:',
  },
  category: {
    en: 'Category: ',
    sv: 'Kategori: ',
  },
  forAll: {
    en: 'For all: ',
    sv: 'För alla: ',
  },
  forTeamMember: {
    en: 'For Team Member: ',
    sv: 'För medarbetare: ',
  },
  teamMember: {
    en: 'TEAM MEMBER',
    sv: 'MEDARBETARE',
  },
  forLeaders: {
    en: 'For Team Leader position: ',
    sv: 'För person i ledarroll: ',
  },
  leader: {
    en: 'LEADER',
    sv: 'LEDARE',
  },
  selectCategory: {
    en: 'Select category:',
    sv: 'Välj kategori:',
  },
  notCategorized: {
    en: 'Not categorized questions',
    sv: 'Ej kategoriserade frågor',
  },
  language: {
    en: 'Language:',
    sv: 'Språk:',
  },
  swedish: {
    en: 'Swedish',
    sv: 'Svenska',
  },
  english: {
    en: 'English',
    sv: 'Engelska',
  },
  none: {
    en: 'none',
    sv: 'none',
  },
  delete: {
    en: 'Delete:',
    sv: 'Delete:',
  },
  addQuestion: {
    en: 'Add question',
    sv: 'Lägg till fråga',
  },
  addCategory: {
    en: 'Add category',
    sv: 'Lägg till kategori',
  },
  verifyAdd: {
    en: 'Are you sure you want to add this? This will affect the end users content immediately.',
    sv: 'Är du säker på att du vill lägga till den här? Detta kommer att påverka slutanvändarnas innehåll omedelbart.',
  },
  addSuggestedQuestionError: {
    en: 'Error adding',
    sv: 'Fel vid tilläggning',
  },
  verifyDelete: {
    en: 'Are you sure you want to delete this question? This will affect the end users content',
    sv: 'Är du säker på att du vill ta bort den här frågan? Detta kommer att påverka slutanvändarnas innehåll',
  },
  deleteSuggestedQuestionError: {
    en: 'Error with delete ',
    sv: 'Fel vid delete',
  },
};

export default translations;
