import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';

import { matchSorter, rankings } from 'match-sorter';

import LazyLoad from 'react-lazy-load';
import VideoCollectorLibraryCard from './VideoCollectorLibraryCard';
import { VideoCollector } from './types';

interface VideoCollectorLibraryProps {
  searchField: string;
  videoCollectors: VideoCollector[];
  onCardClick: (videoCollector: VideoCollector) => void;
}

function VideoCollectorLibrary({
  searchField,
  videoCollectors,
  onCardClick,
}: VideoCollectorLibraryProps) {
  const filteredVideoCollectors = useMemo(() => {
    if (!searchField) {
      return videoCollectors;
    }
    return matchSorter(videoCollectors, searchField, {
      keys: [(vc) => [vc.createdBy.firstName, vc.createdBy.lastName, vc.title]],
      threshold: rankings.CONTAINS,
    });
  }, [searchField, videoCollectors]);

  return (
    <Grid item container spacing={3} role="region" data-testid="video-collector-library">
      {filteredVideoCollectors?.map((videoCollector) => (
        <Grid item xs={12} xl={3} sm={6} md={6} lg={4} key={videoCollector.id}>
          <LazyLoad key={videoCollector.id} offset={300}>
            <VideoCollectorLibraryCard
              videoCollector={videoCollector}
              onClick={(vc) => onCardClick(vc)}
            />
          </LazyLoad>
        </Grid>
      ))}
    </Grid>
  );
}

export default VideoCollectorLibrary;
