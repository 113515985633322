import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

import LoadingIndicator from '../common/LoadingIndicator';

interface UserPermission {
  organization: {
    id: number;
    orgName: string;
  };
}

interface User {
  id: number;
  email: string;
  firstName: string | null;
  lastName: string | null;
  permissions: UserPermission[];
}

interface GetUserResponse {
  user: User;
}

export const GET_USER_QUERY = gql`
  query GetUser($userId: Int!) {
    user(id: $userId) {
      id
      email
      firstName
      lastName
      permissions {
        organization {
          id
          orgName
        }
      }
    }
  }
`;

interface GetOrganizationsRespone {
  organizations: {
    id: number;
    orgName: string;
  }[];
}

export const GET_ORGANIZATIONS_QUERY = gql`
  query Organizations {
    organizations {
      id
      orgName
    }
  }
`;

const ADD_USER_PERMISSION_MUTATION = gql`
  mutation AddUserPermission($userId: Int!, $orgId: Int!) {
    addUserPermission(userId: $userId, orgId: $orgId) {
      statusMessage
    }
  }
`;

const REMOVE_USER_PERMISSION_MUTATION = gql`
  mutation RemoveUserPermission($userId: Int!, $orgId: Int!) {
    removeUserPermission(userId: $userId, orgId: $orgId) {
      statusMessage
    }
  }
`;

function EditUserPermissions() {
  const userId = Number(useParams().userid);

  const [permissionToRemove, setPermissionToRemove] = useState<UserPermission | null>(null);
  const [orgIdPermissionToAdd, setOrgIdPermissionToAdd] = useState<number | null>(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefetch,
  } = useQuery<GetUserResponse>(GET_USER_QUERY, { variables: { userId } });

  const {
    data: organizationsData,
    loading: organizationsLoading,
    error: organizationsError,
  } = useQuery<GetOrganizationsRespone>(GET_ORGANIZATIONS_QUERY);

  const [addUserPermission, { loading: addLoading, error: addError }] = useMutation(
    ADD_USER_PERMISSION_MUTATION,
  );

  const [removeUserPermission, { loading: removeLoading, error: removeError }] = useMutation(
    REMOVE_USER_PERMISSION_MUTATION,
  );

  const user = userData?.user;
  const userOrganizationIds = new Set(user?.permissions.map((up) => up.organization.id) ?? []);

  const organizations = organizationsData?.organizations;

  const removeDialogOpen = permissionToRemove != null;

  const handleOnAddClicked = async () => {
    if (orgIdPermissionToAdd == null) {
      return;
    }
    const orgId = orgIdPermissionToAdd;
    try {
      await addUserPermission({ variables: { userId, orgId } });
      setOrgIdPermissionToAdd(null);
      setAddDialogOpen(false);
      await userRefetch();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleOnRemoveClicked = async () => {
    if (!permissionToRemove) {
      return;
    }
    const orgId = permissionToRemove.organization.id;
    try {
      await removeUserPermission({ variables: { userId, orgId } });
      setPermissionToRemove(null);
      await userRefetch();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  if (userLoading || organizationsLoading) {
    return <LoadingIndicator />;
  }

  if (userError) {
    return (
      <p>
        <Alert severity="error">
          Error fetching user: <code>{userError.message}</code>
        </Alert>
      </p>
    );
  }

  if (organizationsError) {
    return (
      <p>
        <Alert severity="error">
          Error fetching organizations: <code>{organizationsError.message}</code>
        </Alert>
      </p>
    );
  }

  if (user == null || organizations == null) {
    return <p>Not found</p>;
  }

  const organizationAddOptions = organizations
    .filter((org) => {
      return !userOrganizationIds.has(org.id);
    })
    .map((org) => ({
      id: org.id,
      label: org.orgName,
    }));

  const selectedOrganizationAddOption =
    organizationAddOptions.find((opt) => {
      return opt.id === orgIdPermissionToAdd;
    }) ?? null;

  return (
    <>
      <Box data-testid="edit-user-permissions-content">
        <h1>Edit User Permissions (User id: {userId})</h1>
        <p>
          {`${user.firstName} ${user.lastName} <${user.email}>`}&nbsp;
          <Link to={`/admin/edit-user/${user.id}`}>(edit user)</Link>
        </p>
        <h2>Current Permissions</h2>
        <Button
          variant="outlined"
          onClick={() => {
            setAddDialogOpen(true);
          }}
        >
          Add
        </Button>
        <ul>
          {user.permissions.map((userPermission) => (
            <li key={userPermission.organization.id}>
              {userPermission.organization.orgName}{' '}
              <Button
                variant="text"
                onClick={() => {
                  setPermissionToRemove(userPermission);
                }}
              >
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </Box>

      <Dialog open={addDialogOpen} fullWidth>
        <DialogTitle>Add Permission</DialogTitle>
        <DialogContent sx={{ minHeight: 200 }}>
          {addError && (
            <p>
              <Alert severity="error">
                Adding permission failed: <code>{addError.message}</code>
              </Alert>
            </p>
          )}
          <Autocomplete
            disabled={addLoading}
            size="small"
            disablePortal
            value={selectedOrganizationAddOption}
            onChange={(_evt, val) => {
              setOrgIdPermissionToAdd(val?.id ?? null);
            }}
            options={organizationAddOptions}
            sx={{ minWidth: 300 }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Organization" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddDialogOpen(false);
              setOrgIdPermissionToAdd(null);
            }}
            disabled={addLoading}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="success"
            disabled={addLoading || orgIdPermissionToAdd == null}
            onClick={() => handleOnAddClicked()}
          >
            Add
            {addLoading && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={removeDialogOpen} fullWidth>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {removeError && (
              <p>
                <Alert severity="error">
                  Removing permission failed: <code>{removeError.message}</code>
                </Alert>
              </p>
            )}
            Remove permission for organization &quot;{permissionToRemove?.organization.orgName}
            &quot;?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPermissionToRemove(null);
            }}
            disabled={removeLoading}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="error"
            disabled={removeLoading}
            onClick={() => handleOnRemoveClicked()}
          >
            Remove
            {removeLoading && <CircularProgress size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditUserPermissions;
