import '../back-office/back-office.scss';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';

function ConfirmPop({ title, description, onCancel, onConfirm }) {
  const lang = useLanguage();

  return (
    <div className="modal-background">
      <div className="modal confirm-modal">
        <div className="modal-header">{title}</div>
        <div className="modal-content">{description}</div>
        <div className="modal-footer" style={{ justifyContent: 'flex-end' }}>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="outlined" onClick={onCancel}>
              {translations.videoCard.noCancel[lang]}
            </Button>

            <Button variant="contained" color="error" disableElevation onClick={onConfirm}>
              {translations.videoCard.confirm[lang]}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPop;
