import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import StoriesOutlinedIcon from '../../common/icons/stories/StoriesOutlined';
import ThemeContext from '../../../utils/ThemeContext';
import VideosOutlinedIcon from '../../common/icons/videos/VideosOutlined';
import ActivateOutlined from '../../common/icons/activate/ActivateOutlined';

export const iconTypes = {
  stories: 'stories',
  videoLibrary: 'videoLibrary',
  liveVideo: 'liveVideo',
};

function ViewsDataCard({ value, text, icon }) {
  const theme = useContext(ThemeContext);
  const renderIcon = () => {
    switch (icon) {
      case iconTypes.stories:
        return (
          <StoriesOutlinedIcon
            fill={theme.palette.secondary.main}
            style={{ width: '100%', height: '100%' }}
          />
        );
      case iconTypes.videoLibrary:
        return (
          <VideosOutlinedIcon
            fill={theme.palette.secondary.main}
            style={{ width: '100%', height: '100%' }}
          />
        );
      case iconTypes.liveVideo:
        return (
          <ActivateOutlined
            fill={theme.palette.secondary.main}
            style={{ width: '100%', height: '100%' }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        padding: 2,
        backgroundColor: 'background.paper',
        borderRadius: 3,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography variant="headlineLargeBoldRecoleta">{value}</Typography>
      <Typography variant="bodyMedium" color="text.secondary" sx={{ textAlign: 'center' }}>
        {text}
      </Typography>
      <Box
        sx={{
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {renderIcon()}
      </Box>
    </Card>
  );
}
ViewsDataCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.values(iconTypes)).isRequired,
};

export default ViewsDataCard;
