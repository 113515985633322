/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const UPDATE_PROMO_RECORD_VIDEO_PORTRAIT_MUTATION = gql`
  mutation UpdatePromoteRecordVideoPortrait(
    $promoteRecordVideoPortrait: Boolean!
    $encodedOrgId: String!
  ) {
    updatePromoteRecordVideoPortrait(
      promoteRecordVideoPortrait: $promoteRecordVideoPortrait
      encodedOrgId: $encodedOrgId
    )
  }
`;
