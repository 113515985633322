import translationsAnalytics from 'src/utils/translationsAnalytics';

const translations = {
  title: {
    en: 'Title',
    sv: 'Titel',
  },
  impressions: {
    en: 'Impressions',
    sv: 'Visningar',
  },
  viewTime: {
    en: 'View time',
    sv: 'Visningstid',
  },
  plays: {
    en: 'Plays',
    sv: 'Spelningar',
  },
  playRate: {
    en: 'Play rate',
    sv: 'Spelningsfrekvens',
  },
  ariaLabel: {
    sortBy: {
      en: 'Sort by',
      sv: 'Sortera efter',
    },
  },
  tooltips: {
    impressions: {
      en: translationsAnalytics.tooltips.impressions.en,
      sv: translationsAnalytics.tooltips.impressions.sv,
    },
    plays: {
      en: translationsAnalytics.tooltips.plays.en,
      sv: translationsAnalytics.tooltips.plays.sv,
    },
    playRate: {
      en: translationsAnalytics.tooltips.playRate.en,
      sv: translationsAnalytics.tooltips.playRate.sv,
    },
    viewTime: {
      en: translationsAnalytics.tooltips.viewTime.en,
      sv: translationsAnalytics.tooltips.viewTime.sv,
    },
    title: {
      en: 'The title of the video. Click to sort by title.',
      sv: 'Titeln på videon. Klicka för att sortera efter titel.',
    },
  },
};

export default translations;
