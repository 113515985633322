import { useQuery } from '@apollo/client';
import { UserResult } from './types';
import { USER_QUERY } from './queries';

const useUserData = (encodedOrgId: string) => {
  const { data, loading, error } = useQuery<{ me: UserResult }>(USER_QUERY, {
    variables: { encodedOrgId },
  });
  return { data, loading, error, isSuperAdmin: data?.me?.superAdmin };
};

export default useUserData;
