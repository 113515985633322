import { useCallback } from 'react';
import { VideoCollectorDetailed } from '../types';

export type OnFieldChangeFunc<T extends keyof VideoCollectorDetailed> = (
  newValue: VideoCollectorDetailed[T],
) => void;

// useOnFieldChange returns a memoized function for updating the field 'fieldName' through
// the onChange callback.
export const useOnFieldChange = <T extends keyof VideoCollectorDetailed>(
  onChange: (change: Partial<VideoCollectorDetailed>) => void,
  fieldName: T,
) => {
  const f: OnFieldChangeFunc<T> = useCallback(
    (newValue: VideoCollectorDetailed[T]) => {
      onChange({ [fieldName]: newValue });
    },
    [onChange, fieldName],
  );
  return f;
};
