import React from 'react';
import { MediaItemState, StyledImage } from './MediaItemState';
import { calculateAspectRatioStyles } from './aspectRatioCalculator';

interface ImageDisplayProps {
  mediaRef: React.RefObject<HTMLImageElement>;
  shouldRender: boolean;
  mediaItemState: MediaItemState;
  setMediaItemState: React.Dispatch<React.SetStateAction<MediaItemState>>;
}

function ImageDisplay({
  mediaRef,
  shouldRender,
  mediaItemState,
  setMediaItemState,
}: ImageDisplayProps) {
  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    const { aspectRatio, gridColumnEnd, gridRowEnd } = calculateAspectRatioStyles(
      naturalWidth,
      naturalHeight,
    );

    setMediaItemState((prevState) => ({
      ...prevState,
      aspectRatio,
      gridColumnEnd,
      gridRowEnd,
      isVideo: false,
    }));
  };

  return (
    <StyledImage
      data-testid="image-display"
      ref={mediaRef as React.RefObject<HTMLImageElement>}
      src={shouldRender ? mediaItemState.staticThumbnail || undefined : undefined}
      alt=""
      onLoad={handleImageLoad}
    />
  );
}

export default ImageDisplay;
