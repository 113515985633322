import React, { useState } from 'react';

export default function OnOffButton({ isOn, text, onClick }) {
  return (
    <button type="button" className="on-off-btn" onClick={onClick}>
      <span className={`lever ${isOn ? 'on' : 'off'}`}>
        <span className="lever-dot" />
      </span>
      <span className="lever-text">{text}</span>
    </button>
  );
}
