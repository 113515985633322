import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import translationsEndScreen from '../../../utils/translationsEndScreen';
import { storylineStatuses } from '../../../utils/utils';
import SimpleDialogTitle from '../../common/SimpleDialogTitle';
import SimpleDialogActions from '../../common/SimpleDialogAction';
import {
  typeContactForm,
  canSaveContactForm,
  getEndScreenTypeContentContactForm,
  hasChangesContactForm,
} from './ContactForm';
import {
  typeCallToAction,
  canSaveCallToAction,
  getEndScreenTypeContentCallToAction,
  hasChangesCallToAction,
} from './CallToAction';
import AlertDialog from '../../common/AlertDialog';
import { useEndScreensApi } from '../../../api/endScreens';
import EndScreenInput from './EndScreenInput';
import {
  typeVideoLibrary,
  getEndScreenTypeContentVideoLibraryEndScreen,
  canSaveVideoLibraryEndScreen,
  hasChangesVideoLibraryEndScreen,
} from './VideoLibraryEndScreen';

export default function EndScreenDialog({
  open,
  handleClose,
  basicOrgData,
  endScreenItem,
  orgHasEndScreen = false,
  saveAndActivate = false,
}) {
  const endScreensApi = useEndScreensApi();
  const lang = useLanguage();
  const endScreenId = endScreenItem?.id;
  const [type, setType] = useState(endScreenItem?.meta?.endScreenType ?? '');

  const [title, setTitle] = useState(endScreenItem?.meta?.title ?? '');

  const [endScreen, setEndScreen] = useState(() => {
    if (endScreenItem?.meta?.endScreenType === typeCallToAction) {
      return getEndScreenTypeContentCallToAction({ endScreen: endScreenItem });
    }
    if (endScreenItem?.meta?.endScreenType === typeContactForm) {
      return getEndScreenTypeContentContactForm({ endScreen: endScreenItem });
    }
    if (endScreenItem?.meta?.endScreenType === typeVideoLibrary) {
      return getEndScreenTypeContentVideoLibraryEndScreen({ endScreen: endScreenItem });
    }
  });

  const [editSelected, setEditSelected] = useState(
    !endScreenId && !(endScreen?.videoCards?.length > 0),
  );

  const [selectAllVideos, setSelectAllVideos] = useState(false);

  const endScreenStatus = endScreenItem?.status ? endScreenItem?.status : storylineStatuses.EDIT;

  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [deleteAlertDialogOpen, setDeleteAlertDialogOpen] = useState(false);
  const [deleteEndScreenConfirmDialog, setDeleteEndScreenConfirmDialog] = useState(false);
  const [deactivateEndScreenConfirmDialog, setDeactivateEndScreenConfirmDialog] = useState(false);

  const handleOnClose = () => {
    if (hasChanges()) {
      setSimpleAlertDialogOpen(true);
    } else {
      handleClose({});
    }
  };
  const handleOnDelete = () => {
    setDeleteAlertDialogOpen(true);
  };

  const getEndScreenTypeContent = () => {
    switch (type) {
      case typeCallToAction:
        return getEndScreenTypeContentCallToAction({ endScreen: { ...endScreen, type } });
      case typeContactForm:
        return getEndScreenTypeContentContactForm({ endScreen: { ...endScreen, type } });
      case typeVideoLibrary:
        return getEndScreenTypeContentVideoLibraryEndScreen({
          endScreen: { ...endScreen, type },
          save: true,
        });
      default:
        return null;
    }
  };

  const handleConfirmDeactivate = () => {
    if (endScreenId) {
      endScreensApi
        .updateEndScreen({
          encodedOrgId: basicOrgData?.encodedId,
          orgId: basicOrgData?.id,
          id: endScreenId,
          contactForm: type === typeContactForm && getEndScreenTypeContent(),
          callToAction: type === typeCallToAction && getEndScreenTypeContent(),
          endScreenVideoLibrary: type === typeVideoLibrary && getEndScreenTypeContent(),
          title,
          endScreenType: type,
          status: storylineStatuses.EDIT,
          deactivateConfirmed: true,
        })
        .then((res) => {
          handleClose({ editEndScreen: res });
        })
        .catch((error) => {});
    }
  };

  const handleOnSave = (newStatus = endScreenStatus || storylineStatuses.EDIT) => {
    if (
      endScreenId &&
      endScreenItem?.status === storylineStatuses.LIVE &&
      newStatus === storylineStatuses.EDIT
    ) {
      endScreensApi
        .canInactivateEndScreen({
          encodedOrgId: basicOrgData?.encodedId,
          orgId: basicOrgData?.id,
          id: endScreenId,
        })
        .then((res) => {
          const videoFunnelCount = res?.length ?? 0;
          const videoFunnelEdgeCount = res
            ?.map((vf) => vf?.videoFunnelEdges?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          const videoFunnelNodeCount = res
            ?.map((vf) => vf?.videoFunnelNodes?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          if (videoFunnelCount > 0 || videoFunnelEdgeCount > 0 || videoFunnelNodeCount > 0) {
            setDeactivateEndScreenConfirmDialog(
              translationsEndScreen.deactivateInVideoFunnel[lang]({
                videoFunnelCount,
                videoFunnelEdgeCount,
                videoFunnelNodeCount,
                videoFunnelTitles: res?.map((vf) => vf?.title),
              }),
            );
          } else {
            handleClose({ deletedId: endScreenId });
          }
        })
        .catch((err) => {});
    } else if (!endScreenId) {
      if (saveAndActivate) {
        endScreensApi
          .createEndScreen({
            encodedOrgId: basicOrgData?.encodedId,
            orgId: basicOrgData?.id,
            contactForm: type === typeContactForm && getEndScreenTypeContent(),
            callToAction: type === typeCallToAction && getEndScreenTypeContent(),
            endScreenVideoLibrary: type === typeVideoLibrary && getEndScreenTypeContent(),
            title,
            endScreenType: type,
            status: storylineStatuses.LIVE,
          })
          .then((res) => {
            handleClose({ newEndScreen: res });
          })
          .catch((error) => {});
      } else {
        endScreensApi
          .createEndScreen({
            encodedOrgId: basicOrgData?.encodedId,
            orgId: basicOrgData?.id,
            contactForm: type === typeContactForm && getEndScreenTypeContent(),
            callToAction: type === typeCallToAction && getEndScreenTypeContent(),
            endScreenVideoLibrary: type === typeVideoLibrary && getEndScreenTypeContent(),
            title,
            endScreenType: type,
            status: newStatus,
          })
          .then((res) => {
            handleClose({ newEndScreen: res });
          })
          .catch((error) => {});
      }
    } else if (newStatus === storylineStatuses.EDIT || newStatus === storylineStatuses.LIVE) {
      endScreensApi
        .updateEndScreen({
          encodedOrgId: basicOrgData?.encodedId,
          orgId: basicOrgData?.id,
          id: endScreenId,
          contactForm: type === typeContactForm && getEndScreenTypeContent(),
          callToAction: type === typeCallToAction && getEndScreenTypeContent(),
          endScreenVideoLibrary: type === typeVideoLibrary && getEndScreenTypeContent(),
          title,
          endScreenType: type,
          status: newStatus,
        })
        .then((res) => {
          handleClose({ editEndScreen: res });
        })
        .catch((error) => {});
    }
  };

  const hasChanges = () => {
    const changes = () => {
      if (!endScreenItem) {
        if (title !== '' || type !== '') {
          return true;
        }
      } else {
        return title !== endScreenItem?.meta?.title || type !== endScreenItem?.meta?.endScreenType;
      }
      return false;
    };

    switch (type) {
      case typeCallToAction:
        return (
          changes() ||
          hasChangesCallToAction({
            currentEndScreen: { ...endScreen, endScreenId, type },
            ...(!saveAndActivate
              ? { prevEndScreen: getEndScreenTypeContentCallToAction({ endScreen: endScreenItem }) }
              : {}),
          })
        );
      case typeContactForm:
        return (
          changes() ||
          hasChangesContactForm({
            currentEndScreen: { ...endScreen, endScreenId, type },
            ...(!saveAndActivate
              ? { prevEndScreen: getEndScreenTypeContentContactForm({ endScreen: endScreenItem }) }
              : {}),
          })
        );
      case typeVideoLibrary:
        return (
          changes() ||
          hasChangesVideoLibraryEndScreen({
            currentEndScreen: { ...endScreen, endScreenId, type },
            ...(!saveAndActivate
              ? {
                  prevEndScreen: getEndScreenTypeContentVideoLibraryEndScreen({
                    endScreen: endScreenItem,
                  }),
                }
              : {}),
          })
        );
      default:
        return false;
    }
  };

  const canSave = () => {
    switch (type) {
      case typeCallToAction:
        return (
          hasChanges() && canSaveCallToAction({ endScreen: { ...endScreen, endScreenId, type } })
        );
      case typeContactForm:
        return (
          hasChanges() && canSaveContactForm({ endScreen: { ...endScreen, endScreenId, type } })
        );
      case typeVideoLibrary:
        return (
          hasChanges() &&
          canSaveVideoLibraryEndScreen({ endScreen: { ...endScreen, endScreenId, type } })
        );
    }

    return false;
  };

  const canActivate = () => {
    if (
      (endScreenStatus || storylineStatuses.EDIT) === storylineStatuses.EDIT ||
      (!endScreenStatus && saveAndActivate)
    ) {
      if (!hasChanges()) {
        switch (type) {
          case typeCallToAction:
            return canSaveCallToAction({ endScreen: { ...endScreen, endScreenId, type } });
          case typeContactForm:
            return canSaveContactForm({ endScreen: { ...endScreen, endScreenId, type } });
          case typeVideoLibrary:
            return canSaveVideoLibraryEndScreen({
              endScreen: { ...endScreen, endScreenId, type },
            });
        }
        return false;
      }

      return canSave();
    }
    if (endScreenStatus === storylineStatuses.LIVE) {
      return true;
    }

    return false;
  };

  const handleConfirmDelete = () => {
    if (endScreenId) {
      endScreensApi
        .deleteEndScreen({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          id: endScreenId,
          deleteConfirmed: true,
        })
        .then((res) => {
          handleClose({ deletedId: endScreenId });
        });
    }
  };

  const handleDelete = () => {
    if (endScreenId) {
      endScreensApi
        .deleteEndScreen({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          id: endScreenId,
        })
        .then((res) => {
          const videoFunnelCount = res?.length ?? 0;
          const videoFunnelEdgeCount = res
            ?.map((vf) => vf?.videoFunnelEdges?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          const videoFunnelNodeCount = res
            ?.map((vf) => vf?.videoFunnelNodes?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          if (videoFunnelCount > 0 || videoFunnelEdgeCount > 0 || videoFunnelNodeCount > 0) {
            setDeleteEndScreenConfirmDialog(
              translationsEndScreen.deleteInVideoFunnel[lang]({
                videoFunnelCount,
                videoFunnelEdgeCount,
                videoFunnelNodeCount,
                videoFunnelTitles: res?.map((vf) => vf?.title),
              }),
            );
          } else {
            handleClose({ deletedId: endScreenId });
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: '20px',
          ...(type === typeVideoLibrary ? { minHeight: 'calc(100vh - 64px)' } : {}),
        },
      }}
      {...(type === typeVideoLibrary ? { maxWidth: 'xl' } : {})}
      fullWidth
    >
      {simpleAlertDialogOpen && (
        <AlertDialog
          open={simpleAlertDialogOpen}
          title={translationsEndScreen.closeBeforeSave[lang]}
          description={translationsEndScreen.areYouSureClose[lang]}
          confirmBtnText={translationsEndScreen.confirmCloseWithoutSaving[lang]}
          cancelBtnText={translationsEndScreen.noCancelCloseWithoutSaving[lang]}
          onConfirm={() => {
            setSimpleAlertDialogOpen(false);
            handleClose({});
          }}
          onCancel={(e) => {
            setSimpleAlertDialogOpen(false);
          }}
        />
      )}
      {deleteAlertDialogOpen && (
        <AlertDialog
          open={deleteAlertDialogOpen}
          title={translationsEndScreen.deleteTitle[lang]}
          description={translationsEndScreen.deleteDescription[lang]}
          confirmBtnText={translationsEndScreen.deleteConfirmBtnText[lang]}
          cancelBtnText={translationsEndScreen.deleteCancelBtnText[lang]}
          onConfirm={() => {
            handleDelete();
            setDeleteAlertDialogOpen(false);
          }}
          onCancel={(e) => {
            setDeleteAlertDialogOpen(false);
          }}
        />
      )}
      {deleteEndScreenConfirmDialog && (
        <AlertDialog
          open={!!deleteEndScreenConfirmDialog}
          title={translationsEndScreen.confirmDeleteTitle[lang]}
          description={deleteEndScreenConfirmDialog}
          confirmBtnText={translationsEndScreen.deleteConfirmBtnText[lang]}
          cancelBtnText={translationsEndScreen.deleteCancelBtnText[lang]}
          onConfirm={() => {
            handleConfirmDelete();
            setDeleteAlertDialogOpen(false);
            setDeleteEndScreenConfirmDialog(false);
          }}
          onCancel={(e) => {
            setDeleteAlertDialogOpen(false);
            setDeleteEndScreenConfirmDialog(false);
          }}
        />
      )}
      {deactivateEndScreenConfirmDialog && (
        <AlertDialog
          open={!!deactivateEndScreenConfirmDialog}
          title={translationsEndScreen.confirmDeactivateTitle[lang]}
          description={deactivateEndScreenConfirmDialog}
          confirmBtnText={translationsEndScreen.deactivateConfirmBtnText[lang]}
          cancelBtnText={translationsEndScreen.deleteCancelBtnText[lang]}
          onConfirm={() => {
            handleConfirmDeactivate();
            setDeactivateEndScreenConfirmDialog(false);
          }}
          onCancel={(e) => {
            setDeactivateEndScreenConfirmDialog(false);
          }}
        />
      )}
      <SimpleDialogTitle
        title={
          endScreenId
            ? translationsEndScreen.editEndScreen[lang]
            : translationsEndScreen.createEndScreen[lang]
        }
        handleOnClose={handleOnClose}
      />

      <DialogContent>
        <TransitionGroup>
          <CSSTransition timeout={500} classNames="item">
            <EndScreenInput
              title={title}
              setTitle={setTitle}
              endScreen={endScreen}
              setEndScreen={setEndScreen}
              type={type}
              setType={setType}
              orgHasEndScreen={orgHasEndScreen}
              basicOrgData={basicOrgData}
              editSelected={editSelected}
              selectAllVideos={selectAllVideos}
            />
          </CSSTransition>
        </TransitionGroup>
      </DialogContent>
      <SimpleDialogActions>
        <Box style={{ marginLeft: '0px', marginRight: 'auto' }}>
          {endScreenId ? (
            <Button onClick={handleOnDelete}>
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translationsEndScreen.delete[lang]}
            </Button>
          ) : (
            <Button color="secondary" onClick={handleOnClose}>
              {translationsEndScreen.cancel[lang]}
            </Button>
          )}
          {type === typeVideoLibrary && (
            <Chip
              label={`${endScreen?.videoCards?.length ?? 0} ${
                translationsEndScreen.selected[lang]
              }`}
            />
          )}
        </Box>
        <Box style={{ display: 'flex' }}>
          {type === typeVideoLibrary && (
            <Fade in={type === typeVideoLibrary}>
              <div>
                <Fade in={editSelected}>
                  <label htmlFor="selectAllCheckbox">
                    <input
                      name="selectAllCheckbox"
                      id="selectAllCheckbox"
                      type="checkbox"
                      checked={selectAllVideos}
                      onChange={() => setSelectAllVideos(!selectAllVideos)}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography
                      variant="titleLarge"
                      sx={{
                        color: '#000000',
                        cursor: 'pointer',
                        marginRight: '16px',
                      }}
                    >
                      {translationsEndScreen.selectAllVideos[lang]}
                    </Typography>
                  </label>
                </Fade>
                <Button onClick={() => setEditSelected(!editSelected)}>
                  {editSelected
                    ? translationsEndScreen.setSelected[lang]
                    : translationsEndScreen.editSelected[lang]}
                </Button>
              </div>
            </Fade>
          )}

          {!endScreenId && saveAndActivate ? (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                handleOnSave(storylineStatuses.LIVE);
              }}
              disabled={!canActivate()}
            >
              {translationsEndScreen.saveActivateAdd[lang]}
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                disableElevation
                onClick={() => {
                  handleOnSave(
                    endScreenStatus === storylineStatuses.EDIT
                      ? storylineStatuses.LIVE
                      : storylineStatuses.EDIT,
                  );
                }}
                disabled={!canActivate()}
              >
                {endScreenStatus === storylineStatuses.EDIT
                  ? translationsEndScreen.activate[lang]
                  : translationsEndScreen.deactivate[lang]}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  handleOnSave();
                }}
                disabled={!canSave()}
              >
                {translationsEndScreen.save[lang]}
              </Button>
            </>
          )}
        </Box>
      </SimpleDialogActions>
    </Dialog>
  );
}

EndScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  basicOrgData: PropTypes.object.isRequired,
  endScreenItem: PropTypes.object,
};
