import { useState } from 'react';
import { useNavigate } from 'react-router';
import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';

import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';

import { translations } from '../../utils/translations';
import { isValidHttpUrl } from '../../utils/utils';
import EditPremiumFeaturesView from './EditPremiumFeaturesView';

const createNewOrg = ({
  adminClient,
  orgName,
  contactEmail,
  orgNumber,
  defaultLang,
  tagFinderSelector,
  atsIntegrationId,
  whitelistedDomain,
  widgetIsActive,
  premiumFeatures,
}) => {
  return adminClient.mutate({
    mutation: gql`
          mutation($atsIntegrationId: Int, $premiumFeatures: PremiumFeatureInput) {
            createOrganization(input: {
              orgName: """${orgName}""",
              contactEmail: "${contactEmail}",
              orgNumber: "${orgNumber}",
              lang: "${defaultLang}",
              tagFinderSelector: "${tagFinderSelector}",
              atsIntegrationId: $atsIntegrationId
              whitelistedDomain: "${whitelistedDomain}"
              widgetIsActive: ${!!widgetIsActive}
              premiumFeatures: $premiumFeatures
            }) {
              id
              orgName
              orgNumber
              contactEmail
            }
          }
        `,
    variables: {
      atsIntegrationId,
      premiumFeatures,
    },
  });
};

export default function CreateNewOrg() {
  const navigate = useNavigate();
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const [orgName, setOrgName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [orgNumber, setOrgNumber] = useState('');
  const [defaultLanguage, setDefaultLanguage] = useState('sv');
  const [tagFinderSelector, setTagFinderSelector] = useState('h1');
  const [atsIntegrationId, setAtsIntegrationId] = useState('');
  const [whitelistedDomain, setWhitelistedDomain] = useState('');
  const [widgetIsActive, setWidgetIsActive] = useState(true);
  const [premiumFeatures, setPremiumFeatures] = useState(null);

  const createOrg = (e) => {
    e.preventDefault();
    if (!isValidHttpUrl(whitelistedDomain)) {
      // eslint-disable-next-line no-alert
      alert('Invalid whitelisted domain');
      return;
    }
    createNewOrg({
      adminClient,
      orgName,
      contactEmail,
      orgNumber,
      defaultLang: defaultLanguage,
      tagFinderSelector,
      atsIntegrationId: atsIntegrationId ? Number(atsIntegrationId) : null,
      whitelistedDomain,
      widgetIsActive,
      premiumFeatures,
    }).then(() => {
      navigate(`/admin/organizations`);
    });
  };
  return (
    <div className="create-new-page">
      <h1>{translations.admin.header.createOrganization[lang]}</h1>
      <div>
        <form onSubmit={createOrg}>
          <label>
            <span>{translations.admin.Organizations.OrganizationName[lang]}</span>
            <input
              type="text"
              value={orgName}
              required
              onChange={(e) => setOrgName(e.target.value)}
            />
          </label>
          <label>
            <span>{translations.admin.Organizations.OrgNumber[lang]}</span>
            <input type="text" value={orgNumber} onChange={(e) => setOrgNumber(e.target.value)} />
          </label>
          <label>
            <span>{translations.admin.Organizations.Language[lang]}</span>
            <input
              type="text"
              required
              value={defaultLanguage}
              onChange={(e) => setDefaultLanguage(e.target.value)}
            />
          </label>
          <label>
            <span>{translations.admin.Organizations.TagOption[lang]}</span>
            <input
              type="text"
              value={tagFinderSelector}
              onChange={(e) => setTagFinderSelector(e.target.value)}
            />
          </label>
          <label>
            <span>{translations.admin.Organizations.ContactEmail[lang]}</span>
            <input
              type="text"
              required
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </label>
          <label htmlFor="admin-whitelisted-domain">
            <span>{translations.admin.Organizations.whitelistedDomain[lang]}</span>
            <input
              type="url"
              id="admin-whitelisted-domain"
              value={whitelistedDomain}
              required
              onChange={(e) => setWhitelistedDomain(e.target.value)}
            />
          </label>
          <label htmlFor="widget-is-active">
            <span>{translations.admin.Organizations.widgetIsActiveLabel[lang]}</span>
            <input
              type="checkbox"
              id="widget-is-active"
              checked={widgetIsActive}
              required
              onChange={() => setWidgetIsActive(!widgetIsActive)}
            />
          </label>
          <EditPremiumFeaturesView
            premiumFeatures={null}
            setPremiumFeatures={setPremiumFeatures}
            createNewOrg
          />
          <Select
            label="ATS integration"
            value={atsIntegrationId}
            onChange={(e) => setAtsIntegrationId(e.target.value)}
            options={[
              { value: '', label: 'Empty' },
              { value: '1', label: 'Jobylon' },
            ]}
          />
          <Button type="submit" variant="contained" disableElevation>
            Skapa org
          </Button>
        </form>
      </div>
    </div>
  );
}
