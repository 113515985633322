import { useMutation } from '@apollo/client';
import { UPDATE_USER_PERMISSION } from './queries';

function useUpdatePermission() {
  const [updatePermission, { data, loading, error }] = useMutation(UPDATE_USER_PERMISSION);

  return {
    updatePermission,
    data,
    loading,
    error,
  };
}

export default useUpdatePermission;
