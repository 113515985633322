import React, { useEffect, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';

import PublicVideoCollector, { VIDEO_COLLECTOR_QUERY } from './PublicVideoCollector';
import PasswordProtected from './PasswordProtected';
import Layout from './Layout';
import LoadingIndicator from '../../common/LoadingIndicator';
import NotFoundErrorView from './NotFoundErrorView';
import useAlerts from './useAlerts';
import { AlertId } from './types';

// checkPreviewTokenValid checks if the given token looks like it is a valid
// PreviewToken. Note that this is a best-effort check, and that signature is
// not checked.
const checkPreviewTokenValid = (token: string | null) => {
  if (token == null) {
    return false;
  }
  let decodedToken = null;
  try {
    decodedToken = jwtDecode(token);
  } catch (err) {
    return false;
  }
  const exp = decodedToken.exp ?? 0;
  return Date.now() < exp * 1000;
};

const VIDEO_COLLECTOR_STATUS_QUERY = gql`
  query VideoCollectorStatus(
    $encodedOrgId: String!
    $encodedVideoCollectorId: String!
    $previewToken: String
  ) {
    videoCollectorStatus(
      encodedOrgId: $encodedOrgId
      encodedVideoCollectorId: $encodedVideoCollectorId
      previewToken: $previewToken
    )
  }
`;

function PublicVideoCollectorDispatcher() {
  const params = useParams();
  const encodedOrgId = params.encodedorgid!;
  const encodedVideoCollectorId = params.encodedvideocollectorid!;
  const [searchParams] = useSearchParams();

  const previewToken = searchParams.get('previewToken');
  const isPreviewTokenInvalid = !checkPreviewTokenValid(previewToken);
  const isPreview = previewToken != null;

  const [alerts, showAlert, hideAlert] = useAlerts();

  // Show alerts based on preview token from URL.
  useEffect(() => {
    const alertsToShow: AlertId[] = [];
    if (isPreview) {
      alertsToShow.push('ALERT_PREVIEW_NOTICE');
    }
    if (isPreview && isPreviewTokenInvalid) {
      alertsToShow.push('ALERT_PREVIEW_TOKEN_ERROR');
    }
    alertsToShow.forEach(showAlert);
    return () => alertsToShow.forEach(hideAlert);
  }, [isPreview, isPreviewTokenInvalid, showAlert, hideAlert]);

  const { data: statusData, loading: statusLoading } = useQuery(VIDEO_COLLECTOR_STATUS_QUERY, {
    variables: {
      encodedOrgId,
      encodedVideoCollectorId,
      previewToken,
    },
  });
  const videoCollectorStatus = statusData?.videoCollectorStatus;

  // Password protection. We use a lazy query against the VideoCollector endpoint to
  // verify password. This has the benefit of letting the PublicVideoCollector simply
  // use the cached VideoCollector response once correct password is entered.
  const [getVideoCollector, { data: videoCollectorData, loading: videoCollectorLoading }] =
    useLazyQuery(VIDEO_COLLECTOR_QUERY);
  const videoCollector = videoCollectorData?.videoCollector;
  const [hasCheckedPassword, setHasCheckedPassword] = useState(false);
  const checkPasswordOk = videoCollector != null;
  const checkPasswordLoading = videoCollectorLoading;
  const checkPasswordError = hasCheckedPassword && !checkPasswordOk && !checkPasswordLoading;

  const checkPassword = (password: string) => {
    getVideoCollector({
      variables: {
        encodedOrgId,
        encodedVideoCollectorId,
        previewToken,
        password,
      },
    });
    setHasCheckedPassword(true);
  };

  const renderPublicVideoCollector = (password: string | null) => (
    <PublicVideoCollector
      encodedOrgId={encodedOrgId!}
      encodedVideoCollectorId={encodedVideoCollectorId!}
      password={password}
      previewToken={previewToken}
      showAlert={showAlert}
      hideAlert={hideAlert}
    />
  );

  const renderContent = () => {
    if (isPreview && isPreviewTokenInvalid) {
      return null;
    }
    if (statusLoading) {
      return <LoadingIndicator />;
    }
    switch (videoCollectorStatus) {
      case 'OK': {
        // No password required, render directly.
        return renderPublicVideoCollector(null);
      }
      case 'PASSWORD_PROTECTED':
        // Delegate to PasswordProtected component to query password from user.
        return (
          <PasswordProtected
            checkPassword={checkPassword}
            checkPasswordOk={checkPasswordOk}
            checkPasswordLoading={checkPasswordLoading}
            checkPasswordError={checkPasswordError}
          >
            {(password: string) => renderPublicVideoCollector(password)}
          </PasswordProtected>
        );
      case 'INACTIVE': // Fallthrough.
      default: {
        return <NotFoundErrorView />;
      }
    }
  };

  return (
    <Layout alerts={alerts} hideAlert={hideAlert}>
      {renderContent()}
    </Layout>
  );
}

export default PublicVideoCollectorDispatcher;
