import React from 'react';
import Box from '@mui/material/Box';

import MediaGridItem from './MediaGridItem';

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  mediaContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '4px',
    gridAutoFlow: 'dense',
    rowGap: '4px',
    paddingBottom: '40px',
  },
};

interface MediaGridProps {
  encodedVideoCardIds: Array<string>;
  encodedOrgId: string;
  encodedVideoCollectorId: string;
  videoToken: string;
}
function MediaGrid({
  encodedVideoCardIds,
  encodedOrgId,
  encodedVideoCollectorId,
  videoToken,
}: MediaGridProps) {
  return (
    <Box sx={styles.mediaContainer} data-testid="mediaContainer">
      {encodedVideoCardIds.map((encodedId) => (
        <MediaGridItem
          key={encodedId}
          encodedId={encodedId}
          encodedOrgId={encodedOrgId}
          encodedVideoCollectorId={encodedVideoCollectorId}
          videoToken={videoToken}
        />
      ))}
    </Box>
  );
}

export default MediaGrid;
