/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client/index';

export const VIDEO_REQUEST_DETAILS_FRAGMENT = gql`
  fragment VideoRequestDetails on VideoRequest {
    id
    index
    type
    encodedId
    status
    lastReminderTime
    lastReminderByUser
    requestTime
    requestedByUser
    question {
      sv
      en
      videoRequestId
      questionId
      videoCardId
      answeredTimeStampTz
    }
    internalNote {
      sv
      en
    }
    tags {
      id
      name {
        sv
        en
      }
    }
    user {
      id
      firstName
      lastName
    }
    interactionOption {
      __typename
      ... on ContactForm {
        msg {
          sv
          en
        }
        confirmationMsg {
          sv
          en
        }
        email
        type
        btnText {
          sv
          en
        }
      }
      ... on CallToAction {
        type
        msg {
          sv
          en
        }
        url
        btnText {
          sv
          en
        }
      }
    }
    hideFromLibrary
    consentTexts {
      soMeConsent
      uploadConsent
    }
    created
    recipient {
      id
      orgId
      firstName
      lastName
      phoneNumber
      email
      title
      receiveAutoRequests
      leader
      name
    }
    recipients {
      id
      orgId
      firstName
      lastName
      name
      phoneNumber
      email
      title
      receiveAutoRequests
      leader
    }
    questions {
      en
      sv
      videoRequestId
      questionId
      videoCardId
      answeredTimeStampTz
    }
  }
`;
