import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useLanguage } from 'src/context/LanguageContext';
import videoCollectorTranslations from './VideoCollectorTranslations';

import AddIcon from '../../common/icons/add/Add';
import SearchField from '../../common/SearchField/SearchField';

interface VideoCollectorHeaderProps {
  searchField: string;
  disabled?: boolean;
  setSearchField: (search: string) => void;
  onAddClicked: () => void;
}

function VideoCollectorHeader({
  searchField,
  disabled = undefined,
  setSearchField,
  onAddClicked,
}: VideoCollectorHeaderProps) {
  const lang = useLanguage();

  return (
    <Grid
      item
      xs={12}
      xl={12}
      sm={12}
      md={12}
      lg={12}
      role="banner"
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Stack direction="row" spacing={3}>
          <Typography variant="headlineLargeBoldRecoleta">
            {videoCollectorTranslations.videoCollector[lang]}
          </Typography>
          <Button
            variant="text"
            disabled={disabled}
            onClick={() => onAddClicked()}
            data-testid="header-add-button"
          >
            <AddIcon />
            {videoCollectorTranslations.newVideoCollector[lang]}
          </Button>
        </Stack>
      </Grid>
      <Grid item>
        <SearchField
          placeholder={videoCollectorTranslations.videoCollectorSearchField[lang]}
          value={searchField}
          onChange={(e) => setSearchField(e.target.value)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

export default VideoCollectorHeader;
