import React from 'react';

import Grid from '@mui/material/Grid';
import { getStaticThumbnail } from '../../../utils/video-card/utils';
import { VideoCard } from './types';
import StaticThumbnail from './StaticThumbnail';
import AdditionalVideosThumbnail from './AdditionalVideosThumbnail';

interface VideoThumbnailsProps {
  videoCards: VideoCard[];
  videoCollectorId: number;
  inactive?: boolean;
}

function VideoThumbnails({ videoCards, videoCollectorId, inactive = false }: VideoThumbnailsProps) {
  const additionalVideosCount = videoCards.length - 4;
  const hasAdditionalVideos = additionalVideosCount > 0;

  if (videoCards.length === 0) {
    return null;
  }

  // When rendering as inactive we apply a grayscale filter.
  const inactiveFilter = inactive ? 'grayscale(1)' : undefined;

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      columns={5}
      role="grid"
      sx={{ filter: inactiveFilter }}
    >
      {videoCards.slice(0, 4)?.map((videoCard) => (
        <Grid item xs={1} key={`${videoCollectorId}-${videoCard.id}`}>
          <StaticThumbnail src={getStaticThumbnail(videoCard)} />
        </Grid>
      ))}
      {hasAdditionalVideos && (
        <AdditionalVideosThumbnail
          additionalVideosCount={additionalVideosCount}
          videoCollectorId={videoCollectorId}
          videoCard={videoCards[4]}
        />
      )}
    </Grid>
  );
}

export default VideoThumbnails;
