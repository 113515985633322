import React from 'react';
import { gql, useQuery } from '@apollo/client';
import './admin.scss';
import { Outlet, Navigate } from 'react-router';
import { Skeleton, Typography } from '@mui/material';
import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';
import AdminHeader from './Template/AdminHeader';

const ADMIN_USER_QUERY = gql`
  query AdminUser {
    adminUser {
      id
      firstName
      lastName
      email
    }
  }
`;

function ErrorComponent({ errorMessage }: { errorMessage: string }) {
  return (
    <div className="error-wrapper">
      <Typography variant="headlineSmall">Something went wrong</Typography>
      <Typography variant="body2">{errorMessage}</Typography>
    </div>
  );
}

function AdminTemplate() {
  const lang = useLanguage();
  const setLanguage = useSetLanguage();

  function handleLanguageChange(newLang: string) {
    setLanguage(newLang as Language);
  }

  const { loading, error } = useQuery(ADMIN_USER_QUERY);

  if (error) {
    if (error.message === 'Forbidden') {
      return <Navigate to="/login" replace />;
    }
    return <ErrorComponent errorMessage={error.message} />;
  }

  if (loading) {
    return (
      <div className="template admin">
        <Skeleton>
          <AdminHeader />
        </Skeleton>
        <div className="main">
          <Skeleton height={200} />
        </div>
      </div>
    );
  }

  return (
    <div className="template admin">
      <AdminHeader />
      <select
        className="langselect"
        value={lang}
        onChange={(e) => handleLanguageChange(e.target.value)}
      >
        <option value="sv">Swedish</option>
        <option value="en">English</option>
      </select>
      <div className="main">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminTemplate;
