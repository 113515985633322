import React, { useState, useRef, useEffect } from 'react';
import { Dialog, Button, DialogContent, Drawer, Stack, Box, Typography } from '@mui/material';

import ReCAPTCHA from 'react-google-recaptcha';
import { useLanguage } from 'src/context/LanguageContext';
import VideoUploadForm, {
  VideoUploadData as VideoUploadFormData,
  DisplayMode,
} from './VideoUploadForm';
import translationsPublicVideoCollector from './translations';

import LeftIcon from '../../common/icons/Left/LeftIcon';
import { VideoCollector, AlertId } from './types';

export interface VideoUploadData extends VideoUploadFormData {
  reCaptchaToken: string;
}

function debounce<F extends (...args: never[]) => void>(
  func: F,
  wait: number,
): (...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return function debouncedFunction(...args: Parameters<F>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
}

interface ResponsiveVideoUploadProps {
  videoCollector: VideoCollector;
  onVideoUpload: (data: VideoUploadData) => Promise<void>;
  displayMode: DisplayMode;
  containerRef: React.RefObject<HTMLDivElement>;
  uploadProgress: number;
  showAlert: (id: AlertId) => void;
  hideAlert: (id: AlertId) => void;
}

function ResponsiveVideoUpload({
  videoCollector,
  onVideoUpload,
  displayMode,
  containerRef,
  uploadProgress,
  showAlert,
  hideAlert,
}: ResponsiveVideoUploadProps) {
  const lang = useLanguage();
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const [buttonPosition, setButtonPosition] = useState({
    bottom: 48, // default position
  });
  const [videoUploaded, setVideoUploaded] = useState(false);

  useEffect(() => {
    // Define the function to update the button position.
    const updateButtonPosition = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const containerBottomToViewportBottom = window.innerHeight - containerRect.bottom;
        const desiredBottomPosition = Math.max(16, containerBottomToViewportBottom);

        setButtonPosition({
          bottom: desiredBottomPosition,
        });
      }
    };

    // Create a debounced version of updateButtonPosition.
    const debouncedUpdateButtonPosition = debounce(updateButtonPosition, 1);

    // Attach the debounced event handler to both resize and scroll events.
    window.addEventListener('resize', debouncedUpdateButtonPosition);
    window.addEventListener('scroll', debouncedUpdateButtonPosition, true);

    // Update position immediately without waiting for debounce.
    updateButtonPosition();

    // Clean up.
    return () => {
      window.removeEventListener('resize', debouncedUpdateButtonPosition);
      window.removeEventListener('scroll', debouncedUpdateButtonPosition, true);
    };
  }, [containerRef]);

  const handleOpen = () => {
    hideAlert('ALERT_UPLOAD_FAILED');
    hideAlert('ALERT_UPLOAD_SUCCESSFUL');
    setOpen(true);
  };

  const handleClose = () => {
    if (!isUploading) {
      setOpen(false);
      if (displayMode === 'inline') {
        setVideoUploaded(true);
      }
    }
  };

  const executeReCaptcha = async () => {
    if (reCaptchaRef.current) {
      try {
        const result = await reCaptchaRef.current.executeAsync();
        return result;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
    return null;
  };

  const resetReCaptcha = () => {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.reset();
    }
  };

  const handleVideoUploadSubmit = async (data: VideoUploadFormData) => {
    setIsUploading(true);

    try {
      const reCaptchaToken = await executeReCaptcha();
      if (!reCaptchaToken) {
        throw new Error('reCaptcha failed');
      }
      await onVideoUpload({ ...data, reCaptchaToken });
      showAlert('ALERT_UPLOAD_SUCCESSFUL');
    } catch (err) {
      showAlert('ALERT_UPLOAD_FAILED');
    }

    setIsUploading(false);
    resetReCaptcha();
    handleClose();
  };

  const renderForm = () => (
    <VideoUploadForm
      videoCollector={videoCollector}
      onSubmit={handleVideoUploadSubmit}
      reCaptchaRef={reCaptchaRef}
      displayMode={displayMode}
      uploadProgress={uploadProgress}
    />
  );

  const renderUploadButton = () => {
    const buttonStyle = {
      position: 'fixed',
      bottom: `${buttonPosition.bottom}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      ...(buttonPosition.bottom === 16 && { position: 'fixed' }),
    };

    return (
      <Button variant="contained" sx={buttonStyle} onClick={handleOpen} color="secondary">
        {translationsPublicVideoCollector.UploadYourVideo[lang]}
      </Button>
    );
  };

  if (videoUploaded && displayMode === 'inline' && !isUploading) {
    return (
      <Stack justifyContent="center" alignItems="center" spacing={3}>
        <Typography variant="titleMedium" textAlign="center">
          {translationsPublicVideoCollector.ThankYouVideoUploaded[lang]}
        </Typography>
        <Typography variant="bodyMedium" textAlign="center">
          {translationsPublicVideoCollector.VideoUploadSuccessMessage[lang]}
        </Typography>
        <Button
          onClick={() => {
            setVideoUploaded(false);
            handleOpen();
          }}
          variant="contained"
        >
          {translationsPublicVideoCollector.UploadAnotherVideo[lang]}
        </Button>
      </Stack>
    );
  }

  switch (displayMode) {
    case 'dialog':
      return (
        <>
          {renderUploadButton()}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="video-upload-modal"
            aria-describedby="video-upload-form"
            PaperProps={{ sx: { borderRadius: 4 } }}
          >
            <DialogContent>{renderForm()}</DialogContent>
          </Dialog>
        </>
      );

    case 'drawer':
      return (
        <>
          {renderUploadButton()}
          <Drawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiDrawer-paper': {
                borderRadius: '10px 10px 0 0',
                height: `calc(100% - ${8 * 8}px)`,
                mt: 8,
              },
            }}
          >
            <Stack justifyContent="center" alignItems="center" spacing={0}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                <Button onClick={handleClose}>
                  <LeftIcon />
                </Button>
              </Box>
              <Box sx={{ textAlign: 'flex-start', m: 2, mt: 0 }}>{renderForm()}</Box>
            </Stack>
          </Drawer>
        </>
      );

    case 'inline':
      return (
        <Stack justifyContent="center" alignItems="center" spacing={0}>
          {renderForm()}
        </Stack>
      );

    default:
      return null;
  }
}

export default ResponsiveVideoUpload;
