import { useMemo } from 'react';
import { gql } from '@apollo/client/index';

import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_REQUEST_DETAILS_FRAGMENT } from '../../../../fragments/videoRequests';

export class VideoRequestActions {
  #backOfficeClient;

  /**
   * @param {import('src/context/ApolloClientContext').ApolloClient} backOfficeClient
   */
  constructor(backOfficeClient) {
    this.#backOfficeClient = backOfficeClient;
  }

  createVideoRequest({
    encodedOrgId,
    lang,
    orgId,
    status,
    questions,
    internalNote,
    tags,
    contactForm,
    callToAction,
  }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_REQUEST_DETAILS_FRAGMENT}
        mutation createVideoRequest(
          $encodedOrgId: String
          $orgId: Int!
          $status: String
          $questions: [QuestionInput]
          $internalNote: TranslationInput
          $tags: [Int]
          $contactForm: ContactFormInput
          $callToAction: CallToActionInput
        ) {
          createVideoRequest(
            encodedOrgId: $encodedOrgId
            input: {
              orgId: $orgId
              status: $status
              questions: $questions
              internalNote: $internalNote
              tags: $tags
              contactForm: $contactForm
              callToAction: $callToAction
            }
          ) {
            ...VideoRequestDetails
          }
        }
      `,
      variables: {
        encodedOrgId,
        orgId,
        status,
        lang,
        questions,
        internalNote: { [lang]: encodeURIComponent(internalNote) },
        tags,
        contactForm,
        callToAction,
      },
    });
  }

  updateVideoRequest({
    encodedOrgId,
    lang,
    requestId,
    orgId,
    status,
    questions,
    internalNote,
    tags,
    contactForm,
    callToAction,
    hideFromLibrary,
    newStoryLineInclude,
    recipientIds,
  }) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_REQUEST_DETAILS_FRAGMENT}
        mutation updateVideoRequest(
          $encodedOrgId: String
          $requestId: Int
          $orgId: Int!
          $status: String
          $questions: [QuestionInput]
          $internalNote: TranslationInput
          $tags: [Int]
          $contactForm: ContactFormInput
          $callToAction: CallToActionInput
          $hideFromLibrary: Boolean
          $newStoryLineInclude: [Int]
          $recipientIds: [Int]
        ) {
          updateVideoRequest(
            encodedOrgId: $encodedOrgId
            input: {
              id: $requestId
              orgId: $orgId
              status: $status
              questions: $questions
              internalNote: $internalNote
              tags: $tags
              contactForm: $contactForm
              callToAction: $callToAction
              hideFromLibrary: $hideFromLibrary
              storylineInclude: $newStoryLineInclude
              recipientIds: $recipientIds
            }
          ) {
            ...VideoRequestDetails
          }
        }
      `,
      variables: {
        encodedOrgId,
        requestId,
        orgId,
        status,
        lang,
        questions,
        internalNote: { [lang]: encodeURIComponent(internalNote) },
        tags,
        contactForm,
        callToAction,
        hideFromLibrary,
        newStoryLineInclude,
        recipientIds,
      },
    });
  }

  deleteVideoRequest(encodedOrgId, videoRequestId, orgId) {
    const input = {
      id: videoRequestId,
      orgId,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation DeleteVideoRequest($encodedOrgId: String, $input: VideoRequestInput) {
          deleteVideoRequest(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  sendUploadLink({ encodedOrgId, orgId, videoRequestId, sendText }) {
    const input = { orgId, videoRequestId, sendText };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation SendVideoRequestUploadLink(
          $encodedOrgId: String
          $input: VideoRequestUploadLinkInput
        ) {
          sendVideoRequestUploadLink(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  sendReminderLink({ encodedOrgId, orgId, videoRequestId, sendText, sendEmail }) {
    const input = {
      orgId,
      videoRequestId,
      sendTextReminder: sendText,
      sendEmailReminder: sendEmail,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation SendVideoRequestReminderLink(
          $encodedOrgId: String
          $input: VideoRequestReminderInput
        ) {
          sendVideoRequestReminderLink(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }
}

export const useVideoRequestActions = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new VideoRequestActions(backOfficeClient), [backOfficeClient]);
};
