import { Divider } from '@mui/material';
import PropTypes from 'prop-types';

export default function SimpleDivider({
  orientation = 'horizontal',
  variant = 'fullWidth',
  style,
}) {
  return (
    <Divider
      variant={variant}
      orientation={orientation}
      style={{
        borderWidth: '0',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderBottomWidth: 'thin',
        width: '100%',
        ...style,
      }}
    />
  );
}
SimpleDivider.propTypes = {
  orientation: PropTypes.string,
  style: PropTypes.object,
};
