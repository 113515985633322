/* eslint-disable import/no-import-module-exports */
/* eslint-disable react/jsx-filename-extension */

import { createRoot } from 'react-dom/client';
import { ReactNode, StrictMode } from 'react';
import App from './App';
import './index.scss';

const enableStrictMode = (elem: ReactNode) => {
  return <StrictMode>{elem}</StrictMode>;
};

const rootEl = document.getElementById('root');
const isDev = process.env.NODE_ENV === 'development';

createRoot(rootEl!).render(isDev ? enableStrictMode(<App />) : <App />);

if (module.hot) {
  module.hot.accept('./App', () => {
    import('./App').then((module) => {
      const NextApp = module.default;
      createRoot(rootEl!).render(isDev ? enableStrictMode(<NextApp />) : <NextApp />);
    });
  });
}
