import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  IconMovie,
  IconHelp,
  IconPopcorn,
  IconTicket,
  IconStoryline,
  IconNewVideo,
} from '../../common/icons';

import ThemeContext from '../../../utils/ThemeContext';
import WaitingForReplyOutlinedIcon from '../../common/icons/waitingForReply/WaitingForReplyOutlined';
import SettingsOutlinedIcon from '../../common/icons/settings/SettingsOutlined';

export const availableToDos = {
  videoRequestNew: 'videoRequestNew',
  videoRequestDaysSinceNewRequest: 'videoRequestDaysSinceNewRequest',
  videoRequestsDaysSinceNewRequestSent: 'videoRequestsDaysSinceNewRequestSent',
  videoRequestPending: 'videoRequestPending',
  storylineNew: 'storylineNew',
  storylineLive: 'storylineLive',
  storylineDaysOldLive: 'storylineDaysOldLive',
  storylineDaysSinceLastCreation: 'storylineDaysSinceLastCreation',
  videoCardNew: 'videoCardNew',
  videoCardApproved: 'videoCardApproved',
  videoCardNoStoryline: 'videoCardNoStoryline',
  videoCardDaysSinceLastRecipientUpload: 'videoCardDaysSinceLastRecipientUpload',
  videoCardDaysSinceLastUpload: 'videoCardDaysSinceLastUpload',
  orgLessThan5VideosSinceStart: 'orgLessThan5VideosSinceStart',
  orgNoAutoRemindersSettings: 'orgNoAutoRemindersSettings',
  orgUpdateRemindersSettings: 'orgUpdateRemindersSettings',
  orgInactiveRemindersSettings: 'orgInactiveRemindersSettings',
  orgNoAutoRequestsSettings: 'orgNoAutoRequestsSettings',
  orgUpdateRequestsSettings: 'orgUpdateRequestsSettings',
  orgInactiveRequestsSettings: 'orgInactiveRequestsSettings',
  videoRequestOpenReview: 'videoRequestOpenReview',
};

const videoActionOption = ({ basicOrgData }) => ({
  videoRequest: {
    path:
      basicOrgData?.premiumFeatures?.excludeFromVimeo &&
      basicOrgData?.premiumFeatures?.requestsLibraryBeta
        ? `/${basicOrgData.orgNameSlug}/backoffice/requests`
        : `/${basicOrgData.orgNameSlug}/backoffice/planner`,
  },
  videoCard: {
    path:
      basicOrgData?.premiumFeatures?.excludeFromVimeo &&
      basicOrgData?.premiumFeatures?.requestsLibraryBeta
        ? `/${basicOrgData.orgNameSlug}/backoffice/library`
        : `/${basicOrgData.orgNameSlug}/backoffice/planner`,
  },
  storyline: { path: `/${basicOrgData.orgNameSlug}/backoffice/stories` },
  remindersSettings: {
    path: `/${basicOrgData.orgNameSlug}/backoffice/settings/automation#reminders`,
  },
  requestsSettings: {
    path: `/${basicOrgData.orgNameSlug}/backoffice/settings/automation#requests`,
  },
});

const optionIncludes = (toDoItemAction, propertyValue) => {
  return toDoItemAction?.toLowerCase()?.includes(propertyValue?.toLowerCase());
};

const actionIncludedInArray = (action, array) => {
  return array?.some((t) => optionIncludes(action, t));
};

export const getActionValuePath = ({ toDoItem, basicOrgData }) => {
  const options = videoActionOption({ basicOrgData });
  let path = null;

  Object.keys(options).forEach((property) => {
    if (optionIncludes(toDoItem?.action, property)) {
      path = options[property].path;
    }
  });

  return path;
};

export const actionValue = ({ toDoItem, basicOrgData }) => {
  const options = videoActionOption({ basicOrgData });
  let value = null;

  Object.keys(options).forEach((property) => {
    if (optionIncludes(toDoItem?.action, property)) {
      value = property;
    }
  });

  return value;
};

const newRequestIconToDos = [
  availableToDos.videoRequestNew,
  availableToDos.videoRequestDaysSinceNewRequest,
  availableToDos.videoRequestsDaysSinceNewRequestSent,
  availableToDos.videoCardNew,
  availableToDos.videoCardDaysSinceLastRecipientUpload,
];
const isNewRequestIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, newRequestIconToDos);
};

const pendingRequestIconToDos = [availableToDos.videoRequestPending];
const isPendingRequestIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, pendingRequestIconToDos);
};

const storylineIconToDos = [
  availableToDos.storylineNew,
  availableToDos.storylineLive,
  availableToDos.storylineDaysOldLive,
  availableToDos.storylineDaysSinceLastCreation,
];
const isStorylineIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, storylineIconToDos);
};

const readyToPublishIconToDos = [
  availableToDos.videoCardApproved,
  availableToDos.videoCardDaysSinceLastUpload,
];
const isReadyToPublishIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, readyToPublishIconToDos);
};

const libraryIconToDos = [availableToDos.videoCardNoStoryline];
const isLibraryIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, libraryIconToDos);
};

const helpIconToDos = [availableToDos.orgLessThan5VideosSinceStart];
const isHelpIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, helpIconToDos);
};

const settingsIconToDos = [
  availableToDos.orgNoAutoRemindersSettings,
  availableToDos.orgUpdateRemindersSettings,
  availableToDos.orgInactiveRemindersSettings,
  availableToDos.orgNoAutoRequestsSettings,
  availableToDos.orgUpdateRequestsSettings,
  availableToDos.orgInactiveRequestsSettings,
];
const isSettingsIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, settingsIconToDos);
};

const reviewIconToDos = [availableToDos.videoRequestOpenReview];
const isReviewIcon = (toDoItemAction) => {
  return actionIncludedInArray(toDoItemAction, reviewIconToDos);
};

export function ActionIcon({ toDoItem = { action: '' }, style = {} }) {
  const theme = useContext(ThemeContext);
  const defaultFill = theme.palette.secondary.main;

  if (isNewRequestIcon(toDoItem?.action)) {
    return <IconNewVideo fill={defaultFill} style={{ ...style }} />;
  }
  if (isPendingRequestIcon(toDoItem?.action)) {
    return <WaitingForReplyOutlinedIcon fill={defaultFill} />;
  }
  if (isStorylineIcon(toDoItem?.action)) {
    return <IconStoryline fill={defaultFill} style={{ ...style }} />;
  }
  if (isReadyToPublishIcon(toDoItem?.action)) {
    return <IconTicket fill={defaultFill} style={{ ...style }} />;
  }
  if (isLibraryIcon(toDoItem?.action)) {
    return <IconPopcorn fill={defaultFill} style={{ ...style }} />;
  }
  if (isHelpIcon(toDoItem?.action)) {
    return <IconHelp fill={defaultFill} style={{ ...style }} />;
  }
  if (isSettingsIcon(toDoItem?.action)) {
    return <SettingsOutlinedIcon fill={defaultFill} />;
  }
  if (isReviewIcon(toDoItem?.action)) {
    return <IconMovie fill={defaultFill} style={{ ...style }} />;
  }
  return null;
}

ActionIcon.propTypes = {
  toDoItem: PropTypes.shape({
    action: PropTypes.string,
  }),
  style: PropTypes.shape({}),
};
