import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const StyleDialog = styled(Dialog)(({ theme }) => ({
  '& .premium-feature-info': {
    fontSize: '11px',
    textTransform: 'uppercase',
  },
  '& .MuiDialogTitle-root': {},
  '& .MuiPaper-root': {
    overflowY: 'scroll',
    borderRadius: '20px',
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
    },
  },
  '& .MuiPaper-root .dialog-footer': {
    textAlign: 'center',
    flex: 'none',
    padding: '8px 16px',
    borderTop: '1px solid #ededee',
    display: 'flex',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',

    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
}));
