import React, { useState, useRef, useEffect, useContext } from 'react';
import { Grid, Card, Box } from '@mui/material';

import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';
import translationsSignup from '../../../utils/translationsSignup';
import { signupStatuses } from '../../../utils/utils';
import { initiateAccountCreation, verifyPhoneNumber, updateAccount } from './freemiumApi';
import ThemeContext from '../../../utils/ThemeContext';
import SuccessScreen from './SuccessScreen';
import SignUpCard from './SignUpCard';

import PageFooter from '../page-footer';

function FreemiumSignup() {
  const theme = useContext(ThemeContext);
  const lang = useLanguage();
  const setLanguage = useSetLanguage();

  const defaultLanguage = () => {
    // Detect System language
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage) {
      if (userLanguage.includes('en')) {
        return 'en';
      }
      if (userLanguage.includes('sv')) {
        return 'sv';
      }
    }
    return 'en';
  };

  const reCaptchaRef = useRef(null);

  const [status, setStatus] = useState(signupStatuses.initial);
  const [token, setToken] = useState(null);
  const [userOrg, setUserOrg] = useState(null);

  const [errorMsg, setErrorMsg] = useState(null);

  const [formData, setFormData] = useState({
    firstName: { value: '', error: '', valid: false, hasOnBlur: false, step: 0 },
    lastName: { value: '', error: '', valid: false, hasOnBlur: false, step: 0 },
    phoneNumber: { value: '', error: '', valid: false, hasOnBlur: false, step: 0 },
    email: { value: '', error: '', valid: false, hasOnBlur: false, step: 0 },
    orgName: { value: '', error: '', valid: false, hasOnBlur: false, step: 1 },
    domain: {
      value: '',
      error: '',
      valid: false,
      hasOnBlur: false,
      step: 1,
    },
    orgLang: {
      value: defaultLanguage(),
      error: '',
      valid: true,
      hasOnBlur: true,
      step: 1,
    },
    phoneNumberVerificationCode: {
      value: '',
      error: '',
      valid: false,
      hasOnBlur: false,
      step: 'verifyPhoneNumber',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const steps = [
    ...new Set(
      Object.values(formData)
        .filter((field) => typeof field.step === 'number')
        .map((field) => field.step),
    ),
  ];

  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    setErrorMsg(null);
  }, [activeStep]);

  const handleChange = (e) => {
    const { name, value, error, valid, hasOnBlur } = e.target;
    const objectValue = { ...formData[name] };

    if (value || value === '') {
      objectValue.value = value;
    }
    if (error === '' || error) {
      objectValue.error = error;
    }
    if (valid === true || valid === false) {
      objectValue.valid = valid;
    }
    if (hasOnBlur === true || hasOnBlur === false) {
      objectValue.hasOnBlur = hasOnBlur;
    }
    setFormData({ ...formData, [name]: { ...objectValue } });
  };

  const isStepValid = () => {
    return Object.keys(formData).every((key) => {
      if (formData[key].step !== activeStep) return true;
      return formData[key].valid === true;
    });
  };

  const isStepsValid = () => {
    return Object.keys(formData).every((key) => {
      if (activeStep === steps.length - 1 && formData[key].step === 'verifyPhoneNumber') {
        return true;
      }
      return formData[key].valid === true;
    });
  };

  const handleOnSubmit = (reCAPTCHAToken) => {
    if (status === signupStatuses.waitingPhoneNumberVerification) {
      setStatus(signupStatuses.verifyingPhoneNumber);
      setIsLoading(true);
      verifyPhoneNumber({
        phoneNumberVerificationCode: formData.phoneNumberVerificationCode.value,
        token,
      })
        .then((response) => {
          if (response.error) {
            setStatus(signupStatuses.error);
            setErrorMsg(translationsSignup.internalerror[lang]);
          } else {
            setStatus(signupStatuses.success);
            setUserOrg(response);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setStatus(signupStatuses.error);
          setErrorMsg(translationsSignup.internalerror[lang]);
          setIsLoading(false);
        });
    } else {
      setStatus(signupStatuses.creatingUser);
      setIsLoading(true);
      initiateAccountCreation({
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        phoneNumber: formData.phoneNumber.value,
        email: formData.email.value,
        orgName: formData.orgName.value,
        domain: formData.domain.value,
        orgLang: formData.orgLang.value,
        reCAPTCHAToken,
      })
        .then((response) => {
          if (response.error) {
            setStatus(signupStatuses.error);
            if (response.error.message.includes('Internal error, error code: ')) {
              const errorCode = response.error.message.split('Internal error, error code: ')[1];
              const errorMessage = translationsSignup.internalErrorCode[errorCode][lang];
              if (errorMessage) {
                setErrorMsg(errorMessage);
              } else {
                setErrorMsg(translationsSignup.internalErrorCode[errorCode][lang]);
              }
            } else {
              setErrorMsg(translationsSignup.internalerror[lang]);
            }
            setIsLoading(false);
            reCaptchaRef.current.reset();
            return;
          }
          setToken(response.token);
          setStatus(signupStatuses.waitingPhoneNumberVerification);
          setActiveStep(activeStep + 1);
          setIsLoading(false);
        })
        .catch(() => {
          setStatus(signupStatuses.error);
          setErrorMsg(translationsSignup.internalerror[lang]);
          setIsLoading(false);
          reCaptchaRef.current.reset();
        });
    }
  };

  const onSubmitAsync = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (token?.length > 0) {
      setStatus(signupStatuses.waitingPhoneNumberVerification);
      setIsLoading(true);
      updateAccount({
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        phoneNumber: formData.phoneNumber.value,
        email: formData.email.value,
        orgName: formData.orgName.value,
        domain: formData.domain.value,
        orgLang: formData.orgLang.value,
        token,
      })
        .then((response) => {
          if (response.error) {
            setStatus(signupStatuses.error);
            if (response.error.message.includes('Internal error, error code: ')) {
              const errorCode = response.error.message.split('Internal error, error code: ')[1];
              const errorMessage = translationsSignup.internalErrorCode[errorCode][lang];
              if (errorMessage) {
                setErrorMsg(errorMessage);
              } else {
                setErrorMsg(translationsSignup.internalErrorCode[errorCode][lang]);
              }
            } else {
              setErrorMsg(translationsSignup.internalerror[lang]);
            }
            setIsLoading(false);
            return;
          }
          setToken(response.token);
          setStatus(signupStatuses.waitingPhoneNumberVerification);
          setActiveStep(activeStep + 1);
          setIsLoading(false);
        })
        .catch(() => {
          setStatus(signupStatuses.error);
          setErrorMsg(translationsSignup.internalerror[lang]);
          setIsLoading(false);
        });
    } else {
      setStatus(signupStatuses.pendingReCaptcha);
      setIsLoading(true);
      reCaptchaRef.current
        .executeAsync()
        .then((result) => {
          setStatus(signupStatuses.pending);
          handleOnSubmit(result);
        })
        .catch(() => {
          setStatus(signupStatuses.error);
          setErrorMsg(translationsSignup.internalerror[lang]);
          setIsLoading(false);
        });
    }
  };

  return (
    <div style={{ position: 'relative', background: theme.palette.primary.main }}>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: '100vh', pt: 3, px: 3, pb: 1, minWidth: '100vw' }}
      >
        <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid container spacing={1}>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img src="lifeinside_logo_horizontal-326x52.svg" width="184" alt="Life Inside" />
              </Box>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6} md={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    color: 'white',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <img src="globe-icon.svg" alt="Globe" style={{ width: 18, height: 18 }} />
                  <select
                    value={lang}
                    onChange={(e) => setLanguage(e.target.value)}
                    style={{
                      flex: 1,
                      height: '100%',
                      paddingLeft: 8,
                      paddingRight: 32,
                      paddingTop: 6,
                      paddingBottom: 6,
                      backgroundColor: 'transparent',
                      color: 'white',
                      border: 'none',
                      fontSize: 14,
                      fontFamily: 'Inter',
                      fontWeight: 500,
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      appearance: 'none',
                      backgroundImage: 'url(arrow_drop_down_24px.svg)',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right center',
                      backgroundSize: '24px',
                      MozAppearance: 'none',
                      WebkitAppearance: 'none',
                    }}
                  >
                    <option value="en">English</option>
                    <option value="sv">Svenska</option>
                  </select>
                </div>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
            md={12}
            style={{ position: 'relative' }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              sx={{
                border: '0px',
                borderRadius: '16px',
                p: 2,
                pt: 3,
                m: 2,
                maxWidth: '500px',
              }}
              elevation={0}
            >
              {status === signupStatuses.success &&
              !isLoading &&
              userOrg?.user?.id &&
              userOrg?.organization?.id &&
              userOrg?.organization?.orgNameSlug ? (
                <SuccessScreen language={lang} />
              ) : (
                <SignUpCard
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  onSubmitAsync={onSubmitAsync}
                  setErrorMsg={setErrorMsg}
                  activeStep={activeStep}
                  setStatus={setStatus}
                  status={status}
                  setActiveStep={setActiveStep}
                  steps={steps}
                  formData={formData}
                  handleChange={handleChange}
                  token={token}
                  handleOnSubmit={handleOnSubmit}
                  isStepsValid={isStepsValid}
                  setToken={setToken}
                  isStepValid={isStepValid}
                  errorMsg={errorMsg}
                  reCaptchaRef={reCaptchaRef}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        <PageFooter showReCAPTCHA />
      </Grid>
    </div>
  );
}

export default FreemiumSignup;
