export const green = '#2E7D32';
export const pink = '#9400FE';
export const blue = '#121212';
export const red = '#D32F2F';
export const fontFamily = "'Inter', sans-serif";
export const textColorBlack = '#292929';
export const textColorBlackFaded = '#757584';
export const textInputFieldColor = '#2c2c2c';
export const textInputFieldBorderColorSelected = '#80bdff';
export const textInputFieldBorderColor = '#dbdbdd';

export const labelB = {
  fontFamily,
  fontWeight: '400',
  letterSpacing: '-0.5px',
  lineHeight: '24px',
  marginBottom: '0px',
  marginRight: '10px',
  marginTop: '10px',
  color: textColorBlack,
};

export const labelSpanDescription = {
  fontFamily,
  fontWeight: '300',
  letterSpacing: '-0.5px',
  color: textColorBlackFaded,
  fontSize: '14px',
};

export const h1 = {
  color: blue,
  fontFamily,
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  lineHeight: '32px',
  letterSpacing: '-1px',
};

export const h3 = {
  color: blue,
  marginBottom: '0',
  marginTop: '0',
  fontWeight: '400',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  fontFamily,
};

export const p = {
  fontFamily,
  fontWeight: '300',
  letterSpacing: '-0.5px',
  color: textColorBlackFaded,
  fontSize: '14px',
  lineHeight: '21px',
  margin: '0px',
};

export const textInputField = {
  fontFamily,
  fontSize: '14px',
  color: textInputFieldColor,
  border: `1px solid ${textInputFieldBorderColor}`,
  borderRadius: '5px',
  width: '100%',
  height: '40px',
  lineHeight: '40px',
};

export const selectInputField = (value) => ({
  borderRadius: '5px',
  fontFamily,
  ...textInputField,
  color: value ? textInputFieldColor : 'grey',
  border: 'unset',
  '&.Mui-focused': {
    outline: 0,
    borderColor: 'red',
    boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(239, 239, 239, 0.3)',
  },

  '&.MuiOutlinedInput-root': {
    borderWidth: '1px',
    borderColor: 'unset',
    '& fieldset': {
      borderWidth: '1px',
      borderColor: textInputFieldBorderColor,
    },
    '&:hover fieldset': {
      borderWidth: '1px',
      borderColor: textInputFieldBorderColor,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: textInputFieldBorderColorSelected,
    },
  },
});

export const errorText = {
  fontFamily,
  fontSize: '12px',
  color: 'red',
  marginTop: '5px',
  marginBottom: '0px',
  marginLeft: '16px',
  marginRight: '0px',
  position: 'absolute',
};

export const errorInputField = {
  borderColor: 'red',
  boxShadow: '0 0 0 0.2rem rgba(244, 67, 54, 0.25)',
  outline: 'none !important',
  border: '1px solid rgb(244, 67, 54)',
};
