import OutlinedInput from '@mui/material/OutlinedInput';

import ThemeContext from '../../../utils/ThemeContext';

export const outlinedInput = () => {
  return (
    <OutlinedInput
      sx={{
        fontSize: '14px',
        width: '100%',
        height: '40px',
        lineHeight: '40px',
        padding: '0 px',
        border: '0px solid #dbdbdd',
        fontFamily: ['Inter', 'sans-serif'].join(','),
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#80bdff',
          border: '1px solid #80bdff',
          outline: '0',
          boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
        },
      }}
    />
  );
};

export default function getTheme(themeName = 'neutral') {
  if (themeName === 'active') {
    return { theme: ThemeContext, themeString: 'active', outlinedInput };
  }

  if (themeName === 'neutral') {
    return { theme: ThemeContext, themeString: 'neutral' };
  }

  return { theme: ThemeContext, themeString: 'neutral' };
}
