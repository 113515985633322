import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useOutletContext, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useLanguage } from 'src/context/LanguageContext';
import { Button, Checkbox, Snackbar, Stack } from '@mui/material';
import { useMutation } from '@apollo/client';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import VideoRequestEmailTemplate from './VideoRequestEmailTemplate';
import { UPDATE_PROMO_RECORD_VIDEO_PORTRAIT_MUTATION } from './mutations';
import { RouteOutletContext } from './types';
import useCheckPermissionsAndNavigate from '../Permissions/CheckPermissionsAndNavigate';

const translations = {
  promoteRecordVideoPortrait: {
    en: 'Promote record video in portrait mode',
    sv: 'Föredra att inspelningar görs i porträttläge',
  },
  save: {
    en: 'Save',
    sv: 'Spara',
  },
  exampleEmail: {
    en: 'Example email',
    sv: 'Exempel email',
  },
  successMessage: {
    en: 'Update successful!',
    sv: 'Uppdateringen lyckades!',
  },
  errorMessage: {
    en: 'Error updating settings',
    sv: 'Fel vid uppdatering av inställningar',
  },
};

function VideoRequestEmail() {
  const lang = useLanguage();

  const { basicOrgData } = useOutletContext<RouteOutletContext>();

  const [promoteRecordVideoPortrait, setPromoteRecordVideoPortrait] = useState(
    basicOrgData.promoteRecordVideoPortrait ?? true,
  );

  const navigate = useNavigate();

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  const orgHasVideoRequestEmailCustomization =
    basicOrgData?.premiumFeatures?.videoRequestEmailCustomization ?? false;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [updatePromoteRecordVideoPortrait, { loading: mutationLoading }] = useMutation(
    UPDATE_PROMO_RECORD_VIDEO_PORTRAIT_MUTATION,
    {
      onCompleted: () => {
        setSuccess(true);
      },
      onError: (err) => {
        console.error(err);
        setError(translations.errorMessage[lang]);
      },
    },
  );

  const isLoading = mutationLoading;

  const handleUpdatePromoteRecordVideoPortrait = async () => {
    if (isLoading) return;
    setError('');
    try {
      await updatePromoteRecordVideoPortrait({
        variables: {
          promoteRecordVideoPortrait,
          encodedOrgId: basicOrgData.encodedId,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!orgHasVideoRequestEmailCustomization) {
      navigate(`/${basicOrgData.orgNameSlug}/backoffice/settings`);
    }
  }, [orgHasVideoRequestEmailCustomization, navigate, basicOrgData.orgNameSlug]);

  if (userLoading) return <LoadingIndicator />;

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ pt: 3 }}
    >
      <Grid item>
        <Container maxWidth="md">
          <Grid item>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                <Checkbox
                  data-testid="promote-record-video-portrait-checkbox"
                  checked={promoteRecordVideoPortrait}
                  onChange={() => setPromoteRecordVideoPortrait(!promoteRecordVideoPortrait)}
                  size="small"
                  disabled={isLoading}
                />
                <Typography variant="titleMedium" data-testid="promote-record-video-portrait-text">
                  {translations.promoteRecordVideoPortrait[lang]}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <Button
                  variant="contained"
                  onClick={handleUpdatePromoteRecordVideoPortrait}
                  data-testid="save-button"
                  disabled={isLoading}
                >
                  {translations.save[lang]}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item />
          <Grid item sx={{ pt: 3 }}>
            <Divider />
          </Grid>
          <Grid item>
            <Box
              maxWidth={1000}
              sx={{
                position: 'relative',
              }}
            >
              <VideoRequestEmailTemplate
                lang={lang}
                orgName={basicOrgData.orgName}
                promotePortraitVideo={promoteRecordVideoPortrait}
                data-testid="video-request-email-template"
              />

              <Box
                sx={{
                  position: 'absolute',
                  top: 32,
                  left: 40,
                  right: -2,
                  backgroundColor: 'primary.main',
                  opacity: 0.85,
                  index: 1,
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="titleMedium"
                  align="center"
                  color="text.light"
                  style={{
                    width: '100%',
                  }}
                  sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  data-testid="example-email"
                >
                  {translations.exampleEmail[lang]}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Container>
      </Grid>
      <Snackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => setSuccess(false)}
        message={translations.successMessage[lang]}
        color="success"
        data-testid="snackbar-success"
      />
      <Snackbar
        open={error !== ''}
        autoHideDuration={3000}
        onClose={() => setError('')}
        message={error}
        color="error"
        data-testid="snackbar-error"
      />
    </Grid>
  );
}

export default VideoRequestEmail;
