import React, { useRef } from 'react';
import { Box, CardMedia } from '@mui/material';
import EmptyStoryline from 'src/components/common/icons/emptyStoryline/EmptyStoryline';

interface StoryMediaProps {
  cardWidth: number | string;
  isActive: boolean;
  srcImage?: string;
}

function StoryMedia({ cardWidth, isActive, srcImage = undefined }: StoryMediaProps) {
  const mediaRef = useRef<HTMLImageElement | null>(null);

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.style.display = 'none';
  };

  const width = typeof cardWidth === 'number' ? `${cardWidth}px` : cardWidth;
  const height = typeof cardWidth === 'number' ? `${cardWidth * (10 / 16)}px` : 'auto';

  return (
    <Box sx={{ position: 'relative', width, height }} data-testid="story-media">
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
        data-testid="empty-storyline"
        aria-hidden="true"
      >
        <EmptyStoryline isActive={isActive} />
      </Box>
      <CardMedia
        component="img"
        height="100%"
        width="100%"
        image={srcImage}
        onError={handleImageError}
        sx={{
          position: 'relative',
          zIndex: 2,
          filter: isActive ? 'none' : 'grayscale(100%)',
        }}
        ref={mediaRef}
        data-testid="story-media-image"
      />
    </Box>
  );
}

export default StoryMedia;
