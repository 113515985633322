const devMode = process.env.NODE_ENV !== 'production';

const HOSTNAME_STAGING = 'app.staging.lifeinside.io';
const HOSTNAME_PRODUCTION = 'app.lifeinside.io';

const currentURL = devMode ? window?.location?.href : '';
const urlWithoutPort = devMode ? currentURL?.replace(/:\d+/, '') : '';
const trimmedUrl = devMode ? urlWithoutPort?.match(/^(https?:\/\/[^/]+)/)?.[1] : '';

const backendPaths = (host) => {
  if (devMode && trimmedUrl) {
    return `${trimmedUrl}:8080`;
  }

  switch (host) {
    case 'localhost':
      return 'http://localhost:8080';
    case 'local-app.lifeinside.io':
      return 'https://local-backend.lifeinside.io';
    case HOSTNAME_STAGING:
      return 'https://backend.staging.lifeinside.io';
    case HOSTNAME_PRODUCTION:
      return 'https://backend.lifeinside.io';
    default:
      throw new Error(`Current host (${host}) is not handled`);
  }
};

const frontendPaths = (host) => {
  if (devMode && trimmedUrl) {
    return `${trimmedUrl}:3000`;
  }

  switch (host) {
    case 'localhost':
      return 'http://localhost:3000';
    case 'local-app.lifeinside.io':
      return 'https://local-app.lifeinside.io';
    case HOSTNAME_STAGING:
      return 'https://app.staging.lifeinside.io';
    case HOSTNAME_PRODUCTION:
      return 'https://app.lifeinside.io';
    default:
      throw new Error(`Current host (${host}) is not handled`);
  }
};

const widgetPaths = (host) => {
  if (devMode && trimmedUrl) {
    return `/widget.1.0.0-dev.js`;
  }

  switch (host) {
    case 'localhost':
      return '/widget.1.0.0-dev.js';
    case 'local-app.lifeinside.io':
      return '/widget.1.0.0-dev.js';
    case HOSTNAME_STAGING:
      return '/widget.1.0.0-stage.js';
    case HOSTNAME_PRODUCTION:
      return '/widget.1.0.0.js';
    default:
      throw new Error(`Current host (${host}) is not handled`);
  }
};

const mediaUploadPaths = (host) => {
  if (devMode && trimmedUrl) {
    return `${trimmedUrl}:8081`;
  }
  switch (host) {
    case 'localhost':
      return 'http://localhost:8081';
    case 'local-app.lifeinside.io':
      return 'https://local-media-upload.lifeinside.io';
    case HOSTNAME_STAGING:
      return 'https://media-upload.staging.lifeinside.io';
    case HOSTNAME_PRODUCTION:
      return 'https://media-upload.lifeinside.io';
    default:
      throw new Error(`Current host (${host}) is not handled`);
  }
};

const cdnPaths = (host) => {
  if (devMode && trimmedUrl) {
    return `${trimmedUrl}:8081`;
  }

  switch (host) {
    case 'localhost':
      return 'http://localhost:8081';
    case 'local-app.lifeinside.io':
      return 'https://local-media.lifeinside.io';
    case HOSTNAME_STAGING:
      return 'https://media.staging.lifeinside.io';
    case HOSTNAME_PRODUCTION:
      return 'https://media.lifeinside.io';
    default:
      throw new Error(`Current host (${host}) is not handled`);
  }
};

export const backendPostPath = backendPaths(window.location.hostname);
export const backendApiPath = `${backendPaths(window.location.hostname)}/graphql`;
export const backendApiBackOfficePath = `${backendPaths(
  window.location.hostname,
)}/graphql/backoffice`;
export const backendApiStatsPath = `${backendPaths(window.location.hostname)}/graphql/stats`;
export const backendApiAdminPath = `${backendPaths(window.location.hostname)}/graphql/admin`;
export const frontendPath = frontendPaths(window.location.hostname);
export const widgetPath = widgetPaths(window.location.hostname);
export const mediaUploadPath = `${mediaUploadPaths(window.location.hostname)}/upload`;
export const cdnPath = `${cdnPaths(window.location.hostname)}`;

const auth0Config = ((host) => {
  let domain;
  let clientId;
  let backendAudience;

  if (host === HOSTNAME_STAGING) {
    domain = 'auth.staging.lifeinside.io';
    clientId = 'mF41bjxYMTANyRPMw5qD0hYgqUNN1AV4';
    backendAudience = 'https://backend.staging.lifeinside.io';
  } else if (host === HOSTNAME_PRODUCTION) {
    domain = 'auth.lifeinside.io';
    clientId = '0kKMc4ZKr6YXVTMrPHGFudeeom1C9ItB';
    backendAudience = 'https://backend.lifeinside.io';
  } else {
    // developement
    domain = 'life-inside-local-dev.eu.auth0.com';
    clientId = 'w1j5D561N0cbHxtq9zekWGo3jJ39KnYa';
    backendAudience = 'https://lifeinside.io/backend';
  }
  return { domain, clientId, backendAudience };
})(window.location.hostname);

export const auth0Domain = auth0Config.domain;
export const auth0ClientId = auth0Config.clientId;
export const auth0BackendAudience = auth0Config.backendAudience;
