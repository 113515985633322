export const translations = {
  permissions: {
    en: 'Permissions',
    sv: 'Behörigheter',
  },
  firstName: {
    en: 'First Name',
    sv: 'Förnamn',
  },
  lastName: {
    en: 'Last Name',
    sv: 'Efternamn',
  },
  email: {
    en: 'Email',
    sv: 'E-post',
  },
  noUsersFound: {
    en: 'No users found',
    sv: 'Inga användare hittades',
  },

  permissionTypes: {
    org_admin: {
      en: 'Organization Admin',
      sv: 'Organisationsadministratör',
    },
    org_editor: {
      en: 'Organization Editor',
      sv: 'Organisationsredaktör',
    },
    self_editor: {
      en: 'Self Editor',
      sv: 'Självredaktör',
    },
  },
  userRow: {
    helpText: {
      en: 'Select to change user permission level. Currently disabled.',
      sv: 'Välj för att ändra användarens behörighetsnivå. För närvarande inaktiverad.',
    },
  },
  ariaLabel: {
    en: 'Change permission for user',
    sv: 'Ändra behörighet för användare',
  },
};

export default translations;
