import React from 'react';

import parse from 'html-react-parser';
import { Box } from '@mui/material';
import { frontendPath } from 'src/utils/environment';

import DOMPurify from 'dompurify';

const translations = {
  tipsForRecordingGreatVideoTestimonials: {
    sv: 'Tips för att spela in bra mobilvideos',
    en: 'Tips for recording great video testimonials',
  },
  tipsForRecordingGreatVideoTestimonialsDescription: {
    sv: 'Här är några tips enkla tips som gör dig till videoproffs på nolltid!',
    en: 'Check out these tips on how to take quality videos.',
  },
  verticalNotHorizontal: {
    sv: 'Vertikalt, inte horisontellt',
    en: 'Vertical, not horizontal',
  },
  verticalNotHorizontalDescription: {
    sv: 'Filma din video i stående format. Då säkerställer du att videon ser bra ut på den webbsida där den kommer att visas.',
    en: 'Filming your videos vertical will ensure your video will look good on the website where it will show up.',
  },
  goodLightingIsKey: {
    sv: 'Bra belysning',
    en: 'Good lighting is key',
  },
  goodLightingIsKeyDescription: {
    sv: 'Hitta ett tyst ställe med bra belysning att filma videon på – till exempel nära ett fönster med dagsljus.',
    en: "Find a quiet place to film your video with good lighting. It's as simple as finding a window with natural light.",
  },
  keepItSimple: {
    sv: 'Håll det enkelt',
    en: 'Keep it simple',
  },
  keepItSimpleDescription: {
    sv: 'Ett manus kan vara bra som stöd, men ofta blir det bäst när du talar från hjärtat. Ha kul och var dig själv! 💜',
    en: 'A script might be good, but the best videos are the ones where you simply speak from your heart. Remember, have fun!',
  },
};

const emailVerticalVideoContentString = (lang: Language) => `
<tr>
<td class="m_-708467681402322503dnone" align="center" valign="top">
<table role="presentation" cellpadding="0" cellspacing="0" width="564">
<tbody>
<tr>
<td align="center" valign="top" background="https://ci4.googleusercontent.com/proxy/B2G7D7h1n_jEE1PmfrmgJs57iSyribrij2SsOKFvQu4n-zJVKZvip2R9_yI66PHm8BU5s_9V7iG4qK30CtzJtDIWniHSQLBBpl13Gb9s_XmF5ah-GcpxTCJznWes0OO2gw=s0-d-e1-ft#https://gstatic.com/growthlab/api/EXKStJGabgsP8PbrfC3bGMbG6EgNAgE3uATYFdI2.png" style="background-image:url('https://ci4.googleusercontent.com/proxy/B2G7D7h1n_jEE1PmfrmgJs57iSyribrij2SsOKFvQu4n-zJVKZvip2R9_yI66PHm8BU5s_9V7iG4qK30CtzJtDIWniHSQLBBpl13Gb9s_XmF5ah-GcpxTCJznWes0OO2gw=s0-d-e1-ft#https://gstatic.com/growthlab/api/EXKStJGabgsP8PbrfC3bGMbG6EgNAgE3uATYFdI2.png');background-position:top center;background-repeat:no-repeat;background-size:100% 100%">
<table role="presentation" cellpadding="0" cellspacing="0" width="100%">
<tbody>
<tr>
<td class="m_-708467681402322503pdb36" align="center" valign="top" style="padding-top:35px;padding-bottom:13px">
<table align="center" cellpadding="0" cellspacing="0" role="presentation" width="453" class="m_-708467681402322503wid259">
<tbody>
<tr>
<td align="center" valign="top" width="154" class="m_-708467681402322503dblock m_-708467681402322503margin_0">
<img src="https://25100100.fs1.hubspotusercontent-eu1.net/hubfs/25100100/Assets%20for%20Mailgun%20emails/vertical%20not%20horizontal.png" aria-hidden="true" width="154" style="display:block">
</td>
<td aria-hidden="true" align="center" valign="top" width="40" class="m_-708467681402322503dblock">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" width="40" style="display:block">
</td>
<td align="center" valign="top" width="259" class="m_-708467681402322503dblock">
<table align="center" cellpadding="0" cellspacing="0" role="presentation">
<tbody>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;font-weight:700;color:#202124">
${translations.verticalNotHorizontal[lang]}
</td>
</tr>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px">
${translations.verticalNotHorizontalDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" aria-hidden="true">
<a href="#m_-708467681402322503_" style="text-decoration:none"><img src="https://ci4.googleusercontent.com/proxy/8U7-dXdBh2tCa4zequ_b_eNVsYnLqbCCJvlAFBwBupcdc_upkEp5ofHSq9iMFmTXUSX9spkqnFM4oN1kOJnVCB79YB9tp7HIB5VcPMDZP5jOSLeFkCuoT_Hav8mNuhjNsg=s0-d-e1-ft#https://gstatic.com/growthlab/api/CTQ6uOg3Lv8kgYKPCduIPDx9e5y1lHYWaxEumrh4.png" width="564" style="display:block"></a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="m_-708467681402322503dblock" align="center" valign="top" style="display:none">
<table role="presentation" cellpadding="0" cellspacing="0" width="314">
<tbody>
<tr>
<td align="center" valign="top" background="https://ci3.googleusercontent.com/proxy/Gz8mhq6TGzkCYH7H-vwOC-OKsyZ8i-sYGkvExaZBaJotJv0fUjc9j77Ih7j1t-zamYnT08Yd9dc-hZtkNHmEMKnmYn8r72JEHUMFJT88spiAluYX14iWAfR4cQX8gol70A=s0-d-e1-ft#https://gstatic.com/growthlab/api/jjiKTuiVjg9Se7tJqs4SOah5njcPMXCh73DDNaxO.png" style="background-image:url('https://ci3.googleusercontent.com/proxy/Gz8mhq6TGzkCYH7H-vwOC-OKsyZ8i-sYGkvExaZBaJotJv0fUjc9j77Ih7j1t-zamYnT08Yd9dc-hZtkNHmEMKnmYn8r72JEHUMFJT88spiAluYX14iWAfR4cQX8gol70A=s0-d-e1-ft#https://gstatic.com/growthlab/api/jjiKTuiVjg9Se7tJqs4SOah5njcPMXCh73DDNaxO.png');background-position:top center;background-repeat:no-repeat;background-size:100% 100%">
<table role="presentation" cellpadding="0" cellspacing="0" width="100%">
<tbody>
<tr>
<td align="center" valign="top" style="padding-top:35px;padding-bottom:16px">
<table align="center" cellpadding="0" cellspacing="0" role="presentation" width="259">
<tbody>
<tr>
<td align="center" valign="top" width="154" class="m_-708467681402322503dblock m_-708467681402322503margin_0">
<img src="https://ci3.googleusercontent.com/proxy/BYRh2oWgi1l6Rjm7z_fSByHb7vlj1GzcvEEZN6akgngfvudXcxDDrhI8EWnwlhxfnudR1rXbYc6uNxdGLvUsZR2qMe7HU-1PcER9IdzENrBawjkbSr4LE8jwxBQxfl0kqA=s0-d-e1-ft#https://gstatic.com/growthlab/api/5BoP71iPFahFrB7gOSdt3QtyTHXkxtvrF9y2bq5B.png" aria-hidden="true" width="154" style="display:block">
</td>
<td aria-hidden="true" align="center" valign="top" width="40" class="m_-708467681402322503dblock">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" width="40" style="display:block">
</td>
<td align="center" valign="top" width="259" class="m_-708467681402322503dblock">
<table align="center" cellpadding="0" cellspacing="0" role="presentation">
<tbody>tips
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;font-weight:700;color:#202124">
${translations.verticalNotHorizontal[lang]}
</td>
</tr>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px">
${translations.verticalNotHorizontalDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" aria-hidden="true">
<a href="#m_-708467681402322503_" style="text-decoration:none"><img src="https://ci6.googleusercontent.com/proxy/oNEiiF572MvCx0p_wZyIbotILL49voY9_6A3FgfubfJxhYKWWEGSSvwmxznrGgnw4tSt9CZM8Tv7XAuFiZ0mJQhs5OYJHospH4aPPjY918ESgpTKW62rmHvDHyMR7QI9gA=s0-d-e1-ft#https://gstatic.com/growthlab/api/FhjiMXSD0BnmplrJWQfSAJOlNnQxSVH4CT1kUI7E.png" width="314" style="display:block"></a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
`;

interface emailHtmlContentProps {
  maintext: string;
  finaltext: string;
  ctalink: string;
  ctatext: string;
  promotePortraitVideo: boolean;
  lang: Language;
}

const emailHtmlContentString = ({
  maintext,
  finaltext,
  ctalink,
  ctatext,
  promotePortraitVideo,
  lang,
}: emailHtmlContentProps) => `
<div class="email-body" itemscope itemtype="http://schema.org/EmailMessage" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6">

<table class="body-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: #f6f6f6; margin: 0;" bgcolor="#f6f6f6"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
    <td class="container" width="600" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;" valign="top">
      <div class="content" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;">
        <table class="main" width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 3px; background-color: #fff; margin: 0; border: 1px solid #e9e9e9;" bgcolor="#fff"><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="alert alert-warning" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 16px; vertical-align: top; color: #fff; font-weight: 500; text-align: center; border-radius: 3px 3px 0 0; background-color: #9400FE; margin: 0; padding: 10px;" align="center" bgcolor="#9400FE">
              
            </td>
          </tr><tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-wrap" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;" valign="top">
              <table width="100%" cellpadding="0" cellspacing="0" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                  <tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="text-align: center;font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                    <p style="max-width: 450px; margin-left: auto; margin-right: auto;">${maintext}</p>
                  </td>
                </tr>
                <tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="text-align: center;font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                    <a href="${ctalink}" class="btn-primary" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 100px; text-transform: none; background-color: #9400FE; margin: 0; border-color: #9400FE; border-style: solid; border-width: 10px 20px;">${ctatext}</a>
                  </td>
                  
                </tr>
                <tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="text-align: center;font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                     <p style="max-width: 450px; margin-left: auto; margin-right: auto;">${finaltext}</p>
                  </td>
                </tr>



<tr>
<td align="center" valign="top" aria-hidden="true">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" height="30" style="display:block" class="m_-708467681402322503ht61">
</td>
</tr>
<tr>
<td align="center" valign="top">
<table role="presentation" cellpadding="0" cellspacing="0" width="499" class="m_-708467681402322503wid300">
<tbody>
<tr>
<td align="center" valign="top" aria-hidden="true">
<img src="https://25100100.fs1.hubspotusercontent-eu1.net/hubfs/25100100/Icon%20play.png" width="100" style="display:block">
</td>
</tr>
<tr>
<td align="center" valign="top" style="font-family:Google Sans Display,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:28px;line-height:36px;font-weight:400;color:#202124;padding-top:12px">
${translations.tipsForRecordingGreatVideoTestimonials[lang]}
</td>
</tr>
<tr>
<td align="center" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px;padding-left:12px;padding-right:12px">
${translations.tipsForRecordingGreatVideoTestimonialsDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" aria-hidden="true">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" height="22" style="display:block" class="m_-708467681402322503ht32">
</td>
</tr>

${promotePortraitVideo ? emailVerticalVideoContentString(lang) : ''}

<tr>
<td class="m_-708467681402322503dnone" align="center" valign="top" style="padding-top:2px">
<table role="presentation" cellpadding="0" cellspacing="0" width="564">
<tbody>
<tr>
<td align="center" valign="top" background="https://ci4.googleusercontent.com/proxy/B2G7D7h1n_jEE1PmfrmgJs57iSyribrij2SsOKFvQu4n-zJVKZvip2R9_yI66PHm8BU5s_9V7iG4qK30CtzJtDIWniHSQLBBpl13Gb9s_XmF5ah-GcpxTCJznWes0OO2gw=s0-d-e1-ft#https://gstatic.com/growthlab/api/EXKStJGabgsP8PbrfC3bGMbG6EgNAgE3uATYFdI2.png" style="background-image:url('https://ci4.googleusercontent.com/proxy/B2G7D7h1n_jEE1PmfrmgJs57iSyribrij2SsOKFvQu4n-zJVKZvip2R9_yI66PHm8BU5s_9V7iG4qK30CtzJtDIWniHSQLBBpl13Gb9s_XmF5ah-GcpxTCJznWes0OO2gw=s0-d-e1-ft#https://gstatic.com/growthlab/api/EXKStJGabgsP8PbrfC3bGMbG6EgNAgE3uATYFdI2.png');background-position:top center;background-repeat:no-repeat;background-size:100% 100%">
<table role="presentation" cellpadding="0" cellspacing="0" width="100%">
<tbody>
<tr>
<td class="m_-708467681402322503pdb15" align="center" valign="top" style="padding-top:36px;padding-bottom:13px">
<table align="center" cellpadding="0" cellspacing="0" role="presentation" width="462" class="m_-708467681402322503wid259">
<tbody>
<tr>
<td align="center" valign="top" width="163" class="m_-708467681402322503dblock m_-708467681402322503margin_0">
<img src="https://25100100.fs1.hubspotusercontent-eu1.net/hubfs/25100100/Assets%20for%20Mailgun%20emails/good%20lighting.png" width="200" style="display:block" aria-hidden="true">
</td>
<td aria-hidden="true" align="center" valign="top" width="31" class="m_-708467681402322503dblock">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" width="31" style="display:block">
</td>
<td align="center" valign="top" width="268" class="m_-708467681402322503dblock m_-708467681402322503wid251">
<table align="center" cellpadding="0" cellspacing="0" role="presentation">
<tbody>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;font-weight:700;color:#202124">
${translations.goodLightingIsKey[lang]}
</td>
</tr>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px">
${translations.goodLightingIsKeyDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" aria-hidden="true">
<a href="#m_-708467681402322503_" style="text-decoration:none"><img src="https://ci4.googleusercontent.com/proxy/8U7-dXdBh2tCa4zequ_b_eNVsYnLqbCCJvlAFBwBupcdc_upkEp5ofHSq9iMFmTXUSX9spkqnFM4oN1kOJnVCB79YB9tp7HIB5VcPMDZP5jOSLeFkCuoT_Hav8mNuhjNsg=s0-d-e1-ft#https://gstatic.com/growthlab/api/CTQ6uOg3Lv8kgYKPCduIPDx9e5y1lHYWaxEumrh4.png" width="564" style="display:block"></a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="m_-708467681402322503dblock" align="center" valign="top" style="display:none;padding-top:10px">
<table role="presentation" cellpadding="0" cellspacing="0" width="314">
<tbody>
<tr>
<td align="center" valign="top" background="https://ci3.googleusercontent.com/proxy/Gz8mhq6TGzkCYH7H-vwOC-OKsyZ8i-sYGkvExaZBaJotJv0fUjc9j77Ih7j1t-zamYnT08Yd9dc-hZtkNHmEMKnmYn8r72JEHUMFJT88spiAluYX14iWAfR4cQX8gol70A=s0-d-e1-ft#https://gstatic.com/growthlab/api/jjiKTuiVjg9Se7tJqs4SOah5njcPMXCh73DDNaxO.png" style="background-image:url('https://ci3.googleusercontent.com/proxy/Gz8mhq6TGzkCYH7H-vwOC-OKsyZ8i-sYGkvExaZBaJotJv0fUjc9j77Ih7j1t-zamYnT08Yd9dc-hZtkNHmEMKnmYn8r72JEHUMFJT88spiAluYX14iWAfR4cQX8gol70A=s0-d-e1-ft#https://gstatic.com/growthlab/api/jjiKTuiVjg9Se7tJqs4SOah5njcPMXCh73DDNaxO.png');background-position:top center;background-repeat:no-repeat;background-size:100% 100%">
<table role="presentation" cellpadding="0" cellspacing="0" width="100%">
<tbody>
<tr>
<td align="center" valign="top" style="padding-top:35px;padding-bottom:0px">
<table align="center" cellpadding="0" cellspacing="0" role="presentation" width="259">
<tbody>
<tr>
<td align="center" valign="top" width="163" class="m_-708467681402322503dblock m_-708467681402322503margin_0">
<img src="https://ci4.googleusercontent.com/proxy/LrsaNqvZ0F0uCMxX6SPMdh_Dvz7pj6UG5ocuKdVF33TfZweK135zgSMwseX06YbyyX6JiEzRr0t6HiRvh_Wjjk5fM2xsPEYIktwDeF4CkAqO1UYTW24M0CO3cb1sctkdgA=s0-d-e1-ft#https://gstatic.com/growthlab/api/OpkrqOE0nxMRDEP8Rp4yBu5hFtkNpsGWRYZUtafj.png" width="144" style="display:block" aria-hidden="true">
</td>
<td aria-hidden="true" align="center" valign="top" width="31" class="m_-708467681402322503dblock">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" width="31" style="display:block">
</td>
<td align="center" valign="top" width="268" class="m_-708467681402322503dblock m_-708467681402322503wid251">
<table align="center" cellpadding="0" cellspacing="0" role="presentation">
<tbody>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;font-weight:700;color:#202124">
${translations.goodLightingIsKey[lang]}
</td>
</tr>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px">
${translations.goodLightingIsKeyDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td align="center" valign="top" aria-hidden="true">
<a href="#m_-708467681402322503_" style="text-decoration:none"><img src="https://ci6.googleusercontent.com/proxy/oNEiiF572MvCx0p_wZyIbotILL49voY9_6A3FgfubfJxhYKWWEGSSvwmxznrGgnw4tSt9CZM8Tv7XAuFiZ0mJQhs5OYJHospH4aPPjY918ESgpTKW62rmHvDHyMR7QI9gA=s0-d-e1-ft#https://gstatic.com/growthlab/api/FhjiMXSD0BnmplrJWQfSAJOlNnQxSVH4CT1kUI7E.png" width="314" style="display:block"></a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="m_-708467681402322503dnone" align="center" valign="top" style="padding-top:2px">
<table role="presentation" cellpadding="0" cellspacing="0" width="564">
<tbody>
<tr>
<td align="center" valign="top" background="https://ci4.googleusercontent.com/proxy/B2G7D7h1n_jEE1PmfrmgJs57iSyribrij2SsOKFvQu4n-zJVKZvip2R9_yI66PHm8BU5s_9V7iG4qK30CtzJtDIWniHSQLBBpl13Gb9s_XmF5ah-GcpxTCJznWes0OO2gw=s0-d-e1-ft#https://gstatic.com/growthlab/api/EXKStJGabgsP8PbrfC3bGMbG6EgNAgE3uATYFdI2.png" style="background-image:url('https://ci4.googleusercontent.com/proxy/B2G7D7h1n_jEE1PmfrmgJs57iSyribrij2SsOKFvQu4n-zJVKZvip2R9_yI66PHm8BU5s_9V7iG4qK30CtzJtDIWniHSQLBBpl13Gb9s_XmF5ah-GcpxTCJznWes0OO2gw=s0-d-e1-ft#https://gstatic.com/growthlab/api/EXKStJGabgsP8PbrfC3bGMbG6EgNAgE3uATYFdI2.png');background-position:top center;background-repeat:no-repeat;background-size:100% 100%">
<table role="presentation" cellpadding="0" cellspacing="0" width="100%">
<tbody>
<tr>
<td class="m_-708467681402322503pdb36" align="center" valign="top" style="padding-top:35px;padding-bottom:14px">
<table align="center" cellpadding="0" cellspacing="0" role="presentation" width="453" class="m_-708467681402322503wid259">
<tbody>
<tr>
<td align="center" valign="top" width="154" class="m_-708467681402322503dblock m_-708467681402322503margin_0">

<img src="https://25100100.fs1.hubspotusercontent-eu1.net/hubfs/25100100/Assets%20for%20Mailgun%20emails/keep%20it%20simple.png" width="200" style="display:block" aria-hidden="true">
</td>
<td aria-hidden="true" align="center" valign="top" width="40" class="m_-708467681402322503dblock">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" width="40" style="display:block">
</td>
<td align="center" valign="top" width="259" class="m_-708467681402322503dblock">
<table align="center" cellpadding="0" cellspacing="0" role="presentation">
<tbody>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;font-weight:700;color:#202124">
${translations.keepItSimple[lang]}
</td>
</tr>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px">
${translations.keepItSimpleDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>

<tr>
<td align="center" valign="top" aria-hidden="true">
<a href="#m_-708467681402322503_" style="text-decoration:none"><img src="https://ci4.googleusercontent.com/proxy/8U7-dXdBh2tCa4zequ_b_eNVsYnLqbCCJvlAFBwBupcdc_upkEp5ofHSq9iMFmTXUSX9spkqnFM4oN1kOJnVCB79YB9tp7HIB5VcPMDZP5jOSLeFkCuoT_Hav8mNuhjNsg=s0-d-e1-ft#https://gstatic.com/growthlab/api/CTQ6uOg3Lv8kgYKPCduIPDx9e5y1lHYWaxEumrh4.png" width="564" style="display:block"></a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td class="m_-708467681402322503dblock" align="center" valign="top" style="display:none;padding-top:16px">
<table role="presentation" cellpadding="0" cellspacing="0" width="314">
<tbody>
<tr>
<td align="center" valign="top" background="https://ci3.googleusercontent.com/proxy/Gz8mhq6TGzkCYH7H-vwOC-OKsyZ8i-sYGkvExaZBaJotJv0fUjc9j77Ih7j1t-zamYnT08Yd9dc-hZtkNHmEMKnmYn8r72JEHUMFJT88spiAluYX14iWAfR4cQX8gol70A=s0-d-e1-ft#https://gstatic.com/growthlab/api/jjiKTuiVjg9Se7tJqs4SOah5njcPMXCh73DDNaxO.png" style="background-image:url('https://ci3.googleusercontent.com/proxy/Gz8mhq6TGzkCYH7H-vwOC-OKsyZ8i-sYGkvExaZBaJotJv0fUjc9j77Ih7j1t-zamYnT08Yd9dc-hZtkNHmEMKnmYn8r72JEHUMFJT88spiAluYX14iWAfR4cQX8gol70A=s0-d-e1-ft#https://gstatic.com/growthlab/api/jjiKTuiVjg9Se7tJqs4SOah5njcPMXCh73DDNaxO.png');background-position:top center;background-repeat:no-repeat;background-size:100% 100%">
<table role="presentation" cellpadding="0" cellspacing="0" width="100%">
<tbody>
<tr>
<td align="center" valign="top" style="padding-top:35px;padding-bottom:16px">
<table align="center" cellpadding="0" cellspacing="0" role="presentation" width="453" class="m_-708467681402322503wid259">
<tbody>
<tr>
<td align="center" valign="top" width="154" class="m_-708467681402322503dblock m_-708467681402322503margin_0">
<a aria-label="Visit Google Maps to add a video." href="https://notifications.google.com/g/p/APdRdFxgTKlRGj991PchiSJtPpKlFeFDcPaGVcgLbfdHyAsXi4E1Yaz8FvB_EwO1nB97YfTV5KEMsT6MGIzlUtJvTV4mLUNGqZ704cx8it_Eg0U1AxuHlUqtH_eDI1KsmpZbmYtmn20cgCKa3ng0xyhgmngzboQex8sE9UdKqPrGu90udpSIlagmyR4aaN6wJmvCAnS7TajSNeHRuZoNSYxUrigRMwAJ6d3uAK1O5mf7ifG6pgWs_ziXDxc3uI1GFpb-63O-UimZjy6a3Pxl" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/APdRdFxgTKlRGj991PchiSJtPpKlFeFDcPaGVcgLbfdHyAsXi4E1Yaz8FvB_EwO1nB97YfTV5KEMsT6MGIzlUtJvTV4mLUNGqZ704cx8it_Eg0U1AxuHlUqtH_eDI1KsmpZbmYtmn20cgCKa3ng0xyhgmngzboQex8sE9UdKqPrGu90udpSIlagmyR4aaN6wJmvCAnS7TajSNeHRuZoNSYxUrigRMwAJ6d3uAK1O5mf7ifG6pgWs_ziXDxc3uI1GFpb-63O-UimZjy6a3Pxl&amp;source=gmail&amp;ust=1674892685866000&amp;usg=AOvVaw0NIKh3uxUJihVYQdtS4QHm"><img src="https://ci5.googleusercontent.com/proxy/JhgMbhHFfHa8A8LDWbqPBqM2KFRfJAtHfdMJ9786DFO9BtIW9wAr0lPq4CY-vV9JEjceR9snshQb-Nkq2O98kxXtx8ZPjZRiYgnqcyHTG3NwFdUzUp7LLQJH0YoBRvN7JA=s0-d-e1-ft#https://gstatic.com/growthlab/api/HcyTw3foavJMmry6key6k9jPkYD27ZpKpqXWi725.png" width="120" style="display:block" aria-hidden="true"></a>
</td>
<td aria-hidden="true" align="center" valign="top" width="40" class="m_-708467681402322503dblock">
<img src="https://ci4.googleusercontent.com/proxy/rgTq_w-RNHBOcMbHKpqkZHwnCwNIhWBwthonqBjc6XX93eyLNz96K9gDyHNgGH-3Cb1_yN-lXgyKfvnB-FbPQXAa5tOXJw=s0-d-e1-ft#https://www.gstatic.com/gumdrop/files/spacer-11.gif" width="40" style="display:block">
</td>
<td align="center" valign="top" width="259" class="m_-708467681402322503dblock">
<table align="center" cellpadding="0" cellspacing="0" role="presentation">
<tbody>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:20px;line-height:30px;font-weight:700;color:#202124">
${translations.keepItSimple[lang]}
</td>
</tr>
<tr>
<td align="left" valign="top" style="font-family:Google Sans,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;font-size:14px;line-height:22px;font-weight:400;color:#5f6368;padding-top:12px">
${translations.keepItSimpleDescription[lang]}
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>








                <tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"><td class="content-block" style="text-align: center;font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;" valign="top">
                    Life Inside: Video storytelling. Reinvented.
                  </td>
                </tr></table></td>
          </tr></table><div class="footer" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
          <table width="100%" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
              
                                        <tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                  <td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;" align="center" valign="top">
                    <a target="_blank" href="https://www.lifeinside.io/?utm_campaign=System%20emails&utm_source=email&utm_medium=mailgun&utm_term=initial&utm_content=videorequest"> <img src="https://25100100.fs1.hubspotusercontent-eu1.net/hubfs/25100100/Logo%20horizontal.png" alt="Life Inside logo in black"></a>
                </td>
            </tr>

              <tr style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                  <td class="aligncenter content-block" style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 12px; vertical-align: top; color: #999; text-align: center; margin: 0; padding: 0 0 20px;" align="center" valign="top">
                    &copy; Life Inside ${new Date().getFullYear()}
                </td>
            </tr></table></div></div>
    </td>
    <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;" valign="top"></td>
  </tr></table></div>
`;

const styleString = `

img {
max-width: 100%;
}

.email-body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  width: 100% !important;
  height: 100%;
  line-height: 1.6em;
  background-color: #f6f6f6;
  margin: 0;
  padding: 0;
}


@media only screen and (max-width: 640px) {

  .email-body {
    padding: 0 !important;
  }
  .email-body h1 {
    font-weight: 800 !important;
    margin: 20px 0 5px !important;
    font-size: 22px !important;
  }
  .email-body h2 {
    font-weight: 800 !important;
    margin: 20px 0 5px !important;
    font-size: 18px !important;
  }
  .email-body h3 {
    font-weight: 800 !important;
    margin: 20px 0 5px !important;
    font-size: 16px !important;
  }

  h1 {
    font-weight: 800 !important; margin: 20px 0 5px !important;
  }
  h2 {
    font-weight: 800 !important; margin: 20px 0 5px !important;
  }
  h3 {
    font-weight: 800 !important; margin: 20px 0 5px !important;
  }
  h4 {
    font-weight: 800 !important; margin: 20px 0 5px !important;
  }
  h1 {
    font-size: 22px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  .container {
    padding: 0 !important; width: 100% !important;
  }
  .content {
    padding: 0 !important;
  }
  .content-wrap {
    padding: 10px !important;
  }
  .invoice {
    width: 100% !important;
  }
}
`;

interface VideoRequestEmailTemplateProps {
  lang: Language;
  orgName: string;
  promotePortraitVideo?: boolean;
}

function VideoRequestEmailTemplate({
  lang,
  orgName,
  promotePortraitVideo = true,
}: VideoRequestEmailTemplateProps) {
  const user = {
    firstName: 'John',
    lastName: 'Doe',
    jobTitle: { en: 'CEO', sv: 'VD' },
  };

  const questionWithBr = { en: 'What is your favorite color?', sv: 'Vad är din favoritfärg?' }[
    lang
  ];

  const msg = {
    en: '<br/><b>Message:</b><br/>Please record and upload your video by the end of the week.',
    sv: '<br/><b>Message:</b><br/>Vänligen spela in och ladda upp din video senast i slutet av veckan.',
  };

  const recordPortraitModeString = promotePortraitVideo
    ? {
        en: '<br/><br/><i>Please record yourself in <strong>portrait mode</strong> with your phone.</i>',
        sv: '<br/><br/><i>Obs. Filma dig med mobilen i <strong>porträttläge</strong>.</i>',
      }[lang]
    : '';

  const text = {
    mainText: {
      en: `You've got a request from ${user.firstName} to record a video reply to the question:<br/><b>${questionWithBr}</b>${recordPortraitModeString}<br/>${msg.en}`,
      sv: `Du har fått en förfrågan från ${user.firstName} om att spela in ett videosvar på frågan:<br/><b>${questionWithBr}</b>${recordPortraitModeString}<br/>${msg.sv}`,
    },
    ctaText: {
      en: 'Upload your video',
      sv: 'Ladda upp din video',
    },
    finalText: {
      en: `For instructions and tips about how to record and upload your video, <a href="https://www.lifeinside.io/record-video/">click here!</a><br/><br/>Thank you!<br/><br/> Kind regards<br/>${
        user.firstName
      } ${user.lastName}<br/>${user.jobTitle?.[lang] || ''}<br/>${orgName}`,
      sv: `För instruktioner och tips om hur du spelar in och laddar upp din video, <a href="https://www.lifeinside.io/spela-in-video/">klicka här!</a><br/><br/>Tack!<br/><br/> Med vänliga hälsningar<br/> ${
        user.firstName
      } ${user.lastName}<br/>${user.jobTitle?.[lang] || ''}<br/>${orgName}`,
    },
  };

  const content: emailHtmlContentProps = {
    maintext: text.mainText[lang],
    finaltext: text.finalText[lang],
    ctalink: frontendPath,
    ctatext: text.ctaText[lang],
    promotePortraitVideo,
    lang,
  };

  return (
    <Box>
      <meta name="viewport" content="width=device-width" />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <style>{DOMPurify.sanitize(styleString)}</style>
      {parse(DOMPurify.sanitize(emailHtmlContentString(content)))}
    </Box>
  );
}
export default VideoRequestEmailTemplate;
