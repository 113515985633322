/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Alert, Backdrop, Button } from '@mui/material';

import { isValidPhoneNumberInput } from 'src/utils/utils';
import validator from 'validator';
import { useLanguage } from 'src/context/LanguageContext';

import PhoneNumberInput from '../back-office/Recipient/PhoneNumberInput';
import LoadingIndicator from '../common/LoadingIndicator';

const CREATE_ADMIN_USER_MUTATION = gql`
  mutation CreateAdminUser($input: UserInput!) {
    createAdminUser(input: $input)
  }
`;

export default function CreateNewSuperAdmin() {
  const lang = useLanguage();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState<boolean | string>(false);

  const [createAdminUser, { data, loading }] = useMutation(CREATE_ADMIN_USER_MUTATION, {
    onError: (err) => setError(`Error updating user: ${err.message}`),
  });

  const handleCreateUser = (e: React.FormEvent) => {
    setError(false);
    e.preventDefault();
    if (!validator.isEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (!isValidPhoneNumberInput({ phoneNumber, lang, orgRecipients: null, editRecipient: null })) {
      setError('Please enter a valid phone number.');
      return;
    }

    createAdminUser({
      variables: {
        input: {
          email,
          firstName,
          lastName,
          phoneNumber,
        },
      },
    });
  };
  if (data) {
    return (
      <div className="create-new-page">
        <Alert severity="success">
          User created successfully!
          <div>{data.createAdminUser}</div>
        </Alert>
      </div>
    );
  }

  return (
    <>
      <Backdrop open={loading}>
        <LoadingIndicator />
      </Backdrop>
      <div className="create-new-page">
        {error && <Alert severity="error">{error}</Alert>}
        <h1>Skapa ny superadmin</h1>
        <div>
          <form onSubmit={handleCreateUser}>
            <label>
              <span>
                Email *
                {email && !validator.isEmail(email) && (
                  <span style={{ color: 'red' }}> Must be valid email</span>
                )}
              </span>
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              <span>Telefonnummer *</span>
              <PhoneNumberInput
                actorPhoneNumber={phoneNumber}
                setActorPhoneNumber={setPhoneNumber}
                disabled={false}
                orgRecipients={null}
                editRecipient={null}
              />
            </label>
            <label>
              <span>Förnamn *</span>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </label>

            <label>
              <span>Efternamn *</span>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </label>

            <Button
              variant="contained"
              type="submit"
              disableElevation
              disabled={
                !isValidPhoneNumberInput({
                  phoneNumber,
                  lang,
                  orgRecipients: null,
                  editRecipient: null,
                }) ||
                phoneNumber?.length < 5 ||
                !validator.isEmail(email) ||
                firstName?.trim() === '' ||
                lastName?.trim() === ''
              }
            >
              Skapa användare
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
