import './MadeWithLove.scss';

function MadeWithLoveFooter({ style }) {
  const year = new Date().getFullYear();

  return (
    <div className="made-with-love" style={{ ...style }}>
      Made with
      <img src="/images/icons/heart-icon.svg" alt="Love" aria-hidden="true" />©{year} Life Inside
    </div>
  );
}
export default MadeWithLoveFooter;
