import { useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import LoadingIndicator from '../common/LoadingIndicator';

interface UserQueryResult {
  user: {
    id: number;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
  } | null;
}

const USER_QUERY = gql`
  query User($userId: Int!) {
    user(id: $userId) {
      id
      email
      firstName
      lastName
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($userId: Int!) {
    deleteUser(userId: $userId) {
      statusMessage
    }
  }
`;

function DeleteUser() {
  const userId = Number(useParams().userid);
  const navigate = useNavigate();

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery<UserQueryResult>(USER_QUERY, { variables: { userId } });
  const [deleteUser, { loading: deleteLoading, error: deleteError, called: deleteCalled }] =
    useMutation(DELETE_USER_MUTATION, { variables: { userId } });

  const deleteSuccess = deleteCalled && !deleteLoading && deleteError == null;

  useEffect(() => {
    if (deleteSuccess) {
      const timeoutId = setTimeout(() => {
        navigate('/admin');
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [deleteSuccess, navigate]);

  const handleOnDelete = async () => {
    try {
      await deleteUser();
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnCancel = () => {
    navigate('/admin');
  };

  const renderContent = () => {
    if (userLoading || deleteLoading) {
      return <LoadingIndicator />;
    }

    if (userError) {
      return (
        <Box>
          <Alert severity="error">Error fetching user: {userError.message}</Alert>
          <p>
            <Link to="/admin">Back</Link>
          </p>
        </Box>
      );
    }

    const user = userData?.user;
    if (user == null) {
      return (
        <Box>
          <Alert severity="warning">User not found.</Alert>
          <p>
            <Link to="/admin">Back</Link>
          </p>
        </Box>
      );
    }

    if (deleteSuccess) {
      return (
        <Box>
          <Alert severity="success">User deleted!</Alert>
          <p>
            <Link to="/admin">Back</Link>
          </p>
        </Box>
      );
    }

    return (
      <Box>
        {deleteError != null && <Alert severity="error">Error: {deleteError.message}</Alert>}
        <ul>
          <li>id: {user.id}</li>
          <li>email: {user.email ?? ''}</li>
          <li>first name: {user.firstName ?? ''}</li>
          <li>last name: {user.lastName ?? ''}</li>
        </ul>
        <Button variant="text" onClick={() => handleOnCancel()}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={() => handleOnDelete()}>
          Delete
        </Button>
      </Box>
    );
  };

  return (
    <Box>
      <h1>Confirm Delete User &apos;{userId}&apos;?</h1>
      {renderContent()}
    </Box>
  );
}

export default DeleteUser;
