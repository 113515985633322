import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import { useDashboardApi } from './DashboardApi';
import { translationsDashboard } from '../../../utils/translationsDashboard';

const defaultSignedInUser = { current: { firstName: '', lastName: '', email: '' } };
const defaultBasicOrgData = { encodedId: '' };
const defaultDashboardStatsState = {
  helloCardData: {
    videosUploadedThisWeek: 0,
    videosUploadedThisMonth: 0,
    videosUploadedLastTwentyEightDays: 0,
    videosUploadedThisYear: 0,
  },
};

function HelloCard({
  signedInUser = defaultSignedInUser,
  basicOrgData = defaultBasicOrgData,
  dashboardStatsState = defaultDashboardStatsState,
}) {
  const lang = useLanguage();
  const dashboardApi = useDashboardApi();
  const [me, setMe] = useState(signedInUser?.current);

  useEffect(() => {
    dashboardApi
      .getUserData(basicOrgData?.encodedId)
      .then((res) => {
        setMe(res?.data?.me);
        // eslint-disable-next-line no-param-reassign
        signedInUser.current = res.data.me;
      })
      .catch(() => {});
  }, [
    dashboardApi,
    basicOrgData?.encodedId,
    signedInUser,
    signedInUser?.current?.firstName,
    signedInUser?.current?.lastName,
    signedInUser?.current?.email,
  ]);

  const getHelloPhrase = () => {
    const date = new Date();
    const hour = date.getHours();
    if (hour >= 5 && hour < 12) {
      return translationsDashboard.helloCard.goodMorning[lang];
    }
    if (hour >= 12 && hour < 18) {
      return translationsDashboard.helloCard.goodAfternoon[lang];
    }
    if (hour >= 18 && hour < 22) {
      return translationsDashboard.helloCard.goodEvening[lang];
    }
    if (hour >= 22 && hour < 24) {
      return translationsDashboard.helloCard.workingLate[lang];
    }
    return translationsDashboard.helloCard.earlyBird[lang];
  };

  const getStatsValueType = () => {
    if (dashboardStatsState?.helloCardData?.videosUploadedThisWeek > 1) {
      return 'videosUploadedThisWeek';
    }
    if (dashboardStatsState?.helloCardData?.videosUploadedThisMonth > 1) {
      return 'videosUploadedThisMonth';
    }
    if (dashboardStatsState?.helloCardData?.videosUploadedLastTwentyEightDays > 1) {
      return 'videosUploadedLastTwentyEightDays';
    }
    if (dashboardStatsState?.helloCardData?.videosUploadedThisYear > 1) {
      return 'videosUploadedThisYear';
    }
    return 'videosUploadedAllTime';
  };

  const getHelloCardData = () => {
    return dashboardStatsState?.helloCardData?.[getStatsValueType()] ?? 0;
  };

  return (
    <Card
      sx={{
        overflow: 'visible',
      }}
    >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item lg={9} sm={12} xl={8} xs={12}>
            <Grid item>
              <Typography variant="headlineSmallBoldRecoleta">
                {getHelloPhrase()}, {me?.firstName ?? ''}
              </Typography>{' '}
            </Grid>
            {getHelloCardData() > 0 && (
              <Grid item>
                <Typography variant="bodyMedium">
                  {translationsDashboard.helloCard?.[getStatsValueType()]?.[lang]({
                    count: getHelloCardData(),
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default HelloCard;

HelloCard.propTypes = {
  signedInUser: PropTypes.shape({
    current: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
  }),
  dashboardStatsState: PropTypes.shape({
    helloCardData: PropTypes.shape({
      videosUploadedThisWeek: PropTypes.number,
      videosUploadedThisMonth: PropTypes.number,
      videosUploadedLastTwentyEightDays: PropTypes.number,
      videosUploadedThisYear: PropTypes.number,
    }),
  }),
};
