import React, { useContext, useMemo } from 'react';
import { Grid, Typography, Card, Box } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import ThemeContext from 'src/utils/ThemeContext';
import { getStaticThumbnail } from 'src/utils/video-card/utils';
import videoCollectorTranslations from './VideoCollectorTranslations';

import VideoThumbnails from './VideoThumbnails';
import { VideoCollector } from './types';

function ActiveMarker({ active }: { active: boolean }) {
  const theme = useContext(ThemeContext);

  return (
    <Box
      className="VideoCollectorLibraryCard-ActiveMarker"
      sx={{
        float: 'right',
        width: '12px',
        height: '12px',
        background: theme.palette.success.main,
        borderRadius: '50%',
        visibility: active ? 'visible' : 'hidden',
      }}
    />
  );
}

const cardStyle = {
  m: 1,
  width: '100%',
  height: '100%',
  background: 'white',
  overflow: 'hidden',
  transition: 'all 0.2s ease-in-out',
  ':hover': {
    transform: 'scale(1.03)',
    cursor: 'pointer',
  },
  p: 1,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 1,
};

interface VideoCollectorLibraryCardProps {
  videoCollector: VideoCollector;
  onClick: (videoCollector: VideoCollector) => void;
}

function VideoCollectorLibraryCard({ videoCollector, onClick }: VideoCollectorLibraryCardProps) {
  const lang = useLanguage();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick(videoCollector);
    }
  };

  const { videoCards } = videoCollector;

  // During processing videoCards does not yet have a thumbnail. Assume all cards
  // missing a thumbnail is processing.
  const processedVideoCards = useMemo(
    () => videoCards.filter((videoCard) => getStaticThumbnail(videoCard) != null),
    [videoCards],
  );
  const numProcessingVideoCards = videoCards.length - processedVideoCards.length;

  const isActive = videoCollector.status === 'LIVE';

  return (
    <Card
      role="button"
      tabIndex={0}
      elevation={0}
      onClick={() => onClick(videoCollector)}
      onKeyDown={handleKeyDown}
      sx={cardStyle}
      data-testid="video-collector-library-card"
    >
      <Grid container item xs={12} spacing={1} columns={5}>
        <Grid item xs={12} key={`title-${videoCollector.id}`}>
          <ActiveMarker active={isActive} />
          <Typography variant="titleMedium">{videoCollector.title}</Typography>
          <br />
          <Typography variant="bodyMedium" color="text.secondary">
            {processedVideoCards.length}{' '}
            {videoCollectorTranslations.videos[lang](processedVideoCards.length)}
          </Typography>
          {numProcessingVideoCards > 0 && (
            <Typography variant="bodySmall" color="text.secondary" sx={{ ml: 1 }}>
              ({numProcessingVideoCards} {videoCollectorTranslations.processing[lang]})
            </Typography>
          )}
        </Grid>
        <VideoThumbnails
          videoCards={processedVideoCards}
          videoCollectorId={videoCollector.id}
          inactive={!isActive}
        />
      </Grid>
    </Card>
  );
}

export default VideoCollectorLibraryCard;
