/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const ADMIN_USER_QUERY = gql`
  query AdminUser {
    adminUser {
      id
    }
  }
`;

export const CONVERSATIONAL_AVATAR_SETTINGS_QUERY = gql`
  query ConversationalAvatarSettings {
    conversationalAvatarSettings {
      avatarName
      avatarVoiceId
      assistantId
      avatarPosterUrl
    }
  }
`;
