import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { useLanguage } from 'src/context/LanguageContext';
import translationsPublicVideoCollector from './translations';

function NotFoundErrorView() {
  const lang = useLanguage();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: 3,
        p: 4,
        minWidth: '300px',
        maxWidth: '400px',
        borderRadius: '16px',
        backgroundColor: 'background.default',
      }}
    >
      <Stack spacing={4} direction="column" alignItems="center">
        <Typography variant="headlineLargeBoldRecoleta" color="error" gutterBottom>
          {translationsPublicVideoCollector.Error[lang]}
        </Typography>
        <Typography variant="body1">
          {translationsPublicVideoCollector.UnsupportedLinkMessage1[lang]}
        </Typography>

        <Typography variant="body1">
          {translationsPublicVideoCollector.UnsupportedLinkMessage2[lang]}
        </Typography>

        <Typography variant="body1">
          {translationsPublicVideoCollector.UnsupportedLinkMessage3[lang]}
        </Typography>
      </Stack>
    </Box>
  );
}

export default NotFoundErrorView;
