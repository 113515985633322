import React, { useState, useEffect, useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useLanguage } from 'src/context/LanguageContext';
import { getDefaultState } from '../../../utils/utils';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { translations } from '../../../utils/translations';

import ThemeContext from '../../../utils/ThemeContext';

export default function StorylineHead({
  setCreateNewIsOpen,
  storylineSearchField,
  setStoryLineSearchField,
  selectedStatus,
  setSelectedStatus,
  statusOptions,
  selectedUser,
  setSelectedUser,
  userOptions,
  basicOrgData,
  setCreateNewVideoFunnelIsOpen,
}) {
  const lang = useLanguage();
  const [drawer, setDrawer] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const theme = useContext(ThemeContext);
  useEffect(() => {
    if (
      (selectedStatus && selectedStatus?.value !== 'ALL') ||
      (selectedUser && selectedUser?.value !== 'ALL_USERS')
    ) {
      setIsApply(true);
    } else {
      setIsApply(false);
    }
  }, [selectedStatus, selectedUser]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleDeleteChip = (type) => {
    switch (type) {
      case 'status':
        setSelectedStatus(getDefaultState('status', lang));
        break;
      case 'user':
        setSelectedUser(getDefaultState('user', lang));
        break;
      default:
        break;
    }
  };

  const clearState = () => {
    setSelectedUser(getDefaultState('user', lang));
    setSelectedStatus(getDefaultState('status', lang));
    setDrawer({ ...drawer, right: false });
  };

  const handleApply = () => {
    setDrawer({ ...drawer, right: false });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 340,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <AppBar position="static">
          <Typography
            variant="titleLarge"
            component="div"
            sx={{
              display: 'flex',
              flexGrow: 1,
              p: 2.5,
              backgroundColor: '#2F54EB',
              color: 'white',
            }}
          >
            {translations.chooseFilters[lang]}
            <IconButton
              aria-label="clear"
              sx={{ color: 'white', pl: '118px', '&:hover': { background: 'none' } }}
              onClick={toggleDrawer('right', false)}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </AppBar>
      </div>

      <div className="dropdown-wrapper" style={{ padding: '24px' }}>
        <div>
          <InputLabel id="select-status-label">
            {translationsStoryline.selectStatusStoryline[lang]}
          </InputLabel>
          <Select
            style={{ width: '100%', height: '40px' }}
            labelId="storyline-section-label"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            renderValue={(selected) => {
              return selected ? selected?.label : '';
            }}
          >
            {statusOptions?.map((item) => {
              return (
                <MenuItem value={item} key={_.uniqueId(item?.id)}>
                  <ListItemText primary={item.label} key={_.uniqueId(item?.id)} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <InputLabel id="select-user-label">{translations.selectUser[lang]}</InputLabel>
          <Select
            style={{ width: '100%', height: '40px' }}
            labelId="storyline-section-label"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            renderValue={(selected) => {
              return selected ? selected?.label : '';
            }}
          >
            {userOptions?.map((item) => {
              return (
                <MenuItem value={item} key={_.uniqueId(item?.id)}>
                  <ListItemText primary={item.label} key={_.uniqueId(item?.id)} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>

      <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" padding="15px">
        <Button variant="text" disableElevation onClick={() => handleApply()} disabled={!isApply}>
          {translations.applyButton[lang]}
        </Button>

        <Button variant="text" disableElevation onClick={() => clearState()} disabled={!isApply}>
          {translations.resetButton[lang]}
        </Button>
      </Stack>
    </Box>
  );

  return (
    <Box
      className="board-header"
      sx={{
        ml: 3,
        mt: 1,
      }}
    >
      <div>
        <Stack direction="row" spacing={3}>
          <Typography variant="headlineLargeBoldRecoleta">
            {basicOrgData?.premiumFeatures?.videoFunnel
              ? translationsStoryline.allStories[lang]
              : translationsStoryline.allStorylines[lang]}
          </Typography>

          <Button
            variant="text"
            disableElevation
            startIcon={<AddIcon />}
            onClick={setCreateNewIsOpen}
          >
            {translationsStoryline.newStoryline[lang]}
          </Button>

          {basicOrgData?.premiumFeatures?.videoFunnel && (
            <Button
              variant="text"
              disableElevation
              startIcon={<AddIcon />}
              onClick={setCreateNewVideoFunnelIsOpen}
            >
              {translationsStoryline.newVideoFunnel[lang]}
            </Button>
          )}
        </Stack>
      </div>
      <div className="board-header-filters">
        <div className="search-field">
          <TextField
            fullWidth
            variant="outlined"
            placeholder={translationsStoryline.storylineSearchField[lang]}
            value={storylineSearchField}
            onChange={(e) => setStoryLineSearchField(e.target.value)}
            autoComplete="off"
            InputProps={{
              startAdornment: <SearchIcon />,
              style: {
                borderRadius: 50,
                paddingRight: 12,
                backgroundColor: theme.palette.background.paper,
              },
            }}
            name={`userInput_${Math.random().toString(36).substring(2, 9)}`} // Dynamic field name
            sx={{
              background: 'transparent',
              padding: '0',
              minWidth: '300px',
            }}
          />
        </div>
        <div className="filter-drawer">
          <div
            onClick={toggleDrawer('right', true)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              variant="text"
              disableElevation
              startIcon={<FilterAltOutlinedIcon />}
              onClick={toggleDrawer('right', true)}
            >
              {translations.filter[lang]}
            </Button>

            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {selectedUser?.value !== 'ALL_USERS' && (
                <ListItem>
                  <Chip
                    sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                    label={selectedUser?.label}
                    onDelete={() => handleDeleteChip('user')}
                  />
                </ListItem>
              )}

              {selectedStatus && selectedStatus?.value !== 'ALL' && (
                <ListItem>
                  <Chip
                    sx={{ backgroundColor: '#dfe2ee', color: 'black' }}
                    label={selectedStatus?.label}
                    onDelete={() => handleDeleteChip('status')}
                  />
                </ListItem>
              )}
            </Paper>
          </div>

          <React.Fragment key="right">
            <Drawer anchor="right" open={drawer.right} onClose={toggleDrawer('right', false)}>
              {list('right')}
            </Drawer>
          </React.Fragment>
        </div>
      </div>
    </Box>
  );
}
