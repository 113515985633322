import React from 'react';

import Grid from '@mui/material/Grid';
import CopyrightText from '../copyright-text';
import ReCAPTCHAText from '../recaptcha-text';

interface PageFooterProps {
  showReCAPTCHA?: boolean;
  datatestId?: string;
}

function PageFooter({ showReCAPTCHA = false, datatestId = 'page-footer' }: PageFooterProps) {
  return (
    <Grid
      container
      item
      lg={12}
      sm={12}
      xl={12}
      xs={12}
      md={12}
      spacing={1}
      justifyContent="center"
      alignItems="center"
      role="grid"
      component="footer"
      data-testid={datatestId}
    >
      <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
        <CopyrightText />
      </Grid>
      {showReCAPTCHA && (
        <Grid item lg={12} sm={12} xl={12} xs={12} md={12}>
          <ReCAPTCHAText />
        </Grid>
      )}
    </Grid>
  );
}

export default PageFooter;
