/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useState } from 'react';

import './VideoPlayer.scss';
import { MediaItemState } from './MediaItemState';
import { calculateAspectRatioStyles } from './aspectRatioCalculator';

interface VideoPlayerProps {
  mediaRef: React.RefObject<HTMLVideoElement>;
  playFullVideo: boolean;
  mouseHover: boolean;
  shouldRender: boolean;
  mediaItemState: MediaItemState;
  setMediaItemState: React.Dispatch<React.SetStateAction<MediaItemState>>;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  useAbsolutePositionStyles: boolean;
  handleVideoClick: () => void;
  borderRadius?: string | number;
  children?: React.ReactNode;
}

function VideoPlayer({
  mediaRef,
  playFullVideo,
  mouseHover,
  shouldRender,
  mediaItemState,
  setMediaItemState,
  setProgress,
  useAbsolutePositionStyles,
  children = undefined,
  handleVideoClick,
  borderRadius = 0,
}: VideoPlayerProps) {
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const videoElement = mediaRef.current;
    if (videoElement && videoElement instanceof HTMLVideoElement) {
      videoElement.disableRemotePlayback = true;
    }
  }, [mediaRef]);

  useEffect(() => {
    const videoElement = mediaRef.current;

    const handleTimeUpdate = () => {
      if (videoElement instanceof HTMLVideoElement && videoElement.duration > 0) {
        const progressValue = (videoElement.currentTime / videoElement.duration) * 100;
        setProgress(progressValue);
      }
    };

    if (videoElement instanceof HTMLVideoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    const cleanup = () => {
      if (videoElement instanceof HTMLVideoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };

    return cleanup;
  }, [mediaRef, setProgress]);

  useEffect(() => {
    const videoElement = mediaRef.current;
    if (videoElement && videoElement instanceof HTMLVideoElement) {
      videoElement.disableRemotePlayback = true;

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Space') {
          event.preventDefault();
          handleVideoClick();
        }
      };

      videoElement.addEventListener('keydown', handleKeyDown);

      return () => {
        videoElement.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {};
  }, [mediaRef, handleVideoClick]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleVideoEnd = () => {
    if (playFullVideo) return;
    if (mediaRef.current instanceof HTMLVideoElement && mouseHover) {
      mediaRef.current.play().catch(() => {});
    }
  };

  const getVideoSource = (): string | undefined => {
    if (!shouldRender) return undefined;
    const source = playFullVideo ? mediaItemState.videoLink : mediaItemState.animatedThumbnail;
    return source || undefined;
  };

  const handleMediaLoad = () => {
    if (mediaRef.current) {
      const width =
        mediaRef.current instanceof HTMLVideoElement ? mediaRef.current.videoWidth : null;
      const height =
        mediaRef.current instanceof HTMLVideoElement ? mediaRef.current.videoHeight : null;

      if (!width || !height) {
        return;
      }

      const { aspectRatio, gridColumnEnd, gridRowEnd } = calculateAspectRatioStyles(width, height);

      setMediaItemState({
        ...mediaItemState,
        aspectRatio,
        gridColumnEnd,
        gridRowEnd,
        isVideo: true,
      });
    }
    if (mediaRef.current instanceof HTMLVideoElement && playFullVideo && mediaRef.current.paused) {
      mediaRef.current.play().catch(() => {});
    }
  };

  return (
    <div
      className={`videoPlayerContainer ${useAbsolutePositionStyles ? 'useAbsolutePosition' : ''}`}
    >
      <video
        className={`styledVideo ${isFocused ? 'focusVisible' : ''}`}
        disableRemotePlayback
        disablePictureInPicture
        ref={mediaRef as React.RefObject<HTMLVideoElement>}
        controls={false}
        src={getVideoSource()}
        onLoadedMetadata={handleMediaLoad}
        poster={(shouldRender && mediaItemState.staticThumbnail) || undefined}
        onEnded={handleVideoEnd}
        onClick={handleVideoClick}
        onContextMenu={(e) => e.preventDefault()}
        controlsList="nodownload disableremoteplayback disablepictureinpicture"
        style={{
          borderRadius: typeof borderRadius === 'number' ? `${borderRadius}px` : borderRadius,
        }}
        aria-label="Video player"
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid="video-player"
      />
      {children}
    </div>
  );
}

export default VideoPlayer;
