import PropTypes from 'prop-types';

function VideosOutlinedIcon({ fill = '#FFFFFF', style = {} }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="video_library">
        <path
          id="Vector"
          d="M4 6.31299H2V20.313C2 21.413 2.9 22.313 4 22.313H18V20.313H4V6.31299ZM20 2.31299H8C6.9 2.31299 6 3.21299 6 4.31299V16.313C6 17.413 6.9 18.313 8 18.313H20C21.1 18.313 22 17.413 22 16.313V4.31299C22 3.21299 21.1 2.31299 20 2.31299ZM20 16.313H8V4.31299H20V16.313ZM12 5.81299V14.813L18 10.313L12 5.81299Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default VideosOutlinedIcon;

VideosOutlinedIcon.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.shape({}),
};
