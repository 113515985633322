import React, { useEffect, useCallback } from 'react';
import { TransitionGroup } from 'react-transition-group';

import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { useLanguage } from 'src/context/LanguageContext';
import StorylineLinkWhenCondition, { getTranslationKey } from './StorylineLinkWhenCondition';
import { isValidHttpUrl, packageVariant } from '../../../utils/utils';
import { translationsStoryline } from '../../../utils/translationsStoryline';

export const checkIfUrlWhenConditionIsUsed = ({ urlsJsonb, otherStorylinesUrls }) =>
  urlsJsonb
    ?.filter((urlJsonb) => !!urlJsonb.conditionWhen)
    ?.some((urlJsonb) =>
      otherStorylinesUrls?.some(
        (otherStorylinesUrlJsonb) =>
          urlJsonb.conditionWhen === otherStorylinesUrlJsonb.conditionWhen &&
          urlJsonb.url === otherStorylinesUrlJsonb.url,
      ),
    );
export const checkIfUrlWhenConditionIsUsedByThis = ({ urlsJsonb }) =>
  urlsJsonb?.some((urlJsonb) =>
    urlsJsonb?.some((otherStorylinesUrlJsonb) => {
      const condition1 = urlJsonb?.conditionWhen || urlJsonb?.excludedWhen;
      const condition2 =
        otherStorylinesUrlJsonb?.conditionWhen || otherStorylinesUrlJsonb?.excludedWhen;
      return (
        urlJsonb.index !== otherStorylinesUrlJsonb.index &&
        condition1 === condition2 &&
        urlJsonb.url === otherStorylinesUrlJsonb.url
      );
    }),
  );

export const checkIfHasAnyConditionWhen = ({ urlsJsonb }) => {
  return urlsJsonb?.some((urlJsonb) => !!urlJsonb?.conditionWhen && urlJsonb?.url?.length > 0);
};

export const checkIfHasAnyExactMatchInvalid = ({ urlsJsonb }) => {
  return urlsJsonb?.some((urlJsonb) => {
    const condition = urlJsonb?.conditionWhen || urlJsonb?.excludedWhen;
    if (
      condition === getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) &&
      urlJsonb?.url?.length > 0
    ) {
      return !isValidHttpUrl(urlJsonb.url);
    }
    return false;
  });
};

export default function StorylineLinkHandler({
  basicOrgData = undefined,
  urlsJsonb = undefined,
  setUrlsJsonb,
  orgUrlsJsonb = undefined,
  otherStorylinesUrls = undefined,
}) {
  const lang = useLanguage();
  const { premiumFeatures } = basicOrgData;

  const isFreemium = premiumFeatures?.packageVariant === packageVariant?.freemium;
  const freemiumStoriesLimit = isFreemium && premiumFeatures?.freemiumFeatures?.stories;

  const handleAddNewRule = useCallback(() => {
    setUrlsJsonb((prevState) => [
      ...prevState,
      {
        conditionWhen: getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch),
        index: prevState?.length,
      },
    ]);
  }, [setUrlsJsonb]);

  const handleAddNewExclusionRule = () => {
    setUrlsJsonb((prevState) => [
      ...prevState,
      {
        excludedWhen: getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch),
        index: prevState?.length,
      },
    ]);
  };

  useEffect(() => {
    if (!(urlsJsonb?.length > 0)) {
      handleAddNewRule();
    }
  }, [urlsJsonb?.length, handleAddNewRule]);

  const whenConditions = () => {
    return urlsJsonb?.filter((ob) => !!ob?.conditionWhen);
  };

  const numWhenConditions = () => {
    return whenConditions()?.length;
  };

  const hasWhenConditionValue = () => {
    return whenConditions()?.every((ob) => ob?.url?.length > 0);
  };

  const hasWhenConditions = () => {
    return numWhenConditions() > 0;
  };

  const excludeConditions = () => {
    return urlsJsonb?.filter((ob) => !!ob?.excludedWhen);
  };

  const numExcludeConditions = () => {
    return excludeConditions()?.length;
  };
  const hasExcludeConditions = () => {
    return numExcludeConditions() > 0;
  };

  const hasExcludeConditionValue = () => {
    return excludeConditions()?.every((ob) => ob?.url?.length > 0);
  };

  return (
    <Box
      style={{
        backgroundColor: '#ff000000',
        width: '100%',
        height: 'auto',
        position: 'relative',
        paddingTop: '0px',
      }}
    >
      <Grid container direction="column" alignItems="left" justifyContent="center">
        <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
          <Box sx={{ marginTop: '8px' }}>
            <Typography variant="titleMedium">
              {translationsStoryline.showStorylineWidget[lang]}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
          <Box>
            <List>
              <TransitionGroup>
                <Collapse>
                  {urlsJsonb
                    ?.filter(
                      (ob) => (!ob?.conditionWhen && !ob?.excludedWhen) || !!ob?.conditionWhen,
                    )
                    ?.map((ob, index) => (
                      <StorylineLinkWhenCondition
                        urlOjb={ob}
                        basicOrgData={basicOrgData}
                        key={uniqueId(ob?.conditionWhen)}
                        indexInList={index}
                        urlsJsonb={urlsJsonb}
                        setUrlsJsonb={setUrlsJsonb}
                        orgUrlsJsonb={orgUrlsJsonb}
                        otherStorylinesUrls={otherStorylinesUrls}
                      />
                    ))}
                </Collapse>
              </TransitionGroup>
            </List>
          </Box>
        </Grid>
        <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
          <Box
            style={{
              paddingLeft: '50px',
            }}
          >
            <Button
              color="primary"
              onClick={handleAddNewRule}
              disabled={
                (hasWhenConditions() && !hasWhenConditionValue()) ||
                (freemiumStoriesLimit?.whenCondition &&
                  urlsJsonb?.filter((ob) => !!ob?.conditionWhen && !ob?.excludedWhen)?.length >=
                    freemiumStoriesLimit.whenCondition)
              }
            >
              <Add
                style={{
                  fill:
                    hasWhenConditions() && !hasWhenConditionValue()
                      ? 'rgba(0, 0, 0, 0.26)'
                      : '#292929',
                }}
              />
              {translationsStoryline.addRule[lang]}
            </Button>
            {urlsJsonb?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)?.length === 0 && (
              <Button
                color="primary"
                onClick={handleAddNewExclusionRule}
                disabled={
                  (numWhenConditions() === 1 && !hasWhenConditionValue()) ||
                  (freemiumStoriesLimit?.whenCondition &&
                    urlsJsonb?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)?.length >=
                      freemiumStoriesLimit.excludeWhenCondition)
                }
              >
                <Add
                  style={{
                    fill:
                      numWhenConditions() === 1 && !hasWhenConditionValue()
                        ? 'rgba(0, 0, 0, 0.26)'
                        : '#292929',
                  }}
                />
                {translationsStoryline.addExclusionRule[lang]}
              </Button>
            )}
          </Box>
        </Grid>
        {urlsJsonb?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)?.length > 0 && (
          <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
            <Box
              style={{
                paddingTop: '8px',
              }}
            >
              <Divider
                variant="fullWidth"
                sx={{
                  borderTopWidth: '1px',
                  borderTopColor: '#e9edfd',
                  borderRightWidth: '0px',
                  borderBottomWidth: '0px',
                  borderLeftWidth: '0px',
                  borderStyle: 'solid',
                  borderColor: '#e9edf',
                }}
              />
              <Box sx={{ marginTop: '8px' }}>
                <Typography variant="titleMedium">
                  {translationsStoryline.hideStorylineWidget[lang]}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Collapse
          in={urlsJsonb?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)?.length > 0}
        >
          <Box>
            <List>
              <TransitionGroup>
                <Collapse>
                  {urlsJsonb
                    ?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)
                    ?.map((ob, index) => (
                      <StorylineLinkWhenCondition
                        urlOjb={ob}
                        basicOrgData={basicOrgData}
                        key={uniqueId(ob?.conditionWhen)}
                        indexInList={index}
                        urlsJsonb={urlsJsonb}
                        setUrlsJsonb={setUrlsJsonb}
                        orgUrlsJsonb={orgUrlsJsonb}
                        otherStorylinesUrls={[]}
                      />
                    ))}
                </Collapse>
              </TransitionGroup>
            </List>
            <Grid item lg={12} sm={12} md={12} xl={12} xs={12} style={{ paddingTop: '15px' }}>
              <Box
                style={{
                  paddingLeft: '50px',
                }}
              >
                {urlsJsonb?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)?.length >
                  0 && (
                  <Button
                    color="secondary"
                    onClick={handleAddNewExclusionRule}
                    disabled={
                      (hasExcludeConditions() && !hasExcludeConditionValue()) ||
                      (freemiumStoriesLimit?.whenCondition &&
                        urlsJsonb?.filter((ob) => !ob?.conditionWhen && !!ob?.excludedWhen)
                          ?.length >= freemiumStoriesLimit.excludeWhenCondition)
                    }
                  >
                    <Add
                      style={{
                        fill:
                          hasExcludeConditions() && !hasExcludeConditionValue()
                            ? 'rgba(0, 0, 0, 0.26)'
                            : '#292929',
                      }}
                    />
                    {translationsStoryline.addExclusionRule[lang]}
                  </Button>
                )}
              </Box>
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    </Box>
  );
}

StorylineLinkHandler.propTypes = {
  basicOrgData: PropTypes.shape({
    premiumFeatures: PropTypes.shape({
      packageVariant: PropTypes.string,
      freemiumFeatures: PropTypes.shape({
        stories: PropTypes.shape({}),
      }),
    }),
  }),
  urlsJsonb: PropTypes.arrayOf(
    PropTypes.shape({
      conditionWhen: PropTypes.string,
      excludedWhen: PropTypes.string,
      index: PropTypes.number,
      url: PropTypes.string,
    }),
  ),
  setUrlsJsonb: PropTypes.func.isRequired,
  orgUrlsJsonb: PropTypes.arrayOf(PropTypes.shape({})),
  otherStorylinesUrls: PropTypes.arrayOf(PropTypes.shape({})),
};
