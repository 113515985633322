import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { gql } from '@apollo/client';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import ProductionBoard from './ProductionBoard';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../fragments/videoCards';
import { VIDEO_REQUEST_DETAILS_FRAGMENT } from '../../fragments/videoRequests';

const getOrganizationDataExtended = (backOfficeClient, orgNameSlug, encodedOrgId) => {
  return backOfficeClient.query({
    query: gql`
      query OrganizationExtended($orgNameSlug: String, $encodedOrgId: String) {
        organizationExtended(orgNameSlug: $orgNameSlug, encodedOrgId: $encodedOrgId) {
          id
          users {
            id
            firstName
            lastName
          }
          orgQuestions {
            id
            question
            categoryId
            forLeaders
            forTeamMember
            created
          }
          recipients {
            id
            orgId
            firstName
            lastName
            name
            phoneNumber
            email
            title
            receiveAutoRequests
            leader
          }
        }
      }
    `,
    variables: { orgNameSlug, encodedOrgId },
  });
};

const getOrganizationDataMinimized = (backOfficeClient, orgNameSlug, encodedOrgId) => {
  return backOfficeClient.query({
    query: gql`
      ${VIDEO_CARD_DETAILS_FRAGMENT}
      ${VIDEO_REQUEST_DETAILS_FRAGMENT}
      query OrganizationMinimized($orgNameSlug: String, $encodedOrgId: String) {
        organizationMinimized(orgNameSlug: $orgNameSlug, encodedOrgId: $encodedOrgId) {
          videoCards {
            ...VideoCardDetails
          }
          videoRequests {
            ...VideoRequestDetails
          }
          tagCategories {
            id
            name {
              sv
              en
            }
            tags {
              id
              name {
                sv
                en
              }
              isHidden
            }
          }
        }
      }
    `,
    variables: { orgNameSlug, encodedOrgId },
  });
};

function BackOffice() {
  const navigate = useNavigate();
  const {
    basicOrgData,
    videoCards,
    setVideoCardsState,
    setVideoCardState,
    videoRequests,
    setVideoRequestsState,
    setVideoRequestState,
    suggestedQuestions,
    users,
    orgRecipients,
  } = useOutletContext();
  const backOfficeClient = useBackOfficeApolloClient();

  const [theUltimateStateData, setTheUltimateStateData] = useState({
    orgData: basicOrgData ?? null,
    suggestedQuestions: suggestedQuestions?.current ?? [],
    users: users?.current ?? [],
    orgRecipients: orgRecipients?.current ?? [],
  });

  const updateTheUltimateStateData = (data) => {
    setTheUltimateStateData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  useEffect(() => {
    if (basicOrgData) {
      const { orgNameSlug, encodedId } = basicOrgData;
      getOrganizationDataMinimized(backOfficeClient, orgNameSlug, encodedId)
        .then((result) => {
          setVideoCardsState(result.data.organizationMinimized?.videoCards ?? []);
          setVideoRequestsState(result.data.organizationMinimized?.videoRequests ?? []);
          const newStateData = {
            videoCards: result.data.organizationMinimized?.videoCards ?? [],
            videoRequests: result.data.organizationMinimized?.videoRequests ?? [],
            orgData: {
              ...basicOrgData,
              tagCategories: result.data.organizationMinimized?.tagCategories,
            },
          };
          updateTheUltimateStateData(newStateData);
          getOrganizationDataExtended(backOfficeClient, orgNameSlug, encodedId)
            .then((extendedResult) => {
              const extendedData = extendedResult.data.organizationExtended;
              suggestedQuestions.current = extendedData.orgQuestions;
              users.current = extendedData.users;
              orgRecipients.current = extendedData.recipients;
            })
            .catch(() => {});
        })

        .catch(() => {
          navigate(`/${orgNameSlug}/login`);
        });
    }
  }, [backOfficeClient]);

  const updateVideoCard = (data) => {
    setVideoCardState(data);
  };

  const updateVideoRequest = (data) => {
    setVideoRequestState(data);
  };

  const handleUpdateBoard = () => {
    const { orgNameSlug, encodedId } = basicOrgData;
    getOrganizationDataMinimized(backOfficeClient, orgNameSlug, encodedId)
      .then((result) => {
        setVideoCardsState(result.data.organizationMinimized?.videoCards ?? []);
        setVideoRequestsState(result.data.organizationMinimized?.videoRequests ?? []);

        const newStateData = {
          orgData: {
            ...basicOrgData,
            tagCategories: result.data.organizationMinimized?.tagCategories,
          },
        };
        updateTheUltimateStateData(newStateData);
        getOrganizationDataExtended(backOfficeClient, orgNameSlug, encodedId)
          .then((extendedResult) => {
            const extendedData = extendedResult.data.organizationExtended;
            suggestedQuestions.current = extendedData.orgQuestions;
            users.current = extendedData.users;
            orgRecipients.current = extendedData.recipients;
          })
          .catch(() => {
            updateTheUltimateStateData(newStateData);
          });
      })
      .catch(() => {});
  };

  const updateVideoCardsRequests = ({ newVideoCards, newVideoRequests }) => {
    const newState = {
      videoCards: newVideoCards,
      videoRequests: newVideoRequests,
    };
    updateTheUltimateStateData(newState);
  };

  if (!theUltimateStateData?.orgData) {
    return <div />;
  }

  return (
    <ProductionBoard
      videoCards={videoCards ?? []}
      videoRequests={videoRequests ?? []}
      orgData={theUltimateStateData.orgData}
      suggestedQuestions={suggestedQuestions.current ?? []}
      users={users}
      handleUpdateBoard={() => {
        handleUpdateBoard();
      }}
      orgRecipients={theUltimateStateData?.orgRecipients ?? orgRecipients.current ?? []}
      updateVideoCard={(e) => {
        updateVideoCard(e);
      }}
      updateVideoRequest={(e) => {
        updateVideoRequest(e);
      }}
      setVideoCardsRequests={(e) => {
        updateVideoCardsRequests(e);
      }}
    />
  );
}

export default BackOffice;
