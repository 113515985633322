/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const STORYLINE_DETAILS_FRAGMENT = gql`
  fragment StorylineDetails on Storyline {
    excludeWidgetLibraryBtn
    id
    status
    user {
      id
      firstName
      lastName
    }
    orgId
    title
    urls
    urlsJsonb {
      url
      srcImage
      imageUpdatedAt
      srcImageFullPage
      conditionWhen
      excludedWhen
      storylineId
      override
    }
  }
`;
