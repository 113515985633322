import React from 'react';
import Button, { ButtonOwnProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

interface AlertDialogProps {
  open: boolean;
  description: string;
  subDescription?: string;
  title: string;
  confirmBtnText: string;
  cancelBtnText: string;
  confirmBtnColor?: ButtonOwnProps['color'];
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
}

export default function AlertDialog({
  open,
  description,
  subDescription = '',
  title,
  confirmBtnText,
  cancelBtnText,
  confirmBtnColor = 'error',
  onConfirm,
  onCancel,
  onClose = undefined,
}: AlertDialogProps) {
  // Fallback to onCancel for dialog close if onClose not provided.
  const dialogOnClose = onClose ?? onCancel;

  return (
    <Dialog
      open={open}
      onClose={dialogOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="alert-dialog"
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" data-testid="dialog-description">
          <Typography variant="bodyLarge" color="text.primary">
            {description}
          </Typography>
          {subDescription && (
            <>
              <br />
              <br />
              <Typography
                variant="bodyLarge"
                color="text.primary"
                data-testid="dialog-sub-description"
              >
                {subDescription}
              </Typography>
              <br />
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 0.5,
        }}
      >
        <Button onClick={onCancel} variant="outlined" data-testid="cancel-button">
          {cancelBtnText}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color={confirmBtnColor}
          autoFocus
          data-testid="confirm-button"
        >
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
