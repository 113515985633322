/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Box, Button, IconButton } from '@mui/material';
import { useLanguage } from 'src/context/LanguageContext';
import { VideoContainerProps } from './types';

const translations = {
  start: {
    en: 'Start',
    sv: 'Starta',
  },
  ariaLabel: {
    videoStream: {
      en: 'Video Stream',
      sv: 'Videostream',
    },
    closeSession: {
      en: 'Close Session',
      sv: 'Stäng Session',
    },
    repeat: {
      en: 'Repeat',
      sv: 'Upprepa',
    },
    startSession: {
      en: 'Start Session',
      sv: 'Starta Session',
    },
  },
};

function VideoContainer({
  sessionInfo,
  isConnected,
  loading,
  hasSessionInfo,
  handleCloseSession,
  handleRepeat,
  handleNewSession,
  text,
  videoRef,
  videoGridItemRef,
  avatarPosterUrl,
}: VideoContainerProps) {
  const lang = useLanguage();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        aspectRatio: '16/9',
        position: 'relative',
      }}
      ref={videoGridItemRef}
      data-testid="video-container"
    >
      {!sessionInfo?.session_id && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'primary.main',
            opacity: 0.6,
            borderRadius: '16px',
          }}
          data-testid="overlay"
        />
      )}
      <video
        ref={videoRef}
        poster={avatarPosterUrl}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          backgroundColor: '#000',
          borderRadius: '16px',
          aspectRatio: '16/9',
        }}
        autoPlay
        aria-label={translations.ariaLabel.videoStream[lang]}
        data-testid="video-element"
      />
      {hasSessionInfo && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            backgroundColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
            '&:disabled': {
              backgroundColor: 'secondary.light',
              color: 'text.light',
              opacity: 0.3,
            },
          }}
          disabled={loading || !hasSessionInfo || !isConnected}
          onClick={handleCloseSession}
          aria-label={translations.ariaLabel.closeSession[lang]}
          data-testid="close-session-button"
        >
          <CloseIcon fontSize="small" sx={{ color: 'text.light' }} />
        </IconButton>
      )}
      {hasSessionInfo && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            bottom: 8,
            backgroundColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
            '&:disabled': {
              backgroundColor: 'secondary.light',
              color: 'text.light',
              opacity: 0.3,
            },
          }}
          disabled={loading || text.trim() === ''}
          onClick={handleRepeat}
          aria-label={translations.ariaLabel.repeat[lang]}
          data-testid="repeat-button"
        >
          <ReplayRoundedIcon fontSize="small" sx={{ color: 'text.light' }} />
        </IconButton>
      )}
      {!hasSessionInfo && (
        <Button
          color="secondary"
          variant="contained"
          onClick={handleNewSession}
          disabled={loading || hasSessionInfo}
          sx={{
            position: 'absolute',
            bottom: '8px',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            m: 0,
          }}
          aria-label={translations.ariaLabel.startSession[lang]}
          data-testid="start-button"
        >
          {translations.start[lang]}
        </Button>
      )}
    </Box>
  );
}

export default VideoContainer;
