import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import ThemeContext from 'src/utils/ThemeContext';
import { TabData } from './types';

interface TabIconProps {
  isActive: boolean;
  isHovered: boolean;
  tab: TabData;
}

function TabIcon({ isActive, isHovered, tab }: TabIconProps) {
  const theme = useContext(ThemeContext);
  let icon;
  if (isActive) {
    icon = tab.iconFilled;
  } else if (isHovered) {
    icon = tab.iconHover;
  } else {
    icon = tab.iconOutlined;
  }

  let backgroundColor = 'transparent';
  if (isActive) {
    backgroundColor = theme.palette.secondary.light;
  } else if (isHovered) {
    backgroundColor = theme.palette.secondary.dark;
  }

  return (
    <Grid
      item
      xs={12}
      xl={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        borderRadius: '100px',
        backgroundColor,
      }}
      role="presentation"
      data-testid={`tab-icon-${tab.label.replace(/\s+/g, '-').toLowerCase()}`}
    >
      <Box
        key={`${tab.label}-icon-item-state-layer`}
        component="span"
        sx={{
          width: '100%',
          height: '100%',
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 0.5,
          paddingBottom: 0.5,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'inline-flex',
        }}
        aria-label={`${tab.label} icon`}
        data-testid={`tab-icon-item-${tab.label.replace(/\s+/g, '-').toLowerCase()}`}
      >
        {icon}
      </Box>
    </Grid>
  );
}

export default TabIcon;
