import React, { useMemo } from 'react';
import { useOutletContext, Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../../utils/translationsSettings';
import TabSelector from '../../common/TabSelector/TabSelector';
import useUserData from './userDataHook/userDataHook';

interface BasicOrgData {
  encodedId: string;
  id: number;
  orgNameSlug: string;
  premiumFeatures?: {
    videoRequestEmailCustomization?: boolean;
    organizationPermissionsSettings?: boolean;
  };
}

interface Tab {
  value: string;
  label: string;
  path: string;
  disabled: boolean;
  index?: number;
}

interface SettingsTemplateContext {
  basicOrgData: BasicOrgData;
}

export default function SettingsTemplate() {
  const lang = useLanguage();
  const { basicOrgData }: SettingsTemplateContext = useOutletContext();
  const orgHasVideoRequestEmailCustomization =
    basicOrgData?.premiumFeatures?.videoRequestEmailCustomization ?? false;

  const { data: userData, isSuperAdmin } = useUserData(basicOrgData.encodedId);

  const orgHasOrganizationPermissionsSettings =
    basicOrgData?.premiumFeatures?.organizationPermissionsSettings ?? false;

  const userHasOrganizationPermissionsSettings =
    orgHasOrganizationPermissionsSettings &&
    (isSuperAdmin ||
      userData?.me.userPermissions?.some(
        (permission) => permission.orgId === basicOrgData.id && permission.name === 'org_admin',
      ));

  const shouldShowOrganizationSettings =
    (orgHasOrganizationPermissionsSettings && userHasOrganizationPermissionsSettings) ||
    !orgHasOrganizationPermissionsSettings;

  const availableTabs = useMemo(() => {
    let tabs: Tab[] = [
      {
        value: 'recipients',
        label: translations.settingsMenu.recipients[lang],
        path: `/${basicOrgData.orgNameSlug}/backoffice/settings/recipients`,
        disabled: false,
        index: 3,
      },
      {
        value: 'questions',
        label: translations.settingsMenu.questions[lang],
        path: `/${basicOrgData.orgNameSlug}/backoffice/settings/questions`,
        disabled: false,
        index: 4,
      },
    ];

    if (shouldShowOrganizationSettings) {
      tabs = [
        ...tabs,
        {
          value: 'general',
          label: translations.settingsMenu.general[lang],
          path: `/${basicOrgData.orgNameSlug}/backoffice/settings`,
          disabled: false,
          index: 0,
        },
        {
          value: 'widget-script',
          label: translations.settingsMenu.widgetScript[lang],
          path: `/${basicOrgData.orgNameSlug}/backoffice/settings/widget-script`,
          disabled: false,
          index: 1,
        },
        {
          value: 'automation',
          label: translations.settingsMenu.automation[lang],
          path: `/${basicOrgData.orgNameSlug}/backoffice/settings/automation`,
          disabled: false,
          index: 2,
        },
        {
          value: 'styling',
          label: translations.settingsMenu.styling[lang],
          path: `/${basicOrgData.orgNameSlug}/backoffice/settings/styling`,
          disabled: false,
          index: 5,
        },
      ];
    }

    const shouldShowVideoRequestEmailTab =
      (orgHasVideoRequestEmailCustomization && userHasOrganizationPermissionsSettings) ||
      (orgHasVideoRequestEmailCustomization && !orgHasOrganizationPermissionsSettings);

    if (shouldShowVideoRequestEmailTab) {
      tabs.push({
        value: 'video-request-email',
        label: translations.settingsMenu.videoRequestEmail[lang],
        path: `/${basicOrgData.orgNameSlug}/backoffice/settings/video-request-email`,
        disabled: false,
        index: 6,
      });
    }

    if (userHasOrganizationPermissionsSettings) {
      tabs.push({
        value: 'permissions',
        label: translations.settingsMenu.permissions[lang],
        path: `/${basicOrgData.orgNameSlug}/backoffice/settings/permissions`,
        disabled: false,
        index: 7,
      });
    }

    return tabs.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
  }, [
    lang,
    basicOrgData,
    userHasOrganizationPermissionsSettings,
    orgHasVideoRequestEmailCustomization,
    orgHasOrganizationPermissionsSettings,
    shouldShowOrganizationSettings,
  ]);

  return (
    <div className="settings-template">
      <Grid
        container
        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
        justifyContent={{
          xs: 'flex-start',
          sm: 'flex-start',
          md: 'flex-start',
          lg: 'space-between',
          xl: 'space-between',
        }}
        alignItems={{
          xs: 'flex-start',
          sm: 'flex-start',
          md: 'flex-start',
          lg: 'center',
          xl: 'center',
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <Typography variant="headlineLargeBoldRecoleta">
            {translations.settingsMenu.settings[lang]}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TabSelector
            availableTabs={availableTabs}
            setActiveTab={undefined}
            activeTab={undefined}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2} xl={2} />
      </Grid>
      <Outlet context={{ basicOrgData }} />
    </div>
  );
}
