import '../back-office.scss';

import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import PreviewIcon from '@mui/icons-material/Preview';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { storylineStatuses, actions } from '../../../utils/utils';

function SettingsFooter({
  save,
  itemStatus,
  saveAndNext,
  storylineVideoCards = [],
  handleRandomVideoOrder,
  randomizeVideoOrder,
  handleEditStoryline = () => {},
  editableStoryline,
  showPreview,
  setShowPreview,
  handleDeleteClicked,
  disabledLive,
}) {
  const lang = useLanguage();

  switch (itemStatus) {
    case storylineStatuses.LIVE: {
      return (
        <div className="modal-footer">
          <div style={{ alignItems: 'center' }}>
            <Button
              onClick={() => {
                handleDeleteClicked(true);
              }}
            >
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translationsStoryline.deleteItem[lang]}
            </Button>
            <Button onClick={handleEditStoryline}>
              {!editableStoryline && <i className="icon">edit</i>}
              {editableStoryline
                ? translationsStoryline.setStorylineVideos[lang]
                : translationsStoryline.editStorylineVideos[lang]}
            </Button>
            <Chip label={`${storylineVideoCards.length} ${translationsStoryline.selected[lang]}`} />
          </div>
          <div>
            <Button onClick={() => setShowPreview(!showPreview)}>
              <PreviewIcon fontSize="small" style={{ marginRight: '8px', marginLeft: '0px' }} />
              {!showPreview
                ? translationsStoryline.videoFunnel.showPreview[lang]
                : translationsStoryline.videoFunnel.hidePreview[lang]}
            </Button>

            <Button onClick={handleRandomVideoOrder}>
              <img
                src={`/images/icons/${randomizeVideoOrder ? 'shuffle-on.svg' : 'shuffle-off.svg'}`}
                alt=""
                style={{ height: '16px' }}
              />
              {translationsStoryline.shuffle[lang]}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => saveAndNext(actions.UNPUBLISH)}
            >
              <img src="/images/icons/unpublish-icon.svg" alt="" />
              {translationsStoryline.deactivate[lang]}
            </Button>
            <Button variant="contained" color="primary" disableElevation onClick={save}>
              {translationsStoryline.save[lang]}
            </Button>
          </div>
        </div>
      );
    }
    default: {
      return (
        <div className="modal-footer">
          <div style={{ alignItems: 'center' }}>
            <Button
              onClick={() => {
                handleDeleteClicked(true);
              }}
            >
              <img src="/images/icons/bin-icon.svg" alt="" />
              {translationsStoryline.deleteItem[lang]}
            </Button>
            <Button onClick={handleEditStoryline}>
              {!editableStoryline && <i className="icon">edit</i>}
              {editableStoryline
                ? translationsStoryline.setStorylineVideos[lang]
                : translationsStoryline.editStorylineVideos[lang]}
            </Button>
            <Chip label={`${storylineVideoCards.length} ${translationsStoryline.selected[lang]}`} />
          </div>
          <div>
            <Button onClick={() => setShowPreview(!showPreview)}>
              <PreviewIcon fontSize="small" style={{ marginRight: '8px', marginLeft: '0px' }} />
              {!showPreview
                ? translationsStoryline.videoFunnel.showPreview[lang]
                : translationsStoryline.videoFunnel.hidePreview[lang]}
            </Button>
            <Button onClick={handleRandomVideoOrder}>
              <img
                src={`/images/icons/${randomizeVideoOrder ? 'shuffle-on.svg' : 'shuffle-off.svg'}`}
                alt=""
                style={{ height: '16px' }}
              />{' '}
              {translationsStoryline.shuffle[lang]}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => saveAndNext(actions.PUBLISH)}
              disabled={disabledLive}
            >
              {translationsStoryline.activate[lang]}
            </Button>
            <Button variant="contained" color="primary" disableElevation onClick={save}>
              {translationsStoryline.save[lang]}
            </Button>
          </div>
        </div>
      );
    }
  }
}

export default SettingsFooter;
