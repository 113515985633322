import { useCallback, useState } from 'react';
import { AlertId, AlertState, AlertsState } from './types';

export type ShowAlertFunc = (id: AlertId) => void;
export type HideAlertFunc = (id: AlertId) => void;

const DEFAULT_ALERTS_STATE: AlertsState = {
  ALERT_PREVIEW_NOTICE: {
    visible: false,
  },
  ALERT_PREVIEW_TOKEN_ERROR: {
    visible: false,
  },
  ALERT_UPLOAD_SUCCESSFUL: {
    visible: false,
  },
  ALERT_UPLOAD_FAILED: {
    visible: false,
  },
};

const useAlerts: () => [AlertsState, ShowAlertFunc, HideAlertFunc] = () => {
  const [alerts, setAlerts] = useState<AlertsState>(DEFAULT_ALERTS_STATE);

  const updateAlert = useCallback(
    (id: AlertId, propsToUpdate: Partial<AlertState>) => {
      setAlerts((currentState) => {
        return { ...currentState, [id]: { ...currentState[id], ...propsToUpdate } };
      });
    },
    [setAlerts],
  );

  const showAlert = useCallback<ShowAlertFunc>(
    (id: AlertId) => updateAlert(id, { visible: true }),
    [updateAlert],
  );

  const hideAlert = useCallback<HideAlertFunc>(
    (id: AlertId) => updateAlert(id, { visible: false }),
    [updateAlert],
  );

  return [alerts, showAlert, hideAlert];
};

export default useAlerts;
