import React, { useEffect, useState } from 'react';
import { FetchStoryAnalyticsDataParams, useStoryStatsApi } from './StoryStatsApi';
import { StatsByTypeTotalResult } from '../Analytics/Overview/analyticsOverviewApi';
import StatsDisplay from '../StatsDisplay';

const isLocalhostOrStaging =
  window.location.href.includes('localhost') || window.location.href.includes('app.staging');

function StoryAnalytics({ encodedOrgId, identifier }: FetchStoryAnalyticsDataParams) {
  const storyStatsApi = useStoryStatsApi();
  const [storyAnalytics, setStoryAnalytics] = useState<StatsByTypeTotalResult | null>(null);
  const [error, setError] = useState<string | null | unknown>(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      if (!storyStatsApi || !encodedOrgId || !identifier) {
        return;
      }
      try {
        const data = await storyStatsApi.fetchStoryAnalyticsData({
          encodedOrgId,
          identifier,
        });
        if (data?.totalStats) {
          setStoryAnalytics(data.totalStats);
        }
      } catch (err) {
        setError(err);
        if (isLocalhostOrStaging) {
          console.warn(err);
        }
      }
    };

    fetchAnalytics();
  }, [storyStatsApi, encodedOrgId, identifier]);

  if (!storyAnalytics || error) {
    return null;
  }

  return <StatsDisplay stats={storyAnalytics} layout="horizontal" />;
}

export default StoryAnalytics;
