import PropTypes from 'prop-types';

function DashboardOutlined({ fill = '#FFFFFF' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 5C21 3.89543 20.1046 3 19 3H13V9H21V5ZM11 3H5C3.89543 3 3 3.89543 3 5V13H11V3ZM21 11H13V21H19C20.1046 21 21 20.1046 21 19V11ZM11 15H3V19C3 20.1046 3.89543 21 5 21H11V15Z"
        fill={fill}
      />
    </svg>
  );
}

export default DashboardOutlined;

DashboardOutlined.propTypes = {
  fill: PropTypes.string,
};
