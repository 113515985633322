import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export interface SplitButtonOption {
  label: string;
  muiIcon?: React.ReactNode;
  img?: string;
  onClick: () => void;
  disabled: boolean;
}

interface LegacySplitButtonOption {
  label: string;
  muiIcon?: React.ReactNode;
  img?: string;
  disabled: boolean;
  type: string;
}

interface SplitButtonProps {
  options: SplitButtonOption[] | LegacySplitButtonOption[];
  menuPlacement?:
    | 'bottom'
    | 'top'
    | 'right'
    | 'left'
    | 'top-end'
    | 'top-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'right-end'
    | 'right-start'
    | 'left-end'
    | 'left-start';
  disabled?: boolean;
  fillWidth?: boolean;
  variant?: 'contained' | 'outlined';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disableElevation?: boolean;
  asFab?: boolean;
  handleClick?: (option: LegacySplitButtonOption) => void;
}

function SplitButton({
  options,
  menuPlacement = 'bottom',
  disabled = false,
  fillWidth = false,
  variant = 'contained',
  color = 'primary',
  disableElevation = true,
  asFab = false,
  handleClick = undefined,
}: SplitButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (option: SplitButtonOption | LegacySplitButtonOption) => {
    if (option.disabled) {
      return;
    }
    if ('onClick' in option && option.onClick) {
      option.onClick();
    } else if (handleClick) {
      handleClick(option as LegacySplitButtonOption);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    const { target } = event;
    if (anchorRef.current && anchorRef.current.contains(target as Node)) {
      return;
    }
    setOpen(false);
  };

  if (options.length === 0) {
    return null;
  }

  return (
    <>
      <ButtonGroup
        variant={variant}
        ref={anchorRef}
        color={color}
        disabled={disabled}
        style={{
          width: fillWidth ? '100%' : undefined,
          margin: asFab ? '0px 8px' : undefined,
          boxShadow: asFab ? '0px 1px 3px rgba(0, 0, 0, 0.30)' : undefined,
        }}
        disableElevation={disableElevation}
        aria-label="split button"
        data-testid="split-button-group"
        role="group"
      >
        <Button
          variant={variant}
          color={color}
          onClick={() => handleMenuItemClick(options[0])}
          disabled={disabled || options[0].disabled}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : 'false'}
          data-testid={`option-${options[0].label}`}
          disableElevation={disableElevation}
          role="button"
          style={{
            ...(fillWidth ? { width: '100%' } : {}),
            ...(asFab ? { height: '48px' } : {}),
            ...(options?.length > 1 ? { paddingRight: '8px' } : {}),
            ...(options?.length > 1 ? { marginRight: '0px', borderRight: 'none' } : {}),
            ...(options[0].muiIcon ? { paddingLeft: '16px' } : {}),
            margin: '0px',
            ...(options?.length === 1 && asFab ? { borderRadius: '16px' } : {}),
            ...(asFab ? { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.30)' } : {}),
            ...(asFab && options?.length > 1
              ? { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }
              : {}),
          }}
        >
          {options[0].muiIcon}
          {options[0].img && <img src={options[0].img} alt="" />}
          {options[0].label}
        </Button>

        {options.length > 1 && (
          <Button
            color={color}
            variant={variant}
            onClick={handleToggle}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : 'false'}
            aria-haspopup="menu"
            data-testid="toggle-button"
            disableElevation={disableElevation}
            style={{
              padding: '6px 8px',
              paddingLeft: '6px',
              margin: '0px',
              ...(asFab && options?.length > 1
                ? { borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }
                : {}),
              ...(asFab ? { boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.30)' } : {}),
            }}
          >
            <i className="icon">arrow_drop_down</i>
          </Button>
        )}
      </ButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role="presentation"
        transition
        disablePortal
        placement={menuPlacement}
        style={{ zIndex: 1000 }}
        data-testid="split-button-popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem data-testid="menu-list" role="menu">
                  {options.slice(1).map((option, index) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => handleMenuItemClick(option)}
                      data-testid={`menu-item-${index}`}
                      role="menuitem"
                    >
                      {option.muiIcon}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default SplitButton;
