import React from 'react';
import { Button, Grid, CircularProgress } from '@mui/material';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import translationsSignup from '../../../utils/translationsSignup';
import { signupStatuses } from '../../../utils/utils';
import { resendVerificationCode } from './freemiumApi';

function SignUpButton({
  status,
  isLoading,
  activeStep,
  handleOnSubmit,
  isStepsValid,
  setIsLoading,
  token = '',
  setToken,
  setStatus,
  onSubmitAsync,
  setErrorMsg,
  steps,
  setActiveStep,
  isStepValid,
}) {
  const lang = useLanguage();
  if (status === signupStatuses.waitingPhoneNumberVerification) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item lg={12} sm={12} xl={12} xs={12} md={12} sx={{ mt: 2 }}>
          <Button
            key={uniqueId(translationsSignup.verifyPhoneNumberButtonLabel[lang])}
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            disableRipple
            sx={{
              textTransform: 'none',
              position: 'relative',
              color: 'white',
              fontSize: '14px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: '20px',
              letterSpacing: 0.1,
              wordWrap: 'break-word',
              background: '#121212',
              borderRadius: 100,
              overflow: 'hidden',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 8,
              display: 'inline-flex',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
            onClick={(e) => {
              e.preventDefault();
              handleOnSubmit();
            }}
            disabled={!isStepsValid() || isLoading}
          >
            {translationsSignup.verifyPhoneNumberButtonLabel[lang]}
            {isLoading && (
              <CircularProgress
                color="inherit"
                size="24px"
                sx={{ position: 'absolute', right: '16px' }}
              />
            )}
          </Button>
        </Grid>
        <Grid item>
          <Button
            key={uniqueId(translationsSignup.verifyPhoneNumberButtonLabel[lang])}
            type="submit"
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            sx={{
              textTransform: 'none',
              position: 'relative',
              textAlign: 'center',
              color: '#0288D1',
              fontSize: '14px',
              fontFamily: 'Inter',
              fontWeight: '500',
              lineHeight: 20 / 14,
              letterSpacing: 0.1,
              wordWrap: 'break-word',
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsLoading(true);
              resendVerificationCode({ token })
                .then((response) => {
                  setToken(response.token);
                  setStatus(signupStatuses.waitingPhoneNumberVerification);

                  if (response.error) {
                    setStatus(signupStatuses.error);
                    setErrorMsg(translationsSignup.internalerror[lang]);
                  }
                  setIsLoading(false);
                })
                .catch(() => {
                  setStatus(signupStatuses.error);
                  setErrorMsg(translationsSignup.internalerror[lang]);
                  setIsLoading(false);
                });
            }}
            disabled={isLoading}
          >
            {translationsSignup.resendVerificationCode[lang]}
            {isLoading && (
              <CircularProgress
                color="inherit"
                size="24px"
                sx={{ position: 'absolute', right: '16px' }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (activeStep === steps.length - 1) {
    return (
      <Button
        key={uniqueId(translationsSignup.completeRegistration[lang])}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disableElevation
        disableRipple
        sx={{
          textTransform: 'none',
          position: 'relative',
          color: 'white',
          fontSize: '14px',
          fontFamily: 'Inter',
          fontWeight: '500',
          lineHeight: '20px',
          letterSpacing: 0.1,
          wordWrap: 'break-word',
          background: '#121212',
          borderRadius: 100,
          overflow: 'hidden',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,
          display: 'inline-flex',
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        onClick={() => onSubmitAsync()}
        disabled={!isStepValid() || !isStepsValid() || isLoading}
      >
        {token?.length > 0
          ? translationsSignup.updateRegistration[lang]
          : translationsSignup.completeRegistration[lang]}
        {isLoading && (
          <CircularProgress
            color="inherit"
            size="24px"
            sx={{ position: 'absolute', right: '16px' }}
          />
        )}
      </Button>
    );
  }
  return (
    <Button
      key={uniqueId(translationsSignup.next[lang])}
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disableElevation
      disableRipple
      sx={{
        textTransform: 'none',
        position: 'relative',
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: 0.1,
        wordWrap: 'break-word',
        background: '#121212',
        borderRadius: 100,
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        display: 'inline-flex',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '10px',
        paddingBottom: '10px',
      }}
      onClick={(e) => {
        e.preventDefault();
        setActiveStep(activeStep + 1);
      }}
      disabled={!isStepValid() || isLoading}
    >
      {translationsSignup.next[lang]}
      {isLoading && (
        <CircularProgress
          color="inherit"
          size="24px"
          sx={{ position: 'absolute', right: '16px' }}
        />
      )}
    </Button>
  );
}

SignUpButton.propTypes = {
  status: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  isStepsValid: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  token: PropTypes.string,
  setToken: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  onSubmitAsync: PropTypes.func.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  isStepValid: PropTypes.func.isRequired,
};

export default SignUpButton;
