import { useState, useRef } from 'react';
import { Position, Handle, useReactFlow } from 'reactflow';
import { useOutletContext } from 'react-router-dom';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import connectableFn from './connectableFn';
import VideoFunnelAddVideoDialog from './VideoFunnelAddVideoDialog';
import { HandleToolTip } from './HandleToolTip';

const maxVideo = 1;
const onMediaFallback = (event) => (event.target.src = '/images/icons/empty-video-node.svg');

const cardHeight = 606;
const cardWidth = cardHeight * 0.498;
function VideoFunnelEmptyVideoNode(props) {
  const { data, isConnectable, type, id } = props;
  const isDragging = data?.isDragging ? data?.isDragging : false;
  const isTarget = data?.isTarget ? data?.isTarget : false;
  const containerRef = useRef(null);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const { getEdges, getNodes, setNodes } = useReactFlow();

  const lang = useLanguage();
  const { basicOrgData } = useOutletContext();

  const hasSourceConnections = data?.id && connectableFn({ id: data.id, direction: 'source' });

  const hasTargetConnections = data?.id && connectableFn({ id: data.id, direction: 'target' });

  const hasSourceConnectionHandelA =
    data?.id && connectableFn({ id: data.id, direction: 'source', sourceHandle: 'a' });
  const hasSourceConnectionHandelB =
    data?.id && connectableFn({ id: data.id, direction: 'source', sourceHandle: 'b' });
  const hasSourceConnectionHandelC =
    data?.id && connectableFn({ id: data.id, direction: 'source', sourceHandle: 'c' });
  const hasSourceConnectionHandelD =
    data?.id && connectableFn({ id: data.id, direction: 'source', sourceHandle: 'd' });

  const isValidConnection = (connection) => {
    if ('a' === connection.sourceHandle && hasSourceConnectionHandelA) {
      return false;
    }
    if ('b' === connection.sourceHandle && hasSourceConnectionHandelB) {
      return false;
    }
    if ('c' === connection.sourceHandle && hasSourceConnectionHandelC) {
      return false;
    }
    if ('d' === connection.sourceHandle && hasSourceConnectionHandelD) {
      return false;
    }
    return (
      connection.target !== connection.source &&
      getEdges()?.filter(
        (edge) => edge.target === connection.target && edge.source === connection.source,
      )?.length === 0
    );
  };

  const handleOnCloseAddVideo = ({ newVideoCards }) => {
    setOpenAddVideo(false);
    if (newVideoCards?.length === maxVideo) {
      const videoCard = newVideoCards[0];

      setNodes((els) => {
        const nodes = els;

        const node = nodes?.find((n) => n.id === id);

        const newNodes = nodes?.filter((n) => n.id !== id);

        return [
          ...(newNodes?.length
            ? newNodes?.map((n) => ({ ...n, data: { ...n?.data, shouldCheckConnections: true } }))
            : []),
          {
            ...node,
            type: 'videoFunnelVideoNode',
            data: {
              label: videoCard?.question?.[lang],
              id: id,
              videoCard: videoCard,
              shouldCheckConnections: true,
            },
            newNode: true,
          },
        ];
      });
    }
  };

  return (
    <div
      className={`videoFunnel-video-node${hasTargetConnections ? ' hasTarget' : ''} empty${
        isTarget ? ' isTarget' : ''
      }`}
      style={{
        height: cardHeight * (isDragging ? 0.95 : isTarget ? 1.15 : 1),
        width: cardWidth * (isDragging ? 0.95 : isTarget ? 1.15 : 1),
        padding: isDragging ? '5px' : '0px',
        transition: 'padding 0.3s, width 0.3s, height 0.3s',
      }}
    >
      <Card style={{ width: '100%', height: '100%', position: 'relative' }}>
        <CardContent style={{ width: '100%', height: '100%' }}>
          <IconButton
            onClick={() => {
              setOpenAddVideo(true);
            }}
            style={{
              position: 'fixed',
              width: 'auto',
              height: '23%',
              aspectRatio: '1 / 1',
              border: '2px solid ',
              bottom: '50%',
              left: '50%',
              transform: 'translate(-55%, 43%)',
              padding: 0,
              zIndex: 2,
            }}
          >
            <AddRoundedIcon fontSize="large" style={{ width: 'unset', height: 'unset' }} />
          </IconButton>
          <Box
            style={{
              position: 'fixed',
              top: '57%',
              left: '50%',
              transform: 'translate(-52%, 50%)',
              padding: 0,
              zIndex: 2,
              minWidth: '80%',
            }}
          >
            <Button color="secondary" onClick={() => setOpenAddVideo(true)}>
              {translationsStoryline.videoFunnel.clickToAddVideo[lang]}
            </Button>
          </Box>
          <CardMedia
            component="img"
            image={'images/icons/empty-video-node.svg'}
            sx={{ borderRadius: 5 }}
            onError={(e) => {
              onMediaFallback(e);
            }}
            style={{ width: '100%', height: '100%', opacity: 0.4 }}
          />
        </CardContent>
      </Card>
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        isValidConnection={isValidConnection}
      />

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable && !hasSourceConnectionHandelA}
        isConnectableStart={isConnectable && !hasSourceConnectionHandelA}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandelA}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandelA ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandel={hasSourceConnectionHandelA}
      >
        A
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable && !hasSourceConnectionHandelB}
        isConnectableStart={isConnectable && !hasSourceConnectionHandelB}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandelB}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandelB ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandel={hasSourceConnectionHandelB}
      >
        B
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="c"
        isConnectable={isConnectable && !hasSourceConnectionHandelC}
        isConnectableStart={isConnectable && !hasSourceConnectionHandelC}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandelC}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandelC ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandel={hasSourceConnectionHandelC}
      >
        C
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="d"
        isConnectable={isConnectable && !hasSourceConnectionHandelD}
        isConnectableStart={isConnectable && !hasSourceConnectionHandelD}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandelD}
        isValidConnection={isValidConnection}
        className={hasSourceConnectionHandelD ? 'connectionindicator pointer' : ''}
        hasSourceConnectionHandel={hasSourceConnectionHandelD}
      >
        D
      </HandleToolTip>
      <VideoFunnelAddVideoDialog
        open={openAddVideo}
        handleClose={handleOnCloseAddVideo}
        containerRef={containerRef}
        basicOrgData={basicOrgData}
        maxVideos={maxVideo}
      />
    </div>
  );
}

export default VideoFunnelEmptyVideoNode;
