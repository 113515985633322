import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import parse from 'html-react-parser';
import _, { set } from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { translations } from '../../utils/translations';
import { frontendPath, backendPostPath, cdnPath } from '../../utils/environment';
import {
  statuses,
  actions as ACTIONS,
  isValidEmail,
  isValidPhoneNumberInput,
  isValidHttpUrl,
  getVimeoVideoIdByItem,
  getVimeoVideoIdFromLink,
  packageVariant,
} from '../../utils/utils';
import ConfirmPop from '../common/ConfirmPop';
import SettingsFooter from './VideoCardSettingsFooter';

import VideoActionOption from './VideoCardSettings/VideoActionSection/VideoActionOption';
import VideoRequestSection from './VideoCardSettings/VideoRequestSection';

import VideoStorylineSection from './VideoCardSettings/VideoStorylineSection/VideoStorylineSection';

import VideoSection from './VideoCardSettings/VideoSection/VideoSection';
import ManageSubtitleTransitionsModal from './VideoCardSettings/Subtitle/ManageSubtitle';

import { useRequestsApi } from './Requests/RequestsApi';

import AlertDialog from '../common/AlertDialog';

import Loader from '../common/Loader';

import { useActions } from './actions';

import Tags from '../common/Tags';

import './back-office.scss';

function VideoCardSettings({
  item,
  close,
  orgData,
  setAffected,
  setInfo,
  suggestedQuestions,
  duplicateItem,
  handleDuplicateItemClick,
  handleUpdateBoard,
  orgRecipients,
  directUpload = false,
  uploadComplete = false,
  setVideoCardData,
}) {
  const lang = useLanguage();
  const actions = useActions();
  const requestsApi = useRequestsApi();
  const { videoCards } = useOutletContext();

  const isFreemium = orgData?.premiumFeatures?.packageVariant === packageVariant.freemium;
  const freemiumVideoCardsLimit =
    isFreemium && orgData?.premiumFeatures?.freemiumFeatures?.videoCard;

  const formatTags = (dbTags) => {
    if (!dbTags) return [];
    return _.map(dbTags, (t) => ({
      id: t.id,
      selected: true,
    }));
  };

  const [manageSubtitleOpen, setManageSubtitleOpen] = useState(false);
  const InteractionOptionAllowed = orgData?.premiumFeatures?.interactionOption ?? false;
  const orgHasWidgetThree = orgData?.premiumFeatures?.widgetThree ?? false;
  const orgHasVideoFunnel = orgData?.premiumFeatures?.videoFunnel ?? false;
  const [textMessageAllowed] = useState(orgData?.premiumFeatures?.textMessage ?? false);
  const [subtitleOption] = useState(orgData?.premiumFeatures?.subtitleAllowed ?? false);
  const [includeSubtitle] = useState(orgData?.premiumFeatures?.includeSubtitle ?? false);
  const [videoCardId, setVideoCardId] = useState(item?.id ?? '');
  const [question, setQuestion] = useState((item?.question && item?.question[lang]) ?? '');
  const [actorName, setActorName] = useState(item?.recipient?.name ?? item?.actorName ?? '');
  const [actorTitle, setActorTitle] = useState(
    item?.recipient?.title ?? item?.actorTitle?.[lang] ?? '',
  );
  const [actorEmail, setActorEmail] = useState(item?.recipient?.email ?? item?.actorEmail ?? '');
  const [actorPhoneNumber, setActorPhoneNumber] = useState(
    item?.recipient?.phoneNumber ?? item?.actorPhoneNumber ?? '',
  );
  const [internalNote, setInternalNote] = useState(item?.internalNote?.[lang] ?? '');
  const [videoLink, setVideoLink] = useState(
    !orgData?.premiumFeatures?.excludeFromVimeo ? item?.videoLink?.[lang] : item?.videoLink ?? '',
  );

  const [itemTags, setItemTags] = useState((item && formatTags(item?.tags)) ?? []);
  const [hideFromLibrary, setHideFromLibrary] = useState(item?.hideFromLibrary ?? false);
  const [selectedInteraction, setSelectedInteraction] = useState(
    item?.interactionOption?.type ?? null,
  );
  const [itemUser, setItemUser] = useState(item?.user ?? null);

  const [error, setError] = useState(null);
  const [videoError, setVideoError] = useState(null);

  const [storylineInclude, setStorylineInclude] = useState([]);
  const [newStoryLineInclude, setNewStoryLineInclude] = useState([]);

  const [confirmPopData, setConfirmPopData] = useState(null);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deactivateConfirmDialog, setDeactivateConfirmDialog] = useState(false);

  const [recipient, setRecipient] = useState(item?.recipient ? item?.recipient : null);

  const [intervalId, setIntervalId] = useState();

  const [subtitles, setSubtitles] = useState(item?.subtitles ?? []);

  const hasVideoLink = () => {
    if (!orgData?.premiumFeatures?.excludeFromVimeo) {
      return !!videoLink;
    }
    return !!(
      videoLink?.FHD ||
      videoLink?.HD ||
      videoLink?.qHD ||
      videoLink?.nHD ||
      videoLink?.ntsc
    );
  };

  const [selectedQuestionOjb, setSelectedQuestionOjb] = useState(
    (item?.question?.questionId && item?.question) ?? null,
  );
  const [contactForm, setContactForm] = useState(
    (item &&
      item.interactionOption &&
      item.interactionOption.type === 'contactForm' && {
        type: item.interactionOption.type || 'contactForm',
        msg: item.interactionOption.msg[lang] || '',
        confirmationMsg: item.interactionOption.confirmationMsg[lang] || '',
        email: item.interactionOption.email || '',
        btnText: item.interactionOption.btnText[lang] || '',
      }) || {
      type: 'contactForm',
      msg: '',
      confirmationMsg: '',
      email: '',
      btnText: '',
    },
  );
  const [callToAction, setCallToAction] = useState(
    (item &&
      item.interactionOption &&
      item.interactionOption.type === 'callToAction' && {
        type: item.interactionOption.type || 'callToAction',
        msg: item.interactionOption.msg[lang] || '',
        url: item.interactionOption.url || '',
        btnText: item.interactionOption.btnText[lang] || '',
      }) || {
      type: 'callToAction',
      msg: '',
      url: '',
      btnText: '',
    },
  );

  const storylineHasChanges = () => {
    return !(
      storylineInclude.length === newStoryLineInclude.length &&
      storylineInclude.every((element, index) => element === newStoryLineInclude[index])
    );
  };

  const hasChanges = () => {
    if (item) {
      if (
        item.id !== videoCardId ||
        item.question[lang] !== question ||
        item?.recipient?.id !== recipient?.id ||
        (!item?.recipient?.id &&
          (item.actorName !== getActorName() ||
            item.actorTitle[lang] !== getActorTitle() ||
            (getActorEmail() && item.actorEmail !== getActorEmail()) ||
            (internalNote && item.internalNote[lang] !== internalNote) ||
            (getActorPhoneNumber() &&
              actorPhoneNumber.length > 4 &&
              item?.actorPhoneNumber !== getActorPhoneNumber()))) ||
        storylineHasChanges() ||
        callToActionHasChanges() ||
        contactFormHasChanges() ||
        !_.isEqual(formatTags(item.tags), itemTags)
      ) {
        return true;
      }
    }
  };

  const createDuplicateVideoCard = (item) => {
    const tagIds = [];

    _.forEach(item?.tags, (t) => {
      tagIds.push(t.id);
    });

    if (item) {
      return actions.createVideoCard(
        orgData.encodedId,
        lang,
        orgData.id,
        statuses.SUGGESTION,
        getQuestionInput(),
        '',
        '',
        '',
        item?.internalNote && item?.internalNote[lang] ? item?.internalNote[lang] : '',
        tagIds ?? [],
        getContactForm(),
        getCallToAction(),
      );
    }
  };

  const createEmptyVideoCard = () =>
    actions.createVideoCard(
      orgData.encodedId,
      lang,
      orgData.id,
      statuses.SUGGESTION,
      getQuestionInput(),
      '',
      '',
      '',
      '',
      [],
      getContactForm(),
      getCallToAction(),
    );

  useEffect(() => {
    if (uploadComplete && directUpload && !hasVideoLink()) {
      requestsApi
        .getVideoCard(orgData.encodedId, videoCardId)
        .then((res) => {
          if (res.data.videoCard.videoLink) {
            setVideoLink(res.data.videoCard.videoLink);
          } else {
            const intervalIdTemp = setInterval(() => {
              requestsApi
                .getVideoCard(orgData.encodedId, videoCardId)
                .then((res) => {
                  if (res.data.videoCard.videoLink) {
                    setVideoLink(res.data.videoCard.videoLink);
                    clearInterval(intervalId);
                  }
                })
                .catch((err) => {
                  setError(err.message); // Ensure the error is a string
                });
            }, 500);
            setIntervalId(intervalIdTemp);
          }
        })
        .catch((err) => {
          setError(err.message); // Ensure the error is a string
        });
    }

    if (uploadComplete && directUpload && hasVideoLink()) {
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  }, [requestsApi, uploadComplete]);

  useEffect(() => {
    // INIT
    if (item && directUpload) {
    } else if (item && duplicateItem) {
      createDuplicateVideoCard(duplicateItem)
        .then((res) => {
          setVideoCardId(res.data.createVideoCard.id);
          setItemUser(res.data.createVideoCard.user);
          handleUpdateBoard();
        })
        .catch((err) => {
          setError(err.message); // Ensure the error is a string
        });
    } else if (!item) {
      // Save as new draft
      createEmptyVideoCard()
        .then((res) => {
          setVideoCardId(res.data.createVideoCard.id);
          setItemUser(res.data.createVideoCard.user);
          handleUpdateBoard();
        })
        .catch((err) => {
          setError(err.message); // Ensure the error is a string
        });
    }
  }, []);

  const beforeClose = () => {
    if (directUpload) {
      setConfirmPopData({
        title: translations.videoCard.deleteItemUpload[lang],
        description: translations.videoCard.areYouSureDeleteUpload[lang],
        onCancel: () => setConfirmPopData(null),
        onConfirm: deleteItem,
      });
    } else if (hasChanges() || (!item && videoCardId)) {
      setConfirmPopData({
        title: translations.videoCard.closeBeforeSave[lang],
        description: translations.videoCard.areYouSureClose[lang],
        onCancel: () => setConfirmPopData(null),
        onConfirm: () => {
          if (!item && videoCardId) {
            deleteItem();
          } else {
            close();
          }
        },
      });
    } else {
      close();
    }
  };

  document.onkeydown = (event) => {
    const evt = event || window.event;
    let isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      beforeClose();
    }
  };

  const validateQuestion = (value) => {
    if (value?.length > 65) {
      setError(translations.errors.TEXT_TOO_LONG[lang]);
    } else {
      setError(null);
      setQuestion(value);
    }
  };

  const getTagIds = () => {
    const tagIds = [];
    _.forEach(itemTags, (t) => {
      if (t.selected) {
        tagIds.push(t.id);
      }
    });
    return tagIds;
  };
  const getItemUploadLink = (orgId, itemId) => {
    if (orgId && itemId) {
      return `${frontendPath}/${orgId}/upload/${itemId}`;
    }
  };

  const errors = {
    NO_QUESTION: 'NO_QUESTION',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_EMAIL_CONTACT_FORM: 'INVALID_EMAIL_CONTACT_FORM',
    INVALID_URL: 'INVALID_URL',
    NO_CTA_MSG: 'NO_CTA_MSG',
    NO_CTA_BTN: 'NO_CTA_BTN',
    NO_INTERNAL_NOTE: 'NO_INTERNAL_NOTE',
    NO_ACTOR_NAME: 'NO_ACTOR_NAME',
    NO_ACTOR_TITLE: 'NO_ACTOR_TITLE',
    NO_VIDEO: 'NO_VIDEO',
    NO_UPLOAD_LINK: 'NO_UPLOAD_LINK',
    NO_PHONE_NUMBER: 'NO_PHONE_NUMBER',
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  };

  const validationError = (isDraft) => {
    const itemStatus = getItemsStatus();
    switch (itemStatus) {
      case statuses.SUGGESTION:
        if (isDraft) {
          return question ? false : errors.NO_QUESTION;
        }
        if (!question) {
          return errors.NO_QUESTION;
        }
        if (actorEmail?.length > 0 && !isValidEmail(actorEmail)) {
          return errors.INVALID_EMAIL;
        }
        if (!internalNote) {
          return errors.NO_INTERNAL_NOTE;
        }
        if (InteractionOptionAllowed) {
          if (selectedInteraction === 'contactForm' && !isValidEmail(contactForm.email)) {
            return errors.INVALID_EMAIL_CONTACT_FORM;
          }
          if (selectedInteraction === 'callToAction') {
            if (!isValidHttpUrl(callToAction.url)) {
              return errors.INVALID_URL;
            }
            if (!callToAction.btnText) {
              return errors.NO_CTA_BTN;
            }
            if (!callToAction.msg) {
              return errors.NO_CTA_MSG;
            }
          }
        }
        return false;

      case statuses.PENDING:
        if (!question) {
          return errors.NO_QUESTION;
        }
        if (actorEmail?.length > 0 && !isValidEmail(actorEmail)) {
          return errors.INVALID_EMAIL;
        }
        if (!internalNote) {
          return errors.NO_INTERNAL_NOTE;
        }
        if (InteractionOptionAllowed) {
          if (selectedInteraction === 'contactForm' && !isValidEmail(contactForm.email)) {
            return errors.INVALID_EMAIL_CONTACT_FORM;
          }
          if (selectedInteraction === 'callToAction') {
            if (!isValidHttpUrl(callToAction.url)) {
              return errors.INVALID_URL;
            }
            if (!callToAction.btnText) {
              return errors.NO_CTA_BTN;
            }
            if (!callToAction.msg) {
              return errors.NO_CTA_MSG;
            }
          }
        }
        return false;
      case statuses.REVIEW:
      case statuses.APPROVED:
      case statuses.LIVE:
        if (!question) {
          return errors.NO_QUESTION;
        }
        if (!actorName) {
          return errors.NO_ACTOR_NAME;
        }
        if (!actorTitle) {
          return errors.NO_ACTOR_TITLE;
        }
        if (actorEmail?.length > 0 && !isValidEmail(actorEmail)) {
          return errors.INVALID_EMAIL;
        }
        if (!directUpload && !uploadComplete && !hasVideoLink()) {
          if (itemStatus !== statuses.REVIEW) {
            return errors.NO_VIDEO;
          }
        }
        if (InteractionOptionAllowed) {
          if (selectedInteraction === 'contactForm' && !isValidEmail(contactForm.email)) {
            return errors.INVALID_EMAIL_CONTACT_FORM;
          }
          if (selectedInteraction === 'callToAction') {
            if (!isValidHttpUrl(callToAction.url)) {
              return errors.INVALID_URL;
            }
            if (!callToAction.btnText) {
              return errors.NO_CTA_BTN;
            }
            if (!callToAction.msg) {
              return errors.NO_CTA_MSG;
            }
          }
        }
        return false;
      case statuses.ARCHIVED:
        break;
    }
  };

  const getActorPhoneNumber = () => {
    if (recipient?.id) {
      return '';
    }
    return actorPhoneNumber;
  };

  const getActorName = () => {
    if (recipient?.id) {
      return '';
    }
    return actorName;
  };

  const getActorEmail = () => {
    if (recipient?.id) {
      return '';
    }
    return actorEmail;
  };

  const getActorTitle = () => {
    if (recipient?.id) {
      return '';
    }
    return actorTitle;
  };

  const getRecipientIds = () => {
    if (recipient?.id) {
      return [recipient.id];
    }
    return recipient?.map((r) => r?.id) || [];
  };

  const handleSaveOrUpdate = (isDraft, newStatus = statuses.SUGGESTION, infoToSet) => {
    const questionInput = getQuestionInput();

    console.log('Question Input:', questionInput); // Log the question input for debugging

    if (validationError(isDraft)) {
      setError(validationError(isDraft));
      return;
    }

    actions
      .updateVideoCard(
        orgData.encodedId,
        lang,
        videoCardId,
        orgData.id,
        newStatus,
        questionInput,
        getActorName(),
        getActorEmail(),
        getActorTitle(),
        internalNote,
        getTagIds(),
        getContactForm(),
        getCallToAction(),
        hideFromLibrary,
        getActorPhoneNumber(),
        newStoryLineInclude,
        getRecipientIds(),
      )
      .then((res) => {
        handleUpdateBoard();
        setAffected(res.data.updateVideoCard.id);
        const result = res.data.updateVideoCard;

        if (result.encodedId && newStatus === statuses.PENDING) {
          const link = getItemUploadLink(orgData.encodedId, result.encodedId);

          actions
            .sendUploadLink(
              orgData.encodedId,
              orgData.id,
              actorEmail,
              actorName,
              link,
              lang,
              question,
              internalNote,
            )
            .then(() => {
              setInfo(translations.successFeedback.PENDING[lang]);
              if (textMessageAllowed && action === ACTIONS.SEND_SMS_EMAIL_REQUEST) {
                if (!actorPhoneNumber) {
                  setError(errors.NO_PHONE_NUMBER);
                } else if (!getActorPhoneNumberIsValid()) {
                  setError(errors.INVALID_PHONE_NUMBER);
                } else {
                  actions
                    .sendTextMsgUploadLink(
                      orgData.encodedId,
                      orgData.id,
                      actorPhoneNumber,
                      videoCardId,
                    )
                    .then(() => {
                      setInfo(translations.successFeedback.PENDING[lang]);
                      close();
                      setVideoCardData(res.data.updateVideoCard);
                    })
                    .catch(() => {
                      setError(errors.NO_UPLOAD_LINK);
                    });
                }
              } else {
                setInfo(translations.savedSuccess[lang]);
                close();
                setVideoCardData(res.data.updateVideoCard);
              }
            })
            .catch(() => {
              setError(errors.NO_UPLOAD_LINK);
            });
        }
        if (infoToSet.length > 0) {
          setInfo(infoToSet);
          close();
          setVideoCardData(res.data.updateVideoCard);
        }
        return res.data.updateVideoCard;
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const saveOrUpdate = (isDraft, newStatus = statuses.SUGGESTION, infoToSet) => {
    if (validationError(isDraft)) {
      setError(validationError(isDraft));
      return;
    }
    if (newStatus !== statuses.LIVE && hasVideoLink() && item.status === statuses.LIVE) {
      return actions
        .getVideoCardVideoFunnelStatus({
          id: videoCardId,
          orgId: orgData.id,
          encodedOrgId: orgData.encodedId,
        })
        .then((res) => {
          const videoFunnelCount = res?.length ?? 0;
          const videoFunnelEdgeCount = res
            ?.map((vf) => vf?.videoFunnelEdges?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          const videoFunnelNodeCount = res
            ?.map((vf) => vf?.videoFunnelNodes?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          if (videoFunnelCount > 0 || videoFunnelEdgeCount > 0 || videoFunnelNodeCount > 0) {
            setDeactivateConfirmDialog({
              text: translations.deactivateInVideoFunnel[lang]({
                videoFunnelCount,
                videoFunnelEdgeCount,
                videoFunnelNodeCount,
                videoFunnelTitles: res?.map((vf) => vf?.title),
              }),
              isDraft,
              newStatus,
              infoToSet,
            });
          } else {
            try {
              handleSaveOrUpdate(isDraft, newStatus, infoToSet);
            } catch (e) {
              setError(e.message);
            }
          }
        })
        .catch((e) => {});
    }
    handleSaveOrUpdate(isDraft, newStatus, infoToSet);
  };

  const save = async () => {
    saveOrUpdate(true, getItemsStatus(), translations.savedSuccess[lang]);
  };

  const deleteItemConfirm = () => {
    if (directUpload) {
      setConfirmPopData({
        title: translations.videoCard.deleteItemUpload[lang],
        description: translations.videoCard.areYouSureDeleteUpload[lang],
        onCancel: () => setConfirmPopData(null),
        onConfirm: deleteItem,
      });
    } else {
      setConfirmPopData({
        title: translations.videoCard.deleteItem[lang],
        description: translations.videoCard.areYouSureDelete[lang],
        onCancel: () => setConfirmPopData(null),
        onConfirm: deleteItem,
      });
    }
  };

  const handleDeleteItem = () => {
    actions
      .deleteVideoCard(orgData.encodedId, videoCardId, orgData.id)
      .then((res) => {
        handleUpdateBoard();
        close();
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const deleteItem = () => {
    if (videoCardId) {
      actions
        .getVideoCardVideoFunnelStatus({
          id: videoCardId,
          orgId: orgData.id,
          encodedOrgId: orgData.encodedId,
        })
        .then((res) => {
          const videoFunnelCount = res?.length ?? 0;
          const videoFunnelEdgeCount = res
            ?.map((vf) => vf?.videoFunnelEdges?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          const videoFunnelNodeCount = res
            ?.map((vf) => vf?.videoFunnelNodes?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          if (videoFunnelCount > 0 || videoFunnelEdgeCount > 0 || videoFunnelNodeCount > 0) {
            setDeleteConfirmDialog(
              translations.deleteInVideoFunnel[lang]({
                videoFunnelCount,
                videoFunnelEdgeCount,
                videoFunnelNodeCount,
                videoFunnelTitles: res?.map((vf) => vf?.title),
              }),
            );
          } else {
            handleDeleteItem();
          }
        })
        .catch((e) => {});
    } else {
      close();
    }
  };

  const handleSubtitleButtonClick = () => {
    if (hasVideoLink()) {
      actions
        .createSubtitleVideoCard(orgData.encodedId, orgData.id, videoCardId)
        .then((res) => {
          handleUpdateBoard();

          setInfo(translations.subtitleSuccess[lang]);
          close();
        })
        .catch((e) => {
          setError(translations.subtitleError[lang]);
        });
    } else {
      close();
      setError('no video Link');
    }
  };

  const requestSubtitleConfirm = () => {
    setConfirmPopData({
      title: 'Request Subtitle',
      description: 'Please Confirm Your Request!',
      onCancel: () => setConfirmPopData(null),
      onConfirm: handleSubtitleButtonClick,
    });
  };

  const sendReminder = () => {
    actions
      .sendEmailReminderLink(orgData.encodedId, item.id)
      .then((a) => {
        setInfo(translations.successFeedback.REMINDER[lang]);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const sendTextMsgReminder = () => {
    if (textMessageAllowed && actorPhoneNumber) {
      if (getActorPhoneNumberIsValid()) {
        actions
          .sendTextMsgReminderLink(orgData.encodedId, item.id, actorPhoneNumber)
          .then((a) => {
            setInfo(translations.successFeedback.REMINDER[lang]);
            return true;
          })
          .catch((e) => {
            setError(e.message);
          });
      } else {
        setError(errors.INVALID_PHONE_NUMBER);
        return false;
      }
    } else {
      setError(errors.NO_PHONE_NUMBER);
      return false;
    }
  };

  const sendTextMsgAndEmailReminder = () => {
    if (sendTextMsgReminder()) {
      sendReminder();
    }
  };

  const getItemsStatus = () => {
    return item ? item.status : statuses.SUGGESTION;
  };
  const saveAndNext = (action) => {
    const vimeoVideoId = item && getVimeoVideoIdByItem(item, lang);

    switch (action) {
      case ACTIONS.SEND_REQUEST:
      case ACTIONS.SEND_SMS_EMAIL_REQUEST:
        if (!question) {
          setError(errors.NO_QUESTION);
        } else if (!actorName) {
          setError(errors.NO_ACTOR_NAME);
        } else if (!actorTitle) {
          setError(errors.NO_ACTOR_TITLE);
        } else if (!isValidEmail(actorEmail)) {
          setError(errors.INVALID_EMAIL);
        } else if (!internalNote) {
          setError(errors.NO_INTERNAL_NOTE);
        } else if (
          textMessageAllowed &&
          action === ACTIONS.SEND_SMS_EMAIL_REQUEST &&
          !actorPhoneNumber
        ) {
          setError(errors.NO_PHONE_NUMBER);
        } else if (
          textMessageAllowed &&
          action === ACTIONS.SEND_SMS_EMAIL_REQUEST &&
          !getActorPhoneNumberIsValid()
        ) {
          setError(errors.INVALID_PHONE_NUMBER);
        } else {
          saveOrUpdate(false, statuses.PENDING);
        }
        break;
      case ACTIONS.APPROVE:
        if (vimeoVideoId) {
          actions.getAnimatedThumbnails(vimeoVideoId).then((res) => {
            if (!res?.sizes || res.sizes.length === 0) {
              // Generate GIFs if none exist
              actions.createAnimatedThumbnails(vimeoVideoId);
            }
            saveOrUpdate(false, statuses.APPROVED, translations.successFeedback.APPROVED[lang]);
          });
        } else {
          saveOrUpdate(false, statuses.APPROVED, translations.successFeedback.APPROVED[lang]);
        }

        break;
      case ACTIONS.PUBLISH:
        saveOrUpdate(false, statuses.LIVE, translations.successFeedback.LIVE[lang]);

        break;
      case ACTIONS.ARCHIVE:
        saveOrUpdate(false, statuses.ARCHIVED);
        break;
      case ACTIONS.UNPUBLISH:
      case ACTIONS.UNARCHIVE:
        saveOrUpdate(false, statuses.APPROVED);
        break;
    }
  };

  const onChangeTags = (newTags) => {
    const newItemTags = newTags.map((tag) => {
      return { id: tag, selected: true };
    });

    setItemTags(newItemTags);
  };

  // Returns question as graphQL QuestionInput input object.
  const getQuestionInput = () => {
    if (selectedQuestionOjb && selectedQuestionOjb.question === question) {
      return {
        sv: selectedQuestionOjb.sv || '',
        en: selectedQuestionOjb.en || '',
        videoRequestId: selectedQuestionOjb.videoRequestId || null,
        questionId: selectedQuestionOjb.questionId || null,
        videoCardId: selectedQuestionOjb.videoCardId || null,
        answeredTimeStampTz: selectedQuestionOjb.answeredTimeStampTz || null,
      };
    }
    return { en: question, sv: question };
  };

  const getContactForm = () => {
    if (InteractionOptionAllowed) {
      switch (selectedInteraction) {
        case 'contactForm': {
          return `{
          type: "${contactForm.type}",
          msg: {${lang}: "${contactForm.msg}"}
          confirmationMsg: {${lang}: "${contactForm.confirmationMsg}"}
          email: "${contactForm.email}",
          btnText: {${lang}: "${contactForm.btnText}"}
        }`;
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  const contactFormHasChanges = () => {
    if (InteractionOptionAllowed) {
      if (
        item?.interactionOption?.type === 'contactForm' &&
        selectedInteraction !== 'contactForm'
      ) {
        return true;
      }
      if (selectedInteraction === 'contactForm') {
        return (
          item?.interactionOption?.msg[lang] !== contactForm?.msg ||
          item?.interactionOption?.confirmationMsg[lang] !== contactForm?.confirmationMsg ||
          item?.interactionOption?.email !== contactForm?.email ||
          item?.interactionOption?.btnText[lang] !== contactForm?.btnText
        );
      }

      return false;
    }
    return false;
  };

  const getCallToAction = () => {
    if (InteractionOptionAllowed) {
      switch (selectedInteraction) {
        case 'callToAction': {
          return `{
          type: "${callToAction.type}",
          msg: {${lang}: "${callToAction.msg}"}
          btnText: {${lang}: "${callToAction.btnText}"}
          url: "${callToAction.url}"
        }`;
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  const callToActionHasChanges = () => {
    if (InteractionOptionAllowed) {
      if (
        item?.interactionOption?.type === 'callToAction' &&
        selectedInteraction !== 'callToAction'
      ) {
        return true;
      }
      if (selectedInteraction === 'callToAction') {
        return (
          item?.interactionOption?.msg[lang] !== callToAction?.msg ||
          item?.interactionOption?.btnText[lang] !== callToAction?.btnText ||
          item?.interactionOption?.url !== callToAction?.url
        );
      }

      return false;
    }
    return false;
  };

  const handleSelectedOption = (e) => {
    setSelectedInteraction(e);
  };

  const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleCallToActionChange = (e) => {
    const { name, value } = e.target;
    setCallToAction((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getActorPhoneNumberIsValid = () => {
    if (recipient) {
      return isValidPhoneNumberInput({ phoneNumber: recipient?.phoneNumber, lang });
    }

    return isValidPhoneNumberInput({ phoneNumber: actorPhoneNumber, lang });
  };

  const uploadingOverlay = (
    <div
      style={{
        position: 'absolute',
        top: '70px',
        left: '0',
        right: '0',
        bottom: '76px',
        zIndex: '99',
        background: 'rgba(0, 0, 0, 0.3)',
      }}
    >
      <div
        className="upload-loading"
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '10px',
          display: 'grid',
        }}
      >
        <Loader />
        <Typography
          variant="headLineSmall"
          sx={{
            color: 'white',
            textAlign: 'center',
          }}
        >
          {translations.videoCard.uploadingVideo[lang]}
        </Typography>
        <br />
      </div>
    </div>
  );

  console.log('Error:', error);
  console.log('Video Error:', videoError);
  return (
    <div className="modal-background" onClick={beforeClose}>
      <div className="modal video-settings" onClick={(e) => e.stopPropagation()}>
        {manageSubtitleOpen && (
          <ManageSubtitleTransitionsModal
            open={manageSubtitleOpen}
            setOpen={setManageSubtitleOpen}
            videoCard={item}
            basicOrgData={orgData}
            subtitles={subtitles}
            setSubtitles={setSubtitles}
          />
        )}
        {directUpload && !uploadComplete && uploadingOverlay}
        <div className="modal-header">
          <Tooltip
            title={
              item
                ? `${translations.videoCard.editVideoInfo[lang]}`
                : `${translations.videoCard.createNewVideoToSendInfo[lang]}`
            }
          >
            <Typography variant="titleLarge">
              {item
                ? `${translations.videoCard.editVideo[lang]}`
                : `${translations.videoCard.createNewVideo[lang]}`}
              <img src="/images/icons/new-video-icon.svg" alt="" style={{ marginLeft: '8px' }} />
            </Typography>
          </Tooltip>

          <IconButton onClick={beforeClose} className="close-btn">
            <i className="icon">close</i>
          </IconButton>
        </div>
        <div className="modal-content scrollable">
          <div className="texts">
            {error ? (
              <div className="validation-error">
                {error in translations.errors ? translations.errors[error][lang] : error}
              </div>
            ) : null}
            <fieldset className="section">
              <label>
                <Tooltip
                  title={`${translations.videoCard.publicVisibilityTooltip[lang]} ${translations.videoCard.videoTitlePublicVisibility[lang]}`}
                >
                  <Typography variant="titleMedium" className="required-field">
                    {translations.videoCard.videoTitle[lang]}
                  </Typography>
                </Tooltip>

                <Autocomplete
                  freeSolo
                  options={suggestedQuestions}
                  getOptionLabel={(option) => option?.question ?? option ?? ''}
                  inputValue={question}
                  onInputChange={(event, newInputValue) => {
                    validateQuestion(newInputValue || '');
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.id ?? _.uniqueId(option.question)}>
                      <Typography variant="titleMedium">
                        {option?.question ?? option ?? ''}
                      </Typography>
                    </li>
                  )}
                  onChange={(event, value) => {
                    setSelectedQuestionOjb(value);
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        className="test"
                        type="text"
                        value={question}
                        name="question"
                        placeholder={translations.videoCard.questionPlaceholder[lang]}
                        required
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </label>
            </fieldset>
            <fieldset className="section">
              <label>
                <Typography variant="titleMedium">
                  {translations.videoCard.whoIsTheMainChar[lang]}
                </Typography>
              </label>
              <div className="cols">
                <label>
                  <Tooltip title={translations.videoCard.publicVisibilityTooltip[lang]}>
                    <Typography variant="titleMedium" className="required-field">
                      {translations.videoCard.name[lang]}
                    </Typography>
                  </Tooltip>

                  <input
                    type="text"
                    value={actorName}
                    onChange={(e) => setActorName(e.target.value)}
                    required
                    disabled={recipient}
                    readOnly={recipient}
                  />
                </label>
                <label>
                  <Tooltip title={translations.videoCard.publicVisibilityTooltip[lang]}>
                    <Typography variant="titleMedium" className="required-field">
                      {translations.videoCard.jobTitle[lang]}
                    </Typography>
                  </Tooltip>
                  <input
                    type="text"
                    value={actorTitle}
                    onChange={(e) => setActorTitle(e.target.value)}
                    required
                    disabled={recipient}
                    readOnly={recipient}
                  />
                </label>
              </div>
            </fieldset>
            <VideoRequestSection
              videoCard={item}
              recipient={recipient}
              setRecipient={(e) => {
                setRecipient(e);
                setActorName(e?.name ?? '');
                setActorEmail(e?.email ?? '');
                setActorTitle(e?.title ?? '');
                setActorPhoneNumber(e?.phoneNumber);
              }}
              orgRecipients={orgRecipients}
              actorTitle={actorTitle}
              actorName={actorName}
              actorEmail={actorEmail}
              actorPhoneNumber={actorPhoneNumber}
              textMessageAllowed={textMessageAllowed ?? false}
              internalNote={internalNote}
              setActorEmail={setActorEmail}
              setInternalNote={setInternalNote}
              setActorPhoneNumber={(e) => setActorPhoneNumber(e)}
              basicOrgData={orgData}
            />
            <VideoStorylineSection
              encodedOrgId={orgData?.encodedId}
              itemId={Number(videoCardId)}
              storylineInclude={storylineInclude}
              setStorylineInclude={setStorylineInclude}
              newStoryLineInclude={newStoryLineInclude}
              setNewStoryLineInclude={setNewStoryLineInclude}
            />

            <fieldset className="section">
              <label>
                <Typography variant="titleMedium">{translations.videoLibrary[lang]}</Typography>
              </label>
              <label>
                <Tooltip title={translations.videoCard.hideFromLibraryInfo[lang]}>
                  <input
                    type="checkbox"
                    checked={hideFromLibrary}
                    onChange={() => setHideFromLibrary(!hideFromLibrary)}
                  />
                </Tooltip>
                <Tooltip title={translations.videoCard.hideFromLibraryInfo[lang]}>
                  <Typography variant="titleMedium">
                    {translations.videoCard.hideFromLibrary[lang]}
                  </Typography>
                </Tooltip>
              </label>
            </fieldset>

            <div className="tags">
              <label id="tags-selector">
                <Tooltip title={translations.videoCard.selectTagsInfo[lang]}>
                  <Typography variant="titleMedium">
                    {translations.videoCard.selectTags[lang]}
                  </Typography>
                </Tooltip>
                <div>
                  <Tags
                    previouslySelected={itemTags}
                    categories={orgData.tagCategories}
                    onChangeChecked={onChangeTags}
                    orgId={orgData.id}
                    encodedOrgId={orgData.encodedId}
                  />
                </div>
              </label>
            </div>
            <fieldset className="section" />

            {((!orgHasWidgetThree && !orgHasVideoFunnel) || !!item?.interactionOption?.type) && (
              <VideoActionOption
                selectedInteraction={selectedInteraction}
                setSelectedInteraction={handleSelectedOption}
                contactForm={contactForm}
                setContactForm={setContactForm}
                handleContactFormChange={handleContactFormChange}
                callToAction={callToAction}
                setCallToAction={setCallToAction}
                handleCallToActionChange={handleCallToActionChange}
                InteractionOptionAllowed={InteractionOptionAllowed}
              />
            )}
            <ConsentSection item={item} />
          </div>
          <VideoSection
            videoCardId={videoCardId}
            item={item}
            videoLink={videoLink}
            videoError={videoError}
            setVideoError={setVideoError}
            uploadLink={getItemUploadLink(orgData?.encodedId, item?.encodedId)}
            doNotTrack={orgData.doNotTrack}
            interactionOption={selectedInteraction}
            textColor="#ffffff"
            contactForm={contactForm}
            callToAction={callToAction}
            question={question}
            orgId={orgData.id}
            encodedOrgId={orgData.encodedId}
            InteractionOptionAllowed={InteractionOptionAllowed}
            handleSubtitleButtonClick={requestSubtitleConfirm}
            subtitleOption={subtitleOption || includeSubtitle}
            itemUser={itemUser}
            directUpload={directUpload}
            uploadComplete={uploadComplete}
            hasVideoLink={hasVideoLink()}
            excludeFromVimeo={orgData?.premiumFeatures?.excludeFromVimeo}
            basicOrgData={orgData}
            manageSubtitleOpen={manageSubtitleOpen}
            setManageSubtitleOpen={setManageSubtitleOpen}
            subtitles={subtitles}
            setSubtitles={setSubtitles}
          />
        </div>
        <SettingsFooter
          itemStatus={getItemsStatus()}
          save={save}
          saveAndNext={saveAndNext}
          deleteItem={deleteItemConfirm}
          hasError={videoError || !question || !actorName || !actorTitle || !hasVideoLink()}
          sendReminder={sendReminder}
          sendTextMsgReminder={sendTextMsgReminder}
          textMessageAllowed={textMessageAllowed}
          sendTextMsgAndEmailReminder={sendTextMsgAndEmailReminder}
          actorPhoneNumberIsValid={getActorPhoneNumberIsValid()}
          handleDuplicateItemClick={async () => {
            if (hasChanges() || (!item && videoCardId)) {
              const updatedItem = await saveOrUpdate(false, getItemsStatus());
              if (updatedItem != null) {
                handleDuplicateItemClick(updatedItem);
                close();
              }
            } else {
              handleDuplicateItemClick(item);
              close();
            }
          }}
          disabled={directUpload && !uploadComplete}
          disabledLive={
            videoCards?.filter((vc) => vc.status === statuses.LIVE).length >=
            freemiumVideoCardsLimit.live
          }
        />
        {confirmPopData ? (
          <ConfirmPop
            title={confirmPopData.title}
            description={confirmPopData.description}
            onCancel={confirmPopData.onCancel}
            onConfirm={confirmPopData.onConfirm}
          />
        ) : null}
        {deleteConfirmDialog && (
          <AlertDialog
            open={!!deleteConfirmDialog}
            title={translations.confirmDeleteTitle[lang]}
            description={deleteConfirmDialog}
            confirmBtnText={translations.deleteConfirmBtnText[lang]}
            cancelBtnText={translations.deleteCancelBtnText[lang]}
            onConfirm={() => {
              handleDeleteItem();
              setDeleteConfirmDialog(false);
            }}
            onCancel={(e) => {
              setDeleteConfirmDialog(false);
            }}
          />
        )}
        {deactivateConfirmDialog && (
          <AlertDialog
            open={!!deactivateConfirmDialog}
            title={translations.confirmDeactivateTitle[lang]}
            description={deactivateConfirmDialog?.text}
            confirmBtnText={translations.deactivateConfirmBtnText[lang]}
            cancelBtnText={translations.deleteCancelBtnText[lang]}
            onConfirm={() => {
              handleSaveOrUpdate(
                deactivateConfirmDialog?.isDraft,
                deactivateConfirmDialog?.newStatus,
              );
              setDeactivateConfirmDialog(false);
            }}
            onCancel={(e) => {
              setDeactivateConfirmDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default VideoCardSettings;

function ConsentSection({ item, lang }) {
  if (!item || item.status === statuses.SUGGESTION || item.status === statuses.PENDING) return null;

  const soMeConsent =
    item.consentTexts?.soMeConsent?.length > 0 ? item.consentTexts?.soMeConsent : null;

  const decodedSoMeConsent = soMeConsent
    ? decodeURIComponent(soMeConsent) ?? soMeConsent ?? ''
    : '';

  const uploadConsent = item.consentTexts?.uploadConsent || translations.defaultUploadConsent[lang];
  const decodedUploadConsent = decodeURIComponent(uploadConsent) ?? uploadConsent ?? '';

  return (
    <div className="consent-section">
      <Typography variant="titleMedium">{translations.UserConsents[lang]}</Typography>
      <ul>
        <li>
          <Typography variant="bodySmall">
            {parse(decodedUploadConsent?.trim()?.replace(/\n/g, '<br />'))}
          </Typography>
        </li>
        {decodedSoMeConsent && (
          <li style={{ color: !decodedSoMeConsent ? 'red' : 'black' }}>
            <Typography variant="bodySmall">
              {decodedSoMeConsent && decodedSoMeConsent?.length > 0
                ? parse(decodedSoMeConsent?.trim()?.replace(/\n/g, '<br />'))
                : translations.noSoMeConsent[lang]}
            </Typography>
          </li>
        )}
      </ul>
    </div>
  );
}
