import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import { useLanguage } from 'src/context/LanguageContext';
import ViewsDataCard, { iconTypes } from './ViewsDataCard';
import { translationsDashboard } from '../../../utils/translationsDashboard';
import { statuses, dateDiffInDays } from '../../../utils/utils';

const defaultBasicOrgData = {
  premiumFeatures: {
    videoFunnel: false,
  },
};

const defaultDashboardStatsState = {
  dataCardsData: {
    storylineLive: 0,
    videoFunnelLive: 0,
    videosPlayed: 0,
    videosLive: 0,
    startDate: new Date(),
    endDate: new Date(),
  },
};

function DataCard({
  basicOrgData = defaultBasicOrgData,
  dashboardStatsState = defaultDashboardStatsState,
  storylineObjects = { current: [] },
  videoCards = { current: [] },
  videoFunnelObjects = { current: [] },
}) {
  const lang = useLanguage();
  const orgHasVideoFunnel = basicOrgData?.premiumFeatures?.videoFunnel;

  const videoCardsCount = videoCards?.filter((s) => s.status === statuses.LIVE)?.length;
  const storylineCount = storylineObjects?.filter((s) => s.status === statuses.LIVE)?.length;
  const videoFunnelCount = videoFunnelObjects?.filter((s) => s.status === statuses.LIVE)?.length;

  const storylineLiveCount =
    storylineCount > 0 ? storylineCount : dashboardStatsState?.dataCardsData?.storylineLive ?? 0;

  const videoFunnelLiveCount =
    videoFunnelCount > 0
      ? videoFunnelCount
      : dashboardStatsState?.dataCardsData?.videoFunnelLive ?? 0;

  const count = orgHasVideoFunnel ? storylineLiveCount + videoFunnelLiveCount : storylineLiveCount;

  const cardData = [
    {
      value: count,
      text: orgHasVideoFunnel
        ? translationsDashboard.dataCards.storiesLiveRightNow[lang]({ count })
        : translationsDashboard.dataCards.storylineLiveRightNow[lang]({ count }),
      icon: iconTypes.stories,
    },
    {
      value: dashboardStatsState?.dataCardsData?.videosPlayed ?? 0,
      text: translationsDashboard.dataCards.playedVideosLastDays[lang]({
        count: dashboardStatsState?.dataCardsData?.videosPlayed ?? 0,
        nrDays:
          dateDiffInDays(
            new Date(dashboardStatsState?.dataCardsData?.startDate),
            new Date(dashboardStatsState?.dataCardsData?.endDate),
          ) ?? 60,
      }),
      icon: iconTypes.videoLibrary,
    },
    {
      value:
        videoCardsCount > 0 ? videoCardsCount : dashboardStatsState?.dataCardsData?.videosLive ?? 0,
      text: translationsDashboard.dataCards.videosLiveRightNow[lang]({
        count:
          videoCardsCount > 0
            ? videoCardsCount
            : dashboardStatsState?.dataCardsData?.videosLive ?? 0,
      }),
      icon: iconTypes.liveVideo,
    },
  ];

  // Filter out cards with a value of 0
  const filteredCardData = cardData.filter((card) => card.value !== 0);

  // Calculate grid size based on the number of cards to display
  const gridSize = filteredCardData.length > 0 ? 12 / filteredCardData.length : 4;

  return (
    <>
      {filteredCardData.map((card) => (
        <Grid
          item
          xl={gridSize}
          lg={gridSize}
          md={gridSize}
          sm={12}
          xs={12}
          key={card.text + card.value}
        >
          <ViewsDataCard value={card.value} text={card.text} icon={card.icon} />
        </Grid>
      ))}
    </>
  );
}

DataCard.propTypes = {
  basicOrgData: PropTypes.shape({
    premiumFeatures: PropTypes.shape({
      videoFunnel: PropTypes.bool,
    }),
  }),
  dashboardStatsState: PropTypes.shape({
    dataCardsData: PropTypes.shape({
      storylineLive: PropTypes.number,
      videoFunnelLive: PropTypes.number,
      videosPlayed: PropTypes.number,
      videosLive: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
  storylineObjects: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
    }),
  ),
  videoCards: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
    }),
  ),
  videoFunnelObjects: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
    }),
  ),
};

export default DataCard;
