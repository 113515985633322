const reviewTranslations = {
  successfullyUpdated: {
    en: 'Successfully updated',
    sv: 'Uppdaterades',
  },
  errorUpdating: {
    en: 'Error updating',
    sv: 'Fel vid uppdatering',
  },
};

export default reviewTranslations;
