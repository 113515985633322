import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import { gql } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { translations } from '../../utils/translations';

const getUserData = (backOfficeClient, encodedOrgId) => {
  return backOfficeClient.query({
    query: gql`
      query Me($encodedOrgId: String) {
        me(encodedOrgId: $encodedOrgId) {
          id
          encodedId
          firstName
          lastName
          superAdmin
          email
          events {
            id
            eventType
            eventTime
          }
        }
      }
    `,
    variables: { encodedOrgId },
  });
};

export default function MyPage() {
  const backOfficeClient = useBackOfficeApolloClient();
  const lang = useLanguage();

  const { basicOrgData, signedInUser } = useOutletContext();
  const encodedOrgId = basicOrgData.encodedId;

  const [groupedEvents, setGroupedEvents] = useState(null);
  const [me, setMe] = useState(signedInUser);

  useEffect(() => {
    getUserData(backOfficeClient, encodedOrgId).then((res) => {
      const data = res.data.me;
      const newGroupedEvents = _.groupBy(data.events, (i) => i.eventType);
      setGroupedEvents(newGroupedEvents);
      setMe(data);
      signedInUser.current = data;
    });
  }, [backOfficeClient, encodedOrgId, signedInUser]);

  if (!me) return null;

  const stats =
    groupedEvents &&
    _.map(groupedEvents, (group, eventType) => {
      if (eventType in translations.eventTypesDesciptions) {
        return (
          <div key={eventType}>
            <Typography variant="titleMedium">
              {translations.eventTypesDesciptions[eventType][lang]}:
            </Typography>
            <Typography variant="labelLarge"> {group.length} </Typography>
            {/* Ej klar med hur jag ska implementera videos med översättning 
            <Typography variant="bodyMedium"> Videos </Typography> */}
          </div>
        );
      }
      return null;
    });

  const superAdmin = me.superAdmin ? (
    <Typography variant="bodySmall"> (SUPERADMIN)</Typography>
  ) : (
    ''
  );

  return (
    // <div className="my-page">
    <div>
      <Typography variant="headlineMedium">{translations.myPages[lang]}</Typography>
      <br />
      <br />
      <Typography variant="titleLarge">{translations.myPage.aboutMe[lang]}</Typography>
      <div>
        <div>
          <Typography variant="titleMedium">{translations.myPage.userId[lang]}:</Typography>{' '}
          <Typography variant="labelLarge">{me.id}</Typography>
          {superAdmin}
        </div>
        <div>
          <Typography variant="titleMedium">{translations.myPage.name[lang]}:</Typography>{' '}
          <Typography variant="bodyMedium">
            {me.firstName} {me.lastName}
          </Typography>
        </div>
        <div>
          <Typography variant="titleMedium">{translations.myPage.email[lang]}:</Typography>{' '}
          <Typography variant="bodyMedium">{me.email}</Typography>
        </div>
        <br />
      </div>
      <Typography variant="titleLarge">{translations.myPage.stats[lang]}</Typography>
      <div className="stats-box-wrapper">{stats}</div>
    </div>
  );
}
