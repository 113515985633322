import React, { forwardRef, useContext, useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import Button from '@mui/material/Button';

import { useLanguage } from 'src/context/LanguageContext';
import ThemeContext from '../../../../utils/ThemeContext';
import videoCollectorTranslations from '../VideoCollectorTranslations';
import DeleteOutlinedIcon from '../../../common/icons/delete/DeleteOutlinedIcon';
import PreviewOutlinedIcon from '../../../common/icons/preview/PreviewOutlinedIcon';
import ActivateOutlined from '../../../common/icons/activate/ActivateOutlined';

interface VideoCollectorSettingsFooterProps {
  disabled: boolean;
  isActive: boolean;
  onDeleteClicked: () => void;
  onPreviewClicked: () => void;
  onActivateClicked: () => void;
  onSaveClicked: () => void;
}

const VideoCollectorSettingsFooter = forwardRef(
  (
    {
      disabled,
      isActive,
      onDeleteClicked,
      onPreviewClicked,
      onActivateClicked,
      onSaveClicked,
    }: VideoCollectorSettingsFooterProps,
    ref,
  ) => {
    const lang = useLanguage();
    const theme = useContext(ThemeContext);

    const [navBarWidth, setNavBarWidth] = useState(120);

    useEffect(() => {
      const updateWidth = () => {
        const navBar = document.getElementById('navigation-header-bar-left-side');
        const currentOffsetWidth = navBar?.offsetWidth ?? 0;

        if (currentOffsetWidth > 0) {
          setNavBarWidth(currentOffsetWidth);
        }
      };

      updateWidth();
      window.addEventListener('resize', updateWidth);

      return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return (
      <Box
        ref={ref}
        role="contentinfo"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: `${navBarWidth}px`,
          right: 0,
          zIndex: 1,
          height: 'auto',
          backgroundColor: 'background.paper',
          py: 0.5,
          px: 3,
        }}
      >
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <Stack direction="row" spacing={0}>
            <Button disabled={disabled} onClick={onDeleteClicked} data-testid="delete-button">
              <DeleteOutlinedIcon fill={theme.palette.text.primary} />
              {videoCollectorTranslations.delete[lang]}
            </Button>
          </Stack>
          <Stack direction="row" spacing={0}>
            <Button disabled={disabled} onClick={onPreviewClicked} data-testid="preview-button">
              <PreviewOutlinedIcon fill={theme.palette.text.primary} />
              {videoCollectorTranslations.preview[lang]}
            </Button>
            <Button
              disabled={disabled}
              variant="contained"
              onClick={onActivateClicked}
              data-testid="activate-button"
            >
              <ActivateOutlined />
              {isActive
                ? videoCollectorTranslations.deactivate[lang]
                : videoCollectorTranslations.activate[lang]}
            </Button>
            <Button
              disabled={disabled}
              onClick={onSaveClicked}
              variant="contained"
              data-testid="save-button"
            >
              {videoCollectorTranslations.save[lang]}
            </Button>
          </Stack>
        </Stack>
      </Box>
    );
  },
);
export default VideoCollectorSettingsFooter;
