import PropTypes from 'prop-types';

function SettingsFilledIcon({ fill = '#000000' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1401 12.94C19.1801 12.64 19.2001 12.33 19.2001 12C19.2001 11.68 19.1801 11.36 19.1301 11.06L21.1601 9.48002C21.3401 9.34002 21.3901 9.07002 21.2801 8.87002L19.3601 5.55002C19.2401 5.33002 18.9901 5.26002 18.7701 5.33002L16.3801 6.29002C15.8801 5.91002 15.3501 5.59002 14.7601 5.35002L14.4001 2.81002C14.3601 2.57002 14.1601 2.40002 13.9201 2.40002H10.0801C9.84011 2.40002 9.65011 2.57002 9.61011 2.81002L9.25011 5.35002C8.66011 5.59002 8.12011 5.92002 7.63011 6.29002L5.24011 5.33002C5.02011 5.25002 4.77011 5.33002 4.65011 5.55002L2.74011 8.87002C2.62011 9.08002 2.66011 9.34002 2.86011 9.48002L4.89011 11.06C4.84011 11.36 4.80011 11.69 4.80011 12C4.80011 12.31 4.82011 12.64 4.87011 12.94L2.84011 14.52C2.66011 14.66 2.61011 14.93 2.72011 15.13L4.64011 18.45C4.76011 18.67 5.01011 18.74 5.23011 18.67L7.62011 17.71C8.12011 18.09 8.65011 18.41 9.24011 18.65L9.60011 21.19C9.65011 21.43 9.84011 21.6 10.0801 21.6H13.9201C14.1601 21.6 14.3601 21.43 14.3901 21.19L14.7501 18.65C15.3401 18.41 15.8801 18.09 16.3701 17.71L18.7601 18.67C18.9801 18.75 19.2301 18.67 19.3501 18.45L21.2701 15.13C21.3901 14.91 21.3401 14.66 21.1501 14.52L19.1401 12.94ZM12.0001 15.6C10.0201 15.6 8.40011 13.98 8.40011 12C8.40011 10.02 10.0201 8.40002 12.0001 8.40002C13.9801 8.40002 15.6001 10.02 15.6001 12C15.6001 13.98 13.9801 15.6 12.0001 15.6Z"
        fill={fill}
      />
    </svg>
  );
}

export default SettingsFilledIcon;

SettingsFilledIcon.propTypes = {
  fill: PropTypes.string,
};
