import { gql } from '@apollo/client';

/* eslint-disable import/prefer-default-export */
export const USER_QUERY = gql`
  query Me($encodedOrgId: String!) {
    me(encodedOrgId: $encodedOrgId) {
      id
      encodedId
      superAdmin
      email
      firstName
      lastName
      userPermissions {
        id
        name
        description
        orgId
      }
    }
  }
`;
