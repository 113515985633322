import React from 'react';
import { Box, Button, Collapse, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useLanguage } from 'src/context/LanguageContext';
import StatusMessages from './StatusMessages';

interface SettingsPanelProps {
  showSettings: boolean;
  setShowSettings: (show: boolean) => void;
  avatarName: string;
  setAvatarName: (name: string) => void;
  voiceId: string;
  setVoiceID: (id: string) => void;
  assistantId: string;
  setAssistantID: (id: string) => void;
  status: string[];
  loading: boolean;
  allowSettings: boolean;
  avatarPosterUrl: string;
  setAvatarPosterUrl: (url: string) => void;
  saveSettings: () => void;
}

const translations = {
  avatarID: {
    en: 'Avatar ID',
    sv: 'Avatar ID',
  },
  voiceID: {
    en: 'Voice ID',
    sv: 'Röst ID',
  },
  assistantID: {
    en: 'Assistant ID',
    sv: 'Assistent ID',
  },
  avatarPosterUrl: {
    en: 'Avatar Poster URL',
    sv: 'Avatar Poster URL',
  },
  saveButton: {
    en: 'Save',
    sv: 'Spara',
  },
  ariaLabel: {
    settingsButton: {
      en: 'Settings',
      sv: 'Inställningar',
    },
    saveButton: {
      en: 'Save settings',
      sv: 'Spara inställningar',
    },
  },
};

function SettingsPanel({
  showSettings,
  setShowSettings,
  avatarName,
  setAvatarName,
  voiceId,
  setVoiceID,
  assistantId,
  setAssistantID,
  status,
  loading,
  allowSettings,
  avatarPosterUrl,
  setAvatarPosterUrl,
  saveSettings,
}: SettingsPanelProps) {
  const lang = useLanguage();

  if (!allowSettings) {
    return null;
  }

  const canSave = avatarName && voiceId && assistantId && avatarPosterUrl;

  return (
    <>
      <Collapse in={showSettings} collapsedSize={0} unmountOnExit data-testid="settings-collapse">
        <Stack direction="row" spacing={2} sx={{ pt: 3 }}>
          <Stack direction="column" spacing={2} sx={{ minWidth: '20vw' }}>
            <Box>
              <label htmlFor="avatar-id">
                <Typography className="required-field">{translations.avatarID[lang]}</Typography>
              </label>
              <TextField
                id="avatar-id"
                variant="outlined"
                value={avatarName}
                onChange={(e) => setAvatarName(e.target.value)}
                disabled={loading}
                fullWidth
                inputProps={{ 'aria-labelledby': 'avatar-id' }}
                data-testid="avatar-id-input"
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: '12px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
              />
            </Box>
            <Box>
              <label htmlFor="voice-id">
                <Typography className="required-field">{translations.voiceID[lang]}</Typography>
              </label>
              <TextField
                id="voice-id"
                variant="outlined"
                value={voiceId}
                onChange={(e) => setVoiceID(e.target.value)}
                disabled={loading}
                fullWidth
                inputProps={{ 'aria-labelledby': 'voice-id' }}
                data-testid="voice-id-input"
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: '12px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
              />
            </Box>
            <Box>
              <label htmlFor="assistant-id">
                <Typography className="required-field">{translations.assistantID[lang]}</Typography>
              </label>
              <TextField
                id="assistant-id"
                variant="outlined"
                value={assistantId}
                onChange={(e) => setAssistantID(e.target.value)}
                disabled={loading}
                fullWidth
                inputProps={{ 'aria-labelledby': 'assistant-id' }}
                data-testid="assistant-id-input"
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: '12px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
              />
            </Box>
            <Box>
              <label htmlFor="avatar-poster-url">
                <Typography className="required-field">
                  {translations.avatarPosterUrl[lang]}
                </Typography>
              </label>
              <TextField
                id="avatar-poster-url"
                variant="outlined"
                value={avatarPosterUrl}
                onChange={(e) => setAvatarPosterUrl(e.target.value)}
                disabled={loading}
                fullWidth
                inputProps={{ 'aria-labelledby': 'avatar-poster-url' }}
                data-testid="avatar-poster-url-input"
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: '12px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || !canSave || !showSettings || !allowSettings}
              data-testid="save-button"
              aria-label={translations.ariaLabel.saveButton[lang]}
              onClick={saveSettings}
            >
              {translations.saveButton[lang]}
            </Button>
          </Stack>
          <StatusMessages status={status} />
        </Stack>
      </Collapse>
      <IconButton
        onClick={() => setShowSettings(!showSettings)}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
        aria-label={translations.ariaLabel.settingsButton[lang]}
        data-testid="settings-button"
      >
        <Settings fontSize="small" />
      </IconButton>
    </>
  );
}

export default SettingsPanel;
