import { useMemo } from 'react';
import _ from 'lodash';
import getTheme from '../../common/SplitButton/SplitButtonThemes';
import LazyLoad from 'react-lazy-load';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const cardHeight = 540;
const cardWidth = cardHeight * 0.5;
const cardOffset = 300;
import VideoCard from './VideoCard';

export default function LibraryGrid({
  videoCards,
  handleSelected,
  encodedOrgId,
  updateVideoCard,
  orgData,
  setAffectedItem,
  setInfo,
  setError,
  suggestedQuestions,
  orgRecipients,
  handleUpdateBoard,
  handleDuplicateItemClick,
}) {
  const theme = getTheme('passive');

  if (videoCards?.length === 0 || !videoCards) {
    return null;
  }

  const filteredVideos = useMemo(
    () =>
      videoCards?.filter((vc) => {
        return true;
      }),
    [videoCards],
  );

  return (
    <div style={{ marginTop: '20px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} direction="row">
          {filteredVideos?.map((videoCard) => {
            return (
              <Grid item key={_.uniqueId(`${videoCard.id}-grid-item`)}>
                <LazyLoad
                  key={_.uniqueId(videoCard.id)}
                  offset={cardOffset}
                  height={cardHeight}
                  width={cardWidth}
                >
                  <VideoCard
                    reviewMode={false}
                    key={videoCard.id}
                    theme={theme}
                    videoCard={videoCard}
                    orgData={orgData}
                    updateVideoCard={updateVideoCard}
                    height={cardHeight}
                    width={cardWidth}
                    padding={cardHeight * 0.025}
                    setAffectedItem={(e) => setAffectedItem(e)}
                    setInfo={setInfo}
                    handleUpdateBoard={handleUpdateBoard}
                    suggestedQuestions={suggestedQuestions}
                    orgRecipients={orgRecipients}
                    setError={(error) => {
                      setError(error);
                    }}
                    handleDuplicateItemClick={(itemToDuplicate) => {
                      handleDuplicateItemClick(itemToDuplicate);
                    }}
                  />
                </LazyLoad>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}
