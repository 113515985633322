import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { useLanguage } from 'src/context/LanguageContext';
import { formatTime } from '../../../../utils/utils';
import translationsAnalytics from '../../../../utils/translationsAnalytics';

import { generateRandomData } from './generateRandomData';

function TimeCard({ titleType, data = [], selectedDuration, type }) {
  const lang = useLanguage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const displayData = useMemo(() => {
    return data && data.length > 0 ? data : generateRandomData(selectedDuration);
  }, [data, selectedDuration]);

  const totalValueFormat = useMemo(() => {
    if (type === 'interactions') {
      return displayData.reduce((total, currentValue) => total + currentValue.count, 0);
    }
    return formatTime(
      displayData.reduce((total, currentValue) => total + currentValue.count, 0),
      lang,
    );
  }, [displayData, lang, type]);

  useEffect(() => {
    setLoading(true);
    setError('');

    const timer = setTimeout(() => {
      if (!data || data.length === 0) {
        setError(translationsAnalytics.totalImpressionsCard.error[lang]);
      }
      setLoading(false);
    }, 10000);

    if (data && data.length > 0) {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [data, selectedDuration, lang]);

  return (
    <Card
      sx={{
        width: '100%',
        maxHeight: '114px',
        padding: '24px 22px', // paddingTop, paddingBottom, paddingLeft, paddingRight
        borderRadius: '8px',
        background: 'white',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2, // 10px gap
        display: 'flex', // changed from inline-flex to flex for better layout control
        position: 'relative',
      }}
      elevation={0}
    >
      {(loading || error) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            flexDirection: 'column',
          }}
        >
          {loading && <CircularProgress size={50} data-testid="loading-spinner" />}
          <Typography variant="titleLarge" gutterBottom sx={{ mt: 2 }}>
            {error}
          </Typography>
        </Box>
      )}
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 0,
          m: 0,
        }}
      >
        <Typography noWrap variant="titleLarge" align="center" sx={{ mb: 1 }}>
          {translationsAnalytics.timeCard?.[titleType]?.[lang]}
        </Typography>
        <Typography variant="titleLargeBold">{totalValueFormat}</Typography>
      </CardContent>
    </Card>
  );
}

TimeCard.propTypes = {
  titleType: PropTypes.oneOf(['average', 'interactions', 'total']).isRequired,
  type: PropTypes.oneOf(['time', 'interactions']).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ),
  selectedDuration: PropTypes.string.isRequired,
};

export default TimeCard;
