import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { gql } from '@apollo/client/index';
import _ from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import { SubtitleTranslation } from '../../utils/subtitleTranslation';
import AlertDialog from '../common/AlertDialog';
import DataGridTable from '../common/DataGridTable';
import UpdateDialog from './UpdateDialog';

const getAllUpdates = (adminClient) => {
  return adminClient.query({
    query: gql`
      query {
        allDashboardUpdates {
          id
          startDate
          endDate
          title
          description
          language
        }
      }
    `,
  });
};

export const createDashboardUpdate = ({
  adminClient,
  title,
  startDate,
  endDate,
  description,
  language,
}) => {
  return adminClient.mutate({
    mutation: gql`
          mutation {
            createDashboardUpdate(input: {
              title: "${title}",
              endDate: "${endDate}",
              startDate: "${startDate}",
              ${description ? `description: "${description}",` : ''}
              language: "${language}",
            }) {
              id
              startDate
              endDate
              title
              description
              language
            }
          }
        `,
  });
};

export const updateDashboardUpdate = ({
  adminClient,
  id,
  title,
  startDate,
  endDate,
  description,
  language,
}) => {
  return adminClient.mutate({
    mutation: gql`
          mutation {
            updateDashboardUpdate(input: {
              id: ${id},
              title: "${title}",
              endDate: "${endDate}",
              startDate: "${startDate}",
              ${description ? `description: "${description}",` : ''}
              language: "${language}",
            }) {
              id
              startDate
              endDate
              title
              description
              language
            }
          }
        `,
  });
};

export const deleteDashboardUpdate = ({ adminClient, id }) => {
  return adminClient.mutate({
    mutation: gql`
          mutation {
            deleteDashboardUpdate(
              id: ${id},
            ) {
              statusMessage
            }
          }
        `,
  });
};

export default function Updates() {
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const [rows, setRows] = useState([]);

  const [hoveredRow, setHoveredRow] = useState(null);
  const [createNew, setCreateNew] = useState(false);
  const [edit, setEdit] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [simpleAlertDialogOpenDelete, setSimpleAlertDialogOpenDelete] = useState(false);

  useEffect(() => {
    getAllUpdates(adminClient)
      .then((response) => {
        setRows(response?.data?.allDashboardUpdates || []);
      })
      .catch(() => {});
  }, [adminClient]);

  const handleClose = () => {
    setCreateNew(false);
    setEdit(null);
  };

  const handleCreate = (object) => {
    createDashboardUpdate({ adminClient, ...object })
      .then((response) => {
        handleClose();
        setRows((prevState) => [response.data.createDashboardUpdate, ...prevState]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert(error);
        handleClose();
      });
  };

  const handleDelete = (object) => {
    deleteDashboardUpdate({ adminClient, id: object.id })
      .then((response) => {
        if (response.data.deleteDashboardUpdate) {
          handleClose();
          setSimpleAlertDialogOpenDelete(false);
          setRows((prevState) => [...prevState.filter((row) => row.id !== object.id)]);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert(error);
      });
  };

  const handleUpdate = (object) => {
    updateDashboardUpdate({ adminClient, ...object })
      .then((response) => {
        if (response.data.updateDashboardUpdate) {
          handleClose();
          setSimpleAlertDialogOpenDelete(false);
          setRows((prevState) => [
            response.data.updateDashboardUpdate,
            ...prevState.filter((row) => row.id !== object.id),
          ]);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-alert
        alert(error);
      });
  };

  const onMouseEnterRow = (event) => {
    const rowId = Number(event.currentTarget.dataset.id);
    const row = rows?.find((el) => el.id === rowId);
    setHoveredRow(row.id);
  };

  const onMouseLeaveRow = () => {
    setHoveredRow(null);
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 130,
      //   disableColumnMenu: true,
      flex: 1,
      sortable: true,
      //   editable: true,
      type: 'string',
      renderCell: (params) => {
        return parse(
          ((decodeURIComponent(params.row.title) ?? params.row.title ?? '') || '')
            ?.trim()
            ?.replace(/\n/g, '<br />'),
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 130,
      //   disableColumnMenu: true,
      flex: 1,
      sortable: true,
      //   editable: true,
      type: 'string',
      renderCell: (params) => {
        return parse(
          ((decodeURIComponent(params.row.description) ?? params.row.description ?? '') || '')
            ?.trim()
            ?.replace(/\n/g, '<br />'),
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      minWidth: 30,
      flex: 0,
      sortable: true,
      type: 'date',
      valueGetter: (value) => new Date(value),
      renderCell: (params) => {
        const startDate = params.value;
        return startDate.toLocaleDateString(); // Adjust the date formatting as desired
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      minWidth: 30,
      flex: 0,
      sortable: true,
      type: 'date',
      valueGetter: (value) => new Date(value),
      renderCell: (params) => {
        const endDate = params.value;
        return endDate.toLocaleDateString(); // Adjust the date formatting as desired
      },
    },
    {
      field: 'language',
      headerName: 'Language',
      minWidth: 30,
      //   disableColumnMenu: true,
      flex: 0,
      sortable: true,
      //   editable: true,
      type: 'string',
      renderCell: (params) => {
        return SubtitleTranslation.languageAcronyms?.[params.row.language]?.[lang];
      },
    },

    {
      field: 'actions',
      headerName: '',
      minWidth: 20,
      sortable: false,
      flex: 0,
      //   disableColumnMenu: true,
      renderCell: (params) => {
        if (hoveredRow === params.id && params.row.id >= 0) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => {
                  setEdit(params.row);
                  setCreateNew(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setRowToDelete(params.row);
                  setSimpleAlertDialogOpenDelete(true);
                }}
              >
                <img src="/images/icons/bin-icon.svg" alt="" />
              </IconButton>
            </Box>
          );
        }
        return null;
      },
    },
  ];

  return (
    <div>
      <AlertDialog
        open={simpleAlertDialogOpenDelete}
        title="Delete Item"
        description="Are you sure you want to delete this question?"
        confirmBtnText="Yes, delete!"
        cancelBtnText="No, cancel"
        onConfirm={() => {
          handleDelete({ ...rowToDelete });
          setSimpleAlertDialogOpenDelete(false);
        }}
        onCancel={() => {
          setSimpleAlertDialogOpenDelete(false);
        }}
      />
      {createNew && (
        <UpdateDialog
          key={_.uniqueId(edit?.id)}
          open={createNew}
          onClose={handleClose}
          edit={edit}
          onCreate={handleCreate}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />
      )}
      <Button
        onClick={() => {
          setCreateNew(true);
        }}
      >
        <AddIcon />
        Add Update
      </Button>
      <div className="section">
        <DataGridTable
          rows={rows}
          columns={columns}
          pageSize={12}
          initialState={{ pinnedColumns: { right: ['actions'] } }}
          slotProps={{
            row: {
              onMouseEnter: onMouseEnterRow,
              onMouseLeave: onMouseLeaveRow,
            },
          }}
        />
      </div>
    </div>
  );
}
