import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useBackOfficeStatsApolloClient } from 'src/context/ApolloClientContext';
import { GET_ALL_STORIES_WITH_STATS } from './queries';
import formatDateString from '../../formatDateString';
import { sumTotalImpressions, sumTotalTimePlayed } from '../Overview/dataUtils';
import { EnhancedStoryType, StoryType } from './types';

const useFetchStories = (
  encodedOrgId: string,
  selectedDuration: string,
  dates: { startDate: Date | null; endDate: Date | null },
  setStorysData: (data: EnhancedStoryType[]) => void,
) => {
  const backOfficeStatsClient = useBackOfficeStatsApolloClient();
  const [fetchStories, { loading, error }] = useLazyQuery(GET_ALL_STORIES_WITH_STATS, {
    client: backOfficeStatsClient,
  });

  useEffect(() => {
    const variables: {
      encodedOrgId: string;
      startDate?: string;
      endDate?: string;
    } = { encodedOrgId };
    if (selectedDuration === 'custom') {
      variables.startDate = dates.startDate ? formatDateString(dates.startDate) : undefined;
      variables.endDate = dates.endDate ? formatDateString(dates.endDate) : undefined;
    } else if (!Number.isNaN(Number(selectedDuration))) {
      const numberDuration = Number(selectedDuration);
      const calculatedStartDate = new Date();
      calculatedStartDate.setDate(calculatedStartDate.getDate() - (numberDuration - 1));
      const calculatedEndDate = new Date();
      variables.startDate = formatDateString(calculatedStartDate);
      variables.endDate = formatDateString(calculatedEndDate);
    }

    fetchStories({ variables })
      .then((response) => {
        const { data } = response;

        if (data?.getAllStoriesWithStats) {
          const enhancedData = data.getAllStoriesWithStats.map((story: StoryType) => {
            const totalImpressions = story.stats?.playback?.impression
              ? sumTotalImpressions(
                  story.stats.playback.impression,
                  variables.startDate,
                  variables.endDate,
                )
              : 0;

            const totalTimePlayed = story.stats?.playback?.timePlayed
              ? sumTotalTimePlayed(
                  story.stats.playback.timePlayed,
                  variables.startDate,
                  variables.endDate,
                )
              : 0;

            return { ...story, totalImpressions, totalTimePlayed };
          });
          setStorysData(enhancedData);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [encodedOrgId, selectedDuration, dates, fetchStories, setStorysData]);

  return { loading, error };
};

export default useFetchStories;
