import { useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import styled from '@mui/material/styles/styled';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import getTheme from '../../../common/SplitButton/SplitButtonThemes';
import { translations } from '../../../../utils/translations';

const maxLengthButtonText = 22;

function SelectInteractiveTool({
  selectedInteraction,
  setSelectedInteraction,
  interactiveToolsOptions,
  placeholder,
  InteractionOptionAllowed,
}) {
  const lang = useLanguage();
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 5,
      // backgroundColor: theme.palette.background.paper,
      border: '1px solid #dbdbdd',
      fontSize: 14,
      width: '100%',
      height: '40px',
      lineHeight: '40px',
      padding: '0 10px',
      fontFamily: ['Inter', 'sans-serif'].join(','),
      '&:focus': {
        borderRadius: 5,
        outline: 0,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
      },
    },
  }));
  return (
    <Select
      value={selectedInteraction ?? ''}
      onChange={(e) => {
        setSelectedInteraction(e.target.value);
      }}
      placeholder={selectedInteraction ? 'none' : placeholder}
      input={<BootstrapInput />}
      disabled={!InteractionOptionAllowed}
      style={{ width: '100%' }}
      displayEmpty
      renderValue={(value) => {
        return value?.length
          ? Array.isArray(value)
            ? value.join(', ')
            : translations.videoCard[value][lang]
          : placeholder;
      }}
    >
      <MenuItem value="">
        <em>none</em>
      </MenuItem>

      {interactiveToolsOptions.map((option) => (
        <MenuItem key={_.uniqueId(option?.value)} value={option?.value ?? ''}>
          {option.label ?? ''}
        </MenuItem>
      ))}
    </Select>
  );
}

function ContactFormComponent({ contactForm, handleContactFormChange }) {
  const lang = useLanguage();
  return (
    <fieldset className="section">
      <label>
        <Typography variant="titleMedium" className="required-field">
          {translations.videoCard.emailContactForm[lang]}
        </Typography>
        <input
          type="text"
          value={contactForm.email}
          onChange={handleContactFormChange}
          name="email"
          required
        />
      </label>
    </fieldset>
  );
}

function CallToActionComponent({ callToAction, handleCallToActionChange }) {
  const lang = useLanguage();
  return (
    <fieldset className="section">
      <label>
        <Typography variant="titleMedium" className="required-field">
          {translations.videoCard.msgTextCallToAction[lang]}
        </Typography>
        <input
          type="text"
          value={callToAction.msg}
          onChange={handleCallToActionChange}
          name="msg"
          required
        />
      </label>
      <label>
        <Typography variant="titleMedium" className="required-field">
          {translations.videoCard.urlCallToAction[lang]}
        </Typography>
        <input
          type="text"
          value={callToAction.url}
          onChange={handleCallToActionChange}
          name="url"
          required
        />
      </label>
      <label>
        <Typography variant="titleMedium" className="required-field">
          {translations.videoCard.btnTextCallToAction[lang]}
        </Typography>
        <input
          type="text"
          value={callToAction.btnText}
          onChange={handleCallToActionChange}
          name="btnText"
          required
          maxLength={maxLengthButtonText}
        />
      </label>
    </fieldset>
  );
}

function SelectedInteractionTool({
  selectedInteraction,
  contactForm,
  handleContactFormChange,
  callToAction,
  handleCallToActionChange,
}) {
  switch (selectedInteraction) {
    case 'callToAction': {
      return (
        <CallToActionComponent
          callToAction={callToAction}
          handleCallToActionChange={handleCallToActionChange}
        />
      );
    }
    case 'contactForm': {
      return (
        <ContactFormComponent
          contactForm={contactForm}
          handleContactFormChange={handleContactFormChange}
        />
      );
    }
    default: {
      return null;
    }
  }
}

function VideoActionOption({
  selectedInteraction,
  setSelectedInteraction,
  contactForm,
  handleContactFormChange,
  callToAction,
  handleCallToActionChange,
  InteractionOptionAllowed,
}) {
  const lang = useLanguage();

  const interactiveToolsOptions = [
    { value: 'callToAction', label: translations.videoCard.callToAction[lang] },
    { value: 'contactForm', label: translations.videoCard.contactForm[lang] },
  ];
  const [selectedOption, setSelectedOption] = useState(selectedInteraction);

  const handleSelectedOption = (e) => {
    setSelectedInteraction((e && e.value) || e);
    setSelectedOption(e);
  };

  return (
    <fieldset className="section">
      <label id="lead-gen-selector">
        <div className="interaction-wrapper">
          <Typography variant="titleMedium">
            {translations.videoCard.interactionTools[lang]}
          </Typography>
          {!InteractionOptionAllowed && (
            <div>
              <Tooltip title={translations.videoCard.interactionToolsPremium[lang]}>
                <HelpOutlineIcon />
              </Tooltip>
            </div>
          )}
        </div>
        <SelectInteractiveTool
          selectedInteraction={selectedOption}
          setSelectedInteraction={handleSelectedOption}
          interactiveToolsOptions={interactiveToolsOptions}
          placeholder={translations.videoCard.interactionToolsSelect[lang]}
          InteractionOptionAllowed={InteractionOptionAllowed}
        />
        {selectedOption && InteractionOptionAllowed && (
          <div>
            <Typography variant="titleMedium">
              {translations.videoCard.interactionToolInfo[lang]}
            </Typography>
            <SelectedInteractionTool
              selectedInteraction={selectedInteraction}
              contactForm={contactForm}
              handleContactFormChange={handleContactFormChange}
              callToAction={callToAction}
              handleCallToActionChange={handleCallToActionChange}
            />
          </div>
        )}
      </label>
    </fieldset>
  );
}

export default VideoActionOption;
