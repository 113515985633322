import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

import { useLanguage } from 'src/context/LanguageContext';
import { AlertId, AlertsState } from './types';
import { HideAlertFunc } from './useAlerts';
import translationsPublicVideoCollector from './translations';

type AlertItem = {
  severity: 'success' | 'info' | 'warning' | 'error';
  text: string;
};

interface AlertsContainerProps {
  alerts: AlertsState;
  hideAlert: HideAlertFunc;
}

function AlertsContainer({ alerts: alertsState, hideAlert }: AlertsContainerProps) {
  const lang = useLanguage();

  const alertItems: { [k in AlertId]: AlertItem } = {
    ALERT_PREVIEW_NOTICE: {
      severity: 'info',
      text: translationsPublicVideoCollector.alertPreviewNotice[lang],
    },
    ALERT_PREVIEW_TOKEN_ERROR: {
      severity: 'error',
      text: translationsPublicVideoCollector.alertPreviewTokenError[lang],
    },
    ALERT_UPLOAD_SUCCESSFUL: {
      severity: 'success',
      text: translationsPublicVideoCollector.alertUploadSuccessful[lang],
    },
    ALERT_UPLOAD_FAILED: {
      severity: 'error',
      text: translationsPublicVideoCollector.alertUploadFailed[lang],
    },
  };

  const alertElems = Object.entries(alertItems).map(([key, alertItem]) => {
    const id = key as AlertId;
    const alertState = alertsState[id];
    return (
      <Grid item key={id}>
        <Collapse in={alertState.visible}>
          <Alert elevation={10} severity={alertItem.severity} onClose={() => hideAlert(id)}>
            {alertItem.text}
          </Alert>
        </Collapse>
      </Grid>
    );
  });

  return (
    <Grid container direction="column" spacing={1} sx={{ pt: 1 }}>
      {alertElems}
    </Grid>
  );
}

export default AlertsContainer;
