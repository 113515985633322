import { Route } from 'react-router';

import VideoRequestEmailSettings from 'src/components/back-office/settings/VideoRequestEmail';

import ConversationalAvatar from 'src/components/back-office/ConversationalAvatar';
import Permissions from 'src/components/back-office/settings/Permissions';
import Analytics from '../components/back-office/Analytics';
import BackOffice from '../components/back-office/BackOffice';
import BackOfficeTemplate from '../components/back-office/BackOfficeTemplate';
import DashboardPage from '../components/back-office/Dashboard/DashboardIndex';
import EndScreens from '../components/back-office/EndScreens';
import MyPage from '../components/back-office/MyPage';
import Planner from '../components/back-office/Planner/Planner';
import Requests from '../components/back-office/Requests';
import SettingsTemplate from '../components/back-office/settings';
import Storyline from '../components/back-office/Storyline';
import StorylineCreate from '../components/back-office/StorylineCreate';
import VideoCollector from '../components/back-office/VideoCollector/VideoCollector';
import VideoCollectorSettings from '../components/back-office/VideoCollector/VideoCollectorSettings/VideoCollectorSettings';
import VideoFunnelCreate from '../components/back-office/VideoFunnel/VideoFunnelCreate';
import VideoLibrary from '../components/back-office/VideoLibrary';
import WidgetDemo from '../components/back-office/WidgetDemo';
import AutomationSettings from '../components/back-office/settings/Automation';
import GeneralSettings from '../components/back-office/settings/General';
import QuestionsSettings from '../components/back-office/settings/Questions/Questions';
import RecipientsSettings from '../components/back-office/settings/Recipients';
import StyleSettings from '../components/back-office/settings/Styling';
import WidgetScript from '../components/back-office/settings/WidgetScript';

import SelectBackOffice from '../components/back-office/SelectBackOffice/SelectBackOffice';

const backOfficeRoutes = (
  <>
    <Route path="/:orgnameslug/backoffice/" element={<BackOfficeTemplate />}>
      <Route path="planner" element={<BackOffice />} />
      <Route path="planner-beta" element={<Planner />} />
      <Route index element={<DashboardPage />} />

      <Route path="requests" element={<Requests />} />
      <Route path="end-screens" element={<EndScreens />} />

      <Route path="stories" element={<Storyline />} />
      <Route path="video-collector" element={<VideoCollector />} />

      <Route path="video-collector/:videocollectorid" element={<VideoCollectorSettings />} />
      <Route path="storyline" element={<StorylineCreate />} />
      <Route path="video-funnel" element={<VideoFunnelCreate />} />

      <Route path="library" element={<VideoLibrary />} />
      <Route path="settings" element={<SettingsTemplate />}>
        <Route index element={<GeneralSettings />} />
        <Route path="widget-script" element={<WidgetScript />} />
        <Route path="automation" element={<AutomationSettings />} />
        <Route path="recipients" element={<RecipientsSettings />} />
        <Route path="questions" element={<QuestionsSettings />} />
        <Route path="styling" element={<StyleSettings />} />
        <Route path="video-request-email" element={<VideoRequestEmailSettings />} />
        <Route path="permissions" element={<Permissions />} />
      </Route>
      <Route path="widget-demo" element={<WidgetDemo />} />
      <Route path="my-page" element={<MyPage />} />
      <Route path="analytics" element={<Analytics />}>
        <Route path="*" />
      </Route>
      <Route path="conversational-avatar" element={<ConversationalAvatar />} />
    </Route>
    <Route path="/select-backoffice" element={<SelectBackOffice />} />
  </>
);

export default backOfficeRoutes;
