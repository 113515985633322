import PropTypes from 'prop-types';

function PlannerFilledIcon({ fill = '#000000' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.675 20.375L20.375 19.675L18.5 17.8V15H17.5V18.2L19.675 20.375ZM18 23C16.6167 23 15.4373 22.5123 14.462 21.537C13.4867 20.5617 12.9993 19.3827 13 18C13 16.6167 13.4877 15.4373 14.463 14.462C15.4383 13.4867 16.6173 12.9993 18 13C19.3833 13 20.5627 13.4877 21.538 14.463C22.5133 15.4383 23.0007 16.6173 23 18C23 19.3833 22.5123 20.5627 21.537 21.538C20.5617 22.5133 19.3827 23.0007 18 23ZM7 9H17V7H7V9ZM11.675 21H5C4.45 21 3.979 20.804 3.587 20.412C3.195 20.02 2.99934 19.5493 3 19V5C3 4.45 3.196 3.979 3.588 3.587C3.98 3.195 4.45067 2.99934 5 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V11.7C20.5167 11.4667 20.029 11.2917 19.537 11.175C19.045 11.0583 18.5327 11 18 11C17.8167 11 17.6457 11.004 17.487 11.012C17.3283 11.02 17.166 11.041 17 11.075V11H7V13H13.125C12.825 13.2833 12.554 13.5917 12.312 13.925C12.07 14.2583 11.8577 14.6167 11.675 15H7V17H11.075C11.0417 17.1667 11.0207 17.3293 11.012 17.488C11.0033 17.6467 10.9993 17.8173 11 18C11 18.55 11.05 19.0627 11.15 19.538C11.25 20.0133 11.425 20.5007 11.675 21Z"
        fill={fill}
      />
    </svg>
  );
}

export default PlannerFilledIcon;

PlannerFilledIcon.propTypes = {
  fill: PropTypes.string,
};
