/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
// import ContactForm, { typeContactForm } from './ContactForm';
import ContactForm, { typeContactForm } from './ContactForm';
import CallToAction, { typeCallToAction } from './CallToAction';
import VideoLibraryEndScreen, { typeVideoLibrary } from './VideoLibraryEndScreen';

export function EndScreenInputType({
  type,
  endScreen,
  setEndScreen,
  disabled = false,
  basicOrgData,
  editSelected = false,
  nodeMode = false,
  expandedInput = true,
  selectAllVideos,
}) {
  switch (type) {
    case typeCallToAction:
      return (
        <CallToAction
          endScreen={{ ...endScreen, type }}
          setEndScreen={setEndScreen}
          disabled={disabled}
        />
      );
    case typeContactForm:
      return (
        <ContactForm
          endScreen={{ ...endScreen, type }}
          setEndScreen={setEndScreen}
          disabled={disabled}
        />
      );
    case typeVideoLibrary:
      return (
        <VideoLibraryEndScreen
          basicOrgData={basicOrgData}
          endScreen={{ ...endScreen, type }}
          setEndScreen={setEndScreen}
          disabled={disabled}
          editSelected={editSelected}
          nodeMode={nodeMode}
          expandedInput={expandedInput}
          selectAllVideos={selectAllVideos}
        />
      );
    default:
      return null;
  }
}
EndScreenInputType.propTypes = {
  type: PropTypes.string.isRequired,
  endScreen: PropTypes.object,
  setEndScreen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  basicOrgData: PropTypes.object.isRequired,
  editSelected: PropTypes.bool,
};
