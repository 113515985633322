import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';

import translationsSignup from '../../../utils/translationsSignup';

interface SuccessScreenProps {
  language: Language;
}

function SuccessScreen({ language }: SuccessScreenProps) {
  return (
    <>
      <CardHeader
        title={translationsSignup.succesScreenTitle[language]}
        component="h1"
        titleTypographyProps={{
          align: 'center',
          color: '#121212',
          fontSize: '32px',
          fontFamily: 'Recoleta',
          fontWeight: '700',
          lineHeight: 40 / 32,
          wordWrap: 'break-word',
        }}
        sx={{ m: 2, p: 0 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                width: '100%',
                height: '100%',
                color: '#121212',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: 24 / 16,
                letterSpacing: 0.15,
                wordWrap: 'break-word',
              }}
            >
              {translationsSignup.successScreenMessage[language]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                width: '100%',
                height: '100%',
                color: '#121212',
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: 24 / 16,
                letterSpacing: 0.15,
                wordWrap: 'break-word',
              }}
            >
              {translationsSignup.emailSentMessage[language]}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default SuccessScreen;
