import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, CircularProgress } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import LoadingIndicator from '../common/LoadingIndicator';

interface GetUserResponse {
  user: {
    id: number;
    email: string;
    firstName: string | null;
    lastName: string | null;
  };
}

export const GET_USER_QUERY = gql`
  query GetUser($userId: Int!) {
    user(id: $userId) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const RESET_MFAS_MUTATION = gql`
  mutation ResetMFAs($userId: Int!) {
    resetMFAs(userId: $userId)
  }
`;

function ResetUserMFAs() {
  const userId = Number(useParams().userid);

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery<GetUserResponse>(GET_USER_QUERY, { variables: { userId } });

  const [resetMFAs, { loading: resetMFAsLoading, error: resetMFAsError, called: resetMFAsCalled }] =
    useMutation(RESET_MFAS_MUTATION, { variables: { userId } });

  const user = userData?.user;
  const resetMFAsSuccess = resetMFAsCalled && !resetMFAsLoading && !resetMFAsError;

  const onResetMFAsClicked = () => {
    resetMFAs().catch((err) => console.error(err));
  };

  if (userLoading) {
    return <LoadingIndicator />;
  }

  if (userError) {
    return (
      <p>
        <Alert severity="error">
          Error fetching user: <code>{userError.message}</code>
        </Alert>
      </p>
    );
  }

  if (user == null) {
    return <p>Not found</p>;
  }

  return (
    <Box>
      {resetMFAsError && (
        <p>
          <Alert severity="error">
            Error: <code>{resetMFAsError.message}</code>
          </Alert>
        </p>
      )}
      {resetMFAsSuccess && (
        <p>
          <Alert severity="success">MFA factors reset</Alert>
        </p>
      )}
      <h1>Confirm reset MFAs for user id: {userId}</h1>
      <p>
        {`${user.firstName} ${user.lastName} <${user.email}>`}&nbsp;
        <Link to={`/admin/edit-user/${user.id}`}>(edit user)</Link>
      </p>
      <p>This will clear all registered MFA factors for the user. This cannot be undone.</p>
      <p>
        <Button
          variant="contained"
          color="primary"
          disabled={resetMFAsLoading}
          onClick={onResetMFAsClicked}
        >
          Reset MFA
          {resetMFAsLoading && <CircularProgress size={20} />}
        </Button>
      </p>
    </Box>
  );
}

export default ResetUserMFAs;
