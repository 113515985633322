import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import './editSuggestedQuestions.scss';
import LoadingIndicator from '../common/LoadingIndicator';

type VideoCardQuestionsResponse = {
  videoCardQuestions: {
    sv: string[] | null;
    en: string[] | null;
  };
};

const VIDEO_CARD_QUESTIONS_QUERY = gql`
  query VideoCardQuestions {
    videoCardQuestions {
      sv
      en
    }
  }
`;

export default function AllQuestions() {
  const { data, loading } = useQuery<VideoCardQuestionsResponse>(VIDEO_CARD_QUESTIONS_QUERY);

  const [enQuestions, svQuestions] = useMemo(() => {
    const en = data?.videoCardQuestions?.en ?? [];
    const sv = data?.videoCardQuestions?.sv ?? [];
    return [
      en.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
      sv.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
    ];
  }, [data]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="list-view">
      <div className="leftList">
        <textarea
          value={enQuestions.map((q) => JSON.stringify(q)).join(',\n')}
          readOnly
          style={{ height: '100%', width: '100%', minWidth: '500px', minHeight: '800px' }}
        />
      </div>
      <div className="rightList">
        <textarea
          value={svQuestions.map((q) => JSON.stringify(q)).join(',\n')}
          readOnly
          style={{ height: '100%', width: '100%', minWidth: '500px', minHeight: '800px' }}
        />
      </div>
    </div>
  );
}
