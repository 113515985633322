import { useNavigate } from 'react-router-dom';
import React, { useState, useContext } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';

import { useLanguage } from 'src/context/LanguageContext';
import { getActionValuePath, ActionIcon } from './ToDoHelper';
import { translationsDashboard } from '../../../utils/translationsDashboard';
import ThemeContext from '../../../utils/ThemeContext';

const defaultToDoItem = {
  action: '',
  completed: false,
  disallowDismiss: false,
  description: '',
};

function ToDoCard({ toDoItem = defaultToDoItem, basicOrgData = {}, handleDismiss = () => {} }) {
  const navigate = useNavigate();
  const [showDismiss, setShowDismiss] = useState(false);
  const theme = useContext(ThemeContext);
  const lang = useLanguage();

  const handleClick = () => {
    if (toDoItem?.action) {
      navigate(getActionValuePath({ toDoItem, basicOrgData }), {
        state: {
          toDoItem,
        },
      });
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        position: 'relative',
      }}
      onMouseEnter={() => {
        setShowDismiss(true);
      }}
      onMouseLeave={() => {
        setShowDismiss(false);
      }}
    >
      <CardActionArea
        disableRipple
        onClick={(e) => handleClick(e)}
        disabled={!toDoItem?.action && !toDoItem.completed}
        component="div"
      >
        {!toDoItem?.disallowDismiss && (
          <Fade in={showDismiss}>
            <IconButton
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDismiss(toDoItem);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Fade>
        )}

        <CardContent
          style={{
            py: 3,
            ...(toDoItem.completed ? { backgroundColor: theme.palette.success.main } : {}),
          }}
        >
          <Grid container spacing={0} sx={{ justifyContent: 'space-between' }}>
            <Grid
              item
              lg={1}
              sm={1}
              md={1}
              xl={1}
              xs={1}
              style={{ display: 'grid', placeItems: 'center' }}
            >
              {toDoItem.completed ? (
                <CheckCircleIcon
                  style={{
                    color: theme.palette.success.dark,
                  }}
                />
              ) : (
                <ActionIcon
                  toDoItem={toDoItem}
                  style={{
                    minHeight: '15px',
                    minWidth: '15px',
                    maxHeight: '24px',
                    maxWidth: '24px',
                  }}
                />
              )}
            </Grid>
            <Grid item lg={11} sm={11} md={11} xl={11} xs={11}>
              <Typography variant="bodyMedium">
                {toDoItem?.description
                  ? toDoItem.description
                  : translationsDashboard.toDoViewCard?.[toDoItem?.action]?.description?.[lang]}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ToDoCard.propTypes = {
  toDoItem: PropTypes.shape({
    action: PropTypes.string,
    completed: PropTypes.bool,
    disallowDismiss: PropTypes.bool,
    description: PropTypes.string,
  }),
  basicOrgData: PropTypes.shape({}),
  handleDismiss: PropTypes.func,
};

export default ToDoCard;
