import React from 'react';
import { TableCell, TableRow, Box, Typography } from '@mui/material';

import { EnhancedStoryType } from './types';
import { formatTotalTimePlayed } from '../Overview/dataUtils';
import StoryMedia from '../../StoryMedia';

export interface StoryRowProps {
  story: EnhancedStoryType;
  lang: Language;
  width?: number | string;
  height?: number | string;
}

function StoryRow({ story, lang, width = 100, height = 62 }: StoryRowProps) {
  const { totalTimePlayed, totalImpressions } = story;
  const formattedTimePlayed = formatTotalTimePlayed(totalTimePlayed || 0, lang);

  const storyImage = story.urlsJsonb?.filter((urlObj) => !!urlObj.srcImage)?.[0]?.srcImage;

  return (
    <TableRow key={`Story-table-row-${story.id}`} data-testid={`story-row-${story.id}`}>
      <TableCell>
        <Box display="flex" alignItems="center" data-testid={`story-thumbnail-${story.id}`}>
          <Box sx={{ mr: 2, width, height, position: 'relative' }}>
            <StoryMedia
              cardWidth={width}
              isActive={story.status === 'LIVE'}
              srcImage={storyImage}
            />
          </Box>
          <Typography variant="bodyMedium" noWrap data-testid="story-title">
            {story.title}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="bodyMedium">{totalImpressions?.toLocaleString()}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="bodyMedium">{formattedTimePlayed.toLocaleString()}</Typography>
      </TableCell>
    </TableRow>
  );
}

export default StoryRow;
