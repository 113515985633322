import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { ApolloClient, useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../../../fragments/videoCards';

export class SubtitleApi {
  private backOfficeClient: ApolloClient;

  constructor(backOfficeClient: ApolloClient) {
    this.backOfficeClient = backOfficeClient;
  }

  updateDefaultSubtitle({
    encodedOrgId,
    encodedVideoCardId,
    defaultLang,
  }: {
    encodedOrgId: string;
    encodedVideoCardId: string;
    defaultLang: string;
  }) {
    return this.backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        mutation UpdateDefaultSubtitle(
          $encodedOrgId: String!
          $encodedVideoCardId: String!
          $defaultLang: String!
        ) {
          updateDefaultSubtitle(
            encodedOrgId: $encodedOrgId
            encodedVideoCardId: $encodedVideoCardId
            defaultLang: $defaultLang
          ) {
            ...VideoCardDetails
          }
        }
      `,
      variables: { encodedOrgId, encodedVideoCardId, defaultLang },
    });
  }

  deleteSubtitle = ({
    encodedOrgId,
    encodedVideoCardId,
    srcLang,
  }: {
    encodedOrgId: string;
    encodedVideoCardId: string;
    srcLang: string;
  }) => {
    return this.backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        mutation DeleteSubtitle(
          $encodedOrgId: String!
          $encodedVideoCardId: String!
          $srcLang: String!
        ) {
          deleteSubtitle(
            encodedOrgId: $encodedOrgId
            encodedVideoCardId: $encodedVideoCardId
            srcLang: $srcLang
          ) {
            ...VideoCardDetails
          }
        }
      `,
      variables: { encodedOrgId, encodedVideoCardId, srcLang },
    });
  };

  uploadSubtitleFile({
    encodedOrgId,
    videoId,
    videoCardId,
    srcLang,
    label,
    isDefault,
    file,
  }: {
    encodedOrgId: string;
    videoId: number;
    videoCardId: number;
    srcLang: string;
    label: string;
    isDefault: boolean;
    file: File;
  }) {
    return this.backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        mutation UploadSubtitleFile(
          $encodedOrgId: String!
          $videoId: Int!
          $videoCardId: Int!
          $srcLang: String!
          $label: String!
          $isDefault: Boolean!
          $file: Upload!
        ) {
          uploadSubtitleFile(
            encodedOrgId: $encodedOrgId
            videoId: $videoId
            videoCardId: $videoCardId
            srcLang: $srcLang
            label: $label
            isDefault: $isDefault
            file: $file
          ) {
            link
            uri
            videoCard {
              ...VideoCardDetails
            }
            excludeFromVimeo
          }
        }
      `,
      variables: {
        encodedOrgId,
        file,
        videoId,
        videoCardId,
        srcLang,
        label,
        isDefault,
      },
    });
  }
}

export const useSubtitleApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new SubtitleApi(backOfficeClient), [backOfficeClient]);
};
