import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import {
  getAnimatedThumbnail,
  getStaticThumbnail,
  getVideoLink,
  VideoCard as VideoCardData,
} from 'src/utils/video-card/utils';
import { isMobile } from 'react-device-detect';
import { MediaItemState, initialState } from '../../../shared/VideoCard/MediaItemState';

import VideoCard from '../../../shared/VideoCard';

interface MediaGridItemProps {
  encodedId: string;
  encodedOrgId: string;
  encodedVideoCollectorId: string;
  videoToken: string;
}

interface VideoCardQuery {
  videoCardQuery: VideoCardData;
}

export const VIDEO_CARD_QUERY = gql`
  query VideoCardQuery(
    $encodedId: String!
    $encodedOrgId: String!
    $encodedVideoCollectorId: String!
    $videoToken: String!
  ) {
    videoCardQuery(
      encodedId: $encodedId
      encodedOrgId: $encodedOrgId
      encodedVideoCollectorId: $encodedVideoCollectorId
      videoToken: $videoToken
    ) {
      id
      encodedId
      staticThumbnails {
        sqcif
        qcif
        ntsc
        nHD
        qHD
        HD
        FHD
      }
      animatedThumbnails {
        sqcif
        qcif
        ntsc
        nHD
        qHD
        HD
        FHD
      }
      videoLink {
        ntsc
        nHD
        qHD
        HD
        FHD
      }
      question {
        en
        sv
      }
      actorName
      actorTitle {
        en
        sv
      }
      recipient {
        id
        firstName
        lastName
        title
        name
      }
      subtitles {
        label
        src
        srcLang
        default
      }
    }
  }
`;

function MediaGridItem({
  encodedId,
  encodedOrgId,
  encodedVideoCollectorId,
  videoToken,
}: MediaGridItemProps) {
  const [mediaItemState, setMediaItemState] = useState<MediaItemState>(initialState);
  const [shouldRender, setShouldRender] = useState(false);

  let videoCard: VideoCardData | null = null;

  const [fetchVideoCard, { data: videoCardData, loading, error }] = useLazyQuery<VideoCardQuery>(
    VIDEO_CARD_QUERY,
    {
      variables: { encodedId, encodedOrgId, encodedVideoCollectorId, videoToken },
      onCompleted: (data) => {
        if (data?.videoCardQuery) {
          videoCard = data.videoCardQuery;
          const isVideo = !!getVideoLink(data.videoCardQuery) || false;
          const videoLink = getVideoLink(data.videoCardQuery, 'nHD') || null;
          const staticThumbnail = getStaticThumbnail(data.videoCardQuery, 'nHD') || null;
          const animatedThumbnail = getAnimatedThumbnail(data.videoCardQuery, 'nHD') || null;

          setMediaItemState({
            ...mediaItemState,
            staticThumbnail,
            animatedThumbnail,
            videoLink,
            isVideo,
          });
        }
      },
    },
  );

  if (videoCardData?.videoCardQuery) {
    videoCard = videoCardData.videoCardQuery;
  }

  const mediaItemStyle: React.CSSProperties = {
    breakInside: 'avoid',
    width: '100%',
    height: 100,
    position: 'relative',
    paddingBottom: `${100 / mediaItemState.aspectRatio}%`,
    gridColumnEnd: `${mediaItemState.gridColumnEnd} span`,
    gridRowEnd: `${mediaItemState.gridRowEnd} span`,
  };

  useEffect(() => {
    if (shouldRender && !loading && !error) {
      fetchVideoCard();
    }
  }, [shouldRender, fetchVideoCard, loading, error]);

  if (loading) return <div data-testid="loading" />;
  if (error) return <div data-testid="error" />;

  return (
    <Box sx={mediaItemStyle}>
      <VideoCard
        mediaItemState={mediaItemState}
        setMediaItemState={setMediaItemState}
        onVisibilityChange={(visible) => setShouldRender(visible)}
        videoCard={videoCard}
        isMobile={isMobile}
        useAbsolutePositionStyles
      />
    </Box>
  );
}

export default MediaGridItem;
