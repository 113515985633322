// src/components/shared/VideoCard.tsx

import React, { useState, useRef, useEffect } from 'react';
import { VideoCard as VideoCardData } from 'src/utils/video-card/utils';

import VideoPlayer from './VideoPlayer';
import ProgressBar from './ProgressBar';
import MediaInfoOverlay from './MediaInfoOverlay';
import ImageDisplay from './ImageDisplay';
import { MediaItemState } from './MediaItemState';
import useIntersectionObserver from './useIntersectionObserver';
import './VideoCard.scss';

interface VideoCardProps {
  mediaItemState: MediaItemState;
  setMediaItemState: React.Dispatch<React.SetStateAction<MediaItemState>>;
  videoCard?: VideoCardData | null;
  isMobile: boolean;
  visibilityThreshold?: number;
  onVisibilityChange?: (visible: boolean) => void;
  useAbsolutePositionStyles?: boolean;
  borderRadius?: string | number;
  showTitle?: boolean;
  isSelected?: boolean;
  onSelectVideo?: (encodedVideoId: string) => void;
  disabled?: boolean;
}

function VideoCard({
  mediaItemState,
  setMediaItemState,
  videoCard = null,
  isMobile,
  visibilityThreshold = 600,
  onVisibilityChange = undefined,
  useAbsolutePositionStyles = false,
  borderRadius = 0,
  showTitle = false,
  isSelected = false,
  onSelectVideo = undefined,
  disabled = true,
}: VideoCardProps) {
  const mediaRef = useRef<HTMLVideoElement | HTMLImageElement | null>(null);
  const shouldRender = useIntersectionObserver(mediaRef, visibilityThreshold);

  useEffect(() => {
    if (onVisibilityChange) onVisibilityChange(shouldRender);
  }, [shouldRender, onVisibilityChange]);

  const [mouseHover, setMouseHover] = useState(false);

  const [playFullVideo, setPlayFullVideo] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleProgressBarClick = (newTime: number) => {
    if (mediaRef.current instanceof HTMLVideoElement) {
      mediaRef.current.currentTime = newTime;
    }
  };

  const handleVideoClick = () => {
    if (mediaRef.current instanceof HTMLVideoElement) {
      if (playFullVideo) {
        if (mediaRef.current.paused) {
          mediaRef.current.play().catch(() => {});
          mediaRef.current.volume = 1;
          mediaRef.current.muted = false;
        } else {
          mediaRef.current.pause();
          mediaRef.current.volume = 0;
          mediaRef.current.muted = true;
        }
        return;
      }

      setPlayFullVideo((prev) => !prev);

      mediaRef.current.load();
      mediaRef.current.play().catch(() => {});
    }
  };

  const handleMouseEnter = () => {
    if (playFullVideo) return;
    setMouseHover(true);
    if (mediaRef.current instanceof HTMLVideoElement && mediaRef.current.paused) {
      mediaRef.current.play().catch(() => {});
    }
  };

  const handleMouseLeave = () => {
    if (playFullVideo) return;
    setMouseHover(false);
    if (mediaRef.current instanceof HTMLVideoElement && !mediaRef.current.paused) {
      mediaRef.current.pause();
    }
  };

  return (
    <div
      className="video-hover-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={`video-card-${videoCard?.id}`}
    >
      {mediaItemState.isVideo ? (
        <VideoPlayer
          mediaRef={mediaRef as React.RefObject<HTMLVideoElement>}
          playFullVideo={playFullVideo}
          mouseHover={mouseHover}
          shouldRender={shouldRender}
          mediaItemState={mediaItemState}
          setMediaItemState={setMediaItemState}
          setProgress={setProgress}
          useAbsolutePositionStyles={useAbsolutePositionStyles}
          handleVideoClick={handleVideoClick}
          borderRadius={borderRadius}
        >
          <ProgressBar
            playFullVideo={playFullVideo}
            progress={progress}
            mediaRef={mediaRef as React.RefObject<HTMLVideoElement>}
            onProgressBarClick={handleProgressBarClick}
            isMobileDevice={isMobile}
            borderRadius={borderRadius}
          />
          {videoCard && (
            <MediaInfoOverlay
              videoCard={videoCard}
              mouseHover={mouseHover || (showTitle ?? false)}
              playFullVideo={playFullVideo}
              isMobileDevice={isMobile}
              borderRadius={borderRadius}
              isSelected={isSelected}
              onSelectVideo={onSelectVideo}
              disabled={disabled}
            />
          )}
        </VideoPlayer>
      ) : (
        <ImageDisplay
          mediaRef={mediaRef as React.RefObject<HTMLImageElement>}
          shouldRender={shouldRender}
          mediaItemState={mediaItemState}
          setMediaItemState={setMediaItemState}
        />
      )}
    </div>
  );
}

export default VideoCard;
