import PropTypes from 'prop-types';

function StorylineOutlinedIcon({ fill = '#121212' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1216_13972)">
        <path
          d="M19.1655 11.5401L16.4373 9.07135C16.038 8.34071 16.745 7.50079 17.5331 7.76959L17.7165 7.83215L21.8888 11.5352L19.1655 11.5401Z"
          fill={fill}
        />
        <path
          d="M19.1655 11.5401L16.4373 9.07135C16.0367 8.33816 16.7767 7.51158 17.5864 7.7878L17.7165 7.83215L21.8888 11.5352L19.1655 11.5401Z"
          fill={fill}
        />
        <path
          d="M19.2145 13.306L16.5019 15.8334C16.1055 16.5816 16.8342 17.4271 17.6326 17.1454L17.7832 17.0923L21.9321 13.301L19.2145 13.306Z"
          fill={fill}
        />
        <path
          d="M19.2145 13.306L16.5019 15.8334C16.1049 16.5825 16.8467 17.4227 17.6536 17.138L17.7832 17.0923L21.9321 13.301L19.2145 13.306Z"
          fill={fill}
        />
        <path
          d="M6.60679 12.4467C6.60679 13.7148 5.57878 14.7428 4.31068 14.7428C3.04258 14.7428 2.01457 13.7148 2.01457 12.4467C2.01457 11.1786 3.04258 10.1506 4.31068 10.1506C5.57878 10.1506 6.60679 11.1786 6.60679 12.4467Z"
          stroke={fill}
          strokeWidth="2.02913"
        />
        <path
          d="M14.9369 12.4467C14.9369 13.7148 13.9089 14.7428 12.6408 14.7428C11.3727 14.7428 10.3446 13.7148 10.3446 12.4467C10.3446 11.1786 11.3727 10.1506 12.6408 10.1506C13.9089 10.1506 14.9369 11.1786 14.9369 12.4467Z"
          stroke={fill}
          strokeWidth="2.02913"
        />
        <rect x="6.33984" y="11.4855" width="4.05825" height="1.92235" fill={fill} />
        <path
          d="M15.6309 11.4855H21.8186L22.4788 11.9929C22.7475 12.1994 22.7584 12.6006 22.5014 12.8214L21.8186 13.4078H15.6309V11.4855Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1216_13972">
          <rect width="22" height="11" fill="transparent" transform="translate(1 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StorylineOutlinedIcon;

StorylineOutlinedIcon.propTypes = {
  fill: PropTypes.string,
};
