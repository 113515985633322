import { styled } from '@mui/system';

export interface MediaItemState {
  aspectRatio: number;
  gridColumnEnd: number;
  gridRowEnd: number;
  isVideo: boolean;
  videoLink?: string | null;
  staticThumbnail?: string | null;
  animatedThumbnail?: string | null;
}
export const initialState: MediaItemState = {
  aspectRatio: 1 / 2,
  gridColumnEnd: 1,
  gridRowEnd: 1,
  isVideo: false,
  videoLink: null,
  staticThumbnail: null,
  animatedThumbnail: null,
};

export const StyledImage = styled('img')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  overflow: 'hidden',
});
