import { translations } from 'src/utils/translations';

const translationsPublicVideoCollector = {
  ThankYouVideoUploaded: {
    en: 'Thank You for Your Submission!',
    sv: 'Tack för ditt bidrag!',
  },
  VideoUploadSuccessMessage: {
    en: 'Your video has been successfully uploaded. Feel free to upload another video if you wish.',
    sv: 'Din video har laddats upp. Du är välkommen att ladda upp en annan video om du vill.',
  },
  UploadAnotherVideo: {
    en: 'Upload another video',
    sv: 'Ladda upp en annan video',
  },
  VideoUploaded: {
    en: 'Video uploaded',
    sv: 'Video uppladdad',
  },
  SelectVideo: {
    en: 'Select video',
    sv: 'Välj video',
  },
  QuestionAbbreviation: {
    en: 'Q',
    sv: 'Q',
  },
  UploadYourVideo: {
    en: 'Upload your video',
    sv: 'Ladda upp din video',
  },
  UploadConsent: {
    en: 'By clicking “Submit video”, you agree to the following',
    sv: 'Genom att klicka på "Skicka in video", godkänner du följande',
  },
  AccessRestricted: {
    en: 'Access Restricted',
    sv: 'Åtkomst begränsad',
  },
  Error: {
    en: 'Oops...',
    sv: 'Hoppsan...',
  },
  UnsupportedLinkMessage1: {
    en: `Seems this link took a detour.`,
    sv: 'Det verkar som att denna länk tog en omväg.',
  },
  UnsupportedLinkMessage2: {
    en: `No worries, these things happen. It might just be out on a little adventure.`,
    sv: 'Ingen fara, sånt händer. Den kanske bara är ute på ett litet äventyr.',
  },
  UnsupportedLinkMessage3: {
    en: `Should you require guidance, please contact the link provider.`,
    sv: 'Om du behöver vägledning, vänligen kontakta länkens utfärdare.',
  },
  Loading: {
    en: 'Loading...',
    sv: 'Laddar...',
  },
  PasswordProtected: {
    en: 'This video page is protected. Please enter the password to continue.',
    sv: 'Denna videosida är skyddad. Vänligen ange lösenordet för att fortsätta.',
  },
  Password: {
    en: 'Password',
    sv: 'Lösenord',
  },
  Continue: {
    en: 'Continue',
    sv: 'Fortsätt',
  },
  Checking: {
    en: 'Checking...',
    sv: 'Kontrollerar...',
  },
  IncorrectPassword: {
    en: 'Incorrect password',
    sv: 'Fel lösenord',
  },
  SubmitVideo: {
    en: 'Submit Video',
    sv: 'Skicka in video',
  },
  PhoneNumber: {
    en: 'Phone Number',
    sv: 'Telefonnummer',
  },
  Email: {
    en: 'Email',
    sv: 'E-post',
  },
  Name: {
    en: 'Name',
    sv: 'Namn',
  },
  RoleTitle: {
    en: 'Title',
    sv: 'Titel',
  },
  Optional: {
    en: 'Optional',
    sv: 'Valfritt',
  },
  Required: {
    en: 'Required',
    sv: 'Obligatoriskt',
  },
  CustomTitle: {
    en: 'Custom Title',
    sv: 'Anpassad titel',
  },
  VideoTitle: {
    en: 'Video Title',
    sv: 'Videotitel',
  },
  RecordUploadVideo: {
    en: 'Record & Upload Video',
    sv: 'Spela in & Ladda upp video',
  },
  uploadVideoForQuestion: {
    en: 'Upload your video',
    sv: 'Ladda upp din video',
  },
  uploadVideoForMultiQuestions: {
    en: 'Choose a video title from the list and upload your video',
    sv: 'Välj en videotitel från listan och ladda upp din video',
  },
  needSomeTips: {
    en: translations.upload.needSomeTips.en,
    sv: translations.upload.needSomeTips.sv,
  },
  step1Title: {
    en: translations.upload.step1Title.en,
    sv: translations.upload.step1Title.sv,
  },
  step1: {
    en: translations.upload.step1.en,
    sv: translations.upload.step1.sv,
  },
  step1NoPortrait: {
    en: translations.upload.step1NoPortrait.en,
    sv: translations.upload.step1NoPortrait.sv,
  },
  step2Title: {
    en: translations.upload.step2Title.en,
    sv: translations.upload.step2Title.sv,
  },
  step2: {
    en: translations.upload.step2.en,
    sv: translations.upload.step2.sv,
  },
  step3Title: {
    en: translations.upload.step3Title.en,
    sv: translations.upload.step3Title.sv,
  },
  step3: {
    en: (
      <span>
        Simply check the box to agree to the terms, and hit <em>&apos;Upload video&apos;</em>.
      </span>
    ),
    sv: (
      <span>
        Markera rutan för att godkänna villkoren och klicka på <em>&apos;Ladda upp video&apos;</em>.
      </span>
    ),
  },
  alertPreviewNotice: {
    en: 'This is a preview, do not publicly share this link.',
    sv: 'Detta är en förhandsgranskning, dela inte denna länken publikt.',
  },
  alertPreviewTokenError: {
    en: 'This preview link is no longer valid.',
    sv: 'Denna förhandsgranskningslänken är inte längre giltig.',
  },
  validation: {
    phoneNumberError: {
      INVALID_COUNTRY: {
        en: 'You must enter a valid country code',
        sv: 'Du måste ange en giltig landskod',
      },
      NOT_A_NUMBER: {
        en: 'You must enter a number',
        sv: 'Du måste ange ett nummer',
      },
      TOO_SHORT: {
        en: 'Phone number is too short',
        sv: 'Telefonnumret är för kort',
      },
      TOO_LONG: {
        en: 'Phone number is too long',
        sv: 'Telefonnumret är för långt',
      },
      INVALID_LENGTH: {
        en: 'Phone number is invalid length',
        sv: 'Telefonnumret är ogiltigt längd',
      },
      INVALID: {
        en: 'Phone number is invalid',
        sv: 'Telefonnumret är ogiltigt',
      },
      NOT_POSSIBLE: {
        en: 'Phone number is not possible',
        sv: 'Telefonnumret är inte möjligt',
      },
    },
  },
  alertUploadSuccessful: {
    en: 'Upload successful!',
    sv: 'Uppladdningen lyckades!',
  },
  alertUploadFailed: {
    en: 'Upload failed, please try again later.',
    sv: 'Uppladdningen misslyckades, försök igen senare.',
  },
};

export default translationsPublicVideoCollector;
