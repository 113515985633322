import { gql } from '@apollo/client';
import {
  STATS_BY_TIMESTAMP_FRAGMENT,
  VIDEO_CARD_DETAILS_FRAGMENT_FOR_STATS,
} from '../Overview/analyticsOverviewApi';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_VIDEOS_WITH_STATS = gql`
  ${STATS_BY_TIMESTAMP_FRAGMENT}
  ${VIDEO_CARD_DETAILS_FRAGMENT_FOR_STATS}
  query GetAllVideosWithStats($encodedOrgId: String!, $startDate: String, $endDate: String) {
    getAllVideosWithStats(encodedOrgId: $encodedOrgId, startDate: $startDate, endDate: $endDate) {
      ...VideoCardDetails
      stats {
        playback {
          impression {
            ...StatByTimestamp
          }
          timePlayed {
            ...StatByTimestamp
          }
          play {
            ...StatByTimestamp
          }
        }
      }
    }
  }
`;
