import { translations } from '../../../utils/translations';

const NavigatorTranslations = {
  premiumFeature: {
    en: 'Interested in this feature? 🌟 Please contact support for assistance and more information.',
    sv: 'Intresserad av denna funktion? 🌟 Kontakta supporten för hjälp och mer information.',
  },
  home: {
    en: translations.home.en,
    sv: translations.home.sv,
  },
  videoPlanner: {
    en: translations.videoPlanner.en,
    sv: translations.videoPlanner.sv,
  },
  videoLibrary: {
    en: translations.videoLibrary.en,
    sv: translations.videoLibrary.sv,
  },
  planner: {
    en: translations.planner.en,
    sv: translations.planner.sv,
  },
  stories: {
    en: translations.stories.en,
    sv: translations.stories.sv,
  },
  storyline: {
    en: translations.storyline.en,
    sv: translations.storyline.sv,
  },
  videoCollector: {
    en: translations.videoCollector.en,
    sv: translations.videoCollector.sv,
  },
  endScreens: {
    en: translations.endScreens.en,
    sv: translations.endScreens.sv,
  },
  analytics: {
    en: translations.analytics.en,
    sv: translations.analytics.sv,
  },
  library: {
    en: translations.library.en,
    sv: translations.library.sv,
  },
  orgSettings: {
    en: translations.orgSettings.en,
    sv: translations.orgSettings.sv,
  },
  conversationalAvatar: {
    en: 'AI Avatar',
    sv: 'AI Avatar',
  },
  logout: {
    en: translations.logout.en,
    sv: translations.logout.sv,
  },
};

export default NavigatorTranslations;
