import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TextUpload.scss';
import MuiAlert from '@mui/material/Alert';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import Vimeo from '@u-wave/react-vimeo';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Autocomplete,
  TextField,
  Typography,
  Container,
} from '@mui/material';
import { UploadFileRounded } from '@mui/icons-material';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { useLanguage } from 'src/context/LanguageContext';
import { useAdminApolloClient } from 'src/context/ApolloClientContext';
import { SubtitleTranslation } from '../../utils/subtitleTranslation';
import { backendPostPath } from '../../utils/environment';
import MadeWithLoveFooter from '../public/MadeWithLoveFooter';
import { VIDEO_CARD_SUMMARY_FRAGMENT } from '../../fragments/videoCards';
import SplitButton from '../common/SplitButton/SplitButton';
import { isValidHttpUrl } from '../../utils/utils';
import { HappyScribeChip, happyScribeStatuses } from './HappyScribeChip';
import { fetchActiveLanguages } from './HappyScribeLanguages';
import { getSubtitle } from './SubtitleRequest';

const getVideoCardPreSignedUrlAWS = ({ adminClient, encodedVideoCardId, path, key }) => {
  return adminClient.query({
    query: gql`
      query {
        getVideoCardPreSignedUrlAWS(encodedVideoCardId: "${encodedVideoCardId}", path: "${path}", key: "${key}")  
      }
    `,
  });
};

const updateDefaultSubtitle = ({ adminClient, encodedVideoCardId, defaultLang }) => {
  return adminClient.mutate({
    mutation: gql`
    ${VIDEO_CARD_SUMMARY_FRAGMENT}
      mutation {
        updateDefaultSubtitle(encodedVideoCardId: "${encodedVideoCardId}", defaultLang: "${defaultLang}") {
          ...VideoCardSummary
        }
      }
    `,
  });
};

const deleteSubtitle = (adminClient, id) => {
  return adminClient.mutate({
    mutation: gql`
          mutation {
            deleteSubtitle( id: ${id} ) {
              statusMessage
           }
          }
      `,
  });
};

function SubtitleUpload() {
  const adminClient = useAdminApolloClient();
  const { state } = useLocation();

  const params = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!state) {
      const { encodedId } = params;
      getSubtitle(adminClient, null, encodedId)
        .then((res) => {
          const data = res?.data?.getSubtitle?.[0];
          if (data) {
            setItem({ ...data, ...data.videoCard });
          } else {
            throw new Error('Not found');
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('getSubtitle failed', err);
          return setError(err?.message ?? 'Unknown error');
        });
    }
  }, [adminClient, params, state]);

  if (state) {
    return <SubtitleUploadInner item={state} />;
  }

  if (item) {
    return <SubtitleUploadInner item={item} />;
  }

  if (error) {
    return (
      <Box>
        <Typography variant="bodyMedium">
          Error: <code>{error}</code>
        </Typography>
      </Box>
    );
  }

  return null;
}
export default SubtitleUpload;

const uploadSubtitleFile = ({
  adminClient,
  videoId,
  videoCardId,
  srcLang,
  label,
  isDefault,
  file,
}) => {
  return adminClient.mutate({
    mutation: gql`
      ${VIDEO_CARD_SUMMARY_FRAGMENT}
      mutation (
        $videoId: Int!
        $videoCardId: Int!
        $srcLang: String!
        $label: String!
        $isDefault: Boolean!
        $file: Upload!
      ) {
        uploadSubtitleFile(
          videoId: $videoId
          videoCardId: $videoCardId
          srcLang: $srcLang
          label: $label
          isDefault: $isDefault
          file: $file
        ) {
          link
          uri
          videoCard {
            ...VideoCardSummary
          }
          excludeFromVimeo
        }
      }
    `,
    variables: {
      file,
      videoId,
      videoCardId,
      srcLang,
      label,
      isDefault,
    },
  });
};

const confirmHappyScribeVideoUpload = ({ adminClient, videoCardId, orgId, language }) => {
  return adminClient.mutate({
    mutation: gql`
      mutation {
        confirmHappyScribeVideoUpload(videoCardId: ${videoCardId}, orgId: ${orgId}, language: "${language}") {
          statusMessage
        }
      }
    `,
  });
};

const extractVideoIdFromVideoLink = (videoLink) => {
  const path = new URL(videoLink);
  const pathElements = path.pathname.split('/');
  return pathElements[pathElements.length - 1];
};

function SubtitleUploadInner({ item }) {
  const lang = useLanguage();
  const adminClient = useAdminApolloClient();
  const navigate = useNavigate();
  const [videoCard, setVideoCard] = useState(item);
  const [success, setSuccess] = useState(false);
  const [selectedLang, setselectedLang] = useState('');
  const [isDefault, setIsDefault] = useState(
    !(videoCard?.subtitles?.length && videoCard?.subtitles?.length > 0),
  );
  const [fileName, setfileName] = useState('');
  const videoId =
    (videoCard?.videoLink?.en && extractVideoIdFromVideoLink(videoCard?.videoLink?.en)) ??
    (videoCard?.videoLink?.sv && extractVideoIdFromVideoLink(videoCard?.videoLink?.sv)) ??
    null;

  const [happyScribeUpload, setHappyScribeUpload] = useState(false);
  const [selectedHappyScribeLang, setSelectedHappyScribeLang] = useState(null);

  const [happyScribeResponse, setHappyScribeResponse] = useState(videoCard?.happyScribeResponse);

  const [subFile, setSubFile] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  const getHappyScribeEditUrl = () => {
    return `https://www.happyscribe.com/transcriptions/${happyScribeResponse.id}/subtitles?organization_id=${happyScribeResponse.organizationId}`;
  };

  useEffect(() => {
    if (videoCard?.id) {
      getSubtitle(adminClient, videoCard?.id)
        .then((res) => {
          const videoRes = res?.data?.getSubtitle?.[0]?.videoCard;
          setVideoCard({
            ...videoRes,
            subtitleRequestId: res?.data?.getSubtitle?.[0]?.subtitleRequestId,
          });
          setHappyScribeResponse(videoRes?.happyScribeResponse);
        })
        .catch(() => {});
    }
  }, [videoCard?.id]);

  useEffect(() => {
    if (videoCard?.happyScribeResponse?.state) {
      if (!happyScribeStatuses?.[videoCard?.happyScribeResponse?.state]?.doneState) {
        const intervalIdTemp = setInterval(() => {
          getSubtitle(adminClient, videoCard?.id)
            .then((res) => {
              const videoRes = res?.data?.getSubtitle?.[0]?.videoCard;
              setHappyScribeResponse(videoRes?.happyScribeResponse);

              if (happyScribeStatuses?.[videoRes?.happyScribeResponse?.state]?.doneState) {
                setVideoCard({
                  ...videoRes,
                  subtitleRequestId: res?.data?.getSubtitle?.[0]?.subtitleRequestId,
                });

                clearInterval(intervalIdTemp);
              }
            })
            .catch((err) => {});
        }, 1000);
      }
    }
  }, [videoCard]);

  function handleSelectChange(e) {
    setselectedLang(e.target.value);
  }
  // Setting the uploaded subtitle as active
  const applySubtitle = async (uri) => {
    const data = {
      textUri: uri,
    };
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    };
    return axios
      .patch(`${backendPostPath}/subtitle-apply`, data, {
        headers,
      })
      .then((response) => {
        setfileName('');
        setSubFile(null);
        setselectedLang('');
        setError(null);
        setSuccess(true);
        setUploading(false);
        setIsDefault(false);
        setTimeout(() => {
          setSuccess(false);
        }, 6000);
      })
      .catch((err) => {
        alert('Error in applying subtitle');
      });
  };

  const handleChangeFile = (data) => {
    const { validity } = data.target;
    const file = data.target.files[0];

    if (file.size > 10000000) {
      alert('The file is too large, please select another one');
      data.target.value = null;
      setSubFile(null);
    } else if (validity.valid) {
      setSubFile(file);
    } else {
      data.target.value = null;
      setSubFile(null);
    }
    setfileName(file.name);

    if (file.name.includes(videoCard.encodedId)) {
      // empty
    } else {
      setError(SubtitleTranslation.wrongFile[lang]);
    }
  };

  // Uploading the subtitle file
  const handleSubmit = (e) => {
    e.preventDefault();
    if (subFile && selectedLang) {
      setUploading(true);
      uploadSubtitleFile({
        adminClient,
        videoId: Number(videoId),
        videoCardId: Number(videoCard.id),
        srcLang: selectedLang,
        label: SubtitleTranslation.languageAcronym[selectedLang],
        isDefault,
        file: subFile,
      })
        .then((response) => {
          const { link, uri, excludeFromVimeo } = response.data.uploadSubtitleFile;
          const updatedVideoCard = response.data.uploadSubtitleFile.videoCard;
          setVideoCard({ ...videoCard, ...updatedVideoCard });
          const headers = {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
          };

          if (!excludeFromVimeo) {
            axios
              .put(`${link}`, subFile, {
                headers,
              })
              .then((response) => {
                applySubtitle(uri);
              })
              .catch((err) => {
                setError(err);
                setUploading(false);
              });
          } else {
            setfileName('');
            setSubFile(null);
            setselectedLang('');
            setError(null);
            setSuccess(true);
            setUploading(false);
            setIsDefault(false);
            setTimeout(() => {
              setSuccess(false);
            }, 6000);
          }
        })
        .catch((err) => {
          setError(err);
          setUploading(false);
        });
    }
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const optionsVideosAWS =
    videoCard?.videoLink &&
    Object.keys(videoCard?.videoLink)

      ?.filter(
        (key) => item?.videoLink[key] && key !== 'sv' && key !== 'en' && key !== '__typename',
      )
      ?.map((key) => {
        return {
          type: key,
          value: videoCard.videoLink[key],
          label: `${SubtitleTranslation.downloadAWS[lang]} ${
            SubtitleTranslation[key] && SubtitleTranslation[key][lang]
              ? SubtitleTranslation[key][lang]
              : key
          }`,
        };
      });

  const optionsSubtitlesAWS = videoCard?.subtitles?.map((sub) => {
    return {
      type: sub.srcLang,
      value: sub.src,
      label: `${SubtitleTranslation.download[lang]} ${
        SubtitleTranslation.subtitle[sub.srcLang] && SubtitleTranslation.subtitle[sub.srcLang][lang]
          ? SubtitleTranslation.subtitle[sub.srcLang][lang]
          : sub.srcLang
      } ${SubtitleTranslation.subtitleTitle[lang]}`,
    };
  });

  const deleteRequest = (id) => {
    if (!id) {
      return alert('Could not remove Record. Contact support.');
    }
    const confirmation = window.confirm('Do you want to delete this subtitle request?');
    if (confirmation) {
      deleteSubtitle(adminClient, id)
        .then((res) => {
          navigate('/admin/subtitlerequest');
        })
        .catch((e) => {
          alert('Something went wrong. Contact support. ', e);
        });
    }
  };

  const handleDownload = (option) => {
    if (isValidHttpUrl(option.value)) {
      getVideoCardPreSignedUrlAWS({
        adminClient,
        encodedVideoCardId: item.encodedId,
        path: option.value,
        key: option.type,
      })
        .then((res) => {
          const signedUrl = res.data.getVideoCardPreSignedUrlAWS;
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = signedUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {});
    }
  };

  const updateDefaultSub = (sub) => {
    updateDefaultSubtitle({
      adminClient,
      encodedVideoCardId: videoCard.encodedId,
      defaultLang: sub.srcLang,
    })
      .then((res) => {
        const updatedVideoCard = res.data.updateDefaultSubtitle;
        setVideoCard({ ...videoCard, ...updatedVideoCard });
      })
      .catch((err) => {});
  };

  const handleConfirmHappyScribeUpload = () => {
    if (selectedHappyScribeLang?.code) {
      confirmHappyScribeVideoUpload({
        adminClient,
        videoCardId: videoCard.id,
        orgId: videoCard.orgId,
        language: selectedHappyScribeLang.code,
      })
        .then(() => {
          setHappyScribeUpload(false);
          setSelectedHappyScribeLang(null);
          const intervalIdTemp = setInterval(() => {
            getSubtitle(adminClient, videoCard?.id)
              .then((res) => {
                const videoRes = res?.data?.getSubtitle?.[0]?.videoCard;
                setHappyScribeResponse(videoRes?.happyScribeResponse);
                if (videoRes?.happyScribeResponse?.state) {
                  setVideoCard(videoRes);
                  setHappyScribeResponse(videoRes?.happyScribeResponse);
                  clearInterval(intervalIdTemp);
                }
              })
              .catch((err) => {});
          }, 1000);
        })
        .catch(() => {});
    }
  };

  const handleHappyScribeUpload = () => {
    setHappyScribeUpload(true);
  };

  const handleCloseHappyScribeUpload = () => {
    setHappyScribeUpload(false);
  };

  return (
    <div className="upload-text">
      <div className="info">
        <Alert severity="info" sx={{ width: '50%' }}>
          {SubtitleTranslation.info[lang]}
          {videoCard.encodedId}
        </Alert>
      </div>
      {success && (
        <div className="info">
          <Alert severity="info" sx={{ width: '50%' }}>
            <p>{SubtitleTranslation.FileUploaded[lang]}</p>
            <p> {SubtitleTranslation.Thanks[lang]}</p>
          </Alert>
        </div>
      )}
      {error ? (
        <div className="info">
          <Alert severity="error" sx={{ width: '50%' }}>
            {error}
          </Alert>
        </div>
      ) : null}

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '16px' }}
      >
        <Grid item>
          <Typography variant="h7" component="div">
            {SubtitleTranslation.happyScribeStatus[lang]}:
          </Typography>
        </Grid>
        <Grid item>
          {happyScribeResponse?.state ? (
            <Box>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <HappyScribeChip happyScribeResponseState={happyScribeResponse?.state} />
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={handleHappyScribeUpload}
                    href={getHappyScribeEditUrl()}
                    target="_blank"
                  >
                    {SubtitleTranslation.happyScribeEdit[lang]} <LaunchRoundedIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Chip label={SubtitleTranslation.happyScribeNoRef[lang]} />
                </Grid>
                <Grid item>
                  <Button variant="outlined" onClick={handleHappyScribeUpload}>
                    <UploadFileRounded /> {SubtitleTranslation.happyScribeUpload[lang]}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>

      <div className="upload-text-inner">
        <div className="upload-text-inner-left">
          <form method="PUT" id="upload-form" encType="multipart/form-data" onSubmit={handleSubmit}>
            <ol>
              <li>
                <span className="num">1</span>
                <b>{SubtitleTranslation.SelectLang[lang]}</b>
                <label className="type-select" id="lang-select" htmlFor="lang-select">
                  <select
                    className="lang-select"
                    value={selectedLang}
                    onChange={handleSelectChange}
                    required
                    id="lang-select"
                    htmlFor="lang-select"
                  >
                    <option value="">{SubtitleTranslation.Select[lang]}</option>
                    {Object.keys(SubtitleTranslation?.languageAcronym)
                      ?.sort((a, b) => a.localeCompare(b))
                      ?.map((key) => {
                        return (
                          <option value={key} key={key}>
                            {
                              SubtitleTranslation?.[
                                SubtitleTranslation?.languageAcronyms?.[key]?.en?.toLowerCase()
                              ]?.[lang]
                            }
                            {videoCard?.subtitles?.find((sub) => sub.srcLang === key)
                              ? ` (${SubtitleTranslation.languageReUpload[lang]})`
                              : ''}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </li>
              <li>
                <span className="num">2</span>
                <label style={{ display: 'flex', marginTop: '10px' }}>
                  <input
                    type="checkbox"
                    checked={isDefault}
                    disabled={!videoCard?.subtitles?.length}
                    onChange={() => setIsDefault(!isDefault)}
                  />
                  <b>{SubtitleTranslation.subtitleShouldBeDefault[lang]}</b>
                </label>
              </li>
              <li>
                <span className="num">3</span>
                <span>
                  <b>{SubtitleTranslation.SelectSubtitle[lang]}</b>
                  <b>
                    {SubtitleTranslation.FileFormat[lang]} <strong> Webvtt</strong> (.vtt)
                  </b>
                </span>
              </li>
            </ol>

            <div>
              <label
                htmlFor="file"
                className={`upload-area ${subFile && selectedLang && 'file-selected'}`}
              >
                {subFile ? (
                  <>
                    <img src="/images/icons/subtitles-icon.svg" alt="subtitle" />
                    <b>{fileName}</b>
                  </>
                ) : (
                  <>
                    <img src="/images/icons/subtitles-icon.svg" alt="subtitle" />
                    <b>{SubtitleTranslation.clickToSelect[lang]}</b>
                    <p>{SubtitleTranslation.wereExcited[lang]}</p>
                  </>
                )}
                <input
                  onChange={handleChangeFile}
                  type="file"
                  accept="text/vtt"
                  name="file_data"
                  id="file"
                  required
                />
              </label>
              <Button
                variant="contained"
                disableElevation
                type="submit"
                disabled={!subFile || !selectedLang || uploading}
              >
                {SubtitleTranslation.Upload[lang]}
              </Button>
            </div>
          </form>
        </div>
        <div className="upload-text-inner-right">
          <Typography variant="h7" component="div">
            <strong>{SubtitleTranslation.videoTitle[lang]}:</strong>
          </Typography>
          <Typography variant="h7" component="div">
            {videoCard?.question?.sv || videoCard?.question?.en}
          </Typography>
          {videoId ? (
            <>
              <Vimeo video={videoId} width="340" height="605" dnt />
              <div style={{ display: 'flex' }}>
                <a
                  href={`${backendPostPath}/video/${videoCard.id}/download`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn icon-btn"
                >
                  <i className="icon">arrow_downward</i>
                  {SubtitleTranslation.downloadVimeo[lang]}
                </a>
              </div>
            </>
          ) : (
            <video width="340" height="605" controls crossOrigin="anonymous">
              <source src={videoCard?.videoLink?.FHD} type="video/mp4" />
              <source src={videoCard?.videoLink?.HD} type="video/mp4" />
              <source src={videoCard?.videoLink?.qHD} type="video/mp4" />
              <source src={videoCard?.videoLink?.nHD} type="video/mp4" />
              <source src={videoCard?.videoLink?.ntsc} type="video/mp4" />
              {videoCard?.subtitles?.map((sub) => {
                return (
                  <track
                    key={sub.srclang}
                    src={sub.src}
                    label={sub.label}
                    kind="captions"
                    srcLang={sub.srclang}
                    default={sub.default}
                  />
                );
              })}
            </video>
          )}
          <Typography variant="h7" component="div">
            <strong>{SubtitleTranslation.actorName[lang]}:</strong>{' '}
            {videoCard?.recipient?.name ?? videoCard?.actorName ?? ' n/a ??'}
          </Typography>
          <Typography variant="h7" component="div">
            <strong>{SubtitleTranslation.actorTitle[lang]}:</strong>{' '}
            {videoCard?.recipient?.title ??
              videoCard?.actorTitle?.sv ??
              videoCard?.actorTitle?.en ??
              ' n/a ??'}
          </Typography>
          <SplitButton
            options={optionsVideosAWS}
            menuPlacement="top-end"
            handleClick={(option) => {
              handleDownload(option);
            }}
            disableElevation
          />
        </div>
      </div>
      <div
        style={{
          paddingBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          margin: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {videoCard?.subtitles?.length > 0 ? (
          <span>
            {videoCard?.subtitles?.map((sub) => (
              <div key={sub.id} style={{ display: 'flex' }}>
                <b>
                  {SubtitleTranslation.languageAcronyms[sub?.srcLang][lang] ?? sub}(
                  {SubtitleTranslation.languageAcronyms[sub?.srcLang][sub?.srcLang] ?? ''}
                  {') '}
                  {SubtitleTranslation.languageUploaded[lang]}
                  {sub?.default ? ', is default' : ''}
                </b>
                {!sub.default && (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => updateDefaultSub(sub)}
                  >
                    {SubtitleTranslation.setDefault[lang]} {sub?.srcLang}
                  </Button>
                )}
              </div>
            ))}
            <div style={{ paddingTop: '20px' }}>
              <SplitButton
                options={optionsSubtitlesAWS}
                handleClick={(option) => {
                  handleDownload(option);
                }}
                disableElevation
              />
            </div>
          </span>
        ) : (
          <span>
            <b>{SubtitleTranslation.noUploadedSubtitles[lang]}</b>
          </span>
        )}
      </div>
      <div
        style={{
          paddingBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          margin: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={() => deleteRequest(videoCard.subtitleRequestId)}
        >
          {SubtitleTranslation.completed[lang]}
        </Button>
      </div>
      {happyScribeUpload && !videoCard?.happyScribeResponse?.state && (
        <Dialog
          open={happyScribeUpload}
          onClose={handleCloseHappyScribeUpload}
          sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
          <DialogTitle>{SubtitleTranslation.happyScribeUpload[lang]}</DialogTitle>
          <DialogContent>
            <DialogContentText>{SubtitleTranslation.happyScribeUploadText[lang]}</DialogContentText>
            <Autocomplete
              value={selectedHappyScribeLang}
              onChange={(event, newValue) => {
                setSelectedHappyScribeLang(newValue);
              }}
              disablePortal
              options={fetchActiveLanguages()?.map((languageObj) => ({
                ...languageObj,
                label: `${languageObj.language} - ${languageObj.code}`,
              }))}
              renderInput={(params) => {
                return (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                  />
                );
              }}
            />
          </DialogContent>
          <DialogActions>
            <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <Button
                  onClick={handleCloseHappyScribeUpload}
                  color="error"
                  variant="contained"
                  disableElevation
                >
                  {SubtitleTranslation.cancel[lang]}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disableElevation
                  disabled={videoCard?.happyScribeResponse?.state}
                  onClick={handleConfirmHappyScribeUpload}
                >
                  {SubtitleTranslation.confirmUpload[lang]}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <MadeWithLoveFooter />
    </div>
  );
}
