import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BasicOrgData } from './types';
import useUserData from '../../userDataHook/userDataHook';

interface UseCheckPermissionsAndNavigateProps {
  basicOrgData: BasicOrgData;
  navigateBackOfficePath: string;
}

const useCheckPermissionsAndNavigate = ({
  basicOrgData,
  navigateBackOfficePath,
}: UseCheckPermissionsAndNavigateProps) => {
  const navigate = useNavigate();
  const encodedOrgId = basicOrgData.encodedId;
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    isSuperAdmin,
  } = useUserData(encodedOrgId);

  const effectiveUserData = userData?.me;

  const navigatePath = `/${basicOrgData.orgNameSlug}/backoffice/${navigateBackOfficePath}`;

  useEffect(() => {
    if (!userLoading && basicOrgData.premiumFeatures?.organizationPermissionsSettings) {
      const hasOrgAdminPermission =
        effectiveUserData?.userPermissions.some(
          (permission) => permission.orgId === basicOrgData.id && permission.name === 'org_admin',
        ) === true;

      const hasRequiredPermission = isSuperAdmin || hasOrgAdminPermission;

      if (!hasRequiredPermission) {
        navigate(navigatePath);
      }
    }
  }, [userLoading, basicOrgData, effectiveUserData, isSuperAdmin, navigate, navigatePath]);

  return { data: userData, loading: userLoading, error: userError, isSuperAdmin };
};

export default useCheckPermissionsAndNavigate;
