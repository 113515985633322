import React, { useState, useContext } from 'react';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { useLanguage } from 'src/context/LanguageContext';
import AlertDialog from '../common/AlertDialog';
import { translations } from '../../utils/translations';
import translationsEndScreen from '../../utils/translationsEndScreen';
import { useEndScreensApi } from '../../api/endScreens';
import EndScreenDialog from './EndScreen/EndScreenDialog';
import { EndScreenTable } from './EndScreen/EndScreenTable';
import ThemeContext from '../../utils/ThemeContext';

export default function EndScreens() {
  const endScreensApi = useEndScreensApi();
  const lang = useLanguage();
  const { basicOrgData, endScreens: endScreenContext } = useOutletContext();
  const theme = useContext(ThemeContext);
  const [allEndScreen, setAllEndScreens] = useState([
    ...(endScreenContext?.length > 0 ? endScreenContext : []),
  ]);
  const [deleteEndScreenConfirmDialog, setDeleteEndScreenConfirmDialog] = useState(false);

  const [hoveredRow, setHoveredRow] = useState(null);
  const [createEndScreenDialog, setCreateEndScreenDialog] = useState(false);
  const [editEndScreenDialog, setEditEndScreenDialog] = useState(false);

  const [setError, setErrors] = useState(null);
  const [setInfo, setInfos] = useState(null);
  const [deleteEndScreenDialog, setDeleteEndScreenDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchField, setSearchField] = useState('');
  const orgHasEndScreen = basicOrgData?.premiumFeatures?.interactionOption ?? false;
  if (!orgHasEndScreen) {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice`);
  }

  const handleConfirmDelete = () => {
    if (deleteEndScreenDialog?.id) {
      endScreensApi
        .deleteEndScreen({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          id: deleteEndScreenDialog.id,
          deleteConfirmed: true,
        })
        .then((res) => {
          handleCloseEndScreen({ deletedId: deleteEndScreenDialog.id });
        })
        .catch((err) => {});
    }
  };

  const handleDelete = () => {
    if (deleteEndScreenDialog?.id) {
      endScreensApi
        .deleteEndScreen({
          encodedOrgId: basicOrgData.encodedId,
          orgId: basicOrgData.id,
          id: deleteEndScreenDialog.id,
        })
        .then((res) => {
          const videoFunnelCount = res?.length ?? 0;
          const videoFunnelEdgeCount = res
            ?.map((vf) => vf?.videoFunnelEdges?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          const videoFunnelNodeCount = res
            ?.map((vf) => vf?.videoFunnelNodes?.length ?? 0)
            .reduce((a, b) => a + b, 0);

          if (videoFunnelCount > 0 || videoFunnelEdgeCount > 0 || videoFunnelNodeCount > 0) {
            setDeleteEndScreenConfirmDialog(
              translationsEndScreen.deleteInVideoFunnel[lang]({
                videoFunnelCount,
                videoFunnelEdgeCount,
                videoFunnelNodeCount,
                videoFunnelTitles: res?.map((vf) => vf?.title),
              }),
            );
          } else {
            handleCloseEndScreen({ deletedId: deleteEndScreenDialog.id });
          }
        })
        .catch((err) => {});
    }
  };

  const handleCloseEndScreen = ({ newEndScreen, editEndScreen, deletedId }) => {
    setCreateEndScreenDialog(false);
    setEditEndScreenDialog(false);
    setDeleteEndScreenConfirmDialog(false);
    if (newEndScreen) {
      setAllEndScreens([...allEndScreen, newEndScreen]);
    } else if (editEndScreen) {
      const newEndScreens = [...allEndScreen];
      const index = newEndScreens.findIndex((en) => en.id === editEndScreen.id);
      newEndScreens[index] = editEndScreen;
      setAllEndScreens(newEndScreens);
    } else if (deletedId) {
      const newEndScreens = [...allEndScreen];

      setAllEndScreens(newEndScreens?.filter((en) => en.id !== deletedId));

      setDeleteEndScreenDialog(false);
    }
  };

  const handleClickCreateEndScreen = () => {
    setCreateEndScreenDialog(true);
  };

  return (
    <Box sx={{ margin: '20px', marginRight: '120px', marginTop: '32px' }}>
      {createEndScreenDialog && (
        <EndScreenDialog
          open={!!createEndScreenDialog}
          handleClose={handleCloseEndScreen}
          basicOrgData={basicOrgData}
          orgHasEndScreen={orgHasEndScreen}
          setError={setError}
          setInfo={setInfo}
        />
      )}
      {editEndScreenDialog && (
        <EndScreenDialog
          open={!!editEndScreenDialog}
          handleClose={handleCloseEndScreen}
          basicOrgData={basicOrgData}
          endScreenItem={editEndScreenDialog}
          orgHasEndScreen={orgHasEndScreen}
          setError={setError}
          setInfo={setInfo}
        />
      )}
      {deleteEndScreenDialog && (
        <AlertDialog
          open={!!deleteEndScreenDialog?.id}
          title={translationsEndScreen.deleteTitle[lang]}
          description={translationsEndScreen.deleteDescription[lang]}
          confirmBtnText={translationsEndScreen.deleteConfirmBtnText[lang]}
          cancelBtnText={translationsEndScreen.deleteCancelBtnText[lang]}
          onConfirm={() => {
            handleDelete();
            setDeleteEndScreenDialog(false);
          }}
          onCancel={(e) => {
            setDeleteEndScreenDialog(false);
          }}
        />
      )}
      {deleteEndScreenConfirmDialog && (
        <AlertDialog
          open={!!deleteEndScreenConfirmDialog}
          title={translationsEndScreen.confirmDeleteTitle[lang]}
          description={deleteEndScreenConfirmDialog}
          confirmBtnText={translationsEndScreen.deleteConfirmBtnText[lang]}
          cancelBtnText={translationsEndScreen.deleteCancelBtnText[lang]}
          onConfirm={() => {
            handleConfirmDelete();
            setDeleteEndScreenDialog(false);
            setDeleteEndScreenConfirmDialog(false);
          }}
          onCancel={(e) => {
            setDeleteEndScreenDialog(false);
            setDeleteEndScreenConfirmDialog(false);
          }}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction="row" spacing={2}>
              <Typography variant="headlineLargeBoldRecoleta">
                {translations.endScreens[lang]}
              </Typography>

              <Button
                variant="text"
                disableElevation
                startIcon={<AddIcon />}
                onClick={handleClickCreateEndScreen}
              >
                {translationsEndScreen.createEndScreenShort[lang]}
              </Button>
            </Stack>
            <FormControl sx={{ m: 1, minWidth: 400, marginLeft: 'auto', marginRight: '0px' }}>
              <TextField
                type="search"
                fullWidth
                variant="outlined"
                placeholder={translations.search[lang]}
                value={searchField}
                onChange={(e) => setSearchField(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: 50, // Rounded borders
                    paddingRight: 12, // Padding right
                    backgroundColor: theme.palette.background.paper,
                    // You can add more styles to match the design
                  },
                }}
                // Additional styles can be applied here to match the uploaded design
                sx={{
                  background: 'transparent', // Assuming a white background
                  padding: '0', // Adjust padding to match the design
                  // Add more styles as needed
                }}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '16px' }}>
          <EndScreenTable
            hoveredRow={hoveredRow}
            setHoveredRow={setHoveredRow}
            setEditEndScreenDialog={setEditEndScreenDialog}
            setDeleteEndScreenDialog={setDeleteEndScreenDialog}
            searchField={searchField}
            allEndScreen={allEndScreen}
            setAllEndScreens={setAllEndScreens}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
