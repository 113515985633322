import { isValidDate } from 'src/utils/utils';
import formatDateString from './formatDateString';

export interface StartEndDate {
  start: string;
  end: string;
}

export interface DailyData {
  date: string;
  count: number;
}

export interface DailyDataByType {
  [key: string]: DailyData[];
}

export interface DataStructure {
  [key: string]: {
    currentMonthDaily: DailyData[];
  };
}

export const parseDate = (dateString: string): Date | null => {
  // Attempt to handle as timestamp first
  const timestamp = Number(dateString);
  if (!Number.isNaN(timestamp)) {
    const dateFromTimestamp = new Date(timestamp);
    if (isValidDate(dateFromTimestamp)) {
      // Normalize to UTC midnight
      const utcDate = new Date(
        Date.UTC(
          dateFromTimestamp.getUTCFullYear(),
          dateFromTimestamp.getUTCMonth(),
          dateFromTimestamp.getUTCDate(),
        ),
      );
      return utcDate;
    }
  }

  // If the above fails or isn't a timestamp, attempt to parse as ISO date string
  const dateFromISO = new Date(dateString);
  if (isValidDate(dateFromISO)) {
    // Ensure the date is treated as UTC if it has timezone info
    const utcDate = new Date(
      Date.UTC(dateFromISO.getUTCFullYear(), dateFromISO.getUTCMonth(), dateFromISO.getUTCDate()),
    );
    return utcDate;
  }

  return null;
};

const formatDailyData = (startEndDate: StartEndDate, d: DataStructure): DailyDataByType => {
  if (!d) return {};

  const startDate = new Date(Date.parse(startEndDate.start));
  const endDate = new Date(Date.parse(startEndDate.end));

  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(0, 0, 0, 0);

  const dailyDataByType: DailyDataByType = {};

  Object.entries(d).forEach(([type, data]) => {
    const dateMap = new Map<string, number>();

    if (!data.currentMonthDaily) {
      dailyDataByType[type] = [];
      return;
    }
    data.currentMonthDaily.forEach((entry) => {
      const date = parseDate(entry.date);

      if (date && date >= startDate && date <= endDate) {
        const dateString = formatDateString(date);
        const currentCount = dateMap.get(dateString) || 0;
        dateMap.set(dateString, currentCount + entry.count);
      }
    });

    const results: DailyData[] = [];
    for (let tmp = new Date(startDate); tmp <= endDate; tmp.setUTCDate(tmp.getUTCDate() + 1)) {
      const formattedDate = formatDateString(tmp);

      results.push({
        date: formattedDate,
        count: dateMap.get(formattedDate) || 0,
      });
    }

    dailyDataByType[type] = results;
  });

  return dailyDataByType;
};

export default formatDailyData;
