import { FormControl, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { useLanguage, useSetLanguage } from 'src/context/LanguageContext';

const translations = {
  language: {
    english: 'English',
    svenska: 'Svenska',
  },
  ariaLabel: {
    en: 'Select language',
    sv: 'Välj språk',
  },
};

function LanguageSelector() {
  const lang = useLanguage();
  const setLanguage = useSetLanguage();

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        color: 'white',
        paddingLeft: 8,
        paddingRight: 8,
      }}
      data-testid="language-container"
    >
      <img
        src="/globe-icon.svg"
        alt="Globe"
        style={{ width: 18, height: 18 }}
        data-testid="globe-icon"
      />
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={lang}
          onChange={(e) => setLanguage(e.target.value as Language)}
          displayEmpty
          inputProps={{ 'aria-label': translations.ariaLabel[lang] }}
          sx={{
            color: 'white',
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          data-testid="language-select"
        >
          <MenuItem value="en" data-testid="menu-item-lang-en">
            {translations.language.english}
          </MenuItem>
          <MenuItem value="sv" data-testid="menu-item-lang-sv">
            {translations.language.svenska}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default LanguageSelector;
