/* eslint-disable import/prefer-default-export */
import Tooltip from '@mui/material/Tooltip';
import { Handle } from 'reactflow';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';

export function HandleToolTip(props) {
  const {
    type,
    position,
    id,
    isConnectable,
    isConnectableStart,
    isConnectableEnd,
    isValidConnection,
    className,
    hasSourceConnectionHandel,
  } = props;
  const lang = useLanguage();

  return (
    <Tooltip
      title={
        !hasSourceConnectionHandel
          ? translationsStoryline.videoFunnel.tooltips.handleTooltip[lang]
          : ''
      }
      enterDelay={500}
      enterNextDelay={100}
    >
      <Handle
        type={type}
        position={position}
        id={id}
        isConnectable={isConnectable}
        isConnectableStart={isConnectableStart}
        isConnectableEnd={isConnectableEnd}
        isValidConnection={isValidConnection}
        className={className}
      >
        {props?.children}
      </Handle>
    </Tooltip>
  );
}
