import { useState, useRef } from 'react';
import { Position, Handle, useReactFlow } from 'reactflow';
import { useOutletContext } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import VideoFunnelAddVideoDialog from './VideoFunnelAddVideoDialog';

const maxVideo = 1;

function VideoFunnelAddNode(props) {
  const { data, isConnectable, type, id } = props;
  const lang = useLanguage();
  const { basicOrgData } = useOutletContext();
  const containerRef = useRef(null);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const { getEdges, getNodes, setNodes } = useReactFlow();

  const handleOnCloseAddVideo = ({ newVideoCards }) => {
    setOpenAddVideo(false);
    if (newVideoCards?.length === maxVideo) {
      const videoCard = newVideoCards[0];
      setNodes((els) => {
        const nodes = els;

        const node = nodes?.find((n) => n.id === id);

        const newNodes = nodes?.filter((n) => n.id !== id);

        return [
          ...(newNodes?.length
            ? newNodes?.map((n) => ({ ...n, data: { ...n?.data, shouldCheckConnections: true } }))
            : []),
          {
            ...node,
            type: 'videoFunnelVideoNode',
            data: {
              label: videoCard?.question?.[lang],
              id: id,
              videoCard: videoCard,
              shouldCheckConnections: true,
            },
            newNode: true,
          },
        ];
      });
    }
  };

  return (
    <div className={`videoFunnel-add-node`}>
      <Handle id="a" type="target" position={Position.Left} isConnectable={isConnectable} />
      <div className="videoFunnel-add-node-inner">
        <b>{translationsStoryline.videoFunnel.addVideoCTAEndScreen[lang]}</b>
        <Button color="secondary" startIcon={<AddIcon />} onClick={() => setOpenAddVideo(true)}>
          {translationsStoryline.videoFunnel.selectVideo[lang]}
        </Button>
      </div>
      <VideoFunnelAddVideoDialog
        open={openAddVideo}
        handleClose={handleOnCloseAddVideo}
        containerRef={containerRef}
        basicOrgData={basicOrgData}
        maxVideos={maxVideo}
      />
    </div>
  );
}
export default VideoFunnelAddNode;
