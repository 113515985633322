const translationsAnalytics = {
  analytics: {
    en: 'Analytics',
    sv: 'Analys',
  },
  durationDays: {
    en: (days) => `Past ${days} day${days > 1 ? 's' : ''}`,
    sv: (days) => `Senaste ${days} dag${days > 1 ? 'ar' : ''}`,
  },
  startDate: {
    en: 'Start date',
    sv: 'Startdatum',
  },
  endDate: { en: 'End date', sv: 'Slutdatum' },
  durationLifetime: {
    en: 'Life time',
    sv: 'Livstid',
  },
  durationCustom: {
    en: 'Custom',
    sv: 'Anpassad',
  },

  timeCard: {
    average: {
      en: 'Avg view Time',
      sv: 'Genomsnittlig visningstid',
    },
    total: {
      en: 'Total View Time',
      sv: 'Total Visningstid',
    },
    interactions: {
      en: 'Total Interactions',
      sv: 'Interaktioner',
    },
  },

  unit: {
    hours: {
      en: 'hrs',
      sv: 'h',
    },
    clicks: {
      en: 'clicks',
      sv: 'klickar',
    },
  },

  totalImpressionsCard: {
    impressions: {
      en: 'Impressions',
      sv: 'Presenterade',
    },
    plays: {
      en: 'Plays',
      sv: 'Spelningar',
    },
    error: {
      en: 'No data available or data fetching timed out.',
      sv: 'Ingen data tillgänglig eller datahämtning timade ut.',
    },
    title: {
      summary: {
        en: (impressions, plays, playRate) =>
          `${impressions} Impressions and ${plays} Plays. That is an ${playRate}% play rate!`,
        sv: (impressions, plays, playRate) =>
          `${impressions} presenterade och ${plays} spelningar. Det är en ${playRate}% spelgrad!`,
      },
    },
  },

  topCharts: {
    videos: {
      en: 'Top Videos',
      sv: 'Toppvideor',
    },
    stories: {
      en: 'Top Stories',
      sv: 'Topp Stories',
    },
    endscreen: {
      en: 'Top EndScreens',
      sv: 'Topp EndScreen',
    },
  },

  bestPerformance: {
    title: {
      en: 'Best Performance:',
      sv: 'Bäst presterande:',
    },
    metricName: {
      engagement: {
        en: 'Engagement ',
        sv: 'Engagemang',
      },
      avgViewTime: {
        en: 'Avg view time',
        sv: 'Genomsnittlig visningstid',
      },
      totalImpressions: {
        en: 'Total Impressions',
        sv: 'Total visningar',
      },
      totalViewTime: {
        en: 'Total view time',
        sv: 'Total visningstid',
      },
    },
  },
  tooltips: {
    impressions: {
      en: 'Impressions refer to the number of times a video is shown to viewers. This includes instances when the video is visible on your webpage or in search results, but it doesn’t necessarily mean the video was played.',
      sv: 'Visningar avser antalet gånger en video visas för tittarna. Detta inkluderar tillfällen när videon är synlig på din webbsida eller i sökresultat, men det betyder inte nödvändigtvis att videon spelades upp.',
    },
    plays: {
      en: 'Plays track each instance when a video is started. Whether a viewer clicks to play, pauses and then resumes, each action is recorded as a separate play.',
      sv: 'Spelningar spårar varje gång en video startas. Oavsett om en tittare klickar för att spela, pausar och sedan återupptar, registreras varje åtgärd som en separat spelning.',
    },
    playRate: {
      en: 'Play Rate is the ratio of Plays to Impressions. It is calculated by dividing the number of Plays by the number of Impressions, providing insight into how often viewers who see the video actually choose to watch it.',
      sv: 'Spelningsfrekvens är förhållandet mellan Spelningar och Visningar. Det beräknas genom att dela antalet Spelningar med antalet Visningar, vilket ger insikt i hur ofta tittare som ser videon faktiskt väljer att titta på den.',
    },
    viewTime: {
      en: 'View Time refers to the total amount of time viewers have spent watching the video.',
      sv: 'Visningstid avser den totala tiden som tittare har spenderat på att titta på videon.',
    },
  },
};

export default translationsAnalytics;
