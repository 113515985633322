import { Outlet, Route } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { usePublicApolloClient } from 'src/context/ApolloClientContext';
import publicRoutes from './PublicRoutes';
import authenticatedRoutes from './AuthenticatedRoutes';

function DefaultPublicApolloClientProvider({ children }: { children: React.ReactElement }) {
  const publicClient = usePublicApolloClient();
  return <ApolloProvider client={publicClient}>{children}</ApolloProvider>;
}

const OutletWithAuthenticationRequired = withAuthenticationRequired(Outlet);

function AuthenticatedRoutesOutlet() {
  return <OutletWithAuthenticationRequired />;
}

function PublicRoutesOutlet() {
  return (
    <DefaultPublicApolloClientProvider>
      <Outlet />
    </DefaultPublicApolloClientProvider>
  );
}

const appRoutes = (
  <>
    <Route element={<AuthenticatedRoutesOutlet />}>{authenticatedRoutes}</Route>
    <Route element={<PublicRoutesOutlet />}>{publicRoutes}</Route>

    {/* auth0-callback is the route rendered while app determines where to redirect
        users to after login, ideally it renders a skeleton view of the "next" page */}
    <Route path="/auth0-callback" />
    <Route path="/login/callback" />
  </>
);

export default appRoutes;
