import React, { useState, useCallback } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Backdrop,
} from '@mui/material';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import TablePagination from 'src/components/common/TablePagination';
import { translations } from './translations';
import { Context, PermissionType } from './types';
// import useUserData from '../userDataHook/userDataHook';
import UserRow from './UserRow';
import useOrgUsers from './useOrgUsers';
import useUpdatePermission from './useUpdatePermission';
import useCheckPermissionsAndNavigate from './CheckPermissionsAndNavigate';

function Permissions() {
  const lang = useLanguage();
  const { basicOrgData }: Context = useOutletContext<Context>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  if (!basicOrgData?.premiumFeatures?.organizationPermissionsSettings === true) {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/settings`);
  }

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    isSuperAdmin,
  } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/recipients',
  });

  const {
    orgUsers,
    loading: orgUsersLoading,
    error: errorOrgUsers,
    refetch,
  } = useOrgUsers({ encodedOrgId: basicOrgData.encodedId, userDataExists: !!userData });

  const error = errorOrgUsers || userError;

  const { updatePermission } = useUpdatePermission();

  const handlePermissionChange = useCallback(
    async (updateUserId: number, newPermission: PermissionType) => {
      try {
        await updatePermission({
          variables: {
            encodedOrgId: basicOrgData.encodedId,
            updateUserId,
            permission: newPermission,
          },
        });
        refetch();
      } catch (err) {
        // console.error('Update permission error:', err);
      }
    },
    [updatePermission, basicOrgData.encodedId, refetch],
  );

  const loading = orgUsersLoading || userLoading;

  const disablePermissionChange = useCallback(() => {
    const userPermission = userData?.me?.userPermissions?.[0]?.name;
    return !(isSuperAdmin || userPermission === 'org_admin');
  }, [userData, isSuperAdmin]);

  if (error || !userData || loading || !orgUsers) {
    return <LoadingIndicator data-testid="loading-indicator" />;
  }

  return (
    <Box sx={{ p: 0, pt: 4, position: 'relative' }} data-testid="permissions-box">
      <Backdrop
        open={loading}
        style={{ opacity: 0.2 }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        aria-label="Loading data"
      />
      {loading && (
        <Box
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          data-testid="central-loading-indicator"
        >
          <LoadingIndicator backgroundColor="transparent" />
        </Box>
      )}
      <TableContainer component={Paper} sx={{ mt: 4, px: 1, py: 2 }} data-testid="table-container">
        <Table aria-label="Organization Users Table" data-testid="users-table">
          <TableHead data-testid="table-head">
            <TableRow data-testid="table-head-row">
              <TableCell data-testid="table-head-cell-first-name">
                {translations.firstName[lang]}
              </TableCell>
              <TableCell data-testid="table-head-cell-last-name">
                {translations.lastName[lang]}
              </TableCell>
              <TableCell data-testid="table-head-cell-email">{translations.email[lang]}</TableCell>
              <TableCell data-testid="table-head-cell-permissions">
                {translations.permissions[lang]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="table-body">
            {orgUsers.length > 0 ? (
              orgUsers.map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  onPermissionChange={handlePermissionChange}
                  disableChange={disablePermissionChange()}
                  lang={lang}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>{translations.noUsersFound[lang]}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          lang={lang}
          count={orgUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          data-testid="pagination-control"
        />
      </TableContainer>
    </Box>
  );
}

export default Permissions;
