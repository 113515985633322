import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';
import { VIDEO_CARD_DETAILS_FRAGMENT } from '../../../fragments/videoCards';
import { VIDEO_REQUEST_DETAILS_FRAGMENT } from '../../../fragments/videoRequests';

export class RequestsApi {
  #backOfficeClient;

  /**
   * @param {import('src/context/ApolloClientContext').ApolloClient} backOfficeClient
   */
  constructor(backOfficeClient) {
    this.#backOfficeClient = backOfficeClient;
  }

  getRequestsMinimal({ encodedOrgId, orgId }) {
    return this.#backOfficeClient.query({
      query: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        ${VIDEO_REQUEST_DETAILS_FRAGMENT}
        query RequestsMinimal($orgId: Int!, $encodedOrgId: String!) {
          requestsMinimal(orgId: $orgId, encodedOrgId: $encodedOrgId) {
            videoCards {
              ...VideoCardDetails
            }
            videoRequests {
              ...VideoRequestDetails
            }
            tagCategories {
              id
              name {
                sv
                en
              }
              tags {
                id
                name {
                  sv
                  en
                }
                isHidden
              }
            }
          }
        }
      `,
      variables: { orgId, encodedOrgId },
    });
  }

  getRequestsExtended({ encodedOrgId, orgId }) {
    return this.#backOfficeClient.query({
      query: gql`
        query RequestsExtended($orgId: Int!, $encodedOrgId: String!) {
          requestsExtended(orgId: $orgId, encodedOrgId: $encodedOrgId) {
            users {
              id
              firstName
              lastName
            }
            orgQuestions {
              id
              question
              categoryId
              forLeaders
              forTeamMember
              created
            }
            recipients {
              id
              orgId
              firstName
              lastName
              name
              phoneNumber
              email
              title
              receiveAutoRequests
              leader
            }
          }
        }
      `,
      variables: { orgId, encodedOrgId },
    });
  }

  getVideoRequest(encodedOrgId, videoRequestId) {
    return this.#backOfficeClient.query({
      query: gql`
        ${VIDEO_REQUEST_DETAILS_FRAGMENT}
        query VideoRequest($encodedOrgId: String!, $videoRequestId: Int!) {
          videoRequest(encodedOrgId: $encodedOrgId, id: $videoRequestId) {
            ...VideoRequestDetails
          }
        }
      `,
      variables: { encodedOrgId, videoRequestId },
    });
  }

  getVideoCard(encodedOrgId, videoCardId) {
    return this.#backOfficeClient.query({
      query: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        query VideoCard($encodedOrgId: String!, $videoCardId: Int!) {
          videoCard(encodedOrgId: $encodedOrgId, id: $videoCardId) {
            ...VideoCardDetails
          }
        }
      `,
      variables: { encodedOrgId, videoCardId },
    });
  }

  updateVideoCardStatus(encodedOrgId, orgId, videoCardId, status) {
    const input = { id: videoCardId, orgId, status };
    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${VIDEO_CARD_DETAILS_FRAGMENT}
        mutation UpdateVideoCardStatus($encodedOrgId: String, $input: VideoCardInput) {
          updateVideoCardStatus(encodedOrgId: $encodedOrgId, input: $input) {
            ...VideoCardDetails
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  createNewTag(encodedOrgId, lang, orgId, categoryId, name) {
    const translatedName = { [lang]: name };
    const input = { orgId, categoryId, name: translatedName };
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation CreateTag($encodedOrgId: String, $input: TagInput) {
          createTag(encodedOrgId: $encodedOrgId, input: $input) {
            id
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  deleteTag(encodedOrgId, tagId) {
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation DeleteTag($encodedOrgId: String, $tagId: Int) {
          deleteTag(encodedOrgId: $encodedOrgId, tagId: $tagId) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, tagId },
    });
  }

  deleteVideoCard(encodedOrgId, videoCardId, orgId) {
    const input = {
      id: videoCardId,
      orgId,
    };
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation DeleteVideoCard($encodedOrgId: String, $input: VideoCardInput) {
          deleteVideoCard(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  deleteVideoRequest(encodedOrgId, videoRequestId, orgId) {
    const input = {
      id: videoRequestId,
      orgId,
    };
    return this.#backOfficeClient.mutate({
      mutation: gql`
        mutation DeleteVideoRequest($encodedOrgId: String, $input: VideoRequestInput) {
          deleteVideoRequest(encodedOrgId: $encodedOrgId, input: $input) {
            statusMessage
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }
}

export const useRequestsApi = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new RequestsApi(backOfficeClient), [backOfficeClient]);
};
