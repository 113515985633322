import React, { useContext, useMemo, useCallback, useId } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import SearchOutlinedIcon from '../icons/search/SearchOutlined';
import ThemeContext from '../../../utils/ThemeContext';

function SearchField({
  placeholder = '',
  value,
  onChange,
  sx = {},
  variant = 'outlined',
  autoComplete = 'off',
  ariaLabel = '',
  disabled = false,
}) {
  const theme = useContext(ThemeContext);
  const uniqueId = useId();

  const handleChange = useCallback(
    (e) => {
      onChange(e);
    },
    [onChange],
  );

  const inputProps = useMemo(
    () => ({
      startAdornment: <SearchOutlinedIcon fill={theme.palette.navbar.button} />,
      style: {
        borderRadius: 50,
        paddingRight: 12,
        backgroundColor: theme.palette.background.paper,
      },
    }),
    [theme],
  );

  return (
    <TextField
      fullWidth
      variant={variant}
      placeholder={placeholder ?? ''}
      value={value}
      onChange={handleChange}
      autoComplete={autoComplete}
      InputProps={inputProps}
      disabled={disabled}
      data-testid="search-text-field"
      type="search"
      role="search"
      name={uniqueId}
      aria-label={ariaLabel || placeholder}
      id={uniqueId}
      sx={{
        background: 'transparent',
        padding: 0,
        minWidth: '300px',
        ...sx,
      }}
    />
  );
}

SearchField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  sx: PropTypes.shape({}),
  autoComplete: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchField;
