import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function HeaderTitle({ title, style }) {
  return (
    <Typography variant="headlineMedium" style={style}>
      {title}
    </Typography>
  );
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
