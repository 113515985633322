import { useState, forwardRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import SimpleDialogTitle from '../../common/SimpleDialogTitle';
import SimpleDialogActions from '../../common/SimpleDialogAction';
import VideoFunnelVideoLibrary from './VideoFunnelVideoLibrary';

import '../back-office.scss';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} style={{ transformOrigin: '15% 25% 0' }} />;
});

export default function VideoFunnelAddVideoDialog({
  open,
  handleClose,
  onConfirm,
  containerRef,
  basicOrgData,
  maxVideos,
}) {
  const lang = useLanguage();
  const [selectedVideoCards, setSelectedVideoCards] = useState([]);
  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    setSelectedVideoCards([]);
  }, []);

  const handleOnClose = ({ newVideoCards }) => {
    setSelectedVideoCards([]);
    handleClose({ newVideoCards });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      TransitionComponent={Transition}
      ref={containerRef}
      maxWidth="xl"
      PaperProps={{
        style: { borderRadius: '20px', minHeight: 'calc(100vh - 64px)' },
      }}
      fullWidth
    >
      <SimpleDialogTitle
        title={translationsStoryline.videoFunnel.addVideo[lang]}
        searchField={searchField}
        setSearchField={setSearchField}
        handleOnClose={handleOnClose}
      />
      <DialogContent>
        <VideoFunnelVideoLibrary
          basicOrgData={basicOrgData}
          selectedVideoCards={selectedVideoCards}
          setSelectedVideoCards={setSelectedVideoCards}
          maxVideos={maxVideos}
          searchField={searchField}
        />
      </DialogContent>
      <SimpleDialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          {translationsStoryline.videoFunnel.cancel[lang]}
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={() => handleOnClose({ newVideoCards: selectedVideoCards })}
          disabled={!selectedVideoCards || selectedVideoCards?.length === 0}
        >
          {(maxVideos > 1 || !maxVideos) && selectedVideoCards?.length > 1
            ? translationsStoryline.videoFunnel.addSelectedVideos[lang]
            : translationsStoryline.videoFunnel.addSelectedVideo[lang]}
        </Button>
      </SimpleDialogActions>
    </Dialog>
  );
}
