import React from 'react';
import { TableCell, TableRow, Box, Typography } from '@mui/material';
import HoverVideo from '../../HoverVideo';
import { EnhancedVideoCardType } from './types';
import { formatTotalTimePlayed } from '../Overview/dataUtils';

export interface VideoRowProps {
  video: EnhancedVideoCardType;
  lang: Language;
  width?: number | string;
  height?: number | string;
}

function VideoRow({ video, lang, width = 56, height = 100 }: VideoRowProps) {
  const { totalTimePlayed, totalImpressions, totalPlays, totalPlayRate } = video;
  const formattedTimePlayed = formatTotalTimePlayed(totalTimePlayed || 0, lang);

  return (
    <TableRow key={`video-table-row-${video.id}`} data-testid={`video-row-${video.id}`}>
      <TableCell>
        <Box display="flex" alignItems="center" data-testid={`video-thumbnail-${video.id}`}>
          <Box sx={{ mr: 2, width, height, position: 'relative' }}>
            <HoverVideo videoCard={video} width={width} height={height} borderRadius={4} />
          </Box>
          <Typography variant="bodyMedium" noWrap data-testid="video-title">
            {video.question[lang]}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="bodyMedium">{totalImpressions?.toLocaleString()}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="bodyMedium">{totalPlays?.toLocaleString()}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="bodyMedium">{totalPlayRate?.toFixed(0).toLocaleString()}%</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="bodyMedium">{formattedTimePlayed.toLocaleString()}</Typography>
      </TableCell>
    </TableRow>
  );
}

export default VideoRow;
