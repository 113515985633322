import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';

import { useLanguage, useSetDetectedLanguage } from 'src/context/LanguageContext';
import { gqClient } from '../../utils/graphQL';

const getOrganizationData = (orgNameSlug, encodedOrgId) => {
  if (orgNameSlug) {
    return gqClient.query({
      query: gql`
          query {
            organization(orgNameSlug: "${orgNameSlug}"){
              id
              orgName
              orgNameSlug
              encodedId
              lang
              doNotTrack
              customTexts {
                uploadConsent
                soMeConsent {
                  text
                  enable
                  mandatory
                }
              }
              promoteRecordVideoPortrait
              style {
                backgroundColor
                textColor
                btnColor
                btnTextColor
                widgetBottomMargin
                widgetSideMargin
                widgetPositionTop
                widgetProcentBasedMargin
                enableHideWidget
                widgetPositionLeftMobile
                widgetBottomMarginMobile
                widgetSideMarginMobile
                widgetPositionTopMobile
                widgetProcentBasedMarginMobile
                separateDesktopMobile
                widgetHideDialogTitle
                widgetHideDialogTitleMobile
              }
            }
          }
      `,
    });
  }
  if (encodedOrgId) {
    return gqClient.query({
      query: gql`
          query {
            organization(encodedOrgId: "${encodedOrgId}"){
              id
              orgName
              orgNameSlug
              encodedId
              lang
              promoteRecordVideoPortrait
              customTexts {
                uploadConsent
                soMeConsent {
                  text
                  enable
                  mandatory
                }
              }
              style {
                backgroundColor
                textColor
                btnColor
                btnTextColor
                widgetBottomMargin
                widgetSideMargin
                widgetPositionTop
                widgetProcentBasedMargin
                enableHideWidget
                widgetPositionLeftMobile
                widgetBottomMarginMobile
                widgetSideMarginMobile
                widgetPositionTopMobile
                widgetProcentBasedMarginMobile
                separateDesktopMobile
                widgetHideDialogTitle
                widgetHideDialogTitleMobile
              }
            }
          }
      `,
    });
  }
};

function AppWrapper(props) {
  const params = useParams();
  const [orgData, setOrgData] = useState(props?.orgData || null);
  const [error, setError] = useState(null);
  const lang = useLanguage();
  const setDetectedLang = useSetDetectedLanguage();

  useEffect(() => {
    const { orgnameslug: paramOrgNameSlug, encodedorgid: paramEncodedOrgId } = params;
    const orgNameSlug = paramOrgNameSlug || props?.orgData?.orgNameSlug;
    const encodedOrgId = paramEncodedOrgId || props?.encodedOrgId;
    if (orgNameSlug || encodedOrgId) {
      getOrganizationData(orgNameSlug, encodedOrgId)
        .then((result) => {
          setOrgData(result.data.organization);
          setDetectedLang(result.data.organization.lang);
        })
        .catch((e) => setError(e.message));
    } else {
      setError('noOrg');
    }
  }, []);

  if (error) {
    return <ErrorComponent errorMessage={error} />;
  }
  if (!orgData) {
    return null;
  }

  const children = React.Children.toArray(props.children).map((e) =>
    React.cloneElement(e, { lang, basicOrgData: orgData, orgNameSlug: orgData.orgNameSlug }),
  );

  return <div className="app-wrapper">{children}</div>;
}

export default AppWrapper;

function ErrorComponent(props) {
  return (
    <div className="error-wrapper">
      <Typography variant="headlineSmall">Something went wrong</Typography>
      {props.errorMessage}
    </div>
  );
}
