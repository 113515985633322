import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { useLanguage } from 'src/context/LanguageContext';
import VideoCard from '../VideoLibrary/VideoCard';
import { translationsDashboard } from '../../../utils/translationsDashboard';

const cardHeight = 380;
const cardWidth = cardHeight * 0.516;

function PopularVideosCard({ basicOrgData = { encodedId: null }, dashboardStatsState }) {
  const lang = useLanguage();

  if (!(dashboardStatsState?.popularVideosCardData?.videoCards?.length > 0)) return null;

  return (
    <Card>
      <CardHeader
        title={translationsDashboard.popularVideosCard.mostPopularVideos[lang]}
        titleTypographyProps={{
          variant: 'titleMedium',
        }}
        subheaderTypographyProps={{
          variant: 'bodyMedium',
        }}
      />
      <CardContent sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Grid container justifyContent="space-evenly" alignItems="center">
          {dashboardStatsState?.popularVideosCardData?.videoCards?.map((videoCard) => (
            <Grid item key={`video-card-${videoCard.id}`}>
              <VideoCard
                key={videoCard.id}
                playMode
                videoCard={videoCard}
                orgData={basicOrgData}
                updateVideoCard={() => {}}
                height={cardHeight}
                width={cardWidth}
                padding={cardHeight * 0.025}
                setAffectedItem={() => {}}
                setInfo={() => {}}
                handleUpdateBoard={() => {}}
                suggestedQuestions={[]}
                orgRecipients={[]}
                setError={() => {}}
                handleDuplicateItemClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

PopularVideosCard.propTypes = {
  basicOrgData: PropTypes.shape({
    encodedId: PropTypes.string,
  }),
  dashboardStatsState: PropTypes.shape({
    popularVideosCardData: PropTypes.shape({
      videoCards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        }),
      ),
    }),
  }).isRequired,
};

export default PopularVideosCard;
