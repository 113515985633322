import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';

import ThemeContext from 'src/utils/ThemeContext';
import { TabData } from './types';

interface TabLabelProps {
  tab: TabData;
  isActive: boolean;
  isHovered: boolean;
}

type labelVariant = 'labelMedium' | 'labelMediumBold' | 'labelMediumSemiBold';
const largestLabelVariant: labelVariant = 'labelMediumBold';

function TabLabel({ tab, isActive, isHovered }: TabLabelProps) {
  const theme = useContext(ThemeContext);

  let variant: labelVariant = 'labelMedium';
  let color = theme.palette.navbar.main;

  if (isActive) {
    variant = 'labelMediumBold';
    color = theme.palette.navbar.light;
  } else if (isHovered) {
    variant = 'labelMediumSemiBold';
  }

  return (
    <Grid
      item
      xs={12}
      xl={12}
      sm={12}
      md={12}
      lg={12}
      role="gridcell"
      data-testid={`tab-label-${tab.label}`}
    >
      <Typography
        variant={variant}
        align="center"
        color={color}
        aria-label={tab.label}
        data-testid={`tab-label-text-${tab.label}`}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          mx: 1,
        }}
      >
        {tab.label}
      </Typography>
      {/* HACK(petere): Render the largest variant hidden and without height,
          to keep width of element consistent :)) */}
      <br />
      <Typography
        variant={largestLabelVariant}
        align="center"
        data-testid={`tab-label-dummy-text-${tab.label}`}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '1px',
          mx: 1,
          visibility: 'hidden',
          display: 'block',
        }}
      >
        {tab.label}
      </Typography>
    </Grid>
  );
}

export default TabLabel;
