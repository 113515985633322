import { gql } from '@apollo/client/index';
import { useMemo } from 'react';
import { useBackOfficeApolloClient } from 'src/context/ApolloClientContext';

const CATEGORY_DETAIL_FRAGMENT = gql`
  fragment Category on OrgCategory {
    id
    orgId
    title
    forLeaders
    forTeamMember
    suggestedRefId
    created
  }
`;

const QUESTION_DETAIL_FRAGMENT = gql`
  ${CATEGORY_DETAIL_FRAGMENT}
  fragment Question on OrgQuestion {
    id
    orgId
    question
    enableAutoRequest
    orgCategory {
      ...Category
    }
    categoryId
    forLeaders
    forTeamMember
    suggestedRefId
    created
  }
`;

export class QuestionsActions {
  #backOfficeClient;

  /**
   * @param {import('src/context/ApolloClientContext').ApolloClient} backOfficeClient
   */
  constructor(backOfficeClient) {
    this.#backOfficeClient = backOfficeClient;
  }

  getCategories({ encodedOrgId, orgId }) {
    return this.#backOfficeClient.query({
      query: gql`
        ${CATEGORY_DETAIL_FRAGMENT}
        query GetOrgCategories($encodedOrgId: String, $orgId: Int) {
          getOrgCategories(encodedOrgId: $encodedOrgId, orgId: $orgId) {
            ...Category
          }
        }
      `,
      variables: { encodedOrgId, orgId },
    });
  }

  getAllQuestions({ encodedOrgId, orgId }) {
    return this.#backOfficeClient.query({
      query: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        query GetOrgQuestions($encodedOrgId: String, $orgId: Int) {
          getOrgQuestions(encodedOrgId: $encodedOrgId, orgId: $orgId) {
            ...Question
          }
        }
      `,
      variables: { encodedOrgId, orgId },
    });
  }

  createCategory({ encodedOrgId, title, orgId, forLeaders, forTeamMember }) {
    const input = {
      orgId,
      title: title ?? undefined,
      forLeaders: forLeaders ?? undefined,
      forTeamMember: forTeamMember ?? undefined,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        ${CATEGORY_DETAIL_FRAGMENT}
        mutation CreateOrgCategory($encodedOrgId: String, $input: OrgCategoryInput) {
          createOrgCategory(encodedOrgId: $encodedOrgId, input: $input) {
            question {
              ...Question
            }
            questions {
              ...Question
            }
            categories {
              ...Category
            }
            category {
              ...Category
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  deleteCategory({ encodedOrgId, orgId, id }) {
    const input = { orgId, id };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        ${CATEGORY_DETAIL_FRAGMENT}
        mutation DeleteOrgCategory($encodedOrgId: String, $input: OrgCategoryInput) {
          deleteOrgCategory(encodedOrgId: $encodedOrgId, input: $input) {
            questions {
              ...Question
            }
            categories {
              ...Category
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  updateCategory({ encodedOrgId, title, orgId, forLeaders, forTeamMember, id }) {
    const input = {
      id,
      orgId,
      title: title ?? undefined,
      forLeaders: forLeaders ?? undefined,
      forTeamMember: forTeamMember ?? undefined,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        ${CATEGORY_DETAIL_FRAGMENT}
        mutation UpdateOrgCategory($encodedOrgId: String, $input: OrgCategoryInput) {
          updateOrgCategory(encodedOrgId: $encodedOrgId, input: $input) {
            question {
              ...Question
            }
            questions {
              ...Question
            }
            categories {
              ...Category
            }
            category {
              ...Category
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  updateQuestion({
    encodedOrgId,
    id,
    question,
    orgId,
    enableAutoRequest,
    categoryId,
    forLeaders,
    forTeamMember,
    questionCategoryId,
  }) {
    const input = {
      orgId,
      id,
      categoryId: categoryId ?? undefined,
      questionCategoryId: questionCategoryId ?? undefined,
      question: question ?? undefined,
      enableAutoRequest: enableAutoRequest ?? undefined,
      forLeaders: forLeaders ?? undefined,
      forTeamMember: forTeamMember ?? undefined,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        ${CATEGORY_DETAIL_FRAGMENT}
        mutation UpdateOrgQuestion($encodedOrgId: String, $input: OrgQuestionInput) {
          updateOrgQuestion(encodedOrgId: $encodedOrgId, input: $input) {
            questions {
              ...Question
            }
            categories {
              ...Category
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  deleteQuestion({ encodedOrgId, orgId, id, questionCategoryId }) {
    const input = {
      orgId,
      id,
      questionCategoryId: questionCategoryId ?? undefined,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        ${CATEGORY_DETAIL_FRAGMENT}
        mutation DeleteOrgQuestion($encodedOrgId: String, $input: OrgQuestionInput) {
          deleteOrgQuestion(encodedOrgId: $encodedOrgId, input: $input) {
            questions {
              ...Question
            }
            categories {
              ...Category
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }

  createQuestion({
    encodedOrgId,
    question,
    orgId,
    enableAutoRequest,
    categoryId,
    forLeaders,
    forTeamMember,
    questionCategoryId,
  }) {
    const input = {
      orgId,
      categoryId: categoryId ?? undefined,
      questionCategoryId: questionCategoryId ?? undefined,
      question: question ?? undefined,
      enableAutoRequest: enableAutoRequest ?? undefined,
      forLeaders: forLeaders ?? undefined,
      forTeamMember: forTeamMember ?? undefined,
    };

    return this.#backOfficeClient.mutate({
      mutation: gql`
        ${QUESTION_DETAIL_FRAGMENT}
        ${CATEGORY_DETAIL_FRAGMENT}
        mutation CreateOrgQuestion($encodedOrgId: String, $input: OrgQuestionInput) {
          createOrgQuestion(encodedOrgId: $encodedOrgId, input: $input) {
            question {
              ...Question
            }
            questions {
              ...Question
            }
            categories {
              ...Category
            }
          }
        }
      `,
      variables: { encodedOrgId, input },
    });
  }
}

export const useQuestionsActions = () => {
  const backOfficeClient = useBackOfficeApolloClient();
  return useMemo(() => new QuestionsActions(backOfficeClient), [backOfficeClient]);
};
