import { useMemo } from 'react';
import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {
  backendApiPath,
  backendApiBackOfficePath,
  backendApiAdminPath,
  backendApiStatsPath,
} from './environment';

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
  },
};

export const gqClient = new ApolloClient({
  defaultOptions: { ...defaultOptions },
  credentials: 'include',
  uri: backendApiPath,
  cache: new InMemoryCache(),
});

export const makeBackOfficeClient = (accessToken: string) => {
  const headers = { authorization: `Bearer ${accessToken}` };
  return new ApolloClient({
    defaultOptions: { ...defaultOptions },
    cache: new InMemoryCache(),
    link: createUploadLink({ uri: backendApiBackOfficePath, credentials: 'include', headers }),
  });
};

export const makeAdminClient = (accessToken: string) => {
  const headers = { authorization: `Bearer ${accessToken}` };
  return new ApolloClient({
    defaultOptions: { ...defaultOptions },
    cache: new InMemoryCache(),
    link: createUploadLink({ uri: backendApiAdminPath, credentials: 'include', headers }),
  });
};

export const makeBackOfficeStatsClient = (accessToken: string) => {
  const headers = { authorization: `Bearer ${accessToken}` };
  return new ApolloClient({
    defaultOptions: { ...defaultOptions },
    cache: new InMemoryCache(),
    link: createUploadLink({ uri: backendApiStatsPath, credentials: 'include', headers }),
  });
};

// useAuthenticatedApolloClients is a thin "memoize-wrapper" around the make*Client methods
// that returns _new_ client instances configured with the provided accessToken. If accessToken
// is null returns null for all instances.
export const useAuthenticatedApolloClients = (accessToken: string | null) => {
  return useMemo(() => {
    let adminClient = null;
    let backOfficeClient = null;
    let backOfficeStatsClient = null;
    if (accessToken != null) {
      adminClient = makeAdminClient(accessToken);
      backOfficeClient = makeBackOfficeClient(accessToken);
      backOfficeStatsClient = makeBackOfficeStatsClient(accessToken);
    }
    return { adminClient, backOfficeClient, backOfficeStatsClient };
  }, [accessToken]);
};
