import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import LoadingIndicator from 'src/components/common/LoadingIndicator';
import { useQuestionsActions } from './Actions';
import { translations } from '../../../../utils/translationsSettings';
import QuestionCategoryCard from './QuestionCategoryCard';
import QuestionDialog from './QuestionDialog';
import CategoryDialog from './CategoryDialog';
import useCheckPermissionsAndNavigate from '../Permissions/CheckPermissionsAndNavigate';

export default function Questions() {
  const lang = useLanguage();
  const questionsActions = useQuestionsActions();
  const { basicOrgData } = useOutletContext();
  const hasAutoRequest = basicOrgData?.premiumFeatures?.autoRequests;
  const [categories, setCategories] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [createNewQuestionOpen, setCreateNewQuestionOpen] = useState(false);
  const [editQuestion, setEditQuestion] = useState(null);

  const [createNewCategoryOpen, setCreateNewCategoryOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(null);

  const { loading: userLoading } = useCheckPermissionsAndNavigate({
    basicOrgData,
    navigateBackOfficePath: 'settings/questions',
  });

  const handleSetQuestions = (newQuestionArray) => {
    if (newQuestionArray?.length > 0) {
      const sortedQuestions =
        questions?.length > 0
          ? [...newQuestionArray]?.sort((a, b) => {
              return `${a?.question}`.localeCompare(`${b?.question}`);
            }) || []
          : [...newQuestionArray]?.sort((a, b) => {
              questions.indexOf((q) => q.id === a.id) - questions.indexOf((q) => b.id === b.id);
            }) || [];
      setQuestions(sortedQuestions);
    } else {
      setQuestions([]);
    }
  };

  const handleGetCategories = () => {
    questionsActions
      .getCategories({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
      .then((response) => {
        const categories = response?.data?.getOrgCategories;
        handleSetCategories(categories || [{ title: translations.category.uncategorized[lang] }]);
        questionsActions
          .getAllQuestions({ encodedOrgId: basicOrgData.encodedId, orgId: basicOrgData.id })
          .then((response) => {
            handleSetQuestions(response?.data?.getOrgQuestions);
          })
          .catch((error) => {
            alert(error);
          });
      })

      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  const handleSetCategories = (newCategoryArray) => {
    if (newCategoryArray && newCategoryArray.length > 0) {
      const sortedCategories = [...newCategoryArray]?.sort((a, b) => {
        return `${a?.title}`.localeCompare(`${b?.title}`);
      });
      setCategories(
        [
          ...sortedCategories,
          ...(sortedCategories?.find((c) => !c.id)
            ? []
            : [{ title: translations.category.uncategorized[lang] }]),
        ] || [],
      );
    } else {
      setCategories([{ title: translations.category.uncategorized[lang] }]);
    }
  };

  const handleClose = (value) => {
    if (value) {
      if (value.categories?.length >= 0) {
        handleSetCategories(value.categories);
      }
      if (value.category) {
        const newCategory = categories?.find((c) => c.id === value.category.id);
        if (!newCategory) {
          handleSetCategories([...categories, value.category]);
        }
      }

      if (value.questions?.length >= 0) {
        handleSetQuestions(value.questions);
      }
      if (value.question) {
        const newQuestion = questions?.find((q) => q.id === value.question.id);
        if (!newQuestion) {
          handleSetQuestions([...questions, value.question]);
        }
      }
    }
  };

  const handleCloseQuestion = (value) => {
    setCreateNewQuestionOpen(false);
    setEditQuestion(null);
    handleClose(value);
  };

  const handleCreateQuestion = (value, categoryId) => {
    const catId = categoryId || translations.category.uncategorized[lang];

    if (!expandedCategories.includes(catId)) {
      setExpandedCategories([...expandedCategories, catId]);
    }
    handleCloseQuestion(value);
  };

  const handleCloseCategory = (value) => {
    setCreateNewCategoryOpen(false);
    setEditCategory(null);
    handleClose(value);
  };
  const handleCreateCategory = (value) => {
    setExpandedCategories([...expandedCategories, value?.category.id]);
    handleCloseCategory(value);
  };

  if (userLoading) return <LoadingIndicator />;

  return (
    <div className="inner">
      {createNewQuestionOpen && (
        <QuestionDialog
          key={_.uniqueId(editQuestion?.id)}
          open={createNewQuestionOpen}
          onClose={handleCloseQuestion}
          categories={categories}
          editQuestion={editQuestion}
          onCreate={handleCreateQuestion}
          hasAutoRequest={hasAutoRequest}
        />
      )}
      {createNewCategoryOpen && (
        <CategoryDialog
          key={_.uniqueId(editCategory?.id)}
          open={createNewCategoryOpen}
          onClose={handleCloseCategory}
          onCreate={handleCreateCategory}
          categories={categories}
          editCategory={editCategory}
          hasAutoRequest={hasAutoRequest}
        />
      )}
      <div>
        <div className="header-row-questions">
          <div className="title">
            <Typography variant="titleLarge">
              {translations.category.categoriesAndQuestions[lang]}
            </Typography>
          </div>
          <div className="add-question-category">
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setCreateNewCategoryOpen(true);
                }}
              >
                {translations.category.addCategory[lang]}
              </Button>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setCreateNewQuestionOpen(true);
                }}
              >
                {translations.question.addQuestion[lang]}
              </Button>
            </Box>
          </div>
        </div>
        {categories?.map((category) => (
          <div key={_.uniqueId(category?.id)}>
            <div key={_.uniqueId(category?.id)}>
              <QuestionCategoryCard
                title={category?.title}
                key={category?.id}
                category={category}
                expanded={expandedCategories.includes(
                  category.id ? category.id : translations.category.uncategorized[lang],
                )}
                setExpanded={(value) => {
                  const catId = category.id
                    ? category.id
                    : translations.category.uncategorized[lang];

                  if (expandedCategories.includes(catId)) {
                    setExpandedCategories(expandedCategories.filter((c) => c !== catId));
                  } else {
                    setExpandedCategories([...expandedCategories, catId]);
                  }
                }}
                setQuestions={(questions) => {
                  handleClose(questions);
                }}
                questions={questions?.filter((q) => {
                  if (category?.id) {
                    return q?.categoryId === category?.id;
                  }
                  if (!categories?.find((c) => c?.id && c?.id === q?.categoryId)) {
                    return true;
                  }
                  return !q?.categoryId;
                })}
                handleEditCategory={() => {
                  setEditCategory(category);
                  setCreateNewCategoryOpen(true);
                }}
                handleEditQuestion={(q) => {
                  setEditQuestion(q);
                  setCreateNewQuestionOpen(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
