import PropTypes from 'prop-types';

function StorylineCardHeader({ background = '#9400FE', circle = '#F7F8EC' }) {
  return (
    <svg viewBox="0 0 304 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H296C300.418 0 304 3.58172 304 8V20H0V8Z"
        fill={background}
      />
      <circle cx="33" cy="10" r="4" fill={circle} />
      <circle cx="21" cy="10" r="4" fill={circle} />
      <circle cx="9" cy="10" r="4" fill={circle} />
    </svg>
  );
}

export default StorylineCardHeader;

StorylineCardHeader.propTypes = {
  background: PropTypes.string,
  circle: PropTypes.string,
};
