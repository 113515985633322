import { format } from 'date-fns';

/**
 * Formats a date input into 'yyyy-MM-dd' format.
 * Accepts a Date object, a timestamp, or a date string. Checks for validity and handles invalid input.
 * @param dateInput - The date input to be formatted.
 * @returns A formatted date string in 'yyyy-MM-dd' format or 'Invalid Date' if the input is not a valid date.
 */
const formatDateString = (dateInput: Date | number | string): string => {
  // Attempt to construct a Date object from the input
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

  // Check if the constructed Date object is valid
  if (Number.isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  // Return the formatted date
  return format(date, 'yyyy-MM-dd');
};

export default formatDateString;
