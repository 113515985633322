import PropTypes from 'prop-types';

function BackIcon({ fill = '#4E5057' }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M15.705 7.41L14.295 6L8.29504 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z"
        fill={fill}
      />
    </svg>
  );
}

export default BackIcon;

BackIcon.propTypes = {
  fill: PropTypes.string,
};
